import { Search as SearchIcon } from '@mui/icons-material';
import React, { useEffect, useCallback, useState, useRef } from 'react';

import {
  Box,
  Container,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Menu,
  MenuItem,
  TextField,
  Drawer,
  Paper,
  Divider,
  Link,
  LinearProgress,
  InputTextField,
  IconButton,
  Grow,
  Tooltip,
  ListItemIcon,
  ListItemText,
  InputAdornment,
} from 'app/design';

import {
  AccessTime as HistoryIcon,
  Backspace as BackspaceIcon,
  Call as CallIcon,
  Dialpad as DialpadIcon,
  Mood as RegisteredIcon,
  ErrorOutline as UnRegisteredIcon,
  PhoneForwarded as PhoneForwardedIcon,
  Videocam as VideocamIcon,
  CallForward as CallForwardIcon,
  Flag as FlagIcon,
  Person as PersonIcon,
  VideoCall as VideoCallIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Settings as SettingsIcon,
} from 'app/design/icons-material';

import { useHistory } from 'react-router-dom';

// import {
//   randomString,
//   useLazyLoad,
//   getAtPath,
//   setAtPath,
// } from '@KazooSDK/utils';
// import { KazooSDK } from '@KazooSDK';

import { connect, useSelector, useDispatch } from 'react-redux';
import { useEffectOnce, useHoverDirty } from 'react-use';

import PhoneInput from 'react-phone-input-2';
import { useLookupContact } from '../../../../data/webphone/webphoneSlice';
import { useEditContactDialog } from '../../../EditContactDialog';

import { useWebphoneContext } from '../../Store';
import { ContactDisplay } from '../ContactDisplay';
import { Numpad } from '../Numpad';

// import { useSetupHook } from '../../../../Common/SetupHook';
// import { AutocompleteUser } from '../../../../Common/AutocompleteUser';
// import { AutocompleteConference } from '../../../../Common/AutocompleteConference';
import { AutocompleteDialer } from '../AutocompleteDialer';

// import {
//   usePopupState,
//   bindTrigger,
//   bindHover,
//   bindMenu,
// } from 'material-ui-popup-state/hooks';
// // import Menu from 'material-ui-popup-state/HoverMenu'; // use for HoverMenu!

import EventEmitter from 'eventemitter3';
import { ViewContainer } from '../ViewContainer';

const eventEmitter = new EventEmitter();

const DialView = (props, context) => {
  const { dial, dialConference, show } = props;

  const inputRef = useRef();

  const [dialerMode, setDialerMode] = useState('internal'); // conference
  const [additionalInfo, setAdditionalInfo] = useState({});

  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useWebphoneContext();
  const { sip, calls, isRegistered } = state;

  // const accountDefaultNumber = ''; //
  const [hasNumpad, setNumpad] = useState(true);
  const [inputText, setInputText] = useState(''); // defaults are set in each Dialer!

  const handleClickNum = num => {
    // // emit click that child listens for
    // setInputText(`${inputText}${num}`);
    inputRef.current?.focus && inputRef.current.focus();
    eventEmitter.emit('add-num', num);
  };

  // const handleStartCall = (isVideoCall, inputText) => {
  //   if (inputText.trim() === '') {
  //     return;
  //   }
  //   const activeCalls = calls.filter(
  //     (call) => call.isActive() && call.isOnLocalHold() === false
  //   );
  //   activeCalls.forEach((activeCall) => {
  //     activeCall.hold();
  //   });
  //   if (dialerMode === 'conference') {
  //     dialConference(inputText, isVideoCall, additionalInfo);
  //   } else {
  //     dial(inputText, isVideoCall);
  //   }
  // };
  const [contact] = useLookupContact(inputText);
  const {
    Dialog: EditContactDialog,
    DialogProps: EditContactDialogProps,
    toggleOpen: toggleOpenEditContactDialog,
  } = useEditContactDialog();

  const handleStartCall = (isVideoCall, inputText) => {
    if (inputText.trim() === '') {
      return;
    }
    const activeCalls = calls.filter(
      call => call.isActive() && call.isOnLocalHold() === false,
    );
    activeCalls.forEach(activeCall => {
      activeCall.hold();
    });
    // if (dialerMode === 'conference') {
    //   dialConference(inputText, isVideoCall, additionalInfo);
    // } else {
    dial(inputText, isVideoCall);

    // clear dialer
    setInputText('');
    // }
  };

  const handleCall = () => {
    handleStartCall(false, inputText);
  };

  const handleBackspace = () => {
    // remove last char
    setInputText(prev => prev.slice(0, -1));
    inputRef.current?.focus && inputRef.current.focus();
  };

  return (
    <Box
      sx={{
        display: show ? 'flex' : 'none',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <EditContactDialog
        {...EditContactDialogProps}
        initialNumber={inputText}
        onComplete={toggleOpenEditContactDialog}
      />
      <Box sx={{ flex: '0 1 auto' }}>
        <Box sx={{ p: '24px 28px 8px' }}>
          <DialerAutocompleteInput
            show={show}
            setAdditionalInfo={setAdditionalInfo}
            additionalInfo={additionalInfo}
            dialerMode={dialerMode}
            setDialerMode={setDialerMode}
            inputRef={inputRef}
            setDialText={setInputText}
            dial={props.dial}
            dialConference={props.dialConference}
            inputText={inputText}
            setInputText={setInputText}
            onStartCall={handleStartCall}
          />
        </Box>
        <Box sx={{ display: 'grid', placeItems: 'center', minHeight: 54 }}>
          {contact ? (
            <ContactDisplay contact={contact} />
          ) : inputText ? (
            <Button onClick={toggleOpenEditContactDialog}>
              Add to contacts
            </Button>
          ) : null}
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          background: '#285E70',
        }}
      >
        <Numpad
          onClick={handleClickNum}
          onCall={handleCall}
          disableCall={!inputText}
          onDelete={handleBackspace}
        />
      </Box>
    </Box>
  );

  // return (
  //   <div
  //     style={{
  //       height: '100%',
  //       overflowY: 'scroll',
  //       display: show ? 'block' : 'none',
  //     }}
  //   >
  //     <div
  //       style={{
  //         marginBottom: 15,
  //         borderBottom: '1px solid #CFD8DC',
  //       }}
  //     >
  //       <DialerAutocompleteInput
  //         show={show}
  //         setAdditionalInfo={setAdditionalInfo}
  //         additionalInfo={additionalInfo}
  //         dialerMode={dialerMode}
  //         setDialerMode={setDialerMode}
  //         inputRef={inputRef}
  //         setDialText={setInputText}
  //         dial={props.dial}
  //         dialConference={props.dialConference}
  //       />
  //     </div>
  //     {/* <div
  //       style={{
  //         marginBottom: 15,
  //         borderBottom: '1px solid #CFD8DC',
  //       }}
  //     >
  //       <DialerInputs
  //         setAdditionalInfo={setAdditionalInfo}
  //         additionalInfo={additionalInfo}
  //         dialerMode={dialerMode}
  //         setDialerMode={setDialerMode}
  //         inputRef={inputRef}
  //         setDialText={setInputText}
  //       />
  //     </div> */}
  //     <div>
  //       <Numpad onClick={handleClickNum} />
  //     </div>
  //     {/* <div>
  //       <Grid container justify="center">
  //         <Grid
  //           item
  //           md={7}
  //           onClick={() => {
  //             handleStartCall(false);
  //           }}
  //           // className={classes.audioCallButton}
  //         >
  //           <Button variant="contained" color="success" fullWidth>
  //             <CallIcon />
  //           </Button>
  //         </Grid>
  //         <Grid
  //           item
  //           md={5}
  //           onClick={() => {
  //             handleStartCall(true);
  //           }}
  //           // className={classes.videoCallButton}
  //         >
  //           <Button variant="info" color="success" fullWidth>
  //             <VideocamIcon />
  //           </Button>
  //         </Grid>
  //       </Grid>
  //     </div> */}
  //   </div>
  // );
};

const DialerAutocompleteInput = props => {
  const {
    show,
    dial,
    dialConference,
    inputRef,
    setDialText,
    additionalInfo,
    setAdditionalInfo,
    inputText,
    setInputText,
    onStartCall,
  } = props;

  const [state, dispatch] = useWebphoneContext();
  const { sip, calls, isRegistered } = state;

  const [inputValue, setInputValue] = useState(null); // not used!

  const handleChange = newValue => {
    // gets newValue as a string??
    // - or as a specific type to display?
    console.log('handleChange DialerAutocompleteInput:', newValue);
    // debugger;
    setInputText(newValue?.outputValue ?? '');
    if (newValue?.call) {
      onStartCall(false, newValue?.outputValue);
    }
  };
  const handleInputChange = newValue => {
    console.log('handleInputChange:', newValue);
    setInputText(newValue);
  };
  const handleBackspace = () => {
    // TODO: clear/reset mode if pressing backspace repeatedly with none remaining
    setInputText(inputText.slice(0, inputText.length - 1));
  };
  // TODO: handle Clear (clear/reset mode)

  const addNum = num => {
    // console.log('num added');
    // emit click that child listens for
    setInputText(inputText => `${inputText}${num}`);
    inputRef.current?.numberInputRef?.focus &&
      inputRef.current.numberInputRef.focus();
  };

  // // focus
  // useEffect(() => {
  //   if (showInput && inputRef.current?.numberInputRef?.focus) {
  //     inputRef.current.numberInputRef.focus();
  //   }
  // }, [showInput]);

  useEffect(() => {
    eventEmitter.on('add-num', addNum);
    return () => {
      eventEmitter.removeListener('add-num', addNum);
    };
  }, []);

  // update parent when this one changes
  useEffect(() => {
    setDialText(inputText);
  }, [inputText]);

  useEffect(() => {
    // go to the END of the input!
    if (show && inputRef.current) {
      const end = inputRef.current.value.length;
      inputRef.current.setSelectionRange(end, end);
      inputRef.current.focus();
    }
  }, [show]);

  // const classes = useStyles();

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'no-wrap',
        alignItems: 'center', // cant use 'center' if we want full-height buttons
        // padding: '0 12px',
      }}
    >
      <div style={{ width: '28px', textAlign: 'center' }}>
        {typeof isRegistered === 'function' && isRegistered() ? (
          <Tooltip
            title={<>Registered and ready for calls</>}
            type="light"
            arrow
          >
            <span>
              <RegisteredIcon
                style={{ color: 'green', verticalAlign: 'middle' }}
              />
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={<>Not yet ready for calls</>} type="light" arrow>
            <span>
              <UnRegisteredIcon
                style={{ color: 'red', verticalAlign: 'middle' }}
              />
            </span>
          </Tooltip>
        )}
      </div>
      <div style={{ flex: 1, padding: '6px 0 7px 4px' }}>
        <div style={{ margin2: '8px 0 4px' }}>
          <TextField
            inputRef={inputRef}
            placeholder="Dial a Number"
            // label=""
            value={inputText}
            onChange={e => setDialText(e.target.value)}
            autoFocus
            fullWidth
            size="small"
            margin="none"
            sx={{
              '.MuiInputBase-root': {
                background: 'none',
                p: '0px !important',
              },
              // background: 'none',
              input: {
                p: 0,
                fontSize: '24px !important',
                textAlign: 'right',
                // background: 'none',
              },
              fieldset: {
                fontSize: 18,
                border: '0px',
                // background: 'none',
              },
            }}
          />
          {/* <AutocompleteDialer
            show={show}
            inputRef={inputRef}
            placeholder="Dial or enter a number"
            label=""
            inputValue={inputText}
            onInputChange={handleInputChange}
            // value={inputValue} // this is NOT here! we ONLY use the inputValue (inputText) from above!
            onChange={handleChange}
            textfieldProps={{ autoFocus: true }}
          /> */}
        </div>
      </div>
      {/* <div style={{ padding: '0 4px' }}>
        <IconButton onClick={handleBackspace} size="small">
          <BackspaceIcon />
        </IconButton>
      </div> */}
      {/* <div style={{ padding: '0 4px' }}>
        <Button
          color="success"
          variant="contained"
          size="large"
          onClick={() => {
            handleStartCall(false, inputText);
          }}
        >
          <CallIcon />
        </Button>
      </div>
      <div style={{ padding: '0 4px' }}>
        <Button
          color="success"
          variant="outlined"
          size="large"
          onClick={() => {
            handleStartCall(true, inputText);
          }}
        >
          <VideocamIcon />
        </Button>
      </div> */}
    </div>
  );
};

// const DialerInputs = props => {
//   const {
//     inputRef,
//     dialerMode,
//     setDialerMode,
//     additionalInfo,
//     setAdditionalInfo,
//     setDialText,
//   } = props;

//   const popupState = usePopupState({
//     variant: 'popover',
//     popupId: `dialerinputs`,
//   });

//   const handleChangeDialerMode = setTo => () => {
//     setDialerMode(setTo);
//     popupState.close();
//   };

//   let DialerComponent, IconComponent;
//   const MODES = [
//     {
//       id: 'address',
//       name: 'Address Book',
//       icon: PersonIcon,
//     },
//     {
//       id: 'internal',
//       name: 'Internal (Extension)',
//       icon: DialpadIcon,
//     },
//     {
//       id: 'external',
//       name: 'External',
//       icon: DialpadIcon,
//     },
//     {
//       id: 'conference',
//       name: 'Conference',
//       icon: VideoCallIcon,
//     },
//   ];
//   const currentMode = MODES.find(m => m.id === dialerMode);
//   IconComponent = currentMode?.icon;

//   return (
//     <div
//       style={{
//         display: 'flex',
//         flexWrap: 'no-wrap',
//         alignItems: 'center',
//         // padding: '0 12px',
//       }}
//     >
//       <div style={{ padding: '0 4px' }}>
//         <div {...bindTrigger(popupState)}>
//           {/* <Tooltip
//             title={
//               <>
//                 Change Type of Call
//                 <br />
//                 - Internal
//                 <br />
//                 - External
//                 <br />- Conference
//               </>
//             }
//             type="light"
//             arrow
//           > */}
//           <span style={{ display: 'inline-block' }}>
//             <Button size="xsmall" style={{ padding: '8px 0' }}>
//               <IconComponent fontSize="small" />
//               <ArrowDropDownIcon fontSize="small" />
//             </Button>
//           </span>
//           {/* </Tooltip> */}
//         </div>
//         <Menu {...bindMenu(popupState)}>
//           {MODES.map(mode => {
//             const IconComponent = mode.icon;
//             return (
//               <MenuItem key={mode.id} onClick={handleChangeDialerMode(mode.id)}>
//                 <ListItemIcon style={{ paddingRight: 4 }}>
//                   {IconComponent && <IconComponent font="small" />}
//                 </ListItemIcon>
//                 <ListItemText primary={mode.name} />
//               </MenuItem>
//             );
//           })}
//         </Menu>
//       </div>
//       <AddressBookInput
//         showInput={dialerMode === 'address'}
//         inputRef={inputRef}
//         setDialText={setDialText}
//       />
//       <ExternalInput
//         showInput={dialerMode === 'external'}
//         inputRef={inputRef}
//         setDialText={setDialText}
//       />
//       <InternalInput
//         showInput={dialerMode === 'internal'}
//         inputRef={inputRef}
//         setDialText={setDialText}
//       />
//       <ConferenceInput
//         showInput={dialerMode === 'conference'}
//         inputRef={inputRef}
//         setDialText={setDialText}
//         additionalInfo={additionalInfo}
//         setAdditionalInfo={setAdditionalInfo}
//       />
//       {/* <DialerComponent inputRef={inputRef} /> */}
//     </div>
//   );
// };

// const AddressBookInput = props => {
//   const { inputRef, showInput, setDialText } = props;

//   const [inputText, setInputText] = useState('');
//   const [showAddressBookSettings, setShowAddressBookSettings] = useState(null);
//   const setShowAddressBookSettingsWrap = setTo => () =>
//     setShowAddressBookSettings(setTo);

//   const handleChange = newValue => {
//     setInputText(newValue?.user?.doc?.presence_id ?? '');
//   };
//   const handleInputChange = newValue => {
//     setInputText(newValue);
//   };

//   const handleBackspace = () => {
//     setInputText(inputText.slice(0, inputText.length - 1));
//   };

//   const addNum = num => {
//     // emit click that child listens for
//     setInputText(inputText => `${inputText}${num}`);
//     // console.log('inputRef:', inputRef?.current);
//     inputRef.current?.focus && inputRef.current.focus();
//   };

//   useEffect(() => {
//     if (showInput) {
//       eventEmitter.on('add-num', addNum);
//       return () => {
//         eventEmitter.removeListener('add-num', addNum);
//       };
//     }
//   }, [showInput]);

//   // update parent when this one changes
//   useEffect(() => {
//     if (showInput) {
//       setDialText(inputText);
//     }
//   }, [inputText]);

//   // focus
//   useEffect(() => {
//     if (showInput && inputRef.current?.focus) {
//       inputRef.current.focus();
//     }
//   }, [showInput]);

//   if (!showInput) {
//     return null;
//   }

//   return (
//     <>
//       {showAddressBookSettings && (
//         <AddressBookSettings onClose={setShowAddressBookSettingsWrap(null)} />
//       )}
//       <div style={{ flex: 1, padding: '4px 0' }}>
//         <div style={{ margin2: '7px 0 0px' }}>
//           <AutocompleteUser
//             inputRef={inputRef}
//             placeholder="Search your linked address books"
//             label=""
//             inputValue={inputText}
//             onInputChange={handleInputChange}
//             onChange={handleChange}
//             autocompleteProps={{ freeSolo: true }}
//             inputProps={{ autoFocus: true }}
//           />
//         </div>
//       </div>
//       <div style={{ padding: '0 4px' }}>
//         <IconButton
//           onClick={handleBackspace}
//           size="small"
//           disabled={!inputText.length}
//         >
//           <BackspaceIcon />
//         </IconButton>
//       </div>
//       <div style={{ padding: '0 4px' }}>
//         <IconButton onClick={setShowAddressBookSettingsWrap(true)} size="small">
//           <SettingsIcon />
//         </IconButton>
//       </div>
//     </>
//   );
// };

// const AddressBookSettings = props => {
//   const { onClose } = props;

//   return (
//     <Dialog fullWidth maxWidth="sm" open onClose={onClose}>
//       <DialogTitle disableTypography>
//         <Grid container justify="space-between">
//           <Grid item>
//             <Typography variant="h6">Sync Contacts in Address Books</Typography>
//           </Grid>
//           {/* <Grid item>
//             <Button color="success">Advanced Setup</Button>
//           </Grid> */}
//         </Grid>
//       </DialogTitle>
//       <Divider />
//       <DialogContent style={{ padding: '8px 12px' }}>
//         <div style={{ width: '100%' }}>
//           <div
//             style={{ textAlign: 'center', maxWidth: '300px', margin: '0 auto' }}
//           >
//             <p>
//               Choose from the following services to link your contacts and have
//               them easily available for dialing
//             </p>
//           </div>
//           <div style={{ textAlign: 'center' }}>
//             <Button variant="outlined" onClick={onClose}>
//               Google Contacts
//             </Button>
//             &nbsp;&nbsp;&nbsp;
//             <Button variant="outlined" onClick={onClose}>
//               Microsoft Outlook Contacts
//             </Button>
//             &nbsp;&nbsp;&nbsp;
//             <Button variant="outlined" onClick={onClose}>
//               Salesforce Contacts
//             </Button>
//           </div>
//         </div>
//       </DialogContent>
//       <Divider />
//       <DialogActions style={{ padding: '8px 24px' }}>
//         <div style={{ width: '100%' }}>
//           <Grid container justify="space-between" alignItems="center">
//             <Grid item></Grid>
//             <Grid item>
//               <Button variant="outlined" onClick={onClose}>
//                 Done
//               </Button>
//             </Grid>
//           </Grid>
//         </div>
//       </DialogActions>
//     </Dialog>
//   );
// };

// const ExternalInput = props => {
//   const { inputRef, showInput, setDialText } = props;

//   const [inputText, setInputText] = useState('1');

//   const handleBackspace = () => {
//     setInputText(inputText.slice(0, inputText.length - 1));
//   };

//   const addNum = num => {
//     // emit click that child listens for
//     setInputText(inputText => `${inputText}${num}`);
//     inputRef.current?.numberInputRef?.focus &&
//       inputRef.current.numberInputRef.focus();
//   };

//   useEffect(() => {
//     if (showInput) {
//       eventEmitter.on('add-num', addNum);
//       return () => {
//         eventEmitter.removeListener('add-num', addNum);
//       };
//     }
//   }, [showInput]);

//   // update parent when this one changes
//   useEffect(() => {
//     if (showInput) {
//       setDialText(inputText);
//     }
//   }, [inputText]);

//   // focus
//   useEffect(() => {
//     if (showInput && inputRef.current?.numberInputRef?.focus) {
//       inputRef.current.numberInputRef.focus();
//     }
//   }, [showInput]);

//   if (!showInput) {
//     return null;
//   }

//   return (
//     <>
//       <div style={{ flex: 1, padding: '6px 0 7px' }}>
//         <div style={{ margin2: '8px 0 4px' }}>
//           <PhoneInput
//             ref={inputRef}
//             country="us"
//             preferredCountries={['us', 'ca']}
//             value={inputText}
//             onChange={newval => setInputText(newval)}
//             // onFocus={props.onFocus}
//             // onBlur={props.onBlur}
//             inputProps={{ autoFocus: true }}
//             inputStyle={{ width: '100%' }}
//           />
//           {
//             // Expected phone input
//             // something else?? ie w/ text?
//           }
//         </div>
//       </div>
//       <div style={{ padding: '0 4px' }}>
//         <IconButton onClick={handleBackspace} size="small">
//           <BackspaceIcon />
//         </IconButton>
//       </div>
//     </>
//   );
// };

// const InternalInput = props => {
//   const { inputRef, showInput, setDialText } = props;

//   const [inputText, setInputText] = useState('');

//   const handleChange = newValue => {
//     setInputText(newValue?.user?.doc?.presence_id ?? '');
//   };
//   const handleInputChange = newValue => {
//     setInputText(newValue);
//   };

//   const handleBackspace = () => {
//     setInputText(inputText.slice(0, inputText.length - 1));
//   };

//   const addNum = num => {
//     // emit click that child listens for
//     setInputText(inputText => `${inputText}${num}`);
//     // console.log('inputRef:', inputRef?.current);
//     inputRef.current?.focus && inputRef.current.focus();
//   };

//   useEffect(() => {
//     if (showInput) {
//       eventEmitter.on('add-num', addNum);
//       return () => {
//         eventEmitter.removeListener('add-num', addNum);
//       };
//     }
//   }, [showInput]);

//   // update parent when this one changes
//   useEffect(() => {
//     if (showInput) {
//       setDialText(inputText);
//     }
//   }, [inputText]);

//   // focus
//   useEffect(() => {
//     if (showInput && inputRef?.current?.focus) {
//       inputRef.current.focus();
//     }
//   }, [showInput]);

//   if (!showInput) {
//     return null;
//   }

//   return (
//     <>
//       <div style={{ flex: 1, padding: '4px 0' }}>
//         <div style={{ margin2: '7px 0 0px' }}>
//           <AutocompleteUser
//             inputRef={inputRef}
//             placeholder="Type Extension or Search Users"
//             label=""
//             inputValue={inputText}
//             onInputChange={handleInputChange}
//             onChange={handleChange}
//             autocompleteProps={{ freeSolo: true }}
//             textfieldProps={{ autoFocus: true }}
//           />
//         </div>
//       </div>
//       <div style={{ padding: '0 4px' }}>
//         <IconButton
//           onClick={handleBackspace}
//           size="small"
//           disabled={!inputText.length}
//         >
//           <BackspaceIcon />
//         </IconButton>
//       </div>
//     </>
//   );
// };

// const ConferenceInput = props => {
//   const {
//     inputRef,
//     showInput,
//     setDialText,
//     additionalInfo,
//     setAdditionalInfo,
//   } = props;

//   const { defaultAccount } = useSetupHook();

//   const [inputText, setInputText] = useState('');

//   const handleChange = newValue => {
//     // NOTE that if we change from a "+" to a "#" that the URI-encoding breaks!!
//     // - need to pass an actual JsSIP.URI with the values pre-built (or do NOT sanitize the INVITE to %23 for the #)
//     if (!newValue?.conference) {
//       return;
//     }

//     console.log('conference:', newValue.conference.doc);
//     // let conferenceString = `conference#${newValue.conference.doc.id}@${defaultAccount.realm}`;
//     let conferenceString = `conference+${newValue.conference.doc.id}`;
//     console.log('conference string:', conferenceString);
//     // setInputText(newValue.conference.doc.conference_numbers[0] ?? '');
//     conferenceString = '+18152696442';

//     // new JsSIP.URI('sip', 'alice', 'atlanta.com', 5060, parameters, headers)

//     setInputText(conferenceString);
//     setAdditionalInfo({
//       type: 'conference',
//       conference: newValue.conference.doc,
//       number: newValue.conference.doc.conference_numbers[0],
//     });
//   };
//   useEffect(() => {
//     if (!showInput) {
//       setAdditionalInfo(null);
//     }
//   }, [showInput]);

//   const handleInputChange = newValue => {
//     setInputText(newValue);
//   };

//   const handleBackspace = () => {
//     setInputText(inputText.slice(0, inputText.length - 1));
//   };

//   const addNum = num => {
//     // emit click that child listens for
//     setInputText(inputText => `${inputText}${num}`);
//     inputRef.current?.focus && inputRef.current.focus();
//   };

//   useEffect(() => {
//     if (showInput) {
//       eventEmitter.on('add-num', addNum);
//       return () => {
//         eventEmitter.removeListener('add-num', addNum);
//       };
//     }
//   }, [showInput]);

//   // update parent when this one changes
//   useEffect(() => {
//     if (showInput) {
//       setDialText(inputText);
//     }
//   }, [inputText]);

//   // focus
//   useEffect(() => {
//     if (showInput && inputRef?.current?.focus) {
//       inputRef.current.focus();
//     }
//   }, [showInput]);

//   if (!showInput) {
//     return null;
//   }

//   return (
//     <>
//       <div style={{ flex: 1, padding: '4px 0' }}>
//         <div style={{ margin2: '7px 0 0px' }}>
//           <AutocompleteConference
//             inputRef={inputRef}
//             placeholder="Search Conferences"
//             label=""
//             inputValue={inputText}
//             onInputChange={handleInputChange}
//             // value={selectedConference}
//             onChange={handleChange}
//             autocompleteProps={{ freeSolo: true }}
//             inputProps={{ autoFocus: true }}
//           />
//         </div>
//       </div>
//       <div style={{ padding: '0 4px' }}>
//         <IconButton
//           onClick={handleBackspace}
//           size="small"
//           disabled={!inputText.length}
//         >
//           <BackspaceIcon />
//         </IconButton>
//       </div>
//     </>
//   );
// };

export default DialView;
