import { Button } from 'app/design';
import { useCreateAndAddExtensionDialog } from 'app/components/CreateAndAddExtensionDialog';
import { useScheduleEditorDialog } from 'app/components/ScheduleCreateDialog/ScheduleEditorDialog';
import * as React from 'react';

interface CreateScheduleButtonProps {
  onComplete: (scheduleId?: any) => void;
}

const CreateScheduleButton = ({ onComplete }: CreateScheduleButtonProps) => {
  const { toggleOpen, Dialog, DialogProps } = useScheduleEditorDialog({
    initialOpen: false,
  });

  return (
    <>
      <Dialog
        {...DialogProps}
        // userId={user.id}
        owner_type="general"
        onCancel={toggleOpen}
        onComplete={scheduleId => {
          onComplete(scheduleId);
          toggleOpen();
        }}
      />
      <Button variant={'contained'} onClick={toggleOpen}>
        Create a Schedule
      </Button>
    </>
  );
};

export default CreateScheduleButton;

// import { Button } from 'app/design';
// import { ELEMENT_TEMPORAL_RULE } from 'app/components/SchedulePlateEditor/components/TemporalRuleElement';
// import { useCreateSchedule } from 'app/hooks/mutations/schedule';
// import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
// import * as React from 'react';
// import { toast } from 'react-toastify';

// interface CreateScheduleButtonProps {
//   onComplete: () => void;
// }

// const CreateScheduleButton = ({ onComplete }: CreateScheduleButtonProps) => {
//   const createSchedule = useCreateSchedule();
//   const { data: authenticatedUser } = useAuthenticatedUserQuery();

//   const handleCreateSchedule = () => {
//     // dev test, TODO: dialog with required fields for Schedule creation
//     const createSchedulePromise = createSchedule.mutateAsync(
//       {
//         name: 'Schedule',
//         changed: true,
//         // owner_id: authenticatedUser?.id!,
//         // owner_type: 'user', // should be "menu" instead? ...that is where it is used, NOT owned??
//         type: 'schedule',
//         // is_account_default,
//         numbers: [`strategy:temp:${Date.now()}`],
//         flow: {
//           module: 'callflow',
//           data: { id: 'placeholder' },
//         },
//         metaflow: {},
//         strategy: {
//           type: 'schedule', // TODO: switch to a dedicated "schedule" handler like GenericDefault?
//           data: {
//             times: [
//               {
//                 id: 'open',
//                 name: 'Open',
//                 rule: {
//                   type: 'free_form_multiple', // template id like "single_day"
//                   data: {
//                     value: [
//                       {
//                         type: ELEMENT_TEMPORAL_RULE,
//                         children: [{ text: '' }],
//                       },
//                     ],
//                   }, // data for the rule (specific to the template!)
//                   outputRuleIds: [], // temporal_rules generated (just the ids)
//                 },
//               },
//               {
//                 id: 'lunch',
//                 name: 'Lunch',
//                 rule: {
//                   type: 'free_form_multiple', // template id like "single_day"
//                   data: {
//                     value: [
//                       {
//                         type: ELEMENT_TEMPORAL_RULE,
//                         children: [{ text: '' }],
//                       },
//                     ],
//                   }, // data for the rule (specific to the template!)
//                   outputRuleIds: [], // temporal_rules generated (just the ids)
//                 },
//               },
//               {
//                 isElse: true,
//                 id: 'skip',
//                 name: 'Closed',
//               },
//             ],
//           },
//         },
//       },
//       {
//         onSuccess: async resp => {
//           onComplete();
//           console.log('create Schedule resp', resp);
//         },
//         onError: error => console.error(error),
//       },
//     );

//     toast.promise(createSchedulePromise, {
//       pending: 'Creating Schedule...',
//       success: 'Schedule created!',
//       error: 'Error creating Schedule.',
//     });
//   };

//   return (
//     <Button variant={'contained'} onClick={handleCreateSchedule}>
//       Create Schedule (DEV)
//     </Button>
//   );
// };

// export default CreateScheduleButton;
