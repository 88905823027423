import React, { useEffect, useState } from 'react';

// import ReactNotification from 'react-web-notification';

// import { wrap, proxy, releaseProxy } from 'comlink';
// import Worker from '../../../../../../../../../WebWorkers/notification.worker.js';
// const worker = new Worker();
// const workerApi = wrap(worker);

// fix to use-window-focus to start with correct focus on first render
const useWindowFocus = () => {
  const [focused, setFocused] = useState(() => document.hasFocus()); // TODO: only do on first render!

  useEffect(() => {
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return focused;
};

function askNotificationPermission() {
  return new Promise((resolve, reject) => {
    function handlePermission(permission) {
      if (
        Notification.permission === 'denied' ||
        Notification.permission === 'default'
      ) {
        reject();
      } else {
        // notificationBtn.style.display = 'none';
        resolve(undefined);
      }
    }

    function checkNotificationPromise() {
      try {
        Notification.requestPermission().then();
      } catch (e) {
        return false;
      }
      return true;
    }

    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      console.log('This browser does not support notifications.');
      reject();
    } else {
      if (checkNotificationPromise()) {
        Notification.requestPermission().then(permission => {
          handlePermission(permission);
        });
      } else {
        Notification.requestPermission(function (permission) {
          handlePermission(permission);
        });
      }
    }
  });
}

const Notifications = ({ call }) => {
  const [config, setConfig] = useState(null);

  const [wasNotified, setWasNotified] = useState(false);

  const windowFocused = useWindowFocus();
  const isRinging = call?.isRinging ? call.isRinging() : null;

  // @ts-ignore
  useEffect(() => {
    // TODO: handle isDialing for outgoing calls w/ screenpops
    if (call.isRinging()) {
      // TODO: make sure window does NOT have focus?
      if (windowFocused) {
        // console.log('NOT showing cuz focused');
        return;
      }

      if (wasNotified) {
        // what about when closed and we want to re-emit? change in the return function where notification.close()?
        // should only close notification on windowFocused or done ringing, so might not need these...
        console.log('Already notified, not re-emitting notification');
        return false;
      }
      setWasNotified(true);

      // TODO: make sure permission is already granted!!
      askNotificationPermission();

      // window not focused, create Notification
      const newConfig = {
        title: `Incoming Call from ${call.remoteUser}`,
        options: {
          body: 'Accept or Reject Call',
          tag: call.getId(),
          // silent: true,
          requireInteraction: true,
          // actions: [ // // TODO: fix to using a service-worker for Actions
          //   {
          //     action: 'answer',
          //     title: 'Answer',
          //   },
          //   {
          //     action: 'reject',
          //     title: 'Reject',
          //   },
          //   // {
          //   //   action: 'ignore',
          //   //   title: 'Ignore',
          //   // },
          // ],
        },
      };

      const notification = new Notification(newConfig.title, newConfig.options);

      notification.onclick = function (event) {
        // @ts-ignore
        switch (event.action) {
          case 'answer':
            // const onAcceptCall = () => {
            //   // Auto Hold
            //   // Hold active call
            //   const activeCalls = calls.filter(
            //     (item) => item.isActive() && item.isOnLocalHold() === false
            //   );
            //   activeCalls.forEach((activeCall) => {
            //     activeCall.hold();
            //   });
            //   const startAudio = true,
            //     startVideo = false;
            //   call.accept(startAudio, startVideo); // accepting BOTH audio and video??
            // };

            break;
          case 'reject':
            break;
          case 'ignore':
            break;
          default:
            console.log('Was Clicked!', event);
            break;
        }
        window.focus();
        // TODO: mute ringing
        // call.m
        notification.close();
      };

      return () => {
        console.log('Closing notification');
        notification.close();
      };

      // // TODO: fix to using a service-worker for Actions
      // workerApi.showNotify(
      //   newConfig.title,
      //   newConfig.options,
      //   proxy(() => {
      //     console.log('DONE HERE123');
      //   })
      // );

      // if ('serviceWorker' in navigator) {
      //   navigator.serviceWorker.ready.then(function (
      //     registration
      //   ) {
      //     console.log(
      //       'A service worker is active:',
      //       registration.active
      //     );

      //     // At this point, you can call methods that require an active
      //     // service worker, like registration.pushManager.subscribe()
      //   });
      // } else {
      //   console.log('Service workers are not supported.');
      // }

      // const newConfig = {
      //   title: `Incoming Call from ${call.remoteUser}`,
      //   options: {
      //     body: 'Accept or Reject Call',
      //     tag: call.getId(),
      //     // silent: true,
      //     actions: [
      //       {
      //         action: 'answer',
      //         title: 'Answer',
      //       },
      //       {
      //         action: 'reject',
      //         title: 'Reject',
      //       },
      //       // {
      //       //   action: 'ignore',
      //       //   title: 'Ignore',
      //       // },
      //     ],
      //   },
      // };
      // const title = 'Incoming Call:';
      // var body = `Incoming Call from ${call.remoteUser}`;
      // var notification = window._notificationsServiceWorker.showNotification(
      //   newConfig.title,
      //   newConfig.options
      // );

      // setConfig(newConfig);
    }
  }, [isRinging, windowFocused]);

  // console.log('isRinging:', call?.isRinging());
  return null;

  // if (!call.isRinging() || !config) {
  //   return;
  // }

  // return   (
  //   <Notification
  //     timeout={30000}
  //     title={config.title}
  //     options={config.options}
  //     swRegistration={true}
  //   />
  // );
};

export default Notifications;
