import React from 'react';
import { useToggleReducer } from 'app/utilities';
import { User } from 'types/user';
import { Button, IconButton } from 'app/design';
import { Edit as EditIcon, Add as AddIcon } from 'app/design/icons-material';

import { useIntegrationAddDialog } from 'app/components/IntegrationAddDialog';

interface AddIntegrationButtonProps {
  onComplete?: (data: any) => void;
  navigateOnComplete?: boolean;
}

const AddIntegrationButton = ({ onComplete }: AddIntegrationButtonProps) => {
  const {
    toggleOpen: toggleOpenIntegrationAddDialog,
    Dialog: IntegrationAddDialog,
    DialogProps: IntegrationAddDialogProps,
  } = useIntegrationAddDialog({ initialOpen: false });

  const handleComplete = val => {
    toggleOpenIntegrationAddDialog();
    onComplete && onComplete(val);
  };

  return (
    <>
      <IntegrationAddDialog
        {...IntegrationAddDialogProps}
        // formDefaults={{ setDefault: !paymentMethodCards?.length }}
        onComplete={handleComplete}
      />
      <Button
        size="small"
        variant="contained"
        onClick={toggleOpenIntegrationAddDialog}
        startIcon={<AddIcon />}
        // disabled
      >
        Add Telecom Integration
      </Button>
    </>
  );
};

export default AddIntegrationButton;
