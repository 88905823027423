import { Button } from 'app/design';
import { GroupCreateDialog } from 'app/components/GroupCreateDialog';
import { useCreateGroup } from 'app/hooks/mutations/group/useCreateGroup';
import * as React from 'react';
import { toast } from 'react-toastify';
import { useToggleReducer } from 'app/utilities';

interface CreateGroupButtonProps {
  onComplete?: (groupId?: any) => void;
  navigateOnComplete?: boolean;
}

const CreateGroupButton = ({
  onComplete,
  navigateOnComplete,
}: CreateGroupButtonProps) => {
  const [showDialog, toggleShowDialog] = useToggleReducer(false);

  return (
    <>
      {showDialog ? (
        <GroupCreateDialog
          // userId={user.id}
          onComplete={onComplete}
          navigateOnComplete={navigateOnComplete}
          onCancel={toggleShowDialog}
        />
      ) : null}
      <Button variant={'contained'} onClick={toggleShowDialog}>
        Create Group
      </Button>
    </>
  );
};

export default CreateGroupButton;
