import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  FormControlLabel,
  InfoTooltip,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField,
  Typography,
} from 'app/design';
import { Check as CheckIcon } from 'app/design/icons-material';
import { useUpdateDevicePartial } from 'app/hooks/mutations/device';
import { useDeviceQuery } from 'app/hooks/queries/device';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { DeviceMedia } from 'types/device';
import { PhoneNumber } from 'types/phoneNumber';
import { useImmer } from 'use-immer';

// interface declaring which props are required/allowed
interface DeviceCodecsDialogProps {
  deviceId: string;
  onCancel: () => void;
  onComplete: () => void;
}

const DeviceCodecsDialog = ({
  deviceId,
  onCancel,
  onComplete,
}: DeviceCodecsDialogProps) => {
  const { data: device, isLoading: deviceIsLoading } = useDeviceQuery(
    deviceId,
    {
      onSuccess: device => {
        setFormDefaults(device.doc.media);
      },
    },
  );

  const updateDevice = useUpdateDevicePartial();
  const [formDefaults, setFormDefaults] = useImmer<DeviceMedia | undefined>(
    device?.doc?.media,
  );

  const handleSubmit = async (deviceForm: DeviceMedia) => {
    // update form defaults in case of error
    setFormDefaults(deviceForm);

    const updateDevicePromise = updateDevice.mutateAsync(
      {
        id: deviceId,
        media: deviceForm,
      },
      {
        onSuccess: () => {
          onComplete();
        },
      },
    );

    // resolve promise with toast notifications
    toast.promise(updateDevicePromise, {
      pending: 'Updating device codecs...',
      error: 'Failed to update device codecs.',
      success: 'Device codecs updated!',
    });
  };

  return (
    <Dialog open={true} fullWidth maxWidth={'xs'}>
      {deviceIsLoading ? (
        <DialogInProgress title={'Loading device codecs info...'} />
      ) : updateDevice.isLoading ? (
        <DialogInProgress title={'Updating Device codecs...'} />
      ) : (
        <>
          <DialogTitle>Edit Device Codecs</DialogTitle>
          <Divider />
          {device ? (
            <DeviceCodecsForm
              defaultFormValues={formDefaults}
              onCancel={onCancel}
              onSubmit={handleSubmit}
              errorMessage={
                updateDevice.error
                  ? `${updateDevice.error.message} ${
                      updateDevice.error.response
                        ? `: ${updateDevice.error.response?.statusText}`
                        : ''
                    }`
                  : undefined
              }
            />
          ) : (
            <>
              <DialogContent>
                <Typography color={'error'}>Invalid Device ID.</Typography>
              </DialogContent>
              <Divider />
              <DialogActions sx={{ justifyContent: 'left' }}>
                <Button variant={'outlined'} color={'error'} onClick={onCancel}>
                  Cancel
                </Button>
              </DialogActions>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};

interface DeviceCodecsFormProps {
  defaultFormValues?: DeviceMedia;
  initialExternalNumber?: PhoneNumber;
  onCancel: () => void;
  onSubmit: (formValues: DeviceMedia) => void;
  errorMessage?: string;
  startDirty?: boolean;
}

// TODO: codec values?
const defaultAudioList = [
  { value: 'CELT@32000h' }, //codecsI18n.audio['CELT@64000h'],
  { value: 'G722' }, //codecsI18n.audio.G722,
  { value: 'G729' }, //codecsI18n.audio.G729,
  { value: 'G7221@16000h' }, //codecsI18n.audio['G7221@16000h'],
  { value: 'G7221@32000h' }, //codecsI18n.audio['G7221@32000h'],
  { value: 'GSM' }, //codecsI18n.audio.GSM,
  { value: 'OPUS' }, //codecsI18n.audio.OPUS,
  { value: 'PCMA' }, //codecsI18n.audio.PCMA,
  { value: 'PCMU' }, //codecsI18n.audio.PCMU,
  { value: 'speex@16000h' }, //codecsI18n.audio['speex@16000h'],
  { value: 'speex@32000h' }, //codecsI18n.audio['speex@32000h'],
];

const defaultVideoList = [
  { value: 'H261' }, //codecsI18n.video.H261,
  { value: 'H263' }, //codecsI18n.video.H263,
  { value: 'H264' }, //codecsI18n.video.H264,
  { value: 'VP8' }, //codecsI18n.video.VP8,
];

const DeviceCodecsForm = ({
  defaultFormValues,
  onCancel,
  onSubmit,
  errorMessage,
  startDirty = false,
}: DeviceCodecsFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useForm<DeviceMedia>({
    defaultValues: defaultFormValues,
  });
  const formWatch = watch();

  // @ts-ignore
  const audioCodecs = watch('audio.codecs', []);
  // @ts-ignore
  const videoCodecs = watch('video.codecs', []);

  return (
    <>
      <DialogContent>
        {/* audio codecs */}
        <TextField
          fullWidth
          variant="outlined"
          select
          SelectProps={{
            multiple: true,
            dense: true,
            renderValue: value => {
              return value?.join(', ');
            },
          }}
          {...register('audio.codecs')}
          defaultValue={defaultFormValues?.audio?.codecs ?? []}
          label={'Audio Codecs'}
        >
          {defaultAudioList.map(option => (
            <MenuItem value={option.value} key={option.value}>
              <ListItemIcon>
                {audioCodecs.includes(option.value) ? (
                  <CheckIcon fontSize="small" />
                ) : null}
              </ListItemIcon>
              <ListItemText>{option.value}</ListItemText>
            </MenuItem>
          ))}
        </TextField>
        <br />
        <br />
        {/* video codecs */}
        <TextField
          fullWidth
          variant="outlined"
          select
          SelectProps={{
            multiple: true,
            dense: true,
            renderValue: value => {
              return value?.join(', ');
            },
          }}
          {...register('video.codecs')}
          defaultValue={defaultFormValues?.video?.codecs ?? []}
          label={'Video Codecs'}
        >
          {defaultVideoList.map(option => (
            <MenuItem value={option.value} key={option.value}>
              <ListItemIcon>
                {videoCodecs.includes(option.value) ? (
                  <CheckIcon fontSize="small" />
                ) : null}
              </ListItemIcon>
              <ListItemText>{option.value}</ListItemText>
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button variant={'outlined'} color={'error'} onClick={onCancel}>
          Cancel
        </Button>
        {errorMessage ? (
          <Typography color={'error'}>{errorMessage}</Typography>
        ) : null}
        <Button
          variant={'outlined'}
          color={'success'}
          disabled={startDirty ? !startDirty : !isDirty}
          onClick={handleSubmit(onSubmit)}
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default DeviceCodecsDialog;
