import { Chip } from 'app/design';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { QuickFinderExtensionDialog } from 'app/components/QuickFinderExtensionDialog';
import { useUpdateVmboxPartial } from 'app/hooks/mutations/vmbox';
import { useListExtensionsQuery } from 'app/hooks/queries/extension';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { toast } from 'react-toastify';
import { Vmbox } from 'types/vmbox';

interface GreetingVmboxProps {
  vmbox: Vmbox;
  onComplete: () => void;
}

const OperatorExtVmbox = ({ vmbox, onComplete }: GreetingVmboxProps) => {
  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);
  const updateVmbox = useUpdateVmboxPartial();
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'operatorExt',
    options: [
      {
        label: 'Operator Extension',
        value: 'operatorExt',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const operatorExt = vmbox.doc.operator_number ?? '0';
  const { data: extensionsResp, isLoading: extIsLoading } =
    useListExtensionsQuery({
      take: 1,
      filters: {
        context: {
          numbersQuoted: [operatorExt],
        },
      },
    });

  const [extension] = extensionsResp?.extensions ?? [];

  const handleSelect = (selected: string[] | null[]) => {
    const [ext] = selected;
    const promise = updateVmbox.mutateAsync(
      {
        id: vmbox.id,
        operator_number: ext,
      },
      {
        onSuccess: () => {
          onComplete();
          toggleShowEditDialog();
        },
      },
    );

    toast.promise(promise, {
      pending: 'Updating operator extension...',
      success: 'Operator extension updated!',
      error: 'Error updating operator extension.',
    });
  };

  const handleClear = () => {
    handleSelect([null]);
  };

  return (
    // @ts-ignore
    <div ref={ref}>
      <HighlightPulse {...highlightPulseProps} />
      {showEditDialog ? (
        <QuickFinderExtensionDialog
          initialSelected={[operatorExt]}
          onClear={handleClear}
          onSelect={handleSelect}
          onCancel={toggleShowEditDialog}
          selectionTitle={operatorExt}
          mutationLoadingLabel={'Updating operator extension...'}
          mutationLoading={updateVmbox.isLoading}
        />
      ) : null}
      <GenericSettingsCardField
        title={`Operator Ext`}
        chip={
          // if operator extension does not exist
          vmbox.doc.operator_number && !extension && !extIsLoading ? (
            <Chip
              label={'invalid ext'}
              color={'warning'}
              size={'small'}
              variant={'contained'}
            />
          ) : // is using default
          operatorExt === '0' && !extIsLoading ? (
            extension ? (
              // ext exists, inheriting from account
              <Chip
                label={'Account'}
                color={'primary'}
                size={'small'}
                variant={'contained'}
              />
            ) : (
              // no ext '0' exists, needs set up
              <Chip
                label={'Account operator ext. has not been setup'}
                color={'warning'}
                size={'small'}
                variant={'contained'}
              />
            )
          ) : null
        }
        editOnClick={toggleShowEditDialog}
        content={
          extIsLoading
            ? 'Loading'
            : extension
            ? `${extension?.number}
                - ${extension.callflow.doc.name}`
            : '0'
        }
      />
    </div>
  );
};

export default OperatorExtVmbox;
