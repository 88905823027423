import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  Paper,
  Link,
} from 'app/design';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from 'app/design/icons-material';
import { useCreateDirectory } from 'app/hooks/mutations/directory';
import { useToggleReducer } from 'app/utilities';
import Joi from 'joi';
import { pickBy } from 'lodash';
import { useEffect } from 'react';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Directory } from 'types/directory';
import { useImmer } from 'use-immer';

import { sdk } from 'app/sdk';
import { store } from '../../../store';

import copy from 'copy-to-clipboard';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

// schema for form validation. Passed to useForm to only trigger submit when
//  - the below conditions are met. Any known serverside constraints (min/max,
//  - character limits, numbers only, etc.) should be added. Remove 128 max below
//  - to demonstrate serverside invalidation and handling
const schema = Joi.object({
  name: Joi.string().max(128).required(),
});

interface TrialDialogForm {
  name: string;
}

// interface declaring which props are required/allowed
interface TrialDialogProps {
  // directoryId: string;
  open?: boolean;
  onCancel: () => void;
  // onComplete: () => void;
}

const TrialDialog = ({
  // directoryId,
  open,
  onCancel,
}: // onComplete,
TrialDialogProps) => {
  // const {
  //   data: directory,
  //   isLoading: directoryIsLoading,
  //   refetch: refetchDirectory,
  // } = useDirectoryQuery(directoryId, {
  //   onSuccess: directory => {
  //     setFormDefaults({
  //       name: '',
  //     });
  //   },
  // });
  const router = useHistory();
  const createDirectory = useCreateDirectory();
  const [formDefaults, setFormDefaults] = useImmer<TrialDialogForm>({
    name: '',
  });

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (directoryForm: TrialDialogForm) => {
    // // update form defaults if mutation fails
    // setFormDefaults(directoryForm);
    // // "sanitize" and remove any unset values (empty strings)
    // // - so that they are not sent to server.
    // // - ex: An empty string would override the existing password(?)
    // // - no current way to unset password
    // const sanitizedFormData = pickBy(directoryForm, value => !!value?.length);
    // const createDirectoryPromise = createDirectory.mutateAsync({
    //   name: sanitizedFormData.name ?? '',
    // });
    // toast.promise(createDirectoryPromise, {
    //   pending: 'Creating directory...',
    //   success: 'Directory created!',
    //   error: 'Error creating directory.',
    // });
    // const resp = await createDirectoryPromise;
    // if (resp.status === 'success') {
    //   // redirect to Directory
    //   router.push(`/admin/directories/view/${resp.data.id}`);
    // }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.log('stripe not loaded');
      return;
    }

    console.log('elements:', elements);

    // alert('after payment');

    // get token for card (to apply to customer)
    const { token, error } = await stripe.createToken(
      // @ts-ignore
      elements.getElement(CardElement),
    );
    console.log('token:', token);
    if (error) {
      console.error('card error:', error);
      return;
    }

    const result = await sdk.stripe.mutate.newCustomer(
      {
        data: { token },
      },
      { authToken: store.getState().auth.auth_token },
    );

    console.log('Stripe Response:', result);
    onCancel();

    // mutation to stripeNewCustomer
    // - updates Account with new Customer Card
    //   - create new Customer (if not exists on Stripe, search by account_id)

    // const result = await stripe.confirmPayment({
    //   //`Elements` instance that was used to create the Payment Element
    //   elements,
    //   confirmParams: {
    //     return_url: 'https://my-site.com/order/123/complete',
    //   },
    // });

    // if (result.error) {
    //   // Show error to your customer (for example, payment details incomplete)
    //   console.log(result.error.message);
    // } else {
    //   // Your customer will be redirected to your `return_url`. For some payment
    //   // methods like iDEAL, your customer will be redirected to an intermediate
    //   // site first to authorize the payment, then redirected to the `return_url`.
    // }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    // @ts-ignore
    <Dialog open={open} fullWidth maxWidth={'sm'} scroll="body">
      {createDirectory.isLoading ? (
        <DialogInProgress title={'Creating Directory...'} />
      ) : (
        <>
          {/* <DialogTitle>Create Directory</DialogTitle>
          <Divider /> */}
          <TrialDialogForm
            formDefaults={formDefaults}
            startDirty={createDirectory.isError}
            onCancel={onCancel}
            onSubmit={handleSubmit}
            errorMessage={
              // @ts-ignore
              createDirectory.error
                ? // @ts-ignore
                  `${createDirectory.error.message} ${
                    // @ts-ignore
                    createDirectory.error.response
                      ? // @ts-ignore
                        `: ${createDirectory.error.response?.statusText}`
                      : ''
                  }`
                : undefined
            }
          />
        </>
      )}
    </Dialog>
  );
};

interface TrialDialogFormProps {
  formDefaults: TrialDialogForm;
  onCancel: () => void;
  onSubmit: (form: TrialDialogForm) => void;
  errorMessage?: string;
  startDirty?: boolean;
}

const TrialDialogForm = ({
  formDefaults,
  onCancel,
  onSubmit,
  errorMessage,
  startDirty = false,
}: TrialDialogFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty },
    setError,
    watch,
  } = useForm<TrialDialogForm>({
    defaultValues: formDefaults,
    resolver: joiResolver(schema), // pass in custom validation
  });

  return (
    <>
      <DialogContent>
        <div>
          <Typography variant="h2">Trial Mode</Typography>
          <Typography variant="body1">
            <p>- only domestic calls</p>
            <p>- limit to 3 minutes per call</p>
            <p>- limited concurrent calls</p>
            <p>- pre-roll audio on incoming calls</p>
            <p>- no 911 calls (you need an address)</p>
            <p>- expires 30 days after trial start</p>
          </Typography>
          <Divider />
          <br />
          <Typography variant="h2">Activate Now!</Typography>
          <Typography variant="body1">
            <p>- $10 in credit, never expires</p>
          </Typography>

          <TextField
            label={'Name'}
            {...register('name')}
            error={!!formErrors.name}
            helperText={formErrors.name?.message?.replace('"name"', 'Name')}
            size="small"
          />
          <br />
          <br />
          <Paper variant="outlined" sx={{ padding: 1 }}>
            <CardElement />
          </Paper>
          {/* <br />
          <Paper variant="outlined" sx={{ padding: 1 }}>
            <Typography variant="body2">
              Success:{' '}
              <Link onClick={e => copy('4242424242424242')}>
                4242 4242 4242 4242
              </Link>
            </Typography>
            <Typography variant="body2">
              Fail:{' '}
              <Link onClick={e => copy('4040404040404040')}>
                4040 4040 4040 4040
              </Link>
            </Typography>
          </Paper> */}
        </div>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button variant={'outlined'} color={'error'} onClick={onCancel}>
          Cancel
        </Button>
        {errorMessage ? (
          <Typography color={'error'}>{errorMessage}</Typography>
        ) : null}
        <Button
          variant={'outlined'}
          color={'success'}
          disabled={startDirty ? !startDirty : !isDirty}
          onClick={handleSubmit(onSubmit)}
        >
          Activate Account
        </Button>
      </DialogActions>
    </>
  );
};

export const useTrialDialog = ({}) => {
  const [open, toggleOpen] = useToggleReducer();

  const TrialDialogProps = {
    open,
    onCancel: toggleOpen,
  };

  return {
    toggleOpen,
    TrialDialog,
    TrialDialogProps,
  };
};

export default TrialDialog;
