import { joiResolver } from '@hookform/resolvers/joi';
import { usePlate, usePlateId, usePlateStore } from '@udecode/plate';
import {
  Button,
  Dialog,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Grid,
  Typography,
} from 'app/design';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import EditScheduleDialog from 'app/components/EditScheduleDialog/EditScheduleDialog';
import { ScheduleStructureEditor } from 'app/components/ScheduleCreateDialog/components/ScheduleStructureEditor';
import { ScheduleTemplateEditor } from 'app/components/ScheduleCreateDialog/components/ScheduleTemplateEditor';
import { ScheduleTemplateSelect } from 'app/components/ScheduleCreateDialog/components/ScheduleTemplateSelect';
import {
  SCHEDULE_TEMPLATES,
  ScheduleTemplate,
} from 'app/components/ScheduleCreateDialog/scheduleTemplates';
import {
  useCreateSchedule,
  useUpdateSchedulePartial,
} from 'app/hooks/mutations/schedule';
import { useCallflowQuery } from 'app/hooks/queries/callflow';
import { DialogBuilder, useToggleReducer } from 'app/utilities';
import Joi from 'joi';
import { cloneDeep } from 'lodash';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useImmer } from 'use-immer';
import { DialogActions } from 'app/design';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// schema for form validation. Passed to useForm to only trigger submit when
//  - the below conditions are met. Any known serverside constraints (min/max,
//  - character limits, numbers only, etc.) should be added. Remove 128 max below
//  - to demonstrate serverside invalidation and handling
const schema = Joi.object({
  name: Joi.string().max(128).required(),
  template: Joi.string().required(),
  times: Joi.array().required(),
  timezone: Joi.string().optional(),
});

export interface ScheduleCreateForm {
  name: string;
  template: ScheduleTemplate;
  times: any[];
  timezone?: string;
}

// interface declaring which props are required/allowed
interface IvrDialogProps {
  scheduleId?: string;
  name?: string; // for create
  owner_id?: string;
  owner_type?: string;
  onCancel: () => void;
  onComplete: (scheduleId?: string) => void;
}

const ScheduleEditorDialog = ({
  scheduleId,
  name,
  owner_id,
  owner_type,
  onCancel,
  onComplete,
}: IvrDialogProps) => {
  const [isEditMode, SetEditMode] = useImmer(!!scheduleId);
  const [editStructure, toggleEditStructure] = useToggleReducer(false);

  const formMethods = useForm<ScheduleCreateForm>({
    defaultValues: {
      name: name ?? 'Schedule',
      template: ScheduleTemplate.Restaurant,
      times: SCHEDULE_TEMPLATES[ScheduleTemplate.Restaurant].value,
    },
    resolver: joiResolver(schema), // pass in custom validation
  });

  const {
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = formMethods;

  const {
    data: schedule,
    isLoading: scheduleIsLoading,
    error: scheduleError,
  } = useCallflowQuery({
    id: scheduleId,
    options: {
      onSuccess: callflow => {
        reset({
          name: callflow.doc.name,
          template:
            callflow.doc.strategy.data.template ?? ScheduleTemplate.Restaurant,
          times: callflow.doc.strategy.data.times,
          timezone: callflow.doc.strategy.data.timezone,
        });
      },
    },
  });

  const createSchedule = useCreateSchedule();
  const updateSchedule = useUpdateSchedulePartial();

  const onSubmit = async (scheduleForm: ScheduleCreateForm) => {
    if (scheduleId) {
      const updateSchedulePromise = updateSchedule.mutateAsync({
        id: scheduleId,
        name: scheduleForm.name ?? '',
        owner_id,
        owner_type,
        strategy: {
          ...schedule?.doc.strategy,
          data: {
            ...schedule?.doc.strategy.data,
            timezone: scheduleForm.timezone,
            template: scheduleForm.template,
            times: cloneDeep(scheduleForm.times),
          },
        },
      });

      toast.promise(updateSchedulePromise, {
        pending: 'Updating Schedule...',
        success: 'Schedule updated!',
        error: 'Error updating Schedule',
      });

      const resp = await updateSchedulePromise;
      if (resp.success === true) {
        // redirect to Group
        // router.push(`/admin/schedule/edit/${resp.data.id}`);
        onComplete(resp.data.id);
      }
    } else {
      // @ts-ignore
      const createSchedulePromise = createSchedule.mutateAsync({
        name: scheduleForm.name ?? '',
        // owner_id: authenticatedUser?.id!,
        // owner_type: 'user', // should be "menu" instead? ...that is where it is used, NOT owned??
        type: 'schedule',
        owner_id,
        owner_type,
        // is_account_default,
        numbers: [],
        flow: {
          module: 'callflow',
          data: { id: 'placeholder' },
        },
        metaflow: {},
        strategy: {
          type: 'schedule', // TODO: switch to a dedicated "schedule" handler like GenericDefault?
          data: {
            timezone: scheduleForm.timezone,
            template: scheduleForm.template,
            times: cloneDeep(scheduleForm.times),
          },
        },
      });

      toast.promise(createSchedulePromise, {
        pending: 'Creating Schedule...',
        success: 'Schedule created!',
        error: 'Error creating Schedule',
      });

      const resp = await createSchedulePromise;
      if (resp.success === true) {
        // redirect to Group
        // router.push(`/admin/schedule/edit/${resp.data.id}`);
        onComplete(resp.data.id);
      }
    }
  };

  const handleSave = () => {
    if (isEditMode) {
      handleSubmit(onSubmit)();
    } else {
      SetEditMode(true);
    }
  };

  const handleCancel = () => {
    // if (isEditMode) {
    //   SetEditMode(false);
    // } else {
    onCancel();
    // }
  };

  const handleChangeTemplate = () => {
    SetEditMode(false);
  };

  const errorLoadingSchedule = scheduleError || (!schedule && scheduleId);

  return (
    <Dialog open={true} fullWidth maxWidth={'md'}>
      {createSchedule.isLoading ||
      updateSchedule.isLoading ||
      scheduleIsLoading ? (
        <DialogInProgress
          title={
            <>
              {scheduleIsLoading ? (
                'Loading schedule...'
              ) : scheduleId ? (
                'Updating Schedule...'
              ) : (
                <>
                  <Typography variant="h6">
                    One moment while we create your schedule...
                  </Typography>
                </>
              )}
            </>
          }
        />
      ) : (
        <>
          {!scheduleId && isEditMode && !editStructure ? (
            <DialogTitle>
              <Grid container alignItems={'center'}>
                {/* <Grid sx={{ flex: 1 }} item>
                    {isEditMode ? 'Edit Schedule' : 'Choose your schedule'}
                  </Grid> */}
                {!scheduleId && isEditMode ? (
                  <Grid item>
                    <Button size={'small'} onClick={handleChangeTemplate}>
                      <ArrowBackIcon />
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </DialogTitle>
          ) : null}
          <DialogContent dividers>
            {errorLoadingSchedule ? (
              <Typography color={'error'}>Error loading schedule.</Typography>
            ) : (
              <>
                {createSchedule.error || updateSchedule.error ? (
                  <Typography color={'error'}>
                    Error saving schedule.
                  </Typography>
                ) : null}
                <FormProvider {...formMethods}>
                  {isEditMode ? (
                    editStructure ? (
                      <ScheduleStructureEditor />
                    ) : (
                      <ScheduleTemplateEditor />
                    )
                  ) : (
                    <ScheduleTemplateSelect />
                  )}
                </FormProvider>
              </>
            )}
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            {isEditMode && editStructure ? null : (
              <Button
                variant={'outlined'}
                color={'error'}
                onClick={handleCancel}
              >
                {'Cancel'}
              </Button>
            )}
            {isEditMode ? (
              <Button onClick={toggleEditStructure} color={'secondary'}>
                {editStructure ? (
                  <>
                    <ArrowBackIcon />
                    Done Editing Categories
                  </>
                ) : (
                  'Add/Remove/Rename Categories'
                )}
              </Button>
            ) : null}
            {isEditMode &&
            editStructure ? null : errorLoadingSchedule ? null : (
              <Button
                onClick={handleSave}
                variant={'contained'}
                color={'success'}
              >
                {isEditMode
                  ? `${scheduleId ? 'Update' : 'Create'} Schedule`
                  : 'Next'}
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export const useScheduleEditorDialog = DialogBuilder(ScheduleEditorDialog);

export default ScheduleEditorDialog;
