import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { useMutation } from 'react-query';
import { VmboxCreateDoc } from 'types/vmbox';

export const useCreateVmbox = () => {
  const authState = useAuthSelector();
  return useMutation((vmboxCreateDoc: VmboxCreateDoc) =>
    sdk.vmbox.mutate.create(vmboxCreateDoc, authState?.auth_token),
  );
};
