import { IconButton, Menu, MenuItem } from 'app/design';
import { MoreVert as MoreVertIcon } from 'app/design/icons-material';
import React, { ReactNode } from 'react';

interface OptionsCellProps {
  options: { label: string; icon?: ReactNode; onClick: () => void }[];
}

const OptionsTableCell = ({ options }: OptionsCellProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleShowOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = onClick => () => {
    onClick();
    handleClose();
  };

  return (
    <>
      <IconButton
        onClick={handleShowOptions}
        id="user-list-options-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        size={'large'}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map(({ onClick, label, icon }, index) => (
          <MenuItem key={index} onClick={handleClick(onClick)}>
            {icon ?? ''}
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default OptionsTableCell;
