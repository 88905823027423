import { useMutation } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { MediaUploadPayload } from 'types/media';

export const useUploadMedia = () => {
  const authState = useAuthSelector();

  return useMutation(async (mediaUploadPayload: MediaUploadPayload) =>
    sdk.media.mutate.upload(mediaUploadPayload, authState?.auth_token),
  );
};
