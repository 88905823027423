import React, { useEffect, useCallback, useState, useRef } from 'react';
import useStyles from './styles';
import {
  Box,
  Grid,
  Portal,
  IconButton,
  Slider,
  Typography,
  Button,
} from 'app/design';

import {
  Pause as PauseIcon,
  PhonePausedOutlined as CallOnHoldIcon,
  Mic as MuteIcon,
  MicOff as UnMuteIcon,
  CallEnd as CallEndIcon,
  VolumeUp as VolumeUpIcon,
  Close as CloseIcon,
  ScreenShareOutlined as ScreenShareIcon,
  StopScreenShareOutlined as ScreenShareActiveIcon,
  FastForward as FastForwardIcon,
  LocalParking as LocalParkingIcon,
} from 'app/design/icons-material';

import { useWebphoneContext } from '../../Store';

import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

const VideoFullView = props => {
  const { call } = props;
  const classes = useStyles();

  const onToggleHold = () => {
    call.toggleHold();
  };
  const onToggleMute = () => {
    call.toggleAudioMute();
  };

  return (
    <Portal container={document.body}>
      {call && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '84%',
            height: '100vh',
            background: 'rgba(252,252,252,0.95)',
            zIndex: 2000, // higher than the Webphone Drawer!
            // color: 'white',
            display: 'block',
          }}
        >
          <Box
            style={{ position: 'absolute', left: 10, top: 10, zIndex: 2020 }}
          >
            <IconButton onClick={() => props.onStop(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <div
            style={{
              height: '100%',
              display: 'flex',
            }}
          >
            <Box sx={classes.flexRow}>
              <div style={{ flex: 1, position: 'relative' }}>
                <LocalVideoContainer {...props} />
                <RemoteVideoContainer {...props} />
                <Box
                  style={{
                    position: 'absolute',
                    bottom: 80,
                    width: '100%',
                    zIndex: '2015',
                  }}
                >
                  <Grid
                    container
                    alignItems={'center'}
                    justify={'center'}
                    spacing={4}
                  >
                    <Grid item>
                      <IconButton
                        sx={classes.controlButton}
                        onClick={onToggleMute}
                      >
                        {call.isAudioOnMute() ? <UnMuteIcon /> : <MuteIcon />}
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        sx={classes.controlButton}
                        onClick={onToggleHold}
                      >
                        {call.isOnLocalHold() ? (
                          <CallOnHoldIcon />
                        ) : (
                          <PauseIcon />
                        )}
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        color="error"
                        variant={'contained'}
                        onClick={() => props.onStop(true)}
                      >
                        <CallEndIcon />
                      </IconButton>
                    </Grid>
                    <BlindTransfer call={call} />
                    <Park call={call} />
                    <ScreenShare call={call} />
                  </Grid>
                </Box>
                <Box
                  style={{
                    position: 'absolute',
                    bottom: 80,
                    right: '12%',
                    zIndex: 2015,
                  }}
                >
                  <SpkVolume call={call} />
                </Box>
              </div>
            </Box>
          </div>
        </div>
      )}
    </Portal>
  );
};

const BlindTransfer = props => {
  const { call } = props;

  const classes = useStyles();

  const handleTransfer = () => {
    const destination = window.prompt('Transfer To:', '');
    if (!destination) {
      return false;
    }
    call.blindTransfer(destination);
  };

  return (
    <Grid item>
      <IconButton sx={classes.controlButton} onClick={handleTransfer}>
        <FastForwardIcon fontSize="small" />
      </IconButton>
    </Grid>
  );
};

const ScreenShare = props => {
  const { call } = props;

  const classes = useStyles();
  const onToggle = () => {
    call.toggleScreenShare();
  };

  return (
    <Grid item>
      <IconButton sx={classes.controlButton} onClick={onToggle}>
        {call.isScreenShareOn() ? (
          <ScreenShareActiveIcon />
        ) : (
          <ScreenShareIcon />
        )}
      </IconButton>
    </Grid>
  );
};

const Park = props => {
  const { call } = props;

  const classes = useStyles();

  const handlePark = () => {
    const parkNum = window.prompt('Park:', '');
    if (!parkNum) {
      return false;
    }
    call.parkCall(`*3${parkNum}`);
  };

  return (
    <Grid item>
      <IconButton sx={classes.controlButton} onClick={handlePark}>
        <LocalParkingIcon fontSize="small" />
      </IconButton>
    </Grid>
  );
};

const SpkVolume = props => {
  const { call } = props;
  const speakerVol = call.getOutputVolume();
  const [volume, setVolume] = useState(speakerVol * 100);

  const handleChange = val => {
    setVolume(val);
    call.changeOutputVolume(val / 100);
  };

  return (
    <div>
      <div
        style={{
          height: 200,
          padding: '24px 12px 40px',
          textAlign: 'center',
          background: 'rgba(240, 240, 240, 0.4)',
          borderRadius: '15px',
        }}
      >
        <Slider
          orientation="vertical"
          value={volume}
          onChange={(e, num) => handleChange(num)}
        />
        <br />
        <Typography variant="caption">
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <VolumeUpIcon fontSize="small" />
            <span style={{ paddingLeft: '5px' }}>{volume}%</span>
          </Box>
        </Typography>
      </div>
    </div>
  );
};

const LocalVideoContainer = props => {
  const { call } = props;

  const [state, dispatch] = useWebphoneContext();

  const classes = useStyles({
    showLocalVideo: call?.hasLocalVideo(),
  });

  const handleEnableVideo = () => {
    call.offerVideo();
  };

  const handleToggleMute = () => {
    call.toggleVideoMute();
  };

  return (
    <Box sx={classes.fvLocalVideo}>
      <video
        autoPlay
        ref={node => {
          if (node && call) {
            node.srcObject = call.getInputMediaStream();
          }
        }}
      />
      {call &&
        (call.hasLocalVideo() ? (
          //  perhaps have a "stop video" button?
          call.isVideoOnMute() ? (
            <Button size="small" variant="outlined" onClick={handleToggleMute}>
              Unmute
            </Button>
          ) : (
            <Button size="small" variant="outlined" onClick={handleToggleMute}>
              Mute
            </Button>
          )
        ) : (
          <>
            <Typography variant="body2" style={{ color: 'white' }}>
              Video Disabled
            </Typography>
            <br />
            <Button size="small" variant="outlined" onClick={handleEnableVideo}>
              Send Video
            </Button>
          </>
        ))}
    </Box>
  );
};

const RemoteVideoContainer = props => {
  const { call } = props;

  const [state, dispatch] = useWebphoneContext();

  const handleToggleMuteVideoIn = () => {
    window.alert(
      'should be able to hide/deafen/mute the remote/incoming video',
    );
    // call.toggleVideoMute();
  };

  const classes = useStyles({
    showRemoteVideo: call?.hasRemoteVideo(),
  });

  return (
    <Box sx={classes.fvRemoteVideo}>
      <video
        autoPlay
        ref={node => {
          if (node && call) {
            node.srcObject = call.getOutputMediaStream();
          }
        }}
      />
    </Box>
  );
};

export default VideoFullView;
