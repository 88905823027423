import { joiResolver } from '@hookform/resolvers/joi';
import { AddIntegrationButton } from 'app/components/AddIntegrationButton';

import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from 'app/design';
import { useBuyPhoneNumber } from 'app/hooks/mutations/phoneNumbers';
import { sdk } from 'app/sdk';
import { DialogBuilder, Feature } from 'app/utilities';
import Joi from 'joi';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useImmer } from 'use-immer';
import { store } from 'store';

// schema for form validation. Passed to useForm to only trigger submit when
//  - the below conditions are met. Any known serverside constraints (min/max,
//  - character limits, numbers only, etc.) should be added. Remove 128 max below
//  - to demonstrate serverside invalidation and handling
const schema = Joi.object({
  prefix: Joi.string(),
  ptn: Joi.string(),
});

interface BuyPhoneNumberForm {
  prefix?: string | null;
  ptn: string | null;
}

// interface declaring which props are required/allowed
interface BuyPhoneNumberDialogProps {
  // directoryId: string;
  open?: boolean;
  onCancel: () => void;
  onComplete: (numberArr) => void;
}

const BuyPhoneNumberDialog = ({
  // directoryId,
  open,
  onCancel,
  onComplete,
}: BuyPhoneNumberDialogProps) => {
  // const {
  //   data: directory,
  //   isLoading: directoryIsLoading,
  //   refetch: refetchDirectory,
  // } = useDirectoryQuery(directoryId, {
  //   onSuccess: directory => {
  //     setFormDefaults({
  //       name: '',
  //     });
  //   },
  // });
  const router = useHistory();
  const buyPhoneNumberMutation = useBuyPhoneNumber();
  const [formDefaults, setFormDefaults] = useImmer<BuyPhoneNumberForm>({
    // name: '',
    ptn: '',
  });

  const handleSubmit = async (ptnForm: BuyPhoneNumberForm) => {
    // // update form defaults if mutation fails
    // setFormDefaults(ptnForm);
    // // "sanitize" and remove any unset values (empty strings)
    // // - so that they are not sent to server.
    // // - ex: An empty string would override the existing password(?)
    // // - no current way to unset password
    // const sanitizedFormData = pickBy(ptnForm, value => !!value?.length);

    console.log('ptnForm:', ptnForm);

    const buyPhoneNumberMutationPromise = buyPhoneNumberMutation.mutateAsync({
      numbers: [ptnForm.ptn],
    });
    toast.promise(buyPhoneNumberMutationPromise, {
      pending: 'Buying Number...',
      success: 'Number purchased!',
      error: 'Error purchasing number.',
    });
    const resp = await buyPhoneNumberMutationPromise;
    if (resp.status === 'success') {
      // // redirect to Directory
      // router.push(`/admin/directories/view/${resp.data.id}`);
    }
    console.log('buy resp:', resp);

    // await sleep(2000);

    // buy number
    // sdk.phoneNumber.query
    //   .available(
    //     // { prefix: '310', quantity: 3 },
    //     {},
    //     { auth_token: store.getState().auth.auth_token },
    //   )
    //   .then(v => {
    //     console.log('SDK PTN Response:', v);
    //     const ptns = Object.keys(v.data?.numbers ?? {});
    //     if (!ptns.length) {
    //       alert('No phone numbers found');
    //       // setValue('ptn', '+12125554444', { shouldDirty: true });
    //     } else {
    //       setValue('ptn', ptns[0], { shouldDirty: true });
    //     }
    //   });

    // return to parent/caller

    onComplete([ptnForm.ptn]);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Feature
      name="buy_numbers"
      NotTrueRender={() => (
        <Dialog open fullWidth maxWidth={'sm'} onClose={onCancel}>
          <DialogContent>
            <Typography variant="h2" paragraph>
              Activate TrunkingIO Integration
            </Typography>
            <Typography variant="body1" paragraph>
              To enable phone network connectivity, please integrate one of our
              partners:
            </Typography>

            <Divider />
            <Grid
              container
              columnSpacing={1}
              alignItems="center"
              sx={{ padding: 1 }}
            >
              <Grid item md={3}>
                <Typography variant="body1">TrunkingIO</Typography>
              </Grid>
              <Grid item>
                <AddIntegrationButton />
              </Grid>
            </Grid>
            <Divider />
          </DialogContent>
        </Dialog>
      )}
    >
      {/* @ts-ignore */}
      <Dialog open={open} fullWidth maxWidth={'sm'}>
        {buyPhoneNumberMutation.isLoading ? (
          <DialogInProgress title={'Buying Number'} />
        ) : (
          <>
            {/* <DialogTitle>Create Directory</DialogTitle>
          <Divider /> */}
            <BuyPhoneNumberDialogForm
              formDefaults={formDefaults}
              startDirty={buyPhoneNumberMutation.isError}
              onCancel={onCancel}
              onSubmit={handleSubmit}
              errorMessage={
                // @ts-ignore
                buyPhoneNumberMutation.error
                  ? // @ts-ignore
                    `${buyPhoneNumberMutation.error.message} ${
                      // @ts-ignore
                      buyPhoneNumberMutation.error.response
                        ? // @ts-ignore
                          `: ${buyPhoneNumberMutation.error.response?.statusText}`
                        : ''
                    }`
                  : undefined
              }
            />
          </>
        )}
      </Dialog>
    </Feature>
  );
};

interface BuyPhoneNumberDialogFormProps {
  formDefaults: BuyPhoneNumberForm;
  onCancel: () => void;
  onSubmit: (form: BuyPhoneNumberForm) => void;
  errorMessage?: string;
  startDirty?: boolean;
}

const BuyPhoneNumberDialogForm = ({
  formDefaults,
  onCancel,
  onSubmit,
  errorMessage,
  startDirty = false,
}: BuyPhoneNumberDialogFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty, isValid },
    setValue,
    setError,
    clearErrors,
    watch,
    control,
  } = useForm<BuyPhoneNumberForm>({
    defaultValues: formDefaults,
    resolver: joiResolver(schema), // pass in custom validation
  });

  const [isSearching, setSearching] = useState(false);

  const prefix = watch('prefix');
  const ptn = watch('ptn');

  // console.log('formState:', formState);
  // console.log('isValid:', isValid, formErrors);
  // console.log('ptn:', ptn);

  const search = prefix => {
    // note: the api response is different depending on if you include the “prefix” query param
    // - (and “quantity” is ignored if “prefix” is not included)
    // const prefix = getValue('prefix');
    setSearching(true);
    sdk.phoneNumber.query
      .available(
        { prefix, quantity: 1 },
        // {},
        { auth_token: store.getState().auth.auth_token },
      )
      .then(v => {
        setSearching(false);
        console.log('SDK PTN Response:', v);
        let ptns;
        if (isArray(v.data)) {
          ptns = v.data.map(num => num.number);
        } else {
          ptns = Object.keys(v.data?.numbers ?? {});
        }
        if (!ptns.length) {
          // alert('No phone numbers found');
          // setValue('ptn', '+12125554444', { shouldDirty: true });
          setError('ptn', {
            type: 'error',
            message: 'No Numbers found',
          });
        } else {
          setValue('ptn', ptns[0], { shouldDirty: true, shouldValidate: true });
        }
      });
  };

  useEffect(() => {
    // setValue('ptn', '+12125554444', { shouldDirty: true });
    if (!prefix) {
      setValue('ptn', null, { shouldDirty: true });
      return;
    }
    if (prefix.length !== 3) {
      setError('ptn', {
        type: 'error',
        message: '3 digits needed',
      });
      setValue('ptn', null, { shouldDirty: true });
      return;
    }
    clearErrors();
    search(prefix);
  }, [prefix]);

  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={12}>
            {/* <TextField
              label={'Name'}
              {...register('name')}
              error={!!formErrors.name}
              helperText={formErrors.name?.message?.replace('"name"', 'Name')}
            /> */}
            <Typography variant="h4">Get a new phone number</Typography>
            <br />
            <Grid container spacing={3} alignItems="center">
              <Grid item sx={{ width: '120px' }}>
                <Controller
                  control={control}
                  name={'prefix'}
                  defaultValue={'510'}
                  render={({ field: { onChange, value, ...props } }) => (
                    <>
                      <TextField
                        label="Area Code"
                        size="small"
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        sx={{ '& input': { textAlign: 'center' } }}
                      />
                    </>
                  )}
                />
              </Grid>
              {/* <Grid item>
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  onClick={e => search(prefix)}
                  loading={isSearching}
                >
                  Search
                </Button>
              </Grid> */}
              <Grid item>
                {formErrors?.ptn ? (
                  <Typography variant="caption" color="error">
                    {formErrors?.ptn.message}
                  </Typography>
                ) : (
                  <Typography variant="h6">
                    {!ptn?.length ? (
                      'Loading PTN'
                    ) : (
                      <PhoneNumberDisplay width="auto" ptn={ptn} />
                    )}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button variant={'outlined'} color={'error'} onClick={onCancel}>
          Cancel
        </Button>
        {errorMessage ? (
          <Typography color={'error'}>{errorMessage}</Typography>
        ) : null}
        <Button
          variant={'outlined'}
          color={'success'}
          disabled={
            !isValid
            // || (startDirty ? !startDirty : !isDirty)
          }
          onClick={handleSubmit(onSubmit)}
        >
          Buy Phone Number
        </Button>
      </DialogActions>
    </>
  );
};

export const useBuyPhoneNumberDialog = DialogBuilder(BuyPhoneNumberDialog);

export default BuyPhoneNumberDialog;
