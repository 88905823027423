import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from 'app/design';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { OwnerInput } from 'app/components/OwnerInput';
import { useUpdateCallflowPartial } from 'app/hooks/mutations/callflow';
import { useCallflowQuery } from 'app/hooks/queries/callflow';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CallflowDoc } from 'types/callflow';
import { PhoneNumber } from 'types/phoneNumber';
import { useImmer } from 'use-immer';

// interface for useForm. Not required as it can be inferred but
// - prevents typos and provides a layer of documentation and prevents
// - incorrect registers
interface CallflowDetailsFields {
  name: string;
  description: string;
  owner_id?: string;
}

// interface declaring which props are required/allowed
interface CallflowDetailsDialogProps {
  callflowId: string;
  onCancel: () => void;
  onComplete: (form?: CallflowDetailsFields) => void;
  // used for current implementation of dialog from flow.js
  passForm?: boolean;
  flowForm?: CallflowDoc;
}

const DetailsDialogCallflow = ({
  callflowId,
  onCancel,
  onComplete,
  passForm = false,
  flowForm,
}: CallflowDetailsDialogProps) => {
  const { data: callflow, isLoading: callflowIsLoading } = useCallflowQuery({
    id: callflowId,
    options: {
      onSuccess: callflow => {
        const defaults = {
          name: callflow.doc.name ?? '',
          description: callflow.doc.description ?? '',
          owner_id: callflow.doc.owner_id ?? '',
        };

        reset(defaults);

        // if (!defaults.name) setFocus('name');
        // else if (!defaults.description) setFocus('description');
      },
      // only run query if not using ivr flow form
      enabled: !flowForm,
    },
  });

  const {
    register,
    formState: { errors: formErrors },
    reset,
    handleSubmit,
    setFocus,
    control,
  } = useForm<CallflowDetailsFields>({
    defaultValues: flowForm
      ? // add defaults if passed ivr flow form
        {
          name: flowForm.name ?? '',
          description: flowForm.description ?? '',
          owner_id: flowForm.owner_id ?? '',
        }
      : {},
  });

  const updateCallflow = useUpdateCallflowPartial();

  const handleSave = async (callflowForm: CallflowDetailsFields) => {
    if (passForm) {
      onComplete(callflowForm);
    } else {
      const updateCallflowPromise = updateCallflow.mutateAsync(
        {
          id: callflowId,
          ...callflowForm,
        },
        {
          onSuccess: () => {
            onComplete();
          },
        },
      );

      // resolve promise with toast notifications
      toast.promise(updateCallflowPromise, {
        pending: 'Updating callflow details...',
        error: 'Failed to update callflow details.',
        success: 'Callflow details updated!',
      });
    }
  };

  return (
    <Dialog open={true} fullWidth maxWidth={'xs'}>
      {callflowIsLoading ? (
        <DialogInProgress title={'Loading callflow details info...'} />
      ) : updateCallflow.isLoading ? (
        <DialogInProgress title={'Updating callflow details...'} />
      ) : (
        <>
          <DialogTitle>Edit Callflow Details</DialogTitle>
          <Divider />
          {callflow ? (
            <>
              <DialogContent>
                {/* callflow name */}
                <Grid container columnSpacing={1} alignItems={'center'}>
                  <Grid item>
                    <TextField
                      label={'Name'}
                      {...register('name')}
                      error={!!formErrors.name}
                      helperText={formErrors.name?.message?.replace(
                        '"name"',
                        'Name',
                      )}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Controller
                      control={control}
                      name={'owner_id'}
                      render={({ field: { onChange, value, ...props } }) => (
                        <OwnerInput value={value} onChange={onChange} />
                      )}
                    />
                  </Grid>
                </Grid>
                <br />
                <br />
                {/* callflow description */}
                <TextField
                  fullWidth
                  label={'Description'}
                  {...register('description')}
                  error={!!formErrors.description}
                  helperText={formErrors.description?.message?.replace(
                    '"description"',
                    'Description',
                  )}
                  multiline
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </DialogContent>
              <Divider />
              <DefaultDialogActions
                onCancel={onCancel}
                onSave={handleSubmit(handleSave)}
                saveLabel={passForm ? 'Confirm' : undefined}
              />
            </>
          ) : (
            <>
              <DialogContent>
                <Typography color={'error'}>Invalid Callflow ID.</Typography>
              </DialogContent>
              <Divider />
              <DefaultDialogActions onCancel={onCancel} />
            </>
          )}
        </>
      )}
    </Dialog>
  );
};

export default DetailsDialogCallflow;
