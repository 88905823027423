import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { UserUpdatePartialDoc } from 'types/user';
import { VmboxUpdatePartialDoc } from 'types/vmbox';

export const useUpdateVmboxPartial = () => {
  const authState = useAuthSelector();
  return useMutation<any, AxiosError, VmboxUpdatePartialDoc, unknown>(
    (vmboxUpdatePartialDoc: VmboxUpdatePartialDoc) =>
      sdk.vmbox.mutate.updatePartial(
        vmboxUpdatePartialDoc,
        authState?.auth_token,
      ),
  );
};
