import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from 'app/design';
import { QuickFinderDeviceDialog } from 'app/components/QuickFinderDeviceDialog';
import { QuickFinderUserDialog } from 'app/components/QuickFinderUserDialog';
import { useListDevicesQuery } from 'app/hooks/queries/device';
import { useListUsersQuery } from 'app/hooks/queries/user';
import { randomString } from 'app/utilities';
// import { useTranslation } from 'react-i18next';
// import { set } from 'lodash';
import { flatten } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

// import { fetchAllVmboxes } from '../../../../../Actions/voicemails';
// import { AutocompleteDevice } from '../../AutocompleteDevice';
// import { AutocompleteUser } from '../../AutocompleteUser';

const AddComponent = props => {
  const {
    strategyCallflow,
    setStrategyCallflow,
    modifyPath,
    endpointsModifyPath,
    selectedEndpoints,
    allow,
    onAdd,
    label,
  } = props;

  // Users
  const { data: usersResp, isLoading: usersIsLoading } = useListUsersQuery({
    mask: 'id,doc(presence_id,username,email),extra', // mask
    quick: true, // "quick" ie "docOnly"
  });
  // @ts-ignore
  const { users = [] } = usersResp?.users ?? {};

  // Devices
  const { data: devicesResp, isLoading: devicesIsLoading } =
    useListDevicesQuery({
      mask: 'id,doc(name,owner_id)', // mask
      quick: true, // "quick" ie "docOnly"
    });
  // @ts-ignore
  const { devices = [] } = devicesResp?.devices ?? {};
  const [showAdd, setShowAdd] = useState<string | null>(null);
  const setShowAddWrapper = type => () => {
    if (type === showAdd) {
      setShowAdd(null);
    } else {
      setShowAdd(type);
    }
  };

  const [tierName, setTierName] = useState('Tier');

  const [defaultRingTotalTime, setDefaultRingTotalTime] = useState(25);
  const [defaultRingPerUser, setDefaultRingPerUser] = useState(20);

  const [openUser, setOpenUser] = useState(null);
  const [isOpenUserDevices, setIsOpenUserDevices] = useState<string | null>(
    null,
  );
  const [openUserDevices, setOpenUserDevices] = useState([]);

  // const alreadyInList = useCallback(
  //   item => {
  //     const arr = flatten(
  //       selectedEndpoints.map(e =>
  //         e.endpoint_type === 'tier' ? e.tiers.map(t => t.id) : e.id,
  //       ),
  //     );
  //     // console.log('arr:', arr, item.id || item);
  //     return arr.includes(item.id || item);
  //   },
  //   [selectedEndpoints],
  // );
  // const notAlreadyInList = useCallback(
  //   item => {
  //     return !alreadyInList(item);
  //   },
  //   [alreadyInList],
  // );

  const handleAddTier = () => {
    onAdd &&
      onAdd([
        {
          endpoint_type: 'tier',
          id: randomString(5),
          tier_name: tierName,
          tiers: [],
        },
      ]);
    setTierName('Tier');
    setShowAdd(null);
  };

  const handleChangeSelect = type => selected => {
    onAdd && onAdd(selected.map(id => ({ endpoint_type: type, id })));
    setShowAdd(null);
    setIsOpenUserDevices(null);

    // const updatedEndpoints = [
    //   ...selectedEndpoints,
    //   {
    //     endpoint_type: type,
    //     id: value.value,
    //     timeout: defaultRingTotalTime,
    //     delay: 0,
    //   },
    // ];

    // // redistribute if using sequential (test for overlap??)
    // // - TODO: determine if 2+ users how to handle distributing (ring one-by-one, or all-at-once?)
    // //   - currently doing one-by-one, regardless of current state of phones
    // const totalTime = updatedEndpoints.length * defaultRingTotalTime;
    // setAtPath(strategyCallflow, `${modifyPath}.timeout`, totalTime);
    // setAtPath(strategyCallflow, `${modifyPath}.preEndpoints`, updatedEndpoints);

    // let delay = 0;
    // for (let endpointIdx in updatedEndpoints) {
    //   let endpoint = updatedEndpoints[endpointIdx];
    //   setAtPath(
    //     strategyCallflow,
    //     `${modifyPath}.preEndpoints[${endpointIdx}].delay`,
    //     delay
    //   );
    //   setAtPath(
    //     strategyCallflow,
    //     `${modifyPath}.preEndpoints[${endpointIdx}].timeout`,
    //     defaultRingTotalTime
    //   );
    //   delay += defaultRingTotalTime;
    // }

    // setStrategyCallflow({
    //   ...strategyCallflow,
    // });
  };

  const handleChangeSelectUserDevices = (newValue: string[]) => {
    const [selectedUserId] = newValue;
    console.log('PRESSED', selectedUserId);
    // // get user's devices
    // const userId = newValue?.value;
    // const userDevices = devices.filter(device => {
    //   return device.doc.owner_id === userId;
    // });
    // // NOT separating out "already included" devices when displaying
    // setOpenUser(users.find(u => u.id === newValue?.id));
    // setOpenUserDevices(userDevices);
    // setIsOpenUserDevices(true);
    setIsOpenUserDevices(selectedUserId);
  };
  const handleUserDevicesClose = (deviceIdsToAdd = []) => {
    let new_endpoints: any[] = [];
    const updatedEndpoints = [...selectedEndpoints];
    // console.log('deviceIdsToAdd:', deviceIdsToAdd);
    for (let deviceId of deviceIdsToAdd) {
      new_endpoints.push({
        endpoint_type: 'device',
        id: deviceId,
      });
      // // verify not already in list
      // if (!selectedEndpoints.find((se) => se.id === deviceId)) {
      //   updatedEndpoints.push({
      //     endpoint_type: 'device',
      //     id: deviceId,
      //     timeout: defaultRingTotalTime,
      //     delay: 0,
      //   });
      // }
    }

    // TODO: redistribute automatically

    // setAtPath(strategyCallflow, `${modifyPath}.preEndpoints`, updatedEndpoints);
    // setStrategyCallflow({ ...strategyCallflow });
    setIsOpenUserDevices(null);
    onAdd && onAdd(new_endpoints);
  };

  console.log(
    'selectedEndpoints:',
    selectedEndpoints
      .filter(ep => ep.endpoint_type === 'user')
      .map(ep => ({ id: ep.id, checked: true })),
  );

  const excludeIds = useCallback(() => {
    return selectedEndpoints
      .flatMap(ep => {
        if (ep.endpoint_type === 'tier') {
          return ep.tiers;
        }

        return ep;
      })
      .map(ep => ({ id: ep.id, checked: true, indeterminate: true }));
  }, [selectedEndpoints]);

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <div>
            <Typography variant="h7">{label ? label : 'Add:'}</Typography>
          </div>
        </Grid>
        {allow.includes('user') && (
          <Grid item>
            <div>
              <Button
                size="xs"
                variant={showAdd === 'user' ? 'contained' : 'outlined'}
                color={'primary'}
                // color={showAdd === 'user' ? 'info' : 'default'}
                onClick={setShowAddWrapper('user')}
              >
                Users
              </Button>
            </div>
          </Grid>
        )}

        {allow.includes('device') && (
          <>
            <Grid item>
              <div>
                <Button
                  size="xs"
                  variant={showAdd === 'device' ? 'contained' : 'outlined'}
                  // color={showAdd === 'device' ? 'info' : 'default'}
                  color={'primary'}
                  onClick={setShowAddWrapper('device')}
                >
                  Devices
                </Button>
              </div>
            </Grid>
            <Grid item>
              <div>
                <Button
                  size="xs"
                  variant={
                    showAdd === 'users-devices' ? 'contained' : 'outlined'
                  }
                  // color={showAdd === 'users-devices' ? 'info' : 'default'}
                  color={'primary'}
                  onClick={setShowAddWrapper('users-devices')}
                >
                  Devices for User
                </Button>
              </div>
            </Grid>
          </>
        )}

        {allow.includes('tier') && (
          <Grid item>
            <div>
              <Button
                size="xs"
                variant={showAdd === 'tier' ? 'contained' : 'outlined'}
                // color={showAdd === 'tier' ? 'info' : 'default'}
                color={'primary'}
                onClick={setShowAddWrapper('tier')}
              >
                Tier
              </Button>
            </div>
          </Grid>
        )}
      </Grid>

      {/*<br />*/}
      <div>
        {showAdd === 'user' ? (
          <QuickFinderUserDialog
            onSelect={handleChangeSelect('user')}
            onCancel={() => setShowAdd(null)}
            multiple
            exclude={excludeIds()}
          />
        ) : (
          // <AutocompleteUser
          //   placeholder="Select User to Add"
          //   filterOptionsFunc={notAlreadyInList}
          //   defaultValue={null}
          //   label=""
          //   onChange={handleChangeSelect('user')}
          //   clearInputOnSelect
          //   autocompleteProps={{ clearOnBlur: true, blurOnSelect: true }}
          //   // defaultToFirst
          //   // user={ownerUser}
          // />
          ''
        )}
        {showAdd === 'device' ? (
          <QuickFinderDeviceDialog
            onSelect={handleChangeSelect('device')}
            onCancel={() => setShowAdd(null)}
            multiple
            exclude={excludeIds()}
          />
        ) : (
          // <AutocompleteDevice
          //   label=""
          //   placeholder="Select Device to Add"
          //   filterOptionsFunc={notAlreadyInList}
          //   defaultValue={null}
          //   onChange={handleChangeSelect('device')}
          //   clearInputOnSelect
          //   autocompleteProps={{ clearOnBlur: true, blurOnSelect: true }}
          //   // defaultToFirst
          //   // user={ownerUser}
          // />
          ''
        )}
        {showAdd === 'users-devices' ? (
          <>
            {isOpenUserDevices ? (
              <QuickFinderDeviceDialog
                onSelect={handleChangeSelect('device')}
                onCancel={() => {
                  setIsOpenUserDevices(null);
                }}
                queryFilters={{
                  context: {
                    ownerIds: [isOpenUserDevices], // user id
                  },
                }}
                multiple
                exclude={excludeIds()}
                initialSelected={devices
                  .filter(d => d.doc.owner_id === isOpenUserDevices)
                  .map(d => d.id)}
              />
            ) : (
              ''
            )}
            <QuickFinderUserDialog
              onSelect={handleChangeSelectUserDevices}
              onCancel={() => setShowAdd(null)}
            />
          </>
        ) : (
          ''
        )}
        {showAdd === 'tier' ? (
          <Grid
            container
            alignItems={'center'}
            style={{ margin: 10 }}
            spacing={1}
          >
            <Grid item>
              <TextField
                label="New Tier Name"
                value={tierName}
                onChange={e => setTierName(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="dense"
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item>
              <div style={{ paddingTop: 5 }}>
                <Button
                  onClick={handleAddTier}
                  color="primary"
                  variant="outlined"
                >
                  Add Tier
                </Button>
              </div>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

const ChooseUserDevicesDialog = props => {
  const {
    open,
    onClose,
    user,
    devices,
    dialogProps,
    alreadyInList,
    selectedEndpoints,
  } = props;

  const [selectedDevices, setSelectedDevices] = useState(
    devices.filter(d => !alreadyInList(d)).map(d => d.id),
  );

  const updateField = id => e => {
    if (e.target.checked) {
      setSelectedDevices([...selectedDevices, id]);
    } else {
      setSelectedDevices([...selectedDevices.filter(d => d !== id)]);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={e => onClose([])}
      fullWidth
      maxWidth={'sm'}
      // scroll="body"
      {...dialogProps}
    >
      <DialogTitle>Choose User's Devices</DialogTitle>
      <Divider />
      <DialogContent>
        {devices.map(device => {
          const inList = alreadyInList(device);
          return (
            <div key={device.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={true}
                    checked={selectedDevices.includes(device.id)}
                    disabled={inList ? true : false}
                    onChange={updateField(device.id)}
                    color="primary"
                  />
                }
                label={`${device.doc.name}${
                  inList ? ' (already in group)' : ''
                }`}
              />
            </div>
          );
        })}
        {devices.length ? '' : 'No devices for user'}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => onClose(selectedDevices)} color="primary">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddComponent;
