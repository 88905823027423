import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { Typography } from 'app/design';
import { sdk } from 'app/sdk';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { store } from 'store';

const PhoneNumber = ({}) => {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty, isValid },
    setValue,
    setError,
    clearErrors,
    watch,
    control,
  } = useFormContext();

  const [isSearching, setSearching] = useState(false);

  const prefix = watch('prefix');
  const ptn = watch('ptn');

  // console.log('formState:', formState);
  // console.log('isValid:', isValid, formErrors);
  // console.log('ptn:', ptn);

  const search = prefix => {
    // note: the api response is different depending on if you include the “prefix” query param
    // - (and “quantity” is ignored if “prefix” is not included)
    // const prefix = getValue('prefix');
    setSearching(true);
    sdk.phoneNumber.query
      .available(
        { prefix, quantity: 1 },
        // {},
        { auth_token: store.getState().auth.auth_token },
      )
      .then(v => {
        setSearching(false);
        console.log('SDK PTN Response:', v);
        let ptns;
        if (isArray(v.data)) {
          ptns = v.data.map(num => num.number);
        } else {
          ptns = Object.keys(v.data?.numbers ?? {});
        }
        if (!ptns.length) {
          // alert('No phone numbers found');
          // setValue('ptn', '+12125554444', { shouldDirty: true });
          setError('ptn', {
            type: 'error',
            message: 'No Numbers found',
          });
        } else {
          setValue('ptn', ptns[0], { shouldDirty: true, shouldValidate: true });
        }
      });
  };

  useEffect(() => {
    // // setValue('ptn', '+12125554444', { shouldDirty: true });
    if (ptn) {
      return;
    }
    if (!prefix) {
      setValue('ptn', null, { shouldDirty: true });
      return;
    }
    if (prefix.length !== 3) {
      setError('ptn', {
        type: 'error',
        message: '3 digits needed',
      });
      setValue('ptn', null, { shouldDirty: true });
      return;
    }
    clearErrors();
    search(prefix);
  }, [prefix, ptn]);

  return (
    <>
      {formErrors?.ptn ? (
        <Typography color="error">{formErrors?.ptn.message}</Typography>
      ) : !ptn?.length ? (
        'Loading PTN'
      ) : (
        <PhoneNumberDisplay width="auto" ptn={ptn} />
      )}
    </>
  );
};

export default PhoneNumber;
