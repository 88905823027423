import { AudioPlayer } from 'app/components/AudioPlayer';
import { EditHoldMusicDialogDevice } from 'app/components/EditHoldMusicDialogDevice';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { BUILT_IN_AUDIO } from 'app/components/MediaDialog/components/BuiltInAudio/BUILT_IN_AUDIO';
import { useToggleReducer } from 'app/utilities';
import { startCase } from 'lodash';
import * as React from 'react';
import { Device } from 'types/device';
import { Chip } from 'app/design';

interface HoldMusicDeviceProps {
  device: Device;
  onComplete: () => void;
}

const HoldMusicDevice = ({ device, onComplete }: HoldMusicDeviceProps) => {
  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'holdMusic',
    options: [
      {
        label: 'Hold Music',
        value: 'holdMusic',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const handleOnComplete = () => {
    onComplete();
    toggleShowEditDialog();
  };

  const mediaId = device.HoldMusic?.id;

  return (
    // @ts-ignore
    <div ref={ref}>
      <HighlightPulse {...highlightPulseProps} />
      {showEditDialog ? (
        <EditHoldMusicDialogDevice
          deviceId={device.id}
          mediaId={device.doc.music_on_hold.media_id}
          onComplete={handleOnComplete}
          onCancel={toggleShowEditDialog}
        />
      ) : null}
      <GenericSettingsCardField
        title={'Hold Music'}
        chip={
          device.HoldMusic?.inherited ? (
            <Chip
              color={'primary'}
              variant={'contained'}
              size={'small'}
              label={startCase(device.HoldMusic.inherited)}
            />
          ) : !mediaId ? (
            <Chip
              color={'primary'}
              variant={'contained'}
              label={'Platform Default'}
              size={'small'}
            />
          ) : null
        }
        content={<AudioPlayer mediaId={mediaId ?? BUILT_IN_AUDIO[0].link} />}
        editOnClick={toggleShowEditDialog}
        editLabel={
          device.doc.music_on_hold.media_id
            ? 'Change Hold Music'
            : 'Add Hold Music'
        }
      />
    </div>
  );
};

export default HoldMusicDevice;
