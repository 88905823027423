import React from 'react';
import { createSelector } from '@reduxjs/toolkit';

import {
  Voicemail as VoicemailIcon,
  Numbers as NumbersIcon,
  Business as BusinessIcon,
  Phone as PhoneIcon,
  Assessment as AssessmentIcon,
  AltRoute as AltRouteIcon,
  ContentCopy as ContentCopyIcon,
  Group as GroupIcon,
  Audiotrack as AudiotrackIcon,
  Person as PersonIcon,
  Schedule as ScheduleIcon,
  AttachMoney as AttachMoneyIcon,
  Settings as SettingsIcon,
  Dialpad as DialpadIcon,
  Add as AddIcon,
  ShoppingCart as ShoppingCartIcon,
  ImportContacts as ImportContactsIcon,
  Password as PasswordIcon,
} from 'app/design/icons-material';

const actions = [
  {
    name: 'Add User',
    searchables: ['new user', 'add user', 'users', 'create users'],
    // action: 'new user',
    link: `/admin/users/create`,
    icon: <AddIcon />,
  },
  {
    name: 'Import Users (Slack)',
    searchables: [
      'new user',
      'add user',
      'users',
      'create users',
      'import users',
    ],
    // action: 'new user',
    link: `/admin/users/create?via=slack`,
    icon: <ImportContactsIcon />,
  },
  {
    name: 'Import Users (Microsoft Azure)',
    searchables: [
      'new user',
      'add user',
      'users',
      'create users',
      'import users',
    ],
    // action: 'new user',
    link: `/admin/users/create?via=azure`,
    icon: <ImportContactsIcon />,
  },
  // {
  //   name: 'New Directory',
  //   searchables: [
  //     'new directory',
  //     'add directory',
  //     'directory',
  //     'directories',
  //     'create directory',
  //     'create directories',
  //   ],
  //   action: 'new directory',
  //   icon: <AddIcon />,
  // },
  {
    name: 'Add Group',
    searchables: ['new group', 'add group', 'groups', 'create groups'],
    action: 'add_group',
    icon: <AddIcon />,
  },
  {
    name: 'Add Phone',
    searchables: [
      'new device',
      'add device',
      'devices',
      'create devices',
      'add phone',
      'new phone',
      'create phone',
      'phones',
    ],
    // action: 'new device',
    link: `/admin/phones/create`,
    icon: <AddIcon />,
  },
  {
    name: 'Add Call Route',
    searchables: [
      'new callflow',
      'add callflow',
      'create callflow',
      'new call route',
      'add call route',
      'create call route',
      'call route',
      'call flow',
    ],
    action: 'add_call_route',
    icon: <AddIcon />,
  },
  {
    name: 'Buy Numbers',
    searchables: [
      'new numbers',
      'buy numbers',
      'purchase numbers',
      'add numbers',
      'create numbers',
    ],
    action: 'buy_numbers',
    icon: <ShoppingCartIcon />,
  },
  {
    name: 'Add Voicemail Box',
    searchables: [
      'create voicemail boxes',
      'create vmboxes',
      'add voicemail boxes',
      'add vmboxes',
      'new vmboxes',
    ],
    action: 'add_vmbox',
    icon: <AddIcon />,
  },
  // {
  //   name: 'Add Fax Box',
  //   searchables: [
  //     'create fax boxes',
  //     'create faxboxes',
  //     'create faxboxes',
  //     'add fax boxes',
  //     'add faxboxes',
  //   ],
  //   action: 'new faxbox',
  // },
  // {
  //   name: 'Add Screenpop',
  //   searchables: ['create screenpop', 'add screenpop'],
  //   action: 'new screenpop',
  // },
  process.env.NODE_ENV === 'development'
    ? {
        name: 'Add Template',
        searchables: ['create template', 'add template'],
        action: 'add_template',
        icon: <AddIcon />,
      }
    : null,
  {
    name: 'Feature Codes',
    searchables: ['feature codes'],
    action: 'feature_codes',
    icon: <PasswordIcon />,
  },
  // {
  //   name: 'Operator Extension',
  //   searchables: ['operator extension'],
  //   action: 'operator extension',
  // },
].filter(v => !!v);

const pages = [
  {
    name: 'Dashboard',
    link: '/admin/dashboard',
    icon: <BusinessIcon />,
    searchables: ['dashboard', 'company dashboard'],
  },
  {
    name: 'Users',
    link: '/admin/users',
    icon: <PersonIcon />,
    searchables: ['users', 'users page', 'user page'],
  },
  // {
  //   name: 'Directories',
  //   link: '/admin/directories',
  //   searchables: [
  //     'Directories',
  //     'Directories page',
  //     'directory page',
  //     'directory',
  //   ],
  // },
  {
    name: 'Groups',
    link: '/admin/groups',
    icon: <GroupIcon />,
    searchables: ['groups', 'groups page'],
  },
  // {
  //   name: 'Conferences',
  //   link: '/admin/conferences',
  //   searchables: [
  //     'conferences',
  //     'conferences page',
  //     'conference page',
  //     'conference',
  //   ],
  // },
  {
    name: 'Devices',
    link: '/admin/devices',
    icon: <PhoneIcon />,
    searchables: ['devices', 'devices page', 'device page'],
  },
  {
    name: 'Callflows',
    link: '/admin/callflows',
    icon: <AltRouteIcon />,
    searchables: ['callflows', 'callflows page', 'list callflows'],
  },
  {
    name: 'Media',
    link: '/admin/media',
    icon: <AudiotrackIcon />,
    searchables: ['media', 'media page', 'medias page'],
  },
  {
    name: 'Schedules',
    link: '/admin/schedules',
    icon: <ScheduleIcon />,
    searchables: ['schedules', 'schedule page', 'schedules page'],
  },
  {
    name: 'Menus',
    link: '/admin/menus',
    icon: <DialpadIcon />,
    searchables: ['menus', 'menu page', 'menu pages'],
  },
  // {
  //   name: 'Screenpops',
  //   link: '/admin/screenpops',
  //   searchables: ['screenpops'],
  // },

  process.env.NODE_ENV === 'development'
    ? {
        name: 'Templates',
        link: '/admin/callflow_templates',
        icon: <ContentCopyIcon />,
        searchables: ['templates', 'templates page', 'templates pages'],
      }
    : null,
  // {
  //   name: 'All Callflows',
  //   link: '/admin/callflows',
  //   searchables: [
  //     'all callflows',
  //     'callflows',
  //     'callflows',
  //     'callflows page',
  //     'callflow page',
  //   ],
  // },
  {
    name: 'Numbers',
    icon: <NumbersIcon />,
    link: '/admin/numbers',
    searchables: ['numbers', 'numbers page', 'number page'],
  },
  {
    name: 'Extensions',
    icon: <NumbersIcon />,
    link: '/admin/extensions',
    searchables: ['extensions', 'extension page', 'extensions'],
  },
  {
    name: 'Voicemail Boxes',
    link: '/admin/vmboxes',
    icon: <VoicemailIcon />,
    searchables: [
      'voicemail boxes',
      'voicemail boxes page',
      'voicemail box page',
      'voicemail page',
      'voicemails page',
      'vmboxes',
    ],
  },
  // {
  //   name: 'Fax Boxes',
  //   link: '/admin/admin_faxboxes',
  //   searchables: [
  //     'fax boxes',
  //     'fax boxes page',
  //     'fax box page',
  //     'fax page',
  //     'faxes page',
  //   ],
  // },
  {
    name: 'Call Logs & Reports',
    link: '/admin/calllogs',
    icon: <AssessmentIcon />,
    searchables: ['call logs and reports', 'call logs and reports'],
  },
  {
    name: 'Billing',
    link: '/admin/billing',
    icon: <AttachMoneyIcon />,
    searchables: ['billing', 'billing page'],
  },
  // {
  //   name: 'Settings',
  //   link: '/admin/settings',
  //   icon: <SettingsIcon />,
  //   searchables: ['settings', 'settings page', 'setting page'],
  // },
  // {
  //   name: 'Support',
  //   link: '/admin/?',
  // },
].filter(v => !!v);

// TODO: get useQuery results in here??
// - or put in the omniWidget directly?
const prepareOmniSearchState = createSelector(
  [
    state => state,
    // getAuthState,
    // phoneNumbersSelector,
    // strategiesSelector,
  ],
  (state, auth, phoneNumbersSelected, strategiesSelected) => {
    // const usersList = usersFormatListData({
    //   users: usersState.users,
    //   devices: devices.devices,
    //   callflows: callflowsState.callflows,
    //   deviceStatus: devices.devicesStatuses,
    // });

    // return {
    //   groupsState,
    //   usersList: usersSelected.usersList,
    //   usersState: usersSelected.usersState,
    //   callflowsState,
    //   devices,
    //   phoneNumbers: phoneNumbersSelected,
    //   menuCallflows: strategiesSelected.menuCallflows,
    // };

    // const {
    //   callflows,
    //   conferences,
    //   directories,
    //   devices,
    //   faxboxes,
    //   groups,
    //   media,
    //   menus,
    //   users,
    //   vmboxes,
    // } = state;

    return {
      actions,
      // callflows: callflows.list,
      // conferences: conferences.list,
      // devices: devices.list,
      // directories: directories.list,
      // extensions: phoneNumbersSelected.extensions,
      // faxboxes: faxboxes.list,
      // groups: groups.list,
      // ivrMenus: callflows.list.filter(cf => cf.doc.type === 'ivrMain'),
      // medias: media.list,
      // menus: menus.list,
      // phoneNumbers: phoneNumbersSelected.phone_numbers,
      // schedules: callflows.list.filter(cf => cf.doc.type === 'schedule'),
      // screenpops: callflows.list.filter(cf => cf.doc.type === 'screenpop'),
      // templates: callflows.list.filter(cf => cf.doc.type === 'template'),
      // allCallflows: callflows.list.filter(callflow => callflow?.doc?.name),
      // users: users.list,
      // vmboxes: vmboxes.list,
      pages,
    };
  },
);

export default prepareOmniSearchState;
