const useStyles = (props = {}) => ({
  fvLocalVideo: {
    position: 'absolute',
    top: 20,
    right: 20,
    width: 120,
    height: 90, // 4*3 aspect ratio
    zIndex: 2010,
    background: 'black',
    border: 'none',
    // opacity: (props) => (props.showLocalVideo ? 1 : 0.01),
    '& > video': {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      borderRadius: '5px',
      objectFit: 'cover',
      // opacity: (props) => (props.showLocalVideo ? 1 : 0),
    },
  },
  fvRemoteVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '20px',
    height: '100%',
    width: '100%',
    zIndex: 2005,
    background: 'transparent',
    '& > video': {
      position: 'absolute',
      top: '4%',
      left: '4%',
      height: '86%',
      width: '86%',
      borderRadius: '15px',
      objectFit: 'cover',
      // opacity: (props) => (props.showRemoteVideo ? 1 : 0.01),
    },
  },
  controlButton: {
    background: 'rgba(240,240,240,0.4)',
    border: 'none',
    '&:hover': {
      background: 'rgba(248,248,248,0.8)',
    },
  },
  flexRow: {
    display: 'flex',
    width: '100%',
    flexWrap: 'nowrap',
    '& > *:not(:last-child)': {
      marginRight: theme => (props.spacing ?? 0) * theme.spacing,
    },
  },
});

export default useStyles;
