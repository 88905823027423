import { gql } from 'graphql-request';

export default gql`
  query Medias(
    $skip: Int
    $take: Int
    $orderBy: JSON
    $filters: JSON
    $mask: String
  ) {
    medias(
      skip: $skip
      take: $take
      orderBy: $orderBy
      filters: $filters
      mask: $mask
    ) {
      totalCount
      skip
      take
      medias {
        id
        account_id
        doc
        listing
        createdAt
        updatedAt
      }
    }
  }
`;
