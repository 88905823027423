import { Box, Button, Grid, Link, Typography } from 'app/design';
import { EditScheduleDialog } from 'app/components/EditScheduleDialog';
import { EditScheduleLink } from 'app/components/ListSchedules/components/EditScheduleLink';
import { QuickFinderScheduleDialog } from 'app/components/QuickFinderScheduleDialog';
import { useCallflowQuery } from 'app/hooks/queries/callflow';
import { useVmboxQuery } from 'app/hooks/queries/vmbox';
import { setAtPath, useToggleReducer } from 'app/utilities';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import { CallflowDoc } from 'types/callflow';
import { Schedule } from 'types/schedule';
import { Vmbox } from 'types/vmbox';
import { NodeEditDialog } from '../../../../Dialogs';
import { Link as RouterLink } from 'react-router-dom';

interface ModifyNodeDialogScheduleProps {
  callflow: CallflowDoc;
  setCallflow: (callflow: CallflowDoc) => void;
  modifyPath: string;
  moduleItem: any;
  scheduleId: string;
  onClose: () => void;
}

export const ModifyDialog = ({
  callflow: callflowDoc,
  setCallflow,
  modifyPath,
  moduleItem,
  scheduleId: initialScheduleId,
  onClose,
}: ModifyNodeDialogScheduleProps) => {
  // autoshow quick finder if node has no data ID
  const [showFinderDialog, toggleShowFindScheduleDialog] = useToggleReducer(
    !initialScheduleId,
  );

  const [tmpModuleItem, setModifyModuleItem] = useState(() =>
    cloneDeep(moduleItem),
  );
  const scheduleId = tmpModuleItem?.data?.time?.callflow?.id;

  const {
    data: schedule,
    isLoading: scheduleIsLoading,
    error: scheduleError,
    refetch: refetchSchedule,
  } = useCallflowQuery({ id: scheduleId });

  const handleSave = async () => {
    // setAtPath(tmpModuleItem, 'data.time.callflow.id', scheduleId);
    setAtPath(callflowDoc, modifyPath, tmpModuleItem);
    setCallflow({ ...callflowDoc });
    onClose();
  };

  const [initialState] = useState({
    id: initialScheduleId,
  });

  const saveLabel = !scheduleId
    ? 'Leave Empty'
    : !initialScheduleId
    ? 'Add'
    : initialScheduleId === scheduleId
    ? 'Done'
    : 'Update';

  const onChangeScheduleSelect = selected => {
    const [scheduleId] = selected;
    setAtPath(tmpModuleItem, `data.time.callflow.id`, scheduleId); // newValue?.value === id
    setModifyModuleItem({ ...tmpModuleItem });
    toggleShowFindScheduleDialog();
  };

  const handleClear = () => {
    setAtPath(tmpModuleItem, `data.time.callflow.id`, null); // newValue?.value === id
    setModifyModuleItem({ ...tmpModuleItem });
    toggleShowFindScheduleDialog();
  };

  return (
    <>
      <NodeEditDialog
        title={scheduleId ? 'Edit Schedule Settings' : 'Select Schedule'}
        titleButton={
          schedule ? (
            <Button color="primary" onClick={toggleShowFindScheduleDialog}>
              Change Schedule
            </Button>
          ) : null
        }
        maxWidth={'sm'}
        onClose={onClose}
        onCancel={onClose}
        onSave={handleSave}
        saveLabel={saveLabel}
        hideCancel={saveLabel === 'Done'}
        cancelLabel={'Cancel'}
        isLoading={scheduleIsLoading}
        errorMessage={
          scheduleError ? 'Failed loading selected schedule' : undefined
        }
      >
        {schedule ? (
          // @ts-ignore
          <ScheduleSettings schedule={schedule} onComplete={refetchSchedule} />
        ) : (
          <>
            <Typography color={'gray'} sx={{ fontStyle: 'italic' }}>
              No Schedule Selected...
            </Typography>
            <Button onClick={toggleShowFindScheduleDialog}>
              Choose Schedule
            </Button>
          </>
        )}
      </NodeEditDialog>
      {showFinderDialog ? (
        <QuickFinderScheduleDialog
          onSelect={onChangeScheduleSelect}
          onCancel={toggleShowFindScheduleDialog}
          onClear={handleClear}
          initialSelected={scheduleId ? [scheduleId] : []}
          selectionTitle={schedule ? `${schedule.doc.name}` : null}
          allowSelectNone
          allowCreate
        />
      ) : null}
    </>
  );
};

interface ScheduleSettingsProps {
  schedule: Schedule;
  onComplete: () => void;
}

const ScheduleSettings = ({ schedule, onComplete }: ScheduleSettingsProps) => {
  return (
    <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
      <Typography variant="h1">{`${schedule.doc.name}`}</Typography>
      <EditScheduleLink
        label={'Edit'}
        schedule={schedule}
        onComplete={onComplete}
      />
    </Box>
  );
};

// const ChooseSchedule = props => {
//   const { onChoose, onClose } = props;

//   const handleSelect = value => {
//     onChoose(value?.schedule?.doc);
//   };

//   return (
//     <>
//       {/* <AutocompleteSchedule
//         label=""
//         placeholder={'Search Schedule box by name...'}
//         defaultValue={null}
//         onChange={handleSelect}
//       /> */}
//       AutocompleteVmBox
//     </>
//   );
// };

// const EditScheduleSettings = props => {
//   const { schedule, setSchedule, onClose } = props;

//   return (
//     <Grid
//       container
//       spacing={1}
//       // direction={'column'}
//       alignItems={'top'}
//       justify={'center'}
//     >
//       <Grid item xs={12}>
//         <Grid container>
//           <Grid item xs={12}>
//             <Typography variant={'h7'} style={{ display: 'inline-block' }}>
//               Schedule Settings
//             </Typography>
//             {/* <HelpToolTip
//               title={
//                 'Changing these settings will affect the Schedule wherever it is used'
//               }
//             /> */}
//           </Grid>
//           <Grid item xs={12}>
//             {schedule ? (
//               <>Sidebar??</>
//             ) : (
//               // <Sidebar>
//               //   <Divider />
//               //   <ScheduleBoxName
//               //     schedule={{ doc: schedule }}
//               //     onComplete={schedule => setSchedule(schedule)}
//               //   />
//               //   <Divider />
//               //   <SidebarGreeting
//               //     schedule={{ doc: schedule, id: schedule.id }}
//               //     onComplete={schedule => setSchedule(schedule)}
//               //   />
//               //   <Divider />
//               // </Sidebar>
//               'Invalid Schedule.'
//             )}
//           </Grid>
//         </Grid>
//       </Grid>
//     </Grid>
//   );

//   // return (
//   //   <>
//   //     {showModifyAudioDialog && (
//   //       <ModifyDialogAudio
//   //         mediaId={data.media?.unavailable}
//   //         onSave={afterUpdateMedia}
//   //         onClose={setShowModifyAudioDialogWrap(null)}
//   //       />
//   //     )}
//   //     <div>
//   //       <InputTextField
//   //         label="Name"
//   //         value={getAtPath(data, 'name')}
//   //         onChange={handeChangeText('name')}
//   //       />
//   //     </div>
//   //     <Divider />
//   //     <br />
//   //     {data.media?.unavailable ? (
//   //       <div>
//   //         <SmallMediaPlayer mediaId={data.media?.unavailable} includeName />
//   //         <Link onClick={setShowModifyAudioDialogWrap(true)}>
//   //           Modify Greeting
//   //         </Link>
//   //       </div>
//   //     ) : (
//   //       <div>
//   //         <Link onClick={setShowModifyAudioDialogWrap(true)}>Add Greeting</Link>
//   //       </div>
//   //     )}
//   //
//   //     <Divider />
//   //     <div>
//   //       <InputTextField
//   //         label="Mailbox"
//   //         value={getAtPath(data, 'mailbox')}
//   //         onChange={handeChangeText('mailbox')}
//   //       />
//   //     </div>
//   //   </>
//   // );
// };

// const ScheduleBoxName = ({ schedule, onComplete }) => {
//   const dispatch = useDispatch();
//   const [showDialog, setShowDialog] = useState(false);

//   const {
//     mutate: mutateScheduleBox,
//     isLoading: isLoadingMutate,
//     error: mutateError,
//   } = useMutation(data => {
//     // KazooSDK.resourceScheduleesPatch(data)
//   });

//   const handleSave = name => {
//     // const scheduleData = {
//     //   ...schedule.doc,
//     //   name,
//     // };
//     // let saveToast = ToastSaving({ title: 'Saving Schedule Name...' });
//     // mutateScheduleBox(scheduleData, {
//     //   onSuccess: async response => {
//     //     setShowDialog(false);
//     //     dispatch(syncSingleItem({ collectionKey: 'schedulees', id: schedule.id }));
//     //     // await sleep(1000);
//     //     saveToast.saved({ title: 'Schedule Name Saved!' });
//     //     console.log(response.data.data);
//     //     onComplete && onComplete(response.data.data);
//     //   },
//     //   onError: error => {
//     //     saveToast.error(error);
//     //   },
//     // });
//   };

//   return (
//     <>
//       {showDialog && (
//         <EditDetailsDialog
//           type={'Schedule'}
//           progressLabel={'Saving Schedule Name...'}
//           name={schedule?.doc?.name}
//           onCancel={() => setShowDialog(false)}
//           onClose={() => setShowDialog(false)}
//           onSave={handleSave}
//           errors={[mutateError]}
//           isLoadingMutate={isLoadingMutate}
//         />
//       )}
//       {/* <SidebarCard>
//         <SidebarCardHeader
//           label={'Schedule Name'}
//           icon={
//             <FontDownloadIcon
//               style={{ verticalAlign: 'middle', color: '#6736F1' }}
//             />
//           }
//           editButtonProps={{
//             onClick: () => setShowDialog(true),
//           }}
//         />
//         <SidebarCardContent>
//           <div>
//             <Typography variant="body1">{schedule?.doc?.name}</Typography>
//           </div>
//         </SidebarCardContent>
//       </SidebarCard> */}
//       SidebarStuff
//     </>
//   );
// };

export default ModifyDialog;
