import { Info as InfoIcon } from 'app/design/icons-material';
import { OptionsTableCell } from 'app/components/OptionsTableCell';
import { CdrInteractionDetailsDialog } from 'app/reports/components/ListCdrInteractions/components/CdrInteractionDetailsDialog';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { CdrInteraction } from 'types/callDetailRecord';

interface CdrInteractionListOptionsProps {
  cdrInteraction: CdrInteraction;
  onComplete: () => void;
}

const CdrInteractionListOptions = ({
  onComplete,
  cdrInteraction,
}: CdrInteractionListOptionsProps) => {
  const [showDetailsDialog, toggleShowDetailsDialog] = useToggleReducer(false);

  return (
    <>
      {showDetailsDialog ? (
        <CdrInteractionDetailsDialog
          interactionId={cdrInteraction.id}
          onClose={toggleShowDetailsDialog}
        />
      ) : null}
      <OptionsTableCell
        options={[
          {
            label: 'View details',
            icon: <InfoIcon />,
            onClick: toggleShowDetailsDialog,
          },
        ]}
      />
    </>
  );
};

export default CdrInteractionListOptions;
