import { Search as SearchIcon } from '@mui/icons-material';
import React, { useEffect, useCallback, useState, useRef } from 'react';

import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from 'app/design';

import { useWebphoneContext } from '../../Store';
import { ParkingView } from '../ParkingView';
import { ViewContainer } from '../ViewContainer';

import { CallInfo } from './CallInfo';

const CallsView = (props, context) => {
  const { show } = props;

  const [state, dispatch] = useWebphoneContext();
  const { calls } = state;

  // const calls = [];
  const [filterText, setFilterText] = useState('');

  return (
    <Box
      sx={{
        display: show ? 'flex' : 'none',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ flex: 1 }}>
        {calls.length ? (
          <Box
          // sx={{
          //   '& > div': {
          //     borderBottom: '1px solid #fdfdfd',
          //   },
          // }}
          >
            {calls.map((call, i) => (
              <>
                <CallInfo
                  key={call.getId()}
                  call={call}
                  localVideoRef={props.localVideoRef}
                  remoteVideoRef={props.remoteVideoRef}
                />
                <Divider />
              </>
            ))}
          </Box>
        ) : (
          <Box
            style={{
              padding: 4,
              height: '100%',
              // background: 'red',
              display: 'grid',
              placeItems: 'center',
            }}
          >
            <Typography variant="h6">No Calls In Progress</Typography>
          </Box>
        )}
      </Box>
      <Box>
        <ParkingView {...props} />
      </Box>
    </Box>
  );

  // return (
  //   <Box
  //     sx={{
  //       height: '100%',
  //       overflowY: 'scroll',
  //       display: show ? 'block' : 'none',
  //     }}
  //   ></Box>
  // );
};

export default CallsView;
