import { sdk } from 'app/sdk';
import React from 'react';

const setupAccountDetails = async ({
  auth_token,
  ptn,
  accountId,
  companyName,
  address,
}) => {
  const accountResult = await sdk.account.mutate.updatePartial(
    {
      id: accountId,
      name: companyName,
      caller_id: {
        external: {
          number: ptn,
        },
        emergency: {
          number: ptn,
        },
        internal: {
          number: ptn,
          name: companyName?.substr(0, 14),
        },
      },
      address: {
        street_address: address.streetLine, // line 1
        extended_address: address.secondary,
        locality: address.city,
        region: address.state, // state
        postal_code: address.zipcode,
        plus_four: '',
      },
    },
    auth_token,
  );
  console.log('accountResult:', accountResult);
  if (accountResult.status !== 'success') {
    throw 'Failed saving account';
  }
  // const accountId = accountResult.data.id;
  // return { accountId };
};

export default setupAccountDetails;
