import { Box, Checkbox, CircularProgress, Typography } from 'app/design';
import DefaultTable from 'app/components/DefaultTable/DefaultTable';
import DefaultTablePagination from 'app/components/DefaultTablePagination/DefaultTablePagination';
import { Keywords } from 'app/components/KeywordTooltip/KeywordTooltip';
import * as React from 'react';
import { Dispatch, ReactNode, useEffect } from 'react';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import { useImmer } from 'use-immer';

interface ListGenericProps {
  columns: any[];
  data: any[];
  totalCount: number;
  initialPageSize: number;
  initialPageIndex: number;
  queryPaginationDispatch: Dispatch<{ type: any; payload: any }>;
  queryIsFetching: boolean;
  queryIsLoading: boolean;
  getRowId: (row: any) => string;
}

const ROW_NUMBER_WIDTH = 33;

const ReportListGeneric = ({
  columns,
  data,
  totalCount,
  initialPageSize,
  initialPageIndex,
  queryPaginationDispatch,
  queryIsLoading,
  getRowId,
}: ListGenericProps) => {
  // table state
  const {
    allColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    getToggleHideAllColumnsProps,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        pageIndex: initialPageIndex,
        pageSize: initialPageSize,
      },
      getRowId,

      // settings for manual pagination
      autoResetPage: false,
      autoResetSelectedRows: false,
      autoResetRowState: false,
      manualPagination: true,
      pageCount: totalCount ? Math.ceil(totalCount / initialPageSize) : null,

      // settings for manual sorting
      manualSortBy: true,
      autoResetSortBy: false,
      disableSortRemove: true,
      disableMultiSort: true, // TODO: add eventually?
    },
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => {
        return [
          {
            id: 'rowNumber',
            Cell: ({ row, state: { pageSize, pageIndex } }) => {
              return (
                <Typography sx={{ color: 'text.secondary' }}>
                  {row.index + 1 + pageSize * pageIndex}
                </Typography>
              );
            },
            disableToggle: true,
            headerProps: {
              width: ROW_NUMBER_WIDTH,
              minWidth: ROW_NUMBER_WIDTH,
              maxWidth: ROW_NUMBER_WIDTH,
              left: 0,
              position: 'sticky',
              background: 'white',
              zIndex: 1,
            },
          },
          ...columns.slice(0, -1),
          // add filler cell to keep options column fixed width
          {
            id: 'fill',
            Cell: () => '',
            disableToggle: true,
          },
          columns[columns.length - 1],
        ];
      });
    },
  );

  // only update table pageSize/index only table when the table data is
  // updated (loaded/fetched)
  useEffect(() => {
    setPageSize(initialPageSize);
    gotoPage(initialPageIndex);
  }, [data]);

  return (
    <div>
      {queryIsLoading ? (
        <Box sx={{ display: 'grid', placeItems: 'center', padding: 4 }}>
          <CircularProgress variant={'indeterminate'} />
          <Typography variant="body2">Loading results...</Typography>
        </Box>
      ) : (
        <>
          <DefaultTable
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            page={page}
            headerGroups={headerGroups}
            prepareRow={prepareRow}
            emptyRowCount={pageSize - page.length}
            queryPaginationDispatch={queryPaginationDispatch}
            allColumns={allColumns}
          />
          <DefaultTablePagination
            totalRowCount={totalCount}
            rowsPerPage={initialPageSize}
            pageIndex={pageIndex}
            queryPaginationDispatch={queryPaginationDispatch}
            gotoPage={gotoPage}
            setPageSize={setPageSize}
          />
        </>
      )}
    </div>
  );
};

export default ReportListGeneric;
