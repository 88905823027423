import { Chip, Grid, InfoTooltip, Link, Typography } from 'app/design';
import { useFindInPage } from 'app/components/FindInPage';
import { TimezoneDialogVmbox } from 'app/components/TimezoneDialogVmbox';
import { useAccountQuery } from 'app/hooks/queries/account';
import { getTimezoneName, useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { Vmbox } from 'types/vmbox';
import { useMarkdownTranslate } from 'app/utilities/translate';

interface VmboxTimezoneProps {
  vmbox: Vmbox;
}

const TimezoneVmbox = ({ vmbox }: VmboxTimezoneProps) => {
  const { t } = useMarkdownTranslate();
  const [showDialog, toggleShowDialog] = useToggleReducer();
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'timezone',
    options: [
      {
        label: 'Timezone',
        value: 'timezone',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const accountQuery = useAccountQuery();

  const usedTimezone = vmbox.doc.timezone ?? accountQuery.data?.doc?.timezone;

  return (
    // @ts-ignore
    <div ref={ref}>
      {showDialog ? (
        <TimezoneDialogVmbox
          vmboxId={vmbox.id}
          onCancel={toggleShowDialog}
          onComplete={toggleShowDialog}
        />
      ) : null}
      <HighlightPulse {...highlightPulseProps} />
      <Grid container justifyContent="space-between">
        <Grid item xs={6}>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="h3">
                Timezone{' '}
                <InfoTooltip
                  // @ts-ignore
                  title={t('timezone.vmbox.tooltip')}
                  size={'small'}
                />
              </Typography>
            </Grid>
            {usedTimezone === vmbox.doc.timezone ? null : (
              <Grid item>
                <Chip
                  label={'Account'}
                  color={'primary'}
                  variant={'contained'}
                  size={'small'}
                />
              </Grid>
            )}
          </Grid>
          {usedTimezone ? (
            <Typography variant="h4">
              {getTimezoneName(usedTimezone).replace('_', ' ')}
            </Typography>
          ) : (
            <Typography
              variant="h4"
              sx={{ fontStyle: 'italic', color: 'text.secondary' }}
            >
              Not set...
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Link variant="body2" onClick={toggleShowDialog} underline="hover">
            Edit Timezone
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};

export default TimezoneVmbox;
