const useStyles = props => ({
  list: {
    '& > div': {
      borderBottom: '1px solid #ccc',
    },
  },
  call: {
    borderBottom: '1px solid #fcfcfc',
    padding: 1,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  notes_etc: {
    whiteSpace: 'nowrap',
    '& > button:not(:last-child)': {
      marginRight: 1,
    },
  },
  textRow: {
    '& > *:not(:last-child)': {
      paddingRight: 1,
      display: 'inline-block',
    },
  },
});

export default useStyles;
