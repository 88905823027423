import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';

export const useDeletePhoneNumber = () => {
  const authState = useAuthSelector();
  return useMutation<any, AxiosError, string, unknown>(
    (phoneNumberId: string) =>
      sdk.phoneNumber.mutate.delete(
        { data: { number: phoneNumberId } },
        { authToken: authState?.auth_token },
      ),
  );
};
