import React from 'react';
import {
  Tooltip,
  Button,
  ButtonGroup,
  IconButton,
  useMediaQuery,
} from 'app/design';
import { Phone as PhoneIcon } from 'app/design/icons-material';
import { startCase } from 'lodash';
import { Webphone, useWebphoneContext } from 'app/components/Webphone';
import { useDispatch } from 'react-redux';
import { useLocalSelector, useLocalSlice } from 'app/data/local';
import { useTheme } from 'app/design';
import { useGlobalTour } from 'app/components/Tourguide/Helper';

const WebphoneButton = () => {
  const dispatch = useDispatch();
  const { actions: localActions } = useLocalSlice();

  const [{ openTour, tourEvents }] = useGlobalTour();
  const handleShow = event => {
    console.log('showing webphone will close any tour');
    openTour(null); // close any open tour
    dispatch(localActions.set_webphone_visible(true));
  };

  const [{ isLineConnected, isRegistered, hasError }] = useWebphoneContext();

  let state: any = 'init';
  let StateComponent: any = InitState;
  let errCause = '';
  if (typeof isLineConnected === 'function' && !isLineConnected()) {
    state = 'registering';
    StateComponent = RegisteringState;
  } else if (typeof isRegistered === 'function' && isRegistered()) {
    state = 'registered';
    StateComponent = RegisteredState;
  } else if (typeof hasError === 'function' && hasError()) {
    state = 'error';
    StateComponent = ErrorState;
  }

  return <StateComponent onShow={handleShow} />;
};

const InitState = ({ onShow }) => {
  const theme = useTheme();
  const headerMini = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Tooltip title="Webphone" arrow>
        {/* @ts-ignore */}
        {/* <ButtonGroup onClick={onShow} size="small"> */}
        <Button
          onClick={onShow}
          color="primary"
          variant="contained"
          size={'small'}
          startIcon={headerMini ? null : <PhoneIcon style={{}} />}
          data-tour-is-webphone
        >
          {headerMini ? (
            <PhoneIcon fontSize={'small'} />
          ) : (
            'Webphone: Click to Activate'
          )}
        </Button>
        {/* </ButtonGroup> */}
      </Tooltip>
    </>
  );
};

const RegisteringState = ({ onShow }) => {
  const theme = useTheme();
  const headerMini = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Tooltip title="Webphone">
        {/*<ButtonGroup*/}
        {/*  color="primary"*/}
        {/*  size="small"*/}
        {/*  onClick={onShow}*/}
        {/*  disableElevation={true}*/}
        {/*>*/}
        <Button
          startIcon={headerMini ? null : <PhoneIcon style={{}} />}
          variant="contained"
          loading
          size={'small'}
          data-tour-is-webphone
        >
          {headerMini ? (
            <PhoneIcon fontSize={'small'} />
          ) : (
            'Webphone Connecting...'
          )}
        </Button>
        {/*</ButtonGroup>*/}
      </Tooltip>
    </>
  );
};

const RegisteredState = ({ onShow }) => {
  const theme = useTheme();
  const headerMini = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();
  const { webphone_visible } = useLocalSelector();
  const { actions: localActions } = useLocalSlice();

  const [{ isLineConnected, isRegistered, hasError, getErrorMessage, calls }] =
    useWebphoneContext();

  const inCall = calls?.length ? true : false;

  return (
    <>
      <Tooltip title="Webphone">
        {/* <ButtonGroup color="success" size="small" disableElevation={true}>
          <Button variant="contained">
            <PhoneIcon style={{}} />
          </Button> */}
        <Button
          color={inCall ? 'success' : 'primary'}
          variant="contained"
          startIcon={headerMini ? null : <PhoneIcon style={{}} />}
          onClick={onShow}
          size={'small'}
          data-tour-is-webphone
        >
          {headerMini ? (
            <PhoneIcon fontSize={'small'} />
          ) : inCall ? (
            'In a Call'
          ) : (
            'Webphone Ready: Make a Call'
          )}
        </Button>
        {/* </ButtonGroup> */}
      </Tooltip>
    </>
  );
};

const ErrorState = ({ onShow }) => {
  const dispatch = useDispatch();
  const { actions: localActions } = useLocalSlice();

  const [{ getErrorMessage }] = useWebphoneContext();
  const errCause = getErrorMessage && getErrorMessage();

  const handleClick = event => {
    dispatch(localActions.set_webphone_visible(true));
  };

  return (
    <>
      <Tooltip title="Webphone">
        <ButtonGroup
          color="error"
          size="small"
          onClick={onShow}
          disableElevation={true}
          data-tour-is-webphone
        >
          <Button variant="contained">
            <PhoneIcon style={{}} />
          </Button>
          <Button variant="contained">Error</Button>
        </ButtonGroup>
      </Tooltip>
    </>
  );
};

export default WebphoneButton;
