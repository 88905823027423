import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';

export const useDeleteMedia = () => {
  const authState = useAuthSelector();
  return useMutation<any, AxiosError, string, unknown>((mediaId: string) =>
    sdk.media.mutate.delete(mediaId, authState?.auth_token),
  );
};
