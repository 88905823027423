import { Grid, Typography, Link, Button, Card } from 'app/design';
import {
  Refresh as RefreshIcon,
  CreditCard as CreditCardIcon,
  Close as CloseIcon,
  Business as BusinessIcon,
} from 'app/design/icons-material';
import { parseGraphqlErrorCode } from 'app/utilities';
import constants from 'app/constants';
import { useAuthSelector } from 'app/data/auth';
import { useNavDetails } from 'app/hooks/general/useNavDetails';
import { useAccountQuery } from 'app/hooks/queries/account';
import { useCustomBackdrop } from 'app/components/CustomBackdrop';

import React, { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { request, gql } from 'graphql-request';
import { Settings as SettingsIcon } from 'app/design/icons-material';

import { CallHandlingCard } from './components/CallHandlingCard';
import { AccountCallerIdCard } from './components/AccountCallerIdCard';
// import { BillingDetailsCard } from './components/BillingDetailsCard';
// import { SettingsCard } from './components/SettingsCard';
// import { NumbersCard } from './components/NumbersCard';
// import { UsersCard } from './components/UsersCard';
// import { DevicesCard } from './components/DevicesCard';
// import { VmboxesCard } from './components/VmboxesCard';
// import { ConferencesCard } from './components/ConferencesCard';
// import { MiscCountsCard } from './components/MiscCountsCard';
// import { Setup } from './components/Setup';

function AdminAccountCalling() {
  // @ts-ignore
  const { auth_token } = useAuthSelector();
  const queryClient = useQueryClient();

  const {
    data: account,
    isLoading: accountIsLoading,
    isFetching,
    error: accountError,
    refetch: refetchAccount,
    queryKey: accountQueryKey,
  } = useAccountQuery();

  useNavDetails({
    sidebar_key: constants.nav.ADMIN_ACCOUNT_CALLING.sidebar_key,
    crumbs: [
      constants.nav.ADMIN.crumb,
      constants.nav.ADMIN_ACCOUNT_CALLING.crumb,
    ],
  });

  const {
    toggleOpen: toggleBackdrop,
    Dialog: CustomBackdrop,
    DialogProps: CustomBackdropProps,
  } = useCustomBackdrop({ initialOpen: false });

  if (accountIsLoading) {
    return <div>Loading Account...</div>;
  }

  if (accountError) {
    // @ts-ignore
    const code = parseGraphqlErrorCode(accountError) ?? accountError.message;

    return (
      <Typography sx={{ color: 'error.main' }}>
        Error loading user: {code}
      </Typography>
    );
  }

  if (!account) {
    return <Typography sx={{ color: 'error.main' }}>invalid login</Typography>;
  }

  const { doc: accountDoc } = account;

  return (
    <div>
      <CustomBackdrop {...CustomBackdropProps} />

      <div style={{}}>
        {/* <Grid
          container
          columnSpacing={1}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid item>
            <Typography variant="h2">
              <BusinessIcon sx={{ verticalAlign: 'middle' }} />
            </Typography>
          </Grid>
          <Grid item sx={{ whiteSpace: 'nowrap' }}>
            <Typography variant="h1">{`Account Call Handling`}</Typography>
          </Grid>
          <Grid item sx={{ flex: 1 }} />
          <Grid
            item
            // md={3}
          ></Grid>
        </Grid> */}

        <Grid container columnSpacing={3}>
          <Grid item md={6} xs={12}>
            <AccountCallerIdCard
              account={account}
              onComplete={refetchAccount}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CallHandlingCard account={account} onComplete={refetchAccount} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default AdminAccountCalling;
