import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Grid,
  Divider,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Tabs,
  Tab,
  Typography,
  Link,
  Box,
  Button,
  Tooltip,
  IconButton,
  FormControlLabel,
  Switch,
  ButtonGroup,
  Menu,
  MenuItem,
  CircularProgress,
  Skeleton,
  useTheme,
  ThemeProvider,
} from 'app/design';

import {
  MedicalServices as MedicalServicesIcon,
  CalendarToday as CalendarTodayIcon,
  Adjust as AdjustIcon,
  Block as BlockIcon,
  AccessTime as AccessTimeIcon,
  Edit as EditIcon,
  ArrowDropDown as ArrowDropDownIcon,
  InfoOutlined as InfoOutlinedIcon,
  Event as EventIcon,
  Warning as WarningIcon,
  Settings as SettingsIcon,
} from 'app/design/icons-material';

// import { store } from '../../../store';
import constants from 'app/constants';
import { sdk } from 'app/sdk';
import { withRouter, Link as RouterLink } from 'react-router-dom';

import { useAuthSelector, useAuthSlice } from 'app/data/auth';
import { useAccountQuery } from 'app/hooks/queries/account';
import { usePhoneNumberQuery } from 'app/hooks/queries/phoneNumber';
import { useListCallflowsQuery } from 'app/hooks/queries/callflow';
import { useListSchedulesQuery } from 'app/hooks/queries/schedule';
import { ScheduleWidget } from 'app/components/ScheduleWidget';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';

import { CallHandlingCard } from './CallHandlingCard';
import { AccountCallerIdCard } from './AccountCallerIdCard';

import { useLocalSelector } from 'app/data/local';
import { getAtPath } from 'app/utilities';

const Account = props => {
  const accountQuery = useAccountQuery();

  const {
    data: account,
    isLoading: accountIsLoading,
    refetch: refetchAccount,
  } = accountQuery;

  // do not allow to be open if there is a dialog
  // - also need to "capture" the dialog creation/removal so we can hide the tooltip
  const [dialogShown, setDialogShownRaw] = useState(false);
  const setDialogShown = val => {
    setOpen(false);
    setDialogShownRaw(val);
  };
  const [open, setOpen] = useState(false);
  const isOpen = open && !dialogShown ? true : false;

  const theme = useTheme();
  // console.log('THEME:', theme);

  return (
    <Box
      sx={{
        // background: 'rgba(0, 188, 212,0.2)',
        padding: 2,
        pb: 2,
      }}
    >
      <Tooltip
        open={isOpen}
        // onOpen={e => setOpen(true)}
        onClose={e => setOpen(false)}
        title={
          <TooltipContent
            isOpen={isOpen}
            accountQuery={accountQuery}
            setDialogShown={setDialogShown}
          />
        }
        arrow
        placement="right-end"
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: 'none',
              // background: 'red !important',
            },
          },
          popper: {
            keepMounted: true,
            // disablePortal: true
          },
        }}
      >
        <Box>
          <Name
            accountQuery={accountQuery}
            // handleOpenTooltip={e => setOpen(true)}
          />
          <Number
            accountQuery={accountQuery}
            handleOpenTooltip={e => setOpen(!isOpen)}
          />
          <E911 accountQuery={accountQuery} />
        </Box>
      </Tooltip>
      <Schedule accountQuery={accountQuery} />
    </Box>
  );
};

const TooltipContent = ({ isOpen, accountQuery, setDialogShown }) => {
  const {
    data: account,
    isLoading: accountIsLoading,
    refetch: refetchAccount,
  } = accountQuery;

  if (!account) {
    return <>Loading...</>;
  }

  return (
    <>
      <ThemeProvider
        // @ts-ignore
        theme={outerTheme => outerTheme.outerTheme}
      >
        <Box sx={{ minWidth: '600px' }}>
          <AccountCallerIdCard
            account={account}
            setDialogShown={setDialogShown}
            onComplete={refetchAccount}
          />
          <br />
          <CallHandlingCard
            account={account}
            setDialogShown={setDialogShown}
            onComplete={refetchAccount}
          />
        </Box>
      </ThemeProvider>
    </>
  );
};

const Name = ({ accountQuery }) => {
  const { data: account, isLoading: accountIsLoading } = accountQuery;
  return (
    <Typography variant="h2" gutterBottom noWrap>
      <Link
        component={RouterLink}
        to="/"
        underline="hover"
        color="primary.main"
      >
        {account?.doc?.name ?? <Skeleton />}
      </Link>
    </Typography>
  );
};

const Number = ({ accountQuery, handleOpenTooltip }) => {
  const { sidebar_key: selectedSidebarKey } = useLocalSelector();
  const { account_id } = useAuthSelector();
  const { data: account, isLoading: accountIsLoading } = accountQuery;

  // account external CID
  let externalCid = account?.doc?.caller_id?.external?.number;

  const {
    data: externalNumberData,
    isLoading: externalNumberIsLoading,
    refetch: refetchExternal,
  } = usePhoneNumberQuery({ id: externalCid });

  const externalNumber = externalNumberData?.doc.id;

  const {
    data: callflowsPageResponse,
    isLoading: callflowsAreLoading,
    isFetching: callflowsFetching,
    refetch,
  } = useListCallflowsQuery({
    filters: {
      raw: [
        {
          variables: {
            docFilters: [
              ['owner_id', '=', account_id],
              ['type', '=', 'main'],
            ],
          },
        },
      ],
    },
  });

  const mainCallflow = callflowsPageResponse?.callflows?.length
    ? callflowsPageResponse.callflows[0]
    : null;

  // show a single number (External Caller ID)
  // - icon/alert if this is NOT also an incoming number attached to the incoming callflow
  return (
    <Typography variant="h2" gutterBottom>
      <Grid container columnSpacing={1} wrap="nowrap">
        <Grid
          item
          sx={{
            flex: 1,
            cursor: 'pointer',
            color: 'white !important',
            borderRadius: '4px',
            background:
              selectedSidebarKey ===
              constants.nav.ADMIN_ACCOUNT_CALLING.sidebar_key
                ? '#1F8DA4'
                : undefined,
          }}
          // onClick={handleOpenTooltip}
        >
          <Link
            component={RouterLink}
            to="/admin/calling"
            underline="hover"
            color="#fff"
          >
            {externalNumberIsLoading || accountIsLoading ? (
              <Skeleton />
            ) : externalNumber ? (
              <PhoneNumberDisplay ptn={externalNumber} />
            ) : (
              'Setup Number'
            )}
          </Link>
        </Grid>
        <Grid item></Grid>
      </Grid>
    </Typography>
  );
};

const E911 = ({ accountQuery }) => {
  const { data: account, isLoading: accountIsLoading } = accountQuery;

  let emergencyCid = account?.doc?.caller_id?.emergency?.number;

  const {
    data: emergencyNumberData,
    isLoading: emergencyNumberIsLoading,
    refetch: refetchEmergency,
  } = usePhoneNumberQuery({ id: emergencyCid });

  // console.log('emergencyNumberData', emergencyNumberData);

  const emergencyAddress = emergencyNumberData?.doc.e911?.street_address;

  return (
    <Typography
      variant="body2"
      sx={{ textOverflow: 'ellipsis', width: '100%', overflowX: 'hidden' }}
      noWrap
      // paragraph
    >
      <Link
        component={RouterLink}
        to="/admin/calling"
        color="inherit"
        underline="hover"
      >
        {emergencyNumberIsLoading || accountIsLoading ? (
          <Skeleton />
        ) : (
          <>
            <MedicalServicesIcon sx={{ fontSize: 'inherit' }} />
            &nbsp;
            {emergencyNumberData
              ? emergencyAddress ?? 'No Emergency Address'
              : 'No Emergency Address'}
          </>
        )}
      </Link>
    </Typography>
  );
};

const Schedule = ({ accountQuery }) => {
  const { data: account, isLoading: accountIsLoading } = accountQuery;

  // get account schedule
  // - TODO: let them define the schedule

  // schedule list
  const { refetch, isLoading, isFetching, dataUpdatedAt, data } =
    useListSchedulesQuery(
      0, //skip
      10, // take
      undefined, // order by
      {}, // filters
    );
  const { callflows: schedules, totalCount = 0 } = data ?? {};

  const schedule = schedules?.length ? schedules[0] : null;

  return (
    <Typography
      variant="body2"
      sx={{ textOverflow: 'ellipsis', width: '100%', overflowX: 'hidden' }}
      noWrap
    >
      <Grid container columnSpacing={1} wrap="nowrap" alignItems="center">
        {/* <Grid item>
          <CalendarTodayIcon sx={{ fontSize: 'inherit' }} />
        </Grid> */}
        <Grid item sx={{ flex: 1 }}>
          {
            isLoading ? (
              <Skeleton />
            ) : schedule ? (
              <ScheduleWidget callflow={schedule.doc} /> ??
              'No Account Schedule'
            ) : null
            // 'No Account Schedule'
          }
        </Grid>
      </Grid>
    </Typography>
  );
};

export default Account;
