import HandymanIcon from '@mui/icons-material/Handyman';

import { CallflowNumbers } from 'app/components/CallflowNumbers';

import { useAccountChooseTemplateDialog } from 'app/components/CallRoutingDialog/AccountChooseTemplateDialog';
import { SimpleUIComponent } from 'app/components/CallRoutingDialog/components/SimpleUI/Component';
import TEMPLATES from 'app/components/CallRoutingDialog/templates';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';

// if we get "ReferenceError: Cannot access 'IvrMenuEventEmitterContext' before initialization"
// - buildOutputCallflowFromTemplate and getSimpleVariables MUST be directly imported!
import { buildOutputCallflowFromTemplate } from 'app/components/IvrBuilder/Flow/nodes/Template/buildOutputCallflowFromTemplate';
import { getSimpleVariables } from 'app/components/IvrBuilder/Flow/nodes/Template/getSimpleVariables';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Paper,
  Typography,
} from 'app/design';
import { Star as StarIcon } from 'app/design/icons-material';
import { useToggleReducer } from 'app/utilities';
import { cloneDeep, startCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useMutation } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { useFirstMountState } from 'react-use';

// interface declaring which props are required/allowed
// interface CallRoutingDialogProps {
//   // directoryId: string;
//   callflow?: any;
//   open?: boolean;
//   onCancel: () => void;
//   // onComplete: () => void;
// }

const SimpleInlineEditor = ({
  // directoryId,
  ee = null,
  name_type,
  type,
  resourceId,
  owner_type,
  parent_obj,
  callflow,
  onChange,
  modifyPath,
}) => {
  // if callflow doesnt exist, create a default one for saving
  const defaultCallflow = callflow
    ? callflow
    : DEFAULT_CALLFLOW({
        name_type,
        type,
        resourceId,
        owner_type,
      });

  // loaded WITH callflow
  const methods = useForm<any>({
    defaultValues: {
      callflow: defaultCallflow,
      // username: username ?? credentials?.username ?? '',
      // account_name: account_name ?? credentials?.account_name ?? '',
      // password: credentials?.account_name ? credentials?.password : '',
    },
    // resolver: joiResolver(schema),
  });

  // PARENT form!
  // - dirty parent when this gets dirtied
  const isFirstMount = useFirstMountState();
  const parentFormMethods = useFormContext();

  // pass dirtiness to parent form context
  useEffect(() => {
    if (isFirstMount) return;
    if (methods.formState.isDirty) {
      parentFormMethods.setValue('dirty', true, { shouldDirty: true });
      console.log('Set parent dirty', methods.formState);
    }
  }, [isFirstMount, methods.formState.isDirty]);
  // get dirtiness from parent form context
  useEffect(() => {
    if (isFirstMount) return;
    if (!parentFormMethods.formState.isDirty) {
      methods.reset(null, {
        keepValues: true,
        keepErrors: true,
        keepDirty: false,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
      console.log('reset embedded - child dirtied cuz parent saved');
    }
  }, [isFirstMount, parentFormMethods.formState.isDirty]);

  const callflowWatch = useWatch({
    control: methods.control,
    name: 'callflow',
  });

  // update parent that form has changed
  useEffect(() => {
    if (callflowWatch.strategy.template?.parent_callflow)
      onChange(methods.watch());
    else onChange(null);
  }, [callflowWatch.strategy.template?.parent_callflow]);
  useEffect(() => {
    if (callflowWatch.strategy.template?.parent_id) onChange(methods.watch());
    else onChange(null);
  }, [callflowWatch.strategy.template?.parent_id]);

  const onChangeSimple = () => {
    //
    console.log('Updated Simple!');
    onChange(methods.watch());
    // debugger;
  };

  const enabledSimple = methods.watch('callflow.strategy.simple.enabled'); // for Advanced/Visual-only editing
  const parent_set = methods.watch('callflow.strategy.template.parent_id'); // can be "custom"!

  // const handleCancel = () => {
  //   onCancel();
  // };

  const currentCallflow = methods.watch('callflow');

  return (
    // @ts-ignore
    <FormProvider {...methods}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          {enabledSimple ? (
            <>
              {parent_set ? (
                <TemplateVariables
                  modifyPath={''}
                  onChange={onChangeSimple}
                  // onChangeSimple={onChangeSimple}
                />
              ) : (
                <ChooseTemplate
                  {...{
                    type,
                    owner_type,
                    parent_obj,
                    onChange,
                  }}
                />
              )}
            </>
          ) : (
            <>
              <DisallowSimple
                {...{
                  callflow: currentCallflow,
                  ee,
                  // open,
                  // onCancel,
                  // onComplete,
                }}
              />
            </>
          )}
        </Grid>
        {/* <Grid item md={6}></Grid> */}
      </Grid>
    </FormProvider>
  );
};

const DisallowSimple = ({ callflow, open, onCancel, onComplete, ee }: any) => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const handleSwitchBack = () => {
    setValue('callflow.strategy.template.parent_id', null, {
      shouldDirty: true,
    });
    setValue('callflow.strategy.simple.enabled', true, {
      shouldDirty: true,
    });
  };

  const handleModify = () => {
    // emit an event to start a new editor instance
    // - add to chain
    // console.log('callflow:', callflow);
    ee?.emit('MODIFY_CALLFLOW', {
      callflow: callflow,
      onDone: callflow => {
        console.log('done callflow:', callflow);
        // setValue(`callflow`, callflow, {
        //   shouldDirty: true,
        // });
      },
    });
    window.scrollTo(0, 0);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Typography variant="body1">
          Simple Editing is disabled for this Call Route
        </Typography>
        <Button variant={'link'} color={'info'} onClick={handleModify}>
          Modify Now
        </Button>
        {callflow?.id ? (
          <Button
            variant={'link'}
            color={'info'}
            component={RouterLink}
            to={`/admin/ivr/edit/${callflow?.id}`}
          >
            Go to Expert/Visual Editor
          </Button>
        ) : null}
        <Button variant={'link'} color={'info'} onClick={handleSwitchBack}>
          Switch back to a Template
        </Button>
      </Grid>
      {/* <Grid item md={6}></Grid> */}
    </Grid>
  );
};

const NumberChangeDialog = props => {
  const { callflow, setCallflow, modifyPath, onClose } = props;

  const [editingCallflow, setEditingCallflow] = useState(cloneDeep(callflow));

  const handleSave = () => {
    setCallflow({ ...editingCallflow });
    onClose(); // already done for some reason when setCallflow?
  };

  return (
    <Dialog open fullWidth>
      <DialogTitle>Incoming Numbers</DialogTitle>
      <Divider />
      <DialogContent>
        <CallflowNumbers
          neverCollapse
          strategyCallflow={editingCallflow}
          setStrategyCallflow={setEditingCallflow}
          modifyPath={modifyPath}
        />
      </DialogContent>
      <Divider />
      <DefaultDialogActions
        // errors={errors}
        saveLabel="Update"
        onSave={handleSave}
        onCancel={onClose}
      />
    </Dialog>
  );
};

export const ChooseTemplate = ({ type, owner_type, parent_obj, onChange }) => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const options = TEMPLATES.filter((template: any) => {
    if (type === 'template') {
      return true;
    }

    return (
      (template.limit?.owner_type &&
        template.limit?.owner_type?.includes(owner_type)) ||
      (template.limit?.type && template.limit?.type?.includes(type))
    );
  }).map(template => ({
    label: template.name,
    value: template.id,
    full: template,
  }));

  const option_val = watch('callflow.strategy.template.parent_id');
  const optionValue = options.find(opt => opt.value === option_val) || null;

  const onChangeParent = templateFull => {
    if (!templateFull) {
      setValue('callflow.strategy.template.parent_callflow', undefined, {
        shouldDirty: true,
      });
    }

    setValue('callflow.strategy.template.parent_callflow', templateFull, {
      shouldDirty: true,
    });

    setValue('callflow.strategy.simple.variables', {}, { shouldDirty: true });
    setValue('callflow.strategy.data', null, { shouldDirty: true });
  };

  const handleChooseTemplate = opt => {
    setValue(`callflow.strategy.template.parent_id`, opt.value, {
      shouldDirty: true,
    });
    setValue(`callflow.strategy.template.parent_callflow`, opt.full, {
      shouldDirty: true,
    });
    setValue(`callflow.strategy.type`, opt.full.strategy.type, {
      shouldDirty: true,
    });
    setValue(`callflow.strategy.simple.variables`, {}, { shouldDirty: true });
    setValue(`callflow.strategy.data`, null, {
      shouldDirty: true,
    });
    // disable preview
    setValue('preview_template', null, {
      shouldDirty: true,
    });
  };

  const handleGoCustom = opt => {
    // console.log('MMM:', modifyPath);
    setValue(
      `callflow.strategy`,
      {
        type: 'blank',
        template: {
          parent_id: 'custom',
          parent_callflow: null,
        },
        simple: {
          enabled: false,
          variables: {},
        },
        data: {
          modules: [],
        },
      },
      {
        shouldDirty: true,
      },
    );
  };

  const {
    toggleOpen: toggleOpenAccountChooseTemplate,
    Dialog: AccountChooseTemplateDialog,
    DialogProps: AccountChooseTemplateDialogProps,
  } = useAccountChooseTemplateDialog({
    initialOpen: false,
  });

  return (
    <>
      <AccountChooseTemplateDialog
        {...AccountChooseTemplateDialogProps}
        parent_obj={parent_obj}
        onComplete={handleChooseTemplate}
      />
      {/* <Controller
        control={control}
        name={'callflow.strategy.template.parent_id'}
        render={({ field: { onChange, value, ...props } }) => (
          <Autocomplete
            options={options}
            // filterOptions={filterOptions}
            // @ts-ignore
            value={optionValue}
            onChange={(e, v) => {
              onChange(v?.value);
              onChangeParent(v?.full);
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Choose how to route calls"
                fullWidth
              />
            )}
            // disableClearable
          />
        )}
      />
      <br />
      <Link onClick={handleGoCustom}>or go Custom</Link> */}
      <Paper
        sx={{
          cursor: 'pointer',
          p: 2,
          // border: '1px solid white',
          border: 0,
          '&:hover': {
            // border: theme => `1px solid ${theme.palette.primary.main}`,
            boxShadow: '0 0 8px #65CADD',
          },
        }}
        variant="outlined"
        onClick={toggleOpenAccountChooseTemplate}
      >
        <Grid container wrap="nowrap">
          <Grid item xs={1}>
            <StarIcon sx={{ color: 'gold' }} />
          </Grid>
          <Grid item sx={{ flex: 1 }}>
            <Typography variant="h6" gutterBottom>
              Choose from our collection of simple templates
            </Typography>
            <Typography variant="body1" paragraph>
              Get started quickly with a variety of templates to choose from.
              Includes ring, voicemail, and menu options, plus more!
            </Typography>
            <Button variant="outlined" size="small">
              See Templates
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <br />
      <Paper
        sx={{
          cursor: 'pointer',
          p: 2,
          border: 0,
          '&:hover': {
            // border: theme => `1px solid ${theme.palette.primary.dark}`,
            boxShadow: '0 0 8px #65CADD',
          },
        }}
        variant="outlined"
        onClick={handleGoCustom}
      >
        <Grid container wrap="nowrap">
          <Grid item xs={1}>
            <Typography sx={{ color: 'primary.main' }}>
              <HandymanIcon />
            </Typography>
          </Grid>
          <Grid item sx={{ flex: 1 }}>
            <Typography variant="h6" gutterBottom>
              Build a custom flow
            </Typography>
            <Typography variant="body1" paragraph>
              Use our visual editor to create any flow you can imagine!
            </Typography>
            <Button variant="outlined" size="small">
              Build Custom Flow
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

const TemplateVariables = ({ modifyPath, onChange }) => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const [showAdvanced, toggleShowAdvanced] = useToggleReducer(false);

  const callflow = watch('callflow');
  const template_base = watch('callflow.strategy.template.parent_callflow');
  const variables = watch('callflow.strategy.simple.variables');
  const simpleVariablesForUI = watch('simpleVariablesForUI', []);

  const applyTemplateAndLoadVariables = useMutation(
    async ({ callflow, template_base }: any) => {
      // console.log('applyTemplateAndLoadVariables');
      // Load all the variables from the template
      // - modifies the template items "output_value" to be the value built from this "apply" step
      // - skips variable checking (no checkSimple)
      let outputCallflow;

      // see if applied previously
      // - modules exist => ran previously
      if (callflow?.strategy?.data) {
        console.log('Already ran!');
        outputCallflow = callflow;
        // await buildOutputCallflowFromTemplate({
        //   // rebuild STRATEGY
        //   rootCallflow: callflow, // root variables (and callflow.strategy.type=template -> callflow.strategy.simple.variables)
        //   templateCallflow: callflow, // template-level variables (none created)
        //   // checkSimple: false,
        // });
      } else {
        console.log('building callflow simple variables');
        outputCallflow = await buildOutputCallflowFromTemplate({
          // rebuild STRATEGY
          rootCallflow: callflow, // root variables (and callflow.strategy.type=template -> callflow.strategy.simple.variables)
          templateCallflow: template_base, // template-level variables (none created)
          // checkSimple: false,
        });
      }

      console.log('outputCallflow1:', callflow, outputCallflow);

      // TODO: get all variables and filter to simple-only?
      const simpleVariablesForUI = await getSimpleVariables({
        rootCallflow: callflow, // root variables (and callflow.strategy.type=template -> callflow.strategy.simple.variables)
        templateCallflow: outputCallflow, // template-level variables (none created)
      });

      console.log('simpleVariablesForUI:', simpleVariablesForUI);

      // expecting the type to be "blank" or "schedule" already, and not to be changed??
      // - seems weird to only get data... (maybe should get top-level variables as well? for schedule?)
      setValue('callflow.strategy.data', outputCallflow?.strategy?.data, {
        // shouldDirty: true,
      });
      setValue('simpleVariablesForUI', simpleVariablesForUI, {
        // shouldDirty: true,
      });
      // debugger;
    },
  );

  const onChangeSimple = async () => {
    // // re-apply simple variables to callflow
    console.log('re-applying variables to callflow/template (2)');
    await applyTemplateAndLoadVariables.mutateAsync({
      callflow,
      template_base,
    });
    // // debugger;
    onChange && onChange(); // pass to parent (for when embedded)
  };

  useEffect(() => {
    console.log('Running applyTemplateAndLoadVariables in SimpleInlineEditor');
    applyTemplateAndLoadVariables.mutateAsync({ callflow, template_base });
  }, [template_base]);

  //   const {
  //     mutate: mutateDirectoryBox,
  //     isLoading: isLoadingMutate,
  //     error: mutateError,
  //   } = useMutation(data => {
  //     // KazooSDK.resourceDirectoryesPatch(data)
  //   });

  const advancedArr = simpleVariablesForUI?.filter(
    uiVar => uiVar.varItem.simple_advanced,
  );
  const simpleArr = simpleVariablesForUI?.filter(
    uiVar => !uiVar.varItem.simple_advanced,
  );
  const hasAdvanced = advancedArr?.length ? true : false;
  const uiElements = showAdvanced ? simpleVariablesForUI : simpleArr;

  // console.log('uiElements2:', uiElements);
  return (
    <Paper sx={{ p: 2, background: '#fafafa' }} elevation={0}>
      {/* <br />
      <Divider />
      <br /> */}
      {/* <Typography variant="h2" paragraph>
        Call Routing Options:
      </Typography> */}
      {applyTemplateAndLoadVariables.isLoading ? (
        <Box>
          <Typography variant="caption">
            Loading options for editing...
          </Typography>
        </Box>
      ) : uiElements?.length ? (
        uiElements?.map((uiVar, idx) => (
          <Box
            key={idx}
            sx={{
              margin: '12px 0',
              mb: 4,
              display: uiVar.skipShowingInSimple ? 'none' : null,
            }}
          >
            <SimpleUIComponent
              item={uiVar}
              modifyPath={modifyPath}
              onChangeSimple={onChangeSimple}
            />
          </Box>
        ))
      ) : (
        <Box>
          <Typography variant="caption">No options for template</Typography>
        </Box>
      )}
      {process.env.NODE_ENV === 'development' ? (
        <div>
          {hasAdvanced ? (
            <>
              <br />
              <Divider />
              <br />
              {showAdvanced ? (
                <Link variant="body2" onClick={toggleShowAdvanced}>
                  Hide Advanced
                </Link>
              ) : (
                <Link variant="body2" onClick={toggleShowAdvanced}>
                  Show Advanced
                </Link>
              )}
            </>
          ) : null}
        </div>
      ) : null}
    </Paper>
  );
};

const CallRoutingDialogWrapper = props => {
  if (!props.open) {
    return null;
  }
  return <SimpleInlineEditor {...props} />;
};

export const useSimpleNodeEditorDialog = ({ initialOpen = false }) => {
  const [open, toggleOpen] = useToggleReducer(initialOpen);

  const CallRoutingDialogProps = {
    open,
    onCancel: toggleOpen,
  };

  return {
    toggleOpen,
    CallRoutingDialog: CallRoutingDialogWrapper,
    CallRoutingDialogProps,
  };
};

const DEFAULT_CALLFLOW = ({
  name_type,
  type,
  resourceId,
  owner_type,
  // numbers = [],
}) => ({
  id: 'inline',
  name: `${startCase(name_type)} Main`,
  type,
  owner_id: resourceId,
  owner_type, //
  // numbers,
  flow: {
    module: 'callflow',
    data: { id: 'placeholder' },
  },
  metaflow: {},
  strategy: {
    type: 'blank',
    template: {
      // more like "clone" cuz could be another callflow that is NOT a template?
      // straight_to_advanced: false, // for skipping "simple" (not commonly used)
      parent_id: null, // "custom" for a custom non-template one (not used at all yet?)
      parent_callflow: null, // cached value of template (id included)
    },
    simple: {
      enabled: true, // for v1, when we dont allow somebody to "go back" from Advanced/Visual Editor
      variables: {},
    },
    data: {
      // modules: [],
    },
    // type: 'template',
    // data: {
    //   id: null,
    //   templateBaseCallflow: null, // copied from template/id {id, name, limit, strategy...}
    //   variables: {}, // for the selected template (erased when template changes!)
    //   outputCallflow: null,
    // },
  },
});

export default SimpleInlineEditor;
