// import { patch } from 'app/sdk/utils/kazoo';
import { useAuthSelector } from 'app/data/auth';
// import { patchResource, patchResourceKeys } from 'app/kazooSDK';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
// import { BuyPhoneNumberDoc } from 'types/phoneNumber';

export const useBuyPhoneNumber = () => {
  const authState = useAuthSelector();
  return useMutation<any, AxiosError, any, unknown>(
    (vars: any) =>
      sdk.phoneNumber.mutate.buy(vars, { auth_token: authState?.auth_token }),
    // patchResource(patchResourceKeys.phoneNumber, vars),
  );
};
