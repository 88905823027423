import { IncomingNode } from './Incoming';
import { FixCreateBlankNode } from './FixCreateBlank';
import { InsertNode } from './Insert';
import { InsertMenuOptionNode } from './InsertMenuOption';
import { DirectoryNode } from './Directory';
// import { ConferenceRoomNode } from './ConferenceRoom';
import { ContinueToCallflowNode } from './ContinueToCallflow';
import { PlayAudioNode } from './PlayAudio';
import { NoteNode } from './Note';
import { RingNode } from './Ring';
import { MenuNode } from './Menu';
import { OptionNode } from './Option';
import { OptionScheduleNode } from './OptionSchedule';
import { MissingNode } from './Missing';
import { TransferNode } from './Transfer';
import { ScheduleNode } from './Schedule';
import { VoicemailNode } from './Voicemail';
import { SimpleNode } from './Simple';
import { SimpleMenuNode } from './SimpleMenu';
import { SimpleCallflowNode } from './SimpleCallflow';

import { TemplateNode } from './Template';
import { TemplateEndpointNode } from './TemplateEndpoint';

const nodeTypes = {
  MissingNode,
  FixCreateBlankNode,
  ContinueToCallflowNode,
  DirectoryNode,
  // ConferenceRoomNode,
  IncomingNode,
  InsertNode,
  InsertMenuOptionNode,
  MenuNode,
  PlayAudioNode,
  NoteNode,
  RingNode,
  OptionNode,
  OptionScheduleNode,
  TransferNode,
  ScheduleNode,
  SimpleNode,
  SimpleMenuNode,
  SimpleCallflowNode,
  VoicemailNode,
  TemplateNode,
  TemplateEndpointNode,
};

const nodeTypeAllowAfter = {
  IncomingNode: true,
  PlayAudioNode: true,
  NoteNode: true,
  RingNode: true,
};

export { nodeTypes, nodeTypeAllowAfter };
