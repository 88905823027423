import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  InputTextField,
  Link,
  Typography,
} from 'app/design';

import { useCallflowQuery } from 'app/hooks/queries/callflow';

import { CalendarMonth as CalendarMonthIcon } from 'app/design/icons-material';

import { Handle } from 'react-flow-renderer';
import { useHoverDirty } from 'react-use';
import { IvrMenuEventEmitterContext } from '../../..';

// import {
//   ModifyDialog as ModifyDialogAudio,
//   SmallMediaPlayer,
// } from '../../../../SingleMedia';

import { Box } from '../common/Box';
import { ModifyDialog } from './ModifyDialog';
import { InTemplate } from '../common/InTemplate';
import { MenuButton } from '../common/MenuButton';
import { NodeInfoDisplay } from '../common/NodeInfoDisplay';
import { VariablesEditor } from '../common/VariablesEditor';

// why use memo??
const ScheduleNode = memo(({ data, ...rest }) => {
  const {
    skipEditing,
    insertBefore, // bool
    insertAfterData,
    moduleItem,
    callflow,
    setCallflow,
    modifyPath,
    templateParent,
    // element, // used in Box
    // setRootElements, // used in Box
  } = data;

  // Load schedule via query
  const {
    data: schedule,
    isLoading,
    isFetched,
  } = useCallflowQuery({ id: moduleItem?.data?.time?.callflow?.id });

  const [showModifyDialog, setShowModifyDialog] = useState(null);
  const setShowModifyDialogWrap = setTo => () => setShowModifyDialog(setTo);

  const [showVariables, setShowVariables] = useState(null);
  const setShowVariablesWrap = setTo => () => setShowVariables(setTo);

  const boxRef = useRef(null);
  const isHovering = useHoverDirty(boxRef);

  let possibleVariables = [
    {
      id: 'schedule_id',
      name: 'Schedule ID',
      read: {
        type: 'schedule_id',
      },
      write: {
        type: 'set',
        data: {
          moduleItemModifyPath: 'data.time.callflow.id',
        },
      },
    },
  ];

  // action for onCreate
  const ee = useContext(IvrMenuEventEmitterContext);
  const onNodeCreated = data => {
    if (data.optsPath === modifyPath) {
      setShowModifyDialog(true);
    }
  };
  useEffect(() => {
    ee.on('node-created', onNodeCreated);
    return () => {
      ee.removeListener('node-created', onNodeCreated);
    };
  }, []);

  return (
    <>
      <Box
        pref={boxRef}
        borderColor="#1E88E5"
        position={data?.position}
        data={data}
        // height={100}
        // onHeight={}
        // onDimensions={handleDimensions}
      >
        {showModifyDialog && (
          <ModifyDialog
            {...data}
            scheduleId={moduleItem?.data?.id} // is this wrong???
            // schedule={schedule}
            onClose={setShowModifyDialogWrap(null)}
          />
        )}
        {showVariables && (
          <VariablesEditor // Dialog
            {...data}
            possibleVariables={possibleVariables}
            onClose={setShowVariablesWrap(null)}
          />
        )}
        <Handle
          type="target"
          position="top"
          style={{ background: '#555', visibility: 'hidden' }}
        />
        <div style={{ position: 'relative' }}>
          <NodeInfoDisplay
            type={'Schedule'}
            icon={<CalendarMonthIcon />}
            title={
              schedule ? (
                `${schedule?.doc?.name}`
              ) : skipEditing ? (
                'None Selected'
              ) : (
                <Link onClick={setShowModifyDialogWrap(true)}>
                  Select Schedule
                </Link>
              )
            }
            content={''}
            footer={''}
          />
          {skipEditing ? null : (
            <>
              <MenuButton
                data={data}
                mode={isHovering ? 'normal' : 'ignore'}
                menuItems={[
                  {
                    type: 'modify',
                    props: { onClick: setShowModifyDialogWrap(true) },
                  },
                  // 'add',
                  'add-before',
                  'move',
                  'duplicate',
                  'copy',
                  process.env.NODE_ENV === 'development'
                    ? 'create_template'
                    : null,
                  templateParent ||
                  callflow.type == 'template' ||
                  process.env.NODE_ENV === 'development'
                    ? {
                        type: 'variables',
                        props: { onClick: setShowVariablesWrap(true) },
                      }
                    : null,
                  'remove',
                ]}
              />
            </>
          )}
        </div>
        {templateParent ? <InTemplate template={templateParent} /> : ''}
        <Handle
          type="source"
          position="bottom"
          style={{ background: '#555', visibility: 'hidden' }}
        />
      </Box>
    </>
  );
});

export default ScheduleNode;
