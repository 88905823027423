import MediaDialog from 'app/components/MediaDialog/MediaDialog';
import { useUpdateVmboxPartial } from 'app/hooks/mutations/vmbox';
import { useVmboxQuery } from 'app/hooks/queries/vmbox';
import React from 'react';
import { MediaType } from 'types/media';

interface EditHoldMusicDialogProps {
  vmboxId: string;
  onComplete: () => void;
  onCancel: () => void;
  mediaId?: string;
  temporary?: boolean;
}

const EditGreetingDialogVmbox = ({
  vmboxId,
  mediaId,
  onComplete,
  onCancel,
  temporary = false,
}: EditHoldMusicDialogProps) => {
  const updateVmbox = useUpdateVmboxPartial();
  const { data: vmbox } = useVmboxQuery({ id: vmboxId });
  const key = temporary ? 'temporary_unavailable' : 'unavailable';

  const handleComplete = async (mediaId: string | null) => {
    return updateVmbox.mutateAsync(
      {
        id: vmboxId,
        media: {
          ...vmbox?.doc.media,
          [key]: mediaId,
        },
      },
      {
        onSuccess: () => {
          onComplete();
        },
      },
    );

    // resolve promise with toast notifications
    // await toast.promise(updateVmboxPromise, {
    //   pending: 'Updating vmbox details...',
    //   error: 'Failed to update vmbox details.',
    //   success: 'Vmbox details updated!',
    // });
  };

  const label = temporary ? 'Temporary Greeting' : 'Greeting';

  return (
    <MediaDialog
      type={label}
      mediaNameToSaveAs={`${vmbox?.doc.name} ${label}`}
      mediaId={mediaId}
      onComplete={handleComplete}
      onCancel={onCancel}
      // TODO: can't use built-in ATM as unavailable field has string length limit of 32
      allowedTypes={[
        MediaType.Tts,
        MediaType.Upload,
        MediaType.Recording,
        MediaType.Custom,
      ]}
    />
  );
};

export default EditGreetingDialogVmbox;
