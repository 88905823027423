import { Grid, Typography } from 'app/design';
import React from 'react';

import { useHistory } from 'react-router-dom';

const CollectionListHeader = ({
  title,
  subtitle,
  titleVariant = 'h7',
  subtitleVariant = 'body2',
  actionButton,
  icon,
}) => {
  const history = useHistory();
  return (
    <>
      <Grid container alignItems="center" spacing={1} wrap={'nowrap'}>
        {/*{telicon && (*/}
        {/*  <Grid item>*/}
        {/*    <Typography variant={titleVariant}>*/}
        {/*      <Telicon name={telicon} fontSize="inherit" style={teliconStyle} />*/}
        {/*    </Typography>*/}
        {/*  </Grid>*/}
        {/*)}*/}
        {icon && (
          <Grid item>
            <div style={{ paddingTop: 5 }}>
              <Typography variant={'h7'}>{icon}</Typography>
            </div>
          </Grid>
        )}
        <Grid item>
          <Typography variant={titleVariant}>{title}</Typography>
        </Grid>
        <Grid item style={{ flex: 1 }}></Grid>
        {actionButton && (
          <Grid item style={{}}>
            {actionButton}
          </Grid>
        )}
      </Grid>
      {subtitle && (
        <Grid container alignItems="center" spacing={1} wrap={'nowrap'}>
          {/*{telicon && (*/}
          {/*  <Grid item style={{ opacity: 0 }}>*/}
          {/*    <Typography variant={titleVariant}>*/}
          {/*      <Telicon*/}
          {/*        name={telicon}*/}
          {/*        fontSize="inherit"*/}
          {/*        style={teliconStyle}*/}
          {/*      />*/}
          {/*    </Typography>*/}
          {/*  </Grid>*/}
          {/*)}*/}
          <Grid item>
            <Typography variant={subtitleVariant}>{subtitle}</Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default CollectionListHeader;
