import {
  Container,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Link,
  Divider,
  Stepper,
  Step,
  StepLabel,
} from 'app/design';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useAuthSelector, useAuthSlice } from 'app/data/auth';

import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
// import { ActivateAccount } from './ActivateAccount';
// import { ScheduleOpenTimes } from './ScheduleOpenTimes';
// import { ScheduleLunchTimes } from './ScheduleLunchTimes';
// import { ScheduleHolidayTimes } from './ScheduleHolidayTimes';
// import { EmergencyRouting } from './EmergencyRouting';
// import { LunchEmergencyRouting } from './LunchEmergencyRouting';
// import { ClosedEmergencyRouting } from './ClosedEmergencyRouting';
// import { OpenMenu } from './OpenMenu';
// import { Operator } from './Operator';
// import { UsersDirect } from './UsersDirect';
// import { UsersAdd } from './UsersAdd';
// import { PublicNumbers } from './PublicNumbers';
// import { Save } from './Save';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';

// const schema = Joi.object({
//   // username: Joi.string().required(),
//   // account_name: Joi.string().required(),
//   // password: Joi.string().required(),
// });

interface VsbInput {
  // username: string;
  // account_name: string;
  // password: string;
}

interface VsbProps {
  // credentials?: {
  //   username: string;
  //   account_name: string;
  //   password: string;
  // };
}

const Vsb = ({}: VsbProps) => {
  // const { loggingIn, loggedIn, username, account_name, error } =
  //   useAuthSelector();
  // const { actions: authActions } = useAuthSlice();
  // const dispatch = useDispatch();
  // const onSubmit = VsbInputData => {
  //   dispatch(authActions.loginWithCredentials(VsbInputData));
  // };

  const {
    data: userData,
    isLoading: userIsLoading,
    error: userError,
  } = useAuthenticatedUserQuery();

  const methods = useForm<VsbInput>({
    defaultValues: {
      prefix: '', // will get sorted out in Step 1! (after the address is figured out)
      activeStep: 0,
      prevStep: 0,
      transitionToStep: 0,
      companyName: '',
      address: null,
      // username: username ?? credentials?.username ?? '',
      // account_name: account_name ?? credentials?.account_name ?? '',
      // password: credentials?.account_name ? credentials?.password : '',
    },
    mode: 'onChange',
    // resolver: joiResolver(schema),
  });

  // console.log('methods', methods.formState.errors);
  // {
  //   register,
  //   handleSubmit,
  //   formState: { errors: formErrors, isValid },
  // }
  // @ts-ignore
  const activeStep = methods.watch('activeStep', 0);
  // console.log('activeStep', activeStep);

  return (
    <Container sx={{ mt: 5, mb: 5, width: 740 }}>
      <FormProvider {...methods}>
        <form>
          <Box>
            <Typography variant="h1" gutterBottom sx={{ textAlign: 'center' }}>
              Welcome, {userData?.doc?.first_name}!
            </Typography>
            <Box sx={{ width: '100%', mt: 4, mb: 4 }}>
              {/* @ts-ignore */}
              <Stepper activeStep={activeStep}>
                <Step completed>
                  <StepLabel>Basic Details</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Select Phone Number</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Done!</StepLabel>
                </Step>
              </Stepper>
            </Box>
            {/* @ts-ignore */}
            {activeStep === 0 ? <Step1 /> : null}
            {/* @ts-ignore */}
            {activeStep === 1 ? <Step2 user={userData} /> : null}
            {/* @ts-ignore */}
            {activeStep === 2 ? <Step3 /> : null}
          </Box>
        </form>
      </FormProvider>
    </Container>
  );
};

export default Vsb;
