import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { MediasPageResponse } from 'types/media';
import { Vmbox } from 'types/vmbox';
import mediaQueryKeys from './mediaQueryKeys';

interface QueryParams {
  id?: string;
}

export const useMediaUrl = ({ id }: QueryParams) => {
  const authState = useAuthSelector();

  // if built in - id is link
  if (id?.indexOf('http') === 0 || id?.indexOf('data') === 0) {
    return id;
  }

  return sdk.media.query.url({ id }, { authToken: authState.auth_token });
};

export default useMediaUrl;
