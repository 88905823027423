import { DialogCircularProgress } from 'app/components/DialogCircularProgress';
import {
  QUERY_ORDER_BY_CHANGED,
  QUERY_PAGE_INDEX_CHANGED,
} from 'app/utilities/useQueryPaginationReducer';
import { cloneDeep } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as React from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from 'app/design';
import { useScroll } from 'react-use';
import { useImmer } from 'use-immer';

interface DefaultTableProps {
  getTableProps: any;
  headerGroups: any;
  getTableBodyProps: any;
  page: any;
  prepareRow: any;
  emptyRowCount: number;
  queryPaginationDispatch: any;
  allColumns?: any[];
  exclude?: any[];
}

const DefaultTable = ({
  getTableProps,
  headerGroups,
  getTableBodyProps,
  page,
  prepareRow,
  emptyRowCount = 0,
  queryPaginationDispatch,
  allColumns = [],
  exclude = [],
}: DefaultTableProps) => {
  const [showStickyBorder, setShowStickyBorder] = useImmer(() => {
    // get the last sticky column in sequence to draw border
    for (let i = allColumns.length - 2; i >= 0; i--) {
      // @ts-ignore
      if (allColumns[i].headerProps?.position === 'sticky')
        // @ts-ignore
        return { id: allColumns[i].id, show: false };
    }

    return { id: null, show: false };
  });

  const handleScroll = e => {
    let element = e.target;
    if (element.scrollLeft === 0) {
      setShowStickyBorder(prevState => {
        prevState.show = false;
      });
    } else {
      setShowStickyBorder(prevState => {
        prevState.show = true;
      });
    }
  };

  // create empty rows to fill remaining page size
  // const emptyRows = useMemo(() => {
  //   console.log('headerGroups', headerGroups);
  //   return Array.apply(null, Array(emptyRowCount)).map((x, index) => (
  //     <TableRow key={`empty-row-${index}`}>
  //       {headerGroups?.[0]?.headers
  //         ?.filter(header => header.isVisible)
  //         .map((col, index) => (
  //           <TableCell key={`empty-cell-${index}`} sx={{ height: 70 }} />
  //         ))}
  //     </TableRow>
  //   ));
  // }, [emptyRowCount, headerGroups]);

  const handleSortBy = column => () => {
    // react-table defaults to asc so undefined === true
    const desc = column.isSortedDesc === undefined ? true : column.isSortedDesc;
    queryPaginationDispatch({
      type: QUERY_ORDER_BY_CHANGED,
      payload: [[column.id, desc ? 'asc' : 'desc']], // toggle current state
    });
  };

  // console.log('page', page);
  return (
    <TableContainer onScroll={handleScroll} style={{ overflowX: 'auto' }}>
      {/* apply the table props*/}
      <Table
        size="small"
        {...getTableProps()}
        sx={{
          borderCollapse: 'separate',
          // tableLayout: 'fixed',
          // width: '100%',
        }}
      >
        <TableHead>
          {
            // Loop over the header rows
            headerGroups.map(headerGroup => (
              // Apply the header row props
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column, i) => (
                    // Apply the header cell props
                    // eslint-disable-next-line react/jsx-no-undef
                    <TableCell
                      component={'th'}
                      {...column.getHeaderProps(
                        column?.getSortByToggleProps
                          ? column?.getSortByToggleProps()
                          : undefined,
                      )}
                      sx={{
                        ...column.headerProps,
                        borderRight:
                          showStickyBorder.id === column.id &&
                          showStickyBorder.show
                            ? '1px solid rgba(224, 224, 224, 1)'
                            : undefined,
                        borderLeft: '0px', // overwriting any stickyheader value
                        whiteSpace: 'nowrap',
                        // do NOT do border-radius here!
                        // borderTopLeftRadius: i === 0 ? '8px' : undefined,
                        // borderTopRightRadius:
                        //   i === headerGroup.headers.length - 1
                        //     ? '8px'
                        //     : undefined,
                        background: 'white', //i > 1 ? 'white' : 'none',
                      }}
                    >
                      <div
                        // TODO: better handling of this
                        // occasionally seems not to work?
                        onClick={
                          column.canSort ? handleSortBy(column) : undefined
                        }
                      >
                        <Grid
                          container
                          spacing={1}
                          alignItems={'center'}
                          wrap={'nowrap'}
                        >
                          <Grid item>
                            <Typography
                              sx={{
                                display: 'inline',
                                fontStyle: 'bold',
                                fontWeight: 550,
                              }}
                            >
                              {
                                // Render the header
                                column.render('Header')
                              }
                            </Typography>
                          </Grid>
                          {column.adornment ? (
                            <Grid item>{column.adornment}</Grid>
                          ) : null}
                          {column.canSort ? (
                            <Grid item>
                              <TableSortLabel
                                sx={{ display: 'inline-block' }}
                                active={column.isSorted}
                                // react-table has a unsorted state which is not treated here
                                direction={column.isSortedDesc ? 'desc' : 'asc'}
                              />
                            </Grid>
                          ) : null}
                        </Grid>
                      </div>
                    </TableCell>
                  ))
                }
              </TableRow>
            ))
          }
        </TableHead>
        {/* Apply the table body props */}
        <TableBody {...getTableBodyProps()}>
          {/* Loop over the table rows*/}
          {page.map(row => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <TableRow
                {...row.getRowProps()}
                sx={{
                  opacity: !!exclude?.find(ex => ex.id === row.id)
                    ? 0.3
                    : undefined,
                }}
              >
                {
                  // Loop over the rows cells
                  row.cells.map((cell, i) => {
                    // Apply the cell props
                    return (
                      <TableCell
                        component={'td'}
                        {...cell.getCellProps()}
                        sx={{
                          ...cell.column.headerProps,
                          borderRight:
                            showStickyBorder.id === cell.column.id &&
                            showStickyBorder.show
                              ? '1px solid rgba(224, 224, 224, 1)'
                              : undefined,
                          background: 'white', //i > 1 ? 'white' : 'none',
                        }}
                      >
                        <Typography>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </Typography>
                      </TableCell>
                    );
                  })
                }
              </TableRow>
            );
          })}
          {/*{emptyRows}*/}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DefaultTable;
