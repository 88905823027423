import * as React from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Paper,
  Link,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from 'app/design';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from 'app/design/icons-material';
import {
  useFeature,
  Feature,
  useToggleReducer,
  DialogBuilder,
  parseAndSetKazooMutationErrors,
} from 'app/utilities';
import Joi from 'joi';
import { pickBy } from 'lodash';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useImmer } from 'use-immer';

import { useAccountStripeCustomerMutate } from 'app/hooks/mutations/account';
import { useCustomBackdrop } from 'app/components/CustomBackdrop';
import { CreditCardRequiredDialog } from 'app/components/CreditCardRequiredDialog';

// schema for form validation. Passed to useForm to only trigger submit when
//  - the below conditions are met. Any known serverside constraints (min/max,
//  - character limits, numbers only, etc.) should be added. Remove 128 max below
//  - to demonstrate serverside invalidation and handling
// const schema = Joi.object({
//   owner_id: Joi.string(),
//   name: Joi.string().max(128).required(),
//   mailbox: Joi.string().max(5).required(),
// });

interface IntegrationAddForm {
  name?: string;
}

// interface declaring which props are required/allowed
interface IntegrationAddDialogProps {
  formDefaults?: any;
  onCancel: () => void;
  startDirty?: boolean;
  onComplete: () => void;
}

const IntegrationAddDialog = ({
  formDefaults,
  onCancel,
  onComplete,
  startDirty = true,
}: IntegrationAddDialogProps) => {
  const handleCancel = () => {
    onCancel();
  };

  return (
    // @ts-ignore
    <Feature
      name="has_payment_methods"
      // @ts-ignore
      NotTrueRender={({ updateFeatures }) => (
        <CreditCardRequiredDialog
          onCancel={handleCancel}
          onComplete={() => {
            updateFeatures();
          }}
        />
      )}
    >
      <Dialog open fullWidth maxWidth={'sm'}>
        <IntegrationAddDialogForm
          formDefaults={formDefaults}
          startDirty={startDirty}
          onCancel={onCancel}
          onComplete={onComplete}
        />
      </Dialog>
    </Feature>
  );
};

interface IntegrationAddDialogFormProps {
  formDefaults: IntegrationAddForm;
  onCancel: () => void;
  onComplete: () => void;
  startDirty?: boolean;
}

const IntegrationAddDialogForm = ({
  formDefaults,
  onCancel,
  onComplete,
  // onSubmit,
  // errorMessage,
  startDirty = false,
}: IntegrationAddDialogFormProps) => {
  const [saveError, setSaveError] = useImmer(null);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors: formErrors, isDirty, isSubmitting },
    setError,
    watch,
  } = useForm<IntegrationAddForm>({
    defaultValues: formDefaults,
    // resolver: joiResolver(schema), // pass in custom validation
  });

  const router = useHistory();
  const addIntegration = useAccountStripeCustomerMutate();

  const onSubmit = async (ccForm: IntegrationAddForm) => {
    // update form defaults if mutation fails

    setSaveError(null);

    const addIntegrationPromise = addIntegration.mutateAsync({
      data: {
        action: 'addIntegration',
        data: {
          // name: ccForm.name,
          // source: token?.id,
          // // @ts-ignore
          // setDefault: formDefaults?.setDefault ? true : false,
        },
      },
    });

    toast.promise(addIntegrationPromise, {
      pending: 'Adding integration...',
      success: 'Integration added!',
      error: 'Error adding integration.',
    });

    let resp;
    try {
      resp = await addIntegrationPromise;
    } catch (err) {
      // TODO: parse and set errors

      // @ts-ignore
      setSaveError({
        // @ts-ignore
        message:
          'Unable to add Integration. Please correct errors and try again.',
      });
      throw err;
    }

    onComplete && onComplete();
  };

  const {
    toggleOpen: toggleBackdrop,
    Dialog: CustomBackdrop,
    DialogProps: CustomBackdropProps,
  } = useCustomBackdrop({ initialOpen: false });

  return (
    <>
      <CustomBackdrop {...CustomBackdropProps} open={isSubmitting} />
      <DialogContent>
        <div>
          <Typography variant="h2" paragraph>
            TrunkingIO
          </Typography>
          <Typography variant="body1" paragraph>
            This will provision a new TrunkingIO account for you. Choose your
            plan level below:
          </Typography>
          <br />
          <Button
            variant="contained"
            color="info"
            onClick={handleSubmit(onSubmit)}
          >
            Choose Basic Plan
          </Button>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <div>
          <Button variant={'outlined'} color={'error'} onClick={onCancel}>
            Cancel
          </Button>
        </div>
        {/* @ts-ignore */}
        {saveError ? (
          <div>
            <Typography color={'warning.dark'} variant="body2">
              {/* @ts-ignore */}
              {saveError.message}
            </Typography>
          </div>
        ) : null}
      </DialogActions>
    </>
  );
};

export const useIntegrationAddDialog = DialogBuilder(IntegrationAddDialog);

export default IntegrationAddDialog;
