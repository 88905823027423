import { Phone as PhoneIcon } from '@mui/icons-material';
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useLookupContact } from '../../../../data/webphone/webphoneSlice';
import { ContactDisplay } from '../ContactDisplay';
import { ViewContainer } from '../ViewContainer';
import useStyles from './styles';
import { Grid, Typography, Button, Box, Avatar } from 'app/design';

import { useWebsocketBinding } from 'app/utilities/websocketBinding';

import { sortBy } from 'lodash';
import { useCallsParkedQuery } from 'app/hooks/queries/calls';
import { useWebphoneContext } from '../../Store';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';

const ParkingView = props => {
  const { show } = props;

  const { data, isFetching, refetch } = useCallsParkedQuery();

  const parked_calls = data?.callsParked ?? [];
  // console.log('parked_calls', parked_calls);

  // const [parked_calls, setParkedcalls] = useState([
  // {
  //   2: {
  //     attended: false,
  //     call_id: 'f933fba4-bfc2-4bcb-8e71-e89fa7fac4fe',
  //     cid_name: '6027059885',
  //     cid_number: '6027059885',
  //     cid_uri: '+18152165403@py47sn3.sb.2600hz.com',
  //     from_tag: 'cj76eyarrS07c',
  //     hold_media:
  //       '/0a1d8dcccca4138ddb9f76782017769c/1f9ef0df69844458797876df9da645c4',
  //     node: 'freeswitch@apps001.fmt.sb.2600hz.com',
  //     presence_id: '*31@py47sn3.sb.2600hz.com',
  //     presence_realm: 'py47sn3.sb.2600hz.com',
  //     presence_type: 'early',
  //     presence_user: '*31',
  //     ringback_id: '20fc455d4a1673cec9df852b4ce6a320',
  //     slot_call_id: '897ff3f5c1cf9e49770eeba1df64e065',
  //     switch_uri: 'sip:192.81.135.31:11000',
  //     to_tag: 'gK08928db6',
  //   },
  // },
  // ]);

  const [state, dispatch] = useWebphoneContext();
  const { sip, makeCall } = state; // TODO: put parking state in Webphone? use websockets instead of http?

  // const { users } = useSelector(prepareUsersState);

  //   const orderedParkedCalls = sortBy(
  //     parked_calls,
  //     call => Object.keys(call).find(c => true), // gets first key in object (only 1 key expected!)
  //   );
  const orderedParkedCalls = sortBy(parked_calls, 'num');

  const updatedParked = async () => {
    refetch();
  };

  // useEffect(() => {

  // dispatch({
  //   type: 'sip:update',
  //   payload: {
  //     parked_calls: data,
  //   },
  // });},[data])

  useEffect(() => {
    updatedParked();
  }, [show]);

  useWebsocketBinding(
    ['call.PARK_PARKED.*', 'call.PARK_RETRIEVED.*', 'call.PARK_ABANDONED.*'],
    event => {
      updatedParked();
    },
  );

  // don't show anything if no parked calls
  if (!orderedParkedCalls.length) return null;

  return (
    <ViewContainer title={'Parked Calls'} show={show}>
      {orderedParkedCalls.map((call, i) => (
        <ParkedCall key={i} call={call} />
      ))}
      {!parked_calls.length ? (
        <div style={{ padding: 4, height: '100%' }}>
          <Grid
            container
            style={{ height: '100%' }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h6">No Parked Calls</Typography>
            </Grid>
          </Grid>
        </div>
      ) : (
        ''
      )}
    </ViewContainer>
  );

  // return (
  //   <div
  //     style={{
  //       height: '100%',
  //       overflowY: 'scroll',
  //       display: show ? 'block' : 'none',
  //     }}
  //     onClick={refetch}
  //   >
  //     {orderedParkedCalls.map((call, i) => (
  //       <ParkedCall key={i} call={call} />
  //     ))}
  //     {!parked_calls.length ? (
  //       <div style={{ padding: 4, height: '100%' }}>
  //         <Grid
  //           container
  //           style={{ height: '100%' }}
  //           justifyContent="center"
  //           alignItems="center"
  //         >
  //           <Grid item>
  //             <Typography variant="h6">No Parked Calls</Typography>
  //           </Grid>
  //         </Grid>
  //       </div>
  //     ) : (
  //       ''
  //     )}
  //     {/* {channels.map((channel, i) => {
  //       const channelUser = users.find((u) => u.id === channel.owner_id);
  //       return (
  //         <div key={i}>
  //           Channel: {channel.owner_id} {channelUser?.extra?.fullName}
  //         </div>
  //       );
  //     })} */}
  //   </div>
  // );
};

const ParkedCall = props => {
  // NEW:
  //   {
  //     id: '',
  //     num: '4',
  //     doc: {
  //       attended: false,
  //       call_id: 'f933fba4-bfc2-4bcb-8e71-e89fa7fac4fe',
  //       ....(same as OLD below)
  //     },
  //     account_id:''
  //   };

  // OLD:
  // {
  //   2: {
  //     attended: false;
  //     call_id: 'f933fba4-bfc2-4bcb-8e71-e89fa7fac4fe';
  //     cid_name: '6027059885';
  //     cid_number: '6027059885';
  //     cid_uri: '+18152165403@py47sn3.sb.2600hz.com';
  //     from_tag: 'cj76eyarrS07c';
  //     hold_media: '/0a1d8dcccca4138ddb9f76782017769c/1f9ef0df69844458797876df9da645c4';
  //     node: 'freeswitch@apps001.fmt.sb.2600hz.com';
  //     presence_id: '*31@py47sn3.sb.2600hz.com';
  //     presence_realm: 'py47sn3.sb.2600hz.com';
  //     presence_type: 'early';
  //     presence_user: '*31';
  //     ringback_id: '20fc455d4a1673cec9df852b4ce6a320';
  //     slot_call_id: '897ff3f5c1cf9e49770eeba1df64e065';
  //     switch_uri: 'sip:192.81.135.31:11000';
  //     to_tag: 'gK08928db6';
  //   }
  // }
  const { call } = props;
  const [contact] = useLookupContact(call.doc.cid_number);

  const [state, dispatch] = useWebphoneContext();
  const { sip, makeCall } = state;

  //   const slot = Object.keys(call).find(c => true);
  const slot = call.num;
  const details = call.doc;
  //   let details = Object.keys(call).length ? call[slot] : null;

  const handlePickupPark = () => {
    const target = `*5${slot}`;
    makeCall(target);

    dispatch({
      type: 'sip:update',
      payload: {
        mainTab: 'calls',
      },
    });
  };

  const classes = useStyles();

  console.log('parked call', call);

  return (
    <Box sx={{ p: 2 }}>
      <Grid container wrap={'nowrap'} spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="h6" bold>
            {slot}
          </Typography>
        </Grid>
        <Grid item>
          {/*<PhoneNumberDisplay size="small" ptn={details?.cid_number} />*/}
          {contact ? (
            <ContactDisplay contact={contact} number={call.doc.cid_number} />
          ) : (
            <Grid
              container
              alignItems="center"
              wrap={'nowrap'}
              columnSpacing={2}
            >
              <Grid item>
                <Avatar sx={{ width: 40, height: 40 }} />
              </Grid>
              <Grid item sx={{ flex: 1 }}>
                <Grid container columnSpacing={1}>
                  <Grid item>
                    <Typography
                      sx={{
                        display: 'inline-block',
                        fontWeight: 700,
                        fontSize: '16px',
                      }}
                    >
                      <span>
                        {call.doc.cid_name === call.doc.cid_number
                          ? 'No Caller ID'
                          : call.doc.cid_name}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Typography variant="body1">
                  <PhoneNumberDisplay
                    size="small"
                    allowExtensions
                    ptn={call.doc.cid_number}
                    width="auto"
                  />
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={4}>
          <Button
            size="small"
            variant="contained"
            color="success"
            sx={{ borderRadius: 10 }}
            fullWidth
            // startIcon={<PhoneIcon />}
            onClick={handlePickupPark}
          >
            <PhoneIcon />
            {/*Answer*/}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <div className={classes.call}>
      <Grid container spacing={2} justify="space-between" alignItems="center">
        <Grid item>
          <Typography
            variant="h6"
            bold
            style={{ borderRadius: 2, background: '#rgba(0,0,0,0.08)' }}
          >
            {slot}
          </Typography>
        </Grid>
        <Grid item>
          <PhoneNumberDisplay size="small" ptn={details?.cid_number} />
        </Grid>
        <Grid item>
          <Button
            size="small"
            variant="outlined"
            color="success"
            onClick={handlePickupPark}
          >
            Answer
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ParkingView;
