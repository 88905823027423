import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { Account, AccountUpdatePartialDoc } from 'types/account';
import { UserUpdatePartialDoc } from 'types/user';

export const useUpdateAccountPartial = () => {
  const authState = useAuthSelector();
  return useMutation<any, AxiosError, AccountUpdatePartialDoc, unknown>(
    (accountPartialDoc: AccountUpdatePartialDoc) =>
      sdk.account.mutate.updatePartial(
        accountPartialDoc,
        authState?.auth_token,
      ),
  );
};
