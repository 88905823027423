import React, { memo, useState, useRef, useContext, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';
import { NodeEditDialog } from '../../../Dialogs';
import { IvrMenuEventEmitterContext } from '../../../IvrBuilder';
import { NodeInfoDisplay } from '../common/NodeInfoDisplay';

import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import {
  Grid,
  Typography,
  Button,
  Divider,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from 'app/design';

import {
  NavigateNext as NavigateNextIcon,
  Today as TodayIcon,
  Redo as RedoIcon,
  Edit as EditIcon,
  Eject as EjectIcon,
  Link as LinkIcon,
  ChevronRight as ChevronRightIcon,
  TransferWithinAStation as TransferWithinAStationIcon,
  AddToPhotos as AddToPhotosIcon,
} from 'app/design/icons-material';

import { QuickFinderTemplateDialog } from 'app/components/QuickFinderTemplateDialog';
import { useHistory } from 'react-router-dom';

import { Box } from '../common/Box';
import { MenuButton } from '../common/MenuButton';
import { InTemplate } from '../common/InTemplate';
import { VariablesEditor } from '../common/VariablesEditor';

import { useSelector } from 'react-redux';
// import { KazooSDK } from '@KazooSDK';
import { getAtPath, setAtPath, useToggleReducer } from 'app/utilities';

import { useHoverDirty } from 'react-use';
import { cloneDeep, toInteger } from 'lodash';

import { useCallflowQuery } from 'app/hooks/queries/callflow';

// import { USER_ADDABLE_COMPONENTS } from '../../../../Strategies/base/GenericDefault';
// import * as OptionComponents from '../../../../Strategies/components';

// import { AutocompleteTemplate } from '../../../../AutocompleteTemplate';

import { StrategySelect } from '../Insert';

import { GET_A_VAR_TYPES, SET_A_VAR_TYPES } from '../common/VariablesEditor';

// import { ModifyDialog } from './ModifyDialog';
import {
  InsertAfterButton,
  InsertBeforeButton,
  RemoveButton,
  ModifyButton,
} from '../Insert';

import builtinTemplates from '../common/TemplateList/builtinTemplates';

import { buildOutputCallflowFromTemplate } from './buildOutputCallflowFromTemplate';

// import store from '../../../../../../../../store';

const TemplateNode = memo(({ data }) => {
  const {
    insertBefore,
    insertAfterData, // used for removal
    moduleItem,
    callflow,
    setCallflow,
    modifyPath,
    templateParent,
    templateRef,
  } = data;

  const history = useHistory();

  const [showModifyDialog, setShowModifyDialog] = useState(null);
  const setShowModifyDialogWrap = setTo => () => setShowModifyDialog(setTo);

  const boxRef = useRef(null);
  const isHovering = useHoverDirty(boxRef);
  // const actAsHovering = isHovering || anchorEl || anchorFakeEl; // try to detect if a menu was selected

  const isBuiltin =
    moduleItem?.data?.templateId?.indexOf('builtin:') === 0 ? true : false;
  const builtinTemplateLatest = isBuiltin
    ? builtinTemplates.find(t => t.id === moduleItem?.data?.templateId)
    : null;

  // const callflows = useSelector(state => state.lists.callflows.list);

  // Load template via query
  const {
    data: templateCallflowResult,
    isLoading,
    isFetched,
  } = useCallflowQuery({ id: isBuiltin ? null : moduleItem?.data?.templateId });

  // console.log('templateCallflowResult:', templateCallflowResult);
  const templateCallflow = isBuiltin
    ? moduleItem?.data?.outputCallflow
    : templateCallflowResult;

  const applyTemplate = async () => {
    // load and build Template
    const template = isBuiltin ? builtinTemplateLatest : templateCallflowResult;
    console.log('template1:', template);

    if (!template) {
      alert('Unable to find Template1');
      return;
    }

    const newData = {
      ...moduleItem.data,
      outputCallflow: cloneDeep(isBuiltin ? template : template.doc),
    };
    newData.outputCallflow.id = 'inline';
    newData.outputCallflow.changed = true; // to force a rebuild the first time

    // TODO: build outputCallflow now?
    // - apply variables, etc.
    // for each strategy element, see if there are Variables, process them and build as we go...
    newData.outputCallflow = await buildOutputCallflowFromTemplate({
      rootCallflow: callflow, // root variables (and callflow.strategy.type=template -> callflow.strategy.simple.variables)
      templateCallflow: newData.outputCallflow, // template-level variables
    });

    setAtPath(callflow, `${modifyPath}.data`, newData);
    setCallflow({ ...callflow }, { name: 'Template Re-built' });
  };

  const handleReApply = () => {
    applyTemplate();
  };

  // action for onCreate
  const ee = useContext(IvrMenuEventEmitterContext);
  const onNodeCreated = data => {
    if (data.optsPath === modifyPath) {
      setShowModifyDialog(true);
    }
  };
  useEffect(() => {
    ee.on('node-created', onNodeCreated);
    return () => {
      ee.removeListener('node-created', onNodeCreated);
    };
  }, []);

  const authState = useAuthSelector();

  // Builtin dialog

  const [showBuiltInChooserDialog, toggleShowBuiltInChooserDialog] =
    useToggleReducer(false);

  // Finder Dialog
  const [showFinderDialog, toggleShowFindTemplateDialog] =
    useToggleReducer(false);

  const onChangeTemplateSelectCustom = async selected => {
    const [templateId] = selected;
    // setAtPath(tmpModuleItem, `data.templateId`, templateId); // newValue?.value === id
    // setModifyModuleItem({ ...tmpModuleItem });
    toggleShowFindTemplateDialog();

    // expecting it to be passed back from Select?
    const templateResp = await sdk.callflow.query.callflowById(
      { id: templateId },
      { authToken: authState.auth_token },
    );
    if (!templateResp?.doc) {
      // invalid
      console.error('Invalid template', templateId);
      return;
    }

    let templateDoc = templateResp?.doc;

    console.log('template2:', templateDoc);
    if (!templateDoc) {
      alert('Unable to find Template');
      return;
    }
    const newData = {
      continueTo: [],
      ...moduleItem.data,
      templateId,
      outputCallflow: cloneDeep(templateDoc),
    };
    newData.outputCallflow.id = 'inline';
    newData.outputCallflow.changed = true; // to force a rebuild the first time
    // build outputCallflow
    newData.outputCallflow = await buildOutputCallflowFromTemplate({
      rootCallflow: callflow, // root variables
      templateCallflow: newData.outputCallflow, // template-level variables
    });
    moduleItem.data = newData;
    setAtPath(callflow, `${modifyPath}`, moduleItem);
    // setAtPath(callflow, `${modifyPath}.data`, newData);
    setCallflow({ ...callflow }, { name: 'Applied new Template' });
    // onClose();
  };

  const onChangeTemplateSelectBuiltIn = async templateDoc => {
    console.log('builtin template:', templateDoc);
    // const [templateId] = selected;
    // // setAtPath(tmpModuleItem, `data.templateId`, templateId); // newValue?.value === id
    // // setModifyModuleItem({ ...tmpModuleItem });
    toggleShowFindTemplateDialog();

    const newData = {
      continueTo: [],
      ...moduleItem.data,
      templateId: templateDoc.id,
      outputCallflow: cloneDeep(templateDoc),
    };
    newData.outputCallflow.id = 'inline';
    newData.outputCallflow.changed = true; // to force a rebuild the first time
    // build outputCallflow
    newData.outputCallflow = await buildOutputCallflowFromTemplate({
      rootCallflow: callflow, // root variables
      templateCallflow: newData.outputCallflow, // template-level variables
    });
    moduleItem.data = newData;
    setAtPath(callflow, `${modifyPath}`, moduleItem);
    // setAtPath(callflow, `${modifyPath}.data`, newData);
    setCallflow({ ...callflow }, { name: 'Applied new Template' });
    // onClose();
  };

  const handleClear = () => {
    // setAtPath(tmpModuleItem, `data.templateId`, null); // newValue?.value === id
    // setModifyModuleItem({ ...tmpModuleItem });
    // toggleShowFindTemplateDialog();
  };

  return (
    <>
      {showFinderDialog ? (
        <QuickFinderTemplateDialog
          onSelect={onChangeTemplateSelectCustom}
          onCancel={toggleShowFindTemplateDialog}
          // onClear={handleClear}
          initialSelected={[]}
          // selectionTitle={''}
          // allowSelectNone
        />
      ) : null}
      {showBuiltInChooserDialog && (
        <BuiltInChooserDialog
          onCancel={toggleShowBuiltInChooserDialog}
          onChoose={onChangeTemplateSelectBuiltIn}
        />
      )}
      <Box
        pref={boxRef}
        borderColor="#5E35B1"
        position={data?.position}
        data={data}
      >
        <Handle
          type="target"
          position="top"
          style={{ background: '#555', visibility: 'hidden' }}
        />
        <NodeInfoDisplay
          type={'Template'}
          icon={<AddToPhotosIcon />}
          title={
            templateCallflow ? (
              `${isBuiltin ? templateCallflow.name : templateCallflow.doc.name}`
            ) : (
              // <Link onClick={setShowModifyDialogWrap(true)}>
              //   Select Template
              // </Link>
              <>No template chosen</>
            )
          }
        />
        <MenuButton
          data={data}
          mode={isHovering ? 'normal' : 'ignore'}
          menuItems={[
            moduleItem?.data?.templateId
              ? {
                  type: 'custom',
                  props: {
                    customText: 'Re-Apply Template From Source',
                    customSecondaryText: isBuiltin
                      ? builtinTemplateLatest.version ==
                        templateCallflow.version
                        ? 'Up-to-date'
                        : 'Update Available!'
                      : moduleItem?.data?.outputCallflow?.version ==
                        templateCallflow?.version
                      ? 'Up-to-date'
                      : 'Update Available!',
                    customIcon: <RedoIcon />,
                    onClick: handleReApply,
                  },
                }
              : null,
            {
              type: 'custom',
              props: {
                customText: 'Switch to Built-In Template',
                customIcon: <EditIcon />,
                onClick: toggleShowBuiltInChooserDialog,
              },
            },
            {
              type: 'custom',
              props: {
                customText: 'Switch to Custom Template',
                customIcon: <EditIcon />,
                onClick: toggleShowFindTemplateDialog,
              },
            },
            // 'add',
            'add-before',
            'duplicate',
            'remove',
            {
              type: 'custom',
              props: {
                customText: 'Eject',
                customIcon: <EjectIcon />,
                onClick: () => {
                  alert('Ejecting not yet enabled');
                },
              },
            },
            moduleItem?.data?.templateId && !isBuiltin
              ? {
                  type: 'custom',
                  props: {
                    customText: 'Go to Template',
                    customIcon: <LinkIcon />,
                    onClick: () => {
                      history.push(
                        `/user_portal/admin_strategy?strategyCallflowId=${moduleItem.data.templateId}`,
                      );
                      // window.location.reload();
                    },
                  },
                }
              : null,
          ]}
        />
        {/*  </>*/}
        {/*</div>*/}
        <Handle
          type="source"
          position="bottom"
          // id="b"
          style={{
            top: 'auto',
            bottom: 1,
            background: '#555',
            visibility: 'hidden',
          }}
        />
        <InTemplate template={moduleItem.data} />
      </Box>
    </>
  );
});

const BuiltInChooserDialog = ({ onChoose, onCancel }) => {
  return (
    <Dialog open>
      <DialogTitle>Built-in Templates</DialogTitle>
      <Divider />
      <DialogContent>
        <List dense>
          {builtinTemplates.map(template => (
            <ListItem key={template.id} onClick={() => onChoose(template)}>
              <ListItemText
                primary={template.name}
                secondary={''}
                sx={{ cursor: 'pointer' }}
              />
              <ListItemSecondaryAction>
                <IconButton size="small" onClick={() => onChoose(template)}>
                  <ChevronRightIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button onClick={onCancel} color="warning">
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default TemplateNode;
