import { Button } from 'app/design';
import {
  CreateMenuDialog,
  useEditMenuDialog,
} from 'app/components/EditMenuDialog';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';

interface CreateGroupButtonProps {
  onComplete?: (menuId?: any) => void;
}

const AddMenuButton = ({ onComplete }: CreateGroupButtonProps) => {
  const { Dialog, DialogProps, toggleOpen } = useEditMenuDialog();
  const handleComplete = (mediaId?: string | null) => {
    toggleOpen();
    onComplete && onComplete(mediaId);
  };
  return (
    <>
      <Dialog
        {...DialogProps}
        onComplete={handleComplete}
        onCancel={toggleOpen}
      />
      <Button variant={'contained'} onClick={toggleOpen}>
        Add Menu
      </Button>
    </>
  );
};

export default AddMenuButton;
