import { EmptyTableCell } from 'app/components/EmptyTableCell';
import { QuickFinderGenericDialog } from 'app/components/QuickFinderGenericDialog';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { useListPhoneNumbersQuery } from 'app/hooks/queries/phoneNumber';
import {
  QUERY_SEARCH_INPUT_CHANGED,
  useQueryPaginationReducer,
} from 'app/utilities/useQueryPaginationReducer';
import * as React from 'react';
import { useMemo } from 'react';
import { QuickFinderDialogProps } from 'types/components';
import { PhoneNumber } from 'types/phoneNumber';

const QuickFinderPhoneNumberDialog = ({
  onSelect,
  mutationLoading = false,
  mutationLoadingLabel,
  errorMessage,
  onCancel,
  onClear,
  multiple = false,
  allowSelectNone = false,
  initialSelected,
  selectionTitle,
  exclude,
}: QuickFinderDialogProps) => {
  // query pagination state
  const [{ queryPageSize, queryPageIndex, querySearchInput }, dispatch] =
    useQueryPaginationReducer(0, 5);

  const columns = useMemo(
    () => [
      {
        Header: 'Phone Number',
        id: 'number',
        accessor: (phoneNumber: PhoneNumber) => phoneNumber.doc.id,
        Cell: ({ value: number }) => {
          // const { original: phoneNumber }: { original: PhoneNumber } = row;

          return <PhoneNumberDisplay width="auto" ptn={number} />;
        },
        disableToggle: true,
        headerProps: {
          width: 200,
        },
      },
      // {
      //   Header: 'Internal Label',
      //   id: 'internalLabel',
      //   accessor: (phoneNumber: PhoneNumber) => phoneNumber.doc.name,
      //   Cell: ({ value: name }) => {
      //     return name ?? <EmptyTableCell label={'not set'} />;
      //   },
      //   disableToggle: true,
      //   disableSortBy: true,
      //   headerProps: {
      //     width: 220,
      //   },
      // },
      {
        Header: 'External Caller ID Name',
        id: 'oCID',
        accessor: (phoneNumber: PhoneNumber) =>
          phoneNumber.doc.cnam?.display_name,
        Cell: ({ value: name }: { value: string }) => {
          return name ? name : <EmptyTableCell label={'not set'} />;
        },
        disableToggle: true,
        headerProps: {
          width: 150,
        },
      },
      {
        Header: 'e911 Address',
        id: 'e911',
        accessor: (phoneNumber: PhoneNumber) =>
          phoneNumber.doc.e911?.street_address,
        Cell: ({ value: e911 }: { value: string }) => {
          return e911 ? e911 : <EmptyTableCell label={'not set'} />;
        },
        disableToggle: true,
        headerProps: {
          width: 150,
        },
      },
    ],
    [],
  );

  // phoneNumber list
  const phoneNumberListQuery = useListPhoneNumbersQuery(
    queryPageIndex * queryPageSize,
    queryPageSize,
    [['number', 'asc']],
    { searchInput: querySearchInput },
  );
  const { phoneNumbers, totalCount = 0 } = phoneNumberListQuery.data ?? {};

  const handleSearch = (searchInput: string) => {
    if (searchInput === querySearchInput) {
      phoneNumberListQuery.refetch();
    } else {
      dispatch({ type: QUERY_SEARCH_INPUT_CHANGED, payload: searchInput });
    }
  };

  return (
    <QuickFinderGenericDialog
      // dialog
      title={multiple ? 'Select Multiple Numbers' : 'Select Number'}
      mutationLoading={mutationLoading}
      mutationLoadingLabel={mutationLoadingLabel}
      onSelect={onSelect}
      onCancel={onCancel}
      onClear={onClear}
      errorMessage={errorMessage}
      selectionTitle={selectionTitle}
      // table
      getRowId={(row: PhoneNumber) => row.id}
      searchPlaceholder={'Search numbers...'}
      totalCount={totalCount}
      columns={columns}
      data={phoneNumbers ?? []}
      multiple={multiple}
      allowSelectNone={allowSelectNone}
      initialSelected={initialSelected}
      exclude={exclude}
      onSearch={handleSearch}
      // query
      queryIsFetching={phoneNumberListQuery.isFetching}
      queryIsLoading={phoneNumberListQuery.isLoading}
      queryPageSize={queryPageSize}
      queryPageIndex={queryPageIndex}
      queryPaginationDispatch={dispatch}
    />
  );
};

export default QuickFinderPhoneNumberDialog;
