import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Autocomplete,
  Divider,
  TextField,
  Typography,
  Chip,
} from 'app/design';
import { useUpdateVmboxPartial } from 'app/hooks/mutations/vmbox';
import { useVmboxQuery } from 'app/hooks/queries/vmbox';
import { isEqual } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useImmer } from 'use-immer';

// interface declaring which props are required/allowed
interface NotificationEmailsDialogProps {
  vmboxId: string;
  onCancel: () => void;
  onComplete: () => void;
}

const NotificationEmailsDialog = ({
  vmboxId,
  onCancel,
  onComplete,
}: NotificationEmailsDialogProps) => {
  const { data: vmbox, isLoading: vmboxIsLoading } = useVmboxQuery({
    id: vmboxId,
    options: {
      onSuccess: vmbox => {
        setDefaultEmails(vmbox.doc.notify_email_addresses ?? []);
      },
    },
  });

  const updateVmbox = useUpdateVmboxPartial();
  const [defaultEmails, setDefaultEmails] = useImmer<string[]>(
    vmbox?.doc?.notify_email_addresses ?? [],
  );

  const handleSubmit = async (emails: string[]) => {
    // update form defaults in case of error
    setDefaultEmails(emails);

    const updateVmboxPromise = updateVmbox.mutateAsync(
      {
        id: vmboxId,
        notify_email_addresses: emails.filter(email => !!email), // remove empty strings
      },
      {
        onSuccess: () => {
          onComplete();
        },
      },
    );

    // resolve promise with toast notifications
    toast.promise(updateVmboxPromise, {
      pending: 'Updating Voicemail Box notification email list...',
      error: 'Failed to update Voicemail Box notification email list.',
      success: 'Voicemail Box notification email list updated!',
    });
  };

  return (
    <Dialog open={true} fullWidth maxWidth={'xs'}>
      {vmboxIsLoading ? (
        <DialogInProgress
          title={'Loading Voicemail Box notification email list...'}
        />
      ) : updateVmbox.isLoading ? (
        <DialogInProgress
          title={'Updating Voicemail Box notification email list...'}
        />
      ) : (
        <>
          <DialogTitle>Edit Notification Email List</DialogTitle>
          <Divider />
          {vmbox ? (
            <NotificationEmailsForm
              initialEmails={defaultEmails}
              onCancel={onCancel}
              onSubmit={handleSubmit}
              errorMessage={
                updateVmbox.error
                  ? `${updateVmbox.error.message} ${
                      updateVmbox.error.response
                        ? `: ${updateVmbox.error.response?.statusText}`
                        : ''
                    }`
                  : undefined
              }
            />
          ) : (
            <>
              <DialogContent>
                <Typography color={'error'}>
                  Invalid Voicemail Box ID
                </Typography>
              </DialogContent>
              <Divider />
              <DialogActions sx={{ justifyContent: 'left' }}>
                <Button variant={'outlined'} color={'error'} onClick={onCancel}>
                  Cancel
                </Button>
              </DialogActions>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

interface NotificationEmailsFormProps {
  initialEmails: string[];
  onCancel: () => void;
  onSubmit: (formValues: string[]) => void;
  errorMessage?: string;
  startDirty?: boolean;
}

const NotificationEmailsForm = ({
  initialEmails,
  onCancel,
  onSubmit,
  errorMessage,
}: NotificationEmailsFormProps) => {
  const [value, setValue] = useState(initialEmails);
  const [emailsValid, setEmailsValid] = useState(true);

  const handleChange = (_e, value) => {
    setValue(value);
    setEmailsValid(true); // hide error display once typing
  };

  const handleSubmit = () => {
    // check if every email is valid
    value.every(email => validateEmail(email.trim()) || email.trim() === '')
      ? onSubmit(value)
      : setEmailsValid(false);
  };

  return (
    <>
      <DialogContent>
        <>
          <Autocomplete
            freeSolo
            multiple
            defaultValue={initialEmails}
            options={[]}
            onChange={handleChange}
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip
                  variant="outlined"
                  color={
                    emailsValid
                      ? undefined
                      : validateEmail(option)
                      ? 'success'
                      : 'error'
                  }
                  size={'large'}
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Notification Emails"
                placeholder="Emails"
              />
            )}
          />
          {emailsValid ? (
            <Typography variant={'caption'} color={'textSecondary'}>
              * Separate each email by a new line
            </Typography>
          ) : (
            <Typography variant={'caption'} color={'error'}>
              One or more emails are invalid. Please make sure each email is
              separated by a new line and correctly entered.
            </Typography>
          )}
        </>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button variant={'outlined'} color={'error'} onClick={onCancel}>
          Cancel
        </Button>
        {errorMessage ? (
          <Typography color={'error'}>{errorMessage}</Typography>
        ) : null}
        <Button
          variant={'outlined'}
          color={'success'}
          disabled={
            emailsValid &&
            isEqual(
              value.filter(email => email),
              initialEmails,
            )
          }
          onClick={handleSubmit}
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default NotificationEmailsDialog;
