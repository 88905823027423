import React, { useEffect, useCallback, useState, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Hidden,
  Typography,
  Tabs,
  Tab,
  Paper,
  Button,
  Chip,
  Link,
  Portal,
  Tooltip,
} from 'app/design';

import { Star as StarIcon } from 'app/design/icons-material';
import { Search as SearchIcon } from 'app/design/icons-material';

import Tour from 'reactour';

import { useLocalSelector, useLocalSlice } from 'app/data/local';
import { useAccountQuery } from 'app/hooks/queries/account';
import { useHistory } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useEffectOnce, useHoverDirty } from 'react-use';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { useGlobalTour } from 'app/components/Tourguide/Helper';
import Lottie from 'react-lottie-player';

const disableBody = target => disableBodyScroll(target);
const enableBody = target => enableBodyScroll(target);

const OnboardingTour = props => {
  const { onClose, tourEvents } = props;

  const history = useHistory();
  // const dispatch = useDispatch();
  // const { callflows } = useSelector((state) => state.lists);

  const [{ openTour }] = useGlobalTour();
  const [stepNum, setStepNum] = useState(0); // TODO: use redux?
  const [update, setUpdate] = useState(0); // TODO: use redux?

  const runningRef = useRef(null);

  const handleNextStep = () => {
    setStepNum(stepNum + 1);
  };
  const handlePrevStep = () => {
    setStepNum(stepNum - 1);
  };
  const handleAnyStep = curr => {
    console.log('curr:', curr);
  };

  const onAfterOpen = target => {
    // disableBody(target);
    console.log('onAfterOpen');
    if (window.location.pathname.indexOf('/admin') === -1) {
      history.push('/admin');
    }
  };

  const onRequestClose = target => {
    console.log('onRequestClose');
    // setIsTourOpen(false);
    // update account tour_complete
    onClose && onClose();
  };

  const handleUpdate = () =>
    setUpdate(v => {
      return v + 1;
    });

  // handle programatic "update" (usually triggered by a button)
  useEffect(() => {
    tourEvents.on('update', handleUpdate);
    return () => {
      tourEvents.off('update', handleUpdate);
    };
  }, []);

  const tourSteps = [
    {
      selector: '[data-tour-start]',
      style: {
        maxWidth: 'auto !important',
      },
      content: (
        <Step1
          onClose={() => openTour('setup1')}
          onCloseSkip={() => openTour(null)}
        />
      ),
      action: node => {
        // history.push('/admin/dashboard');
      },
    },
  ];

  return (
    <>
      <Tour
        steps={tourSteps}
        // isOpen={isTourOpen}
        isOpen
        onRequestClose={onRequestClose}
        onAfterOpen={onAfterOpen}
        // onBeforeClose={enableBody}
        update={`${window.location.pathname}-${update}`}
        goToStep={stepNum}
        nextStep={handleNextStep}
        prevStep={handlePrevStep}
        getCurrentStep={handleAnyStep}
        showNumber={false}
        showNavigation={false}
        disableInteraction={true}
        showButtons={false}
        showCloseButton={false}
        closeWithMask={false}
        disableDotsNavigation={true}
        // lastStepNextButton={
        //   <Button variant="contained" color="success">
        //     Let's start!
        //   </Button>
        // }
        badgeContent={(curr, tot) => `${curr} of ${tot}`}
        // inViewThreshold={10000} // no real change it seemed like
        scrollDuration={1000}
        rounded={4}
        className={'tmp-tour-class'}
        maskSpace={2}
        maskClassName={'tmp-mask-class'}
      />
    </>
  );
};

const Step1 = ({ onClose, onCloseSkip }) => {
  const { channels } = useLocalSelector();

  const [showAnimationFireworks, setShowAnimationFireworks] = useState(false);
  const { data: account, isLoading: accountIsLoading } = useAccountQuery();
  const number = account?.doc.caller_id?.external?.number;

  useEffect(() => {
    if (channels.length) {
      setShowAnimationFireworks(true);
    }
  }, [channels]);

  return (
    <>
      <Box sx={{ minWidth: 600, p: 4, textAlign: 'center' }}>
        {/* {showAnimation ? ( */}
        <Portal>
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              transform: 'scaleY(-1)',
              zIndex: 10000,
            }}
          >
            <Lottie
              path="https://assets3.lottiefiles.com/private_files/lf30_5aubt2fy.json"
              // background="transparent"
              speed={0.3}
              delay
              style={{
                width: '100%',
                height: '100%',
                // marginTop: -50,
                // marginLeft: -50,
                // animationFillMode: 'forwards',
              }}
              // hover
              play
              loop={false}
              rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
              // onComplete={hideAnimation}
            />
          </Box>
        </Portal>
        {/* ) : null} */}
        {channels?.length ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              // transform: 'scaleY(-1)',
              // zIndex: 10000,
            }}
          >
            <Lottie
              path="https://assets10.lottiefiles.com/packages/lf20_ea475x1k.json"
              // background="transparent"
              speed={1}
              delay
              style={{
                width: '100%',
                height: '100%',
                // marginTop: -50,
                // marginLeft: -50,
                // animationFillMode: 'forwards',
              }}
              // hover
              play
              // loop={false}
              rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
              // onComplete={hideAnimation}
            />
          </Box>
        ) : null}
        <Typography variant="h1" color="success.main">
          All Good to Go!
        </Typography>
        <Tooltip
          open={channels?.length}
          title={
            <Box>
              {channels.map(ch => (
                <Typography key={ch.id} variant="body1">
                  Incoming Call:{' '}
                  {ch.listing.custom_channel_vars['E164-Origination'] ? (
                    <PhoneNumberDisplay
                      ptn={ch.listing.custom_channel_vars['E164-Origination']}
                      allowExtension
                    />
                  ) : (
                    'Internal Caller'
                  )}
                </Typography>
              ))}
            </Box>
          }
          arrow
          placement="top"
          componentsProps={{ popper: { disablePortal: true } }}
        >
          <Typography variant="h1" sx={{ mt: 4, mb: 1, fontSize: 36 }}>
            {number ? <PhoneNumberDisplay ptn={number} /> : '-'}
          </Typography>
        </Tooltip>
        <Box sx={{ mb: 4 }}>
          <Typography variant="caption">
            (Try calling your new number using your personal phone!)
          </Typography>
        </Box>
        <Box sx={{ margin: '0 auto', maxWidth: 400, textAlign: 'left' }}>
          <Grid container columnSpacing={1} alignItems="center" sx={{ mb: 2 }}>
            <Grid item>
              <StarIcon size={44} sx={{ color: '#FFAC00' }} />
            </Grid>
            <Grid item sx={{ flex: 1 }}>
              <Typography variant="h2">
                Make calls with your built-in webphone
              </Typography>
            </Grid>
          </Grid>
          <Grid container columnSpacing={1} alignItems="center" sx={{ mb: 2 }}>
            <Grid item>
              <StarIcon size={44} sx={{ color: '#FFAC00' }} />
            </Grid>
            <Grid item sx={{ flex: 1 }}>
              <Typography variant="h2">
                Personalize your incoming calls
              </Typography>
            </Grid>
          </Grid>
          <Grid container columnSpacing={1} alignItems="center" sx={{ mb: 2 }}>
            <Grid item>
              <StarIcon size={44} sx={{ color: '#FFAC00' }} />
            </Grid>
            <Grid item sx={{ flex: 1 }}>
              <Typography variant="h2">
                Make and receive calls on the mobile app
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <br />
        <Button onClick={onClose} variant="contained">
          Start the Product Tour!
        </Button>
        <br />
        <br />
        or,&nbsp;
        <Link onClick={onCloseSkip}>
          skip the tour, let me get started immediately.
        </Link>
      </Box>
    </>
  );
};

export default OnboardingTour;
