import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Link,
  Typography,
  ThemeProvider,
  Chip,
} from 'app/design';

import { Link as RouterLink } from 'react-router-dom';

import {
  Search as SearchIcon,
  Person as PersonIcon,
} from 'app/design/icons-material';

import { ButtonDropdownMenu } from 'app/components/ButtonDropdownMenu';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { useContactsQuery } from 'app/hooks/queries/contact';
import { cloneDeep, merge, orderBy } from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useDebounce } from 'react-use';
import {
  useWebphoneSelector,
  useWebphoneSlice,
} from '../../../../../../data/webphone';
import { useDeleteContact } from '../../../../../../hooks/mutations/contact';
import {
  useListUsersQuery,
  useUserQuery,
} from '../../../../../../hooks/queries/user';
import { useConfirmationDialog } from '../../../../../DefaultDialogActions/DefaultDialogActions';
import { useEditContactDialog } from '../../../../../EditContactDialog';
import { AvatarCell } from '../../../../../ListUsers/components/AvatarCell';
import { useWebphoneContext } from '../../../../Store';
import { ViewContainer } from '../../../ViewContainer';

// import Menu from 'material-ui-popup-state/HoverMenu'; // use for HoverMenu!

const epochOffset = 62167219200;

const CombinedContacts = props => {
  const { show } = props;
  // const dispatchRedux = useDispatch();
  const { contacts } = useWebphoneSelector();
  const { actions } = useWebphoneSlice();
  const dispatch = useDispatch();
  const {
    data: contactsData,
    isLoading: contactsLoading,
    error: contactsError,
    refetch: refetchContacts,
    isFetched: contactsFetched,
    isFetching: contactsFetching,
  } = useContactsQuery({});
  const {
    data: usersPageResp,
    isLoading: usersLoading,
    error: usersError,
    refetch: refetchUsers,
    isFetched: usersFetched,
    isFetching: usersFetching,
  } = useListUsersQuery({});
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    if (contactsFetched && usersFetched)
      // update contacts state (persisted to local storage)
      dispatch(
        actions.setContacts(
          [
            ...usersPageResp.users.flatMap(user => {
              return {
                user,
                info: {
                  firstName: user.doc.first_name ?? user.doc.name,
                  lastName: user.doc.last_name,
                  // only showing exts/numbers on main cf
                  phoneNumbers:
                    user.Callflows.find(cf => cf.doc.type === 'main')
                      ?.doc.numbers.filter(num => !num.startsWith('cid'))
                      .map(num => ({ number: num, digits: num })) ?? [],

                  // using company field to display title
                  company: user.doc.title,
                },
                id: user.id,
              };
            }),
            ...contactsData,
          ].sort((a, b) => {
            const aValue = `${a.info.lastName ?? ''}${a.info.firstName ?? ''}${
              a.info.company ?? ''
            }`.toLowerCase();

            const bValue = `${b.info.lastName ?? ''}${b.info.firstName ?? ''}${
              b.info.company ?? ''
            }`.toLowerCase();

            return aValue.localeCompare(bValue);
          }),
        ),
      );
  }, [contactsFetched, usersFetched, contactsData, usersPageResp?.users]);

  const [debouncedFilterText, setDebouncedFilterText] = useState('');

  const allContacts = [];
  const [filteredContacts, setFilteredContacts] = useState([]);

  const {
    Dialog: EditContactDialog,
    DialogProps: EditContactDialogProps,
    toggleOpen: toggleOpenEditContactDialog,
  } = useEditContactDialog();

  useDebounce(
    () => {
      setDebouncedFilterText(filterText);
    },
    250,
    [filterText],
  );
  useEffect(() => {
    filterContacts();
  }, [debouncedFilterText, contacts, contactsData, usersPageResp?.users]);

  console.log('contacts', contacts);

  const filterContacts = () => {
    setFilteredContacts(
      matchSorter(contacts, filterText, {
        keys: [
          test => `${test.info.firstName} ${test.info.lastName}`,
          'info.lastName',
          'info.firstName',
          'info.company',
          'info.phoneNumbers.*.digits',
          'info.phoneNumbers.*.number',
        ],
        sorter: items => items,
        // sorter: items => orderBy(items, ['info.lastName', 'info.firstName']),
      }),
    );
  };

  const handleContactChange = () => {
    toggleOpenEditContactDialog();
    // refetchContacts();
  };
  return (
    <>
      <EditContactDialog
        {...EditContactDialogProps}
        onComplete={handleContactChange}
      />
      <Box
        sx={{
          display: show ? 'flex' : 'none',
          height: '100%',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ flex: '0 1 auto' }}>
          <ViewContainer
            title={'Contacts'}
            searchComponent={
              <TextField
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
                placeholder="Filter Contacts"
                variant="outlined"
                fullWidth
                size="small"
                style={{ margin: 0 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ transform: 'scaleX(-1)' }} />
                    </InputAdornment>
                  ),
                }}
              />
            }
            titleAction={
              <ButtonDropdownMenu
                menuItems={[
                  {
                    text: 'New Contact',
                    onClick: toggleOpenEditContactDialog,
                  },
                ]}
                // menuItemsDependencies={[dispatchRedux, actions]}
              />
            }
            show={show}
          />
        </Box>
        <Divider />
        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
          }}
        >
          <Box>
            {filteredContacts.length ? (
              <Box>
                {filteredContacts.map((contact, i) => (
                  <>
                    <ExternalContactItem
                      key={contact.id}
                      contact={contact}
                      onEdit={refetchContacts}
                    />
                    <Divider />
                  </>
                ))}
              </Box>
            ) : allContacts.length ? (
              <Box sx={{ padding: 1, height: '100%' }}>
                <Grid
                  container
                  style={{ height: '100%' }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item style={{ textAlign: 'center' }}>
                    <Typography variant="h6">No Contacts for Filter</Typography>
                    <Typography variant="caption">
                      Try a different search
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ) : usersLoading || contactsLoading ? (
              <div style={{ padding: 1, height: '100%' }}>
                <Grid
                  container
                  style={{ height: '100%' }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item style={{ textAlign: 'center' }}>
                    <Typography variant="h1">
                      <CircularProgress size={44} />
                    </Typography>
                    <Typography variant="caption">
                      Checking for Contacts
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div style={{ padding: 1, height: '100%' }}>
                <Grid
                  container
                  sx={{ height: '100%' }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item style={{ textAlign: 'center' }}>
                    <Typography variant="h6">No Contacts</Typography>
                    <Typography variant="caption">
                      Contacts are shared between your devices
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )}
            {usersFetching || contactsFetching ? (
              <Box sx={{ width: '100%', padding: 2, textAlign: 'center' }}>
                <Typography
                  sx={{
                    fontStyle: 'italic',
                    color: theme => theme.palette.text.secondary,
                  }}
                >
                  Syncing contacts...
                </Typography>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
      {/*<Box
        sx={{
          height: '100%',
          overflowY: 'auto',
          display: show ? 'block' : 'none',
          background: theme => theme.palette.content.background,
          py: '20px',
          px: '30px',
        }}
      >
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Grid item>
            <Typography
              sx={{ color: '#0B2027', fontWeight: 700, fontSize: '18px' }}
            >
              Contacts
            </Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>
        <br />

        <ThemeProvider
          // @ts-ignore
          theme={outerTheme => {
            return merge(cloneDeep(outerTheme), {
              components: {
                MuiInputAdornment: {
                  styleOverrides: {
                    root: {
                      // @ts-ignore
                      color: `${outerTheme.palette.primary.dark} !important`,
                    },
                  },
                },
                MuiAutocomplete: {
                  styleOverrides: {
                    input: {
                      width: '100%',
                      // color: 'white !important',
                      '&::placeholder': {
                        textOverflow: 'ellipsis',
                        color: 'rgba(15, 97, 113, 0.53)',
                        opacity: 1,
                      },
                      color: 'rgba(15, 97, 113, 0.53)',
                    },
                    endAdornment: {
                      opacity: 0,
                    },
                    // groupLabel: {
                    //   fontStyle: 'italic',
                    //   fontWeight: 700,
                    //   borderTop: '1px solid #eee',
                    //   borderBottom: '1px solid #fafafa',
                    // },
                    listbox: {
                      maxHeight: '80vh',
                    },
                  },
                },
                MuiOutlinedInput: {
                  styleOverrides: {
                    root: {
                      padding: '4px !important',
                      background: 'white',
                    },
                    notchedOutline: {
                      // borderColor: 'white !important',
                      borderColor: 'rgba(27, 125, 144, 0.18) !important',
                      borderWidth: '1px !important',
                    },
                  },
                },
              },
            });
          }}
        ></ThemeProvider>
        <br />
        <br />
        <Divider />
         Handle empty states
        {filteredContacts.length ? (
          <Box>
            {filteredContacts.map((contact, i) => (
              <>
                <ExternalContactItem
                  key={contact.id}
                  contact={contact}
                  onEdit={refetchContacts}
                />
                <Divider />
              </>
            ))}
          </Box>
        ) : allContacts.length ? (
          <Box sx={{ padding: 1, height: '100%' }}>
            <Grid
              container
              style={{ height: '100%' }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item style={{ textAlign: 'center' }}>
                <Typography variant="h6">No Contacts for Filter</Typography>
                <Typography variant="caption">
                  Try a different search
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ) : usersLoading || contactsLoading ? (
          <div style={{ padding: 1, height: '100%' }}>
            <Grid
              container
              style={{ height: '100%' }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item style={{ textAlign: 'center' }}>
                <Typography variant="h1">
                  <CircularProgress size={44} />
                </Typography>
                <Typography variant="caption">Checking for Contacts</Typography>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div style={{ padding: 1, height: '100%' }}>
            <Grid
              container
              sx={{ height: '100%' }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item style={{ textAlign: 'center' }}>
                <Typography variant="h6">No Contacts</Typography>
                <Typography variant="caption">
                  Contacts are shared between your devices
                </Typography>
              </Grid>
            </Grid>
          </div>
        )}
        {usersFetching || contactsFetching ? (
          <Box sx={{ width: '100%', padding: 2, textAlign: 'center' }}>
            <Typography
              sx={{
                fontStyle: 'italic',
                color: theme => theme.palette.text.secondary,
              }}
            >
              Syncing contacts...
            </Typography>
          </Box>
        ) : null}
      </Box>*/}
    </>
  );
};

export const ContactNameDisplay = ({ contact }) => {
  return (
    <>
      {contact.info.firstName ? (
        contact.info.lastName?.length ? (
          <Typography
            sx={{
              // color: theme => theme.palette.content.color,
              fontSize: '16px',
              display: 'inline-block',
            }}
          >
            {contact.info.firstName}&nbsp;
          </Typography>
        ) : (
          <Typography
            sx={{
              // color: theme => theme.palette.content.color,
              fontSize: '16px',
              display: 'inline-block',
              fontWeight: 700,
            }}
          >
            {contact.info.firstName}
          </Typography>
        )
      ) : null}
      {contact.info.lastName ? (
        <Typography
          sx={{
            // color: theme => theme.palette.content.color,
            fontSize: '16px',
            display: 'inline-block',
            fontWeight: 700,
          }}
        >
          {contact.info.lastName}{' '}
        </Typography>
      ) : null}
      {contact.info?.company?.length ? (
        contact.info.firstName || contact.info.lastName ? (
          <Typography
            variant="caption"
            sx={{
              color: theme => theme.palette.content.color,
              fontSize: '14px',
              display: 'block',
              opacity: 0.7,
            }}
          >
            {contact.info?.company}
          </Typography>
        ) : (
          <Typography
            sx={{
              color: theme => theme.palette.content.color,
              fontSize: '16px',
              display: 'inline-block',
              fontWeight: 700,
            }}
          >
            {contact.info?.company}
          </Typography>
        )
      ) : null}
    </>
  );
};

const ExternalContactItem = props => {
  const { contact, onEdit } = props;
  const deleteContactMutation = useDeleteContact();

  const [state, dispatch] = useWebphoneContext();
  const { makeCall, eventBus } = state;

  // if contact is user:
  // set primary extension if it exists in users callflow
  const { firstNumber, primaryExt } = useMemo(() => {
    let primaryExt, inMainCallflow;

    if (contact.user) {
      primaryExt = contact.user.doc.presence_id;
      inMainCallflow = contact.info.phoneNumbers.find(
        num => num.digits === primaryExt,
      );
    }

    const [firstNumber] = contact.info.phoneNumbers.filter(num =>
      contact.user ? num.digits.length > 6 : true,
    );

    return { firstNumber, primaryExt: inMainCallflow ? primaryExt : undefined };
  }, [contact]);

  const handleCall = digits => () => {
    makeCall(
      digits,
      false, // isVideoCall
      undefined, // local tag (used default)
      undefined, // remote tag (uses default)
      {},
    );
    eventBus.emit('started-call');
  };

  const {
    Dialog: EditContactDialog,
    DialogProps: EditContactDialogProps,
    toggleOpen: toggleOpenEditContactDialog,
  } = useEditContactDialog();
  const {
    Dialog: ConfirmationDialog,
    DialogProps: ConfirmationDialogProps,
    toggleOpen: toggleOpenConfirmation,
  } = useConfirmationDialog();
  const handleContactChange = () => {
    toggleOpenEditContactDialog();
    onEdit();
  };

  const handleDelete = () => {
    const deleteContactPromise = deleteContactMutation.mutateAsync(contact.id, {
      onSuccess: () => {
        toggleOpenConfirmation();
        onEdit();
      },
    });

    // toast.promise(deleteContactPromise, {
    //   pending: 'Removing contact...',
    //   success: 'Contact removed!',
    //   error: 'Error removing contact.',
    // });
  };

  // console.log('rendering contact item...');

  return (
    <Box sx={{ padding: 2 }}>
      <EditContactDialog
        {...EditContactDialogProps}
        onComplete={handleContactChange}
        onCancel={toggleOpenEditContactDialog}
        contact={contact}
      />
      <ConfirmationDialog
        {...ConfirmationDialogProps}
        onCancel={toggleOpenConfirmation}
        onConfirm={handleDelete}
        isLoading={deleteContactMutation.isLoading}
        loadingLabel={'Deleting contact...'}
        label={'Are you sure you would like to delete this contact?'}
        // key={'delete-confirmation-dialog'}
      />
      <Grid container alignItems="center" columnSpacing={1}>
        <Grid item>
          <AvatarCell
            resource={{
              doc: {
                first_name: contact.info.firstName,
                last_name: contact.info.lastName,
                company: contact.info.company,
              },
            }}
            size={40}
          />
        </Grid>
        <Grid item sx={{ flex: 1 }}>
          {contact.user ? (
            <Link
              component={RouterLink}
              // link={contact.user ? RouterLink : undefined}
              to={`/admin/users/view/${contact.user.id}`}
              color={'primary'}
              sx={{
                underline: 'hover',
                display: 'block',
              }}
            >
              <ContactNameDisplay contact={contact} />
            </Link>
          ) : (
            <div>
              <ContactNameDisplay contact={contact} />
            </div>
          )}
        </Grid>
        <Grid item sx={{ textAlign: 'right' }}>
          <Grid
            container
            alignItems={'center'}
            columnSpacing={2}
            wrap={'nowrap'}
          >
            <Grid item>
              {firstNumber ? (
                <Box
                  key={firstNumber.id}
                  onClick={handleCall(firstNumber.digits)}
                  sx={{ cursor: 'pointer' }}
                >
                  <PhoneNumberDisplay ptn={firstNumber.digits} />
                </Box>
              ) : null}
              {/*))}*/}
              {/* show primary ext if internal user and the ext exists in their main callflow*/}
              {primaryExt ? (
                <Box
                  key={primaryExt}
                  onClick={handleCall(primaryExt)}
                  sx={{ cursor: 'pointer' }}
                >
                  <PhoneNumberDisplay ptn={primaryExt} />
                </Box>
              ) : null}
              {/*))}*/}
            </Grid>
            <Grid item>
              {contact.info.phoneNumbers.length > 1 ? (
                <Tooltip
                  arrow
                  title={contact.info.phoneNumbers?.map(numData => (
                    <Box
                      key={numData.id}
                      onClick={handleCall(numData.digits)}
                      sx={{ cursor: 'pointer', mb: 1 }}
                    >
                      <PhoneNumberDisplay ptn={numData.digits} />
                    </Box>
                  ))}
                >
                  <Chip
                    label={`${contact.info.phoneNumbers.length} total`}
                    variant={'contained'}
                    color={'primary'}
                    size={'small'}
                  />
                </Tooltip>
              ) : null}
            </Grid>
          </Grid>
          {/*{contact.info.phoneNumbers?.map(numData => (*/}
        </Grid>
        <Grid item xs={1}>
          <Box
            sx={{
              color: theme => theme.palette.primary.main,
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {contact.user ? (
              <Tooltip title={'Contact is user on account'} arrow>
                <PersonIcon color={'inherit'} />
              </Tooltip>
            ) : (
              <ButtonDropdownMenu
                menuItems={[
                  { text: 'Edit', onClick: toggleOpenEditContactDialog },
                  { text: 'Delete', onClick: toggleOpenConfirmation },
                ]}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CombinedContacts;
