import { DetailsDialogVmbox } from 'app/components/DetailsDialogVmbox';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { Vmbox } from 'types/vmbox';

interface GreetingVmboxProps {
  vmbox: Vmbox;
  onComplete: () => void;
}

const NameVmbox = ({ vmbox, onComplete }: GreetingVmboxProps) => {
  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'name',
    options: [
      {
        label: 'Name',
        value: 'name',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const handleOnComplete = () => {
    onComplete();
    toggleShowEditDialog();
  };

  return (
    // @ts-ignore
    <div ref={ref}>
      {showEditDialog ? (
        <DetailsDialogVmbox
          vmboxId={vmbox.id}
          onCancel={toggleShowEditDialog}
          onComplete={handleOnComplete}
        />
      ) : null}
      <HighlightPulse {...highlightPulseProps} />
      <GenericSettingsCardField
        title={'Name'}
        content={vmbox.doc.name}
        editOnClick={toggleShowEditDialog}
      />
    </div>
  );
};

export default NameVmbox;
