import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { Media } from 'types/media';
import mediaQueryKeys from './mediaQueryKeys';

interface QueryParams {
  id?: string | null;
  options?: Omit<
    UseQueryOptions<Media, Error, Media, QueryKey>,
    'queryKey' | 'queryFn'
  >;
}

export const useMediaQuery = ({ id, options }: QueryParams) => {
  const authState = useAuthSelector();
  const queryKey = mediaQueryKeys.byId(id);

  const query = useQuery<Media, Error>(
    queryKey,
    () =>
      sdk.media.query.mediaById({ id }, { authToken: authState.auth_token }), // fetchUser(id, authState.auth_token),
    {
      enabled: !!id, //authState?.auth_token_decoded?.owner_id ? true : false,
      ...options,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default useMediaQuery;
