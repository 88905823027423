import { joiResolver } from '@hookform/resolvers/joi';
import { DialogInProgress, Typography } from 'app/design';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { useUpdatePhoneNumberPartial } from 'app/hooks/mutations/phoneNumbers';
import { usePhoneNumberQuery } from 'app/hooks/queries/phoneNumber';
import { DialogBuilder, parseAndSetKazooMutationErrors } from 'app/utilities';
import Joi from 'joi';
import { omit, omitBy, pick, pickBy } from 'lodash';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Dialog, DialogContent, DialogTitle } from 'app/design';
import { toast } from 'react-toastify';
import { E911 } from 'types/phoneNumber';
import { useImmer } from 'use-immer';

interface EmergencyAddressDialogProps {
  onComplete: () => void;
  number: string;
  onCancel: () => void;
}

const schema = Joi.object({
  e911: Joi.object({
    caller_name: Joi.string().max(128).required(),
    street_address: Joi.string().max(128).required(),
    extended_address: Joi.string().max(128).optional().allow(''),
    locality: Joi.string().max(128).required(),
    region: Joi.string().max(128).required(),
    postal_code: Joi.string().max(128).required(),
    // notification_contact_email: Joi.array()
    //   .items(Joi.string().email({ tlds: { allow: false } }))
    //   .optional(),
  }),
});

interface FormFields {
  e911: E911;
}

const EmergencyAddressDialog = ({
  onComplete,
  number,
  onCancel,
}: EmergencyAddressDialogProps) => {
  // needed for invalid geo encoding, not handled as a validation error
  const [genericError, setGenericError] = useImmer<string | undefined>(
    undefined,
  );
  const formMethods = useForm<FormFields>({ resolver: joiResolver(schema) });
  const { handleSubmit, setError, reset } = formMethods;

  const {
    data: phoneNumber,
    isLoading,
    isFetching,
    isError: numberError,
  } = usePhoneNumberQuery({
    id: number,
    options: {
      onSuccess: phoneNumber => {
        reset({
          e911: pick(phoneNumber.doc.e911, [
            'caller_name',
            'street_address',
            'extended_address',
            'locality',
            'region',
            'postal_code',
          ]),
        });
      },
    },
  });
  const numberIsLoading = isLoading || isFetching;

  const updateNumber = useUpdatePhoneNumberPartial();

  const handleSave = (form: FormFields) => {
    setGenericError(undefined);
    const updateNumberPromise = updateNumber.mutateAsync(
      {
        id: number,
        e911: {
          ...phoneNumber?.doc.e911,
          ...form.e911,
        },
      },
      {
        onSuccess: () => {
          onComplete();
        },
        onError: error => {
          // is generic error not specific to form field validation
          if (error.response?.data.message === 'invalid') {
            // TODO: are their other generic errors for this mutation?
            setGenericError('Location is not Geolocated');
          } else {
            parseAndSetKazooMutationErrors({
              response: error.response,
              setError,
            });
          }
        },
      },
    );

    toast.promise(updateNumberPromise, {
      pending: 'Updating phone number...',
      error: 'Error updating phone number.',
      success: 'Phone number updated!',
    });
  };

  const error = numberError || !phoneNumber;

  return (
    <Dialog open={true} fullWidth maxWidth={'sm'}>
      {updateNumber.isLoading || numberIsLoading ? (
        <DialogInProgress
          title={
            numberIsLoading
              ? 'Loading phone number...'
              : `Updating phone number...`
          }
        />
      ) : (
        <>
          <DialogTitle>Edit Emergency Address</DialogTitle>
          <DialogContent dividers>
            {error ? (
              <Typography color={'error'}>Error Loading number.</Typography>
            ) : (
              <FormProvider {...formMethods}>
                <HookFormTextField name={'e911.caller_name'} label={'Name'} />
                <br />
                <br />
                <HookFormTextField
                  name={'e911.street_address'}
                  label={'Address Line 1'}
                  fullWidth
                />
                <br />
                <br />
                <HookFormTextField
                  name={'e911.extended_address'}
                  label={'Address Line 2'}
                  fullWidth
                />
                <br />
                <br />
                <HookFormTextField name={'e911.locality'} label={'City'} />
                <br />
                <br />
                <HookFormTextField name={'e911.region'} label={'State'} />
                <br />
                <br />
                <HookFormTextField
                  name={'e911.postal_code'}
                  label={'ZIP Code'}
                />
              </FormProvider>
            )}
          </DialogContent>
          <DefaultDialogActions
            onSave={error ? undefined : handleSubmit(handleSave)}
            saveLabel={
              formMethods.formState.isDirty
                ? `Update Number`
                : 'No changes to update'
            }
            saveDisabled={!formMethods.formState.isDirty}
            onCancel={onCancel}
            errorMessage={genericError}
          />
        </>
      )}
    </Dialog>
  );
};

export const useEmergencyAddressDialog = DialogBuilder(EmergencyAddressDialog);

export default EmergencyAddressDialog;
