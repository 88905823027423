import { Box } from '@mui/material';
import React, { ReactNode } from 'react';
import flattenChildren from 'react-keyed-flatten-children';

export interface ChipOverGroupProps {
  size?: string;
  children?: ReactNode;
}

const ChipOverGroup = React.forwardRef((props: ChipOverGroupProps, ref) => {
  const { size = 'medium', children } = props;

  const sizes = {
    small: 20,
    medium: 26,
    large: 28,
  };

  const px = sizes[size];

  // iterate down through z-indexes, best I can think of for now...
  const startZindex = 5;

  return (
    <Box
      ref={ref}
      sx={{
        whiteSpace: 'nowrap',
        display: 'inline-block',
        '& > *:not(:first-child)': {
          marginLeft: `-${px}px`,
        },
        '& > *:not(:first-child) > *': {
          paddingLeft: `${px}px`,
        },
        '& .MuiChip-outlined': {
          background: 'white',
        },
      }}
    >
      {flattenChildren(children).map((child, index) => {
        return (
          <Box
            sx={{
              position: 'relative',
              display: 'inline-block',
              zIndex: startZindex - index,
            }}
            // @ts-ignore
            key={child.key}
          >
            {child}
          </Box>
        );
      })}
    </Box>
  );
});

export default ChipOverGroup;
