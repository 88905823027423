import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from 'app/design/icons-material';
import { useEditScheduleDialog } from 'app/components/EditScheduleDialog';
import { OptionsTableCell } from 'app/components/OptionsTableCell';
import { useScheduleEditorDialog } from 'app/components/ScheduleCreateDialog/ScheduleEditorDialog';
import {
  useDeleteSchedule,
  useUpdateSchedulePartial,
} from 'app/hooks/mutations/schedule';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { toast } from 'react-toastify';
import { Schedule } from 'types/schedule';
import { Link } from 'app/design';

interface EditScheduleLinkProps {
  schedule: Schedule;
  onComplete: () => void;
  label?: string;
}

const EditScheduleLink = ({
  onComplete,
  schedule,
  label,
}: EditScheduleLinkProps) => {
  const deleteSchedule = useDeleteSchedule();
  const updateSchedulePartial = useUpdateSchedulePartial();

  const { toggleOpen, Dialog, DialogProps } = useScheduleEditorDialog({
    initialOpen: false,
  });

  const handleDelete = () => {
    const createSchedulePromise = deleteSchedule.mutateAsync(schedule.id, {
      onSuccess: async resp => {
        onComplete();
        console.log('delete schedule resp', resp);
      },
      onError: error => console.error(error),
    });

    toast.promise(createSchedulePromise, {
      pending: 'Deleting schedule...',
      success: 'Schedule deleted!',
      error: 'Error deleting schedule.',
    });
  };

  const handleComplete = () => {
    onComplete();
    toggleOpen();
  };

  return (
    <>
      <Dialog
        {...DialogProps}
        scheduleId={schedule.id}
        onCancel={toggleOpen}
        onComplete={handleComplete}
      />
      <Link onClick={toggleOpen}>
        {label ?? schedule.doc.name ?? 'Untitled'}
      </Link>
    </>
  );
};

export default EditScheduleLink;
