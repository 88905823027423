import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  InputTextField,
  Link,
  Typography,
} from 'app/design';

import { useVmboxQuery } from 'app/hooks/queries/vmbox';
import { useMediaByIdQuery } from 'app/hooks/queries/media';

import { Audiotrack as AudiotrackIcon } from 'app/design/icons-material';

import { Handle } from 'react-flow-renderer';
import { useHoverDirty } from 'react-use';
import { IvrMenuEventEmitterContext } from '../../..';
import useMediaUrl from '../../../../../hooks/queries/media/useMediaUrl';
import { setAtPath } from '../../../../../utilities';
import { AudioPlayer } from '../../../../AudioPlayer';
import { MediaDialog } from '../../../../MediaDialog';

// import {
//   ModifyDialog as ModifyDialogAudio,
//   SmallMediaPlayer,
// } from '../../../../SingleMedia';

import { Box } from '../common/Box';
// import { ModifyDialog } from './ModifyDialog';
import { InTemplate } from '../common/InTemplate';
import { MenuButton } from '../common/MenuButton';
import { NodeInfoDisplay } from '../common/NodeInfoDisplay';
import { VariablesEditor } from '../common/VariablesEditor';

// why use memo??
const PlayAudioNode = memo(({ data, ...rest }) => {
  const {
    skipEditing,
    insertBefore, // bool
    insertAfterData,
    moduleItem,
    callflow,
    setCallflow,
    modifyPath,
    templateParent,
    // element, // used in Box
    // setRootElements, // used in Box
  } = data;

  // Load media via query
  // const {
  //   data: media,
  //   isLoading,
  //   isFetched,
  // } = useMediaByIdQuery(moduleItem?.data?.id);
  // const mediaUrl = useMediaUrl({ id:moduleItem?.data?.id });

  const [showModifyDialog, setShowModifyDialog] = useState(null);
  const setShowModifyDialogWrap = setTo => () => setShowModifyDialog(setTo);

  const [showVariables, setShowVariables] = useState(null);
  const setShowVariablesWrap = setTo => () => setShowVariables(setTo);

  const boxRef = useRef(null);
  const isHovering = useHoverDirty(boxRef);

  let possibleVariables = [
    {
      id: 'media_id',
      name: 'Media ID',
      read: {
        type: 'media_id',
      },
      write: {
        type: 'set',
        data: {
          moduleItemModifyPath: 'data.id',
        },
      },
    },
  ];

  // action for onCreate
  const ee = useContext(IvrMenuEventEmitterContext);
  const onNodeCreated = data => {
    if (data.optsPath === modifyPath) {
      setShowModifyDialog(true);
    }
  };
  useEffect(() => {
    ee.on('node-created', onNodeCreated);
    return () => {
      ee.removeListener('node-created', onNodeCreated);
    };
  }, []);

  const handleChangeAudio = mediaId => {
    setAtPath(callflow, `${modifyPath}.data.id`, mediaId);
    setCallflow({ ...callflow });
  };

  const mediaId = moduleItem?.data?.id;

  return (
    <>
      <Box
        pref={boxRef}
        borderColor="#1E88E5"
        position={data?.position}
        data={data}
        // height={100}
        // onHeight={}
        // onDimensions={handleDimensions}
      >
        {showModifyDialog && (
          <MediaDialog
            mediaNameToSaveAs={'Call Routing Audio'}
            onComplete={handleChangeAudio}
            onCancel={setShowModifyDialogWrap(null)}
            type={'Call Routing Audio'}
            mediaId={mediaId}
          />
        )}
        {showVariables && (
          <VariablesEditor // Dialog
            {...data}
            possibleVariables={possibleVariables}
            onClose={setShowVariablesWrap(null)}
          />
        )}
        <Handle
          type="target"
          position="top"
          style={{ background: '#555', visibility: 'hidden' }}
        />
        <div style={{ position: 'relative' }}>
          <NodeInfoDisplay
            type={'Audio'}
            icon={<AudiotrackIcon />}
            title={
              mediaId ? (
                <AudioPlayer mediaId={mediaId} />
              ) : skipEditing ? (
                'None Selected'
              ) : (
                <Link onClick={setShowModifyDialogWrap(true)}>Set Audio</Link>
              )
            }
            content={
              ''
              // vmbox ? (
              //   vmbox?.doc?.media?.unavailable ? (
              //     // <SmallMediaPlayer mediaId={vmbox?.doc?.media?.unavailable} />
              //     <span style={{ opacity: 0.6 }}>small media player...</span>
              //   ) : (
              //     <span style={{ opacity: 0.6 }}>[No Greeting]</span>
              //   )
              // ) : null
            }
            footer={
              ''
              // vmbox ? (
              //   vmbox?.doc?.is_setup ? null : (
              //     <div
              //       style={{
              //         marginTop: vmbox?.doc?.media?.unavailable ? -15 : -5,
              //       }}
              //     >
              //       <Typography variant={'caption'} color={'secondary'}>
              //         Voicemail Not Set Up
              //       </Typography>
              //     </div>
              //   )
              // ) : null
            }
          />
          {skipEditing ? null : (
            <>
              <MenuButton
                data={data}
                mode={isHovering ? 'normal' : 'ignore'}
                menuItems={[
                  {
                    type: 'modify',
                    props: { onClick: setShowModifyDialogWrap(true) },
                  },
                  'add',
                  'add-before',
                  'move',
                  'duplicate',
                  'copy',
                  process.env.NODE_ENV === 'development'
                    ? 'create_template'
                    : null,
                  templateParent ||
                  callflow.type == 'template' ||
                  process.env.NODE_ENV === 'development'
                    ? {
                        type: 'variables',
                        props: { onClick: setShowVariablesWrap(true) },
                      }
                    : null,
                  'remove',
                ]}
              />
            </>
          )}
        </div>
        <Handle
          type="source"
          position="bottom"
          style={{
            top: 'auto',
            bottom: 1,
            background: '#555',
            visibility: 'hidden',
          }}
        />
        {templateParent ? <InTemplate template={templateParent} /> : ''}
      </Box>
    </>
  );
});

export default PlayAudioNode;

// import React, { useState, useContext, useEffect, useRef, memo } from 'react';
// import { Handle } from 'react-flow-renderer';

// import {
//   Typography,
//   Grid,
//   Button,
//   Link,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Divider,
// } from 'app/design';
// import {
//   Phone as PhoneIcon,
//   Add as AddIcon,
//   Audiotrack as AudiotrackIcon,
//   Dialpad as DialpadIcon,
// } from 'app/design/icons-material';

// import { getAtPath, setAtPath } from 'app/utilities';

// import { IvrMenuEventEmitterContext } from '../../..';

// import { USER_ADDABLE_COMPONENTS } from '../../../../Strategies/base/GenericDefault';
// import * as OptionComponents from '../../../../Strategies/components';

// import { useSetupHook } from '../../../../SetupHook';
// import {
//   SmallMediaPlayer,
//   SingleMedia,
//   ChangeAudioDialog,
//   ModifyDialog as ModifyDialogAudio,
//   builtinAudio,
// } from '../../../../SingleMedia';

// import { Box } from '../common/Box';
// import { MenuButton } from '../common/MenuButton';
// import { InTemplate } from '../common/InTemplate';
// import { NodeInfoDisplay } from '../common/NodeInfoDisplay';
// import { VariablesEditor } from '../common/VariablesEditor';

// import {
//   InsertAfterButton,
//   InsertBeforeButton,
//   RemoveButton,
//   ModifyButton,
//   // MenuButton,
// } from '../Insert';

// import { useHoverDirty } from 'react-use';

// import store from '../../../../../../../../store';

// const PlayAudioNode = memo(({ data }) => {
//   const {
//     insertBefore, // bool
//     insertAfterData,
//     infoIdx,
//     componentData,
//     componentInfo,
//     callflow,
//     setCallflow,
//     modifyPath,
//     templateParent,
//   } = data;

//   const { list: medias } = store.getState().lists.media;
//   const media = medias.find(m => m.id === componentData?.mediaId);

//   let isBuiltin =
//     builtinAudio.find(audio => {
//       if (componentData?.mediaId?.indexOf(audio.link) === 0) {
//         return true;
//       }
//     }) || null;

//   const [showModifyDialog, setShowModifyDialog] = useState(null);
//   const setShowModifyDialogWrap = setTo => () => setShowModifyDialog(setTo);

//   const boxRef = useRef(null);
//   const isHovering = useHoverDirty(boxRef);

//   // action for onCreate
//   const ee = useContext(IvrMenuEventEmitterContext);
//   const onNodeCreated = data => {
//     if (data.optsPath === modifyPath) {
//       setShowModifyDialog(true);
//     }
//   };
//   useEffect(() => {
//     ee.on('node-created', onNodeCreated);
//     return () => {
//       ee.removeListener('node-created', onNodeCreated);
//     };
//   }, []);

//   return (
//     <>
//       <Box
//         pref={boxRef}
//         position={data?.position}
//         height={100}
//         borderColor="#1565C0"
//       >
//         {showModifyDialog && (
//           <ModifyDialog
//             {...data}
//             media={media}
//             onClose={setShowModifyDialogWrap(null)}
//           />
//         )}
//         <Handle
//           type="target"
//           position="top"
//           style={{ background: '#555', visibility: 'hidden' }}
//         />
//         <div>
//           <NodeInfoDisplay
//             type={'Audio'}
//             icon={<AudiotrackIcon />}
//             title={
//               componentData?.mediaId ? (
//                 isBuiltin ? (
//                   <>Built-in Audio: {isBuiltin.name}</>
//                 ) : (
//                   media?.doc?.name
//                 )
//               ) : (
//                 <Link onClick={setShowModifyDialogWrap(true)}>Set Media</Link>
//               )
//             }
//             content={
//               componentData?.mediaId ? (
//                 <SmallMediaPlayer mediaId={componentData?.mediaId} />
//               ) : (
//                 ''
//               )
//             }
//           />
//           {/*<div style={{ padding: 8, textAlign: 'center' }}>*/}
//           {/*  <Typography variant="caption">*/}
//           {/*    <AudiotrackIcon style={{ verticalAlign: 'middle' }} /> Audio*/}
//           {/*  </Typography>*/}
//           {/*  {componentData?.mediaId ? (*/}
//           {/*    <>*/}
//           {/*      <Typography variant="body1" bold noWrap>*/}
//           {/*        {componentData?.mediaId ? (*/}
//           {/*          isBuiltin ? (*/}
//           {/*            <>Built-in Audio: {isBuiltin.name}</>*/}
//           {/*          ) : (*/}
//           {/*            media?.doc?.name*/}
//           {/*          )*/}
//           {/*        ) : (*/}
//           {/*          'No Media'*/}
//           {/*        )}*/}
//           {/*      </Typography>*/}
//           {/*      <div>*/}
//           {/*        {componentData?.mediaId ? (*/}
//           {/*          <SmallMediaPlayer mediaId={componentData?.mediaId} />*/}
//           {/*        ) : (*/}
//           {/*          ''*/}
//           {/*        )}*/}
//           {/*      </div>*/}
//           {/*    </>*/}
//           {/*  ) : (*/}
//           {/*    <div>*/}
//           {/*      <Link onClick={setShowModifyDialogWrap(true)}>Set Media</Link>*/}
//           {/*    </div>*/}
//           {/*  )}*/}
//           {/*</div>*/}
//           <>
//             <MenuButton
//               data={data}
//               mode={isHovering ? 'normal' : 'ignore'}
//               menuItems={[
//                 {
//                   type: 'modify',
//                   props: { onClick: setShowModifyDialogWrap(true) },
//                 },
//                 'add',
//                 'add-before',
//                 'move',
//                 'duplicate',
//                 'copy',
//                 'create_template',
//                 'remove',
//               ]}
//             />
//             {/* <RemoveButton
//               {...insertAfterData}
//               mode={isHovering ? 'normal' : 'ignore'}
//             />
//             <ModifyButton
//               onClick={setShowModifyDialogWrap(true)}
//               mode={isHovering ? 'normal' : 'ignore'}
//             />
//             {insertBefore && (
//               <InsertBeforeButton
//                 insertAfterData={insertAfterData}
//                 mode={isHovering ? 'normal' : 'ignore'}
//               />
//             )}
//             <InsertAfterButton
//               insertAfterData={insertAfterData}
//               mode={isHovering ? 'normal' : 'ignore'}
//             /> */}
//           </>
//         </div>
//         <Handle
//           type="source"
//           position="bottom"
//           style={{
//             top: 'auto',
//             bottom: 1,
//             background: '#555',
//             visibility: 'hidden',
//           }}
//         />
//         {templateParent ? <InTemplate template={templateParent} /> : ''}
//       </Box>
//     </>
//   );
// });

// export const ModifyDialog = props => {
//   const { callflow, setCallflow, modifyPath, index, componentData, onClose } =
//     props;

//   const afterUpdate = async id => {
//     console.log('afterUpdate id:', id);
//     componentData.mediaId = id;
//     setAtPath(callflow, modifyPath, componentData);
//     setCallflow({ ...callflow });
//     onClose();
//   };

//   return (
//     <ModifyDialogAudio
//       defaultName="IVR Menu Audio"
//       mediaId={componentData.mediaId}
//       onSave={afterUpdate}
//       onClose={onClose}
//     />
//   );
// };

// export default PlayAudioNode;
