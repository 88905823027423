import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { authSaga } from './saga';
import { AuthState } from './types';
import jwt from 'jsonwebtoken';

import { merge } from 'lodash';

const persistedInitialState = {
  loggedIn: false,
  account_id: null,
  owner_id: null,
  auth_token: null,
  auth_token_decoded: null,
  login_via: null, // auth_token, credentials
  auto_accept_charges: true, // will automatically add to ALL mutations, otherwise if in response show payment required dialog
  // TODO: remember me to prefill on logout
  username: null,
  account_name: null,
};

// Persistence defined in `persistMask` in `configureStore.ts`
export const initialState: AuthState = {
  ...persistedInitialState,

  // ephemeral
  loggingIn: false,
  silently_logging_in: false,
  ready_after_token_change: false, // only used when querystring has an auth_token change
  error: null,
  show_login_dialog: false, // TODO: more concise name for state and WHY, separate from dialog
};

export const persistMask = Object.keys(persistedInitialState)
  .join(',')
  .replace(/\s/g, '');

const slice = createSlice({
  name: 'auth',
  initialState: () => {
    // eh, merging the "saved" state with the "initial"/"default" state is hard, so simplify by only providing top-level keys that can be saved
    // - doing deep-merge is error-prone and more complicated
    // - TODO: handle versioning
    try {
      return {
        ...initialState,
        // @ts-ignore
        ...(JSON.parse(localStorage.getItem(`app_persist_state_auth`)) ??
          initialState),
      };
    } catch (err) {}
    return initialState;
  },
  reducers: {
    // someAction(state, action: PayloadAction<any>) {},
    setAuthToken(state, action: { payload: { auth_token: string } }) {
      console.log('setAuthToken:', action.payload);
      state.auth_token = action.payload.auth_token;
      state.auth_token_decoded = jwt.decode(action.payload.auth_token);
      state.account_id = state.auth_token_decoded.account_id;
      state.owner_id = state.auth_token_decoded.owner_id;
      state.loggedIn = true;
      state.loggingIn = false;
      state.error = null;
      state.login_via = 'auth_token';
    },
    authTokenChange(state, action) {},
    readyAfterTokenChange(state, action) {
      state.ready_after_token_change = true;
    },
    silentlyUpdateAuthToken(
      state,
      action: { payload: { auth_token: string } },
    ) {
      state.auth_token = action.payload.auth_token;
      state.auth_token_decoded = jwt.decode(state.auth_token);
      state.owner_id = state.auth_token_decoded.owner_id;
      state.account_id = state.auth_token_decoded.account_id;
      state.login_via = 'auth_token';
    },
    loginWithAuthToken(state, action: { payload: { auth_token: string } }) {
      state.loggedIn = false;
      state.loggingIn = true;
      state.auth_token = action.payload.auth_token;
      state.auth_token_decoded = jwt.decode(action.payload.auth_token);
      state.owner_id = state.auth_token_decoded.owner_id;
      state.account_id = state.auth_token_decoded.account_id;
      state.error = null;
      state.login_via = 'auth_token';
      console.log('attempting to login with auth token.', action);
    },
    loginFail(state, action: { payload: { error: string } }) {
      // is login dialog showing? TODO: separate reducer?
      if (!state.show_login_dialog) {
        // prevent auth gate from triggering route to /login if the show login dialog is open
        state.loggedIn = false;
        state.auth_token = null;
        state.auth_token_decoded = null;
        state.owner_id = null;
        state.account_id = null;
        state.login_via = null;
      }

      state.silently_logging_in = false;
      state.error = action.payload.error;
      state.loggingIn = false;

      console.log('Login failed.', action);
    },
    loginSuccess(state, action: { payload: { auth_token: string } }) {
      state.loggedIn = true;
      state.loggingIn = false;
      state.silently_logging_in = false;
      state.show_login_dialog = false;
      state.auth_token = action.payload.auth_token;
      state.auth_token_decoded = jwt.decode(action.payload.auth_token);
      state.owner_id = state.auth_token_decoded.owner_id;
      state.account_id = state.auth_token_decoded.account_id;
      state.error = null;
      console.log('Login was successful.', action);
    },
    loginWithCredentials(
      state,
      action: {
        payload: { username: string; account_name: string; password: string };
      },
    ) {
      // is login dialog showing? TODO: separate reducer?
      if (state.show_login_dialog) {
        state.silently_logging_in = true;
      } else {
        // prevent auth gate from triggering route to /login if the show login dialog is open
        state.loggedIn = false;
        state.loggingIn = true;
        state.auth_token_decoded = null;
        state.owner_id = null;
        state.account_id = null;
        state.auth_token = null;
      }

      state.error = null;
      state.login_via = 'credentials';
      state.username = action.payload.username;
      state.account_name = action.payload.account_name;

      console.log('attempting to login in with credentials...', action);
    },
    logout(state, action) {
      console.log('logging out...', action);
      return {
        ...initialState,
      };
    },
    unauthorizedRequest(state, action) {
      // if (login_via === 'credentials')
      state.show_login_dialog = true;
      // else
      // TODO: what do we show some one who logged in with auth token? they don't
      // - know credentials
      console.log('authSlice: unauthorized Request');
    },
    showLoginDialog(state, action: { payload: { show: boolean } }) {
      state.show_login_dialog = action.payload.show;
    },
  },
});

export const { actions, reducer } = slice;

export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: authSaga });
  // const state = useSelector(selectAuth); // dont want to include here cuz runs selector unnecessarily?
  // return { AuthActionCreators };
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAuthSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
