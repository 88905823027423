import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { useGlobalTour } from 'app/components/Tourguide/Helper';
import { useAuthSelector } from 'app/data/auth';
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Grid,
  Link,
  Slide,
  Tooltip,
  Typography,
} from 'app/design';
import { ChevronLeft as ChevronLeftIcon } from 'app/design/icons-material';
import { useListPhoneNumbersQuery } from 'app/hooks/queries/phoneNumber';
import { sdk } from 'app/sdk';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { PhoneNumber } from './components/PhoneNumber';
import setupAccountDetails from './fn/setupAccountDetails';
import setupCompanyVoicemail from './fn/setupCompanyVoicemail';
import setupFeatureCodesAndNomatch from './fn/setupFeatureCodesAndNomatch';
import setupMainCallflow from './fn/setupMainCallflow';
import setupNumberDetails from './fn/setupNumberDetails';
import setupRingGroup from './fn/setupRingGroup';

const Step2 = ({ user }) => {
  const { handleSubmit, watch, setValue, setError, clearErrors } =
    useFormContext();
  const { auth_token, account_id } = useAuthSelector();

  const [{ openTour }] = useGlobalTour();

  const { data: phoneNumbersData } = useListPhoneNumbersQuery(
    0,
    10, // take
    [], // order by
    {}, // filters
  );
  const { phoneNumbers } = phoneNumbersData ?? {};

  const [isSaving, setIsSaving] = useState(false);
  const [isFailedBuyingNumber, setIsFailedBuyingNumber] = useState(false);
  const [isFailedSaving, setIsFailedSaving] = useState(false);

  const [inVal, setInVal] = useState(true);

  const companyName = watch('companyName');
  const address = watch('address');

  const prevStep = watch('prevStep');
  const transitionToStep = watch('transitionToStep');
  const thisStep = 1;
  const timeout = 350;
  const handlePrev = () => {
    setValue('transitionToStep', thisStep - 1);
    setInVal(false);
    setTimeout(() => {
      // setValue('prevStep', thisStep);
      setValue('activeStep', thisStep - 1);
    }, timeout);
  };

  const handleNext = async () => {
    setIsSaving(true);
    setIsFailedBuyingNumber(false);
    setIsFailedSaving(false);

    // const { auth_token, account_id } = store.getState().auth;

    // Buy number first
    // - ok if fails, just ask them to buy a different number

    let ptn = watch('ptn');

    // get a number from the account if it exists...
    // console.log('phoneNumbers:', phoneNumbers);
    if (phoneNumbers?.length) {
      ptn = phoneNumbers[0].id;
    } else {
      // alert('not buying a number right now');
      // setIsSaving(false);
      // setIsFailedSaving(true);
      // return;
      try {
        const ptnResult = await sdk.phoneNumber.mutate.buy(
          { numbers: [ptn] },
          { auth_token },
        );
      } catch (err) {
        // if failed here, let them try to get another number (failure is expected/ok here, especially if there are alot of people trying to signup at once?)
        alert('Sorry, failed to buy number, please try again');
        setIsSaving(false);
        setIsFailedBuyingNumber(true);
        setValue('ptn', null);
        return;
      }
    }

    // console.log('PTN:', ptn);
    // setIsSaving(false);
    // setIsFailedSaving(true);
    // return;

    // sdk.phoneNumber.query.phoneNumbers(
    //     {
    //       skip,
    //       take,
    //       orderBy,
    //       filters,
    //     },
    //     { authToken: authState.auth_token },
    //   ),

    // Bought number, finish setting up account
    // ---> treat this as "should never fail" -> if it does, immediately involve Support
    // - update account name
    // - save e911 address for number
    // - update caller ID for number (use account name)
    // - create All Users ring group
    // - company voicemail box (and set it up correctly)
    // - set up account call handling (Ring Group, Ring Company Shared Voicemail)
    // - Feature codes
    // - nomatch callflow
    try {
      await setupAccountDetails({
        auth_token,
        ptn,
        accountId: account_id,
        companyName,
        address,
      });
      await setupNumberDetails({
        auth_token,
        user,
        ptn,
        companyName,
        address,
      });
      const { groupId } = await setupRingGroup({ auth_token, userId: user.id });
      const { vmboxId } = await setupCompanyVoicemail({ auth_token });
      // const { vmboxId } = await setupUserVoicemail({ auth_token, user });

      // const vmboxId = '1';
      await setupMainCallflow({
        auth_token,
        ptn,
        accountId: account_id,
        vmboxId,
        groupId,
      });
      await setupFeatureCodesAndNomatch({
        auth_token,
      });
    } catch (err) {
      // TODO: "should never fail"
      console.error(err);
      setIsSaving(false);
      setIsFailedSaving(true);
      return;
    }

    console.log('-DONE-');
    // setIsSaving(false);
    // return;

    // transition to Final Screen
    setTimeout(() => {
      setValue('transitionToStep', thisStep + 1);
      setInVal(false);
      setTimeout(() => {
        // setValue('activeStep', thisStep + 1);
        openTour('onboarding');
      }, timeout);
    }, 2000);
  };

  useEffect(() => {
    setTimeout(() => {
      setValue('prevStep', thisStep);
    }, timeout);
  }, []);

  const direction = inVal
    ? prevStep > thisStep
      ? 'right'
      : 'left'
    : transitionToStep > thisStep
    ? 'right'
    : 'left';
  // console.log('step2 direction:', direction, prevStep);
  return (
    <Slide in={inVal} timeout={timeout} direction={direction}>
      <Box>
        <Fade in={inVal} timeout={timeout}>
          <Box>
            <Box
              sx={{
                borderRadius: '8px',
                background: 'white',
                boxShadow: '0px 4px 14px 4px rgba(27, 125, 144, 0.12)',
                p: 5,
                pt: 3,
                pb: 3,
                transition: 'height 2s linear',
                height: 'auto',
              }}
            >
              {isSaving ? (
                <Typography
                  variant="h3"
                  sx={{ mt: 4, mb: 4, textAlign: 'center' }}
                >
                  <CircularProgress size={44} />
                  <br />
                  <br />
                  One moment while we get your number setup...
                  <br />
                  <br />
                  <Typography variant="caption">
                    (Currently this takes about 60 seconds, please be patient!
                    It wont usually take that long...)
                  </Typography>
                </Typography>
              ) : isFailedSaving ? (
                <Box sx={{ mb: 0, textAlign: 'center' }}>
                  <Typography variant="h2" paragraph>
                    Sorry, we had a problem finishing setup for your account
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Please contact Support and we'll walk you through the rest
                    of setup.
                  </Typography>
                </Box>
              ) : (
                <>
                  {phoneNumbers?.length ? (
                    <Box
                      sx={{
                        p: 2,
                        mb: 4,
                        br: 4,
                        border: theme =>
                          // @ts-ignore
                          `1px solid ${theme.palette.card.border}`,
                      }}
                    >
                      <Typography variant="body1" color="info.main">
                        Existing Number (will actually use):
                        <br />
                        <strong>
                          <PhoneNumberDisplay ptn={phoneNumbers[0].id} />
                        </strong>
                      </Typography>
                    </Box>
                  ) : null}
                  <Box
                    sx={{
                      mb: 6,
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      variant="h2"
                      paragraph
                      sx={{
                        width: '100%',
                        noWrap: true,
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      Your new number for{' '}
                      <Tooltip arrow title={watch('companyName')}>
                        <Box
                          component="span"
                          sx={{
                            color: theme => theme.palette.primary.main,
                          }}
                        >
                          {watch('companyName')}
                        </Box>
                      </Tooltip>
                    </Typography>
                    {isFailedBuyingNumber ? (
                      <Typography variant="body1" color="error" paragraph>
                        Sorry, we failed getting that number.
                        <br />
                        Please try a new number (we selected another new one for
                        you already):
                      </Typography>
                    ) : null}
                    <Typography variant="h1" paragraph>
                      {/* <PhoneNumberDisplay ptn={'+18315555555'} /> */}
                      <PhoneNumber />
                    </Typography>
                    <Typography variant="caption" paragraph>
                      <Link>Change my number</Link>
                    </Typography>
                  </Box>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ mt: 2 }}
                  >
                    <Grid item>
                      <Button
                        // size="small"
                        // variant="contained"
                        // color="primary"
                        onClick={handlePrev}
                        startIcon={<ChevronLeftIcon />}
                      >
                        Go back
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        // size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={!watch('ptn')}
                      >
                        Finish Setup
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
            <Box sx={{ mt: 3, textAlign: 'center' }}>
              <Typography variant="body1">
                Want to port your number? <br />
                You can do so after finishing setup
              </Typography>
            </Box>
          </Box>
        </Fade>
      </Box>
    </Slide>
  );
};

export default Step2;
