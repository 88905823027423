import { useVmboxCreateDialog } from 'app/components/VmboxCreateDialog';
import { useCreateVmbox } from 'app/hooks/mutations/vmbox/useCreateVmbox';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { User } from 'types/user';
import { Button, IconButton } from 'app/design';
import {
  Edit as EditIcon,
  AddCircleOutline as AddIcon,
} from 'app/design/icons-material';

interface CreateVmboxButtonProps {
  onComplete?: (data: any) => void;
  navigateOnComplete?: boolean;
  variant?: 'default' | 'button';
}

const CreateVmboxButton = ({
  onComplete,
  navigateOnComplete,
  variant = 'button',
}: CreateVmboxButtonProps) => {
  const {
    toggleOpen: toggleOpenVmboxCreateDialog,
    Dialog: VmboxCreateDialog,
    DialogProps: VmboxCreateDialogProps,
  } = useVmboxCreateDialog({});

  return (
    <>
      {/*{showDialog ? (
        <QuickFinderGroupDialog
          onSelect={handleSelect}
          mutationLoading={false}
          mutationLoadingLabel={'Adding device...'}
          onCancel={toggleShowDialog}
          // multiple
          // TODO: remove - for demo purposes
          // exclude={[
          //   {
          //     id: '69890c0cf6da793da9c7d8ac5557c3d5',
          //     checked: false,
          //     reason: 'This device is not available.',
          //   },
          //   {
          //     id: 'beda688813d786e8324940ed59c66f53',
          //     checked: true,
          //     reason: 'This device is used in another tier',
          //   },
          //   {
          //     id: '33fbe5302d205a3d519509aa35af1380',
          //     indeterminate: true,
          //     reason: 'This device is disabled. Please enable to select.',
          //   },
          // ]}
        />
      ) : null}*/}
      <VmboxCreateDialog
        {...VmboxCreateDialogProps}
        formDefaults={{
          // owner_id: user.id,
          // owner_type: 'user',
          name: `Voicemail Box`,
          mailbox: '0', // next available vmbox number
        }}
        onComplete={onComplete}
        navigateOnComplete={navigateOnComplete}
        // onSelect={handleSelect}
        onCancel={toggleOpenVmboxCreateDialog}
        startDirty
        // onClear={handleClear}
        // initialSelected={owner ? [owner] : undefined}
        // selectionTitle={
        //   user ? `${user.doc.first_name} ${user.doc.last_name}` : null
        // }
      />
      {variant === 'button' ? (
        <Button
          size="small"
          variant="contained"
          onClick={toggleOpenVmboxCreateDialog}
          startIcon={<AddIcon />}
          // disabled
        >
          Create Voicemail Box
        </Button>
      ) : (
        <IconButton color={'primary'} onClick={toggleOpenVmboxCreateDialog}>
          <AddIcon />
        </IconButton>
      )}
    </>
  );
};

export default CreateVmboxButton;
