import { ExtensionsTableCell } from 'app/components/ExtensionsTableCell';
import { QuickFinderGenericDialog } from 'app/components/QuickFinderGenericDialog';
import { useListTemplatesQuery } from 'app/hooks/queries/template';
import { parseCallflowNumbers } from 'app/utilities';
import {
  QUERY_SEARCH_INPUT_CHANGED,
  useQueryPaginationReducer,
} from 'app/utilities/useQueryPaginationReducer';
import { startCase } from 'lodash';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { QuickFinderDialogProps } from 'types/components';
import { Template } from 'types/template';

const QuickFinderTemplateDialog = ({
  onSelect,
  mutationLoading = false,
  mutationLoadingLabel,
  errorMessage,
  onCancel,
  multiple = false,
  initialSelected,
  selectionTitle,
  exclude,
  passResourceOnSelect,
}: QuickFinderDialogProps) => {
  // query pagination state
  const [{ queryPageSize, queryPageIndex, querySearchInput }, dispatch] =
    useQueryPaginationReducer(0, 5);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (template: Template) => template.doc.name,
      },
    ],
    [],
  );

  // template list
  const templateListQuery = useListTemplatesQuery(
    queryPageIndex * queryPageSize,
    queryPageSize,
    [['name', 'asc']],
    { searchInput: querySearchInput },
  );
  const { callflows, totalCount = 0 } = templateListQuery.data ?? {};

  const handleSearch = (searchInput: string) => {
    if (searchInput === querySearchInput) {
      templateListQuery.refetch();
    } else {
      dispatch({ type: QUERY_SEARCH_INPUT_CHANGED, payload: searchInput });
    }
  };

  return (
    <QuickFinderGenericDialog
      // dialog
      title={multiple ? 'Select Multiple Templates' : 'Select Template'}
      mutationLoading={mutationLoading}
      mutationLoadingLabel={mutationLoadingLabel}
      onSelect={onSelect}
      onCancel={onCancel}
      errorMessage={errorMessage}
      selectionTitle={selectionTitle}
      passResourceOnSelect={passResourceOnSelect}
      // table
      getRowId={(row: Template) => row.id}
      searchPlaceholder={'Search Templates...'}
      totalCount={totalCount}
      columns={columns}
      data={callflows ?? []}
      multiple={multiple}
      initialSelected={initialSelected}
      exclude={exclude}
      onSearch={handleSearch}
      // query
      queryIsFetching={templateListQuery.isFetching}
      queryIsLoading={templateListQuery.isLoading}
      queryPageSize={queryPageSize}
      queryPageIndex={queryPageIndex}
      queryPaginationDispatch={dispatch}
    />
  );
};

export default QuickFinderTemplateDialog;
