import React, { useState, useRef, useCallback, ReactNode } from 'react';

import {
  MoreVert as MoreVertIcon,
  MoreHoriz as MoreHorizIcon,
  Add as AddIcon,
  ExpandMore as ExpandMoreIcon,
} from 'app/design/icons-material';

import { useFindInPage } from 'app/components/FindInPage';
import {
  useHighlightPulse,
  HighlightPulse,
} from 'app/components/FindInPage/HighlightPulse';

import { isArray } from 'lodash';

import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  ThemeProvider,
  Button,
} from 'app/design';

import { css, keyframes } from '@emotion/react';
import { ButtonProps } from 'app/design';

const PulseBackgroundKeyframe = keyframes`
  0% {
    background: rgba(15,163,177,0.3)
  }
  50% {
    background: rgba(15,163,177,0.1)
  }
  100% {
    background: rgba(15,163,177,0.3)
  }
`;

interface ButtonDropdownProps {
  buttonColor?: any;
  variant?: 'default' | 'button';
  vertical?: boolean;
  label?: string;
  size?: ButtonProps['size'];
  onCloseMenu?: () => void;
  menuItems: any[] | any;
  menuItemsDependencies?: any[];
  buttonVariant?: string;
  disabled?: boolean;
  icon?: ReactNode;
}

const ButtonDropdownMenu = React.forwardRef(
  (props: ButtonDropdownProps, ref) => {
    const {
      buttonColor = undefined,
      variant = 'default',
      label = '',
      size = 'small',
      onCloseMenu,
      menuItems,
      menuItemsDependencies = [],
      disabled,
      icon,
      vertical = false,
    } = props;

    let buttonVariant =
      variant === 'default'
        ? props.buttonVariant || 'link'
        : props.buttonVariant || 'outlined';

    const [itemToHighlight, setItemToHighlight] = useState(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClickMenu = event => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
      setAnchorEl(null);
      setItemToHighlight(null);
      onCloseMenu && onCloseMenu();
    };

    const buildMenuItems = (items, itemToHighlight) => {
      return items.map((item, i) => {
        if (React.isValidElement(item)) {
          return <MenuItem key={i}>{item}</MenuItem>;
        }
        return (
          <MenuItem
            key={i}
            onClick={e => {
              handleCloseMenu();
              item.onClick();
            }}
            disabled={item.disabled}
            component={'a'}
            sx={{
              animation:
                item.findInPage && item.text === itemToHighlight
                  ? `${PulseBackgroundKeyframe} 4s infinite`
                  : undefined,
            }}
          >
            <ListItemText primary={item.text} />
          </MenuItem>
        );
      });
    };

    const MenuItems = isArray(menuItems)
      ? buildMenuItems(menuItems, itemToHighlight)
      : menuItems(handleCloseMenu);

    const findInPageOptions = isArray(menuItems)
      ? menuItems
          .filter(item => item.findInPage)
          .map((opt, i) => ({
            label: opt.text,
            value: opt.text,
          }))
      : [];
    const findInPageOnClick = isArray(menuItems)
      ? menuItems
          .filter(item => item.findInPage)
          .map((opt, i) => ({
            onClick: opt.onClick,
          }))
      : [];
    const { doPulse, startAnimation, duration } = useHighlightPulse({});

    const buttonRef = useRef(null);

    const onChoose = useCallback(
      opt => {
        // only startAnimation if this is the one chosen!
        const localOptIdx = findInPageOptions.findIndex(
          o => o.value === opt.value,
        );
        if (localOptIdx === -1) {
          return;
        }
        startAnimation();
        setTimeout(() => {
          // click dropdown button (ie "show")
          // - a diff way of triggering "open" on child?
          // @ts-ignore
          buttonRef?.current?.click();
          // highlight the background of the menu item (until the menu closes again)
          setItemToHighlight(opt.value);

          // trigger onClick for item
          // - onClick might be stale?
          const localOpt = findInPageOnClick[localOptIdx];
          setTimeout(() => {
            localOpt.onClick && localOpt.onClick();
          }, 350);
        }, 350);
      },
      [startAnimation, ...menuItemsDependencies],
    );

    const { ref: boxRef } = useFindInPage({
      name: 'settings button',
      options: findInPageOptions,
      onChoose,
    });

    return (
      <Box
        // @ts-ignore
        ref={boxRef}
        sx={{
          display: 'inline-block',
          position: 'relative',
        }}
      >
        <HighlightPulse in={doPulse} duration={duration} />
        {variant === 'default' ? (
          // @ts-ignore
          <IconButton
            ref={buttonRef}
            color={buttonColor}
            // variant={buttonVariant}
            size={size}
            onClick={handleClickMenu}
          >
            {icon ?? vertical ? <MoreVertIcon /> : <MoreHorizIcon />}
          </IconButton>
        ) : (
          <Button
            ref={buttonRef}
            onClick={handleClickMenu}
            color={buttonColor}
            variant={buttonVariant}
            size={size}
            endIcon={<ExpandMoreIcon />}
            disabled={disabled}
          >
            {label}
          </Button>
        )}

        <Menu
          variant="menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {MenuItems}
        </Menu>
      </Box>
    );
  },
);

export default ButtonDropdownMenu;
