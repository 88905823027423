import { useAuthSelector } from 'app/data/auth';

import { Box, Grid, Typography } from 'app/design';
import { useListDevicesQuery } from 'app/hooks/queries/device';
import React from 'react';
import 'react-phone-input-2/lib/style.css';
// import { SipProvider } from '@nicholasareed/react-sip';
// import { useCiophoneContext, Dispatcher } from './store';
// import CallContainerView from './callcontainer';
// import DialView from './dial';
// import CallDetailView from './calldetail';
import { WebphoneUI } from './UI';

import { CreateWebphone } from './UI/CreateWebphone';

const Webphone = () => {
  // TODO: Get the user's webphone
  // - type=softphone
  // - subtype=webphone

  const authState = useAuthSelector();
  const owner_id = authState?.auth_token_decoded?.owner_id;
  const webphoneQuery = useListDevicesQuery({
    take: 1,
    filters: {
      raw: [
        {
          or: [
            {
              variables: {
                docFilters: [
                  ['owner_id', '=', owner_id],
                  ['device_type', '=', 'webphone'],
                ],
              },
            },
            {
              variables: {
                docFilters: [
                  ['owner_id', '=', owner_id],
                  ['subtype', '=', 'webphone'],
                ],
              },
            },
          ],
        },
      ],
    },
  });

  // console.log('webphoneQuery:', webphoneQuery?.data);

  const device = webphoneQuery.data?.devices?.length
    ? webphoneQuery.data?.devices[0]
    : undefined;

  // console.log('DEVICE:', device);
  // const { devices, user } = useSelector(state => ({
  //   devices: state.lists.devices.list ?? [],
  //   user: state.auth.user_data,
  // }));

  // // TODO: pass in the user's "webphone" device
  // // - allow them to choose which webphone, etc? why would they want to?? (caller id changes??)
  // const device = devices.find(
  //   d =>
  //     d.doc.owner_id === user.id &&
  //     d.doc.device_type === 'softphone' &&
  //     d.doc?.subtype === 'webphone',
  // );

  // if (!device) {

  if (webphoneQuery.isLoading) {
    return <LoadingWebphone />;
  }

  if (!device) return <CreateWebphone refetch={webphoneQuery.refetch} />;
  // }

  return <WebphoneUI device={device} />;
};

const LoadingWebphone = () => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100%', padding: 3 }}
    >
      <Grid item>
        <Box sx={{ maxWidth: '280px', textAlign: 'center' }}>
          <Typography variant="body1" component="div" gutterBottom>
            Initializing, one moment
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Webphone;
