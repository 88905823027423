import { QuickFinderGenericDialog } from 'app/components/QuickFinderGenericDialog';
import { useListExtensionsQuery } from 'app/hooks/queries/extension';
import {
  QUERY_SEARCH_INPUT_CHANGED,
  useQueryPaginationReducer,
} from 'app/utilities/useQueryPaginationReducer';
import * as React from 'react';
import { useMemo } from 'react';
import { QuickFinderDialogProps } from 'types/components';
import { Extension } from 'types/extension';

const QuickFinderExtensionDialog = ({
  onSelect,
  mutationLoading = false,
  mutationLoadingLabel,
  errorMessage,
  onCancel,
  onClear,
  multiple = false,
  allowSelectNone = false,
  initialSelected,
  selectionTitle,
  exclude,
  passResourceOnSelect = false,
}: QuickFinderDialogProps) => {
  // query pagination state
  const [{ queryPageSize, queryPageIndex, querySearchInput }, dispatch] =
    useQueryPaginationReducer(0, 5);

  const columns = useMemo(
    () => [
      {
        Header: 'Ext',
        id: 'ext',
        accessor: (extension: Extension) => extension.number,
        disableToggle: true,
        headerProps: {
          width: 100,
          position: 'sticky',
        },
      },
    ],
    [],
  );

  // extension list
  const extensionListQuery = useListExtensionsQuery({
    skip: queryPageIndex * queryPageSize,
    take: queryPageSize,
    orderBy: [['number', 'asc']],
    filters: {
      searchInput: querySearchInput,
    },
  });
  const { extensions, totalCount = 0 } = extensionListQuery.data ?? {};

  const handleSearch = (searchInput: string) => {
    if (searchInput === querySearchInput) {
      extensionListQuery.refetch();
    } else {
      dispatch({ type: QUERY_SEARCH_INPUT_CHANGED, payload: searchInput });
    }
  };

  return (
    <QuickFinderGenericDialog
      // dialog
      title={multiple ? 'Select Multiple Extensions' : 'Select Extension'}
      mutationLoading={mutationLoading}
      mutationLoadingLabel={mutationLoadingLabel}
      onSelect={onSelect}
      onCancel={onCancel}
      onClear={onClear}
      errorMessage={errorMessage}
      selectionTitle={selectionTitle}
      passResourceOnSelect={passResourceOnSelect}
      // table
      getRowId={(row: Extension) => row.number}
      searchPlaceholder={'Search extensions...'}
      totalCount={totalCount}
      columns={columns}
      data={extensions ?? []}
      multiple={multiple}
      allowSelectNone={allowSelectNone}
      initialSelected={initialSelected}
      exclude={exclude}
      onSearch={handleSearch}
      // query
      queryIsFetching={extensionListQuery.isFetching}
      queryIsLoading={extensionListQuery.isLoading}
      queryPageSize={queryPageSize}
      queryPageIndex={queryPageIndex}
      queryPaginationDispatch={dispatch}
    />
  );
};

export default QuickFinderExtensionDialog;
