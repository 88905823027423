import { Box } from 'app/design';
import { AsYouType } from 'libphonenumber-js';
import React from 'react';
import Flag from 'react-flags';

export const PhoneNumberFlag = ({ ptn }) => {
  const asYouType = new AsYouType();
  asYouType.input(ptn ?? '');
  const code = asYouType.getNumber()?.country ?? 'unknown';
  // const classes = useStyles();
  // className={classes.flag}
  return (
    <Box
      sx={{
        display: 'inline',
        // verticalAlign: 'middle',
        '& > img': {
          height: '0.75em',
          width: '1em',
          // verticalAlign: 'middle',
        },
      }}
    >
      <Flag
        name={code}
        format="svg"
        basePath={process.env.PUBLIC_URL + '/flags'}
      />
    </Box>
  );
};

export default PhoneNumberFlag;
