import { AudioPlayer } from 'app/components/AudioPlayer';
import { MediaDialog } from 'app/components/MediaDialog';
import { Button, Typography } from 'app/design';
import { useMediaByIdQuery } from 'app/hooks/queries/media';
import { useToggleReducer } from 'app/utilities';
import React from 'react';
import { useFormContext } from 'react-hook-form';

// interface declaring which props are required/allowed
// interface VoicemailBoxProps {
//   // directoryId: string;
//   callflow?: any;
//   open?: boolean;
//   onCancel: () => void;
//   // onComplete: () => void;
// }

const MediaId = ({ item, modifyPath, onChangeSimple }) => {
  const { varItem, moduleItem, moduleModifyPath } = item;
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const [showFinderDialog, toggleShowFindMediaDialog] = useToggleReducer(false);

  const key = varItem.key;
  const variables = watch(`${modifyPath}callflow.strategy.simple.variables`);

  // simple variable values
  const currentVarOutputValue = varItem.output_value;
  const defaultValueInFlow = varItem.output_value_default;
  const simpleValueInCallflow = variables[key];

  // get CURRENT value FROM MODULE
  // - for when saved by the Advanced Editor!
  //   - that doesnt update the varItem, it only updates the actual flow!
  const currentValue = moduleItem?.data?.id;
  const mediaId = simpleValueInCallflow ?? currentValue;

  // const mediaUrlCurrent = useMediaUrl({ id: mediaId });

  // Load media via query
  const {
    data: mediaCurrent,
    isLoading: mediaCurrentIsLoading,
    isFetched: mediaCurrentIsFetched,
  } = useMediaByIdQuery(currentValue);

  const {
    data: mediaSimple,
    isLoading: mediaSimpleIsLoading,
    isFetched: mediaSimpleIsFetched,
  } = useMediaByIdQuery(simpleValueInCallflow);

  const onChangeVmboxSelect = selected => {
    const [vmboxId] = selected;
    setValue(`${modifyPath}callflow.strategy.simple.variables.${key}`, vmboxId);
    onChangeSimple && onChangeSimple();
    // setAtPath(tmpModuleItem, `data.id`, vmboxId); // newValue?.value === id
    // setModifyModuleItem({ ...tmpModuleItem });
    toggleShowFindMediaDialog();
  };

  const handleChangeAudio = mediaId => {
    // setAtPath(callflow, `${modifyPath}.data.id`, mediaId);
    // setCallflow({ ...callflow });
    setValue(`${modifyPath}callflow.strategy.simple.variables.${key}`, mediaId);
    // TODO: this may work for "account no_schedule" but may fail at others, need to verify
    // - we're doing this so that the "simple" value is applied immediately!
    //   - maybe should emit an event that rebuilds, instead?
    setValue(`${modifyPath}callflow${moduleModifyPath}.data.data.id`, mediaId, {
      shouldDirty: true,
    });
    onChangeSimple && onChangeSimple();
    toggleShowFindMediaDialog();
  };

  const handleClear = () => {
    // setAtPath(tmpModuleItem, `data.id`, null); // newValue?.value === id
    // setModifyModuleItem({ ...tmpModuleItem });
    setValue(`${modifyPath}callflow.strategy.simple.variables.${key}`, null);
    toggleShowFindMediaDialog();
  };

  // console.log('varitem MediaId:', varItem, {
  //   defaultValueInFlow,
  //   simpleValueInCallflow,
  // });

  return (
    <div>
      {/* {showFinderDialog ? (
        <QuickFinderVmboxDialog
          onSelect={onChangeVmboxSelect}
          onCancel={toggleShowFindMediaDialog}
          onClear={handleClear}
          initialSelected={simpleValueInCallflow ? [simpleValueInCallflow] : []}
          selectionTitle={vmboxSimple ? `${vmboxSimple.doc.name}` : null}
          allowSelectNone
        />
      ) : null} */}
      {showFinderDialog && (
        <MediaDialog
          mediaNameToSaveAs={'Call Routing Audio'}
          onComplete={handleChangeAudio}
          onCancel={toggleShowFindMediaDialog}
          type={'Call Routing Audio'}
          mediaId={mediaId}
        />
      )}
      <Typography variant="h3">Audio: </Typography>

      {mediaId ? <AudioPlayer mediaId={mediaId} /> : null}

      {/* <Typography variant="bodyBig" sx={{ fontWeight: 'bold' }} paragraph>
        {mediaCurrentIsLoading
          ? 'Loading...'
          : mediaCurrent
          ? mediaCurrent.doc?.name ?? 'Invalid media'
          : 'None set'}
      </Typography> */}

      <Button
        color="primary"
        variant="outlined"
        size="small"
        onClick={toggleShowFindMediaDialog}
      >
        Change Audio
      </Button>
      {/* <Typography variant="body1">
        Current:{' '}
        {vmboxCurrentIsLoading
          ? 'Loading...'
          : vmboxCurrent
          ? vmboxCurrent.doc?.name ?? 'Invalid voicemail box'
          : 'None set'}
      </Typography>
      <Typography variant="body1" onClick={toggleShowFindMediaDialog}>
        Custom:
        {vmboxSimpleIsLoading
          ? 'Loading...'
          : vmboxSimple
          ? vmboxSimple.doc?.name ?? 'Invalid voicemail box'
          : 'None set'}
      </Typography> */}
    </div>
  );
};

export default MediaId;
