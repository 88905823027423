import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { useProvisionerPhoneModelQuery } from 'app/hooks/queries/provisioner';
import useProvisionerBrandsAndModelsQuery from 'app/hooks/queries/provisioner/useProvisionerBrandsAndModelsQuery';
import * as React from 'react';
import { Device } from 'types/device';
import { useImmer } from 'use-immer';

interface DeviceProvisionerSettingsProps {
  device: Device;
  onComplete: () => void;
}

const DeviceProvisionerSettings = ({
  device,
  onComplete,
}: DeviceProvisionerSettingsProps) => {
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'sideCarsAndComboKeys',
    options: [
      {
        label: 'Sidecars & Combo Keys',
        value: 'sideCarsAndComboKeys',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  // unauthorized request with cluster id / auth token
  const { data: provisionerInfo } = useProvisionerPhoneModelQuery({
    brand: device.doc.provision?.endpoint_brand,
    family: device.doc.provision?.endpoint_family,
    model: device.doc.provision?.endpoint_model,
  });

  // const { data: brandsAndModels } = useProvisionerBrandsAndModelsQuery();
  // console.log('provisioner info', brandsAndModels);

  if (!provisionerInfo) {
    return null;
  }

  // TODO PLACEHOLDER - need to implement component

  return (
    // @ts-ignore
    <div ref={ref}>
      <HighlightPulse {...highlightPulseProps} />

      <GenericSettingsCardField title={'Sidecars'} editOnClick={() => {}} />
      <br />
      <GenericSettingsCardField title={'Combo Keys'} editOnClick={() => {}} />
    </div>
  );

  // const [deviceProvisionerInfo] = useImmer(async () => {
  //   const [modelResponse] = await Promise.all([
  //     // getProvisionerPhoneModel(
  //     //   device.doc.provision?.endpoint_brand,
  //     //   device.doc.provision?.endpoint_family,
  //     //   device.doc.provision?.endpoint_model,
  //     // ),
  //   ]);
  //
  //   // const sidecars = Object.keys(modelResponse.data.data?.template || {})
  //   //   .filter(key => key.startsWith('sidecar_'))
  //   //   .sort();
  //   //
  //   // return {
  //   //   ...modelResponse.data.data,
  //   //   sidecars,
  //   // };
  // });
};

export default DeviceProvisionerSettings;
