import {
  AvatarGroup,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Typography,
  Box,
  CardActions,
  IconButton,
  Button,
  InfoTooltip,
  Tooltip,
} from 'app/design';
import {
  CellTower as CellTowerIcon,
  PhoneCallback as PhoneCallbackIcon,
  CallEnd as CallEndIcon,
  LocalParking as LocalParkingIcon,
} from 'app/design/icons-material';
import { AvatarCell } from 'app/components/ListUsers/components/AvatarCell';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { useLocalSelector } from 'app/data/local';
import { useListUsersQuery } from 'app/hooks/queries/user';
import { ListCdrInteractions } from 'app/reports/components/ListCdrInteractions';
import { durationFormat } from 'app/reports/components/utils';
import { groupBy, orderBy, startCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Avatar } from 'app/design';
const epochOffset = 62167219200;

const validate = interactions => {
  const allInteractions = Object.entries(interactions);
  const validatedInteractions: any = {};

  allInteractions.forEach(([key, legs]) => {
    // @ts-ignore
    if (legs.length === 1) {
      // check if dialed in to parked call
      // TODO: valid way? need LOTS of testing with multiple callers, etc.

      // @ts-ignore
      const [leg] = legs;

      // seems like dials to parked calls don't use standard leg ID
      // standard: 138978492_47036879@67.231.9.143
      // parked received: llrt8mndsmb1e8kjqche
      // TODO: better way of determing picked up parked call leg?
      if (leg.id.includes('@')) {
        // not a picked up park leg
        validatedInteractions[key] = legs;
      } else {
        // find other leg interaction
        const otherLegInteraction = allInteractions.find(([key, legs]) => {
          // @ts-ignore
          const legIds = legs.map(leg => leg.listing.uuid);
          return legIds.includes(leg.listing.other_leg);
        });

        if (otherLegInteraction) {
          // add leg to other leg interaction
          const [key, legs] = otherLegInteraction;
          // @ts-ignore
          console.log('merging', key, orderBy([...legs, leg]));
          validatedInteractions[key] = orderBy(
            // @ts-ignore
            [...legs, leg],
            'listing.timestamp',
          );
        }
      }
    } else {
      validatedInteractions[key] = legs;
    }
  });

  return validatedInteractions;
};

function AdminLiveCalls() {
  const { channels } = useLocalSelector();
  const [refreshCallHistory, setRefreshCallHistory] = useState<any>(null);

  const calls = Object.entries(
    validate(
      groupBy(orderBy(channels, 'listing.timestamp'), 'listing.interaction_id'),
    ),
  ).map(([interaction_id, channels]: [string, any]) => {
    const userIds: string[] = [];

    channels.forEach(channel => {
      const ownerId = channel?.listing?.custom_channel_vars?.['Owner-ID'];
      if (ownerId) {
        userIds.push(ownerId);
      }
    });

    // TODO: on channels check for metric of call quality, might be per channel
    // TODO: callflow id in channels?

    return {
      interaction_id,
      channels,
      userIds,
      answered: channels.some(channel => channel.listing.answered),
      direction:
        channels[0].listing.custom_channel_vars['Resource-Type'] ===
        'offnet-origination'
          ? 'Inbound'
          : 'Outbound', // verify always true?
      startTime: channels[0].listing.timestamp - epochOffset,
      // TODO: only show if owned channels from ALL users are on hold?
      onHold: channels.some(channel => channel.listing.is_onhold),
      parked: !userIds.length, // TODO: better way of determining parked
      caller:
        channels[0].listing.custom_channel_vars['E164-Origination'] ??
        channels[1].listing.custom_channel_vars['E164-Origination'],
      callee:
        channels[0].listing.custom_channel_vars['E164-Destination'] ??
        channels[1].listing.custom_channel_vars['E164-Destination'],
    };
  });

  const {
    data: usersResp,
    isLoading: usersAreLoading,
    isError: usersError,
    remove: removeUserQuery,
  } = useListUsersQuery({
    quick: true,
    filters: {
      context: {
        ids: calls.flatMap(call => call.userIds),
      },
    },
    options: {
      enabled: !!calls.length,
      keepPreviousData: false,
    },
  });

  const {
    // @ts-ignore
    users: { users },
  } = usersResp ?? { users: {} };

  console.log('calls', calls);

  return (
    <div style={{}}>
      <Grid
        container
        columnSpacing={1}
        alignItems={'center'}
        justifyContent={'left'}
      >
        <Grid item>
          <Typography variant="h2">
            <CellTowerIcon sx={{ verticalAlign: 'middle' }} />
          </Typography>
        </Grid>
        <Grid item sx={{ whiteSpace: 'nowrap' }}>
          <Typography variant="h1">{`Live Calls`}</Typography>
          {/*<Typography variant="h1">{`${accountDoc.name}`}</Typography>*/}
        </Grid>
      </Grid>
      <br />
      <Grid container columnSpacing={3}>
        <Grid item md={6}>
          <Grid container sx={{ minHeight: '30vh' }} columnSpacing={1}>
            <Grid item xs={5}>
              <Grid container alignItems={'center'} spacing={1}>
                {calls.length ? (
                  calls.map(call => {
                    const color = call.answered
                      ? call.onHold
                        ? 'blue'
                        : // : call.parked
                          // ? 'purple'
                          'green'
                      : 'orange';

                    const label = call.answered
                      ? call.onHold
                        ? 'On Hold'
                        : // : call.parked
                          // ? 'Parked'
                          'Active'
                      : 'Ringing';

                    const callUsers = call.userIds.map(userId =>
                      users?.find(user => user.id === userId),
                    );

                    return (
                      <Grid item>
                        <Card
                          key={call.interaction_id}
                          variant={'outlined'}
                          sx={{ overflow: 'clip', height: 155 }}
                        >
                          <Grid
                            container
                            sx={{ height: '100%' }}
                            justifyContent={'space-between'}
                            columnSpacing={1}
                          >
                            <Grid item>
                              <Box
                                sx={{
                                  width: 10,
                                  height: '100%',
                                  background: color,
                                }}
                              />
                            </Grid>
                            <Grid item>
                              <CardHeader
                                title={
                                  <Grid
                                    container
                                    alignItems={'center'}
                                    columnSpacing={1}
                                  >
                                    <Grid item>
                                      <AvatarGroup max={4}>
                                        {channels.length &&
                                          (usersAreLoading ? (
                                            <CircularProgress
                                              variant={'indeterminate'}
                                            />
                                          ) : call.answered ? (
                                            // callUsers.length ? (
                                            // only show if channels exist (query data is still present
                                            // even though disabled)
                                            callUsers?.map(user => (
                                              <AvatarCell resource={user} />
                                            ))
                                          ) : // ) : (
                                          //   <Tooltip arrow title={'Parked Call'}>
                                          //     <Avatar sx={{ background: 'purple' }}>
                                          //       <LocalParkingIcon />
                                          //     </Avatar>
                                          //   </Tooltip>
                                          // )
                                          null)}
                                      </AvatarGroup>
                                    </Grid>
                                    <Grid item sx={{ flex: 1 }}>
                                      {call.direction}
                                    </Grid>
                                    <Grid item>
                                      <DurationComponent call={call} />
                                    </Grid>
                                  </Grid>
                                }
                              />
                              <CardContent sx={{ mt: -1 }}>
                                <Grid container alignItems={'center'}>
                                  <Grid item>
                                    <Grid
                                      container
                                      justifyContent={'space-between'}
                                      columnSpacing={1}
                                    >
                                      <Grid item>
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                          }}
                                        >
                                          From:
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <PhoneNumberDisplay
                                          ptn={call.caller ?? ''}
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      container
                                      justifyContent={'space-between'}
                                      columnSpacing={1}
                                    >
                                      <Grid item>
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                          }}
                                        >
                                          To:
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <PhoneNumberDisplay
                                          ptn={call.callee ?? ''}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </CardContent>
                              <CardActions
                                sx={{ justifyContent: 'right', mt: -2 }}
                              >
                                <Typography
                                  variant={'h6'}
                                  color={color}
                                  sx={{ flex: 1 }}
                                >
                                  {label}
                                </Typography>
                                {/* <Tooltip arrow title={'Join Call'}>
                                  <Button>
                                    <PhoneCallbackIcon color={'success'} />
                                  </Button>
                                </Tooltip>
                                <Tooltip arrow title={'End Call'}>
                                  <Button>
                                    <CallEndIcon color={'error'} />
                                  </Button>
                                </Tooltip>*/}
                              </CardActions>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item>There are no active calls at this time.</Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <ListCdrInteractions pageSize={5} refresh={refreshCallHistory} />
        </Grid>
      </Grid>
    </div>
  );
}

const DurationComponent = ({ call }: { call: any }) => {
  const [timestamp, setTimestamp] = useState(Date.now());

  // update call duration

  useEffect(() => {
    setTimeout(() => setTimestamp(Date.now()), 1000);
  }, [timestamp]);

  return (
    <Typography
      variant={'subtitle1'}
      sx={{
        color: 'text.secondary',
        width: 50,
      }}
    >
      {durationFormat(timestamp / 1000 - call.startTime)}
    </Typography>
  );
};

export default AdminLiveCalls;
