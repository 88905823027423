import { Box, CircularProgress, DialogContent, Grid } from 'app/design';
import * as React from 'react';

interface DialogCircularProgressProps {
  label?: string;
}

const DialogCircularProgress = ({ label }: DialogCircularProgressProps) => {
  return (
    <DialogContent>
      <Grid
        container
        sx={{ width: '100%' }}
        spacing={1}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Grid item md={12}>
          <Box sx={{ placeItems: 'center', display: 'grid' }}>
            {label ?? 'Loading'}
          </Box>
        </Grid>
        <Grid item md={12}>
          <Box sx={{ placeItems: 'center', display: 'grid' }}>
            <CircularProgress variant={'indeterminate'} />
          </Box>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default DialogCircularProgress;
