import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

export const useDeleteGroup = () => {
  const authState = useAuthSelector();
  return useMutation<any, AxiosError, string, unknown>((groupId: string) =>
    sdk.group.mutate.delete(groupId, authState?.auth_token),
  );
};
