import React, { useEffect, useCallback, useState, useRef } from 'react';

import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js';
import {
  Container,
  Typography,
  Dialog,
  Button,
  Grid,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Drawer,
  Paper,
  Divider,
  Link,
  LinearProgress,
  InputTextField,
  Box,
  IconButton,
} from 'app/design';

const noop = () => {};

const Permissions = props => {
  // const { show } = props;

  const [num, setNum] = useState(1); // used for incrementing
  const incrementNum = () => setNum(n => n + 1);

  const [micState, setMicState] = useState(null);
  const [cameraState, setCameraState] = useState(null);

  useEffect(() => {
    // if (!navigator.permissions?.query) {
    //   return 'prompt';
    // }

    // check mic state
    const checkMic = async () => {
      const mic_permission = await navigator.permissions.query({
        name: 'microphone',
      });
      setMicState(mic_permission.state);
      const micFunc = () => {
        console.log('done changing mic permission!');
        // incrementNum();
        mic_permission.removeEventListener('change', micFunc);
        checkMic();
      };
      mic_permission.addEventListener('change', micFunc);
    };
    checkMic();

    // // check camera state
    // const checkCamera = async () => {
    //   const camera_permission = await navigator.permissions.query({
    //     name: 'camera',
    //   });
    //   setCameraState(camera_permission.state);
    //   const cameraFunc = () => {
    //     console.log('done changing camera permission!');
    //     // incrementNum();
    //     camera_permission.removeEventListener('change', cameraFunc);
    //     checkCamera();
    //   };
    //   camera_permission.addEventListener('change', cameraFunc);
    // };
    // checkCamera();
  }, []);

  // TODO: request both at once!

  const handleClickMic = () => {
    switch (micState) {
      case 'prompt':
        navigator.getUserMedia({ audio: true }, noop, noop);
        break;

      case 'denied':
        // instructions on how to fix!
        break;

      case 'granted':
      default:
        break;
    }
  };

  const handleClickCamera = () => {
    switch (cameraState) {
      case 'prompt':
        navigator.getUserMedia({ video: true }, noop, noop);
        break;

      case 'denied':
        // instructions on how to fix!
        break;

      case 'granted':
      default:
        break;
    }
  };

  const handleClickBoth = () => {
    navigator.getUserMedia({ audio: true, video: true }, noop, noop);
  };

  // dont show if either is in the "figureing out" stage, or if everything is golden
  if (
    !micState ||
    // !cameraState ||
    micState === 'granted'
    // && cameraState === 'granted'
  ) {
    // all good!
    return null;
  }

  // not both?
  if (
    micState === 'prompt'
    // && cameraState === 'prompt'
  ) {
    return (
      <div
        style={{
          borderBottom: '1px solid #ccc',
          background: 'rgba(250,250,250,0.8)',
          padding: 4,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
          }}
        >
          <div style={{ flex: 1 }}>
            <Button
              onClick={handleClickBoth}
              variant="contained"
              color="success"
              fullWidth
              size="large"
            >
              Allow Mic and Speakers
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        borderBottom: '1px solid #ccc',
        background: 'rgba(250,250,250,0.8)',
        padding: 4,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
        }}
      >
        {/* Mic */}
        {micState === 'prompt' ? (
          <div style={{ flex: 1 }}>
            <Button
              variant="contained"
              color="success"
              fullWidth
              onClick={handleClickMic}
              size="large"
            >
              Allow Mic &amp; Speakers
            </Button>
          </div>
        ) : (
          ''
        )}
        {micState === 'denied' ? (
          <div style={{ flex: 1 }}>
            <Button
              variant="outlined"
              color="warning"
              fullWidth
              onClick={handleClickMic}
            >
              Fix Mic &amp; Speakers
            </Button>
          </div>
        ) : (
          ''
        )}
        {/* Camera */}
        {/* {cameraState === 'prompt' ? (
          <div style={{ flex: 1 }}>
            <Button
              variant="outlined"
              color="success"
              fullWidth
              onClick={handleClickCamera}
            >
              Allow Camera
            </Button>
          </div>
        ) : (
          ''
        )}
        {cameraState === 'denied' ? (
          <div style={{ flex: 1 }}>
            <Button
              variant="outlined"
              color="warning"
              fullWidth
              onClick={handleClickCamera}
            >
              Fix Camera
            </Button>
          </div>
        ) : (
          ''
        )} */}
      </div>
    </div>
  );
};

export default Permissions;
