import { ButtonDropdownMenu } from 'app/components/ButtonDropdownMenu';
import { getTimeRange } from 'app/reports/components/utils';
import React, { useState } from 'react';
import { TimeRange } from 'types/generic';

interface TimeRangeSelectProps {
  setTimeRange: (timeRange: any) => void;
}

const TimeRangeSelect = ({ setTimeRange }: TimeRangeSelectProps) => {
  const [menuItems] = useState(() => [
    {
      text: 'Today',
      onClick: async e => {
        setTimeRange(TimeRange.Today);
      },
    },
    {
      text: 'This Week',
      onClick: () => {
        setTimeRange(TimeRange.ThisWeek);
      },
    },
    {
      text: 'Last Week',
      onClick: () => {
        setTimeRange(TimeRange.LastWeek);
      },
    },
    {
      text: 'This Month',
      onClick: () => {
        setTimeRange(TimeRange.ThisMonth);
      },
    },
    {
      text: 'Last Month',
      onClick: () => {
        setTimeRange(TimeRange.LastMonth);
      },
    },
  ]);

  return (
    <ButtonDropdownMenu
      // @ts-ignore
      variant="outlined"
      label="Time Range"
      menuItems={menuItems}
      menuItemsDependencies={[menuItems]}
    />
  );
};

export default TimeRangeSelect;
