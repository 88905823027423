import MediaDialog from 'app/components/MediaDialog/MediaDialog';
import { useUpdateUserPartial } from 'app/hooks/mutations/user';
import { useUserQuery } from 'app/hooks/queries/user';
import React from 'react';
import { MediaType } from 'types/media';

interface EditHoldMusicDialogProps {
  userId: string;
  onComplete: () => void;
  onCancel: () => void;
  mediaId?: string | null;
}

const EditHoldMusicDialogUser = ({
  userId,
  mediaId,
  onComplete,
  onCancel,
}: EditHoldMusicDialogProps) => {
  const updateUser = useUpdateUserPartial();
  const { data: user } = useUserQuery(userId);

  const handleComplete = async (mediaId: string | null) => {
    return updateUser.mutateAsync(
      {
        id: userId,
        music_on_hold: {
          media_id: mediaId,
        },
      },
      {
        onSuccess: () => {
          onComplete();
        },
      },
    );

    // resolve promise with toast notifications
    // await toast.promise(updateUserPromise, {
    //   pending: 'Updating user details...',
    //   error: 'Failed to update user details.',
    //   success: 'User details updated!',
    // });
  };

  return (
    <MediaDialog
      type={'Hold Music'}
      mediaNameToSaveAs={`${user?.extra.fullName} Hold Music`}
      mediaId={mediaId}
      onComplete={handleComplete}
      onCancel={onCancel}
      allowedTypes={[MediaType.BuiltIn, MediaType.Upload]}
    />
  );
};

export default EditHoldMusicDialogUser;
