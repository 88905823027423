import React, { useState, useContext, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Paper,
  TooltipLight,
  Icon,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ButtonDropdownMenu,
} from 'app/design';
import {
  Delete as DeleteIcon,
  Eject as EjectIcon,
  PresentToAll as PresentToAllIcon,
  FileCopy as FileCopyIcon,
  Phone as PhoneIcon,
  Add as AddIcon,
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
} from 'app/design/icons-material';

// import { getAtPath, setAtPath } from 'app/utilities';

import { IvrMenuEventEmitterContext, useSharedFlow } from '../../../..';

import { USER_ADDABLE_COMPONENTS } from 'app/components/IvrBuilder/strategies/blank';
// import * as OptionComponents from '../../../../../Strategies/components';

import { TemplateList } from '../TemplateList';

export const StrategySelect = props => {
  const {
    placeholder,
    preventComplexBeforeAfter,
    requireAllowAfter, // determines if the Selectable module is required to have the "allowAfter" value set
    requireAllowBefore, // determines if the Selectable module is required to have the "allowBefore" value set
    onChange,
    onChooseTemplate,
    selected,
    isInTemplate,
    showTemplateList, // depends on "inTemplate" of course
    endpointAlreadyInArr, // for the Strategy, to prevent a second Endpoint in an array...(might not be correct?)
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // TODO: this list of options should be from a different list of possible "before" and "after" possible components
  // - type, options[props] like in Strategies.componentConfig.components
  const options = USER_ADDABLE_COMPONENTS.filter(c =>
    c.allowFunc ? c.allowFunc({ isInTemplate, endpointAlreadyInArr }) : true,
  )
    .map(component => {
      // console.log('optKey:', optKey);
      const module = component; //OptionComponents[component.type];
      // console.log('type:', component.type);
      // const allow = requireAllowBefore
      //   ? module.allowBefore === true
      //     ? true
      //     : false
      //   : true && requireAllowAfter
      //   ? module.allowAfter === true
      //     ? true
      //     : false
      //   : true;
      const allow = requireAllowAfter
        ? module.allowAfter === true
          ? true
          : false
        : true;
      return allow ? { ...component, allow: true } : component;
    })
    .map(component => ({
      label: component.name,
      value: component,
      allow: component.allow,
      type: component.type,
    }));

  const opt = null;
  return (
    <>
      <Grid container spacing={3} noWrap>
        <Grid item style={{ flex: 1 }}>
          <div>
            <Typography variant="h6" gutterBottom>
              Standard Components
            </Typography>
          </div>

          <>
            {options
              .filter(opt => opt.allow)
              .map(opt => (
                <Paper
                  key={opt.label}
                  lcolor={
                    JSON.stringify(opt) === JSON.stringify(selected)
                      ? 'teal.50'
                      : 'grey.50'
                  }
                  onClick={() => onChange(opt)}
                  padding={1}
                  sx={{
                    margin: '4px 0',
                    cursor: 'pointer',
                    '&:hover': {
                      background: theme => theme.palette.info.light,
                    },
                  }}
                  elevation={0}
                  // elevateOnHover
                >
                  <Grid container alignItems="center" noWrap>
                    <Grid item sx={{ padding: '4px 12px 4px 4px' }}>
                      <Icon
                        style={{ verticalAlign: 'text-top' }}
                        fontSize="small"
                      >
                        {opt.value.icon ?? 'help_outline'}
                      </Icon>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" strong>
                        {opt.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
          </>
        </Grid>
        {false && showTemplateList && !isInTemplate ? (
          <Grid item xs={6}>
            {/* <div>
              <Typography variant="h7">Templates</Typography>
            </div> */}
            <TemplateList onChooseTemplate={onChooseTemplate} />
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </>
  );
};

export default StrategySelect;
