import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';

import { Chip, Switch, Tooltip } from '@mui/material';
import React, { useRef } from 'react';

export interface ChipSwitchProps {
  checked?: boolean;
  onChange?: any;
  size?: string;
  infoTooltip?: string;
  label?: any;
  icon?: any;
  onColor?: string;
  offColor?: string;
  disabled?: boolean;
  sx?: any;
}

const ChipSwitch = React.forwardRef((props: ChipSwitchProps, ref) => {
  const {
    checked = false,
    onChange = () => {},
    size = 'small',
    infoTooltip = '',
    icon = undefined,
    label = '',
    offColor = 'default',
    onColor = 'success',
    disabled = false,
    sx = {},
    ...restProps
  } = props;

  const thisRef = useRef(null);

  // const isHovering = useHoverDirty(ref ? ref : thisRef);

  return (
    // @ts-ignore
    <Chip
      ref={ref ? ref : thisRef}
      size={size}
      color={checked ? onColor : offColor}
      variant={checked ? 'contained' : 'outlined'}
      disabled={disabled}
      icon={icon}
      // sx={{ boxShadow: isHovering ? 4 : 0 }}
      label={
        <>
          {label}
          {infoTooltip?.length ? (
            <span style={{ display: 'inline-block', paddingLeft: '2px' }}>
              <Tooltip title={infoTooltip} arrow>
                <InfoOutlinedIcon
                  sx={{
                    // verticalAlign: 'top',
                    fontSize: '14px',
                    opacity: 0.5,
                  }}
                />
              </Tooltip>
            </span>
          ) : (
            ''
          )}
          <Switch
            // @ts-ignore
            size={size}
            checked={checked}
            onChange={onChange}
            color="default"
            // color={checked ? onColor : offColor}
          />
        </>
      }
      sx={sx}
      {...restProps}
    />
  );
});

export default ChipSwitch;
