import React from 'react';
import { GlobalStyles } from 'app/design';

export const globalStyles = (
  <GlobalStyles
    styles={{
      '.tmp-tour-class': {
        maxWidth: '800px !important', // cannot use "auto !important" for some reason :( )
      },
      '.tmp-tour-hide-content': {
        opacity: 0,
      },
      '.tmp-mask-class': {
        opacity: 0.3,
      },
    }}
  />
);
