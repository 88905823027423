import React, { useState, useContext, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Paper,
  Tooltip,
  Icon,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from 'app/design';

import { ButtonDropdownMenu } from 'app/components/ButtonDropdownMenu';

import {
  Delete as DeleteIcon,
  Eject as EjectIcon,
  PresentToAll as PresentToAllIcon,
  FileCopy as FileCopyIcon,
  Phone as PhoneIcon,
  Add as AddIcon,
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
} from 'app/design/icons-material';

// import { getAtPath, setAtPath } from 'app/utilities';

import { IvrMenuEventEmitterContext, useSharedFlow } from '../../../..';

// import { USER_ADDABLE_COMPONENTS } from '../../../../../Strategies/base/GenericDefault';
// import * as OptionComponents from '../../../../../Strategies/components';

import { MenuItem_Add } from './Add';
import { MenuItem_AddBefore } from './AddBefore';
import { MenuItem_Duplicate } from './Duplicate';
import { MenuItem_Copy } from './Copy';
import { MenuItem_CreateTemplateFrom } from './CreateTemplateFrom';
import { MenuItem_Move } from './Move';
import { MenuItem_Modify } from './Modify';
import { MenuItem_Remove } from './Remove';
import { MenuItem_Variables } from './Variables';
import { MenuItem_EjectSimple } from './EjectSimple';
import { MenuItem_Custom } from './Custom';

export const MenuButton = props => {
  const { data, mode, menuItems } = props;
  const {
    insertBefore, // bool
    insertAfterData,
    infoIdx,
    currentCallflow,
    componentData,
    componentInfo,
    callflow,
    setCallflow,
    modifyPath,
  } = data;

  const [sharedFlow, setSharedFlow] = useSharedFlow();

  const ee = useContext(IvrMenuEventEmitterContext);

  const handleDuplicateTo = () => {
    // alert('duplicate to');
    const componentInfo =
      currentCallflow.strategy.config.components.slice(infoIdx);
    const componentData = currentCallflow.strategy.data.opts.slice(infoIdx);

    // console.log('ARR:', componentData);

    setSharedFlow(s => ({
      ...s,
      state: 'duplicate-to',
      data: {
        // array to concat to wherever it is being duplicated
        // - TODO: different way of doing this? create a new strategy/callflow each time?
        duplicateJson: JSON.parse(
          JSON.stringify({
            componentInfo,
            componentData,
          }),
        ),
      },
    }));
  };

  const handleEdit = () => {};

  const handleAdd = () => {};

  const handleRemove = () => {};

  const updatedItems = menuItems
    .filter(x => x) // get rid of empty items
    .map(item => {
      switch (item?.type ?? item) {
        // defaults
        case 'add':
          return React.forwardRef((props, ref) => (
            <MenuItem_Add {...props} {...item?.props} />
          ));
        case 'add-before':
          return React.forwardRef((props, ref) => (
            <MenuItem_AddBefore {...props} {...item?.props} />
          ));
        case 'move':
          return React.forwardRef((props, ref) => (
            <MenuItem_Move {...props} {...item?.props} />
          ));
        case 'duplicate':
          return React.forwardRef((props, ref) => (
            <MenuItem_Duplicate {...props} {...item?.props} />
          ));
        case 'copy':
          return React.forwardRef((props, ref) => (
            <MenuItem_Copy {...props} {...item?.props} />
          ));
        case 'remove':
          return React.forwardRef((props, ref) => (
            <MenuItem_Remove {...props} {...item?.props} />
          ));
        case 'modify':
          return React.forwardRef((props, ref) => (
            <MenuItem_Modify {...props} {...item?.props} />
          ));
        case 'create_template':
          return React.forwardRef((props, ref) => (
            <MenuItem_CreateTemplateFrom {...props} {...item?.props} />
          ));
        case 'variables':
          return React.forwardRef((props, ref) => (
            <MenuItem_Variables {...props} {...item?.props} />
          ));
        case 'eject-simple':
          return React.forwardRef((props, ref) => (
            <MenuItem_EjectSimple {...props} {...item?.props} />
          ));
        case 'custom':
          return React.forwardRef((props, ref) => (
            <MenuItem_Custom {...props} {...item?.props} />
          ));

        default:
          console.error('invalid type:', item);
          break;
      }
      return false;
    })
    .filter(x => x);

  return (
    <>
      <div style={{ position: 'absolute', top: -10, right: -8 }}>
        <Tooltip title={<>Open Menu</>} placement="bottom" type="light" arrow>
          <ButtonDropdownMenu
            buttonVariant="outlined"
            buttonColor="primary"
            menuItems={onClose =>
              updatedItems.map((TmpMenuComponent, i) => (
                <TmpMenuComponent {...props} key={i} onClose={onClose} />
              ))
            }
          />
          {/* <Button onClick={handleDuplicateTo} size="small" variant="outlined">
            <MoreHorizIcon />
          </Button> */}
        </Tooltip>
      </div>
    </>
  );
};

// export const InsertBeforeButton = (props) => {
//   const { insertAfterData, mode } = props;

//   const [showInsertBefore, setShowInsertBefore] = useState(null);
//   const setShowInsertBeforeWrap = (setTo) => () => setShowInsertBefore(setTo);

//   const classes = useStyles({ mode });

//   return (
//     <>
//       {showInsertBefore && (
//         <ChooseDialog
//           {...insertAfterData}
//           requireAllowAfter={true}
//           index={insertAfterData.index - 1}
//           onClose={setShowInsertBeforeWrap(null)}
//         />
//       )}
//       <span
//         className={classes.opacity}
//         style={{ position: 'absolute', top: -18, right: -25 }}
//       >
//         <Tooltip
//           title={<>Choose an option to run BEFORE this one</>}
//           placement="bottom"
//           type="light"
//           arrow
//         >
//           <IconButton
//             onClick={setShowInsertBeforeWrap(true)}
//             size="small"
//             variant="outlined"
//           >
//             <PresentToAllIcon />
//           </IconButton>
//         </Tooltip>
//       </span>
//     </>
//   );
// };

// export const InsertAfterButton = (props) => {
//   const { insertAfterData, onClick, mode, disabled } = props;

//   const [showInsertAfter, setShowInsertAfter] = useState(null);
//   const setShowInsertAfterWrap = (setTo) => () => setShowInsertAfter(setTo);

//   const classes = useStyles({ mode, disabled });

//   return (
//     <>
//       {showInsertAfter && (
//         <ChooseDialog
//           {...insertAfterData}
//           onClose={setShowInsertAfterWrap(null)}
//         />
//       )}
//       <span
//         className={classes.opacity}
//         style={{ position: 'absolute', bottom: -18, right: -25 }}
//       >
//         <Tooltip
//           title={<>Choose the NEXT option</>}
//           placement="bottom"
//           type="light"
//           arrow
//         >
//           <IconButton
//             onClick={onClick || setShowInsertAfterWrap(true)}
//             size="small"
//             variant="outlined"
//           >
//             <AddIcon />
//           </IconButton>
//         </Tooltip>
//       </span>
//     </>
//   );
// };

// export const ModifyButton = (props) => {
//   const { insertAfterData, onClick, mode } = props;

//   const classes = useStyles({ mode });

//   return (
//     <>
//       <span
//         className={classes.opacity}
//         style={{ position: 'absolute', top: -18, left: -25 }}
//       >
//         <Tooltip
//           title={<>Modify this option</>}
//           placement="bottom"
//           type="light"
//           arrow
//         >
//           <IconButton onClick={onClick} size="small" variant="outlined">
//             <EditIcon />
//           </IconButton>
//         </Tooltip>
//       </span>
//     </>
//   );
// };

// export const RemoveMenuItem = (props) => {
//   const { data, mode, onClose } = props;
//   const {
//     insertBefore, // bool
//     insertAfterData,
//     infoIdx,
//     currentCallflow,
//     componentData,
//     componentInfo,
//     // callflow,
//     // setCallflow,
//     // modifyPath,
//   } = data;

//   const { callflow, setCallflow, modifyPath } = insertAfterData;

//   console.log('props:', props);

//   // const { callflow, setCallflow, modifyPath, index, mode } = props;

//   // console.log('removeButton:', props);
//   const classes = useStyles({ mode });

//   const handleRemove = async () => {
//     // const data = {
//     //   type: 'MenuGreetingAndTargets',
//     // };
//     const components = getAtPath(
//       callflow,
//       `${modifyPath}.strategy.config.components`
//     );
//     const stratDataOpts = getAtPath(
//       callflow,
//       `${modifyPath}.strategy.data.opts`
//     );

//     console.log('components:', components, callflow, modifyPath);
//     // index is from the insertAfterData!! (ie, we need to -1 to remove from the correct place)
//     const comp = components.splice(insertAfterData.index - 1, 1);
//     stratDataOpts.splice(insertAfterData.index - 1, 1);

//     setAtPath(callflow, `${modifyPath}.strategy.config.components`, components);
//     setAtPath(callflow, `${modifyPath}.strategy.data.opts`, stratDataOpts);

//     setCallflow({ ...callflow }, { name: `Removed ${comp[0].type}` });
//   };

//   return (
//     <MenuItem
//       onClick={(e) => {
//         onClose();
//         handleRemove();
//       }}
//       component={'a'}
//     >
//       <ListItemIcon>
//         <DeleteIcon />
//       </ListItemIcon>
//       <ListItemText primary="Remove" />
//     </MenuItem>
//   );
// };

// export const RemoveButton = (props) => {
//   const { callflow, setCallflow, modifyPath, index, mode } = props;

//   // console.log('removeButton:', props);
//   const classes = useStyles({ mode });

//   const handleRemove = async () => {
//     // const data = {
//     //   type: 'MenuGreetingAndTargets',
//     // };
//     const components = getAtPath(
//       callflow,
//       `${modifyPath}.strategy.config.components`
//     );
//     const stratDataOpts = getAtPath(
//       callflow,
//       `${modifyPath}.strategy.data.opts`
//     );

//     // index is from the insertAfterData!! (ie, we need to -1 to remove from the correct place)
//     const comp = components.splice(index - 1, 1);
//     stratDataOpts.splice(index - 1, 1);

//     setAtPath(callflow, `${modifyPath}.strategy.config.components`, components);
//     setAtPath(callflow, `${modifyPath}.strategy.data.opts`, stratDataOpts);

//     setCallflow({ ...callflow }, { name: `Removed ${comp[0].type}` });
//   };

//   return (
//     <span
//       className={classes.opacity}
//       style={{ position: 'absolute', bottom: -18, left: -25 }}
//     >
//       <Tooltip
//         title={<>Remove this option (NOT ones below - if they exist!)</>}
//         placement="bottom"
//         type="light"
//         arrow
//       >
//         <IconButton
//           onClick={handleRemove}
//           size="small"
//           variant="outlined"
//           color="secondary"
//         >
//           <DeleteIcon />
//         </IconButton>
//       </Tooltip>
//     </span>
//   );
// };

export default MenuButton;
