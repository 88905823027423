import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { useAuthSelector } from 'app/data/auth';
import { Box, Button, Fade, Grid, Slide, Typography } from 'app/design';
import {
  Phone as PhoneIcon,
  PhoneIphone as PhoneIphoneIcon,
  Voicemail as VoicemailIcon,
} from 'app/design/icons-material';
import { useCreateCallflow } from 'app/hooks/mutations/callflow/useCreateCallflow';
import React, { useEffect, useState } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import Lottie from 'react-lottie-player';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const Step3 = ({}) => {
  const router = useHistory();
  const { handleSubmit, watch, setValue, setError, clearErrors } =
    useFormContext();
  const { isSubmitting, isValid, errors } = useFormState();
  const { auth_token } = useAuthSelector();
  const createCallflow = useCreateCallflow();

  const [inVal, setInVal] = useState(true);

  // const clearAndHandleSubmit = e => {
  //   clearErrors();
  //   handleSubmit(onSubmit)(e);
  // };

  const [showAnimation, setShowAnimation] = useState(true);
  const prevStep = watch('prevStep');
  const transitionToStep = watch('transitionToStep');
  const thisStep = 2;
  const timeout = 350;
  const handlePrev = () => {
    setValue('transitionToStep', thisStep - 1);
    setInVal(false);
    setTimeout(() => {
      // setValue('prevStep', thisStep);
      setValue('activeStep', thisStep - 1);
    }, timeout);
  };
  const handleNext = () => {
    setValue('transitionToStep', thisStep + 1);
    setInVal(false);
    setTimeout(() => {
      // setValue('prevStep', thisStep);
      setValue('activeStep', thisStep + 1);
    }, timeout);
  };
  const hideAnimation = () => {
    setShowAnimation(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setValue('prevStep', thisStep);
    }, timeout);
  }, []);

  const direction = inVal
    ? prevStep > thisStep
      ? 'right'
      : 'left'
    : transitionToStep > thisStep
    ? 'right'
    : 'left';
  // console.log('step3 direction:', direction, prevStep);

  return (
    <Slide in={inVal} timeout={timeout} direction={direction}>
      <Box>
        <Fade in={inVal} timeout={timeout}>
          <Box
            sx={{
              // position: 'relative',
              borderRadius: '8px',
              background: 'white',
              boxShadow: '0px 4px 14px 4px rgba(27, 125, 144, 0.12)',
              p: 5,
              pt: 3,
              pb: 3,
            }}
          >
            {showAnimation ? (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100vw',
                  height: '100vh',
                  transform: 'scaleY(-1)',
                }}
              >
                <Lottie
                  path="https://assets3.lottiefiles.com/private_files/lf30_5aubt2fy.json"
                  // background="transparent"
                  speed={0.3}
                  // @ts-ignore
                  delay
                  style={{
                    width: '100%',
                    height: '100%',
                    // marginTop: -50,
                    // marginLeft: -50,
                    // animationFillMode: 'forwards',
                  }}
                  hover
                  play
                  loop={false}
                  rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                  onComplete={hideAnimation}
                />
              </Box>
            ) : null}
            <Box sx={{ mb: 6, textAlign: 'center' }}>
              <Typography variant="h2" paragraph>
                Hurray!
              </Typography>
              <Typography variant="h1" paragraph>
                <PhoneNumberDisplay ptn={'+18315555555'} />
              </Typography>
              {/* <Typography variant="h3" paragraph>
                Test out your new account:
              </Typography> */}
              <Box sx={{ textAlign: 'left' }}>
                <Box
                  sx={{
                    width: 480,
                    p: 2,
                    m: '14px auto',
                    borderRadius: '8px',
                    border: '1px solid #eee',
                    '&:hover': { border: '1px solid #111' },
                  }}
                >
                  <Typography variant="h3" paragraph>
                    Test Outgoing Calls (built-in webphone)
                    <br />
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<PhoneIcon />}
                    >
                      Make a Call
                    </Button>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: 480,
                    p: 2,
                    m: '14px auto',
                    borderRadius: '8px',
                    border: '1px solid #eee',
                    '&:hover': { border: '1px solid #111' },
                  }}
                >
                  <Typography variant="h3" paragraph>
                    Test incoming Calls
                  </Typography>
                  <Typography variant="body1" paragraph>
                    With your webphone open, use your personal cell phone (or
                    another phone you have) to call your Company Number to test
                    the default routing we have setup for you (Ring your phones,
                    then go to your Company Voicemail).
                  </Typography>
                  <Typography variant="body1" paragraph>
                    - you can change this routing anytime later, to add menus,
                    schedules, ring more people, etc
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <Button color="primary" startIcon={<VoicemailIcon />}>
                      Update your Company Voicemail Greeting
                    </Button>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: 480,
                    p: 2,
                    m: '14px auto',
                    borderRadius: '8px',
                    border: '1px solid #eee',
                    '&:hover': { border: '1px solid #111' },
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{ fontWeight: 'bold' }}
                    paragraph
                  >
                    Want to make/receive calls on your mobile device?
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <Button color="primary" startIcon={<PhoneIphoneIcon />}>
                      Get the Mobile App
                    </Button>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{ mt: 2 }}
            >
              <Grid item>
                {/* <Button
                  size="small"
                  // variant="contained"
                  // color="primary"
                  onClick={handlePrev}
                  startIcon={<ChevronLeftIcon />}
                >
                  Go back
                </Button> */}
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color={'primary'}
                  component={RouterLink}
                  to={'/admin/dashboard'}
                >
                  Done, Take Me to My Account
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Box>
    </Slide>
  );
};

const buildMainSchedule = data => {
  /*
  holidays.usa_standard
  holidays.usa_auto_update
  holidays.use_extra
  holidays.extra
  */
  const holidays = {};
};

export default Step3;
