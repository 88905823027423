import {
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  IconButton,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from 'app/design';

import {
  AccessTime as AccessTimeIcon,
  DragHandle as DragHandleIcon,
  Edit as EditIcon,
  FilterList as FilterListIcon,
  Layers as LayersIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  Group as GroupIcon,
  Person as PersonIcon,
  PhoneAndroid as PhoneAndroidIcon,
} from 'app/design/icons-material';
import { CollectionListHeader } from 'app/components/CollectionListHeader';
import { useListDevicesQuery } from 'app/hooks/queries/device';
import { useListUsersQuery } from 'app/hooks/queries/user';
import { getAtPath, randomString, setAtPath } from 'app/utilities';
import {
  preEndpointsToCallflowModuleFormat,
  preEndpointsToGroupFormat,
} from 'app/utilities/utils';
// import { useTranslation } from 'react-i18next';
// import { set } from 'lodash';
import { toInteger } from 'lodash';
import React, { useCallback, useState } from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { useSelector } from 'react-redux';

// import { fetchAllVmboxes } from '../../../../../Actions/voicemails';

import { AddComponent } from '../AddComponent';

// Select users from list of users
export const CustomComponent = props => {
  const {
    modifyPath,
    strategyCallflow,
    setStrategyCallflow,
    // users,
    // devices,
    defaultToFirst,
    ownerUser,
    // fetchUsers,
    allow,
    useGroupFormat, // vs. "ringgroup" Format (requires conversion! has restrictions!)
    onSave,
    onReset,
    startEditing,
    users,
    devices,
  } = props;

  const [isEditing, setIsEditing] = useState(startEditing ? true : false);

  const [defaultRingTotalTime, setDefaultRingTotalTime] = useState(25);

  const ringGroup = getAtPath(strategyCallflow, `${modifyPath}`, {
    endpoints: useGroupFormat ? {} : [],
    preEndpoints: [], // gets built into "endpoints" depending on the useGroupFormat
    timeout: 20,
    strategy: 'simultaneous',
  });

  const selectedEndpoints = getAtPath(
    strategyCallflow,
    `${modifyPath}.preEndpoints`,
    [],
  );

  const handleSave = () => {
    const data = getAtPath(strategyCallflow, `${modifyPath}`);

    let { preEndpoints = [] } = data;

    // // dont NEED to do the math to have the correct timeout end point if going longer than parent timeout
    // // rebuild preEndpoints timeout and delay
    // preEndpoints = preEndpoints.map((endpoint, i) => {
    //   return endpoint;
    // });

    const endpoints = useGroupFormat
      ? preEndpointsToGroupFormat(preEndpoints)
      : preEndpointsToCallflowModuleFormat(preEndpoints);
    setAtPath(strategyCallflow, `${modifyPath}.endpoints`, endpoints);
    setAtPath(strategyCallflow, `${modifyPath}.preEndpoints`, preEndpoints);
    setStrategyCallflow({
      ...strategyCallflow,
    });
    console.log('Custom Endpoints Build:', endpoints);
    onSave && onSave();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const endpoints = reorder(
        selectedEndpoints,
        result.source.index,
        result.destination.index,
      );
      setAtPath(strategyCallflow, `${modifyPath}.preEndpoints`, endpoints);

      // TODO: convert according to useGroupFormat

      setStrategyCallflow({ ...strategyCallflow });

      // convert according to useGroupFormat
    },
    [selectedEndpoints, modifyPath, strategyCallflow, setStrategyCallflow],
  );

  const handleAdd = new_endpoints_arr => {
    let currentOpts = {
      delay: 0,
      timeout: 10,
    };
    let newArr = new_endpoints_arr.map(t => ({ ...t, ...currentOpts }));

    const updatedEndpoints = [...selectedEndpoints, ...newArr];

    setAtPath(strategyCallflow, `${modifyPath}.preEndpoints`, updatedEndpoints);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const updateFieldWithTargetValue = prop => e => {
    setAtPath(strategyCallflow, `${modifyPath}.timeout`, e.target.value);
    setStrategyCallflow({ ...strategyCallflow });
  };

  const [openUser, setOpenUser] = useState(null);
  const [isOpenUserDevices, setIsOpenUserDevices] = useState(false);
  const [openUserDevices, setOpenUserDevices] = useState([]);

  const handleChangeSelectUserDevices = newValue => {
    // get user's devices
    const userId = newValue?.value;
    const userDevices = devices.filter(device => {
      return device.doc.owner_id === userId;
    });
    // NOT separating out "already included" devices when displaying
    setOpenUser(users.find(u => u.id === newValue?.id));
    setOpenUserDevices(userDevices);
    setIsOpenUserDevices(true);
  };
  const handleUserDevicesClose = (deviceIdsToAdd = []) => {
    const updatedEndpoints = [...selectedEndpoints];
    // console.log('deviceIdsToAdd:', deviceIdsToAdd);
    for (let deviceId of deviceIdsToAdd) {
      // verify not already in list
      if (!selectedEndpoints.find(se => se.id === deviceId)) {
        updatedEndpoints.push({
          endpoint_type: 'device',
          id: deviceId,
          timeout: defaultRingTotalTime,
          delay: 0,
        });
      }
    }

    // TODO: redistribute automatically

    setAtPath(strategyCallflow, `${modifyPath}.preEndpoints`, updatedEndpoints);
    setStrategyCallflow({ ...strategyCallflow });
    setIsOpenUserDevices(false);
  };

  const handleSetRingTimeout = timeoutInt => {
    setAtPath(strategyCallflow, `${modifyPath}.timeout`, timeoutInt);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const handleResetToChooser = () => {
    setAtPath(strategyCallflow, `${modifyPath}.type`, 'none');
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const ringTimeout = getAtPath(strategyCallflow, `${modifyPath}.timeout`, 20);

  return (
    <div style={{ padding: 20, minWidth: isEditing ? 670 : 650 }}>
      <CollectionListHeader
        // telicon="device-fax"
        // teliconStyle={{ marginBottom: -4 }}
        icon={<FilterListIcon color={'primary'} style={{ fontSize: 20 }} />}
        title="Ring Strategy: Custom"
        subtitle="Use the sliders to setup custom ringing scenarios."
        actionButton={
          isEditing ? (
            <>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    // startIcon={<EditIcon />}
                    disableRipple
                    onClick={handleResetToChooser}
                  >
                    Switch Ring Strategy
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="error"
                    variant="outlined"
                    size="small"
                    // startIcon={<EditIcon />}
                    disableRipple
                    onClick={e => {
                      setIsEditing(false);
                      onReset();
                    }}
                  >
                    Cancel Editing
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <IconButton
              //color="info"
              size="small"
              onClick={e => setIsEditing(true)}
            >
              <EditIcon />
            </IconButton>
          )
        }
      />
      <br />
      <Divider />
      <br />

      <Grid
        container
        style={{
          minWidth: 600,
          width: !isEditing ? 600 : undefined,
        }}
        spacing={1}
      >
        <Grid item xs={12}>
          <div>
            <Typography variant="h7">
              <Grid container alignItems={'center'} spacing={1}>
                <Grid item>
                  <div style={{ paddingTop: 4 }}>
                    <AccessTimeIcon color={'primary'} />
                  </div>
                </Grid>
                <Grid item>Total Ring Time (seconds)</Grid>
              </Grid>
            </Typography>
          </div>
          <div style={{ paddingLeft: 25 }}>
            {isEditing ? (
              <TextField
                label=""
                value={ringTimeout}
                type="number"
                inputProps={{
                  min: 0,
                }}
                onChange={e =>
                  // setDefaultRingTotalTime(toInteger(e.target.value))
                  handleSetRingTimeout(toInteger(e.target.value))
                }
                InputLabelProps={{
                  shrink: true,
                }}
                margin="dense"
                variant="outlined"
                size="small"
                style={{ textAlign: 'right' }}
              />
            ) : (
              <Typography variant="body1">{ringTimeout}</Typography>
            )}
            <br />
          </div>
        </Grid>
        <Grid item xs={12} wrap={'nowrap'}>
          <Grid container>
            <Grid item xs={isEditing ? 5 : 6}>
              <Typography variant="h7">
                <Grid container alignItems={'center'} spacing={1}>
                  <Grid item>
                    <div style={{ paddingTop: 4 }}>
                      <GroupIcon color={'primary'} />
                    </div>
                  </Grid>
                  <Grid item>Group Members</Grid>
                </Grid>
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h7" wrap={'nowrap'}>
                <Grid
                  container
                  alignItems={'center'}
                  wrap={'nowrap'}
                  spacing={1}
                >
                  <Grid item>
                    <div style={{ paddingTop: 4 }}>
                      <AccessTimeIcon color={'primary'} />
                    </div>
                  </Grid>
                  <Grid item>Ring Duration (seconds)</Grid>
                </Grid>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div style={{ paddingLeft: 25 }}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {selectedEndpoints.map((endpoint, i) => (
                        <RingConfig
                          key={endpoint.id}
                          index={i}
                          endpoint={endpoint}
                          users={users}
                          devices={devices}
                          timeout={ringGroup.timeout}
                          endpointsModifyPath={`${modifyPath}.preEndpoints`}
                          modifyPath={`${modifyPath}.preEndpoints.${i}`}
                          strategyCallflow={strategyCallflow}
                          setStrategyCallflow={setStrategyCallflow}
                          provided={provided}
                          isEditing={isEditing}
                          allow={allow}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {!selectedEndpoints.length ? (
                <Typography
                  variant="body1"
                  style={{
                    fontStyle: 'italic',
                    opacity: 0.8,
                    textAlign: 'center',
                    paddingBottom: '16px',
                  }}
                >
                  No users or devices added to group
                </Typography>
              ) : (
                ''
              )}

              {isEditing ? (
                <>
                  <Grid
                    container
                    spacing={1}
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    <Grid item style={{ flex: 1 }}>
                      <AddComponent
                        label={'Add to Group'}
                        {...props}
                        onAdd={handleAdd}
                        allow={[...allow, 'tier']}
                        selectedEndpoints={selectedEndpoints}
                      />
                    </Grid>
                  </Grid>

                  <Divider />
                  <br />
                  <Grid
                    container
                    justifyContent="space-between"
                    wrap={'nowrap'}
                  >
                    <Grid item>
                      {!selectedEndpoints.length ? (
                        <Typography variant="body1">
                          No users or devices added to group
                        </Typography>
                      ) : (
                        ''
                      )}
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="success"
                        size="small"
                        disableRipple
                        onClick={e => {
                          handleSave();
                          setIsEditing(false);
                        }}
                      >
                        Save Group Changes
                      </Button>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const RingConfig = props => {
  const {
    index,
    endpoint,
    timeout,
    users,
    devices,
    endpointsModifyPath,
    modifyPath,
    strategyCallflow,
    setStrategyCallflow,
    isEditing,
    allow,
  } = props;

  const value = [endpoint.delay, endpoint.timeout + endpoint.delay];

  const selectedEndpoints = getAtPath(
    strategyCallflow,
    `${endpointsModifyPath}`,
    [],
  );

  const handleChange = useCallback(
    (e, value) => {
      // console.log('modifyPath:', modifyPath, value);
      setAtPath(strategyCallflow, `${modifyPath}.delay`, value[0]);
      setAtPath(strategyCallflow, `${modifyPath}.timeout`, value[1] - value[0]);
      setStrategyCallflow({ ...strategyCallflow });
    },
    [modifyPath, strategyCallflow, setStrategyCallflow],
  );

  const handleAddToTier = new_endpoints_arr => {
    let new_arr = [...tiers, ...new_endpoints_arr];
    setAtPath(strategyCallflow, `${modifyPath}.tiers`, new_arr);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const handleRemove = useCallback(
    (e, value) => {
      const endpointsArr = getAtPath(strategyCallflow, endpointsModifyPath);
      endpointsArr.splice(index, 1);
      setAtPath(strategyCallflow, `${endpointsModifyPath}`, endpointsArr);
      setStrategyCallflow({ ...strategyCallflow });
    },
    [index, endpointsModifyPath, strategyCallflow, setStrategyCallflow],
  );

  const handleChangeTierName = tierName => {
    setAtPath(strategyCallflow, `${modifyPath}.tier_name`, tierName);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const handleBreakTier = () => {
    let currentOpts = {
      delay: endpoint.delay,
      timeout: endpoint.timeout,
    };
    let newArr = endpoint.tiers.map(t => ({ ...t, ...currentOpts }));
    const endpointsArr = getAtPath(strategyCallflow, endpointsModifyPath);
    endpointsArr.splice.apply(endpointsArr, [index, 1].concat(newArr));
    setAtPath(strategyCallflow, `${endpointsModifyPath}`, endpointsArr);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const handleConvertToTier = () => {
    // move the endpoint_type to the list of endpoints in tiers
    const tiers = [
      JSON.parse(JSON.stringify(endpoint)), // id, endpoint_type
    ];
    // set a default tier name

    setAtPath(strategyCallflow, `${modifyPath}.tier_name`, 'Tier');
    setAtPath(strategyCallflow, `${modifyPath}.tiers`, tiers);
    setAtPath(strategyCallflow, `${modifyPath}.endpoint_type`, 'tier');
    setAtPath(strategyCallflow, `${modifyPath}.id`, randomString(5));
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const user = users.find(d => d.id === endpoint.id);
  const device = devices.find(d => d.id === endpoint.id);

  const isTier = endpoint.endpoint_type === 'tier' ? true : false;
  const tierName = endpoint.tier_name;

  const tiers = endpoint.tiers || [];

  return (
    <Draggable draggableId={endpoint.id} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Grid
            container
            spacing={1}
            style={{ marginBottom: isTier ? 0 : 5, marginTop: isEditing && 5 }}
            alignItems={'center'}
          >
            <Grid item>
              <Grid container wrap="nowrap">
                <Grid
                  item
                  style={{
                    width: 34,
                    display: isEditing ? undefined : 'none',
                  }}
                >
                  <Button
                    {...provided.dragHandleProps}
                    style={{ minWidth: 0, padding: 0 }}
                  >
                    <DragHandleIcon style={{ color: 'gray' }} />
                  </Button>
                </Grid>
                <Grid item style={{ width: isEditing ? 200 : 150 }}>
                  <div
                    style={{
                      // marginTop: 2,
                      width: '100%',
                      display: 'block',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {endpoint.endpoint_type === 'user' ? (
                      <Typography variant={'body1'} wrap={'nowrap'}>
                        <Grid
                          container
                          spacing={1}
                          alignItems={'center'}
                          wrap={'nowrap'}
                        >
                          <Grid item style={{ fontSize: 13 }}>
                            <div style={{ paddingTop: 5 }}>
                              <PersonIcon color={'primary'} />
                            </div>
                          </Grid>
                          <Grid
                            item
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {user?.extra?.fullName ?? 'Unknown User'}
                          </Grid>
                        </Grid>
                      </Typography>
                    ) : (
                      // <>{user?.extra?.fullName ?? 'Unknown User'}</>
                      ''
                    )}
                    {endpoint.endpoint_type === 'device' ? (
                      <Typography variant={'body1'}>
                        <Grid
                          container
                          spacing={1}
                          alignItems={'center'}
                          wrap={'nowrap'}
                        >
                          <Grid item style={{ fontSize: 13 }}>
                            <div style={{ paddingTop: 5 }}>
                              <PhoneAndroidIcon color={'primary'} />
                            </div>
                          </Grid>
                          <Grid
                            item
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {device?.doc?.name}
                          </Grid>
                        </Grid>
                      </Typography>
                    ) : (
                      // <>{device?.doc?.name}</>
                      ''
                    )}
                    {endpoint.endpoint_type === 'tier' ? (
                      <>
                        {isEditing ? (
                          <TextField
                            label="Tier Name"
                            value={endpoint.tier_name ?? ''}
                            onChange={e => handleChangeTierName(e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            margin="dense"
                            variant="outlined"
                            size="small"
                          />
                        ) : (
                          <Typography
                            variant={'body1'}
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Grid
                              container
                              spacing={1}
                              alignItems={'center'}
                              wrap={'nowrap'}
                            >
                              <Grid item style={{ fontSize: 15 }}>
                                <div style={{ paddingTop: 5 }}>
                                  <LayersIcon />
                                </div>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {endpoint.tier_name}
                              </Grid>
                              {!isEditing && (
                                <Grid item>
                                  <Tooltip
                                    placement="right"
                                    arrow
                                    title={
                                      <>
                                        {endpoint.tiers.map(
                                          (tierEndpoint, tierEndpointIdx) => (
                                            <TierComponent
                                              users={users}
                                              devices={devices}
                                              key={tierEndpoint.id}
                                              {...props}
                                              tierEndpointIdx={tierEndpointIdx}
                                              tierEndpoint={tierEndpoint}
                                            />
                                          ),
                                        )}
                                      </>
                                    }
                                  >
                                    <div style={{ marginTop: -5 }}>
                                      <Chip label={endpoint.tiers?.length} />
                                    </div>
                                  </Tooltip>
                                </Grid>
                              )}
                            </Grid>
                          </Typography>
                          // <div>
                          //   <Typography variant="body2">
                          //     {endpoint.tier_name}
                          //   </Typography>
                          // </div>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <div
                style={{
                  marginTop: -4,
                  padding: '0 8px',
                  // disabled: true,
                }}
              >
                <Slider
                  color={'primary'}
                  value={value}
                  onChange={handleChange}
                  min={0}
                  max={timeout}
                  valueLabelDisplay="on" // "auto"
                  disabled={!isEditing}
                />
              </div>
            </Grid>

            {isEditing && (
              <>
                <Grid item style={{ flexGrow: 0.1 }} />

                <Grid item style={{ width: 34, textAlign: 'left' }}>
                  {/* @ts-ignore */}
                  <IconButton
                    onClick={handleRemove}
                    // color="secondary"
                    // variant="outlined"
                    style={{ minWidth: 0, padding: 0, fontSize: 20 }}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Grid>
                <Grid item style={{ width: 100 }}>
                  {isTier ? (
                    <Button
                      onClick={handleBreakTier}
                      color="primary"
                      variant="outlined"
                      size="small"
                      style={{ minWidth: 0 }}
                    >
                      Break Apart Tier
                    </Button>
                  ) : (
                    <Button
                      onClick={handleConvertToTier}
                      color="primary"
                      variant="outlined"
                      size="small"
                      style={{ minWidth: 0 }}
                    >
                      Convert to Tier
                    </Button>
                  )}
                </Grid>
              </>
            )}
          </Grid>
          {endpoint.endpoint_type === 'tier'
            ? isEditing && (
                <Grid container style={{ marginLeft: 50 }}>
                  <Grid item xs={4}>
                    <Typography
                      variant="h7"
                      // style={{ textDecoration: 'underline' }}
                    >
                      Tier Users/Devices:
                    </Typography>
                    {endpoint.tiers.map((tierEndpoint, tierEndpointIdx) => (
                      <TierComponent
                        users={users}
                        devices={devices}
                        key={tierEndpoint.id}
                        {...props}
                        tierEndpointIdx={tierEndpointIdx}
                        tierEndpoint={tierEndpoint}
                      />
                    ))}
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    style={{
                      // borderLeft: '1px solid rgba(0,0,0,0.3)',
                      paddingLeft: 8,
                      height: '100%',
                    }}
                  >
                    <div style={{ width: 300 }}>
                      <AddComponent
                        {...props}
                        label={'Add to Tier:'}
                        selectedEndpoints={selectedEndpoints}
                        modifyPath={props.parentModifyPath}
                        onAdd={handleAddToTier}
                      />
                    </div>
                  </Grid>
                </Grid>
              )
            : ''}
          {isEditing && <Divider />}
        </div>
      )}
    </Draggable>
  );
};

const TierComponent = props => {
  const {
    strategyCallflow,
    setStrategyCallflow,
    modifyPath,
    tierEndpoint,
    tierEndpointIdx,
    isEditing,
    users,
    devices,
  } = props;

  const user = users.find(d => d.id === tierEndpoint.id);
  const device = devices.find(d => d.id === tierEndpoint.id);

  const handleRemove = () => {
    const tiers = getAtPath(strategyCallflow, `${modifyPath}.tiers`);
    tiers.splice(tierEndpointIdx, 1);
    setAtPath(strategyCallflow, `${modifyPath}.tiers`, tiers);
    setStrategyCallflow({ ...strategyCallflow });
  };

  return (
    <div style={{ width: '100%' }}>
      <Grid container spacing={1} wrap={'nowrap'} style={{ width: '100%' }}>
        <Grid item style={{ maxWidth: 175 }}>
          {tierEndpoint.endpoint_type === 'user' ? (
            <Typography variant={'caption'} wrap={'nowrap'}>
              <Grid container spacing={1} alignItems={'center'} wrap={'nowrap'}>
                <Grid item style={{ fontSize: 10 }}>
                  <div style={{ paddingTop: 2 }}>
                    <PersonIcon color={'primary'} />
                  </div>
                </Grid>
                <Grid
                  item
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {user?.extra?.fullName ?? 'Unknown User'}
                </Grid>
              </Grid>
            </Typography>
          ) : (
            // <>{user?.extra?.fullName ?? 'Unknown User'}</>
            ''
          )}
          {tierEndpoint.endpoint_type === 'device' ? (
            <Typography variant={'caption'} wrap={'nowrap'}>
              <Grid container spacing={1} alignItems={'center'} wrap={'nowrap'}>
                <Grid item style={{ fontSize: 10 }}>
                  <div style={{ paddingTop: 2 }}>
                    <PhoneAndroidIcon color={'primary'} />
                  </div>
                </Grid>
                <Grid
                  item
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {device?.doc?.name}
                </Grid>
              </Grid>
            </Typography>
          ) : (
            // <>{device?.doc?.name}</>
            ''
          )}
        </Grid>
        {isEditing && (
          <Grid item style={{ width: 34, textAlign: 'left' }}>
            <IconButton
              onClick={handleRemove}
              // color="secondary"
              // variant="outlined"
              style={{ minWidth: 0, padding: 0, fontSize: 15 }}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const DividerWithText = ({ children }) => {
  return (
    <Grid container alignItems="center" wrap="nowrap">
      <Divider style={{ flex: '1 1 auto' }} />
      <span style={{ padding: '0 10px' }}>{children}</span>
      <Divider style={{ flex: '1 1 auto' }} />
    </Grid>
  );
};

// const mapStateToProps = (state) => ({
//   users: state.users.users,
//   devices: state.devices.devices,
// });

export default CustomComponent; //connect(mapStateToProps, { fetchUsers })(RingGroup);
