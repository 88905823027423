import React, { useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  InfoTooltip,
  Link,
  Tooltip,
  Typography,
  Portal,
} from 'app/design';
import {
  ArrowCircleDown as ArrowCircleDownIcon,
  ArrowCircleUp as ArrowCircleUpIcon,
} from 'app/design/icons-material';
import { useFindInPage } from 'app/components/FindInPage';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { useListCallflowsQuery } from 'app/hooks/queries/callflow';
import { useCallRoutingDialog } from 'app/components/CallRoutingDialog';
import FixCallHandlingDialog from 'app/pages/admin/Dashboard/components/CallHandlingFixDialogGroup/FixCallHandlingDialog';
import { parseCallflowNumbers, useToggleReducer } from 'app/utilities';
import { Account } from 'types/account';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { IvrQuickDisplay } from 'app/components/IvrBuilder';

interface CallHandlingCardProps {
  account: Account;
  setDialogShown: any;
  onComplete: () => void;
}

const CallHandlingCard = ({
  account,
  setDialogShown,
  onComplete,
}: CallHandlingCardProps) => {
  const {
    toggleOpen: toggleOpenCallRoutingDialog,
    CallRoutingDialog,
    CallRoutingDialogProps,
  } = useCallRoutingDialog({});

  const [showFixDialog, toggleFixDialog] = useToggleReducer(false);

  const {
    data: callflowsPageResponse,
    isLoading: callflowsAreLoading,
    isFetching: callflowsFetching,
    refetch,
  } = useListCallflowsQuery({
    filters: {
      raw: [
        {
          variables: {
            docFilters: [
              ['owner_id', '=', account.id],
              ['type', '=', 'main'],
            ],
          },
        },
      ],
    },
  });

  const mainCallflow = callflowsPageResponse?.callflows?.length
    ? callflowsPageResponse.callflows[0]
    : null;

  const {
    ptns: accountPtns,
    ptnsParsed: accountPtnsParsed,
    extensions: accountExtensions,
  } = parseCallflowNumbers(mainCallflow?.doc?.numbers);

  useEffect(() => {
    setDialogShown(CallRoutingDialogProps.open);
  }, [CallRoutingDialogProps.open]);

  return (
    <>
      <CallRoutingDialog
        {...CallRoutingDialogProps}
        name_type="account"
        owner_type="account"
        type="main"
        resourceId={account.id}
        defaultNumbers={[]}
        callflow={mainCallflow}
        onComplete={() => {
          toggleOpenCallRoutingDialog();
          refetch();
        }}
      />
      {/* @ts-ignore */}

      <Typography variant="h2">
        <ArrowCircleDownIcon sx={{}} /> Incoming Calls
      </Typography>

      <Typography variant="number1">
        {callflowsAreLoading ? (
          <Typography variant="h5">Loading calling</Typography>
        ) : (
          <>
            {accountPtns.length ? (
              <div>
                <Typography variant="number1">
                  {/* @ts-ignore */}
                  <PhoneNumberDisplay width="auto" ptn={accountPtns[0]} />
                  &nbsp;
                  {accountPtns?.length > 1 ? (
                    <Tooltip
                      title={
                        <>
                          <Typography variant="h4">
                            All Phone Numbers:
                          </Typography>
                          <Divider />
                          {accountPtns.map(ptn => (
                            <div key={ptn}>
                              <Typography variant="number1">
                                {/* @ts-ignore */}
                                <PhoneNumberDisplay width="auto" ptn={ptn} />
                              </Typography>
                            </div>
                          ))}
                        </>
                      }
                    >
                      <Chip
                        label={`+${accountPtns.length - 1}`}
                        color="primary"
                        size="small"
                      />
                    </Tooltip>
                  ) : null}
                </Typography>
              </div>
            ) : (
              <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                No Main Account Phone Number
              </Typography>
            )}
            {mainCallflow ? (
              <>
                <Link
                  // component={RouterLink}
                  variant="body2"
                  onClick={toggleOpenCallRoutingDialog}
                  // to={`/admin/dashboard/call_handling`}
                  underline="hover"
                >
                  Modify
                </Link>
              </>
            ) : (
              <>
                {showFixDialog ? (
                  <FixCallHandlingDialog
                    resourceId={account.id}
                    type={'account'}
                    onCancel={toggleFixDialog}
                  />
                ) : null}
                <Link
                  variant="body2"
                  color="warning.main"
                  // onClick={toggleFixDialog}
                  onClick={toggleOpenCallRoutingDialog}
                  // href={`/admin/ivr/edit/${mainCallflow?.id}`} // TODO: provide fix
                  underline="hover"
                >
                  Setup Incoming Call Handling
                </Link>
              </>
            )}
          </>
        )}
      </Typography>

      {mainCallflow ? (
        <Box sx={{ position: 'relative', width: '300px', height: '300px' }}>
          <IvrQuickDisplay
            // id={JSON.stringify(callflow)}
            // key={mainCallflowKey} // causes a rerender when changed!
            callflow={mainCallflow?.doc}
          />
        </Box>
      ) : null}
    </>
  );
};

export default CallHandlingCard;
