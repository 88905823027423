import { EmptyTableCell } from 'app/components/EmptyTableCell';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { ContactDisplay } from 'app/components/Webphone/UI/ContactDisplay';
import { useWebphoneSelector, useWebphoneSlice } from 'app/data/webphone';
import { useLookupContact } from 'app/data/webphone/webphoneSlice';
import React from 'react';
import { CdrInteraction } from 'types/callDetailRecord';

interface ToCellProps {
  interaction: CdrInteraction;
}

const ToCell = ({ interaction }: ToCellProps) => {
  const { actions } = useWebphoneSlice();

  const { numberContactDictionary } = useWebphoneSelector();
  const { name, number, userId } = interaction.doc.to;
  const [contact] = useLookupContact(number);

  // TODO: handle
  if (!number) {
    return <EmptyTableCell label={'unknown'} />;
  }

  return (
    <div>
      {contact ? (
        <ContactDisplay contact={contact} />
      ) : name !== number ? (
        name
      ) : (
        <EmptyTableCell label={'no caller ID'} />
      )}
      {/* @ts-ignore */}
      <PhoneNumberDisplay ptn={number} allowExtension />
      {/*{userId ? (*/}
      {/*  <div>*/}
      {/*    <OwnerLinkChip id={userId} size="xsmall" />*/}
      {/*  </div>*/}
      {/*) : null}*/}
    </div>
  );
};

export default ToCell;
