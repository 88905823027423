import { cloneDeep } from 'lodash';
import React, { useState, useContext, useRef, memo, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from 'app/design';

import {
  Phone as PhoneIcon,
  Add as AddIcon,
  TransferWithinAStation as TransferWithinAStationIcon,
  Dialpad as DialpadIcon,
} from 'app/design/icons-material';

import { useSelector } from 'react-redux';
import { getAtPath, setAtPath } from 'app/utilities';

import { IvrMenuEventEmitterContext } from '../../..';
import { useListExtensionsQuery } from '../../../../../hooks/queries/extension';

// import { AutocompleteExtension } from '../../../../AutocompleteExtension';
import { NodeEditDialog } from '../../../Dialogs';

import { Box as IvrBox } from '../common/Box';
import { MenuButton } from '../common/MenuButton';
import { InTemplate } from '../common/InTemplate';
import { NodeInfoDisplay } from '../common/NodeInfoDisplay';
import { VariablesEditor } from '../common/VariablesEditor';

import {
  InsertAfterButton,
  InsertBeforeButton,
  RemoveButton,
  ModifyButton,
} from '../Insert';

import { useHoverDirty } from 'react-use';
import { ModifyNodeDialogVmbox } from '../Voicemail/ModifyNodeDialogVmbox';
import { ModifyNodeDialogTransfer } from './ModifyNodeDialogTransfer';

const TransferNode = memo(({ data }) => {
  const {
    skipEditing,
    insertBefore, // bool
    insertAfterData,
    moduleItem,
    callflow,
    setCallflow,
    modifyPath,
    templateParent,
  } = data;

  const [showModifyDialog, setShowModifyDialog] = useState(null);
  const setShowModifyDialogWrap = setTo => () => setShowModifyDialog(setTo);

  // const callflows = useSelector(state => state.lists.callflows.list);
  // const extCallflow = callflows.find(cf =>
  //   cf.doc.numbers.includes(moduleItem.target),
  // );
  const extensionId = moduleItem?.data?.target;
  const {
    data: extensionsPage,
    isLoading: extensionIsLoading,
    error: extensionError,
    refetch: refetchExtension,
  } = useListExtensionsQuery({
    filters: {
      context: {
        numbersQuoted: [extensionId],
      },
    },
    options: {
      enabled: !!extensionId,
    },
  });
  const extension = extensionsPage?.extensions?.[0];
  const extCallflow = extension?.callflow;

  const [showVariables, setShowVariables] = useState(null);
  const setShowVariablesWrap = setTo => () => setShowVariables(setTo);

  const boxRef = useRef(null);
  const isHovering = useHoverDirty(boxRef);

  let possibleVariables = [
    {
      id: 'extension',
      name: 'Extension',
      read: {
        type: 'extension',
      },
      write: {
        type: 'set',
        data: {
          moduleItemModifyPath: 'data.target',
        },
      },
    },
  ];

  // action for onCreate
  const ee = useContext(IvrMenuEventEmitterContext);
  const onNodeCreated = data => {
    if (data.optsPath === modifyPath) {
      setShowModifyDialog(true);
    }
  };
  useEffect(() => {
    ee.on('node-created', onNodeCreated);
    return () => {
      ee.removeListener('node-created', onNodeCreated);
    };
  }, []);

  return (
    <>
      <IvrBox pref={boxRef} borderColor="#E65100" data={data}>
        {showModifyDialog && (
          <ModifyNodeDialogTransfer
            {...data}
            extensionId={moduleItem?.data?.target}
            onClose={setShowModifyDialogWrap(null)}
          />
        )}
        {showVariables && (
          <VariablesEditor // Dialog
            {...data}
            possibleVariables={possibleVariables}
            onClose={setShowVariablesWrap(null)}
          />
        )}
        <Handle
          type="target"
          position="top"
          style={{ background: '#555', visibility: 'hidden' }}
        />
        <div>
          <NodeInfoDisplay
            type={'Transfer'}
            icon={<TransferWithinAStationIcon />}
            title={
              moduleItem.data.target ? (
                `${moduleItem.data.target}: ${
                  extCallflow?.doc?.name ?? 'Unnamed Callflow'
                }`
              ) : skipEditing ? (
                'None Selected'
              ) : (
                <Link onClick={setShowModifyDialogWrap(true)}>
                  Select Transfer
                </Link>
              )
            }
          />
          {skipEditing ? null : (
            <>
              <MenuButton
                data={data}
                mode={isHovering ? 'normal' : 'ignore'}
                menuItems={[
                  {
                    type: 'modify',
                    props: { onClick: setShowModifyDialogWrap(true) },
                  },
                  // 'add',
                  'add-before',
                  'move',
                  'duplicate',
                  'copy',
                  process.env.NODE_ENV === 'development'
                    ? 'create_template'
                    : null,
                  templateParent ||
                  callflow.type == 'template' ||
                  process.env.NODE_ENV === 'development'
                    ? {
                        type: 'variables',
                        props: { onClick: setShowVariablesWrap(true) },
                      }
                    : null,
                  'remove',
                ]}
              />
            </>
          )}
        </div>
        {templateParent ? <InTemplate template={templateParent} /> : ''}
      </IvrBox>
    </>
  );
});

export const ChooseDialog = props => {
  const { callflow, setCallflow, modifyPath, index, moduleItem, onClose } =
    props;

  const [selectedValue, setSelectedValue] = useState(() =>
    cloneDeep(moduleItem.data.target),
  );

  const handleChangeSelect = async selected => {
    setSelectedValue(selected?.value);
    // console.log('selected:', selected);
    // moduleItem.data.target = selected?.value;
    // setAtPath(callflow, modifyPath, moduleItem);
    // setCallflow({ ...callflow });
    // onClose();
  };

  const handleSave = () => {
    moduleItem.data.target = selectedValue;
    setAtPath(callflow, modifyPath, moduleItem);
    setCallflow({ ...callflow });
    onClose();
  };

  const [initialState] = useState({
    target: moduleItem.data.target,
  });

  const saveLabel = !selectedValue
    ? 'Leave Empty'
    : !initialState.target
    ? 'Add'
    : initialState.target === selectedValue
    ? 'Done'
    : 'Update';

  return (
    <NodeEditDialog
      title={'Select Transfer Target'}
      maxWidth={'sm'}
      onClose={onClose}
      onCancel={onClose}
      onSave={handleSave}
      saveLabel={saveLabel}
      hideCancel={saveLabel === 'Done'}
      // isLoading={isSaving}
      // loadingLabel={'Saving Menu Settings...'}
      // error={error}
    >
      {/* <AutocompleteExtension
        label=""
        placeholder={'Search transfer target by name or extension...'}
        // placeholder="Search for Extension or Name"
        defaultValue={selectedValue}
        onChange={handleChangeSelect}
      /> */}
      Autocomplete extension...
    </NodeEditDialog>
  );
};

export default TransferNode;
