import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from 'app/design';
import { useUpdateDevicePartial } from 'app/hooks/mutations/device';
import { useDeviceQuery } from 'app/hooks/queries/device';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { PhoneNumber } from 'types/phoneNumber';
import { useImmer } from 'use-immer';

// interface for useForm. Not required as it can be inferred but
// - prevents typos and provides a layer of documentation and prevents
// - incorrect registers
interface DeviceDetailsFields {
  name: string;
}

// interface declaring which props are required/allowed
interface DeviceDetailsDialogProps {
  deviceId: string;
  onCancel: () => void;
  onComplete: () => void;
}

const DetailsDialogDevice = ({
  deviceId,
  onCancel,
  onComplete,
}: DeviceDetailsDialogProps) => {
  const { data: device, isLoading: deviceIsLoading } = useDeviceQuery(
    deviceId,
    {
      onSuccess: device => {
        setFormDefaults(device.doc);
      },
    },
  );

  const updateDevice = useUpdateDevicePartial();
  const [formDefaults, setFormDefaults] = useImmer<
    DeviceDetailsFields | undefined
  >(device?.doc);

  const handleSubmit = async (deviceForm: DeviceDetailsFields) => {
    // update form defaults in case of error
    setFormDefaults(deviceForm);

    const updateDevicePromise = updateDevice.mutateAsync(
      {
        id: deviceId,
        ...deviceForm,
      },
      {
        onSuccess: () => {
          onComplete();
        },
      },
    );

    // resolve promise with toast notifications
    toast.promise(updateDevicePromise, {
      pending: 'Updating device details...',
      error: 'Failed to update device details.',
      success: 'Device details updated!',
    });
  };

  return (
    <Dialog open={true} fullWidth maxWidth={'xs'}>
      {deviceIsLoading ? (
        <DialogInProgress title={'Loading device details info...'} />
      ) : updateDevice.isLoading ? (
        <DialogInProgress title={'Updating Device details...'} />
      ) : (
        <>
          <DialogTitle>Edit Device Details</DialogTitle>
          <Divider />
          {device ? (
            <DeviceDetailsForm
              defaultFormValues={formDefaults}
              onCancel={onCancel}
              onSubmit={handleSubmit}
              errorMessage={
                updateDevice.error
                  ? `${updateDevice.error.message} ${
                      updateDevice.error.response
                        ? `: ${updateDevice.error.response?.statusText}`
                        : ''
                    }`
                  : undefined
              }
            />
          ) : (
            <>
              <DialogContent>
                <Typography color={'error'}>Invalid Device ID.</Typography>
              </DialogContent>
              <Divider />
              <DialogActions sx={{ justifyContent: 'left' }}>
                <Button variant={'outlined'} color={'error'} onClick={onCancel}>
                  Cancel
                </Button>
              </DialogActions>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};

interface DeviceDetailsFormProps {
  defaultFormValues?: DeviceDetailsFields;
  initialExternalNumber?: PhoneNumber;
  onCancel: () => void;
  onSubmit: (formValues: DeviceDetailsFields) => void;
  errorMessage?: string;
  startDirty?: boolean;
}

const DeviceDetailsForm = ({
  defaultFormValues,
  onCancel,
  onSubmit,
  errorMessage,
  startDirty = false,
}: DeviceDetailsFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useForm<DeviceDetailsFields>({
    defaultValues: defaultFormValues,
  });
  const formWatch = watch();

  return (
    <>
      <DialogContent>
        {/* device name */}
        <TextField
          label={'Name'}
          {...register('name')}
          error={!!formErrors.name}
          helperText={formErrors.name?.message?.replace('"name"', 'Name')}
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button variant={'outlined'} color={'error'} onClick={onCancel}>
          Cancel
        </Button>
        {errorMessage ? (
          <Typography color={'error'}>{errorMessage}</Typography>
        ) : null}
        <Button
          variant={'outlined'}
          color={'success'}
          disabled={startDirty ? !startDirty : !isDirty}
          onClick={handleSubmit(onSubmit)}
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default DetailsDialogDevice;
