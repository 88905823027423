import { useMutation } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { DeviceCreateDoc } from 'types/device';

export const useCreateDevice = () => {
  const authState = useAuthSelector();
  return useMutation((doc: DeviceCreateDoc) =>
    sdk.device.mutate.create(doc, authState?.auth_token),
  );
};
