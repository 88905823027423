import React, { useState, useContext, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Paper,
  TooltipLight,
  Icon,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ButtonDropdownMenu,
} from 'app/design';
import {
  Delete as DeleteIcon,
  Eject as EjectIcon,
  PresentToAll as PresentToAllIcon,
  FileCopy as FileCopyIcon,
  Phone as PhoneIcon,
  Add as AddIcon,
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
  ControlPointDuplicate as ControlPointDuplicateIcon,
} from 'app/design/icons-material';

// import useStyles from './styles';

// import { getAtPath, setAtPath } from 'app/utilities';

import { IvrMenuEventEmitterContext, useSharedFlow } from '../../../../..';

// import { ToastQuick } from '@Util/toast';
import copy from 'copy-to-clipboard';

export const MenuItem_Copy = props => {
  const { data, mode, onClose } = props;
  const {
    insertBefore, // bool
    insertAfterData,
    infoIdx,
    callflow: rootCallflow,
    currentCallflow,
    componentData,
    componentInfo,
    templateParent,
    // callflow,
    // setCallflow,
    modifyPath,
  } = data;

  // const { callflow, setCallflow, modifyPath } = insertAfterData;

  const [sharedFlow, setSharedFlow] = useSharedFlow();

  const ee = useContext(IvrMenuEventEmitterContext);
  const handleCopy = () => {
    // alert('duplicate to');
    // if (rootCallflow.type != 'template' && templateParent) {
    //   alert(
    //     'Note that adding to this template outside of a Template Endpoint can result in losing information when the Template is re-applied!'
    //   );
    // }
    const componentInfo =
      currentCallflow.strategy.config.components.slice(infoIdx);
    const componentData = currentCallflow.strategy.data.opts.slice(infoIdx);

    // console.log('ARR:', componentData);
    const str = JSON.stringify({
      type: 'cio.v1',
      data: {
        componentInfo,
        componentData,
      },
    });
    copy(str);
    // ToastQuick({ title: 'Copied to clipboard', type: 'success' });
  };

  // return null;
  return (
    <MenuItem
      onClick={e => {
        onClose();
        handleCopy();
      }}
      component={'a'}
    >
      <ListItemIcon>
        <ControlPointDuplicateIcon />
      </ListItemIcon>
      <ListItemText primary="Copy" />
    </MenuItem>
  );
};

export default MenuItem_Copy;
