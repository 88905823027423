import {
  ArrowRightAlt as ArrowRightAltIcon,
  HelpOutline as HelpOutlineIcon,
  Person as PersonIcon,
  Public as PublicIcon,
  Videocam as VideocamIcon,
  Warning as WarningIcon,
} from 'app/design/icons-material';
import { InteractionType } from 'app/reports/components/ListCdrInteractions/utils';
import React from 'react';
import { CdrInteraction } from 'types/callDetailRecord';

interface InteractionTypeCell {
  interaction: CdrInteraction;
}

const InteractionTypeCell = ({ interaction }: InteractionTypeCell) => {
  const type = interaction.doc.type;

  let icons;
  let name;

  switch (type) {
    case InteractionType.InternalToExternal:
      icons = [<PersonIcon />, <PublicIcon />];
      name = 'Outbound';
      break;
    case InteractionType.ExternalToInternal:
      icons = [<PublicIcon />, <PersonIcon />];
      name = 'Inbound';
      break;
    default:
    case InteractionType.Unknown:
      icons = [<HelpOutlineIcon />];
      name = 'Unknown';
      break;
    // case 'internal-to-internal':
    //   icons = [<PersonIcon />, <PersonIcon />];
    //   name = 'Internal';
    //   break;
    // case 'internal-to-conference':
    //   icons = [<PersonIcon />, <VideocamIcon />];
    //   name = 'Conference';
    //   break;
    // case 'internal-to-failure':
    //   icons = [<PersonIcon />, <WarningIcon />];
    //   name = 'Failed';
    //   break;
  }

  return (
    <div style={{ display: 'grid', placeItems: 'center' }}>
      <div>
        <span>{icons[0]}</span>
        {icons.length > 1 ? (
          <>
            <span>
              <ArrowRightAltIcon />
            </span>
            <span>{icons[1]}</span>
          </>
        ) : null}
        <div style={{ textAlign: 'center', fontSize: 12 }}>{name}</div>
      </div>
    </div>
  );
};

export default InteractionTypeCell;
