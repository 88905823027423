import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InfoTooltip,
  Link,
  TextField,
  Typography,
} from 'app/design';
import { Clear as ClearIcon } from 'app/design/icons-material';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { QuickFinderExtensionDialog } from 'app/components/QuickFinderExtensionDialog';
import { QuickFinderPhoneNumberDialog } from 'app/components/QuickFinderPhoneNumberDialog';
import { useUpdateDevicePartial } from 'app/hooks/mutations/device';
import { useUpdatePhoneNumberPartial } from 'app/hooks/mutations/phoneNumbers';
import { useAccountQuery } from 'app/hooks/queries/account';
import { useDeviceQuery } from 'app/hooks/queries/device';
import { usePhoneNumberQuery } from 'app/hooks/queries/phoneNumber';
import { useUserQuery } from 'app/hooks/queries/user';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Account } from 'types/account';
import { Device } from 'types/device';
import { PhoneNumber } from 'types/phoneNumber';
import { User } from 'types/user';
import { useImmer } from 'use-immer';

// interface for useForm. Not required as it can be inferred but
// - prevents typos and provides a layer of documentation and prevents
// - incorrect registers
interface DeviceCallerIdFormFields {
  internalName?: string;
  internalNumber?: string | null;
  externalName?: string;
  externalNumber?: string | null;
  emergencyNumber?: string | null;
  updateExternalName: boolean;
}

// interface declaring which props are required/allowed
interface DeviceCallerIdDialogProps {
  deviceId: string;
  onCancel: () => void;
  onComplete: () => void;
}

const CallerIdDialogDevice = ({
  deviceId,
  onCancel,
  onComplete,
}: DeviceCallerIdDialogProps) => {
  const { data: device, isLoading: deviceIsLoading } = useDeviceQuery(
    deviceId,
    {
      onSuccess: device => {
        setFormDefaults({
          internalName: device?.doc.caller_id?.internal?.name,
          internalNumber: device?.doc.caller_id?.internal?.number,
          externalName: externalNumber?.doc.cnam?.display_name,
          externalNumber: externalNumber?.doc.id,
          emergencyNumber: device?.doc.caller_id?.emergency?.number,
          updateExternalName: false,
        });
      },
    },
  );
  const { data: externalNumber, isLoading: externalNumberIsLoading } =
    usePhoneNumberQuery({ id: device?.doc.caller_id?.external?.number });

  // account and user are used for inheritance of CID
  const { data: account, isLoading: accountIsLoading } = useAccountQuery();
  const { data: user, isLoading: userIsLoading } = useUserQuery(
    device?.doc.owner_id,
  );

  const updateDevice = useUpdateDevicePartial();
  const updatePhoneNumber = useUpdatePhoneNumberPartial();
  const [formDefaults, setFormDefaults] = useImmer<DeviceCallerIdFormFields>({
    internalName: device?.doc.caller_id?.internal?.name,
    internalNumber: device?.doc.caller_id?.internal?.number,
    externalName: externalNumber?.doc.cnam?.display_name,
    externalNumber: externalNumber?.doc.id,
    emergencyNumber: device?.doc.caller_id?.emergency?.number,
    updateExternalName: false,
  });

  const handleSubmit = async (deviceForm: DeviceCallerIdFormFields) => {
    // update form defaults in case of error
    setFormDefaults(deviceForm);

    const {
      externalNumber,
      externalName,
      emergencyNumber,
      internalNumber,
      internalName,
      updateExternalName,
    } = deviceForm;

    const updateDevicePromise = updateDevice.mutateAsync({
      id: device?.id!,
      caller_id: {
        internal: {
          name: internalName,
          number: internalNumber,
        },
        external: {
          number: externalNumber,
        },
        emergency: {
          number: emergencyNumber,
        },
      },
    });

    let promises = [updateDevicePromise];

    // need to update phone number external label?
    if (externalNumber && updateExternalName) {
      const updatePhoneNumberPromise = updatePhoneNumber.mutateAsync({
        id: externalNumber,
        cnam: {
          display_name: externalName || undefined,
        },
      });
      promises.push(updatePhoneNumberPromise);
    }

    // resolve promise with toast notifications
    const results = await toast.promise(Promise.all(promises), {
      pending: 'Updating device caller ID...',
      error: 'Failed to update device caller ID.',
      success: 'Device caller ID updated!',
    });

    const errorResponse = results.find(result => result.status !== 'success');

    if (errorResponse) {
      console.log('error', errorResponse);
    } else {
      onComplete();
    }
  };

  return (
    <Dialog open={true} fullWidth maxWidth={'sm'}>
      {deviceIsLoading ||
      userIsLoading ||
      accountIsLoading ||
      externalNumberIsLoading ? (
        <DialogInProgress title={'Loading device caller ID info...'} />
      ) : updateDevice.isLoading || updatePhoneNumber.isLoading ? (
        <DialogInProgress title={'Updating Device Caller ID...'} />
      ) : (
        <>
          <DialogTitle>Edit Device Caller ID</DialogTitle>
          <Divider />
          {device ? (
            <DeviceCallerIdForm
              defaultFormValues={formDefaults}
              initialExternalNumber={externalNumber}
              onCancel={onCancel}
              onSubmit={handleSubmit}
              account={account}
              user={user}
              errorMessage={
                updateDevice.error
                  ? `${updateDevice.error.message} ${
                      updateDevice.error.response
                        ? `: ${updateDevice.error.response?.statusText}`
                        : ''
                    }`
                  : updatePhoneNumber.error
                  ? `${updatePhoneNumber.error.message} ${
                      updatePhoneNumber.error.response
                        ? `: ${updatePhoneNumber.error.response?.statusText}`
                        : ''
                    }`
                  : undefined
              }
            />
          ) : (
            <>
              <DialogContent>
                <Typography color={'error'}>Invalid Device ID.</Typography>
              </DialogContent>
              <Divider />
              <DialogActions sx={{ justifyContent: 'left' }}>
                <Button variant={'outlined'} color={'error'} onClick={onCancel}>
                  Cancel
                </Button>
              </DialogActions>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};

interface DeviceCallerIdFormProps {
  defaultFormValues: DeviceCallerIdFormFields;
  account?: Account;
  user?: User;
  initialExternalNumber?: PhoneNumber;
  onCancel: () => void;
  onSubmit: (formValues: DeviceCallerIdFormFields) => void;
  errorMessage?: string;
  startDirty?: boolean;
}

const DeviceCallerIdForm = ({
  account,
  user,
  defaultFormValues,
  onCancel,
  onSubmit,
  errorMessage,
  startDirty = false,
}: DeviceCallerIdFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useForm<DeviceCallerIdFormFields>({
    defaultValues: defaultFormValues,
  });

  const formWatch = watch();

  const { data: externalNumber, isLoading: externalNumberIsLoading } =
    usePhoneNumberQuery({ id: formWatch.externalNumber });

  useEffect(() => {
    setValue('externalName', externalNumber?.doc.cnam?.display_name);
  }, [externalNumber]);

  // flag whether to update external number display name
  useEffect(() => {
    if (dirtyFields.externalName) {
      setValue('updateExternalName', true);
    } else {
      setValue('updateExternalName', false);
    }
  }, [dirtyFields.externalName]);

  const handleSetInternal = (number: string | null) => {
    setValue('internalNumber', number, { shouldDirty: true });
  };

  const handleSetExternal = (number: string | null) => {
    setValue('externalNumber', number, { shouldDirty: true });
  };

  const handleSetEmergency = (number: string | null) => {
    setValue('emergencyNumber', number, { shouldDirty: true });
  };

  // device external CID
  let externalCid = formWatch.externalNumber;
  let externalInherited = '';

  // device doesn't have external CID?
  if (!externalCid) {
    //user has external CID?
    if (user?.doc.caller_id?.external?.number) {
      // inherit user external CID
      externalCid = user?.doc.caller_id?.external?.number;
      externalInherited = 'User';
    } else {
      // inherit account external CID
      externalCid = account?.doc.caller_id?.external?.number;
      externalInherited = 'Account';
    }
  }

  // device internal CID
  let internalCid = formWatch.internalNumber;
  let internalInherited = '';

  // device doesn't have internal CID?
  if (!internalCid) {
    //user has internal CID?
    if (user?.doc.caller_id?.internal?.number) {
      // inherit user internal CID
      internalCid = user?.doc.caller_id?.internal?.number;
      internalInherited = 'User';
    } else {
      // inherit account internal CID
      internalCid = account?.doc.caller_id?.internal?.number;
      internalInherited = 'Account';
    }
  }

  // device internal CID name
  let internalName = formWatch.internalName;
  let internalNameInherited = '';

  // device doesn't have internalName CID?
  if (!internalName) {
    //user has internalName CID?
    if (user?.doc.caller_id?.internal?.name) {
      // inherit user internalName CID
      internalName = user?.doc.caller_id?.internal?.name;
      internalNameInherited = 'User';
    } else {
      // inherit account internalName CID
      internalName = account?.doc.caller_id?.internal?.name;
      internalNameInherited = 'Account';
    }
  }

  // device emergency CID
  let emergencyCid = formWatch.emergencyNumber;
  let emergencyInherited = '';

  // device doesn't have emergency CID?
  if (!emergencyCid) {
    //user has emergency CID?
    if (user?.doc.caller_id?.emergency?.number) {
      // inherit user emergency CID
      emergencyCid = user?.doc.caller_id?.emergency?.number;
      emergencyInherited = 'User';
    } else {
      // inherit account emergency CID
      emergencyCid = account?.doc.caller_id?.emergency?.number;
      emergencyInherited = 'Account';
    }
  }

  return (
    <>
      <DialogContent>
        <>
          {/* Internal CID */}
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant={'h6'}>Internal</Typography>
            </Grid>
            <Grid item>
              <InfoTooltip size={'large'} title={'placeholder'} />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item md={4}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                label={'Name'}
                placeholder={'leave empty'}
                {...register('internalName')}
                helperText={
                  formWatch.internalName
                    ? undefined
                    : internalName
                    ? `Will use internal ${internalNameInherited} name:
                     ${internalName}
                    `
                    : 'no account default set. Please set up account caller ID'
                }
              />
            </Grid>
            <Grid item md={5}>
              <div style={{ position: 'relative' }}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Number'}
                  {...register('internalNumber')}
                  // disabled
                  // value={formWatch.internalNumber ? '' : 'Select number'}
                  helperText={
                    formWatch.internalNumber ? undefined : internalCid ? (
                      <Grid container>
                        <Grid item>
                          Will use internal {internalInherited} number:
                        </Grid>
                        <Grid item>
                          {/* @ts-ignore */}
                          <PhoneNumberDisplay ptn={internalCid} />
                        </Grid>
                      </Grid>
                    ) : (
                      'no account default set. Please set up account caller ID'
                    )
                  }
                />

                {/* internal number display */}
                {/*{formWatch.internalNumber ? (
                  <div style={{ position: 'absolute', top: 9, left: 15 }}>
                    <Grid container alignItems={'center'}>
                      <Grid item>
                        <Typography variant={'h6'}>
                           @ts-ignore
                          <PhoneNumberDisplay ptn={formWatch.internalNumber} />
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => handleSetInternal(null)}>
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                ) : null}*/}
              </div>
            </Grid>

            {/* Links to select ext/number for internal number */}
            <Grid item md={3}>
              <NumberSelect
                number={formWatch.internalNumber}
                onSelect={number => handleSetInternal(number)}
              />
              <ExtensionSelect
                ext={formWatch.internalNumber}
                onSelect={ext => handleSetInternal(ext)}
              />
            </Grid>
          </Grid>
          <br />
          {/* External CID */}
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant={'h6'}>External</Typography>
            </Grid>
            <Grid item>
              <InfoTooltip size={'large'} title={'placeholder'} />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item md={4}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                label={'Name'}
                placeholder={
                  externalNumberIsLoading
                    ? 'Loading'
                    : formWatch.externalNumber
                    ? 'leave empty'
                    : 'select number'
                }
                {...register('externalName')}
                error={!!formErrors.externalName}
                disabled={externalNumberIsLoading || !formWatch.externalNumber}
                helperText={
                  '*Will change name for this number in all locations'
                }
              />
            </Grid>
            <Grid item md={5}>
              <div style={{ position: 'relative' }}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Number'}
                  disabled
                  value={formWatch.externalNumber ? '' : 'Select number'}
                  helperText={
                    formWatch.externalNumber ? undefined : externalCid ? (
                      <Grid container>
                        <Grid item>
                          Will use external {externalInherited} number:
                        </Grid>
                        <Grid item>
                          {/* @ts-ignore */}
                          <PhoneNumberDisplay ptn={externalCid} />
                        </Grid>
                      </Grid>
                    ) : (
                      'no account default set. Please set up account caller ID'
                    )
                  }
                />

                {/* external number display */}
                {formWatch.externalNumber ? (
                  <div style={{ position: 'absolute', top: 9, left: 15 }}>
                    <Grid container alignItems={'center'}>
                      <Grid item>
                        <Typography variant={'h6'}>
                          {/* @ts-ignore*/}
                          <PhoneNumberDisplay ptn={formWatch.externalNumber} />
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => handleSetExternal(null)}>
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                ) : null}
              </div>
            </Grid>
            {/* Links to select number for external number */}
            <Grid item md={3}>
              <NumberSelect
                number={formWatch.externalNumber}
                onSelect={number => handleSetExternal(number)}
              />
            </Grid>
          </Grid>
          <br />
          {/* Emergency CID */}
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant={'h6'}>Emergency</Typography>
            </Grid>
            <Grid item>
              <InfoTooltip size={'large'} title={'placeholder'} />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item md={5}>
              <div style={{ position: 'relative' }}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Number'}
                  disabled
                  value={formWatch.emergencyNumber ? '' : 'Select number'}
                  helperText={
                    formWatch.emergencyNumber ? undefined : emergencyCid ? (
                      <Grid container>
                        <Grid item>
                          Will use emergency {emergencyInherited} number:
                        </Grid>
                        <Grid item>
                          {/* @ts-ignore */}
                          <PhoneNumberDisplay ptn={emergencyCid} />
                        </Grid>
                      </Grid>
                    ) : (
                      'no account default set. Please set up account caller ID'
                    )
                  }
                />

                {/* emergency number display */}
                {formWatch.emergencyNumber ? (
                  <div style={{ position: 'absolute', top: 9, left: 15 }}>
                    <Grid container alignItems={'center'}>
                      <Grid item>
                        <Typography variant={'h6'}>
                          {/* @ts-ignore*/}
                          <PhoneNumberDisplay ptn={formWatch.emergencyNumber} />
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => handleSetEmergency(null)}>
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                ) : null}
              </div>
            </Grid>
            {/* Links to select number for emergency number */}
            <Grid item md={3}>
              <NumberSelect
                number={formWatch.emergencyNumber}
                onSelect={number => handleSetEmergency(number)}
              />
            </Grid>
          </Grid>
        </>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button variant={'outlined'} color={'error'} onClick={onCancel}>
          Cancel
        </Button>
        {errorMessage ? (
          <Typography color={'error'}>{errorMessage}</Typography>
        ) : null}
        <Button
          variant={'outlined'}
          color={'success'}
          disabled={
            externalNumberIsLoading || startDirty ? !startDirty : !isDirty
          }
          onClick={handleSubmit(onSubmit)}
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

const NumberSelect = ({ number, onSelect }) => {
  const [showDialog, toggleShowDialog] = useToggleReducer(false);

  const handleSelect = (selected: string[]) => {
    const [number] = selected;
    onSelect(number);
    toggleShowDialog();
  };

  const handleClear = () => {
    onSelect(null);
    toggleShowDialog();
  };

  return (
    <div>
      {showDialog ? (
        <QuickFinderPhoneNumberDialog
          onSelect={handleSelect}
          onCancel={toggleShowDialog}
          onClear={handleClear}
          initialSelected={[number]}
          selectionTitle={number}
        />
      ) : null}
      <Link underline={'hover'} onClick={toggleShowDialog} variant={'caption'}>
        Select Number
      </Link>
    </div>
  );
};

const ExtensionSelect = ({ ext, onSelect }) => {
  const [showDialog, toggleShowDialog] = useToggleReducer(false);

  const handleSelect = (selected: string[]) => {
    const [ext] = selected;
    onSelect(ext);
    toggleShowDialog();
  };

  const handleClear = () => {
    onSelect(null);
    toggleShowDialog();
  };

  return (
    <div>
      {showDialog ? (
        <QuickFinderExtensionDialog
          onSelect={handleSelect}
          onCancel={toggleShowDialog}
          onClear={handleClear}
          initialSelected={[ext]}
          selectionTitle={ext}
        />
      ) : null}
      <Link underline={'hover'} onClick={toggleShowDialog} variant={'caption'}>
        Select Ext
      </Link>
    </div>
  );
};

export default CallerIdDialogDevice;
