import React, { useState, useCallback } from 'react';
import { createStateContext } from 'react-use';

import { Setup1 as TourSetup1 } from '../Tours/Setup1';
import { Webphone as TourWebphone } from '../Tours/Webphone';
import { Onboarding as TourOnboarding } from '../Tours/Onboarding';
import { MobileApp as TourMobileApp } from '../Tours/MobileApp';
// import { First as TourFirst } from '../Tours/First';
// import { User as TourUser } from '../Tours/User';
// import { ScheduleSetup as TourScheduleSetup } from '../Tours/ScheduleSetup';

import EventEmitter from 'eventemitter3';

const ee = new EventEmitter();

ee.on('next', () => console.log('trying next!'));

export const POSSIBLE_TOURS = [
  {
    id: 'setup1',
    component: TourSetup1,
  },
  {
    id: 'webphone',
    component: TourWebphone,
  },
  {
    id: 'mobileapp',
    component: TourMobileApp,
  },
  {
    id: 'onboarding',
    component: TourOnboarding,
  },
  // {
  //   id: 'first',
  //   component: TourFirst,
  // },
  // {
  //   id: 'user',
  //   component: TourUser,
  // },
  // {
  //   id: 'schedule-setup',
  //   component: TourScheduleSetup,
  // },
];

export const [useGlobalTour, GlobalTourStateProvider] = createStateContext();

export const useTourHelper = props => {
  const defaults = {
    startOpen: null,
  };
  const { id, startOpen } = { ...defaults, ...props };

  let [isOpen, setIsOpen] = useState(startOpen);
  let openTour = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  let closeTour = useCallback(() => {
    console.log('closing!');
    setIsOpen(null);
  }, [setIsOpen]);

  let tourProps = {
    onClose: closeTour,
  };

  const TourComponent = POSSIBLE_TOURS.find(t => t.id === id)?.component ?? (
    <></>
  );

  return {
    tourIsOpen: isOpen,
    tourEvents: ee,
    openTour,
    closeTour,
    TourComponent,
    tourProps,
  };
};

export const GlobalTourProvider = ({ children }) => {
  let [tourId, setTourId] = useState(null);

  let closeTour = () => setTourId(null);

  let tourProps = {
    onClose: closeTour,
    tourEvents: ee,
  };

  const TourComponent =
    POSSIBLE_TOURS.find(t => t.id === tourId)?.component ?? null;

  return (
    <GlobalTourStateProvider
      initialValue={{
        openTour: setTourId,
        tourEvents: ee,
      }}
    >
      {TourComponent && <TourComponent {...tourProps} />}
      {children}
    </GlobalTourStateProvider>
  );
};
