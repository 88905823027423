import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Typography,
  InfoTooltip,
  Chip,
  CircularProgress,
} from 'app/design';
import {
  ContactPhone as ContactPhoneIcon,
  MedicalServices as MedicalServicesIcon,
  Place as PlaceIcon,
} from 'app/design/icons-material';
import { useFindInPage } from 'app/components/FindInPage';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { useAccountQuery } from 'app/hooks/queries/account';
import { usePhoneNumberQuery } from 'app/hooks/queries/phoneNumber';
import { UserCallerIdDialog } from 'app/pages/admin/Users/View/components/UserCallerIdDialog';
import { useToggleReducer } from 'app/utilities';
import { useMarkdownTranslate } from 'app/utilities/translate';
import { startCase } from 'lodash';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { User } from 'types/user';

interface UserCallerIdCardProps {
  user: User;
  onComplete: () => void;
}

const UserCallerIdCard = ({ user, onComplete }: UserCallerIdCardProps) => {
  const [showEditDialog, toggleEditShowDialog] = useToggleReducer(false);
  const { data: account } = useAccountQuery();
  // CID Finder
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'caller id',
    options: [
      {
        label: 'Caller ID',
        value: 'caller_id',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  // External CID Finder
  const {
    ref: refExt,
    HighlightPulse: HighlightPulseExt,
    highlightPulseProps: highlightPulsePropsExt,
  } = useFindInPage({
    name: 'ext',
    options: [
      {
        label: 'External Caller ID',
        value: 'external_caller_id',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  // e911 CID Finder
  const {
    ref: refE911,
    HighlightPulse: HighlightPulseE911,
    highlightPulseProps: highlightPulsePropsE911,
  } = useFindInPage({
    name: 'e911',
    options: [
      {
        label: 'Emergency Caller ID',
        value: 'e911',
      },
      {
        label: 'E911',
        value: 'e911',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  // user external CID
  let externalCid = user.doc.caller_id?.external?.number;
  let externalInherited = false;

  // user doesn't have external CID?
  if (!externalCid) {
    // inherit external CID from account
    externalCid = account?.doc.caller_id?.external?.number;
    externalInherited = !!externalCid;
  }

  // user emergency CID
  let emergencyCid = user.doc.caller_id?.emergency?.number;
  let emergencyInherited = false;

  // user doesn't have emergency CID?
  if (!emergencyCid) {
    // inherit emergency CID from account
    emergencyCid = account?.doc.caller_id?.emergency?.number;
    emergencyInherited = !!emergencyCid;
  }

  const {
    data: externalNumberData,
    isLoading: externalNumberIsLoading,
    refetch: refetchExternal,
  } = usePhoneNumberQuery({ id: externalCid });

  const {
    data: emergencyNumberData,
    isLoading: emergencyNumberIsLoading,
    refetch: refetchEmergency,
  } = usePhoneNumberQuery({ id: emergencyCid });

  const externalName = externalNumberData?.doc.cnam?.display_name;
  const externalNumber = externalNumberData?.doc.id;

  const emergencyName = emergencyNumberData?.doc.cnam?.display_name;
  const emergencyAddress = emergencyNumberData?.doc.e911?.street_address
    ? true
    : false;

  const handleComplete = () => {
    onComplete();
    refetchExternal();
    refetchEmergency();
    toggleEditShowDialog();
  };

  const { t } = useMarkdownTranslate();

  return (
    <>
      {showEditDialog ? (
        <UserCallerIdDialog
          userId={user.id}
          onCancel={toggleEditShowDialog}
          onComplete={handleComplete}
        />
      ) : null}
      {/* @ts-ignore */}
      <Card ref={ref} variant="dashboard">
        <HighlightPulse {...highlightPulseProps} />
        <CardHeader
          disableTypography
          title={
            <Grid container>
              <Grid item sx={{ flex: 1 }}>
                <Typography variant="h2">Outgoing Caller ID</Typography>
              </Grid>
              {emergencyNumberIsLoading || externalNumberIsLoading ? (
                <Grid item>
                  <CircularProgress size={15} />
                </Grid>
              ) : null}
              <Grid item>
                <Link
                  onClick={toggleEditShowDialog}
                  underline="hover"
                  variant="body2"
                >
                  Edit Caller ID
                </Link>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          <Grid container rowSpacing={3}>
            {/* @ts-ignore */}
            <Grid ref={refExt} item xs={12} sx={{ position: 'relative' }}>
              <HighlightPulseExt {...highlightPulsePropsExt} />

              <Grid container columnSpacing={2} wrap="nowrap">
                <Grid item>
                  <Typography
                    sx={{ color: theme => theme.palette.primary.mainAlt }} // doesnt work, wrong color
                  >
                    <ContactPhoneIcon
                    // @ts-ignore
                    // sx={{ color: theme => theme.palette.primary.main }} // doesnt work, wrong color
                    />
                  </Typography>
                </Grid>
                <Grid item sx={{ flex: 1 }}>
                  <Typography variant="h3" gutterBottom>
                    <strong>Public:</strong>{' '}
                    <InfoTooltip
                      title={t('caller_id.external.tooltip')}
                      size={'small'}
                    />
                  </Typography>
                  {externalInherited ? (
                    <>
                      <Typography
                        variant="caption"
                        sx={{
                          color: theme => theme.palette.primary.main,
                          fontStyle: 'italic',
                        }}
                      >
                        Inherits from Account
                      </Typography>
                    </>
                  ) : // <Chip
                  //   sx={{ float: 'right' }}
                  //   color={'primary'}
                  //   variant={'outlined'}
                  //   size={'small'}
                  //   label={'Account'}
                  // />
                  null}
                  <Typography
                    variant="bodyBig"
                    sx={
                      externalName
                        ? {}
                        : { color: 'text.secondary', fontStyle: 'italic' }
                    }
                  >
                    {externalNumberIsLoading ? (
                      <>&nbsp;</>
                    ) : (
                      externalName ?? 'name not set'
                    )}
                  </Typography>
                  <Typography variant="bodyBig">
                    {externalNumberIsLoading ? (
                      <>&nbsp;</>
                    ) : externalNumber ? (
                      // @ts-ignore
                      <PhoneNumberDisplay ptn={externalNumber} />
                    ) : (
                      <Typography
                        variant="number1"
                        sx={{ color: 'text.secondary', fontStyle: 'italic' }}
                      >
                        Number not set
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* @ts-ignore */}
            <Grid ref={refE911} item xs={12} sx={{ position: 'relative' }}>
              <HighlightPulseE911 {...highlightPulsePropsE911} />
              <Grid container columnSpacing={2} wrap="nowrap">
                <Grid item>
                  <Typography
                    sx={{ color: theme => theme.palette.primary.mainAlt }} // doesnt work, wrong color
                  >
                    <MedicalServicesIcon
                    // @ts-ignore
                    // sx={{ color: theme => theme.palette.primary.main }} // doesnt work, wrong color
                    />
                  </Typography>
                </Grid>
                <Grid item sx={{ flex: 1 }}>
                  <Typography variant="h3" gutterBottom>
                    <strong>Emergency (E911):</strong>{' '}
                    <InfoTooltip
                      title={t('caller_id.emergency.tooltip')}
                      size={'small'}
                    />
                  </Typography>

                  {emergencyInherited ? (
                    <>
                      <Typography
                        variant="caption"
                        sx={{
                          color: theme => theme.palette.primary.main,
                          fontStyle: 'italic',
                        }}
                      >
                        Inherits from Account
                      </Typography>
                    </>
                  ) : // <Chip
                  //   sx={{ float: 'right' }}
                  //   color={'primary'}
                  //   variant={'outlined'}
                  //   size={'small'}
                  //   label={'Account'}
                  // />
                  null}
                  <Typography
                    variant="bodyBig"
                    sx={
                      emergencyName
                        ? {}
                        : { color: 'text.secondary', fontStyle: 'italic' }
                    }
                  >
                    {emergencyNumberIsLoading ? (
                      <>&nbsp;</>
                    ) : (
                      emergencyName ?? 'name not set'
                    )}
                  </Typography>
                  <Typography variant="bodyBig">
                    {externalNumberIsLoading ? (
                      <>&nbsp;</>
                    ) : emergencyCid ? (
                      // @ts-ignore
                      <PhoneNumberDisplay ptn={emergencyCid} />
                    ) : (
                      <Typography
                        variant="number1"
                        sx={{ color: 'text.secondary', fontStyle: 'italic' }}
                      >
                        Number not set
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <br />
              <Grid container columnSpacing={2} wrap="nowrap">
                <Grid item>
                  <Typography
                    sx={{ color: theme => theme.palette.primary.mainAlt }} // doesnt work, wrong color
                  >
                    <PlaceIcon
                    // @ts-ignore
                    // sx={{ color: theme => theme.palette.primary.main }} // doesnt work, wrong color
                    />
                  </Typography>
                </Grid>
                <Grid item sx={{ flex: 1 }}>
                  <Typography
                    variant="bodyBig"
                    sx={
                      emergencyAddress
                        ? {}
                        : { color: 'text.secondary', fontStyle: 'italic' }
                    }
                  >
                    {emergencyNumberIsLoading ? (
                      <>&nbsp;</>
                    ) : emergencyAddress ? (
                      <>
                        <div>
                          {emergencyNumberData?.doc.e911?.street_address}
                        </div>
                        <div>
                          {emergencyNumberData?.doc.e911?.extended_address}
                        </div>
                        <div>
                          {emergencyNumberData?.doc.e911?.locality},{' '}
                          {emergencyNumberData?.doc.e911?.region}{' '}
                          {emergencyNumberData?.doc.e911?.postal_code}
                        </div>
                      </>
                    ) : (
                      'Address Not Set'
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default UserCallerIdCard;
