import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Link,
  Divider,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Checkbox,
  FormHelperText,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Slide,
  Fade,
  useTheme,
} from 'app/design';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext, useFormState } from 'react-hook-form';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useAuthSelector, useAuthSlice } from 'app/data/auth';
import { useCreateCallflow } from 'app/hooks/mutations/callflow/useCreateCallflow';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { AddressAutocomplete } from 'app/components/AddressAutocomplete';
import { useAccountQuery } from 'app/hooks/queries/account';

import { useLocalStorage } from 'react-use';
import { sleep } from 'app/utilities';

import { css, keyframes } from '@emotion/react';
// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

// const PulseBackgroundKeyframe = keyframes`
//   0% {
//     transform:
//   }
//   100% {
//     box-shadow: 0px 0px 20px rgb(239, 83, 80, 1.0)
//   }
// `;

const Step1 = ({}) => {
  const router = useHistory();
  const { handleSubmit, watch, setValue, setError, trigger, clearErrors } =
    useFormContext();
  const { isSubmitting, isValid, errors } = useFormState();
  const { auth_token } = useAuthSelector();
  const createCallflow = useCreateCallflow();

  const [inVal, setInVal] = useState(true);

  const { data: account } = useAccountQuery({});
  useEffect(() => {
    if (!account || watch('address')) {
      return;
    }
    setValue('companyName', account.doc.name);
    if (account.doc.address) {
      // set values for address (will be re-translated back)
      setValue('address', {
        streetLine: account.doc.address.street_address, // line 1
        secondary: account.doc.address.extended_address,
        city: account.doc.address.locality,
        state: account.doc.address.region, // state
        zipcode: account.doc.address.postal_code,
      });
      setValue('existingAddress', account.doc.address);
    }
  }, [account]);

  // const clearAndHandleSubmit = e => {
  //   clearErrors();
  //   handleSubmit(onSubmit)(e);
  // };

  const prevStep = watch('prevStep');
  const transitionToStep = watch('transitionToStep');
  const thisStep = 0;
  const timeout = 350;
  const handleNext = () => {
    // TODO: validate the address?
    // - figure out what prefix to set
    setValue('prefix', '831');

    // transition:
    setInVal(false);
    setValue('transitionToStep', thisStep + 1);
    setTimeout(() => {
      // setValue('prevStep', thisStep);
      setValue('activeStep', thisStep + 1);
    }, timeout);
  };
  useEffect(() => {
    setTimeout(() => {
      setValue('prevStep', thisStep);
    }, timeout);
  }, []);

  const direction = inVal
    ? prevStep > thisStep
      ? 'right'
      : 'left'
    : transitionToStep > thisStep
    ? 'right'
    : 'left';

  const theme = useTheme();
  const existingAddress = watch('existingAddress');

  return (
    <Slide in={inVal} timeout={timeout} direction={direction}>
      <Box>
        <Fade in={inVal} timeout={timeout}>
          <Box>
            <Box
              sx={{
                borderRadius: '8px',
                background: 'white',
                boxShadow: '0px 4px 14px 4px rgba(27, 125, 144, 0.12)',
                p: 5,
                pt: 3,
                pb: 3,
                // animation: `${PulseBackgroundKeyframe} 3.5s`,
                // animationFillMode: 'forwards',
              }}
            >
              <Box>
                <Typography variant="h2" paragraph>
                  What is the name of your company or team?
                </Typography>
                <Typography variant="body1" paragraph>
                  This will be used as your Caller ID display. You can change
                  this later if needed.
                </Typography>
                <HookFormTextField
                  options={{ maxLength: 128 }}
                  name={'companyName'}
                  errorMessageParser={() => {
                    return 'Company Name cannot exceed 128 characters';
                  }}
                  // label={'Key Input'}
                  // @ts-ignore
                  variant="outlined"
                  placeholder="Company Name"
                  fullWidth
                  autoFocus
                />
              </Box>
              <Box sx={{ mt: 4 }}>
                <Typography variant="h2" paragraph>
                  Where is your company located?
                </Typography>
                <Typography variant="body1" paragraph>
                  This is required for emergency service operators (dialing
                  911).
                </Typography>
                {/* <TextField
                variant="outlined"
                placeholder="Street Address"
                fullWidth
              /> */}
                <AddressAutocomplete
                  // @ts-ignore
                  websiteKey={'120287158445923608'}
                  textfieldProps={{ placeholder: 'Street Address' }}
                  autocompleteProps={{
                    sx: {
                      '& .MuiAutocomplete-endAdornment': {
                        opacity: 0,
                      },
                    },
                  }}
                  onChange={value => {
                    console.log('Address Chosen:', value);
                    if (!value) {
                      return;
                    }
                    setValue('address', value?.value);
                  }}
                />
                {existingAddress ? (
                  <Typography variant="caption">
                    {existingAddress.street_address}{' '}
                    {existingAddress.extended_address}{' '}
                    {existingAddress.locality}, {existingAddress.region}{' '}
                    {existingAddress.zipcode}
                  </Typography>
                ) : null}
                {/* <GooglePlacesAutocomplete
                apiKey="AIzaSyDo7DbGtZg7WgeCW8Ik3enQo5zHftI6GTo"
                selectProps={{
                  placeholder: 'Street Address',
                  openMenuOnFocus: false,
                  openMenuOnClick: false,
                  noOptionsMessage: m => 'test no options',
                  loadingMessage: m => 'Searching addresses',
                  components: {
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  },
                  styles: {
                    // container: (provided, state) => ({
                    //   ...provided,
                    //   paddingBottom: state.isFocused ? 0 : '4px',
                    //   '&:hover': {
                    //     paddingBottom: '3px',
                    //   },
                    // }),
                    control: (provided, state) => ({
                      ...provided,
                      boxShadow: state.isFocused
                        ? `0px 0px 2px ${theme.palette.primary.main}`
                        : `none`,
                      // marginBottom: '1px',
                      borderColor: state.isFocused
                        ? `${theme.palette.primary.main} !important`
                        : 'rgba(0, 0, 0, 0.23)',
                      '&:focus': {
                        // marginLeft: '-1px',
                        // marginTop: '-1px',
                        borderColor: theme.palette.primary.main,
                        boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
                        // borderWidth: '2px',
                      },
                      '&:hover': {
                        // marginLeft: '-1px',
                        // marginTop: '-1px',
                        // // marginBottom: '0px',
                        borderColor: '#333',
                        // boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
                        // borderWidth: '2px',
                      },
                    }),
                    input: (provided, state) => ({
                      ...provided,
                      // color: 'blue',
                      fontWeight: 500,
                      fontFamily: 'DM Sans',
                      padding: '11px 4px',
                    }),
                    placeholder: provided => ({
                      ...provided,
                      // color: 'blue',
                      opacity: 0.4,
                      color: 'currentColor',
                      fontFamily: 'DM Sans',
                      fontWeight: 500,
                      marginLeft: '6px',
                      // padding: '11px 14px',
                    }),
                    option: provided => ({
                      ...provided,
                      fontFamily: 'DM Sans',
                      fontWeight: 500,
                    }),
                    singleValue: provided => ({
                      ...provided,
                      fontFamily: 'DM Sans',
                      fontWeight: 500,
                      marginLeft: '6px',
                    }),
                  },
                }}
              /> */}
              </Box>
              <Box sx={{ mt: 2, textAlign: 'right' }}>
                <Button
                  // size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={
                    !isValid ||
                    !watch('companyName', '').length ||
                    !watch('address')
                  }
                >
                  Next
                </Button>
              </Box>
            </Box>
            {process.env.NODE_ENV == 'development' ? (
              <Box sx={{ mt: 2, textAlign: 'center' }}>
                <Button
                  color="error"
                  size="small"
                  to={'/admin/dashboard'}
                  component={RouterLink}
                >
                  Reset account from Dashboard (very bottom)
                </Button>
              </Box>
            ) : null}
          </Box>
        </Fade>
      </Box>
    </Slide>
  );
};

const buildMainSchedule = data => {
  /*
  holidays.usa_standard
  holidays.usa_auto_update
  holidays.use_extra
  holidays.extra
  */
  const holidays = {};
};

export default Step1;
