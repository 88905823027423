import { Box, Grid } from 'app/design';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MediaType } from 'types/media';

const UploadAudio = ({ register, setValue }) => {
  const [filename, setFilename] = useState();
  useEffect(() => register(`${MediaType.Upload}.file`), [register]);

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles?.[0];
    setValue(`${MediaType.Upload}.file`, file);
    setFilename(file?.name);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: ['audio/wav', 'audio/mp3', 'audio/mp4', 'audio/mpeg'],
    // TODO max size for upload
  });

  return (
    <Box
      {...getRootProps()}
      sx={{
        borderRadius: 1,
        borderColor: 'text.secondary',
        borderStyle: 'solid',
        display: 'grid',
        placeItems: 'center',
        cursor: 'pointer',
      }}
    >
      {isDragActive ? (
        <p>Drop the file here ...</p>
      ) : (
        filename ?? (
          <p>Drag 'n' drop or click to select files (wav, mp3, mp4)</p>
        )
      )}
      <input {...getInputProps()} />
    </Box>
  );
};

export default UploadAudio;
