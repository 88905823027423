import React, {
  // useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isArray } from 'lodash';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useAuthSelector } from 'app/data/auth';
// import { connect } from 'react-redux';

// TODO: handle reconnection and resubscription of bindings
// - on disconnect, go through bindings and create new "waitingForMessage" for each
// - also need to handle Handshake...dont actually "enable" the callback until the "success" reply is received

export const WebsocketContext = React.createContext({});

// handshakes hold the "waiting for successful confirmation of listener being applied"
// - is a TODO (not currently waiting for confirmation)
// const Handshakes = {};

const bindingToUuid = new Map(); // { 'binding' : uuid1}
const uuidToLocalUuids = new Map(); // { uuid1 : [uuid2] }
const localUuidToCallback = new Map(); // { uuid2 : Func }
const waitingToSend = new Set(); // [JSON.stringify(msg)]

export const createSubscribeMsg = (authParams, binding, bindingUuid) => {
  return JSON.stringify({
    action: 'subscribe',
    auth_token: authParams.auth_token,
    requestId: bindingUuid,
    data: {
      binding,
      account_id: authParams.account_id,
    },
  });
};

export const WebsocketProvider = props => {
  const socketUrl = process.env.REACT_APP_WEBSOCKET_URL;

  const { auth_token, auth_token_decoded } = useAuthSelector();
  const account_id = auth_token_decoded?.account_id;
  const authParams = { auth_token, account_id };

  // const dynamicPropRef = useRef(null);
  // dynamicPropRef.current = /*some prop you want to use in the options callbacks*/
  const options = useMemo(
    () => ({
      // share: true, // do NOT use, this kills reconnecting!
      // onMessage: message =>
      //   console.log(
      //     `onMessage with access to dynamicProp: ${dynamicPropRef.current}`,
      //     message
      //   ),
      // onClose: event => console.log('onClose', event),
      // onError: error => console.log('onError', error),
      // onOpen: event => console.log('onOpen', event),
      retryOnError: true,
      reconnectAttempts: 10,
      reconnectInterval: 3000,
      shouldReconnect: () => {
        return true;
      },
    }),
    [],
  );

  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    socketUrl,
    options,
  );

  // TODO: handle readyState management
  // - gathering subscriptions to make when connection is ready
  useEffect(() => {
    // console.log('ReadyState Change', readyState, ReadyState);
    switch (readyState) {
      case ReadyState.OPEN:
        // make requests to all pending
        // console.log('sending waitingToSend', waitingToSend);
        // debugger;
        for (let msg of waitingToSend.values()) {
          sendMessage(msg);
        }
        // setWaitingToSend([]);
        waitingToSend.clear();
        break;
      case ReadyState.CLOSED:
        // note that we need to re-open on the next OPEN!
        // - add a bunch to sendMessage? Use the same requestID-uuid?
        // PUSH to waitingToSend
        // alert('closed websocket');
        bindingToUuid.forEach((bindingUuid, binding) => {
          const msg = createSubscribeMsg(authParams, binding, bindingUuid);
          waitingToSend.add(msg);
        });
        break;
      case ReadyState.CLOSING:
        // note that we need to re-open on the next OPEN!
        // - add a bunch to sendMessage? Use the same requestID-uuid?
        // PUSH to waitingToSend
        // alert('closing websocket');
        break;
      case ReadyState.CONNECTING:
        // note that we need to re-open on the next OPEN!
        // - add a bunch to sendMessage? Use the same requestID-uuid?
        // PUSH to waitingToSend
        // alert('connecting websocket');
        break;
      default:
        // trying to reconnect?
        break;
    }
  }, [readyState, sendMessage, authParams.auth_token, authParams.account_id]);

  const subscribe = useCallback(
    (bindings, onDataFunc) => {
      const localUuid = uuidv4();
      localUuidToCallback.set(localUuid, onDataFunc);

      bindings = isArray(bindings) ? bindings : [bindings];

      for (let binding of bindings) {
        // see if already subscribed
        let bindingUuid = bindingToUuid.get(binding);
        if (bindingUuid) {
          // push requestId to binding
          uuidToLocalUuids.get(bindingUuid).add(localUuid);
        } else {
          // start a new subscription
          bindingUuid = uuidv4();
          bindingToUuid.set(binding, bindingUuid);
          uuidToLocalUuids.set(bindingUuid, new Set());
          uuidToLocalUuids.get(bindingUuid).add(localUuid);

          const msg = createSubscribeMsg(authParams, binding, bindingUuid);
          // JSON.stringify({
          //   action: 'subscribe',
          //   auth_token: localStorage.getItem('authToken'),
          //   requestId: bindingUuid,
          //   data: {
          //     binding,
          //     account_id: localStorage.getItem('account_id')
          //   }
          // });
          if (ReadyState.OPEN === readyState) {
            sendMessage(msg);
          } else {
            waitingToSend.add(msg);
          }
        }
      }

      return localUuid;
    },
    [readyState, sendMessage, authParams.auth_token, authParams.account_id],
  );

  const unsubscribe = useCallback(localUuid => {
    // remove local subscription from globally-shared listeners
    // - TODO: clear empty/unsubscribe (instead of passing to no listeners)
    localUuidToCallback.delete(localUuid);
    // delete localUuidToCallback[localUuid];
    // setCallbackForLocal({
    //   ...localUuidToCallback
    // });
  }, []);

  useEffect(() => {
    if (lastMessage) {
      // console.log('Parsing lastMessage for websocket:', lastMessage);

      let data;
      try {
        data = JSON.parse(lastMessage.data);
      } catch (err) {
        console.error('Failed parsing data from websocket');
        return false;
      }

      switch (data.action) {
        case 'reply':
          // console.log('Handling a websocket reply');
          // TODO: respond to the initiating request w/ a "this was setup successfully" response
          break;
        case 'event':
          // Update data for listeners
          const binding = data.subscribed_key;
          const bindingUuid = bindingToUuid.get(binding);
          if (!bindingUuid) {
            // console.log('Listener expired (dont care no more)');
            return;
          }
          const localUuids = uuidToLocalUuids.get(bindingUuid);
          // call updater function for each listener
          for (const localUuid of localUuids.values()) {
            const callback = localUuidToCallback.get(localUuid);
            if (callback) {
              // console.log('running callback');
              callback(data);
            } else {
              // console.log('NO callback');
            }
          }
          break;
        default:
          console.error('Invalid websocket action');
          return false;
      }
    } else {
      // console.error('MISSING lastMessage');
    }
    // debugger;
  }, [lastMessage, subscribe, unsubscribe]);

  const value = {
    subscribe,
    unsubscribe,
    getWebSocket,
  };

  return (
    <WebsocketContext.Provider value={value}>
      {props.children}
    </WebsocketContext.Provider>
  );
};

export const useWebsocketBinding = (bindings, callback) => {
  const { subscribe, unsubscribe, readyState, getWebSocket } =
    useContext(WebsocketContext);

  // const context = useContext(WebsocketContext);
  const [lastMessage, setLastMessage] = useState(null);

  useEffect(() => {
    // subscribe
    // console.log('subscribing to:', binding, callback);

    // // TODO: remove for working websockets
    // if (window.sessionStorage.getItem('mobile')) {
    //   return;
    // }
    const requestId = subscribe(bindings, data => {
      // console.log('new event:', binding);
      setLastMessage(data);
      if (callback) {
        callback(data);
      }
    });
    return () => {
      if (requestId) {
        unsubscribe(requestId);
      }
    };
  }, [bindings, callback, subscribe, unsubscribe]);

  return {
    lastMessage,
    readyState,
    getWebSocket,
  };
};
