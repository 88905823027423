import { CallflowNumbers } from 'app/components/CallflowNumbers';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { IvrQuickDisplay } from 'app/components/IvrBuilder';

// if we get "ReferenceError: Cannot access 'IvrMenuEventEmitterContext' before initialization"
// - buildOutputCallflowFromTemplate and getSimpleVariables MUST be directly imported!
import { buildOutputCallflowFromTemplate } from 'app/components/IvrBuilder/Flow/nodes/Template/buildOutputCallflowFromTemplate';
import { getSimpleVariables } from 'app/components/IvrBuilder/Flow/nodes/Template/getSimpleVariables';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from 'app/design';
import {
  useCreateCallflow,
  useUpdateCallflowPartial,
} from 'app/hooks/mutations/callflow';
import { parseCallflowNumbers, useToggleReducer } from 'app/utilities';
import { clone, cloneDeep, startCase } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { SimpleUIComponent } from './components/SimpleUI/Component';
import TEMPLATES from './templates';

// interface declaring which props are required/allowed
// interface CallRoutingDialogProps {
//   // directoryId: string;
//   callflow?: any;
//   open?: boolean;
//   onCancel: () => void;
//   // onComplete: () => void;
// }

let CALLFLOW;
const CallRoutingDialog = ({
  // directoryId,
  name_type,
  type,
  resourceId,
  owner_type,
  defaultNumbers,
  callflow,
  open,
  onCancel,
  onComplete,
  isInline,
}) => {
  const updateCallflow = useUpdateCallflowPartial();
  const createCallflow = useCreateCallflow();

  const router = useHistory();

  // if callflow doesnt exist, create a default one for saving
  const defaultCallflow = callflow?.doc
    ? callflow.doc
    : DEFAULT_CALLFLOW({
        name_type,
        type,
        resourceId,
        owner_type,
        numbers: defaultNumbers,
      });

  // loaded WITH callflow
  const methods = useForm({
    defaultValues: {
      callflow: defaultCallflow,
      // username: username ?? credentials?.username ?? '',
      // account_name: account_name ?? credentials?.account_name ?? '',
      // password: credentials?.account_name ? credentials?.password : '',
    },
    // resolver: joiResolver(schema),
  });

  const enabledSimple = methods.watch('callflow.strategy.simple.enabled'); // for Advanced/Visual-only editing
  const parent_set = methods.watch('callflow.strategy.template.parent_id'); // can be "custom"!

  const onSubmit = async data => {
    const callflow = clone(data.callflow);
    // const template_base = data.callflow.strategy.data.templateBaseCallflow;
    // const output_base = data.callflow.strategy.data.outputCallflow;

    // rebuild the callflow that we'll insert into the data.callflow.strategy.data.outputCallflow

    if (enabledSimple) {
      // alert('SIMPLE');
      const outputCallflow = await buildOutputCallflowFromTemplate({
        // more like "Build Template Strategy using rootCallflow variables"
        // - or, can be used on regular Callflows too!
        rootCallflow: callflow, // root variables (and callflow.strategy.type=template -> callflow.strategy.simple.variables)
        templateCallflow: callflow, // has the "ready for simple" (calculated) values!
        checkSimple: true,
      });
      // add the outputCallflow to the final!
      callflow.strategy.data = outputCallflow.strategy.data;
      methods.setValue('callflow', callflow, { shouldDirty: true });
      console.log('built outputcallflow', outputCallflow);
    }

    // save THIS callflow! (it has correct values inserted, hopefully!)
    console.log('Final', callflow);
    CALLFLOW = callflow;
    if (callflow.id) {
      await updateCallflow.mutateAsync(
        {
          id: callflow.id,
          numbers: callflow.numbers,
          strategy: callflow.strategy,
        },
        {
          onSuccess: resp => {
            // const { data }: { data: CallflowDoc } = resp;
            // router.push(link);
            // alert('done saving1');
            onComplete();
          },
        },
      );
    } else {
      await createCallflow.mutateAsync(callflow, {
        onSuccess: resp => {
          // const { data }: { data: CallflowDoc } = resp;
          // router.push(link);
          // alert('done saving2');
          onComplete();
        },
      });
    }
    // return callflow value for handleGotoVisual
    return callflow;
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleGotoVisual = async () => {
    console.log('handleGotoVisual');
    let cf = callflow;
    if (methods.formState.isDirty) {
      await methods.handleSubmit(onSubmit)();
      cf = CALLFLOW;
      console.log('CF:', cf);
    } else {
      onCancel();
    }
    if (cf?.id) {
      router.push(`/admin/ivr/edit/${cf?.id}`);
    } else {
      console.error('No callflow id to redirect to');
    }
  };

  const inprogressCallflow = methods.watch('callflow');
  const [inprogressCallflowKey, setInprogressCallflowKey] = useState(0);
  useEffect(() => {
    console.log('INPROGRESS UPDA', inprogressCallflowKey);
    setInprogressCallflowKey(v => {
      return v + 1;
    });
  }, [JSON.stringify(inprogressCallflow)]);

  return (
    // @ts-ignore
    <FormProvider {...methods}>
      <Dialog open={open} maxWidth="lg" fullWidth scroll="body">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <NumberRouting />
              {enabledSimple ? (
                <>
                  {parent_set ? (
                    <DisplayTemplate />
                  ) : (
                    <ChooseTemplate
                      {...{
                        type,
                        owner_type,
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <DisallowSimple
                    {...{
                      callflow,
                      open,
                      onCancel,
                      onComplete,
                    }}
                  />
                </>
              )}
            </Grid>
            <Grid item md={6}>
              {inprogressCallflow ? (
                <Box
                  sx={{ position: 'relative', width: '500px', height: '500px' }}
                >
                  <IvrQuickDisplay
                    // id={JSON.stringify(callflow)}
                    key={inprogressCallflowKey} // causes a rerender when changed!
                    callflow={inprogressCallflow}
                  />
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button variant={'outlined'} color={'error'} onClick={handleCancel}>
            Cancel
          </Button>
          {!methods.formState.isSubmitting ? (
            <Button
              variant={'link'}
              color={'info'}
              // component={RouterLink}
              onClick={handleGotoVisual}
            >
              {methods.formState.isDirty ? 'Save and ' : null}Go to Visual
              Editor
            </Button>
          ) : null}
          <Button
            variant={methods.formState.isDirty ? 'contained' : 'outlined'}
            loading={methods.formState.isSubmitting ? true : false}
            color={'success'}
            onClick={methods.handleSubmit(onSubmit)}
            disabled={methods.formState.isDirty ? false : true}
          >
            {methods.formState.isDirty
              ? 'Save Call Handling'
              : 'No Change to Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

// const InlineWrapper = ({children}) => {
//   return children
// }

// const DialogWrapper = (props) => {
//   return <Dialog open={open} maxWidth="lg" fullWidth scroll="body">
//         <DialogContent></DialogContent></Dialog>
// }

const DisallowSimple = ({ callflow, open, onCancel, onComplete }) => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const editingCallflow = watch('callflow');

  const handleChangeTemplate = () => {
    setValue('callflow.strategy.template.parent_id', null, {
      shouldDirty: true,
    });
    setValue('callflow.strategy.simple.enabled', true, { shouldDirty: true });
    setValue('callflow.strategy.simple.variables', {}, { shouldDirty: true });
    setValue('callflow.strategy.data', null, { shouldDirty: true });
  };

  let label =
    editingCallflow?.strategy?.template?.parent_callflow?.name ??
    'Unknown template';

  return (
    <Grid container spacing={1} alignItems={'center'}>
      <Grid item sx={{ flex: 1 }}>
        <Typography variant={'h6'}>Custom Call Routing</Typography>
      </Grid>
      <Grid item>
        <Button onClick={handleChangeTemplate}>
          Switch to Simple Template
        </Button>
      </Grid>
    </Grid>
    // <Grid container spacing={2}>
    //   <Grid item md={12}>
    //     <Typography variant="body1">
    //       Simple Editing is disabled for this Call Route
    //     </Typography>
    //     {/* {callflow?.id ? (
    //       <Button
    //         variant={'link'}
    //         color={'info'}
    //         component={RouterLink}
    //         to={`/admin/ivr/edit/${callflow?.id}`}
    //       >
    //         Go to Expert/Visual Editor
    //       </Button>
    //     ) : null} */}
    //     <Button variant={'link'} color={'info'} onClick={handleChangeTemplate}>
    //       Switch back to a Template
    //     </Button>
    //   </Grid>
    //   {/* <Grid item md={6}></Grid> */}
    // </Grid>
  );
};

const NumberRouting = () => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const editingCallflow = watch('callflow');
  const setEditingCallflow = cf => {
    setValue('callflow', cf, { shouldDirty: true });
  };

  const [showDialog, toggleShowDialog] = useToggleReducer(false);

  const { ptns, ptnsParsed, extensions } = parseCallflowNumbers(
    editingCallflow.numbers,
  );

  return (
    <div>
      {showDialog && (
        <NumberChangeDialog
          callflow={editingCallflow}
          setCallflow={setEditingCallflow}
          modifyPath={''}
          onClose={toggleShowDialog}
        />
      )}
      <Grid container justifyContent="space-between">
        <Grid item>
          <div>
            {!ptns.length && !extensions.length && (
              <Chip
                label="No numbers selected"
                color="default"
                // size="small"
                variant="outlined"
                // sx={{ margin: 0.5 }}
              />
            )}
            {ptns.length
              ? ptns.map(num => (
                  <Typography variant="h6" key={num}>
                    <PhoneNumberDisplay
                      // key={num}
                      // size="medium"
                      ptn={num}
                    />
                  </Typography>
                ))
              : null}
            {extensions.length ? (
              <div>
                <Typography variant="body2">
                  {/* <Box style={{ display: 'inline-block', fontWeight: 'bold' }}>
                    Internal Extensions:{' '}
                  </Box> */}
                  {extensions.map(num => (
                    <Chip
                      key={num}
                      label={`${num}`}
                      color="primary"
                      // size="small"
                      sx={{ margin: 0.5 }}
                    />
                  ))}
                </Typography>
              </div>
            ) : null}
          </div>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={toggleShowDialog}>
            {!ptns.length && !extensions.length
              ? 'Add Numbers'
              : 'Change Numbers'}
          </Button>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br />
    </div>
  );
};

const NumberChangeDialog = props => {
  const { callflow, setCallflow, modifyPath, onClose } = props;

  const [editingCallflow, setEditingCallflow] = useState(cloneDeep(callflow));

  const handleSave = () => {
    setCallflow({ ...editingCallflow });
    onClose(); // already done for some reason when setCallflow?
  };

  return (
    <Dialog open fullWidth>
      <DialogTitle>Incoming Numbers</DialogTitle>
      <Divider />
      <DialogContent>
        <CallflowNumbers
          neverCollapse
          strategyCallflow={editingCallflow}
          setStrategyCallflow={setEditingCallflow}
          modifyPath={modifyPath}
        />
      </DialogContent>
      <Divider />
      <DefaultDialogActions
        // errors={errors}
        saveLabel="Update"
        onSave={handleSave}
        onCancel={onClose}
      />
    </Dialog>
  );
};

const DisplayTemplate = () => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const editingCallflow = watch('callflow');

  const handleChangeTemplate = () => {
    setValue('callflow.strategy.template.parent_id', null, {
      shouldDirty: true,
    });
    setValue('callflow.strategy.simple.enabled', true, { shouldDirty: true });
    setValue('callflow.strategy.simple.variables', {}, { shouldDirty: true });
    setValue('callflow.strategy.data', null, { shouldDirty: true });
  };

  let label =
    editingCallflow?.strategy?.template?.parent_callflow?.name ??
    'Unknown template';
  return (
    <>
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item sx={{ flex: 1 }}>
          <Typography variant={'h6'}>{label}</Typography>
        </Grid>
        <Grid item>
          <Button onClick={handleChangeTemplate}>Change Template</Button>
        </Grid>
      </Grid>
      <TemplateVariables />
    </>
  );
};

export const ChooseTemplate = ({ type, owner_type }) => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const options = TEMPLATES.filter((template: any) => {
    return (
      (template.limit?.owner_type &&
        template.limit?.owner_type?.includes(owner_type)) ||
      (template.limit?.type && template.limit?.type?.includes(type))
    );
  }).map(template => ({
    label: template.name,
    value: template.id,
    full: template,
  }));

  const option_val = watch('callflow.strategy.template.parent_id');
  const optionValue = options.find(opt => opt.value === option_val) || null;

  const onChangeParent = templateFull => {
    if (!templateFull) {
      return;
    }
    setValue('callflow.strategy.template.parent_callflow', templateFull, {
      shouldDirty: true,
    });
    setValue('callflow.strategy.simple.variables', {}, { shouldDirty: true });
    setValue('callflow.strategy.data', null, { shouldDirty: true });
  };

  return (
    <Controller
      control={control}
      name={'callflow.strategy.template.parent_id'}
      render={({ field: { onChange, value, ...props } }) => (
        <Autocomplete
          options={options}
          // filterOptions={filterOptions}
          // @ts-ignore
          value={optionValue}
          onChange={(e, v) => {
            onChange(v?.value);
            onChangeParent(v?.full);
          }}
          renderInput={params => (
            <TextField
              {...params}
              label="Choose template for routing incoming calls"
              fullWidth
            />
          )}
          // disableClearable
        />
      )}
    />
  );
};

const TemplateVariables = () => {
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const [showAdvanced, toggleShowAdvanced] = useToggleReducer(false);

  const callflow = watch('callflow');
  const template_base = watch('callflow.strategy.template.parent_callflow');
  const variables = watch('callflow.strategy.simple.variables');
  const simpleVariablesForUI = watch('simpleVariablesForUI', []);

  const applyTemplateAndLoadVariables = useMutation(
    async ({ callflow, template_base }: any) => {
      console.log('applyTemplateAndLoadVariables');
      // Load all the variables from the template
      // - modifies the template items "output_value" to be the value built from this "apply" step
      // - skips variable checking (no checkSimple)
      let outputCallflow;

      // see if applied previously
      // - modules exist => ran previously
      if (callflow?.strategy?.data) {
        console.log('Already ran!');
        outputCallflow = callflow;
        // await buildOutputCallflowFromTemplate({
        //   // rebuild STRATEGY
        //   rootCallflow: callflow, // root variables (and callflow.strategy.type=template -> callflow.strategy.simple.variables)
        //   templateCallflow: callflow, // template-level variables (none created)
        //   // checkSimple: false,
        // });
      } else {
        outputCallflow = await buildOutputCallflowFromTemplate({
          // rebuild STRATEGY
          rootCallflow: callflow, // root variables (and callflow.strategy.type=template -> callflow.strategy.simple.variables)
          templateCallflow: template_base, // template-level variables (none created)
          // checkSimple: false,
        });
      }

      console.log('outputCallflow1:', callflow, outputCallflow);

      // get all variables and filter to simple-only?
      const simpleVariablesForUI = await getSimpleVariables({
        rootCallflow: callflow, // root variables (and callflow.strategy.type=template -> callflow.strategy.simple.variables)
        templateCallflow: outputCallflow, // template-level variables (none created)
      });

      // expecting the type to be "blank" or "schedule" already, and not to be changed??
      // - seems weird to only get data... (maybe should get top-level variables as well? for schedule?)
      setValue('callflow.strategy.data', outputCallflow?.strategy?.data, {
        // shouldDirty: true,
      });
      setValue('simpleVariablesForUI', simpleVariablesForUI, {
        // shouldDirty: true,
      });
    },
  );

  // skip first run?
  const firstRef = useRef(false);
  useEffect(() => {
    // if (!firstRef.current) {
    //   console.log('Skipping applyTemplateAndLoadVariables on first-run');
    //   firstRef.current = true;
    //   return;
    // }
    console.log(
      'Changing applyTemplateAndLoadVariables because template_base changed',
    );
    applyTemplateAndLoadVariables.mutateAsync({ callflow, template_base });
  }, [template_base]);

  //   const {
  //     mutate: mutateDirectoryBox,
  //     isLoading: isLoadingMutate,
  //     error: mutateError,
  //   } = useMutation(data => {
  //     // KazooSDK.resourceDirectoryesPatch(data)
  //   });

  const advancedArr = simpleVariablesForUI?.filter(
    uiVar => uiVar.varItem.simple_advanced,
  );
  const simpleArr = simpleVariablesForUI?.filter(
    uiVar => !uiVar.varItem.simple_advanced,
  );
  const hasAdvanced = advancedArr?.length ? true : false;
  const uiElements = showAdvanced ? simpleVariablesForUI : simpleArr;

  // console.log('uiElements1:', uiElements);

  return (
    <Paper sx={{ p: 2, background: '#fafafa' }} elevation={0}>
      {/* <br />
      <Divider />
      <br />
      <Typography variant="h2" paragraph>
        Call Routing Options:
      </Typography> */}
      {applyTemplateAndLoadVariables.isLoading ? (
        <Box>
          <Typography variant="caption">
            Loading options for editing...
          </Typography>
        </Box>
      ) : uiElements?.length ? (
        uiElements?.map((uiVar, idx) => (
          <Box
            key={idx}
            sx={{
              margin: '12px 0',
              display: uiVar.skipShowingInSimple ? 'none' : null,
              mb: 4,
            }}
          >
            <SimpleUIComponent item={uiVar} />
          </Box>
        ))
      ) : (
        <Box>
          <Typography variant="caption">No options for template</Typography>
        </Box>
      )}
      {process.env.NODE_ENV === 'development' ? (
        <div>
          {hasAdvanced ? (
            <>
              <br />
              <Divider />
              <br />
              {showAdvanced ? (
                <Link variant="body2" onClick={toggleShowAdvanced}>
                  Hide Advanced
                </Link>
              ) : (
                <Link variant="body2" onClick={toggleShowAdvanced}>
                  Show Advanced
                </Link>
              )}
            </>
          ) : null}
        </div>
      ) : null}
    </Paper>
  );
};

const CallRoutingDialogWrapper = props => {
  if (!props.open) {
    return null;
  }
  return <CallRoutingDialog {...props} />;
};

export const useCallRoutingDialog = ({ initialOpen = false }) => {
  const [open, toggleOpen] = useToggleReducer(initialOpen);

  const CallRoutingDialogProps = {
    open,
    onCancel: toggleOpen,
  };

  return {
    toggleOpen,
    CallRoutingDialog: CallRoutingDialogWrapper,
    CallRoutingDialogProps,
  };
};

const DEFAULT_CALLFLOW = ({
  name_type,
  type,
  resourceId,
  owner_type,
  numbers = [],
}) => ({
  name: `${startCase(name_type)} Main`,
  type,
  owner_id: resourceId,
  owner_type, //
  numbers,
  flow: {
    module: 'callflow',
    data: { id: 'placeholder' },
  },
  metaflow: {},
  strategy: {
    type: 'blank',
    template: {
      // more like "clone" cuz could be another callflow that is NOT a template?
      // straight_to_advanced: false, // for skipping "simple" (not commonly used)
      parent_id: null, // "custom" for a custom non-template one (not used at all yet?)
      parent_callflow: null, // cached value of template (id included)
    },
    simple: {
      enabled: true, // for v1, when we dont allow somebody to "go back" from Advanced/Visual Editor
      variables: {},
    },
    data: {
      // modules: [],
    },
    // type: 'template',
    // data: {
    //   id: null,
    //   templateBaseCallflow: null, // copied from template/id {id, name, limit, strategy...}
    //   variables: {}, // for the selected template (erased when template changes!)
    //   outputCallflow: null,
    // },
  },
});

export default CallRoutingDialog;
