import { useMutation } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { GroupCreateDoc } from 'types/group';

export const useCreateGroup = () => {
  const authState = useAuthSelector();
  return useMutation((groupCreateDoc: GroupCreateDoc) =>
    sdk.group.mutate.create(groupCreateDoc, authState?.auth_token),
  );
};

export const useCreateGroupDev = () => {
  const authState = useAuthSelector();
  return useMutation((groupCreateDoc: GroupCreateDoc) =>
    sdk.group.mutate.createDev(groupCreateDoc, authState?.auth_token),
  );
};
