import {
  Button,
  Chip,
  Grid,
  IconButton,
  InfoTooltip,
  Link,
  Typography,
} from 'app/design';
import { Edit as EditIcon } from 'app/design/icons-material';
import { useFindInPage } from 'app/components/FindInPage';
import { QuickFinderUserDialog } from 'app/components/QuickFinderUserDialog';
import { useUpdateVmboxPartial } from 'app/hooks/mutations/vmbox';
import { useUserQuery } from 'app/hooks/queries/user';
import { useMarkdownTranslate } from 'app/utilities/translate';
import * as React from 'react';
import { useReducer } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Vmbox } from 'types/vmbox';

interface VmboxOwnerProps {
  vmbox: Vmbox;
  onComplete: () => void;
}

const VmboxOwner = ({ vmbox, onComplete }: VmboxOwnerProps) => {
  const [showDialog, toggleShowDialog] = useReducer(show => !show, false);
  const updateVmbox = useUpdateVmboxPartial();
  const { data: user, isLoading: userIsLoading } = useUserQuery(
    vmbox.doc.owner_id,
  );

  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'owner',
    options: [
      {
        label: 'Owner',
        value: 'owner',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const handleSelect = (selected: string[]) => {
    const [userId] = selected;

    const updateVmboxPromise = updateVmbox.mutateAsync(
      { id: vmbox.doc.id, owner_id: userId },
      {
        onSuccess: () => {
          toggleShowDialog();
          onComplete();
        },
      },
    );

    toast.promise(updateVmboxPromise, {
      pending: 'Updating vmbox owner...',
      success: 'Vmbox owner updated!',
      error: 'Error updating vmbox owner.',
    });
  };

  const { t } = useMarkdownTranslate();

  const handleMakeShared = () => {
    const updateVmboxPromise = updateVmbox.mutateAsync(
      { id: vmbox.doc.id, owner_id: null },
      {
        onSuccess: () => {
          onComplete();
        },
      },
    );

    toast.promise(updateVmboxPromise, {
      pending: 'Updating vmbox...',
      success: 'Vmbox updated!',
      error: 'Error updating vmbox.',
    });
  };

  return (
    <>
      {showDialog ? (
        <QuickFinderUserDialog
          mutationLoading={updateVmbox.isLoading}
          mutationLoadingLabel={'Updating vmbox owner...'}
          onSelect={handleSelect}
          onCancel={toggleShowDialog}
          errorMessage={updateVmbox.error?.message}
          initialSelected={user ? [user?.id] : []}
          selectionTitle={user ? user.extra.fullName : null}
        />
      ) : null}
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item>
          <Typography
            // @ts-ignore
            ref={ref}
            sx={user ? {} : { fontStyle: 'italic', color: 'text.secondary' }}
          >
            <HighlightPulse {...highlightPulseProps} />
            {user ? (
              <Link
                to={`/admin/users/view/${user.id}`}
                component={RouterLink}
                underline={'hover'}
              >
                Owner: {user.extra.fullName}
              </Link>
            ) : userIsLoading ? (
              'Loading...'
            ) : (
              <Grid container columnSpacing={1}>
                {vmbox.doc.owner_id ? (
                  // if orphaned (owner ID set, user does not exists)
                  <>
                    <Grid item>
                      <Chip
                        color={'error'}
                        variant={'contained'}
                        size={'small'}
                        label={'Orphaned'}
                      />
                    </Grid>
                    <Grid item>
                      <InfoTooltip title={t('vmbox.orphan.tooltip')} />
                    </Grid>
                  </>
                ) : (
                  // shared vmbox
                  <>
                    <Grid item>
                      <Chip
                        color={'primary'}
                        variant={'contained'}
                        size={'small'}
                        label={'Shared'}
                      />
                    </Grid>
                    <Grid item>
                      <InfoTooltip title={t('vmbox.shared.tooltip')} />
                    </Grid>
                  </>
                )}
              </Grid>
            )}
          </Typography>
        </Grid>
        {updateVmbox.isLoading || userIsLoading ? (
          <Grid item>{updateVmbox.isLoading ? 'Updating...' : ''}</Grid>
        ) : (
          <>
            <Grid item>
              <IconButton size={'small'} onClick={toggleShowDialog}>
                <EditIcon fontSize={'small'} />
              </IconButton>
            </Grid>
            {vmbox.doc.owner_id ? (
              <Grid item>
                <Button onClick={handleMakeShared}>Make Shared</Button>
              </Grid>
            ) : null}
          </>
        )}
      </Grid>
    </>
  );
};

export default VmboxOwner;
