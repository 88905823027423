import React, { memo, useState, useRef } from 'react';
import {
  Handle,
  useZoomPanHelper,
  useStore,
  useStoreState,
  useStoreActions,
} from 'react-flow-renderer';

import {
  Box,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
} from 'app/design';

import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import {
  Phone as PhoneIcon,
  ArrowDownward as ArrowDownwardIcon,
} from 'app/design/icons-material';

import { getAtPath, parseCallflowNumbers } from 'app/utilities';

import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { CallflowNumbers } from 'app/components/CallflowNumbers';

import { cloneDeep } from 'lodash';
import { useHoverDirty } from 'react-use';

import { Box as IvrNodeWrap } from '../common/Box';

const IncomingNode = memo(props => {
  const { xPos, yPos, data, ...rest } = props;
  const flowStore = useStore();
  const state = flowStore.getState();
  const { zoomTo, setCenter } = useZoomPanHelper();

  const boxRef = useRef(null);
  const isHovering = useHoverDirty(boxRef);

  const [showDialog, setShowDialog] = useState(null);

  const handleDoubleClick = () => {
    console.log('position:', xPos, yPos, state.width, state.height);
    setCenter(xPos, yPos, 1);
  };

  const {
    ptns: accountPtns,
    ptnsParsed: accountPtnsParsed,
    extensions: accountExtensions,
  } = parseCallflowNumbers(data.callflow.numbers);

  if (data.isInlineCallflow) {
    // CustomInline version
    // - TODO: let the user pass in what text we want displayed here?
    return <IncomingCustomInlineDisplayNode {...props} />;
  }

  if (data.skipEditing) {
    // QuickDisplay version
    return <IncomingQuickDisplayNode {...props} />;
  }

  if (data.callflow.type == 'template') {
    return <IncomingTemplateNode {...props} />;
  }

  return (
    <>
      {showDialog && (
        <IncomingDialog {...props} onClose={e => setShowDialog(null)} />
      )}
      <IvrNodeWrap
        pref={boxRef}
        // borderColor="#5E35B1"
        position={data?.position}
        data={data}
      >
        <Box sx={{ padding: 1 }} onClick={e => setShowDialog(true)}>
          <div style={{ textAlign: 'center' }}>
            <PhoneIcon size="small" />
          </div>
          <div>
            {accountPtns.length ? (
              <div>
                {accountPtns.map(ptn => (
                  <div key={ptn}>
                    <Typography variant="h6">
                      <PhoneNumberDisplay width="auto" ptn={ptn} />
                    </Typography>
                  </div>
                ))}
              </div>
            ) : (
              <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                No Direct Numbers
              </Typography>
            )}
          </div>
          <div>
            {accountExtensions.length ? (
              <div>
                {accountExtensions.map(ext => (
                  <div key={ext}>
                    <PhoneNumberDisplay
                      width="auto"
                      size="small"
                      ptn={ext}
                      allowExtension
                    />
                  </div>
                ))}
              </div>
            ) : (
              <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                No Extensions
              </Typography>
            )}
          </div>
        </Box>
        <Handle
          type="source"
          position="bottom"
          style={{
            top: 'auto',
            bottom: 4,
            background: '#555',
            visibility: 'hidden',
          }}
        />
      </IvrNodeWrap>
    </>
  );
});

const IncomingTemplateNode = memo(props => {
  const { xPos, yPos, data, ...rest } = props;
  const flowStore = useStore();
  const state = flowStore.getState();
  const { zoomTo, setCenter } = useZoomPanHelper();

  const [showDialog, setShowDialog] = useState(null);

  const handleDoubleClick = () => {
    console.log('position:', xPos, yPos, state.width, state.height);
    setCenter(xPos, yPos, 1);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div onClick={e => setShowDialog(true)}>
          <div style={{ textAlign: 'center' }}>
            <ArrowDownwardIcon size="small" />
            <br />
            <Typography variant="body2">Template Input</Typography>
          </div>
          <Handle
            type="source"
            position="bottom"
            style={{
              top: 'auto',
              bottom: 4,
              background: '#555',
              visibility: 'hidden',
            }}
          />
        </div>
      </div>
    </>
  );
});

const IncomingDialog = props => {
  const { data, onClose } = props;
  const { callflow, setCallflow, modifyPath } = data;

  const [editingCallflow, setEditingCallflow] = useState(cloneDeep(callflow));

  const handleSave = () => {
    setCallflow({ ...editingCallflow });
    onClose(); // already done for some reason when setCallflow?
  };

  return (
    <Dialog open fullWidth>
      <DialogTitle>Incoming Numbers</DialogTitle>
      <Divider />
      <DialogContent>
        <CallflowNumbers
          neverCollapse
          strategyCallflow={editingCallflow}
          setStrategyCallflow={setEditingCallflow}
          modifyPath={modifyPath}
        />
      </DialogContent>
      <Divider />
      <DefaultDialogActions
        // errors={errors}
        saveLabel="Update"
        onSave={handleSave}
        onCancel={onClose}
      />
    </Dialog>
  );
};

const IncomingQuickDisplayNode = memo(props => {
  const { xPos, yPos, data, ...rest } = props;
  const flowStore = useStore();
  const state = flowStore.getState();
  const { zoomTo, setCenter } = useZoomPanHelper();

  const [showDialog, setShowDialog] = useState(null);

  const handleDoubleClick = () => {
    console.log('position:', xPos, yPos, state.width, state.height);
    setCenter(xPos, yPos, 1);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div onClick={e => setShowDialog(true)}>
          <div style={{ textAlign: 'center' }}>
            <ArrowDownwardIcon size="small" />
            <br />
            <Typography variant="body2">Incoming Call</Typography>
          </div>
          <Handle
            type="source"
            position="bottom"
            style={{
              top: 'auto',
              bottom: 4,
              background: '#555',
              visibility: 'hidden',
            }}
          />
        </div>
      </div>
    </>
  );
});

const IncomingCustomInlineDisplayNode = memo(props => {
  const { xPos, yPos, data, ...rest } = props;
  const flowStore = useStore();
  const state = flowStore.getState();
  const { zoomTo, setCenter } = useZoomPanHelper();

  const [showDialog, setShowDialog] = useState(null);

  const handleDoubleClick = () => {
    console.log('position:', xPos, yPos, state.width, state.height);
    setCenter(xPos, yPos, 1);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div onClick={e => setShowDialog(true)}>
          <div style={{ textAlign: 'center' }}>
            <ArrowDownwardIcon size="small" />
            <br />
            <Typography variant="body2">Call from previous</Typography>
          </div>
          <Handle
            type="source"
            position="bottom"
            style={{
              top: 'auto',
              bottom: 4,
              background: '#555',
              visibility: 'hidden',
            }}
          />
        </div>
      </div>
    </>
  );
});

export default IncomingNode;
