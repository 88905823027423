import React, { useCallback, useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Checkbox,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Tooltip,
  Paper,
} from 'app/design';

import { AccountTree as AccountTreeIcon } from 'app/design/icons-material';

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// import { KazooSDK } from '@KazooSDK';
import { getAtPath, setAtPath } from 'app/utilities';

import { useEffectOnce, useAsyncFn } from 'react-use';

// import { AutocompleteRingStrategy } from '../AutocompleteRingStrategy';

// import { Custom } from './Custom';
import { User } from './User';
import { Group } from './Group';
import { Device } from './Device';
// import { UserPreset } from './UserPreset';
// import { Audio } from './Audio';
// import { UserAudioUser } from './UserAudioUser';

export const RING_STRATEGIES = [
  {
    id: 'user',
    name: 'User',
    description: "Ring all a user's devices",
    component: User,
    categories: ['level1', 'account', 'user'],
  },
  {
    id: 'device',
    name: 'Device',
    description: 'Ring a single device',
    component: Device,
    categories: ['level1', 'account', 'user'],
  },
  {
    id: 'group',
    name: 'Group',
    description: 'Following ring strategy for a Group',
    component: Group,
    categories: ['level1', 'account', 'user'],
  },
  // {
  //   id: 'user_preset',
  //   name: 'User Preset',
  //   description: 'Use the Ring Preset for a User',
  //   component: UserPreset,
  //   categories: ['level1', 'account', 'user'],
  // },
  // {
  //   id: 'audio',
  //   name: 'Audio',
  //   description: 'Play a prompt or other audio to the caller',
  //   component: Audio,
  //   categories: ['level1', 'account', 'user'],
  // },
  // {
  //   id: 'user_audio_user',
  //   name: 'User, Audio, User',
  //   description:
  //     'Ring one user, then play a prompt, then ring a different user',
  //   component: UserAudioUser,
  //   categories: ['account', 'user'],
  // },
  // {
  //   id: 'custom',
  //   name: 'Custom',
  //   description: 'Define your own custom ring strategy',
  //   component: Custom,
  //   categories: ['account', 'user'],
  // },
  // // {
  // //   id: 'shared',
  // //   name: 'Shared',
  // //   description: '',
  // //   component: Shared,
  // //   categories: ['account', 'user'],
  // // },
];

export const ringTypeToComponent = type => {
  let elem = RING_STRATEGIES.find(rs => rs.id === type);
  return elem?.component;
};

const RingStrategiesBuilder = props => {
  const {
    skipEditing,
    modifyPath,
    callflow,
    setCallflow,
    filterFunc,
    title,
    variant,
    showModifyDialog,
    setShowModifyDialog,
    defaultRingType = '',
  } = props;

  const dataModifyPath = `${modifyPath}.data`;

  const ring_setup = getAtPath(callflow, dataModifyPath, {
    ring_type: defaultRingType,
    data: {},
  }); // {type, data}

  // console.log('ring_setup:', ring_setup);

  const onChangeRingSetupSelect = newValue => {
    const newData = {
      ring_type: newValue?.value,
      data: {
        // id: 'c00cf3959834a8c8aaadf0966676dbe3',
      },
    };
    setAtPath(callflow, `${dataModifyPath}`, newData); // newValue?.value === id
    setCallflow(
      { ...callflow },
      { name: `Inserted RingStrategy (${newData.ring_type})` },
      false,
      true,
    ); // adds to History! (replaces)
  };

  useEffect(() => {
    if (!ring_setup?.ring_type) {
      onChangeRingSetupSelect({ value: defaultRingType });
    }
  }, [defaultRingType, ring_setup?.ring_type]);

  let RingComponent;
  if (ring_setup?.ring_type) {
    const rs = RING_STRATEGIES.find(rs => rs.id === ring_setup?.ring_type);
    RingComponent = rs?.component;
  }

  const handleClearStrategy = () => {
    onChangeRingSetupSelect(null);
  };

  if (ring_setup?.ring_type && RingComponent) {
    if (variant === 'ivr_builder') {
      return (
        <RingComponent
          skipEditing={skipEditing}
          modifyPath={`${modifyPath}`}
          callflow={callflow}
          setCallflow={setCallflow}
          variant={variant}
          showModifyDialog={showModifyDialog}
          setShowModifyDialog={setShowModifyDialog}
        />
      );
    }

    return (
      <Grid container spacing={3} style={{ marginTop: 0 }}>
        <Grid item style={{ flex: 1 }} zeroMinWidth>
          <RingComponent
            modifyPath={`${modifyPath}`}
            callflow={callflow}
            setCallflow={setCallflow}
          />
        </Grid>
        <Grid item>
          <Tooltip
            title={<>Switch Ring Strategy</>}
            placement="top"
            type="light"
            arrow
          >
            <Button
              variant="contained"
              color="info"
              size="xsmall"
              startIcon={<AccountTreeIcon />}
              disableElevation
              onClick={handleClearStrategy}
            >
              Change Ring
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }

  if (variant == 'ivr_builder') {
    return 'Ring strategy not set';
  }

  return (
    <>
      <BoxesOfStrategies
        modifyPath={`${modifyPath}`}
        callflow={callflow}
        setCallflow={setCallflow}
        filterFunc={filterFunc}
        title={title}
      />
      {/* <AutocompleteRingStrategy
        label="Select Ring Strategy"
        placeholder=""
        defaultValue={ring_setup?.ring_type}
        onChange={onChangeRingSetupSelect}
      /> */}
    </>
  );
};

const BoxesOfStrategies = props => {
  const { title, filterFunc, modifyPath, callflow, setCallflow } = props;

  const dataModifyPath = `${modifyPath}.data`;

  const ring_setup = getAtPath(callflow, dataModifyPath, {
    ring_type: '',
    data: {},
  }); // {type, data}

  const onChangeRingSetupSelect = newValue => () => {
    const newData = {
      ring_type: newValue,
      data: {},
    };
    setAtPath(callflow, `${dataModifyPath}`, newData); // newValue?.value === id
    setCallflow({ ...callflow });
  };

  return (
    <div>
      {title && (
        <Typography variant="h6" bold>
          {title}
        </Typography>
      )}

      {RING_STRATEGIES.filter(filterFunc || (() => true)).map((rs, i) => (
        <Paper
          key={i}
          onClick={onChangeRingSetupSelect(rs.id)}
          elevation={0}
          // elevateOnHover={3}
          padding={1}
          style={{
            margin: '0px 8px 16px',
            cursor: 'pointer',
          }}
        >
          <Grid container spacing={1} alignItems="center">
            {/* <Grid item style={{ textAlign: 'center', width: 40 }}>
              <Telicon name={listItem.icon} />
            </Grid> */}
            <Grid item style={{ flex: 1 }}>
              <Typography variant="body1" bold>
                {rs.name}
              </Typography>
              <Typography variant="subtitle1">{rs.description}</Typography>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </div>
  );
};

export default RingStrategiesBuilder;
