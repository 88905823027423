import { sdk } from 'app/sdk';
import axios from 'axios';
import React from 'react';

const setupFeatureCodesAndNomatch = async ({ auth_token }) => {
  // Create nomatch callflow
  try {
    const callflowResult = await sdk.callflow.mutate.create(
      {
        data: {
          name: 'Outgoing (No Match) Callflow',
          numbers: ['no_match'],
          owner_type: 'account',
          type: 'nomatch',
          strategy: {
            type: 'nomatch',
            data: {},
          },
        },
      },
      {
        authToken: auth_token,
      },
    );
  } catch (err) {
    // ok if this fails (cuz it probably already exists)
    console.error('no match failed create');
  }

  // Create feature codes
  const result = await axios.post(
    `${process.env.REACT_APP_CIO_API_SERVER}/api/v1/feature_codes`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': auth_token,
      },
    },
  );
};

export default setupFeatureCodesAndNomatch;
