import { ExtensionsTableCell } from 'app/components/ExtensionsTableCell';
import { CreateGroupButton } from 'app/components/ListGroups/components/CreateGroupButton';
import { AddMenuButton } from 'app/components/ListMenus/components/AddMenuButton';
import { QuickFinderGenericDialog } from 'app/components/QuickFinderGenericDialog';
import { useListMenusQuery } from 'app/hooks/queries/menu';
import { parseCallflowNumbers } from 'app/utilities';
import {
  QUERY_SEARCH_INPUT_CHANGED,
  useQueryPaginationReducer,
} from 'app/utilities/useQueryPaginationReducer';
import { startCase } from 'lodash';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { QuickFinderDialogProps } from 'types/components';
import { Menu } from 'types/menu';

const QuickFinderMenuDialog = ({
  onSelect,
  mutationLoading = false,
  mutationLoadingLabel,
  errorMessage,
  onCancel,
  multiple = false,
  initialSelected,
  selectionTitle,
  exclude,
  allowCreate,
}: QuickFinderDialogProps) => {
  // query pagination state
  const [{ queryPageSize, queryPageIndex, querySearchInput }, dispatch] =
    useQueryPaginationReducer(0, 5);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (menu: Menu) => menu.doc.name,
      },
    ],
    [],
  );

  // menu list
  const menuListQuery = useListMenusQuery(
    queryPageIndex * queryPageSize,
    queryPageSize,
    [['name', 'asc']],
    { searchInput: querySearchInput },
  );
  const { menus, totalCount = 0 } = menuListQuery.data ?? {};

  const handleSearch = (searchInput: string) => {
    if (searchInput === querySearchInput) {
      menuListQuery.refetch();
    } else {
      dispatch({ type: QUERY_SEARCH_INPUT_CHANGED, payload: searchInput });
    }
  };

  const handleCreate = (menuId: string) => {
    // TODO: does not work with passResource
    onSelect([menuId]);
  };

  return (
    <QuickFinderGenericDialog
      // dialog
      title={multiple ? 'Select Multiple Menus' : 'Select Menu'}
      mutationLoading={mutationLoading}
      mutationLoadingLabel={mutationLoadingLabel}
      onSelect={onSelect}
      onCancel={onCancel}
      errorMessage={errorMessage}
      selectionTitle={selectionTitle}
      titleButton={
        allowCreate ? <AddMenuButton onComplete={handleCreate} /> : null
      }
      // table
      getRowId={(row: Menu) => row.id}
      searchPlaceholder={'Search Menus...'}
      totalCount={totalCount}
      columns={columns}
      data={menus ?? []}
      multiple={multiple}
      initialSelected={initialSelected}
      exclude={exclude}
      onSearch={handleSearch}
      // query
      queryIsFetching={menuListQuery.isFetching}
      queryIsLoading={menuListQuery.isLoading}
      queryPageSize={queryPageSize}
      queryPageIndex={queryPageIndex}
      queryPaginationDispatch={dispatch}
    />
  );
};

export default QuickFinderMenuDialog;
