import { IconButton, Tooltip } from 'app/design';
import { Delete as DeleteIcon } from 'app/design/icons-material';

import { getAtPath, setAtPath } from 'app/utilities';
import React, { memo, useRef } from 'react';
import { Handle } from 'react-flow-renderer';

// import useStyles from './styles';
import { useHoverDirty } from 'react-use';

import { InTemplate } from '../common/InTemplate';

const OptionNode = memo(({ data }) => {
  const {
    skipEditing,
    callflow,
    setCallflow,
    modifyPath, // to ".targets"
    targetKey,
    text,
    templateParent,
  } = data;

  const isWide = text.length > 1 ? true : false;

  const optionRef = useRef(null);
  const isHovering = useHoverDirty(optionRef);

  // const classes = useStyles({ mode: isHovering ? 'normal' : 'ignore' });

  const handleRemove = () => {
    const targets = getAtPath(callflow, modifyPath);
    delete targets[targetKey];
    setAtPath(callflow, modifyPath, targets);
    setCallflow({ ...callflow });
  };

  return (
    <div
      ref={optionRef}
      style={{
        display: 'flex',
        justifyContent: 'center',
        // border: '1px solid #ddd',
        // borderRadius: 4,
      }}
    >
      <div
        style={{
          position: 'relative',
          width: isWide ? 80 : 30,
          height: 30,
          borderRadius: isWide ? 3 : 15,
          border: '1px solid #ddd',
          background: 'rgb(254, 255, 255)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Handle
          type="target"
          position="top"
          style={{ top: 4, background: '#555', visibility: 'hidden' }}
          onConnect={params => console.log('handle onConnect', params)}
        />
        <div style={{ position: 'relative', textAlign: 'center' }}>
          <strong>{text}</strong>
          {skipEditing ? null : (
            <span
              // className={classes.opacity}
              style={{ position: 'absolute', top: -18, left: -25 }}
            >
              <Tooltip
                title={<>Remove this option (and all its descendents)</>}
                placement="bottom"
                type="light"
                arrow
              >
                <IconButton
                  onClick={handleRemove}
                  size="small"
                  variant="outlined"
                  color="secondary"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </span>
          )}
        </div>
        <Handle
          type="source"
          position="bottom"
          style={{
            top: 'auto',
            bottom: 4,
            background: '#555',
            visibility: 'hidden',
          }}
        />
        {templateParent ? <InTemplate template={templateParent} /> : ''}
      </div>
    </div>
  );
});

export default OptionNode;
