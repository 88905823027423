import React, { useCallback, useState, useRef } from 'react';

import {
  Grid,
  TextField,
  Autocomplete,
  Typography,
  Tooltip,
  InputAdornment,
  CircularProgress,
  Popper,
  useMediaQuery,
} from 'app/design';
import {
  Search as SearchIcon,
  FileOpen as FileOpenIcon,
  Bolt as BoltIcon,
  PhoneIphone as PhoneIphoneIcon,
  Person as PersonIcon,
  Group as GroupIcon,
  ContentCopy as ContentCopyIcon,
  AltRoute as AltRouteIcon,
} from 'app/design/icons-material';

// import { FeatureCodesDrawer } from '../../AdminRouting/FeatureCodesDrawer';
// import { OperatorExtensionDrawer } from '../../AdminRouting/OperatorExtensionDrawer';
// import { DialogAddDevice } from '../DialogAddDevice';
// import { DialogAddDirectory } from '../DialogAddDirectory';
// import { DialogAddGroup } from '../DialogAddGroup';
// import DialogAddIvr from '../DialogAddIvr/dialogAddIvr';
// import { DialogAddScreenpop } from '../DialogAddScreenpop';
// import { DialogAddTemplate } from '../DialogAddTemplate';
// import { DialogAddUser } from '../DialogAddUser';
// import { DialogBuyNumbers } from '../DialogBuyNumbers';
// import { DialogFaxboxCreate } from '../DialogFaxboxCreate';
// import { DialogVmboxCreate } from '../DialogVmboxCreate';

import { matchSorter } from 'match-sorter';
import { useHistory } from 'react-router-dom';

import { useOmniQuery } from 'app/hooks/queries/omni';
import { useTheme } from 'app/design';
import BuyPhoneNumberDialog from '../BuyPhoneNumberDialog/BuyPhoneNumberDialog';
import { CallRoutingDialog } from '../CallRoutingDialog';
import { FeatureCodesDrawer } from '../FeatureCodesDrawer';
import { GroupCreateDialog } from '../GroupCreateDialog';
import { TemplateTypeChooser } from '../ListTemplates/components/CreateTemplateButton/CreateTemplateButton';
import { VmboxCreateDialog } from '../VmboxCreateDialog';
import prepareOmniSearchState from './data';
import { useSelector } from 'react-redux';
import { useDebounce } from 'react-use';

// Select users from list of users
export const OmniSearchWidget = props => {
  const [input, setInput] = useState('');
  const inputRef = useRef();
  const {
    actions,
    // allCallflows,
    // conferences,
    // devices,
    // directories,
    // extensions,
    // faxboxes,
    // groups,
    // ivrMenus,
    // medias,
    // menus,
    // phoneNumbers,
    // schedules,
    // screenpops,
    // templates,
    // users,
    // vmboxes,
    pages,
  } = useSelector(prepareOmniSearchState);

  // groupsState.groups.filter(
  //   (group) => ['people', 'group_people'].includes(group.type) || !group.type
  // ); // PEOPLE groups (and mixed groups)

  // groupsState.groups.filter((group) =>
  //     ['location', 'office', 'location_office'].includes(group.type)
  //   ); // OFFICE groups

  // groupsState.groups.filter(
  //     (group) => ['group_devices'].includes(group.type) || !group.type
  //   );

  // console.log('devices:', devices);

  const [debouncedInput, setDebouncedInput] = useState(input);
  const [, cancel] = useDebounce(() => setDebouncedInput(input), 250, [input]);
  const {
    data: omniResponse,
    isLoading: omniIsLoading,
    isFetching: omniIsFetching,
    refetch: omniRefetch,
  } = useOmniQuery(debouncedInput);

  // console.log('omniResponse:', omniResponse);

  const history = useHistory();

  // console.log('ptn:', phoneNumbers);

  // TODO: move to Selector
  // label:string, options:[]
  const groupedOptions = [
    omniIsFetching
      ? {
          sortLevel: 9, // going to "10" will count as a "0" until switching to "01" for other sortOrder
          // groupName: 'Searching',
          label: 'for users, devices, etc',
          value: 'searching',
          searchable: [debouncedInput],
          loading: true,
        }
      : null,
    ...(omniResponse?.users?.users ?? []).map(user => ({
      sortLevel: 2,
      groupName: 'Users',
      label: user.extra.fullName,
      value: user.id,
      type: 'user',
      data: user,
      redirect: `/admin/users/view/${user.id}`,
      // redirect: `/user_portal/admin_users?query=id:${user.id}`,
      searchable: [user.extra.fullName],
    })),
    ...(omniResponse?.devices?.devices ?? []).map(device => ({
      sortLevel: 2,
      groupName: 'Devices',
      label: device.doc.name,
      value: device.id,
      type: 'device',
      data: device,
      redirect: `/admin/devices/view/${device.id}`,
      // redirect: `/device_portal/admin_devices?query=id:${device.id}`,
      searchable: [device.doc.name],
    })),
    ...(omniResponse?.groups?.groups ?? []).map(group => ({
      sortLevel: 2,
      groupName: 'Groups',
      label: group.doc.name,
      value: group.id,
      type: 'group',
      data: group,
      redirect: `/admin/groups/view/${group.id}`,
      // redirect: `/group_portal/admin_users?query=id:${user.id}`,
      searchable: [group.doc.name],
    })),
    ...(omniResponse?.callflows?.callflows ?? [])
      .filter(v => v.doc.type !== 'template')
      .map(callflow => ({
        sortLevel: 3,
        groupName: 'Callflows',
        label: callflow.doc.name,
        value: callflow.id,
        type: 'callflow',
        data: callflow,
        redirect: `/admin/ivr/edit/${callflow.id}`,
        // redirect: `/callflow_portal/admin_users?query=id:${user.id}`,
        searchable: [callflow.doc.name],
      })),
    ...(omniResponse?.callflows?.callflows ?? [])
      .filter(v => v.doc.type === 'template')
      .map(callflow => ({
        sortLevel: 3,
        groupName: 'Templates',
        label: callflow.doc.name,
        value: callflow.id,
        type: 'callflow',
        data: callflow,
        redirect: `/admin/ivr/edit/${callflow.id}`,
        // redirect: `/callflow_portal/admin_users?query=id:${user.id}`,
        searchable: [callflow.doc.name],
      })),

    ...pages.map(page => ({
      sortLevel: 0,
      groupName: 'Pages',
      label: page.name,
      value: page.name,
      type: 'page',
      icon: page.icon,
      data: page,
      redirect: page.link,
      searchable: [...page.searchables, page.name, 'pages'],
    })),
    ...actions.map(action => ({
      sortLevel: 1,
      groupName: 'Actions',
      label: action.name,
      value: action.name,
      icon: action.icon,
      type: 'action',
      data: action,
      redirect: action.link,
      action: action.action,
      searchable: [...action.searchables, action.name, 'actions'],
    })),

    // {
    //   label: 'Directories',
    //   options: directories.map(directory => ({
    //     label: directory.doc.name,
    //     value: directory.id,
    //     type: 'directory',
    //     data: directory,
    //     redirect: `/user_portal/admin_directories/view/${directory.id}`,
    //     searchable: [directory.doc.name, 'directories', 'directory'],
    //   })),
    // },
    // {
    //   label: 'Conferences',
    //   options: conferences.map(conferences => ({
    //     label: conferences.doc.name,
    //     value: conferences.id,
    //     type: 'conferences',
    //     data: conferences,
    //     redirect: `/user_portal/admin_conferences/view/${conferences.id}`,
    //     searchable: [conferences.doc.name, 'conference', 'conferences'],
    //   })),
    // },
    // {
    //   label: 'Groups',
    //   options: groups.map(group => ({
    //     label: group.doc.name,
    //     value: group.id,
    //     type: 'group_people',
    //     data: group,
    //     redirect: `/user_portal/admin_groups/view/${group.id}`,
    //     // redirect: `/user_portal/admin_departments?query=id:${group.id}`,
    //     searchable: [group.doc.name, 'groups'],
    //   })),
    // },
    // // {
    // //   label: 'Locations',
    // //   options: locations.map((group) => ({
    // //     label: group.name,
    // //     value: group.id,
    // //     type: 'location',
    // //     data: group,
    // //     redirect: `/user_portal/admin_locations?query=id:${group.id}`,
    // //     searchable: [group.name],
    // //   })),
    // // },
    // {
    //   label: 'Devices',
    //   options: devices.map(device => ({
    //     label: device.doc.name,
    //     value: device.id,
    //     type: 'device',
    //     data: device,
    //     redirect: `/user_portal/admin_device/view/${device.id}`,
    //     // redirect: `/user_portal/admin_devices?query=id:${device.id}`,
    //     searchable: [device.doc.name, 'devices'],
    //   })),
    // },
    // {
    //   label: 'Phone Numbers',
    //   options: phoneNumbers.map(number => ({
    //     label: number.number,
    //     value: number.number,
    //     type: 'number',
    //     data: number,
    //     redirect: `/user_portal/admin_numbers?query=${number.number.substring(
    //       1,
    //     )}`,
    //     searchable: [number.number, 'phone numbers'],
    //   })),
    // },
    // {
    //   label: 'Extensions',
    //   options: extensions.map(extension => ({
    //     label: extension.extension,
    //     value: extension.extension,
    //     type: 'extension',
    //     data: extension,
    //     redirect: `/user_portal/admin_extensions?query=${extension.extension}`,
    //     searchable: [extension.extension, 'extensions'],
    //   })),
    // },
    // {
    //   label: 'Voicemail Boxes',
    //   options: vmboxes.map(vmbox => ({
    //     label: vmbox.doc.name,
    //     value: vmbox.id,
    //     type: 'vmbox',
    //     data: vmbox,
    //     redirect: `/user_portal/admin_boxes/vmbox/view/${vmbox.id}`,
    //     searchable: [vmbox.doc.name, 'voicemail boxes', 'vm boxes'],
    //   })),
    // },
    // // {
    // //   label: 'Fax Boxes',
    // //   options: faxboxes.map((faxbox) => ({
    // //     label: faxbox.doc.name,
    // //     value: faxbox.id,
    // //     type: 'faxbox',
    // //     data: faxbox,
    // //     redirect: `/user_portal/admin_boxes/faxbox/view/${faxbox.id}`,
    // //     searchable: [faxbox.doc.name, 'fax boxes'],
    // //   })),
    // // },
    // {
    //   label: 'IVR Menus',
    //   options: ivrMenus.map(ivrMenu => ({
    //     label: ivrMenu.doc.name,
    //     value: ivrMenu.id,
    //     type: 'ivrMenu',
    //     data: ivrMenu,
    //     redirect: `/user_portal/admin_ivr_extensions?query=${ivrMenu.doc.name}`,
    //     searchable: [ivrMenu.doc.name, 'IVR menus'],
    //   })),
    // },
    // {
    //   label: 'Media',
    //   options: medias.map(media => ({
    //     label: media.doc.name,
    //     value: media.id,
    //     type: 'media',
    //     data: media,
    //     redirect: `/user_portal/admin_media?query=${media.doc.name}`,
    //     searchable: [media.doc.name, 'media'],
    //   })),
    // },
    // {
    //   label: 'Schedules',
    //   options: schedules.map(schedule => ({
    //     label: schedule.doc.name,
    //     value: schedule.id,
    //     type: 'schedule',
    //     data: schedule,
    //     redirect: `/user_portal/admin_schedules?query=${schedule.doc.name}`,
    //     searchable: [schedule.doc.name, 'schedules'],
    //   })),
    // },
    // {
    //   label: 'Screenpops',
    //   options: screenpops.map(screenpop => ({
    //     label: screenpop.doc.name,
    //     value: screenpop.id,
    //     type: 'screenpop',
    //     data: screenpop,
    //     redirect: `/user_portal/admin_screenpops?query=${screenpop.doc.name}`,
    //     searchable: [screenpop.doc.name, 'screenpops'],
    //   })),
    // },
    // {
    //   label: 'Templates',
    //   options: templates.map(template => ({
    //     label: template.doc.name,
    //     value: template.id,
    //     type: 'template',
    //     data: template,
    //     redirect: `/user_portal/admin_callflow_templates?query=${template.doc.name}`,
    //     searchable: [template.doc.name, 'templates'],
    //   })),
    // },
    // {
    //   label: 'Menus',
    //   options: menus.map(menu => ({
    //     label: menu.doc.name,
    //     value: menu.id,
    //     type: 'menu',
    //     data: menu,
    //     redirect: `/user_portal/admin_menus?query=${menu.doc.name}`,
    //     searchable: [menu.doc.name, 'menus'],
    //   })),
    // },
    // {
    //   label: 'All Callflows',
    //   options: allCallflows.map(callflow => ({
    //     label: callflow.doc.name,
    //     value: callflow.id,
    //     type: 'callflow',
    //     data: callflow,
    //     redirect: `/user_portal/admin_all_callflows?query=${callflow.doc.name}`,
    //     searchable: [callflow.doc.name, 'all callflows'],
    //   })),
    // },
  ].filter(opt => !!opt);

  const filterOptions = (options, { inputValue }) => {
    // show most popular actions/pages if no search value
    if (!inputValue) {
      return options.filter(option =>
        [
          'Dashboard',
          'Users',
          'New User',
          'Settings',
          'Buy Numbers',
          'Import Users (Slack)',
        ].includes(option.value),
      );
    }

    return matchSorter(options, inputValue, {
      keys: ['label', 'searchable'],
    }).sort(
      (a, b) =>
        -[b.sortLevel, b.groupName]
          .join('')
          .localeCompare([a.sortLevel, a.groupName].join('')),
    );
  };

  // const defaultOption = options.find((opt) => opt.value === defaultValue);

  const [isFocusSearch, setIsFocusSearch] = useState(false);
  const setFocusWrap = val => () => setIsFocusSearch(val);
  // const { sync } = useSetupHook();
  const [showAction, setShowAction] = useState(null);
  // console.log('defaultOption:', defaultOption);
  const handleSelect = (e, value) => {
    inputRef.current.blur();
    if (!value) {
      return;
    }
    if (value && value.redirect) {
      history.push(value.redirect);
    } else if (value?.action) {
      switch (value.action) {
        case 'new user':
          // setShowAction(() => () => (
          //   <DialogAddUser
          //     // key={`d_${showDialogAddUser}`}
          //     open={true}
          //     onClose={user => {
          //       setShowAction(null);

          //       if (user?.id) {
          //         history.push(`/user_portal/admin_users/view/${user.id}`);
          //       }
          //     }}
          //     dialogProps={{}}
          //   />
          // ));
          break;
        case 'new directory':
          // setShowAction(() => () => (
          //   <DialogAddDirectory
          //     // key={`d_${showDialogAddUser}`}
          //     open={true}
          //     onClose={directory => {
          //       setShowAction(null);

          //       if (directory?.id) {
          //         history.push(
          //           `/user_portal/admin_users/edit?type=directory&id=${directory.id}`,
          //         );
          //       }
          //     }}
          //     dialogProps={{}}
          //   />
          // ));
          break;
        case 'add_group':
          setShowAction(() => () => (
            <GroupCreateDialog
              // userId={user.id}
              onComplete={() => setShowAction(null)}
              onCancel={() => setShowAction(null)}
              navigateOnComplete
            />
          ));
          break;
        case 'new device':
          // setShowAction(() => () => (
          //   <DialogAddDevice
          //     // key={`d_${showDialogAddDevice}`}
          //     open={true}
          //     onDone={device => {
          //       if (device?.id)
          //         history.push(`/user_portal/admin_device/view/${device.id}`);
          //     }}
          //     onClose={() => {
          //       setShowAction(false);
          //     }}
          //     dialogProps={{}}
          //   />
          // ));
          break;
        case 'add_call_route':
          setShowAction(() => () => (
            <CallRoutingDialog
              open
              name_type="general"
              // owner_type="device"
              type="general"
              // resourceId={device.id}
              defaultNumbers={[]}
              // callflow={mainCallflow}
              onComplete={() => {
                setShowAction(null);
              }}
              onCancel={() => {
                setShowAction(null);
              }}
            />
          ));
          break;
        case 'buy_numbers':
          setShowAction(() => () => (
            <BuyPhoneNumberDialog
              open
              onComplete={() => {
                setShowAction(null);
              }}
              onCancel={() => {
                setShowAction(null);
              }}
            />
          ));
          break;
        case 'feature_codes':
          setShowAction(() => () => (
            <FeatureCodesDrawer onClose={() => setShowAction(false)} />
          ));
          break;
        case 'operator extension':
          // setShowAction(() => () => (
          //   <OperatorExtensionDrawer onClose={() => setShowAction(false)} />
          // ));
          break;
        case 'add_vmbox':
          setShowAction(() => () => (
            <VmboxCreateDialog
              open
              formDefaults={{
                // owner_id: user.id,
                // owner_type: 'user',
                name: `Voicemail Box`,
                mailbox: '0', // next available vmbox number
              }}
              onComplete={() => {
                setShowAction(null);
              }}
              onCancel={() => {
                setShowAction(null);
              }}
              navigateOnComplete
            />
          ));
          break;
        case 'new faxbox':
          // setShowAction(() => () => (
          //   <DialogFaxboxCreate
          //     // key={`d_${showDialogAddUser}`}
          //     open={true}
          //     onClose={faxbox => {
          //       setShowAction(null);

          //       if (faxbox?.id) {
          //         history.push(
          //           `/user_portal/admin_boxes/faxbox/view/${faxbox.id}`,
          //         );
          //       }
          //     }}
          //     dialogProps={{}}
          //   />
          // ));
          break;
        case 'new screenpop':
          // setShowAction(() => () => (
          //   <DialogAddScreenpop
          //     // key={`d_${showDialogAddUser}`}
          //     open={true}
          //     onClose={async screenpop => {
          //       setShowAction(null);
          //       await sync(['callflows']);
          //       if (screenpop?.id) {
          //         history.push(
          //           `/user_portal/admin_screenpops/edit/${screenpop.id}`,
          //         );
          //       }
          //     }}
          //     dialogProps={{}}
          //   />
          // ));
          break;
        case 'add_template':
          setShowAction(() => () => (
            <TemplateTypeChooser
              onComplete={() => {
                setShowAction(null);
              }}
              onCancel={() => {
                setShowAction(null);
              }}
              onClose={() => {
                setShowAction(null);
              }}
            />
          ));
          break;

        default:
          setShowAction(null);
          break;
      }
    } else {
      console.error('Invalid option chosen');
    }
  };

  const handleInputChange = (e, v, reason) => {
    if (reason === 'reset' && v?.length > 0) {
      return false;
    }
    setInput(v);
  };

  const theme = useTheme();
  const miniSearch = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {showAction && showAction()}
      <div data-tour-is-quicksearch>
        <Autocomplete
          sx={{
            // TODO: force popper to be 400px?
            // width: input?.length || isFocusSearch ? '400px' : '200px',
            width: miniSearch ? '155px' : '310px',
            transition: 'all 0.2s ease-in-out',
          }}
          // PopperComponent={props => (
          //   <Popper
          //     {...props}
          //     placement={'bottom-start'}
          //     sx={{ minWidth: '400px', maxWidth: '400px', width: '400px' }}
          //   />
          // )}
          // multiple
          // openOnFocus
          groupBy={option => option.groupName}
          renderGroup={params => {
            return (
              <>
                <Grid container columnSpacing={1} alignItems={'center'}>
                  <Grid item sx={{ pt: '5px' }}>
                    <OmniIcon type={params.group} />
                  </Grid>
                  <Grid item>
                    <Typography sx={{ fontWeight: 700 }}>
                      {`${params.group ?? ''} ${input ? '' : '(Most popular)'}`}
                    </Typography>
                  </Grid>
                </Grid>
                {params.children}
              </>
            );
          }}
          // disablePortal
          autoHighlight
          size="small"
          open={isFocusSearch}
          // open
          getOptionLabel={option => option.label}
          filterOptions={filterOptions}
          options={groupedOptions}
          value={null}
          onChange={handleSelect}
          inputValue={input}
          onInputChange={handleInputChange}
          clearOnBlur={false}
          selectOnFocus={false}
          // loading={omniIsFetching}
          renderOption={(props, option) => {
            if (option.loading) {
              return (
                <li key={option.value}>
                  <Typography
                    variant="body2"
                    sx={{ textAlign: 'center', padding: 2 }}
                  >
                    Searching names of users, devices, etc...
                  </Typography>
                </li>
              );
            }

            return (
              <li {...props} key={option.value}>
                <Grid container alignItems={'center'} columnSpacing={1}>
                  <Grid item sx={{ pt: '5px' }}>
                    {option.icon ?? ''}
                  </Grid>
                  <Grid item>{option.label}</Grid>
                </Grid>
              </li>
            );
          }}
          renderInput={params => {
            return (
              <TextField
                {...params}
                inputRef={inputRef}
                variant="outlined"
                // label={props.hasOwnProperty('label') ? props.label : 'Conference'}
                placeholder={
                  isFocusSearch
                    ? 'Search for users, pages, actions etc. '
                    : 'Quick Search'
                }
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <React.Fragment>
                      {omniIsFetching ? (
                        <CircularProgress color="white" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                onFocus={setFocusWrap(true)}
                onBlur={setFocusWrap(false)}
              />
            );
          }}
        />
        {/* <Select
          placeholder={
            isFocusSearch ? (
              <div
                style={{
                  textOverflow: 'ellipsis',
                  width: '100%',
                  whiteSpace: 'nowrap',
                }}
              >
                Search for users, groups, devices, phone numbers, etc.
              </div>
            ) : (
              <div
                style={{
                  textOverflow: 'ellipsis',
                  width: '100%',
                  whiteSpace: 'nowrap',
                }}
              >
                Quick Search
              </div>
            )
          }
          options={groupedOptions}
          components={{
            Placeholder,
            NoOptionsMessage,
          }}
          filterOption={filterOptions}
          // options={options}
          // value={defaultOption}
          onFocus={() => setIsFocusSearch(true)}
          onBlur={() => setIsFocusSearch(false)}
          // styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          // menuPortalTarget={document.body}
          styles={{
            menuPortal: base => ({
              ...base,
              zIndex: 9999,
            }),
          }}
          value={null}
          menuPortalTarget={document.body}
          onChange={handleSelect}
          defaultMenuIsOpen={false}
          backspaceRemovesValue
          isClearable
        /> */}
      </div>
    </>
  );
};

const OmniIcon = ({ type }) => {
  switch (type) {
    case 'Pages':
      return <FileOpenIcon />;
    case 'Actions':
      return <BoltIcon />;
    case 'Users':
      return <PersonIcon />;
    case 'Devices':
      return <PhoneIphoneIcon />;
    case 'Groups':
      return <GroupIcon />;
    case 'Callflows':
      return <AltRouteIcon />;
    case 'Templates':
      return <ContentCopyIcon />;
    default:
      return null;
  }
};

export default OmniSearchWidget;
