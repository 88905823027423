import { Dialog, DialogTitle, Divider, Grid, Typography } from 'app/design';
import { DialogCircularProgress } from 'app/components/DialogCircularProgress';
import * as React from 'react';
import { ReactNode } from 'react';

interface QuickFinderDialogShellProps {
  title: string;
  children: ReactNode;
  loading: boolean;
  loadingLabel: string;
  selectionTitle?: string | null;
  titleButton?: ReactNode;
}

const QuickFinderDialogShell = ({
  children,
  loading,
  loadingLabel,
  title,
  selectionTitle,
  titleButton,
}: QuickFinderDialogShellProps) => {
  return (
    <Dialog open={true} fullWidth maxWidth={'sm'}>
      {loading ? (
        <DialogCircularProgress label={loadingLabel} />
      ) : (
        <>
          <DialogTitle>
            <Grid
              container
              spacing={1}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Grid item>{title}</Grid>
              {selectionTitle !== undefined ? (
                <Grid item>
                  <Typography variant={'subtitle1'}>
                    Currently Selected: {selectionTitle ?? 'none'}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
            {titleButton ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                }}
              >
                {titleButton}
              </div>
            ) : null}
          </DialogTitle>
          <Divider />
          {children}
        </>
      )}
    </Dialog>
  );
};

export default QuickFinderDialogShell;
