import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { localSaga } from './saga';
import { LocalState } from './types';

export const initialState: LocalState = {
  pressed: false,
  sidebar_key: '',
  sidebar_mini: null, // uses default, if not forced
  nav_breadcrumbs: [],
  search_in_page_focus: false,
  search_in_page_options: [],
  search_in_page_options_currently_shown_in_list: [], // the output/matches of the filter
  search_in_page_option_highlighted: null, // the currently-highlighted value
  poke_holes: [],
  webphone_visible: false,
  channels: [],
};

const slice = createSlice({
  name: 'local',
  initialState,
  reducers: {
    someAction(state, action: PayloadAction<any>) {
      state.pressed = !state.pressed;
    },
    set_sidebar_key(state, action: PayloadAction<any>) {
      state.sidebar_key = action.payload;
    },
    set_sidebar_mini(state, action: PayloadAction<any>) {
      state.sidebar_mini = action.payload;
    },
    set_nav_breadcrumbs(state, action: PayloadAction<any>) {
      state.nav_breadcrumbs = [action.payload, ...state.nav_breadcrumbs];
    },
    unregister_nav_breadcrumbs(state, action: PayloadAction<any>) {
      state.nav_breadcrumbs = state.nav_breadcrumbs.filter(
        v => !action.payload,
      );
    },
    set_search_in_page_option_highlighted(state, action: PayloadAction<any>) {
      state.search_in_page_option_highlighted = action.payload;
    },
    add_search_in_page_options(state, action: PayloadAction<any>) {
      state.search_in_page_options = state.search_in_page_options.concat(
        action.payload,
      );
    },
    remove_search_in_page_options(state, action: PayloadAction<any>) {
      state.search_in_page_options = state.search_in_page_options.filter(
        opt => opt.id !== action.payload,
      );
    },
    add_poke_holes(state, action: PayloadAction<any>) {
      state.poke_holes = state.poke_holes.concat(action.payload);
    },
    remove_poke_holes(state, action: PayloadAction<any>) {
      state.poke_holes = state.poke_holes.filter(
        opt => opt.id !== action.payload,
      );
    },
    set_search_in_page_focus(state, action: PayloadAction<any>) {
      state.search_in_page_focus = action.payload;
    },
    set_search_in_page_options_currently_shown_in_list(
      state,
      action: PayloadAction<any>,
    ) {
      state.search_in_page_options_currently_shown_in_list = action.payload;
    },
    set_webphone_visible(state, action: PayloadAction<any>) {
      state.webphone_visible = action.payload;
    },
    set_channels(state, action: PayloadAction<any>) {
      state.channels = action.payload;
    },
  },
  extraReducers: {
    'auth/logout': (state, action) => {
      return {
        ...initialState,
      };
    },
  },
});

export default slice.reducer;

export const { actions, reducer } = slice;

export const useLocalSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: localSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useLocalSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
