import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYesterday,
  set,
  setHours,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYesterday,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';
import { TimeRange } from 'types/generic';

export const getCurrentTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getTimeRange = (timeRange: TimeRange, businessHours?: boolean) => {
  let start, end;
  switch (timeRange) {
    case TimeRange.Today:
      start = startOfDay(Date.now()).getTime();
      // needs to be end of day for concurrent chart, not just current time
      end = endOfDay(Date.now()).getTime();

      break;
    case TimeRange.Yesterday:
      start = startOfYesterday().getTime();
      end = endOfYesterday().getTime();
      break;
    case TimeRange.ThisWeek:
      start = startOfWeek(Date.now()).getTime();
      end = endOfWeek(Date.now()).getTime();

      break;
    case TimeRange.LastWeek:
      start = startOfWeek(subWeeks(Date.now(), 1)).getTime();
      end = endOfWeek(subWeeks(Date.now(), 1)).getTime();

      break;
    case TimeRange.ThisDayLastWeek:
      start = startOfDay(subWeeks(Date.now(), 1)).getTime();
      end = endOfDay(subWeeks(Date.now(), 1)).getTime();

      break;
    case TimeRange.ThisMonth:
      start = startOfMonth(Date.now()).getTime();
      end = endOfMonth(Date.now()).getTime();

      break;
    case TimeRange.LastMonth:
      start = startOfMonth(subMonths(Date.now(), 1)).getTime();
      end = endOfMonth(subMonths(Date.now(), 1)).getTime();

      break;
    case TimeRange.TwoMonthsAgo:
      start = startOfMonth(subMonths(Date.now(), 2)).getTime();
      end = endOfMonth(subMonths(Date.now(), 2)).getTime();

      break;
    case TimeRange.Last90Days:
      start = startOfDay(subDays(Date.now(), 90)).getTime();
      end = Date.now();

      break;
    default:
      throw new Error('Invalid time range specified');
  }

  if (businessHours) {
    start = set(start, { hours: 7, minutes: 0, seconds: 0 }).getTime();
    end = set(end, { hours: 19, minutes: 0, seconds: 0 }).getTime();
  }

  return {
    start,
    end,
  };
};

const format = number => `0${number}`.slice(-2);

export const durationFormat = totalSeconds => {
  if (!totalSeconds) {
    return '00:00:00';
  }
  const seconds = totalSeconds % 60;
  const totalMinutes = Math.floor(totalSeconds / 60);
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);
  return `${Math.floor(hours)}:${format(Math.floor(minutes))}:${format(
    Math.floor(seconds),
  )}`;
};
