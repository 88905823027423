import { ScheduleCreateForm } from 'app/components/ScheduleCreateDialog/ScheduleEditorDialog';
import {
  ScheduleTemplate,
  SCHEDULE_TEMPLATES,
} from 'app/components/ScheduleCreateDialog/scheduleTemplates';
import { SchedulePlateEditor } from 'app/components/SchedulePlateEditor';
import { cloneDeep } from 'lodash';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Tab,
  Tabs,
  Typography,
  Divider,
} from 'app/design';

interface ScheduleTemplateSelectProps {}

const ScheduleTemplateSelect = ({}: ScheduleTemplateSelectProps) => {
  const { control, setValue, watch } = useFormContext<ScheduleCreateForm>();
  const templateWatch = watch('template');
  return (
    <>
      <Typography variant={'h6'} gutterBottom>
        Select the schedule template that most closely aligns with your business
      </Typography>
      <Typography variant={'body1'} paragraph>
        You can easily modify these categories, times, days, etc later!
      </Typography>
      <Controller
        control={control}
        name={'template'}
        render={({ field: { onChange, value, ...props } }) => {
          // @ts-ignore
          console.log('value', value, SCHEDULE_TEMPLATES[value]);
          return (
            <>
              <Tabs
                value={value}
                onChange={(e, value) => {
                  // @ts-ignore
                  setValue('times', cloneDeep(SCHEDULE_TEMPLATES[value].value));
                  onChange(value);
                }}
              >
                <Tab label={'Restaurant'} value={ScheduleTemplate.Restaurant} />
                <Tab
                  label={'Dental Office'}
                  value={ScheduleTemplate.DentalOffice}
                />
              </Tabs>
              <Divider />
            </>
          );
        }}
      />
      <br />
      <Typography
        variant={'subtitle'}
        style={{ fontStyle: 'italic' }}
        color={'gray.main'}
      >
        {/* @ts-ignore */}
        {SCHEDULE_TEMPLATES[templateWatch].description}
      </Typography>
      <br />
      <br />
      <Grid
        container
        direction={'row'}
        // sx={{ width: 500 }}
        rowSpacing={2}
        columnSpacing={2}
        // alignContent={'center'}
      >
        {/*@ts-ignore */}
        {SCHEDULE_TEMPLATES[templateWatch].value.map(time => {
          if (time.isElse) {
            return (
              <Grid item xs={6}>
                <Card key={time.id} variant={'outlined'}>
                  <CardHeader title={time.name} />
                  <CardContent>
                    <Typography variant={'caption'}>
                      {'Closed hours'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          }
          return (
            <Grid item xs={6}>
              <Card key={time.id} variant={'outlined'}>
                <CardHeader title={time.name} />
                <CardContent>
                  <SchedulePlateEditor
                    id={`select_${time.id}_${templateWatch}`}
                    initialValue={time.rule?.data.value}
                    readOnly
                  />
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <br />
      <Typography
        variant={'body1'}
        color={'gray.main'}
        style={{ fontStyle: 'italic' }}
      >
        * Remember: this is just a starting point! You will be able to adjust
        the hours/dates easily
      </Typography>
    </>
  );
};

export default ScheduleTemplateSelect;
