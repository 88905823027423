import React, { memo, useState, useEffect, useLayoutEffect } from 'react';
import {
  Handle,
  useZoomPanHelper,
  useStore,
  useStoreState,
  useStoreActions,
} from 'react-flow-renderer';
import {
  Box as VlBox,
  IconButton,
  Grow,
  Tooltip,
  Typography,
} from 'app/design';
import { Phone as PhoneIcon } from 'app/design/icons-material';
// import useStyles from './styles';

import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { useLocalSelector } from 'app/data/local';
import useComponentSize from '@rehooks/component-size';

import { css, keyframes } from '@emotion/react';

const Box = ({ pref, position, data, borderColor, children }) => {
  const { elementId, rootElements, setRootElements } = data;

  // see if a call is currently happening on this callflow
  const { channels } = useLocalSelector();
  const channels_found = channels?.filter(
    ch =>
      ch.listing['callflow_id'] == data.callflow.id &&
      // match any "start path" (works for menus, schedules, branch_callflows)
      Object.keys(ch.listing.custom_application_vars).filter(k =>
        k.startsWith(`path_${data?.modifyPath}`),
      ).length,
    // exact match:
    // ch.listing.custom_application_vars[`path_${data?.modifyPath}`],
  );

  const flowStore = useStore();
  const { nodes, edges, ...rest } = flowStore.getState();
  // console.log('rest:', rest);
  // const transform = useStoreState((store) => store.transform);
  // const setSelectedElements = useStoreActions(
  //   (actions) => actions.setSelectedElements
  // );
  const actions = useStoreActions(actions1 => actions1);
  const { setElements } = actions;

  const { width, height } = useComponentSize(pref);
  useEffect(() => {
    // console.log('component changed:', width, height);
  }, [width, height]);

  // const classes = useStyles({
  //   borderColor: borderColor,
  //   // height: height,
  // });
  const classes = {};

  const { zoomTo, setCenter } = useZoomPanHelper();

  const handleDoubleClick = () => {
    console.log('position:', position);
    position && setCenter(position.x, position.y, 1);
    // // zoomTo(1);
  };

  return (
    <VlBox
      sx={{
        position: 'relative',
        width: width || '150px',
        // height: (props) => height || 62,
        transition: 'box-shadow 0.3s',
        border: '1px solid #ddd',
        borderColor: borderColor,
        borderRadius: '4px',
        background: '#fff',
        boxShadow: '0px 2px 10px rgba(72, 148, 252, 0.2)',
        '&:hover': {
          // background: '#f3f3f3',
          boxShadow: '0px 2px 10px rgba(72, 148, 252, 0.6)',
        },
      }}
      ref={pref}
      onDoubleClick={handleDoubleClick}
    >
      {children}
      {channels_found?.length ? (
        <VlBox
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            animation: `${PulseBackgroundKeyframe} 3.5s`,
            animationFillMode: 'forwards',
            // boxShadow: '0px 0px 20px rgb(239, 83, 80, 1.0)',
          }}
        >
          <Tooltip
            title={
              <VlBox>
                <Typography variant="h3">Incoming Callers:</Typography>
                {channels_found.map(ch => (
                  <Typography key={ch.id} variant="body1">
                    {ch.listing.custom_channel_vars['E164-Origination'] ? (
                      <PhoneNumberDisplay
                        ptn={ch.listing.custom_channel_vars['E164-Origination']}
                        allowExtension
                      />
                    ) : (
                      'Internal Caller (but we are unable to determine who is calling...??)'
                    )}
                  </Typography>
                ))}
              </VlBox>
            }
          >
            <Grow in timeout={2000}>
              <VlBox
                sx={{
                  position: 'absolute',
                  top: -0,
                  left: -48,
                  width: 40,
                  height: 40,
                  display: 'grid',
                  placeItems: 'center',
                  borderRadius: '50%',
                  background: 'rgb(239, 83, 80, 1.0)',
                }}
              >
                <PhoneIcon sx={{ color: 'white' }} />
              </VlBox>
            </Grow>
          </Tooltip>
        </VlBox>
      ) : null}
    </VlBox>
  );
};

const PulseBackgroundKeyframe = keyframes`
  0% {
    box-shadow: 0px 0px 0px rgb(239, 83, 80, 0.0)
  }
  40% {
    box-shadow: 0px 0px 20px rgb(239, 83, 80, 1.0)
  }
  50% {
    box-shadow: 0px 0px 20px rgb(239, 83, 80, 1.0)
  }
  60% {
    box-shadow: 0px 0px 20px rgb(239, 83, 80, 1.0)
  }
  100% {
    box-shadow: 0px 0px 20px rgb(239, 83, 80, 1.0)
  }
`;

export default Box;
