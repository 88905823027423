import { EmptyTableCell } from 'app/components/EmptyTableCell';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { ContactDisplay } from 'app/components/Webphone/UI/ContactDisplay';
import { useLookupContact } from 'app/data/webphone/webphoneSlice';
import React from 'react';
import { CdrInteraction } from 'types/callDetailRecord';

interface FromCellProps {
  interaction: CdrInteraction;
}

const FromCell = ({ interaction }: FromCellProps) => {
  const { name, number, userId } = interaction.doc.from;
  const [contact] = useLookupContact(number);

  // TODO: handle
  if (!number) {
    return <EmptyTableCell label={'unknown'} />;
  }

  return (
    <div>
      {contact ? (
        <ContactDisplay contact={contact} />
      ) : name !== number ? (
        name
      ) : (
        <EmptyTableCell label={'no caller ID'} />
      )}

      {/* @ts-ignore */}
      <PhoneNumberDisplay ptn={number} allowExtension />
      {/*{userId ? (*/}
      {/*  <div>*/}
      {/*    <OwnerLinkChip id={userId} size="xsmall" />*/}
      {/*  </div>*/}
      {/*) : null}*/}
    </div>
  );
};

export default FromCell;
