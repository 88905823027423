import { Grid, InfoTooltip, Typography } from 'app/design';
import * as React from 'react';
import { ReactNode } from 'react';

interface GenericSettingsTemplateProps {
  title: ReactNode;
  caption: ReactNode;
  children: ReactNode;
}

const GenericSettingsSwitchTemplate = ({
  title,
  caption,
  children,
}: GenericSettingsTemplateProps) => {
  return (
    <Grid container wrap={'nowrap'} justifyContent="space-between">
      <Grid item>
        <Typography variant="h4">
          {title} <InfoTooltip title={'placeholder'} size={'small'} />
        </Typography>
        <Typography variant="caption">{caption}</Typography>
      </Grid>

      <Grid item>{children}</Grid>
    </Grid>
  );
};

export default GenericSettingsSwitchTemplate;
