import React, { useState, useContext, useEffect, useRef, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Box,
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  IconButton,
  Tooltip,
} from 'app/design';
import {
  Phone as PhoneIcon,
  NoteAdd as NoteAddIcon,
} from 'app/design/icons-material';

// import { getAtPath, setAtPath } from 'app/utilities';

import { IvrMenuEventEmitterContext } from '../../..';

// import { USER_ADDABLE_COMPONENTS } from '../../../../Strategies/base/GenericDefault';
// import * as OptionComponents from '../../../../Strategies/components';

// import { useSetupHook } from '../../../../SetupHook';
// import {
//   SmallMediaPlayer,
//   SingleMedia,
//   ChangeAudioDialog,
//   ModifyDialog as ModifyDialogAudio,
//   builtinAudio,
// } from '../../../../SingleMedia';

// import { Box as IvrBox } from '../common/Box';

import { getAtPath, setAtPath } from 'app/utilities';

import { useHoverDirty } from 'react-use';

// import store from '../../../../../../../../store';

const NoteNode = memo(({ data, ...rest }) => {
  const {
    insertBefore, // bool
    insertAfterData,
    moduleItem,
    callflow,
    setCallflow,
    modifyPath,
  } = data;

  const boxRef = useRef(null);
  const isHovering1 = useHoverDirty(boxRef);

  const hoverRef = useRef(null);
  const isHovering2 = useHoverDirty(hoverRef);

  const isHoveringEither = isHovering1 || isHovering2 ? true : false;

  // // action for onCreate
  // const ee = useContext(IvrMenuEventEmitterContext);
  // const onNodeCreated = (data) => {
  //   if (data.optsPath === modifyPath) {
  //     setShowModifyDialog(true);
  //   }
  // };
  // useEffect(() => {
  //   ee.on('node-created', onNodeCreated);
  //   return () => {
  //     ee.removeListener('node-created', onNodeCreated);
  //   };
  // }, []);

  const handleAddNote = () => {
    // use same as InsertAbove (ie, push stuff down, insert a NoteNode here)
    const noteText = prompt('Note:', '');
    if (noteText === null || !noteText?.length) {
      // cancel
      return false;
    }
    const { callflow, setCallflow, modifyPath } = insertAfterData;
    // console.log('cc:', modifyPath, callflow);
    const data = {
      type: 'Note',
      data: {
        text: noteText.toString(),
        options: {},
      },
    };
    const index = insertAfterData.index - 1;
    const stratModules = getAtPath(
      callflow,
      `${modifyPath}.strategy.data.modules`,
    );
    stratModules.splice(index, 0, data);
    setAtPath(callflow, `${modifyPath}.strategy.data.modules`, stratModules);
    setCallflow({ ...callflow });
    // // TODO: focus after rebuild, instead of on a Timeout
    // window.setTimeout(() => {
    //   ee.emit('node-created', {
    //     key: `${modifyPath}.strategy.config.components[${index}]`,
    //     optsPath: `${modifyPath}.strategy.data.opts[${index}]`,
    //   });
    // }, 100);
  };

  const handleEditNote = () => {
    const noteText = prompt('Note:', moduleItem?.data?.text);
    if (noteText === null) {
      // cancel
      return false;
    }
    // empty = delete note!
    if (!noteText?.length) {
      // delete
      const { index, callflow, setCallflow, modifyPath } = insertAfterData;
      const stratDataModules = getAtPath(
        callflow,
        `${modifyPath}.strategy.data.modules`,
      );
      // index is from the insertAfterData!! (ie, we need to -1 to remove from the correct place)
      stratDataModules.splice(index - 1, 1);
      setAtPath(
        callflow,
        `${modifyPath}.strategy.data.modules`,
        stratDataModules,
      );
      setCallflow({ ...callflow });
    } else {
      // modify
      moduleItem.data.text = noteText;
      setAtPath(callflow, modifyPath, moduleItem);
      setCallflow({ ...callflow });
    }
  };

  return (
    <>
      {!moduleItem?.data ? (
        <Tooltip title={<>Add a Note</>} placement="bottom" type="light" arrow>
          <Box
            ref={boxRef}
            sx={{
              display: 'inline-block',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <Box
              ref={hoverRef}
              sx={{
                // background: isHoveringEither ? 'red' : 'none',
                position: 'absolute',
                top: -18,
                left: -12,
                width: 'calc(100% + 24px)',
                height: 'calc(100% + 36px)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                opacity: isHoveringEither ? 1 : 0,
              }}
            >
              <IconButton
                size={'medium'}
                variant="outlined"
                style={{
                  opacity: 0.5,
                  marginLeft2: isHoveringEither ? -16 : 0,
                }}
                onClick={handleAddNote}
              >
                <NoteAddIcon />
              </IconButton>
            </Box>
            <IconButton
              // pref={boxRef}
              position={data?.position}
              // height={100}
              // borderColor="#FFEEB0"
              size={isHoveringEither ? 'small' : 'small'}
              variant="outlined"
              sx={{
                opacity: isHoveringEither ? 0 : 0.2,
                marginLeft2: isHoveringEither ? -16 : 0,
              }}
              onClick={handleAddNote}
            >
              <Handle
                type="target"
                position="top"
                style={{ background: '#555', visibility: 'hidden' }}
              />
              <NoteAddIcon />
              <Handle
                type="source"
                position="bottom"
                style={{
                  top: 'auto',
                  bottom: 1,
                  background: '#555',
                  visibility: 'hidden',
                }}
              />
            </IconButton>
          </Box>
        </Tooltip>
      ) : (
        <Box sx={{ position: 'relative' }}>
          <Handle
            type="target"
            position="top"
            style={{ background: '#555', visibility: 'hidden' }}
          />
          <Box
            onClick={handleEditNote}
            sx={{
              cursor: 'pointer',
              background: '#FFEEB0',
              padding: 1,
              borderRadius: 1,
              border: '1px solid #eaeaea',
              boxShadow: 'rgb(90 71 2 / 20%) 0px 2px 10px 0px',
            }}
          >
            <Tooltip
              title={<>Click to Modify Note</>}
              placement="bottom"
              type="light"
              arrow
            >
              <div style={{ maxWidth: 180 }}>{moduleItem?.data?.text}</div>
            </Tooltip>
          </Box>
          <Handle
            type="source"
            position="bottom"
            style={{
              top: 'auto',
              bottom: 1,
              background: '#555',
              visibility: 'hidden',
            }}
          />
        </Box>
      )}
    </>
  );
});

export default NoteNode;
