import { Box, Button, Grid, Typography, useTheme } from 'app/design';

import {
  Phone as PhoneIcon,
  Backspace as BackspaceIcon,
} from 'app/design/icons-material';
import React from 'react';

// import { useSetupHook } from '../../SetupHook';

const Numpad = props => {
  const { onClick, onCall, disableCall, light, onDelete } = props;
  const nums = [1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#'];
  const chars = [
    '',
    'abc',
    'def',
    'ghi',
    'jkl',
    'mno',
    'pqrs',
    'tuv',
    'wxyz',
    '',
    '',
    '',
  ];

  const handleClick = num => e => {
    onClick(num);
  };

  const theme = useTheme();

  return (
    <div
      style={{
        padding: 20,
        width: '100%',
        background: light ? undefined : '#285E70',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <Grid
        container
        spacing={1}
        justifyContent={'space-between'}
        sx={{ width: '100%' }}
      >
        {nums.map((num, i) => (
          <Grid
            key={num}
            item
            xs={4}
            sx={{
              // background: 'green',
              display: 'grid',
              placeItems: 'center',
            }}
          >
            {/* <span style={numButtonStyle} onClick={handleClick(num)}> */}
            <Button
              onClick={handleClick(num)}
              variant={'outlined'}
              sx={{
                height: 75,
                width: '100%',
                // background: 'red',
                // borderRadius: 90,
                borderColor: '#285E70',
                display: 'grid',
                placeItems: 'center',
              }}
            >
              <Box
                sx={{
                  textAlign: 'center',
                  color: light ? '#285E70' : '#ffffff',
                  height: 40,
                }}
              >
                <Typography color={'inherit'} variant={'h1'}>
                  {num}
                </Typography>
                <Typography color={'inherit'} variant={'body1'}>
                  {chars[i]}
                </Typography>
              </Box>
            </Button>
            {/* </span> */}
          </Grid>
        ))}
      </Grid>
      <br />
      <Box sx={{ width: '80%' }}>
        <Grid container alignItems={'center'} columnSpacing={4}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            {onCall ? (
              <Button
                variant={'contained'}
                sx={{ borderRadius: 5 }}
                color={'success'}
                disabled={disableCall}
                size={'large'}
                fullWidth
                startIcon={<PhoneIcon />}
                onClick={onCall}
              >
                Call
              </Button>
            ) : null}
          </Grid>
          <Grid item xs={3}>
            {onDelete ? (
              <Button
                variant={'contained'}
                sx={{ borderRadius: 5 }}
                color={'error'}
                disabled={disableCall}
                size={'large'}
                fullWidth
                onClick={onDelete}
              >
                <BackspaceIcon />
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </div>
  );

  // return (
  //   <div style={{ padding: 4 }}>
  //     <Grid container spacing={1}>
  //       {nums.map((num, i) => (
  //         <Grid key={num} item xs={4} onClick={handleClick(num)}>
  //           {/* <span style={numButtonStyle} onClick={handleClick(num)}> */}
  //           <Box
  //             sx={{
  //               position: 'relative',
  //               borderRadius: 2,
  //               // background: 'rgb(236, 244, 254)',
  //               // background: theme.palette.info.light,
  //               background: 'rgb(227, 252, 255)', // e3fcff
  //               textAlign: 'center',
  //               fontSize: 18,
  //               fontWeight: 'bold',
  //               '&:hover': {
  //                 background: theme.palette.primary.main, //'rgb(200, 222, 254)',
  //               },
  //               '&:active': {
  //                 background: theme.palette.primary.dark, //'rgb(72, 148, 252)',
  //               },
  //               padding: '8px',
  //               cursor: 'pointer',
  //             }}
  //           >
  //             {num}
  //             <Box
  //               sx={{
  //                 position: 'absolute',
  //                 display: 'inline',
  //                 verticalAlign: 'super',
  //                 fontSize: 12,
  //                 fontWeight: 200,
  //                 paddingLeft: 1,
  //               }}
  //             >
  //               {chars[i] ?? ''}
  //             </Box>
  //           </Box>
  //           {/* </span> */}
  //         </Grid>
  //       ))}
  //     </Grid>
  //
  //   </div>
  // );
};

export default Numpad;
