import {
  Button,
  Grid,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableRow,
  Typography,
} from 'app/design';
import { AudioPlayer } from 'app/components/AudioPlayer';
import React, { useEffect, useState } from 'react';
import { MediaType } from 'types/media';

export const BUILT_IN_AUDIO = [
  {
    name: 'Easy Morning',
    link: 'https://objective-liskov-9272da.netlify.app/morning-with-coffee-loop.mp3',
  },
  {
    name: 'Exciting Mystery',
    link: 'https://objective-liskov-9272da.netlify.app/the-power-inside.mp3',
  },
];

const BuiltInAudio = ({ register, setValue }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(
    () =>
      register(`${MediaType.BuiltIn}.link`, {
        value: BUILT_IN_AUDIO[0].link,
      }),
    [register],
  );

  const handleChange = event => {
    const index = event.target.value;
    setSelectedIndex(index);
    setValue(`${MediaType.BuiltIn}.link`, BUILT_IN_AUDIO[index].link);
  };

  return (
    <Grid container spacing={1} alignItems={'center'} justifyContent={'center'}>
      <Grid item sm={6}>
        <Select value={selectedIndex} onChange={handleChange}>
          {BUILT_IN_AUDIO.map((audio, index) => (
            <MenuItem key={audio.name} value={index}>
              {audio.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item sm={6}>
        <AudioPlayer mediaId={BUILT_IN_AUDIO[selectedIndex].link} />
      </Grid>
    </Grid>
  );
};

export default BuiltInAudio;
