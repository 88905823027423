import { getAtPath } from 'app/utilities';
import { startCase } from 'lodash';
import * as React from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { TextField, StandardTextFieldProps } from 'app/design';

interface HookFormTextFieldProps extends StandardTextFieldProps {
  name: string;
  label?: string;
  errorMessageParser?: (error: any) => string;
  options?: RegisterOptions;
}

const HookFormTextField = ({
  name,
  label,
  errorMessageParser,
  options,
  ...props
}: HookFormTextFieldProps) => {
  const {
    register,
    formState: { errors: formErrors },
  } = useFormContext();

  const error = getAtPath(formErrors, name);

  const errorMessage = error
    ? errorMessageParser
      ? errorMessageParser(error)
      : error.message
    : undefined;

  return (
    <TextField
      label={label}
      {...props}
      {...register(name, options)}
      error={!!errorMessage}
      helperText={errorMessage?.replace(`"${name}"`, startCase(name))}
    />
  );
};

export default HookFormTextField;
