import React, { useEffect } from 'react';
import { getAtPath, setAtPath } from 'app/utilities';
import { createStateContext } from 'react-use';

import { useQueryClient } from 'react-query';
import { useAccountQuery } from 'app/hooks/queries/account';
import keyvalueQueryKeys from 'app/hooks/queries/keyvalue/keyvalueQueryKeys';
import { useKeyValuesQuery } from 'app/hooks/queries/keyvalue';
import { useKeyValueWrite } from 'app/hooks/mutations/keyvalue';
import { useAuthSelector, useAuthSlice } from 'app/data/auth';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { AppLoadingScreen } from 'app/components/AppLoadingScreen';

// these defaults are overridden by the fetched settings for an account/user
// - key/value, NOT a JS object
// - (ie can have "x = true" AND "x.y = {test:'1'}" AND "x.y.test = 'not 1'")
export const [useHintsState, HintProviderRaw] = createStateContext({});

export const useHint = name => {
  const { account_id, owner_id } = useAuthSelector();
  const queryClient = useQueryClient();
  const keyValueWrite = useKeyValueWrite();
  const [hints] = useHintsState();
  const hintKey = `account_id.${account_id}.user_id.${owner_id}.hints.${name}`;
  const hintVal = hints[hintKey]; // "." delimiter (NOT a "/" slash) // TODO: memoize
  const queryKey = keyvalueQueryKeys.bySubject('hints');

  const refetch = async () => {
    await queryClient.refetchQueries(queryKey);
  };

  const setValue = async value => {
    try {
      const currentData = queryClient.getQueryData(queryKey);
      currentData.data = [...currentData.data, { key: hintKey, value }];
      queryClient.setQueryData(queryKey, { ...currentData });
    } catch (err) {
      console.info('Failed setToTrue');
    }
    await keyValueWrite.mutateAsync({
      data: {
        subject: 'hints',
        data: {
          key: name,
          value,
        },
      },
    });
    // refetch();
    queryClient.invalidateQueries(queryKey);
  };

  const setToTrue = async () => setValue(true);

  // console.log('hintval:', name, hintVal);
  return [hintVal, setToTrue, refetch, setValue];
};

export const useHints = names => {
  // console.log('names:', names);
  const { account_id, owner_id } = useAuthSelector();
  const queryClient = useQueryClient();
  const keyValueWrite = useKeyValueWrite();
  const [allHints] = useHintsState();
  const hints = {};
  for (let name of names) {
    const hintKey = `account_id.${account_id}.user_id.${owner_id}.hints.${name}`;
    const hintVal = allHints[hintKey]; // "." delimiter (NOT a "/" slash) // TODO: memoize
    hints[name] = hintVal;
  }
  const queryKey = keyvalueQueryKeys.bySubject('hints');

  const refetchHints = async () => {
    await queryClient.refetchQueries(queryKey);
  };

  const setHintVal = async (name, value) => {
    // console.log('setToTrue:');
    // set immediately in querycache (TODO: optimistic in useMutation?)
    const currentData = queryClient.getQueryData(queryKey);
    const hintKey = `account_id.${account_id}.user_id.${owner_id}.hints.${name}`;
    currentData.data = [...currentData.data, { key: hintKey, value }]; // TODO: replace if exists
    queryClient.setQueryData(queryKey, { ...currentData });
    await keyValueWrite.mutateAsync({
      data: {
        subject: 'hints',
        data: {
          key: name,
          value: true,
        },
      },
    });
    // refetch();
    queryClient.invalidateQueries(queryKey);
  };

  // console.log('hintval:', name, hintVal);
  return {
    hints,
    setHintVal,
    refetchHints,
  };
};

const HintBuilder = ({ children }) => {
  const [, setHints] = useHintsState();

  // fetch account for available features
  // - integrations enable features?
  // const { data: account, isLoading: accountIsLoading } = useAccountQuery();
  const {
    data: hintKeyValues,
    dataUpdatedAt,
    isLoading,
    isFetching,
    refetch,
  } = useKeyValuesQuery('hints');
  // console.log('dataUpdatedAt:', dataUpdatedAt, hintKeyValues);

  useEffect(() => {
    if (!hintKeyValues?.data) {
      return;
    }
    // build hints
    const obj = {};
    for (let kv of hintKeyValues.data) {
      obj[kv.key] = kv.value;
    }
    // console.log('all hints:', obj);
    setHints(obj);
  }, [dataUpdatedAt]);

  if (isLoading) {
    return <AppLoadingScreen />;
  }
  return children;
};

// const updateFlag = (key, value, obj) => {
//   const flag = obj[key];
//   if (flag) {
//     flag.value = value;
//   } else {
//     // MUST exist?
//     obj[key] = {
//       value: value,
//     };
//   }
// };

export const HintProvider = ({ children }) => (
  <HintProviderRaw>
    <HintBuilder>{children}</HintBuilder>
  </HintProviderRaw>
);
