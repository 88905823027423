import { CALL_DIRECTION_OUTGOING } from '@nicholasareed/react-sip';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  InfoTooltip,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Slider,
  Tooltip,
  Typography,
} from 'app/design';
import {
  CallEnd as CallEndIcon,
  CallMerge as CallMergeIcon,
  Dialpad as DialpadIcon,
  FastForward as FastForwardIcon,
  LocalParking as LocalParkingIcon,
  Mic as MuteIcon,
  MicOff as UnMuteIcon,
  Notes as NotesIcon,
  Pause as PauseIcon,
  Phone as PhoneIcon,
  PhoneCallback as PhoneCallbackIcon,
  PhoneForwarded as PhoneForwardedIcon,
  PhonePausedOutlined as CallOnHoldIcon,
  TransferWithinAStation as TransferWithinAStationIcon,
  Videocam as VideocamIcon,
  VolumeDown as VolumeUpIcon,
  VolumeMute as VolumeOffIcon,
  VolumeOff as AmplifyOffIcon,
  VolumeUp as AmplifyOnIcon,
} from 'app/design/icons-material';

import { useWebphoneSlice } from 'app/data/webphone';

import { sleep, validIpAddress } from 'app/utilities';

import { compact, flatten } from 'lodash';

// import { useSetupHook } from './';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

import prettyMs from 'pretty-ms';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useInterval } from 'react-use';
import { useLookupContact } from '../../../../../data/webphone/webphoneSlice';
import { useCallsParkedQuery } from '../../../../../hooks/queries/calls';
import { PhoneNumberDisplay } from '../../../../PhoneNumberDisplay';
import { useWebphoneContext } from '../../../Store';
import { ContactDisplay } from '../../ContactDisplay';
import { Numpad } from '../../Numpad';
import { Dialer } from './Dialer';
import { NotesEditor } from './NotesEditor';
import { Notifications } from './Notifications';
import { Screenpops } from './Screenpops';
import useStyles from './styles';
// import Menu from 'material-ui-popup-state/HoverMenu'; // use for HoverMenu!

const CallInfo = (props, context) => {
  const { call } = props;
  const dispatchRedux = useDispatch();
  const { actions } = useWebphoneSlice();
  const [contact] = useLookupContact(call.remoteUser);
  const [state, dispatch] = useWebphoneContext();
  const { calls, getSpeakerVolume, setSpeakerVolume } = state;

  const disposition = call.getDisposition();

  const [callState, setCallState] = useState({
    showNotes: false,
    showKeypad: false,
  });

  // 'idle'
  // 'dialing'
  // 'ringing'
  // 'progress'
  // 'active'
  // 'local hold'

  let bgStatus = 'gray';
  // let bgStatus = '#edc585';
  let bgColor = 'none';
  let borderColor = 'none';
  let rowColor = 'white',
    rowFull = false; // TODO: dark mode

  // status background
  switch (disposition) {
    case 'dialing':
    case 'progress':
      rowColor = 'primary';
      break;
    case 'ringing':
      rowColor = '#A5D6A7';
      bgStatus = 'error';
      break;
    case 'local hold':
      rowColor = '#FFCC80';
      bgStatus = 'warning';
      break;
    case 'active':
      rowFull = true;
      rowColor = '#F1F8E9';
      bgStatus = 'success';
    case 'idle':
    default:
      break;
  }
  // if (disposition === 'active') {
  //   bgStatus = 'success';
  //   // bgStatus = '#32a852';
  // } else if (
  //   disposition === 'ringing' ||
  //   disposition === 'dialing' ||
  //   disposition === 'progress'
  // ) {
  //   bgStatus = 'primary';
  //   // bgStatus = '#4f99e3';
  // }
  // if active
  if (call.isMediaActive() || call.isEstablishing()) {
    bgColor = '#e0e0e0';
  }
  const callEventHandler = (event, params) => {
    const { localVideoRef, remoteVideoRef } = props;
    switch (event) {
      case 'input.stream.opened':
      case 'input.stream.modified':
        if (params.video) {
          const stream = call.getInputMediaStream();
          localVideoRef.current.srcObject = stream;
        }
        break;
      case 'output.stream.opened':
      case 'output.stream.modified':
        if (params.video) {
          const stream = call.getOutputMediaStream();
          remoteVideoRef.current.srcObject = stream;
        }
    }
  };
  const onStopCall = () => {
    if (call.isRinging()) {
      call.reject();
    } else {
      call.hangup();
    }
  };
  const onAcceptCall = hasVideo => {
    // Auto Hold
    // Hold active call
    const activeCalls = calls.filter(
      item => item.isActive() && item.isOnLocalHold() === false,
    );
    activeCalls.forEach(activeCall => {
      activeCall.hold();
    });
    const startAudio = true,
      startVideo = hasVideo;
    call.accept(startAudio, startVideo, callEventHandler); // accepting BOTH audio and video??
  };
  const handleUnhold = () => {
    //  only 1 active call allowed (hold any other active calls if we're unholding this line)
    // if (call.isOnLocalHold()) {
    const activeCalls = calls.filter(
      item => item.isActive() && item.isOnLocalHold() === false,
    );
    activeCalls.forEach(activeCall => {
      activeCall.hold();
    });
    call.unhold();
    // } else {
    //   call.hold();
    // }
  };

  // // Ringing/Audio
  // useEffect(() => {
  //   // start ringing
  //   if (call.isRinging()) {

  //   }

  // },[])

  // handle conferences dial-in
  const runOnce = useRef(null);
  useEffect(() => {
    const opts = call.getAdditionalInfo();
    // Mute, wait for first audio "Please enter conference number", then send DTMF tones to join conference
    if (opts?.conference) {
      if (call.isActive()) {
        if (runOnce.current) {
          console.log('already run once');
          return;
        }
        runOnce.current = true;

        // // console.log('volume off (does NOT work)');
        // setSpeakerVolume(1);

        // try to send dtmf tones (do it until we fail!)
        // - try 100 times max? exponential backoff?
        (async () => {
          await sleep(6000); // wait 2 seconds before trying at all
          call.sendDTMF(`${opts.number}#`);
          // // todo: send PIN also if necessary?

          // for (let i = 0; i < 100; i++) {
          //   try {
          //     console.log('sending dtmf');
          //     call.sendDTMF(`${opts.number}#`);
          //     break;
          //   } catch (err) {
          //     console.log('not ready for dtmf yet');
          //     await sleep(100);
          //   }
          // }
          // console.log('volume reset, joined conference!');
          // setSpeakerVolume(100); // reset speaker volume
        })();
      }
    }
  }, [call.isActive()]);

  // handle call ending
  // - saving important data to history
  const runOnceEnding = useRef(null);
  useEffect(() => {
    // TODO: should be updating the CallHistory every time the call changes!
    // - otherwise a page refresh will result in NO ENTRY in the call history!

    // const opts = call.getAdditionalInfo();
    // // Mute, wait for first audio "Please enter conference number", then send DTMF tones to join conference
    // if (opts?.conference) {
    // }
    return () => {
      if (call._endType) {
        // const { call_history } = store.getState().webphone;

        console.log('call ended:', call._notes);
        const newCallEntry = {
          id: call.getId(),
          direction:
            call._direction === CALL_DIRECTION_OUTGOING
              ? 'outgoing'
              : 'incoming',
          remoteName: call.remoteName,
          remoteUser: call.remoteUser,
          startTime: call.startTime || new Date().toString(),
          endTime: call.endTime || new Date().toString(),
          endType: call._endType,
          errorReason: call._errorReason,
          additionalInfo: call._additionalInfo,
          screenpops: call._screenpops || [],
          notes: call._notes || { type: 'text', data: '' }, // markdown?
        };

        dispatchRedux(actions.add_to_call_history(newCallEntry));
      }
    };
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      <Grid container alignItems={'center'} columnSpacing={2} wrap={'nowrap'}>
        {call.isRinging() ? null : (
          <Grid item>
            {call._direction === CALL_DIRECTION_OUTGOING ? (
              <PhoneForwardedIcon color={'primary'} />
            ) : (
              <PhoneCallbackIcon color={'primary'} />
            )}
          </Grid>
        )}
        <Grid item sx={{ flex: 1 }}>
          {contact ? (
            <ContactDisplay contact={contact} number={call.remoteUser} />
          ) : (
            <Grid
              container
              alignItems="center"
              wrap={'nowrap'}
              columnSpacing={2}
            >
              <Grid item>
                <Avatar sx={{ width: 40, height: 40 }} />
              </Grid>
              <Grid item sx={{ flex: 1 }}>
                <Grid container columnSpacing={1}>
                  <Grid item>
                    <Typography
                      sx={{
                        display: 'inline-block',
                        fontWeight: 700,
                        fontSize: '16px',
                      }}
                    >
                      <span>
                        {call.remoteName === call.remoteUser.replace('+', '')
                          ? 'No Caller ID'
                          : call.remoteName}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Typography variant="body1">
                  <PhoneNumberDisplay
                    size="small"
                    allowExtensions
                    ptn={call.remoteUser}
                    width="auto"
                  />
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        {call.isRinging() && call.startTime ? null : (
          <Grid item xs={2}>
            <Typography sx={{ color: '#285E70' }}>
              <CallTimer call={call} />
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Grid container alignItems={'center'} columnSpacing={2}>
            {call.isRinging() && (
              <>
                <Grid item style={{ flex: 1 }}>
                  <Button
                    size="small"
                    variant="contained"
                    color="success"
                    sx={{ borderRadius: 10 }}
                    fullWidth
                    // startIcon={<PhoneIcon />}
                    onClick={e => onAcceptCall(false)}
                  >
                    <PhoneIcon />
                    {/*Answer*/}
                  </Button>
                </Grid>
              </>
            )}
            {/* {call.isOnLocalHold() && (
              <Grid item style={{ flex: 1 }}>
                <Button
                  size="small"
                  variant="contained"
                  fullWidth
                  sx={{ borderRadius: 10 }}
                  // startIcon={
                  //   call.isOnLocalHold() ? (
                  //     <CallOnHoldIcon style={{ color: '#732222' }} />
                  //   ) : (
                  //     <PauseIcon />
                  //   )
                  // }
                  onClick={handleUnhold}
                >
                  {call.isOnLocalHold() ? <CallOnHoldIcon /> : <PauseIcon />}
                  Unhold
                </Button>
              </Grid>
            )}*/}
            <Grid item style={{ flex: 1 }}>
              <Button
                size="small"
                color="error"
                variant="contained"
                fullWidth
                sx={{ borderRadius: 10 }}
                // startIcon={<CallEndIcon />}
                onClick={onStopCall}
              >
                <CallEndIcon />
                {/*End*/}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: '100%',
          justifyContent: 'right',
          mt: 2,
        }}
      >
        <Grid
          container
          columnSpacing={1}
          alignItems={'center'}
          wrap={'nowrap'}
          style={{ width: '100%' }}
        >
          <Grid item sx={{ flex: 1 }}>
            <Chip
              label={disposition}
              color={bgStatus}
              size="small"
              variant="contained"
            />
          </Grid>

          <Grid item>
            <MuteMic call={call} />
          </Grid>
          {/* <SpeakerVolume call={call} /> */}
          <Grid item>
            <LocalHold call={call} />
          </Grid>
          <Grid item>
            <Park call={call} />
          </Grid>
          <Grid item>
            <BlindTransfer call={call} />
          </Grid>
          {/* <AttendedTransfer call={call} /> */}
          {/* <Merge call={call} /> */}
          <Grid item>
            <Dialpad
              call={call}
              callState={callState}
              setCallState={setCallState}
            />
          </Grid>
          <Grid item>
            <NotesButton
              call={call}
              callState={callState}
              setCallState={setCallState}
            />
          </Grid>
          {/*
          <Grid item>
            {!call.hasLocalVideo() ? (
              //  && !call.hasRemoteVideo()
              <Video call={call} />
            ) : (
              ''
            )}
          </Grid>
*/}
          {/* <SpeakerAmplifier call={call} /> */}
        </Grid>
      </Box>
      <NotesEditor
        call={call}
        callState={callState}
        setCallState={setCallState}
        showNotes={callState.showNotes}
        onUpdate={notes => {
          call._notes = notes;
        }}
      />
      <Keypad
        call={call}
        callState={callState}
        setCallState={setCallState}
        show={callState.showKeypad}
      />
      <Screenpops
        call={call}
        onUpdate={screenpops => {
          call._screenpops = screenpops;
        }}
      />
      <Notifications call={call} />
    </Box>
  );

  // return (
  //   <Box
  //     sx={{
  //       background: rowColor
  //         ? rowFull
  //           ? rowColor
  //           : `linear-gradient(to bottom, ${rowColor} 0%,rgba(255,255,255,0) 8px),
  //       linear-gradient(to bottom, rgba(255,255,255,0) calc(100% - 8px),${rowColor} 100%),
  //           linear-gradient(to right, ${rowColor} 0%,rgba(255,255,255,0) 8px),
  //           linear-gradient(to right, rgba(255,255,255,0) calc(100% - 8px),${rowColor} 100%)`
  //         : 'none',
  //       padding: 3,
  //     }}
  //     // style={{ borderLeft: `4px solid ${borderColor}` }}
  //   >
  //     <div style={{ cursor: 'pointer' }}>
  //       <Grid container alignItems={'top'} justify="space-between">
  //         <Grid item style={{ flex: 1 }}>
  //           <div>
  //             <Chip label={disposition} size="small" variant="outlined" />
  //           </div>
  //           <div style={{ margin: '4px 0' }}>
  //             {contact ? (
  //               <ContactDisplay contact={contact} number={call.remoteUser} />
  //             ) : (
  //               <>
  //                 <Typography variant="body1" strong>
  //                   {call.remoteName}
  //                 </Typography>
  //                 <Typography variant="subtitle1">
  //                   {call.remoteName !== call.remoteUser ? call.remoteUser : ''}
  //                 </Typography>
  //               </>
  //             )}
  //           </div>
  //         </Grid>
  //         <Grid item>
  //           <div style={{ padding: 4 }}>
  //             <Grid container spacing={1} noWrap>
  //               {/* {call.isDialing() && (
  //               <>
  //                 <Grid item>
  //                   <Button
  //                     size="small"
  //                     variant="outlined"
  //                     color="info"
  //                     startIcon={<PhoneIcon />}
  //                     onClick={onStopCall}
  //                   >
  //                     Hang Up
  //                   </Button>
  //                 </Grid>
  //               </>
  //             )} */}
  //               {call.isRinging() && (
  //                 <React.Fragment>
  //                   <Grid item style={{ flex: 1 }}>
  //                     <Button
  //                       size="small"
  //                       variant="outlined"
  //                       color="success"
  //                       fullWidth
  //                       startIcon={<PhoneIcon />}
  //                       onClick={e => onAcceptCall(false)}
  //                     >
  //                       Answer
  //                     </Button>
  //                   </Grid>
  //                   <Grid item style={{ flex: 1 }}>
  //                     <Button
  //                       size="small"
  //                       variant="outlined"
  //                       color="success"
  //                       fullWidth
  //                       startIcon={<VideocamIcon />}
  //                       onClick={e => onAcceptCall(true)}
  //                     >
  //                       Video
  //                     </Button>
  //                   </Grid>
  //                 </React.Fragment>
  //               )}
  //               {call.isOnLocalHold() && (
  //                 <Grid item style={{ flex: 1 }}>
  //                   <Button
  //                     size="small"
  //                     variant="outlined"
  //                     fullWidth
  //                     startIcon={
  //                       call.isOnLocalHold() ? (
  //                         <CallOnHoldIcon style={{ color: '#732222' }} />
  //                       ) : (
  //                         <PauseIcon />
  //                       )
  //                     }
  //                     onClick={handleUnhold}
  //                   >
  //                     Unhold
  //                   </Button>
  //                 </Grid>
  //               )}
  //               <Grid item style={{ flex: 1 }}>
  //                 <Button
  //                   size="small"
  //                   color="error"
  //                   variant="outlined"
  //                   fullWidth
  //                   startIcon={<CallEndIcon />}
  //                   onClick={onStopCall}
  //                 >
  //                   End
  //                 </Button>
  //               </Grid>
  //             </Grid>
  //           </div>
  //           <div style={{ padding: 4, textAlign: 'right' }}>
  //             <CallTimer call={call} />
  //             &nbsp;
  //             <CallIP call={call} />
  //           </div>
  //         </Grid>
  //       </Grid>
  //     </div>
  //     <div>
  //       <Grid container noWrap style={{ overflowX: 'auto' }}>
  //         <MuteMic call={call} />
  //         {/* <SpeakerVolume call={call} /> */}
  //         <LocalHold call={call} />
  //         <Park call={call} />
  //         <BlindTransfer call={call} />
  //         {/* <AttendedTransfer call={call} /> */}
  //         {/* <Merge call={call} /> */}
  //         <Dialpad call={call} />
  //         <NotesButton
  //           call={call}
  //           callState={callState}
  //           setCallState={setCallState}
  //         />
  //         {!call.hasLocalVideo() ? (
  //           //  && !call.hasRemoteVideo()
  //           <Video call={call} />
  //         ) : (
  //           ''
  //         )}
  //         {/* <SpeakerAmplifier call={call} /> */}
  //       </Grid>
  //     </div>
  //     <NotesEditor
  //       call={call}
  //       callState={callState}
  //       setCallState={setCallState}
  //       showNotes={callState.showNotes}
  //       onUpdate={notes => {
  //         call._notes = notes;
  //       }}
  //     />
  //     <Screenpops
  //       call={call}
  //       onUpdate={screenpops => {
  //         call._screenpops = screenpops;
  //       }}
  //     />
  //     <Notifications call={call} />
  //   </Box>
  // );
};

const MuteMic = props => {
  const { call } = props;

  const classes = useStyles();

  const handleToggleMute = () => {
    call.toggleAudioMute();
  };

  const isMuted = call.isAudioOnMute();

  return (
    <Tooltip title={'Toggle Microphone Mute'} type="light" arrow>
      <Button
        variant={'outlined'}
        size={'small'}
        color={isMuted ? 'primary' : 'gray'}
        sx={{
          color: isMuted ? undefined : theme => theme.palette.content.color,
        }}
        onClick={handleToggleMute}
      >
        {isMuted ? (
          <UnMuteIcon fontSize="small" />
        ) : (
          <MuteIcon fontSize="small" />
        )}
      </Button>
    </Tooltip>
  );

  return (
    <Grid item>
      <Tooltip title={<>Toggle Microphone Mute</>} type="light" arrow>
        <Box sx={classes.cell} onClick={handleToggleMute}>
          {call.isAudioOnMute() ? (
            <UnMuteIcon fontSize="small" />
          ) : (
            <MuteIcon fontSize="small" />
          )}
        </Box>
      </Tooltip>
    </Grid>
  );
};

const CallDuration = ({ call }) => {
  const startTime = new Date(call.startTime).getTime();
  const endTime = new Date(call.endTime).getTime();

  const duration = endTime - startTime;

  // console.log(
  //   'duration:',
  //   duration,
  //   call.endTime,
  //   call.startTime,
  //   endTime,
  //   startTime
  // );
  if (call.errorReason || !duration) {
    return null;
  }

  // console.log('duration:', duration);
  return (
    <span>
      {prettyMs(duration, { secondsDecimalDigits: 0, colonNotation: true })}
    </span>
  );
};

const LocalHold = props => {
  const { call } = props;

  const classes = useStyles();

  const handleToggleLocalHold = () => {
    call.toggleHold();
  };

  const onHold = call.isOnLocalHold();
  return (
    <Tooltip title={'Toggle Hold'} type="light" arrow>
      <Button
        variant={'outlined'}
        size={'small'}
        color={onHold ? 'primary' : 'gray'}
        sx={{
          color: onHold ? undefined : theme => theme.palette.content.color,
        }}
        onClick={handleToggleLocalHold}
      >
        {onHold ? (
          <CallOnHoldIcon fontSize="small" />
        ) : (
          <PauseIcon fontSize="small" />
        )}
      </Button>
    </Tooltip>
  );

  // return (
  //   <Grid item>
  //     <Box sx={classes.cell} onClick={handleToggleLocalHold}>
  //       <Tooltip title={<>Toggle Hold</>} type="light" arrow>
  //         <span>
  //           {call.isOnLocalHold() ? (
  //             <CallOnHoldIcon fontSize="small" />
  //           ) : (
  //             <PauseIcon fontSize="small" />
  //           )}
  //         </span>
  //       </Tooltip>
  //     </Box>
  //   </Grid>
  // );
};

const Park = props => {
  const { call } = props;

  const classes = useStyles();

  const { data, isFetching, refetch } = useCallsParkedQuery();

  // const parked_calls = data?.callsParked ?? [];

  const parkedCallsDictionary = useMemo(() => {
    const numDictionary = {};
    data?.callsParked.forEach(pc => {
      numDictionary[pc.num] = true;
    });
    return numDictionary;
  }, [data?.callsParked]);

  const handlePark = parkNum => {
    // const parkNum = window.prompt('Park:', '');
    if (!parkNum) {
      return false;
    }
    call.parkCall(`*3${parkNum}`);
    setTimeout(() => {
      call.hangup();
    }, 100); // nick chose a random amount of time until ending our side of the call after parking the other side
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  // console.log('parked', parked_calls);

  return (
    <>
      <Menu
        variant="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem disabled>Select Spot:</MenuItem>
        {[...Array(10).keys()].map(num => {
          const number = num + 1;
          const inUse = parkedCallsDictionary[number];

          return (
            <MenuItem
              key={number}
              value={number}
              label={number}
              onClick={() => handlePark(number)}
              disabled={inUse}
            >
              <Box
                sx={{
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <Typography>{`${
                  inUse ? 'In use - ' : ''
                } ${number}`}</Typography>
              </Box>
            </MenuItem>
          );
        })}
      </Menu>

      <Tooltip title={'Park Call'} type="light" arrow>
        <Button
          variant={'outlined'}
          size={'small'}
          color={'gray'}
          sx={{ color: theme => theme.palette.content.color }}
          onClick={handleClickMenu}
        >
          <LocalParkingIcon fontSize="small" />
        </Button>
      </Tooltip>
    </>
  );

  // return (
  //   <Grid item>
  //     <Box sx={classes.cell} onClick={handlePark}>
  //       <Tooltip title={<>Park Call</>} type="light" arrow>
  //         <span>
  //           <LocalParkingIcon fontSize="small" />
  //         </span>
  //       </Tooltip>
  //     </Box>
  //   </Grid>
  // );
};

const BlindTransfer = props => {
  const { call } = props;

  const classes = useStyles();

  const handleTransfer = () => {
    const destination = window.prompt('Transfer To:', '');
    if (!destination) {
      return false;
    }
    // if (!call.isOnLocalHold()) {
    //   call.hold();
    // }
    call.blindTransfer(destination);
  };

  return (
    <Tooltip title={'Blind Transfer'} type="light" arrow>
      <Button
        variant={'outlined'}
        size={'small'}
        color={'gray'}
        sx={{ color: theme => theme.palette.content.color }}
        onClick={handleTransfer}
      >
        <FastForwardIcon fontSize="small" />
      </Button>
    </Tooltip>
  );

  // return (
  //   <Grid item>
  //     <Box sx={classes.cell} onClick={handleTransfer}>
  //       <Tooltip title={<>Blind Transfer</>} type="light" arrow>
  //         <span>
  //           <FastForwardIcon fontSize="small" />
  //         </span>
  //       </Tooltip>
  //     </Box>
  //   </Grid>
  // );
};

const AttendedTransfer = props => {
  const { call } = props;

  const [state, dispatch] = useWebphoneContext();
  const { calls } = state;

  const classes = useStyles();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `atransfer-${call._id}`,
  });

  const handleTransfer = mergeCall => () => {
    // other call must be on hold!
    if (!mergeCall.isOnLocalHold()) {
      mergeCall.hold();
    }
    call.attendedTransfer(mergeCall);
    popupState.close();
  };

  const otherCalls = calls.filter(tcall =>
    tcall.getId() !== call.getId() ? true : false,
  );

  return (
    <Grid item>
      <div
        className={classes.cell}
        {...bindTrigger(popupState)}
        style={{ opacity: otherCalls.length ? 1 : 0.5 }}
      >
        <Tooltip title={<>Attended Transfer</>} type="light" arrow>
          <span>
            <TransferWithinAStationIcon fontSize="small" />
          </span>
        </Tooltip>
      </div>
      {/* <IconButton size="small" {...bindTrigger(popupState)}>
        <MoreVertIcon />
      </IconButton> */}
      <Menu {...bindMenu(popupState)}>
        {otherCalls.map(ocall => (
          <MenuItem onClick={handleTransfer(ocall)}>
            <ListItemIcon style={{ paddingRight: 4 }}>
              <PhoneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={ocall.remoteUser} />
          </MenuItem>
        ))}
        {!otherCalls.length ? (
          <MenuItem onClick={popupState.close}>
            <ListItemText primary="No calls to transfer to" />
          </MenuItem>
        ) : (
          ''
        )}
      </Menu>
    </Grid>
  );
};

const Merge = props => {
  const { call } = props;

  const [state, dispatch] = useWebphoneContext();
  const { calls } = state;

  const classes = useStyles();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `mergeMenu-${call._id}`,
  });

  const handleMerge = mergeCall => () => {
    // other call must be on hold!
    alert('this does not work correctly yet!');
    // if (!mergeCall.isOnLocalHold()) {
    //   mergeCall.hold();
    // }
    // call.attendedTransfer(mergeCall);
    popupState.close();
  };

  const otherCalls = calls.filter(tcall =>
    tcall.getId() !== call.getId() ? true : false,
  );

  return (
    <Grid item>
      <div
        className={classes.cell}
        {...bindTrigger(popupState)}
        style={{ opacity: otherCalls.length ? 1 : 0.5 }}
      >
        <Tooltip title={<>Merge Calls</>} type="light" arrow>
          <span>
            <CallMergeIcon fontSize="small" />
          </span>
        </Tooltip>
      </div>
      {/* <IconButton size="small" {...bindTrigger(popupState)}>
        <MoreVertIcon />
      </IconButton> */}
      <Menu {...bindMenu(popupState)}>
        {otherCalls.map(ocall => (
          <MenuItem onClick={handleMerge(ocall)}>
            <ListItemIcon style={{ paddingRight: 4 }}>
              <PhoneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={ocall.remoteUser} />
          </MenuItem>
        ))}
        {!otherCalls.length ? (
          <MenuItem onClick={popupState.close}>
            <ListItemText primary="No calls to merge with" />
          </MenuItem>
        ) : (
          ''
        )}
      </Menu>
    </Grid>
  );
};

const NotesButton = props => {
  const { call, callState, setCallState } = props;

  const classes = useStyles({
    bgcolor: call._notes?.data?.length > 0 ? '#FFF8E1' : undefined,
  });

  return (
    <Tooltip title={'Notes'} type="light" arrow>
      <Button
        variant={'outlined'}
        size={'small'}
        color={callState.showNotes ? 'primary' : 'gray'}
        sx={{
          color: callState.showNotes
            ? undefined
            : theme => theme.palette.content.color,
        }}
        onClick={e =>
          setCallState({ showNotes: callState.showNotes ? null : true })
        }
      >
        <NotesIcon fontSize="small" />
      </Button>
    </Tooltip>
  );

  // return (
  //   <Grid item>
  //     <div
  //       className={classes.cell}
  //       onClick={e =>
  //         setCallState({ showNotes: callState.showNotes ? null : true })
  //       }
  //     >
  //       <Tooltip title={<>Notes</>} type="light" arrow>
  //         <span>
  //           <NotesIcon fontSize="small" />
  //         </span>
  //       </Tooltip>
  //     </div>
  //   </Grid>
  // );
};

const Video = props => {
  const { call } = props;

  const classes = useStyles();

  const handleEnableVideo = () => {
    // this should simply enable YOUR video
    call.offerVideo();
  };

  if (call.hasLocalVideo()) {
    return null;
  }

  return (
    <Tooltip title={'Enable Video Call'} type="light" arrow>
      <Button
        variant={'outlined'}
        size={'small'}
        color={'gray'}
        sx={{ color: theme => theme.palette.content.color }}
        onClick={handleEnableVideo}
      >
        <VideocamIcon fontSize="small" />
      </Button>
    </Tooltip>
  );

  // return (
  //   <Grid item>
  //     <Box sx={classes.cell} onClick={handleEnableVideo}>
  //       <Tooltip title={<>Enable Video Call</>} type="light" arrow>
  //         <div style={{ whiteSpace: 'nowrap' }}>
  //           <VideocamIcon fontSize="small" />
  //         </div>
  //       </Tooltip>
  //     </Box>
  //   </Grid>
  // );
};

// const Dialpad = (props) => {
//   const { call } = props;

//   const classes = useStyles();

//   const handleSubmit = (input) => {};

//   <Dialer onSubmit={handleSubmit} />;
//   return (
//     <Grid item>
//       <Box sx={classes.cell}>
//         <DialpadIcon fontSize="small" />
//       </Box>
//     </Grid>
//   );
// };

const Keypad = ({ show, call }) => {
  const handlePress = char => {
    // send dtmf tone for character
    call.sendDTMF(char);
  };

  if (!show) return null;

  return (
    <Box sx={{ pt: 2 }}>
      <Numpad light onClick={handlePress} />
    </Box>
  );
};

const Dialpad = props => {
  const { call, callState, setCallState } = props;

  const [state, dispatch] = useWebphoneContext();
  const { calls } = state;

  const classes = useStyles();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `keypad-${call._id}`,
  });

  const handleSubmit = input => {
    // no submit shown?
    // - dtmf tones sent one-by-one
  };

  return (
    <>
      <Tooltip title={'Show Keypad'} type="light" arrow>
        <Button
          // {...bindTrigger(popupState)}
          variant={'outlined'}
          size={'small'}
          color={callState.showKeypad ? 'primary' : 'gray'}
          sx={{
            color: callState.showKeypad
              ? undefined
              : theme => theme.palette.content.color,
          }}
          onClick={() => {
            setCallState(prev => {
              return {
                ...prev,
                showKeypad: !prev.showKeypad,
              };
            });
          }}
        >
          <DialpadIcon fontSize="small" />
        </Button>
      </Tooltip>
      {/* <Popover {...bindMenu(popupState)}>
        <Dialer onPress={handlePress} onSubmit={handleSubmit} />
      </Popover>*/}
    </>
  );

  return (
    <Grid item>
      <Box sx={classes.cell} {...bindTrigger(popupState)}>
        <Tooltip title={<>Show Dialpad</>} type="light" arrow>
          <span>
            <DialpadIcon fontSize="small" />
          </span>
        </Tooltip>
      </Box>
      {/* <IconButton size="small" {...bindTrigger(popupState)}>
        <MoreVertIcon />
      </IconButton> */}
      {/*<Popover {...bindMenu(popupState)}>*/}
      {/*  <Dialer onPress={handlePress} onSubmit={handleSubmit} />*/}
      {/*</Popover>*/}
    </Grid>
  );
};

const SpeakerVolume = props => {
  const { call } = props;

  const [state, dispatch] = useWebphoneContext();
  const { calls } = state;

  const speakerVol = call.getOutputVolume();
  const [volume, setVolume] = useState(speakerVol * 100);

  const classes = useStyles();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `dialpad-${call._id}`,
  });

  const handleChange = num => {
    const vol = num / 100;
    call.changeOutputVolume(vol);
    setVolume(num);
  };

  return (
    <Grid item>
      <Box sx={classes.cell} {...bindTrigger(popupState)}>
        <Tooltip
          title={
            <>
              Volume of Call (does NOT adjust your overall audio volume, only
              the volume of this single call)
            </>
          }
          type="light"
          arrow
        >
          <div style={{ whiteSpace: 'nowrap' }}>
            {volume === 0 ? (
              <VolumeOffIcon fontSize="small" />
            ) : (
              <VolumeUpIcon fontSize="small" />
            )}{' '}
            <span style={{ verticalAlign: 'top' }}>{volume}%</span>
          </div>
          {/* <div style={{ flex: 1, wrap: 'no-wrap' }}>
            <div>
              {volume === 0 ? (
                <VolumeOffIcon fontSize="small" />
              ) : (
                <VolumeUpIcon fontSize="small" />
              )}
            </div>
            <div>
              <span style={{ verticalAlign: 'top' }}>{volume}%</span>
            </div>
          </div> */}
        </Tooltip>
      </Box>
      {/* <IconButton size="small" {...bindTrigger(popupState)}>
        <MoreVertIcon />
      </IconButton> */}
      <Popover {...bindMenu(popupState)}>
        <div style={{ height: 160, padding: '24px 12px', textAlign: 'center' }}>
          <Slider
            orientation="vertical"
            value={volume}
            onChange={(e, num) => handleChange(num)}
            // max={200} // increase the potential audio after switching to the WebAudio API (to increase audio above 100%)
          />
          <br />
          <Typography variant="caption">{volume}%</Typography>
        </div>
      </Popover>
    </Grid>
  );
};

const SpeakerAmplifier = props => {
  const { call } = props;
  const [ampVal, setAmpVal] = useState(100);
  const classes = useStyles();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `spk-ampl-${call._id}`,
  });

  const handleChange = num => {
    if (num === 100) {
      call.amplifySpeakerOff();
    } else {
      const val = num / 100;
      call.amplifySpeakerOn(val);
    }
    setAmpVal(num);
  };

  return (
    <Grid item>
      <Box sx={classes.cell} {...bindTrigger(popupState)}>
        <Tooltip
          title={<>Amplify Speaker Volume (max 400%)</>}
          type="light"
          arrow
        >
          <div style={{ whiteSpace: 'nowrap' }}>
            {ampVal === 100 ? (
              <AmplifyOffIcon fontSize="small" />
            ) : (
              <>
                <AmplifyOnIcon fontSize="small" />
                <span style={{ verticalAlign: 'top' }}>{ampVal}%</span>
              </>
            )}
          </div>
        </Tooltip>
      </Box>
      <Popover {...bindMenu(popupState)}>
        <div style={{ height: 160, padding: '24px 12px', textAlign: 'center' }}>
          <Slider
            orientation="vertical"
            value={ampVal}
            onChange={(e, num) => handleChange(num)}
            min={100} // increase the potential audio after switching to the WebAudio API (to increase audio above 100%)
            max={400}
            step={50}
            marks
          />
          <br />
          <Typography variant="caption">{ampVal}%</Typography>
        </div>
      </Popover>
    </Grid>
  );
};

const CallTimer = props => {
  const { call } = props;
  const [duration, setDuration] = useState(
    new Date().getTime() - new Date(call.startTime).getTime(),
  );

  useInterval(() => {
    setDuration(new Date().getTime() - new Date(call.startTime).getTime());
    // trigger update
  }, 1000);

  if (!call.isActive() || !duration) {
    return '';
  }

  return prettyMs(duration, {
    secondsDecimalDigits: 0,
    colonNotation: true,
  });
};

const CallIP = props => {
  const { call } = props;

  const [state, setState] = useState({
    ip: null,
    loadingIp: null,
    ipResponse: null,
    validIpResponse: null,
  });

  const hasRunWithEnoughInfo = useRef(null);

  // NOTE: this list updates throughout the call (and any other ongoing calls)
  //   const { list: channels } = useSelector(state => state.lists.channels);
  const channels = []; //TODO: useChannelsSelector(); (have <Channels /> in <App /> publish channels to redux)

  useEffect(() => {
    (async () => {
      // TODO: should be running once per remote caller (could be multiple remote parties on the call!)
      // - for now, just doing the first "connected" remote caller

      if (hasRunWithEnoughInfo.current) {
        return;
      }

      // see if we have enough info already
      // - remote caller IP via channel api
      // - turn the call ID into an interaction_id for the call
      // - I could use the username (of the device!) to determine if it is "this" device in the call
      // console.log('all channels:', channels, call._request?.call_id);
      const thisChannel = channels.find(
        channel => channel.listing?.uuid === call._request?.call_id,
      );

      // console.log('thisChannel:', thisChannel);

      const interaction_id = thisChannel?.listing?.interaction_id;
      const interactionChannels = channels.filter(
        channel => channel.listing?.interaction_id === interaction_id,
      );

      // console.log('interactionChannels:', interactionChannels);

      const ips = compact(
        interactionChannels.map(ic => {
          // console.log(
          //   'ic:',
          //   ic,
          //   ic.listing?.uuid,
          //   ic.listing?.uuid.includes('@')
          // );
          return ic.listing?.uuid.includes('@')
            ? validIpAddress(ic.listing?.uuid.split('@')[1])
              ? ic.listing?.uuid.split('@')[1]
              : null
            : null;
        }),
      );

      console.log('ips:', ips);

      if (!ips.length) {
        return;
      }

      const tmpRemoteIp = ips[0];

      setState({ ip: tmpRemoteIp });
      setState({ loadingIp: true });

      // get channel details
      // - might take a minute for them to arrive via the channel api?
      // - can request directly cuz we know the call_id?
      //  - DO we actually know the call_id?? note sure if call.getId() or something else.
      const url = `${process.env.REACT_APP_CIO_API_SERVER}/api/v1/ip?ip=${tmpRemoteIp}`;
      let response = await fetch(url, {
        method: 'GET', // TODO: more than GET
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      console.log('IP response:', data);
      const updatedStateProps = {};
      updatedStateProps.validIpResponse =
        data.known || data?.country ? true : null;
      updatedStateProps.ipResponse = data;
      updatedStateProps.loadingIp = null;
      updatedStateProps.ip = tmpRemoteIp;
      setState(updatedStateProps);
    })();
  }, [channels]); // TODO: probably need to disassemble the channels to see if the above needs to run

  if (call._direction === CALL_DIRECTION_OUTGOING) {
    // nothing to show on outgoing calls??
    // - even after the call connects? (doesnt seem correct, wouldnt we know the IP we connect to?)
    return null;
  }

  return (
    <InfoTooltip icon="help">
      <Typography variant="body1">IP Address:</Typography>
      {state.ip ? (
        <Typography variant="body2">
          {state.ip}
          <br />
          {state.loadingIp ? (
            'Loading...'
          ) : state.validIpResponse ? (
            <>
              {flatten([
                state.ipResponse.city?.names?.en ?? 'Unknown',
                state.ipResponse.subdivisions.map(sd => sd.names?.en),
                state.ipResponse.country?.isoCode, // US
              ]).join(', ')}{' '}
              (~{state.ipResponse.location?.accuracyRadius ?? 'N/A'} km)
            </>
          ) : (
            'Invalid IP Response'
          )}
          {state.ipResponse?.known ? (
            <>
              <br />"{state.ipResponse.known}"
            </>
          ) : (
            ''
          )}
        </Typography>
      ) : (
        <Typography variant="body2">No Remote IP Address</Typography>
      )}
    </InfoTooltip>
  );
};

export default CallInfo;
