import { Typography } from 'app/design';
import React from 'react';

interface CreatedAtTableCellProps {
  createdAt: Date;
}

const CreatedAtTableCell = ({ createdAt }: CreatedAtTableCellProps) => {
  return <Typography>{new Date(createdAt).toLocaleString()}</Typography>;
};

export default CreatedAtTableCell;
