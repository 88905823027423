import React, { useState, useContext, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Paper,
  TooltipLight,
  Icon,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ButtonDropdownMenu,
} from 'app/design';
import {
  Delete as DeleteIcon,
  Eject as EjectIcon,
  PresentToAll as PresentToAllIcon,
  OpenWith as OpenWithIcon,
  Phone as PhoneIcon,
  Add as AddIcon,
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
} from 'app/design/icons-material';

// import { getAtPath, setAtPath, removeTrailingArr } from '@KazooSDK/utils';

import { IvrMenuEventEmitterContext, useSharedFlow } from '../../../../..';

export const MenuItem_Move = props => {
  const { data, mode, onClose } = props;
  const {
    insertBefore, // bool
    insertAfterData,
    infoIdx,
    callflow: rootCallflow,
    currentCallflow,
    componentData,
    componentInfo,
    templateParent,
    // callflow,
    // setCallflow,
    modifyPath,
  } = data;

  // const { callflow, setCallflow, modifyPath } = insertAfterData;

  const [sharedFlow, setSharedFlow] = useSharedFlow();

  const ee = useContext(IvrMenuEventEmitterContext);
  const handleMoveTo = () => {
    // if (rootCallflow.type != 'template' && templateParent) {
    //   alert(
    //     'Note that adding to this template outside of a Template Endpoint can result in losing information when the Template is re-applied!',
    //   );
    // }
    const componentInfo =
      currentCallflow.strategy.config.components.slice(infoIdx);
    const componentData = currentCallflow.strategy.data.opts.slice(infoIdx);

    // console.log('ARR:', componentData);

    setSharedFlow(s => ({
      ...s,
      state: 'move-to',
      data: {
        // array to concat to wherever it is being duplicated
        // - TODO: different way of doing this? create a new strategy/callflow each time?
        // TODO: prevent moving to self?
        currentCallflowPath: insertAfterData.modifyPath,
        pathLevel: removeTrailingArr(modifyPath),
        infoIdx,
        moveJson: JSON.parse(
          JSON.stringify({
            componentInfo,
            componentData,
          }),
        ),
      },
    }));
  };

  return (
    <MenuItem
      onClick={e => {
        onClose();
        handleMoveTo();
      }}
      component={'a'}
    >
      <ListItemIcon>
        <OpenWithIcon />
      </ListItemIcon>
      <ListItemText primary="Move" />
    </MenuItem>
  );
};

export const removeTrailingArr = modifyPath => {
  let sp = modifyPath.split('.');
  sp[sp.length - 1] = sp[sp.length - 1].substr(
    0,
    sp[sp.length - 1].indexOf('['),
  );
  return sp.join('.');
};

export default MenuItem_Move;
