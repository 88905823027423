import React from 'react';
import moment from 'moment';

import { startCase, range, toInteger } from 'lodash';

const epochOffset = 62167219200;

const dayRepresentations = {
  sunday: ['sunday', 'sun', 'su'],
  monday: ['monday', 'mon', 'mo', 'm'],
  tuesday: ['tuesday', 'tues', 'tue', 'tu'],
  wednesday: ['wednesday', 'wed', 'we', 'w'],
  thursday: ['thursday', 'thurs', 'thur', 'thu', 'th'],
  friday: ['friday', 'fri', 'fr', 'f'],
  saturday: ['saturday', 'sat', 'sa'],
};

const nextDays = {
  sunday: 'monday',
  monday: 'tuesday',
  tuesday: 'wednesday',
  wednesday: 'thursday',
  thursday: 'friday',
  friday: 'saturday',
  saturday: 'sunday',
};

const monthRepresentations = {
  january: ['january', 'jan'],
  february: ['february', 'feb'],
  march: ['march', 'mar'],
  april: ['april', 'apr'],
  may: ['may'],
  june: ['june', 'jun'],
  july: ['july', 'jul'],
  august: ['august', 'aug'],
  september: ['september', 'sept'],
  october: ['october', 'oct'],
  november: ['november', 'nov'],
  december: ['december', 'dec'],
};

const monthIntegers = {
  january: 1,
  february: 2,
  march: 3,
  april: 4,
  may: 5,
  june: 6,
  july: 7,
  august: 8,
  september: 9,
  october: 10,
  november: 11,
  december: 12,
};

const days = [
  '(',
  Object.keys(dayRepresentations)
    .map(k => dayRepresentations[k].join('|'))
    .join('|'),
  ')',
].join('');
const months = [
  '(',
  Object.keys(monthRepresentations)
    .map(k => monthRepresentations[k].join('|'))
    .join('|'),
  ')',
].join('');

const endCommentMatcher = `\\s*?((\\/\\/)|\$)`;

//const regex1stTry = /^(Mon?|Tue?|Wed?|Thu?|F(?:ri?)?|Sat?|Sun?)\s*(0?[1-9]|1[0-2])(?::([0-5]?\d))?(?:\s*[ap]m?)?(?:\s*-\s*(0?[1-9]|1[0-2])(?::([0-5]?\d))?(?:\s*[ap]m?)?)?$/i;
//const regex = /^(Mon?|Tue?|Wed?|Thu?|F(?:ri?)?|Sat?|Sun?)\s*(0?[1-9]|1[0-2])(?::([0-5]?\d))?(?:\s*[ap]m?)?(?:\s*-\s*(0?[1-9]|1[0-2])(?::([0-5]?\d))?(?:\s*[ap]m?)?)?$/i;
//Well, it is that long
// In JS code, you may shorten it, i.e. build dynamically
// https://regex101.com/r/fi2sd9/2
// '(Monday|Mon|Mo|M|Tuesday|Tues|Tue|Tu|Wednesday|Wed|We|W|Thursday|Thurs|Thur|Thu|Th|Friday|Fri|Fr|F|Saturday|Sat|Sa|Sunday|Sun|Su)';
const hr = '(0?[1-9]|1[0-2])';
const min = '(?::(\\d{2}))?';
const apm = '(?:\\s*([ap]m?))';

const dayTimeRangeStr = `^(?<dayOfWeek>${days})\\s*(?<hr1>${hr})(?<min1>${min})(?<apm1>${apm})(?:\\s*-\\s*(?<hr2>${hr})(?<min2>${min})(?<apm2>${apm}))${endCommentMatcher}`;
const dayTimeRangeExp = new RegExp(dayTimeRangeStr, 'i');

// monday
const singleWeekDayRegExp = new RegExp(
  `^(?<dayOfWeek>${days})${endCommentMatcher}`,
  'i',
);

// january 10
const repeatDateStr = `^(?<month>${months})\\s*(?<day>0?[1-9]|[1-9][0-9])${endCommentMatcher}`;
const repeatDateRegExp = new RegExp(repeatDateStr, 'i');

// TODO: support m/d, or mm/dd format

// january 10, 2007
const singleDatePart1 = `^(?<month>${months})\\s*(?<day>0?[1-9]|[1-9][0-9])\\s*?`;
const singleDatePart2 = `${singleDatePart1},\\s*?(?<year>\\d{4}|\\d{2})${endCommentMatcher}`;
const singleDateRegExp = new RegExp(singleDatePart2, 'i');
// console.log('singleDatePart2:', singleDatePart2);

// mm/dd/yyyy or m/d/yyyy
const monthDayYear = `^(?<month>0?[1-9]|1[0-2])[/](?<day>0?[1-9]|[12]\\d|3[01])[/](?<year>(19|20)\\d{2})${endCommentMatcher}`;
const monthDayYearRegExp = new RegExp(monthDayYear, 'i');

// january 5 - 10
const monthSpan1 = `^(?<month>${months})\\s*(?<day1>0?[1-9]|[1-9][0-9])`;
const monthSpan1Full = `${monthSpan1}(\\s*(-|to)\\s*)(?<day2>0?[1-9]|[1-9][0-9])${endCommentMatcher}`;
const monthSpan1RegExp = new RegExp(monthSpan1Full, 'i');

// january 10 - may 1st
const monthsSpan1 = `^(?<month1>${months})\\s*(?<day1>0?[1-9]|[1-9][0-9])`;
const monthsSpan2 = `(?<month2>${months})\\s*(?<day2>0?[1-9]|[1-9][0-9])`;
const monthsSpanFull = `${monthsSpan1}(\\s*(-|to)\\s*)${monthsSpan2}${endCommentMatcher}`;
const monthsSpanRegExp = new RegExp(monthsSpanFull, 'i');

// // january 10, 2020 - march 1st, 2020
// // - TODO: limit days that can be entered?
// //   - or have an "expires at" where processing is expected to stop...
// const monthDayStr1 = `^(?<month1>${months})\\s*(?<day1>0?[1-9]|[1-9][0-9])?\$`;

const normalize = {
  day: day => {
    return parseInt(day, 10);
  },
  dayOfWeek: dayOfWeek => {
    return Object.keys(dayRepresentations).find(k =>
      dayRepresentations[k].includes(dayOfWeek.toLowerCase()),
    );
  },
  apm: apm => {
    return apm.trim().toLowerCase() === 'a' || apm.trim().toLowerCase() === 'am'
      ? 'am'
      : 'pm';
  },
  month: tmpMonth => {
    return Object.keys(monthRepresentations).find(k =>
      monthRepresentations[k].includes(tmpMonth.toLowerCase()),
    );
  },
  monthToInteger: month => {
    return monthIntegers[month.toLowerCase()];
  },
  monthIntegerToMonth: monthInteger => {
    let tmp = monthInteger % 12 === 0 ? 12 : monthInteger % 12;
    return Object.keys(monthIntegers).find(mi => monthIntegers[mi] == tmp);
  },
  minPretty: min => {
    let tmp = min === undefined ? 0 : toInteger(min);
    if (tmp === 0) {
      return `00`;
    }
    if (tmp < 10) {
      return `0${tmp}`;
    }
    return `${tmp}`;
  },
};

const freeFormRegex = [
  {
    name: 'Single Day Time-Range',
    re: dayTimeRangeExp,
    examples: ['Sunday 1:30am - 5:45pm', 'Tuesday 8pm - 2am'], // for testing and display to user (TODO: handle i18n)
    parse: ({ text, groups, match }) => {
      const temporal_rules = [];

      // console.log('Single Day Time-Range', text, groups);

      let { dayOfWeek, hr1, min1, apm1, hr2, min2, apm2 } = groups;

      dayOfWeek = normalize.dayOfWeek(dayOfWeek);
      apm1 = normalize.apm(apm1);
      apm2 = normalize.apm(apm2);
      min1 = min1?.length ? min1.slice(1) : min1;
      min2 = min2?.length ? min2.slice(1) : min2;

      const startTimeString = `${hr1}:${normalize.minPretty(min1)}${apm1}`;
      const endTimeString = `${hr2}:${normalize.minPretty(min2)}${apm2}`;

      const startTimeSeconds = moment(startTimeString, 'h:mm:a').diff(
        moment(startTimeString, 'h:mm:a').startOf('day'),
        'seconds',
      );
      const endTimeSeconds = moment(endTimeString, 'h:mm:a').diff(
        moment(endTimeString, 'h:mm:a').startOf('day'),
        'seconds',
      );

      let parsedOutputComponent;
      if (endTimeSeconds < startTimeSeconds) {
        // next day
        // creating two rules

        const nextDay = nextDays[dayOfWeek];

        let displayFormat = `${startCase(
          dayOfWeek,
        )} ${hr1}:${normalize.minPretty(min1)}${apm1} to ${startCase(
          nextDay,
        )} ${hr2}:${normalize.minPretty(min2)}${apm2}`;
        parsedOutputComponent = (
          <div>
            <strong>Overnight Time Range:</strong>
            <br />
            {displayFormat}
          </div>
        );
        temporal_rules.push({
          name: `freeform:${text}`,
          interval: 1,
          start_date: 62586115200,
          cycle: 'weekly',
          wdays: [dayOfWeek],
          time_window_start: startTimeSeconds,
          time_window_stop: 24 * 60 * 60,
        });
        temporal_rules.push({
          name: `freeform:${text}`,
          interval: 1,
          start_date: 62586115200,
          cycle: 'weekly',
          wdays: [nextDay],
          time_window_start: 0,
          time_window_stop: endTimeSeconds,
        });
      } else {
        // NOT next day
        // - single day

        let displayFormat = `${dayOfWeek} ${hr1}:${normalize.minPretty(
          min1,
        )}${apm1} to ${hr2}:${normalize.minPretty(min2)}${apm2}`;
        parsedOutputComponent = (
          <div>
            <strong>Single Day Time Range:</strong>
            <br />
            {displayFormat}
          </div>
        );
        temporal_rules.push({
          name: `freeform:${text}`,
          interval: 1,
          start_date: 62586115200,
          cycle: 'weekly',
          wdays: [dayOfWeek],
          time_window_start: startTimeSeconds,
          time_window_stop: endTimeSeconds,
        });
      }

      return { temporal_rules, output: parsedOutputComponent };
    },
  },
  {
    //
    name: 'Day of the Week - Repeat',
    re: singleWeekDayRegExp, //new RegExp('', 'i'), // matching handled in parser (return false on no-match)
    examples: ['Monday', 'Tue', 'Wednesday'], // for testing and display to user (TODO: handle i18n)
    parse: ({ text, groups, match }) => {
      let { dayOfWeek } = groups;

      dayOfWeek = normalize.dayOfWeek(dayOfWeek);

      if (!dayOfWeek) {
        return false;
      }

      const temporal_rules = [];

      const displayFormat = startCase(dayOfWeek);

      let parsedOutputComponent = (
        <div>
          <strong>Day of the Week, Repeating:</strong>
          <br />
          {displayFormat}
        </div>
      );

      temporal_rules.push({
        name: `freeform:${text}`,
        interval: 1,
        start_date: 62586115200,
        cycle: 'weekly',
        wdays: [dayOfWeek],
      });
      return { temporal_rules, output: parsedOutputComponent };
    },
  },
  {
    //
    name: 'Repeat Date Every Year 1',
    re: repeatDateRegExp,
    examples: ['January 10'], // for testing and display to user (TODO: handle i18n)
    parse: ({ text, groups, match }) => {
      const temporal_rules = [];

      let { month, day } = groups;

      month = normalize.month(month);
      let monthInteger = normalize.monthToInteger(month);

      // console.log('Repeat Date Every Year 1:', text, groups, month, day);

      let displayFormat = `${startCase(month)} ${day}`;

      let parsedOutputComponent = (
        <div>
          <strong>Single Date - Repeat Every Year:</strong>
          <br />
          {displayFormat}
        </div>
      );

      temporal_rules.push({
        name: `freeform:${text}`,
        interval: 1,
        start_date: 62586115200,
        cycle: 'yearly',
        month: monthInteger,
        days: [day],
        // start_date: parsed.startOf('day').unix() + epochOffset,
      });

      return { temporal_rules, output: parsedOutputComponent };
    },
  },
  {
    //
    name: 'Single Date 1',
    re: singleDateRegExp,
    examples: ['January 10, 2020'], // for testing and display to user (TODO: handle i18n)
    parse: ({ text, groups, match }) => {
      const temporal_rules = [];

      // console.log('Single Date, 1 time', text, groups);

      let { month, day, year } = groups;

      month = normalize.month(month);
      day = normalize.day(day);

      let monthInteger = normalize.monthToInteger(month);

      let displayFormat = `${startCase(month)} ${day}, ${year}`;

      const parsed = moment(displayFormat, 'MMMM D, YYYY');

      let parsedOutputComponent = (
        <div>
          <strong>Single Date, 1 Time:</strong>
          <br />
          {displayFormat}
        </div>
      );

      temporal_rules.push({
        name: `freeform:${text}`,
        interval: 1,
        cycle: 'yearly',
        start_date: parsed.startOf('day').unix() + epochOffset,
        end_date: parsed.endOf('day').unix() + epochOffset,
      });

      return { temporal_rules, output: parsedOutputComponent };
    },
  },
  {
    //
    name: 'Single Date 2',
    re: monthDayYearRegExp,
    examples: ['mm/dd/yyyy', 'm/d/yyyy'], // for testing and display to user (TODO: handle i18n)
    parse: ({ text, groups, match }) => {
      const temporal_rules = [];

      // console.log('Single Date 2', text, groups);

      let { month: monthInteger, day, year } = groups;

      let month = normalize.monthIntegerToMonth(monthInteger);
      day = normalize.day(day);

      let displayFormat = `${startCase(month)} ${day}, ${year}`;

      const parsed = moment(displayFormat, 'MMMM D, YYYY');

      let parsedOutputComponent = (
        <div>
          <strong>Single Date, 1 Time:</strong>
          <br />
          {displayFormat}
        </div>
      );

      temporal_rules.push({
        name: `freeform:${text}`,
        interval: 1,
        cycle: 'yearly',
        start_date: parsed.startOf('day').unix() + epochOffset,
        end_date: parsed.endOf('day').unix() + epochOffset,
      });

      return { temporal_rules, output: parsedOutputComponent };
    },
  },
  {
    //
    name: 'Repeat Day Span Yearly - 1',
    re: monthSpan1RegExp,
    examples: ['January 10 - 12'], // for testing and display to user (TODO: handle i18n)
    parse: ({ text, groups, match }) => {
      // this is almost the same as "Repeat Day Span Yearly - 2"

      const temporal_rules = [];

      // console.log('Repeat Day Span Yearly 1:', text, groups);

      let { month: month1, day1, day2 } = groups;
      day1 = normalize.day(day1);
      day2 = normalize.day(day2);
      month1 = normalize.month(month1);
      let monthInteger1 = normalize.monthToInteger(month1);
      let month2 =
        day1 <= day2
          ? month1
          : normalize.monthIntegerToMonth(monthInteger1 + 1); // handle going to "next year" ie Dec 29-5
      let monthInteger2 = normalize.monthToInteger(month2);

      let continueUntilMonth = monthInteger2;

      let title;
      let displayFormat;

      // Handle "next year"
      const dayCount1 = monthInteger1 * 35 + day1,
        dayCount2 = monthInteger2 * 35 + day2;
      if (dayCount1 > dayCount2) {
        continueUntilMonth = monthInteger2 + 12;
        title = `Date Range (over new year) - Repeating`;
      } else {
        title = `Date Range - Repeating`;
      }

      if (monthInteger1 !== monthInteger2) {
        displayFormat = `${startCase(month1)} ${day1} - ${startCase(
          month2,
        )} ${day2}`;
      } else {
        displayFormat = `${startCase(month1)} ${day1} - ${day2}`;
      }

      // only this year
      // - create one temporal rule per month
      // - choose the correct days for that month
      let currentMonthInteger = monthInteger1;
      let currentStartDay = day1;
      while (true) {
        let dayNumbers;
        if (currentMonthInteger >= continueUntilMonth) {
          // in last month
          dayNumbers = range(currentStartDay, day2 + 1);
          temporal_rules.push({
            name: `freeform:${text}`,
            interval: 1,
            start_date: 62586115200,
            cycle: 'yearly',
            month:
              currentMonthInteger % 12 === 0 ? 12 : currentMonthInteger % 12, // for wrapping around to the next year
            days: dayNumbers,
          });
          break;
        } else {
          // not to last month yet
          dayNumbers = range(currentStartDay, 32);
          temporal_rules.push({
            name: `freeform:${text}`,
            interval: 1,
            start_date: 62586115200,
            cycle: 'yearly',
            month: currentMonthInteger,
            days: dayNumbers,
          });
          currentStartDay = 1;
          currentMonthInteger++;
        }
      }

      // console.log('temporal_rules:', temporal_rules);

      // TODO: display the total day count (roughly)

      let parsedOutputComponent = (
        <div>
          <strong>{title}</strong>
          <br />
          {displayFormat}
        </div>
      );

      return { temporal_rules, output: parsedOutputComponent };
    },
  },
  {
    //
    name: 'Repeat Day Span Yearly - 2',
    re: monthsSpanRegExp,
    examples: ['January 10 - January 12', 'November 10 - January 5'], // for testing and display to user (TODO: handle i18n)
    parse: ({ text, groups, match }) => {
      const temporal_rules = [];

      // console.log('Repeat Day Span Yearly 2:', text, groups);

      let { month1, day1, month2, day2 } = groups;
      day1 = normalize.day(day1);
      day2 = normalize.day(day2);
      month1 = normalize.month(month1);
      month2 = normalize.month(month2);
      let monthInteger1 = normalize.monthToInteger(month1);
      let monthInteger2 = normalize.monthToInteger(month2);

      let continueUntilMonth = monthInteger2;

      let title;
      let displayFormat;

      // Handle "next year"
      const dayCount1 = monthInteger1 * 35 + day1,
        dayCount2 = monthInteger2 * 35 + day2;
      if (dayCount1 > dayCount2) {
        continueUntilMonth = monthInteger2 + 12;
        title = `Date Range (over new year) - Repeating`;
      } else {
        title = `Date Range - Repeating`;
      }

      displayFormat = `${startCase(month1)} ${day1} - ${startCase(
        month2,
      )} ${day2}`;

      // only this year
      // - create one temporal rule per month
      // - choose the correct days for that month
      let currentMonthInteger = monthInteger1;
      let currentStartDay = day1;
      while (true) {
        let dayNumbers;
        if (currentMonthInteger >= continueUntilMonth) {
          // in last month
          dayNumbers = range(currentStartDay, day2 + 1);
          temporal_rules.push({
            name: `freeform:${text}`,
            interval: 1,
            start_date: 62586115200,
            cycle: 'yearly',
            month: currentMonthInteger % 12, // for wrapping around to the next year
            days: dayNumbers,
          });
          break;
        } else {
          // not to last month yet
          dayNumbers = range(currentStartDay, 32);
          temporal_rules.push({
            name: `freeform:${text}`,
            interval: 1,
            start_date: 62586115200,
            cycle: 'yearly',
            month: currentMonthInteger,
            days: dayNumbers,
          });
          currentStartDay = 1;
          currentMonthInteger++;
        }
      }

      // console.log('temporal_rules1:', temporal_rules);

      // TODO: display the total day count (roughly)

      let parsedOutputComponent = (
        <div>
          <strong>{title}</strong>
          <br />
          {displayFormat}
        </div>
      );

      return { temporal_rules, output: parsedOutputComponent };
    },
  },
  // {
  //   //
  //   name: 'All 2020 US Holidays',
  //   re: new RegExp(/all 2020 us holidays/, 'i'), //new RegExp('', 'i'), // matching handled in parser (return false on no-match)
  //   examples: [
  //     'All 2020 US Holidays',
  //     'All 2020 US Holidays (days to skip)',
  //     'All 2020 US Holidays (Jan 3, jan 7)',
  //   ], // for testing and display to user (TODO: handle i18n)
  //   parse: ({ text, match }) => {
  //     const temporal_rules = [];

  //     const skip = []; // TODO: handle skipped days
  //     const daysIncluded = [
  //       {
  //         name: 'Christmas',
  //         date: '3/25',
  //       },
  //     ]; // array of holidays

  //     let displayFormat;
  //     if (skip.length) {
  //       displayFormat = (
  //         <>
  //           Skipping dates:
  //           {skip.map((date) => (
  //             <div key={date}>- {date}</div>
  //           ))}
  //         </>
  //       );
  //     } else {
  //       displayFormat = `- no dates to skip`;
  //     }

  //     displayFormat = (
  //       <>
  //         {displayFormat}
  //         <br />
  //         <br />
  //         Holidays Included:
  //         {daysIncluded.map((info) => (
  //           <div key={info.name}>
  //             - {info.date} ({info.name})
  //           </div>
  //         ))}
  //       </>
  //     );

  //     let parsedOutputComponent = (
  //       <div>
  //         <strong>All 2020 Holidays:</strong>
  //         <br />
  //         {displayFormat}
  //       </div>
  //     );

  //     temporal_rules.push({
  //       name: `freeform:${text}`,
  //       cycle: 'date',
  //       time_window_start: 0,
  //       time_window_stop: 1,
  //     });
  //     return { temporal_rules, output: parsedOutputComponent };
  //   },
  // },
  // {
  //   //
  //   name: 'Date to Date - Repeating',
  //   re: true, //new RegExp('', 'i'), // matching handled in parser (return false on no-match)
  //   examples: ['Date1 - Date2', 'Date1 to Date2'], // for testing and display to user (TODO: handle i18n)
  //   parse: ({ text, match }) => {
  //     const temporal_rules = [];

  //     // check for valid dates using Moment
  //     let dates;
  //     let [date1, date2] = text.split(' - ');
  //     if (!date1 || !date2) {
  //       dates = text.split(' to ');
  //       date1 = dates[0];
  //       date2 = dates[1];
  //     }
  //     if (!date1 || !date2) {
  //       return false;
  //     }
  //     // console.log('TEXT:', text);
  //     // console.log('date1:', date1);
  //     // console.log('date2:', date2);

  //     const parsed1 = moment(date1.trim());
  //     const parsed2 = moment(date2.trim());
  //     if ((!parsed1.isValid(), !parsed2.isValid())) {
  //       // console.log(
  //       //   date1,
  //       //   date2,
  //       //   parsed1.isValid(),
  //       //   parsed2.isValid(),
  //       //   parsed1,
  //       //   parsed2
  //       // );
  //       return false;
  //     }
  //     parsed1.year(moment().format('YYYY'));
  //     parsed2.year(moment().format('YYYY'));

  //     if (parsed1.valueOf() > parsed2.valueOf()) {
  //       return false;
  //     }

  //     const displayFormat1 = parsed1.format('dddd, MMMM Do YYYY');
  //     const displayFormat2 = parsed2.format('dddd, MMMM Do YYYY');

  //     let parsedOutputComponent = (
  //       <div>
  //         <strong>Date Range</strong>
  //         <br /> Start: {displayFormat1}
  //         <br /> End: {displayFormat2}
  //       </div>
  //     );

  //     let tmpDate = parsed1;
  //     let i = 1;
  //     while (true) {
  //       i++;
  //       if (i > 35) {
  //         console.error('Creating too many');
  //         break;
  //       }
  //       console.log('add date:', tmpDate.format());
  //       temporal_rules.push({
  //         name: `freeform:${text}`,
  //         cycle: 'date',
  //         start_date: tmpDate.unix() + epochOffset,
  //       });
  //       tmpDate = tmpDate.add(1, 'day');
  //       if (tmpDate.unix() > parsed2.unix()) {
  //         break;
  //       }
  //     }
  //     return { temporal_rules, output: parsedOutputComponent };
  //   },
  // },
];

export default freeFormRegex;
