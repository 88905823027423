import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { DeviceUpdatePartialDoc } from 'types/device';
import { VmboxMessageUpdatePayload } from 'types/vmboxMessage';

export const useUpdateDevicePartial = () => {
  const authState = useAuthSelector();
  return useMutation<any, AxiosError, DeviceUpdatePartialDoc, unknown>(
    (deviceUpdatePartialDoc: DeviceUpdatePartialDoc) =>
      sdk.device.mutate.updatePartial(
        deviceUpdatePartialDoc,
        authState?.auth_token,
      ),
  );
};
