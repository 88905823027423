import { Grid, TablePagination, Typography } from 'app/design';
import {
  QUERY_PAGE_INDEX_CHANGED,
  QUERY_PAGE_SIZE_CHANGED,
} from 'app/utilities/useQueryPaginationReducer';
import * as React from 'react';
import { Dispatch } from 'react';

interface DefaultTablePaginationProps {
  totalRowCount: number;
  rowsPerPage: number;
  pageIndex: number;
  queryPaginationDispatch: Dispatch<{ type: any; payload: any }>;
  gotoPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  rowsPerPageOptions?: number[];
}

const DefaultTablePagination = ({
  totalRowCount,
  rowsPerPage,
  pageIndex,
  queryPaginationDispatch,
  rowsPerPageOptions = [5, 10, 25, 50],
}: DefaultTablePaginationProps) => {
  // change page index
  const handleChangePage = (e, number) => {
    queryPaginationDispatch({
      type: QUERY_PAGE_INDEX_CHANGED,
      payload: number,
    });
  };

  // change rows per page
  const handleChangeRowsPerPage = e => {
    const pageSize = e.target.value;
    queryPaginationDispatch({
      type: QUERY_PAGE_SIZE_CHANGED,
      payload: pageSize,
    });
    handleChangePage(e, 0); // reset page index to 0
  };

  const pageCount = Math.ceil(totalRowCount / rowsPerPage);

  return (
    <Grid container alignItems={'center'} justifyContent={'right'}>
      <Grid item>
        <div style={{ paddingLeft: 20 }}>
          <Typography>
            {`Page: ${pageCount > 0 ? pageIndex + 1 : 0} of ${pageCount}`}
          </Typography>
        </div>
      </Grid>
      <Grid item>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalRowCount}
          rowsPerPage={rowsPerPage}
          page={pageIndex}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </Grid>
    </Grid>
  );
};

export default DefaultTablePagination;
