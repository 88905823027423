import { DetailsDialogDevice } from 'app/components/DetailsDialogDevice';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { Device } from 'types/device';

interface DeviceNameProps {
  device: Device;
  onComplete: () => void;
}

const NameDevice = ({ device, onComplete }: DeviceNameProps) => {
  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'name',
    options: [
      {
        label: 'Name',
        value: 'name',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const handleOnComplete = () => {
    onComplete();
    toggleShowEditDialog();
  };

  return (
    // @ts-ignore
    <div ref={ref}>
      <HighlightPulse {...highlightPulseProps} />
      {showEditDialog ? (
        <DetailsDialogDevice
          deviceId={device.id}
          onCancel={toggleShowEditDialog}
          onComplete={handleOnComplete}
        />
      ) : null}
      <GenericSettingsCardField
        title={'Device Name'}
        content={device.doc.name}
        editOnClick={toggleShowEditDialog}
      />
    </div>
  );
};

export default NameDevice;
