import { useAuthSelector } from 'app/data/auth';
import { useQuery } from 'react-query';
import { sdk } from 'app/sdk';
import mediaQueryKeys from 'app/hooks/queries/media/mediaQueryKeys';

export const useMediaByIdQuery = (id: string | undefined) => {
  // - TODO v2: can be filtered/etc
  const authState = useAuthSelector();
  const queryKey = mediaQueryKeys.byId(id);

  const query = useQuery(
    queryKey,
    () =>
      sdk.media.query.mediaById({ id }, { authToken: authState.auth_token }),
    {
      enabled: id ? true : false, // authState?.auth_token_decoded?.owner_id ? true : false,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default useMediaByIdQuery;
