import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from 'app/design';
import moment from 'moment';
import React, { ReactNode, useState } from 'react';
import { jsonToCSV } from 'react-papaparse';
import { CdrInteraction } from 'types/callDetailRecord';
import { uniq } from 'lodash';
interface ExportButtonProps {
  interactions: CdrInteraction[];
  timeframe?: string;
  children: ReactNode;
}

const ExportButton = ({
  interactions,
  timeframe = '',
  // simple,
  children,
}: ExportButtonProps) => {
  // Generic "export" button that expects to be given a path to data to export
  // - also should include the name of what is to be exported
  const [showExport, setShowExport] = useState(false);

  const handleClick = () => {
    // alert('Needs to for exporting, where/how to export?');
    setShowExport(true);
  };

  return (
    <>
      {showExport && (
        <ExportDialog
          onClose={() => setShowExport(false)}
          interactions={interactions}
          timeframe={timeframe}
        />
      )}
      <Button
        variant="contained"
        color="primary"
        size={'small'}
        onClick={handleClick}
      >
        {children || 'Export'}
      </Button>
    </>
  );
};

interface ExportDialogProps {
  interactions: CdrInteraction[];
  timeframe?: string;
  onClose: () => void;
}

const ExportDialog = (props: ExportDialogProps) => {
  const { onClose, interactions, timeframe } = props;
  const [exportType, setExportType] = useState('csv');

  const flattenObject = oldObject => {
    const newObject = {};

    const flattenHelper = (currentObject, newObject, previousKeyName) => {
      for (let key in currentObject) {
        const value = currentObject[key];
        if (typeof value !== 'object') {
          if (!previousKeyName) {
            newObject[key] = value;
          } else {
            if (!key) {
              newObject[previousKeyName] = value;
            } else {
              newObject[previousKeyName + '.' + key] = value;
            }
          }
        } else {
          if (!previousKeyName) {
            flattenHelper(value, newObject, key);
          } else {
            flattenHelper(value, newObject, previousKeyName + '.' + key);
          }
        }
      }
    };

    flattenHelper(oldObject, newObject, '');

    return newObject;
  };

  const handleSimpleExport = async () => {
    const data: any[] = [];

    switch (exportType) {
      case 'csv':
        interactions.forEach(interaction => {
          data.push({
            id: interaction.id,
            created_at: interaction.createdAt,
            type: interaction.doc.type,
            result: interaction.doc.result,
            ...flattenObject({ from: interaction.doc.from }),
            ...flattenObject({ to: interaction.doc.to }),
            total_billable_time: interaction.doc.totalBillableSeconds,
            duration_seconds: interaction.doc.durationInSeconds,
          });
        });
        break;
      case 'json':
        interactions.forEach(interaction => {
          data.push({
            id: interaction.id,
            created_at: interaction.createdAt,
            type: interaction.doc.type,
            result: interaction.doc.result,
            from: interaction.doc.from,
            to: interaction.doc.to,
            total_billable_time: interaction.doc.totalBillableSeconds,
            duration_seconds: interaction.doc.durationInSeconds,
          });
        });
        break;
    }

    await exportData(data, 'simple', exportType);
    onClose();
  };

  const handleAdvancedExport = async () => {
    let data: any[] = [];

    switch (exportType) {
      case 'json':
        interactions.forEach(interaction => {
          data.push({
            custom: {
              id: interaction.id,
              created_at: interaction.createdAt,
              type: interaction.doc.type,
              result: interaction.doc.result,
              ...flattenObject({ from: interaction.doc.from }),
              ...flattenObject({ to: interaction.doc.to }),
              total_billable_time: interaction.doc.totalBillableSeconds,
              duration_seconds: interaction.doc.durationInSeconds,
            },
            cdrs: interaction.legs,
          });
        });
        break;
      case 'csv':
      default:
        // sort by longest cdrs as csv creates columns based on first object
        // interactions.sort((a, b) => (a.legs.length > b.legs.length ? -1 : 1));
        interactions.forEach(interaction => {
          data.push({
            ...flattenObject({
              custom: {
                id: interaction.id,
                created_at: interaction.createdAt,
                type: interaction.doc.type,
                result: interaction.doc.result,
                from: interaction.doc.from,
                to: interaction.doc.to,
                total_billable_time: interaction.doc.totalBillableSeconds,
                duration_seconds: interaction.doc.durationInSeconds,
              },
            }),
            ...flattenObject({
              cdrs: interaction.legs,
            }),
          });
        });
        break;
    }

    await exportData(data, 'advanced', exportType);
    onClose();
  };

  const exportData = async (data, label, type) => {
    const formattedData =
      type === 'csv'
        ? jsonToCSV(data, {
            columns: uniq(
              data.reduce((prev, curr) => {
                return [...prev, ...Object.keys(curr)];
              }, []),
              // .sort(),
            ),
          })
        : JSON.stringify(data.length > 1 ? data : { ...data[0] }, null, '\t');
    const blob = new Blob([formattedData], { type: `application/${type}` });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = `${label}-interaction-export-${moment().format(
      'YYYY-MM-DD-HH-mm-ss',
    )}.${type}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Export Interaction{interactions.length > 1 ? 's' : ''}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          Export {interactions.length} interaction
          {interactions.length > 1 ? 's' : ''} from{' '}
          {timeframe ?? new Date(interactions[0].createdAt).toLocaleString()}?
        </Typography>
        <br />
        <div style={{ display: 'grid', placeItems: 'center' }}>
          <InputLabel style={{ display: 'inline-block' }}>
            Format:{' '}
            <Select
              value={exportType}
              style={{ width: 100, textAlign: 'right' }}
              onChange={e => setExportType(e.target.value)}
            >
              <MenuItem value={'csv'}>CSV</MenuItem>
              <MenuItem value={'json'}>JSON</MenuItem>
            </Select>
          </InputLabel>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleSimpleExport} variant="outlined" color="info">
          Simple Export
        </Button>
        <Button onClick={handleAdvancedExport} variant="outlined" color="info">
          Advanced Export
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportButton;
