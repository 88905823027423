import { InfoTooltip, StandardTextFieldProps, Typography } from 'app/design';
import { AudioPlayer } from 'app/components/AudioPlayer';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { MediaDialog } from 'app/components/MediaDialog';
import { getAtPath, useToggleReducer } from 'app/utilities';
import { useMarkdownTranslate } from 'app/utilities/translate';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface HookFormTextFieldProps {
  name: string;
  label: string;
  editLabel?: string;
  onChange?: any;
}

const HookFormMediaSelect = ({
  name,
  label,
  editLabel,
  onChange,
}: HookFormTextFieldProps) => {
  const [showSelectDialog, toggleSelectDialog] = useToggleReducer();
  const {
    formState: { errors: formErrors },
    control,
  } = useFormContext();

  const errorMessage = getAtPath(formErrors, name);
  const { t } = useMarkdownTranslate();

  return (
    <Controller
      control={control}
      // @ts-ignore
      name={name}
      render={({ field: { onChange: hookOnChange, value, ...props } }) => {
        const handleComplete = (mediaId?: string | null) => {
          hookOnChange(mediaId);
          onChange && onChange(mediaId); // pass to parent easier that 'watch'ing
          toggleSelectDialog();
        };

        return (
          <>
            {showSelectDialog ? (
              <MediaDialog
                mediaNameToSaveAs={`Menu ${label}`}
                onComplete={handleComplete}
                onCancel={toggleSelectDialog}
                mediaId={value}
              />
            ) : null}
            <GenericSettingsCardField
              title={label}
              titleHelp={t('menu.greeting.tooltip')}
              content={value ? <AudioPlayer mediaId={value} /> : undefined}
              editOnClick={toggleSelectDialog}
              editLabel={editLabel ?? `Change ${label}`}
            />
            <br />
            {errorMessage ? (
              <Typography color={'error'} variant={'caption'}>
                {errorMessage}
              </Typography>
            ) : null}
          </>
        );
      }}
    />
  );
};

export default HookFormMediaSelect;
