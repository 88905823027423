import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

export const useDeleteDevice = () => {
  const authState = useAuthSelector();
  return useMutation<any, AxiosError, string, unknown>((deviceId: string) =>
    sdk.device.mutate.delete(deviceId, authState?.auth_token),
  );
};
