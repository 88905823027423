import React, { useEffect, useCallback, useState, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogContent,
  Box,
  Grid,
  Hidden,
  Typography,
  Tabs,
  Tab,
  Paper,
  Button,
  Chip,
  Link,
} from 'app/design';

import { Search as SearchIcon } from 'app/design/icons-material';

import Tour from 'reactour';

import { useHistory } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useEffectOnce, useHoverDirty } from 'react-use';

const disableBody = target => disableBodyScroll(target);
const enableBody = target => enableBodyScroll(target);

const Setup1Tour = props => {
  const { onClose, tourEvents } = props;

  const history = useHistory();
  // const dispatch = useDispatch();
  // const { callflows } = useSelector((state) => state.lists);

  const [stepNum, setStepNum] = useState(0); // TODO: use redux?
  const [update, setUpdate] = useState(0); // TODO: use redux?

  const runningRef = useRef(null);

  const handleNextStep = () => {
    setStepNum(stepNum + 1);
  };
  const handlePrevStep = () => {
    setStepNum(stepNum - 1);
  };
  const handleAnyStep = curr => {
    console.log('curr:', curr);
  };

  const onAfterOpen = target => {
    disableBody(target);
    console.log('onAfterOpen');
    if (window.location.pathname.indexOf('/admin') === -1) {
      history.push('/admin');
    }
  };

  const onRequestClose = e => {
    console.log('onRequestClose', e);
    // setIsTourOpen(false);
    // update account tour_complete
    enableBody(e.target);
    onClose && onClose();
  };

  const handleUpdate = () =>
    setUpdate(v => {
      return v + 1;
    });

  // handle programatic "update" (usually triggered by a button)
  useEffect(() => {
    tourEvents.on('update', handleUpdate);
    return () => {
      tourEvents.off('update', handleUpdate);
    };
  }, []);

  const tourSteps = [
    {
      selector: '[data-tour-is-setup]',
    },
  ];

  return (
    <>
      <Tour
        steps={tourSteps}
        // isOpen={isTourOpen}
        isOpen
        onRequestClose={onRequestClose}
        onAfterOpen={onAfterOpen}
        update={`${window.location.pathname}-${update}`}
        goToStep={stepNum}
        nextStep={handleNextStep}
        prevStep={handlePrevStep}
        getCurrentStep={handleAnyStep}
        showButtons={false}
        showNumber={false}
        showNavigation={false}
        // disableInteraction={true}
        showCloseButton={false}
        closeWithMask
        disableDotsNavigation={true}
        badgeContent={(curr, tot) => `${curr} of ${tot}`}
        // inViewThreshold={10000} // no real change it seemed like
        scrollDuration={1000}
        rounded={30}
        maskSpace={0}
        className="tmp-tour-hide-content"
        maskClassName={'tmp-mask-class'}
      />
    </>
  );
};

export default Setup1Tour;
