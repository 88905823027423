import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from 'app/design';
import * as React from 'react';
import { useAuthSelector, useAuthSlice } from 'app/data/auth';
import { useDispatch } from 'react-redux';
import { DialogCircularProgress } from 'app/components/DialogCircularProgress';
import { LoginForm } from 'app/components/LoginForm';

const LoginDialog = () => {
  const { silently_logging_in } = useAuthSelector();
  const { actions: authActions } = useAuthSlice();
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(authActions.showLoginDialog({ show: false }));
  };

  return (
    <Dialog open={true} fullWidth maxWidth={'sm'}>
      {silently_logging_in ? (
        <DialogCircularProgress label={'Logging in...'} />
      ) : (
        <>
          <DialogTitle>
            <Typography color={'error'}>
              Unauthorized Request: Please re-sign in
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <LoginForm />
          </DialogContent>
          <Divider />
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button variant={'outlined'} color={'error'} onClick={handleCancel}>
              Cancel
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default LoginDialog;
