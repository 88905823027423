import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from 'app/design';
import { useUpdateGroupPartial } from 'app/hooks/mutations/group';
import { useGroupQuery } from 'app/hooks/queries/group';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { PhoneNumber } from 'types/phoneNumber';
import { useImmer } from 'use-immer';

// interface for useForm. Not required as it can be inferred but
// - prevents typos and provides a layer of documentation and prevents
// - incorrect registers
interface GroupDetailsFields {
  name: string;
}

// interface declaring which props are required/allowed
interface GroupDetailsDialogProps {
  groupId: string;
  onCancel: () => void;
  onComplete: () => void;
}

const DetailsDialogGroup = ({
  groupId,
  onCancel,
  onComplete,
}: GroupDetailsDialogProps) => {
  const { data: group, isLoading: groupIsLoading } = useGroupQuery(groupId, {
    onSuccess: group => {
      setFormDefaults(group.doc);
    },
  });

  const updateGroup = useUpdateGroupPartial();
  const [formDefaults, setFormDefaults] = useImmer<
    GroupDetailsFields | undefined
  >(group?.doc);

  const handleSubmit = async (groupForm: GroupDetailsFields) => {
    // update form defaults in case of error
    setFormDefaults(groupForm);

    const updateGroupPromise = updateGroup.mutateAsync(
      {
        id: groupId,
        ...groupForm,
      },
      {
        onSuccess: () => {
          onComplete();
        },
      },
    );

    // resolve promise with toast notifications
    toast.promise(updateGroupPromise, {
      pending: 'Updating group details...',
      error: 'Failed to update group details.',
      success: 'Group details updated!',
    });
  };

  return (
    <Dialog open={true} fullWidth maxWidth={'xs'}>
      {groupIsLoading ? (
        <DialogInProgress title={'Loading group details info...'} />
      ) : updateGroup.isLoading ? (
        <DialogInProgress title={'Updating Group details...'} />
      ) : (
        <>
          <DialogTitle>Edit Group Details</DialogTitle>
          <Divider />
          {group ? (
            <GroupDetailsForm
              defaultFormValues={formDefaults}
              onCancel={onCancel}
              onSubmit={handleSubmit}
              errorMessage={
                updateGroup.error
                  ? `${updateGroup.error.message} ${
                      updateGroup.error.response
                        ? `: ${updateGroup.error.response?.statusText}`
                        : ''
                    }`
                  : undefined
              }
            />
          ) : (
            <>
              <DialogContent>
                <Typography color={'error'}>Invalid Group ID.</Typography>
              </DialogContent>
              <Divider />
              <DialogActions sx={{ justifyContent: 'left' }}>
                <Button variant={'outlined'} color={'error'} onClick={onCancel}>
                  Cancel
                </Button>
              </DialogActions>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};

interface GroupDetailsFormProps {
  defaultFormValues?: GroupDetailsFields;
  initialExternalNumber?: PhoneNumber;
  onCancel: () => void;
  onSubmit: (formValues: GroupDetailsFields) => void;
  errorMessage?: string;
  startDirty?: boolean;
}

const GroupDetailsForm = ({
  defaultFormValues,
  onCancel,
  onSubmit,
  errorMessage,
  startDirty = false,
}: GroupDetailsFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useForm<GroupDetailsFields>({
    defaultValues: defaultFormValues,
  });
  const formWatch = watch();

  return (
    <>
      <DialogContent>
        {/* group name */}
        <TextField
          label={'Name'}
          {...register('name')}
          error={!!formErrors.name}
          helperText={formErrors.name?.message?.replace('"name"', 'Name')}
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button variant={'outlined'} color={'error'} onClick={onCancel}>
          Cancel
        </Button>
        {errorMessage ? (
          <Typography color={'error'}>{errorMessage}</Typography>
        ) : null}
        <Button
          variant={'outlined'}
          color={'success'}
          disabled={startDirty ? !startDirty : !isDirty}
          onClick={handleSubmit(onSubmit)}
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default DetailsDialogGroup;
