// import { getAtPath, setAtPath } from '@KazooSDK/utils';
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from 'app/design';
import {
  ClearAll as ClearAllIcon,
  Dehaze as DehazeIcon,
  FilterList as FilterListIcon,
  FormatListNumbered as FormatListNumberedIcon,
} from 'app/design/icons-material';
import { useListDevicesQuery } from 'app/hooks/queries/device';
import { useListUsersQuery } from 'app/hooks/queries/user';
import { AllAtOnceComponent } from 'app/pages/admin/Groups/View/components/GroupRingStrategyCard/RingGroup/AllAtOnce';
import { CustomComponent } from 'app/pages/admin/Groups/View/components/GroupRingStrategyCard/RingGroup/Custom';
import { FallbackComponent } from 'app/pages/admin/Groups/View/components/GroupRingStrategyCard/RingGroup/Fallback';
import { SequentialComponent } from 'app/pages/admin/Groups/View/components/GroupRingStrategyCard/RingGroup/Sequential';
import { getAtPath, setAtPath } from 'app/utilities';
// import { useTranslation } from 'react-i18next';
// import { set } from 'lodash';
import React, { useEffect, useState } from 'react';

// Note: this does NOT need to be callable by convertToFlow!
// - the components each build a correct ringgroup "endpoints" value (or preEndpoints)
export const rgTypes = [
  {
    id: 'simu',
    name: 'All at Once',
    description: 'Ring users (or devices) all at once',
    component: AllAtOnceComponent,
    icon: <DehazeIcon color={'primary'} style={{ fontSize: 20 }} />,
  },
  {
    id: 'seq',
    name: 'Sequential',
    description:
      'Ring users (or devices) one after another. Supports "tiers" or "groups" ',
    component: SequentialComponent,
    icon: <FormatListNumberedIcon color={'primary'} style={{ fontSize: 20 }} />,
  },
  {
    id: 'fallback',
    name: 'Fallback (Backoff)',
    description:
      'Start ringing users (or devices), with a delay before ringing more users (or devices). Supports "tiers" or "groups"',
    component: FallbackComponent,
    icon: <ClearAllIcon color={'primary'} style={{ fontSize: 20 }} />,
  },
  {
    id: 'custom',
    name: 'Custom',
    description: 'Fully custom sliders and timing for ringing',
    component: CustomComponent,
    icon: <FilterListIcon color={'primary'} style={{ fontSize: 20 }} />,
  },
];

// Select users from list of users
export const RingGroup = props => {
  const {
    modifyPath,
    strategyCallflow,
    setStrategyCallflow,
    // users,
    // devices,
    defaultToFirst,
    ownerUser,
    // fetchUsers,
    allow,
    useGroupFormat, // vs. "ringgroup" Format (requires conversion! has restrictions!)
  } = props;

  const ringGroup = getAtPath(strategyCallflow, `${modifyPath}`, {
    type: 'none',
    version: 1, // for if we change the type's version
    endpoints: useGroupFormat ? {} : [],
    preEndpoints: [], // gets built into "endpoints" depending on the useGroupFormat
    timeout: 20,
    strategy: 'simultaneous',
  });

  const ringGroupType = ringGroup?.type ?? 'none';

  const [startEditing, setStartEditing] = useState(
    ringGroup?.type ? false : true,
  );

  useEffect(() => {
    if (ringGroupType === 'none') {
      setStartEditing(true);
    }
  }, [ringGroupType]);

  const handleChooseType = type => () => {
    setAtPath(strategyCallflow, `${modifyPath}.type`, type);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const rgFound = rgTypes.find(rgInfo => rgInfo.id === ringGroupType);
  // console.log('rgFound:', rgFound, ringGroupType);
  const RingGroupComponent = rgFound?.component;

  // Users
  const { data: usersResp, isLoading: usersIsLoading } = useListUsersQuery({
    mask: 'id,doc(presence_id,username,email),extra', // mask
    quick: true, // "quick" ie "docOnly"
  });
  // @ts-ignore
  const { users = [] } = usersResp?.users ?? {};

  // Devices
  const { data: devicesResp, isLoading: devicesIsLoading } =
    useListDevicesQuery({
      mask: 'id,doc(name,owner_id)', // mask
      quick: true, // "quick" ie "docOnly"
    });
  // @ts-ignore
  const { devices = [] } = devicesResp?.devices ?? {};

  if (devicesIsLoading || usersIsLoading) {
    return (
      <div style={{ display: 'grid', placeItems: 'center' }}>
        <CircularProgress variant={'indeterminate'} />
        Loading...
      </div>
    );
  }

  if (RingGroupComponent) {
    return (
      <RingGroupComponent
        startEditing={startEditing}
        users={users}
        devices={devices}
        {...props}
      />
    );
  }

  return (
    <>
      <Typography>Select:</Typography>
      {rgTypes.map(rgInfo => (
        <Card
          key={rgInfo.id}
          onClick={handleChooseType(rgInfo.id)}
          style={{ marginBottom: 8, cursor: 'pointer' }}
          variant={'outlined'}
        >
          <CardContent>
            <Grid container alignItems={'center'} spacing={1}>
              <Grid item>
                <div style={{ paddingTop: 5 }}>
                  <Typography variant="h7">{rgInfo.icon}</Typography>
                </div>
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <Typography variant="h7" bold>
                  {rgInfo.name}
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="body2">{rgInfo.description}</Typography>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default RingGroup; //connect(mapStateToProps, { fetchUsers })(RingGroup);
