import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { MenuDoc } from 'types/menu';
import menuQueryKeys from 'app/hooks/queries/menu/menuQueryKeys';

export const useUpdateMenuPartial = () => {
  const authState = useAuthSelector();
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, MenuDoc, unknown>(
    (menuUpdatePartialDoc: MenuDoc) =>
      sdk.menu.mutate.updatePartial(
        menuUpdatePartialDoc,
        authState?.auth_token,
      ),
    {
      onSuccess: resp => {
        // this always runs, even if the caller provides an onSuccess
        queryClient.invalidateQueries(menuQueryKeys.byId(resp.data.id));
        // console.log('val:', val);
      },
    },
  );
};
