import MediaDialog from 'app/components/MediaDialog/MediaDialog';
import { useUpdateGroupPartial } from 'app/hooks/mutations/group';
import { useGroupQuery } from 'app/hooks/queries/group';
import React from 'react';
import { MediaType } from 'types/media';

interface EditHoldMusicDialogProps {
  groupId: string;
  onComplete: () => void;
  onCancel: () => void;
  mediaId?: string | null;
}

const EditHoldMusicDialogGroup = ({
  groupId,
  mediaId,
  onComplete,
  onCancel,
}: EditHoldMusicDialogProps) => {
  const updateGroup = useUpdateGroupPartial();
  const { data: group } = useGroupQuery(groupId);

  const handleComplete = async (mediaId: string | null) => {
    return updateGroup.mutateAsync(
      {
        id: groupId,
        music_on_hold: {
          media_id: mediaId,
        },
      },
      {
        onSuccess: () => {
          onComplete();
        },
      },
    );

    // resolve promise with toast notifications
    // await toast.promise(updateGroupPromise, {
    //   pending: 'Updating group details...',
    //   error: 'Failed to update group details.',
    //   success: 'Group details updated!',
    // });
  };

  return (
    <MediaDialog
      type={'Hold Music'}
      mediaNameToSaveAs={`${group?.doc.name} Hold Music`}
      mediaId={mediaId}
      onComplete={handleComplete}
      onCancel={onCancel}
      allowedTypes={[MediaType.BuiltIn, MediaType.Upload]}
    />
  );
};

export default EditHoldMusicDialogGroup;
