import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from 'app/design';
import { useUpdateVmboxPartial } from 'app/hooks/mutations/vmbox';
import { useVmboxQuery } from 'app/hooks/queries/vmbox';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { PhoneNumber } from 'types/phoneNumber';
import { useImmer } from 'use-immer';

// interface for useForm. Not required as it can be inferred but
// - prevents typos and provides a layer of documentation and prevents
// - incorrect registers
interface VmboxDetailsFields {
  name: string;
}

// interface declaring which props are required/allowed
interface VmboxDetailsDialogProps {
  vmboxId: string;
  onCancel: () => void;
  onComplete: () => void;
}

const DetailsDialogVmbox = ({
  vmboxId,
  onCancel,
  onComplete,
}: VmboxDetailsDialogProps) => {
  const { data: vmbox, isLoading: vmboxIsLoading } = useVmboxQuery({
    id: vmboxId,
    options: {
      onSuccess: vmbox => {
        setFormDefaults(vmbox.doc);
      },
    },
  });

  const updateVmbox = useUpdateVmboxPartial();
  const [formDefaults, setFormDefaults] = useImmer<
    VmboxDetailsFields | undefined
  >(vmbox?.doc);

  const handleSubmit = async (vmboxForm: VmboxDetailsFields) => {
    // update form defaults in case of error
    setFormDefaults(vmboxForm);

    const updateVmboxPromise = updateVmbox.mutateAsync(
      {
        id: vmboxId,
        ...vmboxForm,
      },
      {
        onSuccess: () => {
          onComplete();
        },
      },
    );

    // resolve promise with toast notifications
    toast.promise(updateVmboxPromise, {
      pending: 'Updating Voicemail Box details...',
      error: 'Failed to update Voicemail Box details.',
      success: 'Voicemail Box details updated!',
    });
  };

  return (
    <Dialog open={true} fullWidth maxWidth={'xs'}>
      {vmboxIsLoading ? (
        <DialogInProgress title={'Loading Voicemail Box details info...'} />
      ) : updateVmbox.isLoading ? (
        <DialogInProgress title={'Updating Voicemail Box details...'} />
      ) : (
        <>
          <DialogTitle>Edit Vmbox Details</DialogTitle>
          <Divider />
          {vmbox ? (
            <VmboxDetailsForm
              defaultFormValues={formDefaults}
              onCancel={onCancel}
              onSubmit={handleSubmit}
              errorMessage={
                updateVmbox.error
                  ? `${updateVmbox.error.message} ${
                      updateVmbox.error.response
                        ? `: ${updateVmbox.error.response?.statusText}`
                        : ''
                    }`
                  : undefined
              }
            />
          ) : (
            <>
              <DialogContent>
                <Typography color={'error'}>Invalid Vmbox ID.</Typography>
              </DialogContent>
              <Divider />
              <DialogActions sx={{ justifyContent: 'left' }}>
                <Button variant={'outlined'} color={'error'} onClick={onCancel}>
                  Cancel
                </Button>
              </DialogActions>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};

interface VmboxDetailsFormProps {
  defaultFormValues?: VmboxDetailsFields;
  initialExternalNumber?: PhoneNumber;
  onCancel: () => void;
  onSubmit: (formValues: VmboxDetailsFields) => void;
  errorMessage?: string;
  startDirty?: boolean;
}

const VmboxDetailsForm = ({
  defaultFormValues,
  onCancel,
  onSubmit,
  errorMessage,
  startDirty = false,
}: VmboxDetailsFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useForm<VmboxDetailsFields>({
    defaultValues: defaultFormValues,
  });
  const formWatch = watch();

  return (
    <>
      <DialogContent>
        {/* vmbox name */}
        <TextField
          label={'Name'}
          {...register('name')}
          error={!!formErrors.name}
          helperText={formErrors.name?.message?.replace('"name"', 'Name')}
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button variant={'outlined'} color={'error'} onClick={onCancel}>
          Cancel
        </Button>
        {errorMessage ? (
          <Typography color={'error'}>{errorMessage}</Typography>
        ) : null}
        <Button
          variant={'outlined'}
          color={'success'}
          disabled={startDirty ? !startDirty : !isDirty}
          onClick={handleSubmit(onSubmit)}
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default DetailsDialogVmbox;
