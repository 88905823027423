import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { UsersPageResponse } from 'types/user/UsersPageResponse';
import { VmboxesPageResponse } from 'types/vmbox';
import vmboxQueryKeys from './vmboxQueryKeys';

interface QueryParams {
  skip?: number;
  take?: number;
  orderBy?: any;
  filters?: any;
  mask?: any;
  quick?: boolean;
  options?: Omit<
    UseQueryOptions<VmboxesPageResponse, Error, VmboxesPageResponse, QueryKey>,
    'queryKey' | 'queryFn'
  >;
}

export const useListVmboxesQuery = ({
  skip = 0,
  take = 10000,
  orderBy = [['lastName', 'asc']],
  filters = {},
  mask = '*',
  quick = false,
  options,
}: QueryParams) => {
  // returns list of vmboxes
  // - just get ALL vmboxes+relations
  // - TODO v2: can be filtered/etc
  const authState = useAuthSelector();
  const queryKey = quick
    ? vmboxQueryKeys.listQuick(skip, take, orderBy, filters, mask)
    : vmboxQueryKeys.list(skip, take, orderBy, filters, mask);

  const func = quick ? 'vmboxesQuick' : 'vmboxes';

  const query = useQuery<VmboxesPageResponse, Error>(
    queryKey,
    () =>
      sdk.vmbox.query[func](
        {
          skip,
          take,
          orderBy,
          filters,
          mask,
        },
        { authToken: authState.auth_token },
      ),
    {
      // enabled: authState?.auth_token_decoded?.owner_id ? true : false,

      // prevents pagination from looking like a hard load;
      //  - keeps previous data displayed until new data arrives
      ...options,
      keepPreviousData: options?.keepPreviousData ?? true,
    },
  );

  return {
    ...query,
    queryKey,
  };
};
export default useListVmboxesQuery;
