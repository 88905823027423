import React, { useState, useCallback } from 'react';

// import * as OptionComponents from '../../components';
// import * as MODULES from '../../flowTransformModules';

import { orderBy } from 'lodash';

import { v4 as uuidv4 } from 'uuid';

export const USER_ADDABLE_COMPONENTS = orderBy(
  [
    // {
    //   name: 'Caller ID Routing',
    //   type: 'CallerIdRouting',
    //   options: {
    //     props: {
    //       required: true,
    //     },
    //   },
    // },
    {
      name: 'Play Audio',
      icon: 'audiotrack',
      type: 'PlayAudio',
      options: {
        props: {},
      },
      allowAfter: true,
    },
    {
      name: 'Schedule', // and Options
      icon: 'schedule',
      type: 'Schedule',
      options: {
        props: {},
      },
    },
    // {
    //   name: 'Directory',
    //   icon: 'folder',
    //   type: 'Directory',
    //   options: {
    //     props: {},
    //   },
    // },
    // {
    //   name: 'Conference Room',
    //   icon: 'videocam',
    //   type: 'ConferenceRoom',
    //   options: {
    //     props: {},
    //   },
    // },
    // {
    //   name: 'Choose Menu',
    //   type: 'ChooseMenu',
    //   options: {
    //     props: {
    //       type: ['is_menu', 'time_of_day_menu'],
    //       required: true,
    //     },
    //   },
    // },
    // {
    //   name: 'Transfer to Menu',
    //   type: 'ChooseMenu',
    //   options: {
    //     props: {
    //       required: true,
    //     },
    //   },
    // },
    {
      name: 'Menu',
      icon: 'list',
      type: 'Menu',
      options: {
        props: {
          // type: ['is_menu', 'time_of_day_menu'],
          // possibleStrategyTypes:[],
          required: true,
        },
      },
    },
    // {
    //   name: 'Go To Callflow',
    //   type: 'ContinueToCallflow',
    //   options: {
    //     props: {
    //       types: ['office_menu'],
    //       showChooser: true,
    //       required: true,
    //     },
    //   },
    // },
    // {
    //   name: 'Ring Group',
    //   type: 'RingGroup',
    //   options: {
    //     props: {
    //       required: true,
    //     },
    //   },
    // },
    {
      name: 'Ring a User',
      icon: 'person',
      type: 'Ring',
      options: {
        props: {
          required: true,
          defaultRingType: 'user',
        },
      },
      data: {
        ring_type: 'user',
        data: {},
      },
      allowAfter: true,
    },
    {
      name: 'Ring a Device',
      icon: 'phonelink',
      type: 'Ring',
      options: {
        props: {
          required: true,
          defaultRingType: 'device',
        },
      },
      data: {
        ring_type: 'device',
        data: {},
      },
      allowAfter: true,
    },
    {
      name: 'Ring a Group',
      icon: 'group',
      type: 'Ring',
      options: {
        props: {
          required: true,
          defaultRingType: 'group',
        },
      },
      data: {
        ring_type: 'group',
        data: {},
      },
      allowAfter: true,
    },
    // {
    //   name: 'Simple',
    //   icon: 'bolt',
    //   type: 'Simple',
    //   options: { props: {} },
    // },
    // {
    //   name: 'Ring Custom',
    //   icon: 'folder_special',
    //   type: 'Ring',
    //   options: {
    //     props: {
    //       required: true,
    //       defaultRingType: 'custom',
    //     },
    //   },
    // },
    // {
    //   name: 'User',
    //   type: 'User',
    //   options: { props: { defaultToFirst: true } },
    // },
    // {
    //   name: 'User Desk Phone',
    //   type: 'UserDeskPhone',
    //   options: { props: { defaultToFirst: true } },
    // },
    // {
    //   name: 'User Cell Phone',
    //   type: 'UserCellPhone',
    //   options: { props: { defaultToFirst: true } },
    // },
    {
      name: 'Transfer to Extension',
      icon: 'transform',
      type: 'Transfer',
      options: {
        props: {},
      },
    },
    // {
    //   name: 'Template',
    //   icon: 'add_to_photos',
    //   type: 'Template',
    //   options: { props: {} },
    //   allowFunc: ({ isInTemplate, endpointAlreadyInArr }) => {
    //     return isInTemplate ? false : true;
    //   },
    // },
    {
      name: 'Template Endpoint',
      icon: 'stop',
      type: 'TemplateEndpoint',
      options: { props: {} },
      allowFunc: ({ isInTemplate, endpointAlreadyInArr }) => {
        if (!isInTemplate || (isInTemplate && endpointAlreadyInArr)) {
          // console.log({ isInTemplate, endpointAlreadyInArr });
          return false;
        }
        return true;
      },
    },
    {
      name: 'Voicemail Box',
      icon: 'voicemail',
      type: 'VoicemailBox',
      options: { props: {} },
    },
  ],
  'name',
);
