import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Link,
  Divider,
} from 'app/design';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useAuthSelector, useAuthSlice } from 'app/data/auth';

const schema = Joi.object({
  username: Joi.string().required(),
  account_name: Joi.string().required(),
  password: Joi.string().required(),
});

interface LoginFormInput {
  username: string;
  account_name: string;
  password: string;
}

interface LoginFormProps {
  credentials?: {
    username: string;
    account_name: string;
    password: string;
  };
}

const LoginForm = ({ credentials }: LoginFormProps) => {
  const {
    loggingIn,
    loggedIn,
    username,
    account_name,
    error,
    show_login_dialog,
  } = useAuthSelector();
  const { actions: authActions } = useAuthSlice();
  const dispatch = useDispatch();
  const onSubmit = loginFormInputData => {
    dispatch(authActions.loginWithCredentials(loginFormInputData));
  };
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isValid },
  } = useForm<LoginFormInput>({
    defaultValues: {
      username: username ?? credentials?.username ?? '',
      account_name: account_name ?? credentials?.account_name ?? '',
      password: credentials?.account_name ? credentials?.password : '',
    },
    resolver: joiResolver(schema),
  });
  const [showEmailLogin, setShowEmailLogin] = React.useState(false);

  if (loggingIn) {
    return (
      <Typography variant="h3" align="center">
        Logging in, just a moment...
        <br />
        <br />
        <CircularProgress />
      </Typography>
    );
  }

  // show form if showing login dialog (from unauthorized request)
  // to let user re-sign in from current page
  if (loggedIn && !show_login_dialog) {
    return <Typography variant="h3">Login Successful!</Typography>;
  }

  const thisApp = `${window.location.protocol}//${window.location.host}`;
  const server = process.env.REACT_APP_CIO_HOME;
  return (
    <Box component={'form'}>
      <Grid container spacing={3} justifyContent={'center'} direction="column">
        <Grid item>
          <Typography variant="h1" sx={{ textAlign: 'center' }}>
            <Link href={process.env.REACT_APP_CIO_HOME}>
              <img src="/logo_normal.png" />
            </Link>
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Button
                href={`${server}/oauth/app-auto-signin/slack?callbackUrl=${thisApp}`}
                variant="outlined"
                color="primary"
                fullWidth
              >
                Login with&nbsp;<strong>Slack</strong>
              </Button>
            </Grid>
            <Grid item>
              <Button
                href={`${server}/oauth/app-auto-signin/azure-ad?callbackUrl=${thisApp}`}
                variant="outlined"
                color="primary"
                fullWidth
              >
                Login with&nbsp;<strong>Microsoft Azure AD</strong>
              </Button>
            </Grid>
            {showEmailLogin ? null : (
              <Grid item>
                <Button
                  onClick={e => setShowEmailLogin(true)}
                  variant="outlined"
                  color="primary"
                  fullWidth
                >
                  Login with&nbsp;<strong>Email / Password</strong>
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* <Divider>or</Divider> */}
        {showEmailLogin ? (
          <>
            <br />
            <Divider />
            <br />
            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              Login using your credentials:
            </Typography>
            <Grid item>
              <TextField
                fullWidth
                label={'Username'}
                {...register('username')}
                placeholder="Username (youremail@example.com)"
                error={!!formErrors.username}
                helperText={formErrors.username?.message?.replace(
                  '"username"',
                  'Username',
                )}
                autoFocus={!(username || credentials?.username)}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label={'Account Name'}
                {...register('account_name')}
                placeholder={'Account Name (Sam Co Furniture)'}
                error={!!formErrors.account_name}
                helperText={formErrors.account_name?.message?.replace(
                  '"account_name"',
                  'Account Name',
                )}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label={'Password'}
                type={'password'}
                {...register('password')}
                error={!!formErrors.password}
                helperText={formErrors.password?.message?.replace(
                  '"password"',
                  'Password',
                )}
                autoFocus={
                  credentials?.password
                    ? false
                    : !!(username || credentials?.username)
                }
              />
              <Box sx={{ marginTop: '4px' }}>
                <Link href="/forgot-password" variant="body2">
                  Forgot Password?
                </Link>
              </Box>
            </Grid>
            <Grid item sx={{ textAlign: 'center' }}>
              <Button
                fullWidth
                variant={'contained'}
                color={'primary'}
                onClick={handleSubmit(onSubmit)}
              >
                Login
              </Button>
            </Grid>
            {error ? (
              <Grid item>
                <Typography color={'error'}>
                  {error.replace('_', ' ')}
                </Typography>
              </Grid>
            ) : null}
          </>
        ) : null}

        {/* <Grid item>
          <Typography
            variant="caption"
            component="div"
            sx={{ textAlign: 'center' }}
          >
            <Link href="/forgot-password">Forgot Password</Link>
          </Typography>
        </Grid> */}
        <Grid item>
          <Typography
            variant="caption"
            component="div"
            sx={{ textAlign: 'center' }}
          >
            Any questions? Contact us at{' '}
            <Link href="mailto:support@voiplabs.com">support@voiplabs.com</Link>
          </Typography>
          <br />
          <Divider>or</Divider>
          <br />
          <Typography
            variant="caption"
            component="div"
            sx={{ textAlign: 'center' }}
          >
            Don't have an account yet?{' '}
            <Link href={`${process.env.REACT_APP_CIO_HOME}`}>Signup</Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginForm;
