import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  Paper,
  Link,
} from 'app/design';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from 'app/design/icons-material';
import { useCreateDirectory } from 'app/hooks/mutations/directory';
import { useToggleReducer } from 'app/utilities';
import Joi from 'joi';
import { pickBy } from 'lodash';
import { useEffect } from 'react';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Directory } from 'types/directory';
import { useImmer } from 'use-immer';

import { sdk } from 'app/sdk';
import { store } from '../../../../../store';

import copy from 'copy-to-clipboard';
import { useAuthSelector } from 'app/data/auth';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import QRCodeDisplay from 'react-qr-code';

// interface declaring which props are required/allowed
interface MobileAppDialogProps {
  // directoryId: string;
  open?: boolean;
  onCancel: () => void;
  // onComplete: () => void;
}

const MobileAppDialog = ({
  // directoryId,
  open,
  onCancel,
}: // onComplete,
MobileAppDialogProps) => {
  const { auth_token } = useAuthSelector();
  const { data: authUser } = useAuthenticatedUserQuery();

  const strForQrCode = JSON.stringify({
    type: 'v1',
    data: {
      auth_token,
      cio_api_url:
        process.env.REACT_APP_CIO_QR_CODE_SERVER ||
        process.env.REACT_APP_CIO_API_SERVER,
      kazoo_api_url: process.env.REACT_APP_API_URL,
      name: `${authUser?.extra?.fullName}`,
    },
  });

  return (
    // @ts-ignore
    <Dialog
      // @ts-ignore
      open={open}
      fullWidth
      maxWidth={'sm'}
      scroll="body"
      onClose={onCancel}
    >
      {/* <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h4">Mobile App QR Code</Typography>
          </Grid>
          <Grid item>
            <Button size="sm" variant="outlined" onClick={onCancel}>
              Done
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider /> */}
      <DialogContent>
        <div style={{}}>
          <Typography variant="h2" gutterBottom>
            Make and receive business calls from your mobile phone!
          </Typography>
          <Typography variant="body1" paragraph>
            Our mobile app integrates deeply into your phone, giving you access
            to your CallingIO Contacts on the go, and enabling you to make and
            receive calls as if you were in the office (including correct Caller
            ID information!).
          </Typography>

          <br />
          <Divider />
          <br />
          <Typography variant="h6" gutterBottom>
            Step 1: Download from the App Store
          </Typography>
          <Typography variant="body1" paragraph>
            Click on the App Store for your provider (we currently support iOS
            and Android):
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            {/* TestFlight */}
            <img
              src="https://ucarecdn.com/79e85a6b-5b49-48b0-addc-07c54cb438fa/testflightbadge.png"
              style={{ height: 48 }}
            />
            {/* &nbsp;&nbsp;
            <img
              src="https://ucarecdn.com/36cdd446-a278-41e4-b19d-9ffc87b857f7/Available_on_the_App_Store_black.png"
              style={{ height: 48 }}
            />
            &nbsp;&nbsp;
            <img
              src="https://ucarecdn.com/22b588ac-e4a9-4220-86aa-05666289f730/getitongoogleplaybadge.png"
              style={{ height: 48 }}
            /> */}
          </Typography>
          <br />
          <Typography variant="h6" gutterBottom>
            Step 2: Open the App
          </Typography>
          <Typography variant="body1" paragraph>
            After downloading and opening the app, it will ask you to point your
            phone at the QR code below:
          </Typography>
          <div
            onClick={() => {
              // copy(strForQrCode);
              // alert(`Copied: ${strForQrCode}`);
            }}
            style={{ textAlign: 'center' }}
          >
            <QRCodeDisplay value={strForQrCode} size={240} />
          </div>
          <br />
          <Typography variant="body1" paragraph>
            ...and you are all done!
          </Typography>
          <Typography variant="body1" paragraph>
            If you need to login to the mobile app again, you can repeat this
            process anytime.
          </Typography>

          {/* <br />
            <Button onClick={callgithub} variant="outlined">
              Test Branding Build
            </Button> */}
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button size="sm" variant="outlined" onClick={onCancel}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const useMobileAppDialog = ({ initialOpen = false }) => {
  const [open, toggleOpen] = useToggleReducer(initialOpen);

  const MobileAppDialogProps = {
    open,
    onCancel: toggleOpen,
  };

  return {
    toggleOpen,
    MobileAppDialog,
    MobileAppDialogProps,
  };
};

export default MobileAppDialog;
