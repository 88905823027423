import { useAuthSelector } from 'app/data/auth';
import { useLocalSelector, useLocalSlice } from 'app/data/local';
import { sdk } from 'app/sdk';
import { useWebsocketBinding } from 'app/utilities/websocketBinding';
import { flatten } from 'lodash';
import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useEffectOnce, useInterval } from 'react-use';

const epochOffset = 62167219200;

let isUpdating = false,
  shouldUpdateAgain = false,
  lastUpdated = -1;

const Channels = props => {
  const dispatch = useDispatch();
  const { channels } = useLocalSelector();
  const { actions: localActions } = useLocalSlice();
  const { account_id, auth_token } = useAuthSelector();
  const queryClient = useQueryClient();

  const updateChannels = async () => {
    // sync channels
    // - should be using Websockets to manage the existing channels, not re-fetch them all each time (bad for lots of users/calls)
    // console.log('Updating Channels');
    if (isUpdating) {
      shouldUpdateAgain = true;
      return;
    }
    isUpdating = true;
    shouldUpdateAgain = false;
    // const resp = await KazooSDK.getChannels();

    const headers = new Headers();
    // @ts-ignore
    headers.set('X-AUTH-TOKEN', auth_token);
    headers.set('Content-Type', 'application/json');
    const response = await fetch(
      `${process.env.REACT_APP_CIO_API_SERVER}/api/kproxy/accounts/${account_id}/channels`,
      {
        method: 'GET',
        headers,
      },
    );
    const resp = await response.json();
    if (resp.status !== 'success') {
      isUpdating = false;
      return;
    }

    // console.log('channels resp:', resp);

    const timestampSyncTime =
      Math.round(new Date(resp.timestamp).getTime() / 1000) + epochOffset;
    const channels = resp.data.map(ch => ({ id: ch.uuid, listing: ch }));
    isUpdating = false;
    // console.log('channels timestampSyncTime', timestampSyncTime, lastUpdated);
    if (timestampSyncTime > lastUpdated) {
      lastUpdated = timestampSyncTime;
      // dispatch({
      //   type: 'SET_LISTS_COLLECTION',
      //   payload: {
      //     collectionKey: 'channels',
      //     data: {
      //       list: channels,
      //       loading: true,
      //       loaded: true,
      //       recentSyncTime: timestampSyncTime,
      //     },
      //   },
      // });
      // console.log('SETTING CHANNELS:', channels);
      dispatch(localActions.set_channels(channels));
    } else {
      // console.log('stale channels loaded, ignored');
    }
    if (shouldUpdateAgain) {
      updateChannels();
    }
  };

  // TODO:
  // - handle if we should be listening or not
  const callIdBindings = flatten(
    channels.map(ch => [
      // `presence.update.*`,
      // `UPDATE.*`,
      // `presence.update.${ch.listing.interaction_id}`,
      // `presence.update.${ch.id.split('@')[0]}`
    ]),
  );

  useWebsocketBinding(
    [
      'call.CHANNEL_CREATE.*',
      'call.CHANNEL_ANSWER.*',
      'call.CHANNEL_DESTROY.*',
      'call.CHANNEL_HOLD.*',
      'call.CHANNEL_UNHOLD.*',
      'call.CHANNEL_BRIDGE.*',
      'call.PARK_PARKED.*',
      'call.PARK_RETRIEVED.*',
      // 'presence.update.*',
      // 'update.*',
      ...callIdBindings,
      // 'call.PARK_ABANDONED.*',
      // 'update.63817612459-96b1f7aa', // what call id to use?? does NOT exist on channel yet??
    ],
    event => {
      updateChannels();
      // also trigger an update to happen in a few seconds (to account for broken fetches, out-of-sync)
      setTimeout(updateChannels, 5000);
    },
  );
  // console.log('channels id bindings:', callIdBindings, channels);

  useEffectOnce(() => {
    updateChannels();
    sdk.websocket.query.available(undefined);
  });

  // Update every 5 seconds, if there are any active channels
  // - TODO: ask for a "CHANNEL_UPDATE" method that watches for all changes to the channel (specifically: custom_application_vars)
  useInterval(() => updateChannels(), channels?.length ? 5000 : null);

  // console.log('channels:', channels);

  useEffect(() => {
    queryClient.invalidateQueries(['callDetailRecords']);
  }, [channels]);

  return null;
};

export default Channels;
