import * as React from 'react';
import { Box, CircularProgress, Typography } from 'app/design';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAuthSlice, selectAuth, useAuthSelector } from 'app/data/auth';
import { useEffectOnce } from 'react-use';

function AppLoadingScreen() {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <CircularProgress size={30} />
        <Typography variant="body2">CallingIO App Initializing...</Typography>
      </Box>
    </Box>
  );
}

export default AppLoadingScreen;
