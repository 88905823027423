import React, { useState } from 'react';
import {
  AllInclusive as AllInclusiveIcon,
  Business as BusinessIcon,
  Person as PersonIcon,
  Public as PublicIcon,
  Videocam as VideocamIcon,
  Warning as WarningIcon,
  ArrowRightAlt as ArrowRightAltIcon,
} from 'app/design/icons-material';
import { Grid, Typography, Card, Link } from 'app/design';
import ReactJson from 'react-json-view';

const GridIcons = ({ icons }) => {
  return (
    <Grid item>
      <span>{icons[0]}</span>
      {icons.length > 1 ? (
        <>
          <span>
            <ArrowRightAltIcon />
          </span>
          <span>{icons[1]}</span>
        </>
      ) : null}
    </Grid>
  );
};

const hangupCauseIsFailure = hangup_cause => {
  const failureArr = [
    'UNALLOCATED_NUMBER',
    'NO_ROUTE_DESTINATION',
    'CHANNEL_UNACCEPTABLE',
    'INCOMPATIBLE_DESTINATION',
  ];
  return failureArr.includes(hangup_cause) ? true : false;
};

export const Leg = props => {
  const { interaction, leg } = props;

  // determine component to display based on leg type?
  // or just have different parts display differently?
  // - internal-inbound
  // - internal-outbound
  // - external-inbound (offnet-origination)
  // - external-outbound
  // - loopback

  // icons:
  // - internal User
  // - internal Device
  // - external XYZ
  // - switch/kazoo/business
  // - call_forward_device (PhoneForward)

  let title;

  // console.log('leg:', leg);

  if (leg.doc.channel_loopback_leg) {
    return <Leg_Loopback leg={leg} interaction={interaction} />;
  }
  if (
    leg.doc.call_direction === 'inbound' &&
    leg.doc.custom_channel_vars?.resource_type === 'offnet-origination'
  ) {
    return <Leg_ExternalInbound leg={leg} interaction={interaction} />;
  }
  if (
    leg.doc.call_direction === 'outbound' &&
    leg.doc.custom_channel_vars?.resource_type === 'offnet-termination'
  ) {
    return <Leg_ExternalOutbound leg={leg} interaction={interaction} />;
  }
  if (
    leg.doc.call_direction === 'inbound' &&
    (!leg.doc.custom_channel_vars?.resource_type ||
      leg.doc.custom_channel_vars?.resource_type === 'onnet-origination') &&
    hangupCauseIsFailure(leg.doc.hangup_cause)
  ) {
    return <Leg_InternalFailure leg={leg} interaction={interaction} />;
  }
  if (
    leg.doc.call_direction === 'inbound' &&
    (!leg.doc.custom_channel_vars?.resource_type ||
      leg.doc.custom_channel_vars?.resource_type === 'onnet-origination') &&
    leg.doc.caller_destination_number === 'conference'
  ) {
    return <Leg_InternalConference leg={leg} interaction={interaction} />;
  }
  if (
    leg.doc.call_direction === 'inbound' &&
    (!leg.doc.custom_channel_vars?.resource_type ||
      leg.doc.custom_channel_vars?.resource_type === 'onnet-origination')
  ) {
    return <Leg_InternalInbound leg={leg} interaction={interaction} />;
  }
  if (
    leg.doc.call_direction === 'outbound' &&
    (!leg.doc.custom_channel_vars?.resource_type ||
      leg.doc.custom_channel_vars?.resource_type === 'onnet-termination')
  ) {
    return <Leg_InternalOutbound leg={leg} interaction={interaction} />;
  }

  return (
    <Grid item>
      <Card variant="outlined">
        <Typography variant="body1" bold>
          {title}
        </Typography>
        <div>unknown leg</div>
      </Card>
    </Grid>
  );
};

const Leg_ExternalInbound = props => {
  const { interaction, leg } = props;

  return (
    <Grid item>
      <Card variant="outlined">
        <Grid container spacing={3} wrap={'nowrap'}>
          <GridIcons icons={[<PersonIcon />, <BusinessIcon />]} />
          <Grid item style={{ flex: 1 }}>
            <Typography variant="body1" bold>
              External to Switch
            </Typography>

            <LegCommon {...props} />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

const Leg_ExternalOutbound = props => {
  const { interaction, leg } = props;

  return (
    <Grid item>
      <Card variant="outlined">
        <Grid container spacing={3} wrap={'nowrap'}>
          <GridIcons icons={[<BusinessIcon />, <PublicIcon />]} />
          <Grid item style={{ flex: 1 }}>
            <Typography variant="body1" bold>
              Switch to External
            </Typography>

            <LegCommon {...props} />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

const Leg_InternalInbound = props => {
  const { interaction, leg } = props;

  return (
    <Grid item>
      <Card variant="outlined">
        <Grid container spacing={3} wrap={'nowrap'}>
          <GridIcons icons={[<PersonIcon />, <BusinessIcon />]} />
          <Grid item style={{ flex: 1 }}>
            <Typography variant="body1" bold>
              User/Device to Switch
            </Typography>

            <LegCommon {...props} />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

const Leg_InternalOutbound = props => {
  const { interaction, leg } = props;

  return (
    <Grid item>
      <Card variant="outlined">
        <Grid container spacing={3} wrap={'nowrap'}>
          <GridIcons icons={[<BusinessIcon />, <PersonIcon />]} />
          <Grid item style={{ flex: 1 }}>
            <Typography variant="body1" bold>
              Switch to User/Device
            </Typography>

            <LegCommon {...props} />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

const Leg_InternalConference = props => {
  const { interaction, leg } = props;

  return (
    <Grid item>
      <Card variant="outlined">
        <Grid container spacing={3} wrap={'nowrap'}>
          <GridIcons icons={[<PersonIcon />, <VideocamIcon />]} />
          <Grid item style={{ flex: 1 }}>
            <Typography variant="body1" bold>
              User/Device to Conference
            </Typography>

            <LegCommon {...props} />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

const Leg_InternalFailure = props => {
  const { interaction, leg } = props;

  return (
    <Grid item>
      <Card variant="outlined">
        <Grid container spacing={3} wrap={'nowrap'}>
          <GridIcons icons={[<PersonIcon />, <WarningIcon />]} />
          <Grid item style={{ flex: 1 }}>
            <Typography variant="body1" bold>
              User/Device to Switch (Problem)
            </Typography>

            <LegCommon {...props} />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

const Leg_Loopback = props => {
  const { interaction, leg } = props;

  return (
    <Grid item>
      <Card variant="outlined">
        <Grid container spacing={3} wrap={'nowrap'}>
          <GridIcons icons={[<AllInclusiveIcon />]} />
          <Grid item style={{ flex: 1 }}>
            <Typography variant="body1" bold>
              Loopback Leg
            </Typography>

            <LegCommon {...props} />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

const LegCommon = ({ interaction, leg }) => {
  const [showDetails, setShowDetails] = useState(null);
  const setShowDetailsWrap = val => () => {
    setShowDetails(val);
  };

  return (
    <div style={{ padding: '8px 0' }}>
      <div>
        <strong>Hangup Cause: </strong>
        {leg.doc.hangup_cause}
      </div>
      <div>
        <strong>Disposition: </strong>
        {leg.doc.disposition}
      </div>
      <br />
      <div>
        <Link onClick={setShowDetailsWrap(showDetails ? null : true)}>
          Details
        </Link>
        {showDetails && (
          <div
            style={{
              width: 500,
              marginLeft: -80,
              paddingTop: 5,
              overflowX: 'auto',
            }}
          >
            <ReactJson src={leg} />
          </div>
        )}
      </div>
    </div>
  );
};
