import { Tooltip } from 'app/design';
import { AsYouType } from 'libphonenumber-js';
import React from 'react';
// import Flag from 'react-flags';
import { PhoneNumberFlag } from '../PhoneNumberFlag';

interface PhoneNumberDisplayProps {
  ptn?: string;
}

export const PhoneNumberDisplay = ({
  ptn,
  // size = 'small',
  // allowExtension = false,
  width = undefined,
  flagStyle = {},
  hideFlag = false,
  flagOnly = false,
}: {
  ptn: any;
  width?: any;
  flagStyle?: any;
  hideFlag?: any;
  flagOnly?: any;
}) => {
  let asYouType;
  let number;
  let code;

  let isExtension = ptn?.length < 8 ? true : false;
  if (isExtension) {
  } else {
    asYouType = new AsYouType();
    asYouType.input(ptn?.length === 10 ? `+1${ptn}` : ptn); // default to USA if only 10 digits
    number = asYouType.getNumber();
    code = number?.country ?? 'unknown';
    // console.log('number:', number);
  }

  // const sizes = {
  //   small: 12,
  //   medium: 24,
  //   large: 36,
  // };

  const wrapStyle = {
    display: 'inline-block',
    width: width || '100%',
    whiteSpace: 'nowrap',
  };

  flagStyle = { verticalAlign_OLD: 'middle', ...flagStyle };
  const numStyle = { marginLeft: 4 };

  if (isExtension) {
    return (
      // @ts-ignore
      <span style={wrapStyle}>
        <span>{ptn}</span>
      </span>
    );
  }

  return flagOnly ? (
    <span style={flagStyle}>
      <PhoneNumberFlag ptn={number?.number} />
    </span>
  ) : !hideFlag ? (
    // @ts-ignore
    <span style={wrapStyle}>
      <Tooltip
        title={<>{number?.formatInternational()}</>}
        placement="top"
        // type="light"
        arrow
      >
        <span style={flagStyle}>
          <PhoneNumberFlag ptn={number?.number} />
        </span>
      </Tooltip>
      <span style={numStyle}>{number?.formatNational()}</span>
    </span>
  ) : (
    <span style={numStyle}>{number?.formatNational().split(' ').join('')}</span>
  );
};

export default PhoneNumberDisplay;
