import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAuthSlice, selectAuth } from 'app/data/auth';

import { Box, AppBar, Toolbar, Typography } from 'app/design';

import { Header } from 'app/components/Header';
import { Sidebar } from 'app/components/Sidebar';

function Layout({ children }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Header />
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          minWidth: 0,
        }}
      >
        <Toolbar variant="dense" />
        {children}
      </Box>
    </Box>
  );
}

export default Layout;
