import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from 'app/design';
import { getTimeZones } from '@vvo/tzdb';
import { useUpdateAccountPartial } from 'app/hooks/mutations/account';
import { useAccountQuery } from 'app/hooks/queries/account';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

export const TIMEZONE_LIST = getTimeZones().map(tz => ({
  label: `${tz.name.replace('_', ' ')} (${tz.abbreviation})`,
  value: tz.name,
}));

// interface for useForm. Not required as it can be inferred but
// - prevents typos and provides a layer of documentation and prevents
// - incorrect registers
interface AccountTimezoneForm {
  timezone?: { label: string; value: string };
}

// interface declaring which props are required/allowed
interface AccountTimezoneDialogProps {
  onCancel: () => void;
  onComplete: () => void;
}

const TimezoneDialogAccount = ({
  onCancel,
  onComplete,
}: AccountTimezoneDialogProps) => {
  const {
    data: account,
    isLoading: accountIsLoading,
    refetch: refetchAccount,
  } = useAccountQuery();
  const updateAccount = useUpdateAccountPartial();

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useForm<AccountTimezoneForm>();

  const onSubmit = async (accountForm: AccountTimezoneForm) => {
    const { timezone } = accountForm;

    const updateAccountPromise = updateAccount.mutateAsync(
      {
        id: account?.id!,
        timezone: timezone?.value ?? null,
      },
      {
        onSuccess: () => {
          refetchAccount();
          onComplete();
        },
      },
    );

    toast.promise(updateAccountPromise, {
      pending: 'Updating account timezone...',
      error: 'Failed to update account timezone.',
      success: 'Account timezone updated!',
    });
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Dialog open={true} fullWidth maxWidth={'sm'}>
      {accountIsLoading ? (
        <DialogInProgress title={'Loading account...'} />
      ) : updateAccount.isLoading ? (
        <DialogInProgress title={'Updating account timezone...'} />
      ) : (
        <>
          <DialogTitle>Edit Account Timezone</DialogTitle>
          <Divider />
          <>
            <DialogContent>
              {account ? (
                <Controller
                  name="timezone"
                  control={control}
                  defaultValue={TIMEZONE_LIST.find(
                    tz => tz.value === account.doc.timezone,
                  )}
                  // rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <Autocomplete
                      options={TIMEZONE_LIST}
                      getOptionLabel={option => option.label}
                      value={value}
                      onChange={(event, value) => {
                        onChange(value);
                      }}
                      renderInput={params => {
                        // @ts-ignore
                        params.InputLabelProps.shrink = true;
                        return (
                          <TextField
                            {...params}
                            label={'Timezone'}
                            placeholder={'select'}
                          />
                        );
                      }}
                    />
                  )}
                />
              ) : (
                <Typography color={'error'}>Invalid Account ID.</Typography>
              )}
            </DialogContent>
            <Divider />
            <DialogActions sx={{ justifyContent: 'space-between' }}>
              <Button
                variant={'outlined'}
                color={'error'}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              {updateAccount.error ? (
                <Typography color={'error'}>
                  {`${updateAccount.error.message} ${
                    updateAccount.error.response
                      ? `: ${updateAccount.error.response?.statusText}`
                      : ''
                  }`}
                </Typography>
              ) : null}
              <Button
                variant={'outlined'}
                color={'success'}
                disabled={!isDirty}
                onClick={handleSubmit(onSubmit)}
              >
                Update
              </Button>
            </DialogActions>
          </>
        </>
      )}
    </Dialog>
  );
};

export default TimezoneDialogAccount;
