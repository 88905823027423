import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
} from 'app/design';
import { useFindInPage } from 'app/components/FindInPage';
import { useUpdateGroupPartial } from 'app/hooks/mutations/group';
import { RingGroup } from 'app/pages/admin/Groups/View/components/GroupRingStrategyCard/RingGroup';
import { useToggleReducer } from 'app/utilities';
import { useState } from 'react';
import * as React from 'react';
import { toast } from 'react-toastify';
import { Group } from 'types/group';

interface GroupRingStrategyCardProps {
  group: Group;
  onComplete: () => void;
}

const GroupRingStrategyCard = ({
  group,
  onComplete,
}: GroupRingStrategyCardProps) => {
  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'ringStrategy',
    options: [
      {
        label: 'Ring Strategy',
        value: 'ringStrategy',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });
  const updateGroup = useUpdateGroupPartial();

  const handleOnComplete = () => {
    onComplete();
    toggleShowEditDialog();
  };

  const [tmpGroup, setTmpGroup] = useState(() =>
    JSON.parse(JSON.stringify(group)),
  );

  const handleSave = async () => {
    const updateGroupPromise = updateGroup.mutateAsync(tmpGroup.doc, {
      onSuccess: () => {
        onComplete();
      },
      onError: () => {
        handleReset();
      },
    });

    // resolve promise with toast notifications
    toast.promise(updateGroupPromise, {
      pending: 'Updating group ring strategy...',
      error: 'Failed to update group ring strategy.',
      success: 'Group ring strategy updated!',
    });
    // const returnedGroup = await KazooSDK.resourceGroupPatch(tmpGroup.doc);
    // dispatchSyncSingleItem({ collectionKey: 'groups', id: group.id });
  };

  const handleReset = async () => {
    setTmpGroup(JSON.parse(JSON.stringify(group)));
  };

  return (
    // @ts-ignore
    <Card ref={ref} variant="dashboard">
      <HighlightPulse {...highlightPulseProps} />
      <CardHeader title="Group Ring Strategy" />
      <CardContent>
        {updateGroup.isLoading ? (
          <div style={{ placeItems: 'center', display: 'grid' }}>
            <CircularProgress variant={'indeterminate'} />
            Updating ring strategy...
          </div>
        ) : (
          <RingGroup
            allow={['user', 'device']}
            modifyPath={`doc`} // root-level??
            strategyCallflow={tmpGroup}
            setStrategyCallflow={setTmpGroup} // SHOULD BE UNCOMMENTED!
            customizeOptions={{}} // TODO: set defaults for timeout, strategy, limits to types of devices, etc
            required
            useGroupFormat
            onSave={handleSave}
            onReset={handleReset}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default GroupRingStrategyCard;
