import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogContent,
  Grid,
  Tooltip,
  Typography,
} from 'app/design';
import DefaultTable from 'app/components/DefaultTable/DefaultTable';
import DefaultTablePagination from 'app/components/DefaultTablePagination/DefaultTablePagination';
import { QuickFinderDialogActions } from 'app/components/QuickFinderDialogActions';
import QuickFinderDialogShell from 'app/components/QuickFinderDialogShell/QuickFinderDialogShell';
import { SlateSearch } from 'app/components/SlateSearch';
import { isEqual, pickBy } from 'lodash';
import * as React from 'react';
import { Dispatch, ReactNode, useEffect } from 'react';
import { usePagination, useRowSelect, useTable } from 'react-table';
import { QuickFinderDialogProps } from 'types/components';

interface QuickFinderGenericDialogProps extends QuickFinderDialogProps {
  empty?: string;
  columns: any[];
  data: any[];
  totalCount: number;
  queryPageSize: number;
  queryPageIndex: number;
  queryPaginationDispatch: Dispatch<{ type: any; payload: any }>;
  queryIsFetching: boolean;
  queryIsLoading: boolean;
  searchPlaceholder: string;
  title: string;
  getRowId: (row: any) => string;
  onSearch: (search: string) => void;
  titleButton?: ReactNode;
}

const removeExcludes = (selected, exclude) => {
  if (exclude) {
    const excludeIds = exclude.map(e => e.id);
    return selected.filter(select => !excludeIds.includes(select));
  }

  return selected;
};

const QuickFinderGenericDialog = ({
  onSelect,
  onSearch,
  mutationLoading = false,
  mutationLoadingLabel = 'Loading..',
  empty,
  errorMessage,
  onCancel,
  onClear,
  multiple = false,
  allowSelectNone = false,
  columns,
  data,
  totalCount,
  queryPageSize,
  queryPageIndex,
  queryPaginationDispatch,
  queryIsFetching,
  queryIsLoading,
  searchPlaceholder,
  title,
  titleButton,
  getRowId,
  initialSelected = [],
  selectionTitle,
  exclude,
  passResourceOnSelect = false,
}: QuickFinderGenericDialogProps) => {
  // custom table state reducer to handle exclusions from toggle all rows
  // - default behavior
  const tableReducer = (newState, action, prevState) => {
    switch (action.type) {
      case 'toggleAllPageRowsSelected':
        const excludeIds = exclude?.map(excluded => excluded.id) ?? [];

        // remove excluded selected rows
        let selectedRowIds = pickBy(
          newState.selectedRowIds,
          (value, key) => !excludeIds.includes(key),
        );

        // if the state is the same as the previous, we
        //  - know it was a deselect toggle and clear the remaining ids
        //  - from this page (this check to toggle false is normally based on
        //  - if all rows are selected, which is never true with excludes,
        //  - so we need to manually remove them)
        if (isEqual(selectedRowIds, prevState.selectedRowIds)) {
          const pageIds = data.map(row => row.id);
          selectedRowIds = pickBy(
            selectedRowIds,
            (value, key) => !pageIds.includes(key),
          );
        }

        return { ...newState, selectedRowIds };
    }

    return newState;
  };

  // only triggered if multiple is false
  const handleSelect = selected => () => {
    onSelect([selected]);
  };

  // table state
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      // we need to pass a custom state reducer to handle excludes in row toggle all row selection
      stateReducer: tableReducer,
      columns: columns,
      data: data,
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        // add initially selected rows
        selectedRowIds: Object.assign(
          {},
          ...removeExcludes(initialSelected, exclude).map(selected => ({
            [selected]: true,
          })),
        ),
      },
      getRowId,
      autoResetPage: false,
      autoResetSelectedRows: false,
      autoResetRowState: false,
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: totalCount ? Math.ceil(totalCount / queryPageSize) : null,
    },
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        multiple // add multiple select column
          ? {
              id: 'selection',
              Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <Checkbox
                  sx={{ fontSize: 20 }}
                  {...getToggleAllPageRowsSelectedProps()}
                />
              ),
              Cell: ({ row }) => {
                // override react-table props if id is excluded
                // to handle checked and indeterminate state
                const excluded = exclude?.find(
                  excluded => excluded.id === row.id,
                );
                const props = excluded
                  ? {
                      checked: excluded.checked,
                      indeterminate: excluded.indeterminate,
                    }
                  : row.getToggleRowSelectedProps();

                return (
                  <Tooltip
                    arrow
                    title={excluded?.reason ?? ''}
                    placement={'left'}
                  >
                    <Box>
                      <Checkbox
                        sx={{ fontSize: 20 }}
                        {...props}
                        disabled={!!excluded}
                      />
                    </Box>
                  </Tooltip>
                );
              },
            }
          : // add single select column
            {
              id: 'selection',
              Cell: ({ row }) => {
                // disable select if row is currently selected
                const { checked } = row.getToggleRowSelectedProps();
                return (
                  <Button
                    variant={'outlined'}
                    size={'small'}
                    onClick={handleSelect(
                      passResourceOnSelect ? row.original : row.id,
                    )}
                    disabled={checked}
                  >
                    Select{checked ? 'ed' : ''}
                  </Button>
                );
              },
            },
        ...columns,
      ]);
    },
  );

  // select when multiple is true
  const handleMultiSelect = () => {
    onSelect(Object.keys(selectedRowIds));
  };

  // only for multi select
  const selectedIds = Object.keys(selectedRowIds);

  return (
    <QuickFinderDialogShell
      title={title}
      titleButton={titleButton}
      selectionTitle={selectionTitle}
      loading={mutationLoading}
      loadingLabel={mutationLoadingLabel}
    >
      <DialogContent>
        <Grid container direction="column" spacing={1} sx={{ width: '100%' }}>
          <Grid item>
            <SlateSearch
              loading={queryIsFetching}
              onSearch={onSearch}
              placeholder={searchPlaceholder}
            />
          </Grid>
          {queryIsLoading ? (
            <Box sx={{ display: 'grid', placeItems: 'center', padding: 4 }}>
              <CircularProgress variant={'indeterminate'} />
              <Typography variant="body2">Loading results...</Typography>
            </Box>
          ) : (
            <>
              <Grid item>
                <DefaultTable
                  getTableProps={getTableProps}
                  getTableBodyProps={getTableBodyProps}
                  page={page}
                  headerGroups={headerGroups}
                  prepareRow={prepareRow}
                  emptyRowCount={pageSize - page.length}
                  queryPaginationDispatch={queryPaginationDispatch}
                  exclude={exclude}
                />
                {!totalCount ? (
                  <Box
                    sx={{
                      padding: 3,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#fcfcfc',
                      // border: '0.5px solid #e6e6e6',
                      borderTop: 0,
                    }}
                  >
                    {empty ? empty : 'No Results'}
                  </Box>
                ) : null}
              </Grid>
              <Grid item>
                <DefaultTablePagination
                  totalRowCount={totalCount}
                  rowsPerPage={queryPageSize}
                  pageIndex={pageIndex}
                  queryPaginationDispatch={queryPaginationDispatch}
                  gotoPage={gotoPage}
                  setPageSize={setPageSize}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <QuickFinderDialogActions
        errorMessage={errorMessage}
        multiple={multiple}
        allowSelectNone={allowSelectNone}
        onSelect={handleMultiSelect}
        onCancel={onCancel}
        onClear={initialSelected.length ? onClear : undefined}
        selectedIds={selectedIds}
      />
    </QuickFinderDialogShell>
  );
};

export default QuickFinderGenericDialog;
