import { useAuthSelector } from 'app/data/auth';
import { useQuery } from 'react-query';
import { sdk } from 'app/sdk';
import { GroupsPageResponse } from 'types/group';
import groupQueryKeys from './groupQueryKeys';

export const useGroupsListQuery = (
  skip: number,
  take: number,
  orderBy: any,
  filters: any,
) => {
  // returns list of groups
  // - just get ALL groups+relations
  // - TODO v2: can be filtered/etc
  const authState = useAuthSelector();
  const queryKey = groupQueryKeys.list(skip, take, orderBy, filters);

  const query = useQuery<GroupsPageResponse, Error>(
    queryKey,
    () =>
      sdk.group.query.groups(
        {
          skip,
          take,
          orderBy,
          filters,
        },
        { authToken: authState.auth_token },
      ),
    {
      // enabled: authState?.auth_token_decoded?.owner_id ? true : false,
      // prevents pagination from looking like a hard load;
      //  - keeps previous data displayed until new data arrives
      keepPreviousData: true,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default useGroupsListQuery;
