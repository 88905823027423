import { sdk } from 'app/sdk';
import React from 'react';

const setupNumberDetails = async ({
  auth_token,
  ptn,
  user,
  companyName,
  address,
}) => {
  // get number, so that we don't overwrite the "cnam" values

  const findNumber = await sdk.phoneNumber.query.phoneNumberById(
    { id: ptn },
    { authToken: auth_token },
  );

  // console.log('findNumber:', findNumber);
  // console.log('address:', address);

  const numberResult = await sdk.phoneNumber.mutate.updatePartial(
    {
      id: ptn,
      cnam: {
        ...findNumber.doc.cnam,
        display_name: companyName?.substr(0, 14),
      },
      e911: {
        caller_name: user.extra.fullName.substr(0, 14),
        street_address: address.streetLine, // line 1
        extended_address: address.secondary,
        locality: address.city,
        region: address.state, // state
        postal_code: address.zipcode,
        plus_four: '',
      },
    },
    auth_token,
  );

  console.log('numberResult:', numberResult);
  if (numberResult.status !== 'success') {
    throw 'Failed saving number';
  }
};

export default setupNumberDetails;
