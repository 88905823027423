import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InfoTooltip,
  Link,
  TextField,
  Typography,
} from 'app/design';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { QuickFinderExtensionDialog } from 'app/components/QuickFinderExtensionDialog';
import { QuickFinderPhoneNumberDialog } from 'app/components/QuickFinderPhoneNumberDialog';
import { useUpdatePhoneNumberPartial } from 'app/hooks/mutations/phoneNumbers';
import { useUpdateUserPartial } from 'app/hooks/mutations/user';
import { useAccountQuery } from 'app/hooks/queries/account';
import { usePhoneNumberQuery } from 'app/hooks/queries/phoneNumber';
import { useUserQuery } from 'app/hooks/queries/user';
import { useToggleReducer } from 'app/utilities';
import { useMarkdownTranslate } from 'app/utilities/translate';
import { useEffect } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Account } from 'types/account';
import { CallerId, CallForward } from 'types/generic';
import { PhoneNumber } from 'types/phoneNumber';
import { User } from 'types/user';
import { Clear as ClearIcon } from 'app/design/icons-material';
import { useImmer } from 'use-immer';

// interface for useForm. Not required as it can be inferred but
// - prevents typos and provides a layer of documentation and prevents
// - incorrect registers
interface UserCallerIdFormFields {
  internalName?: string;
  internalNumber?: string | null;
  externalName?: string;
  externalNumber?: string | null;
  emergencyNumber?: string | null;
  updateExternalName: boolean;
}

// interface declaring which props are required/allowed
interface UserCallerIdDialogProps {
  userId: string;
  onCancel: () => void;
  onComplete: () => void;
}

const UserCallerIdDialog = ({
  userId,
  onCancel,
  onComplete,
}: UserCallerIdDialogProps) => {
  const { data: user, isLoading: userIsLoading } = useUserQuery(userId, {
    onSuccess: user => {
      setFormDefaults({
        internalName: user?.doc.caller_id?.internal?.name,
        internalNumber: user?.doc.caller_id?.internal?.number,
        externalName: externalNumber?.doc.cnam?.display_name,
        externalNumber: externalNumber?.doc.id,
        emergencyNumber: user?.doc.caller_id?.emergency?.number,
        updateExternalName: false,
      });
    },
  });
  const { data: externalNumber, isLoading: externalNumberIsLoading } =
    usePhoneNumberQuery({ id: user?.doc.caller_id?.external?.number });
  const { data: account, isLoading: accountIsLoading } = useAccountQuery();
  const updateUser = useUpdateUserPartial();
  const updatePhoneNumber = useUpdatePhoneNumberPartial();
  const [formDefaults, setFormDefaults] = useImmer<UserCallerIdFormFields>({
    internalName: user?.doc.caller_id?.internal?.name,
    internalNumber: user?.doc.caller_id?.internal?.number,
    externalName: externalNumber?.doc.cnam?.display_name,
    externalNumber: externalNumber?.doc.id,
    emergencyNumber: user?.doc.caller_id?.emergency?.number,
    updateExternalName: false,
  });
  // const {
  //   data: phoneNumbersPageResponse,
  //   isLoading: phoneNumbersAreLoading,
  //   refetch: refetchPhoneNumbers,
  // } = useListPhoneNumbersQuery(0, 1000, [['number', 'asc']], {
  //   searchInput: '',
  // });
  // const { data: extensionsPageResponse, isLoading: extensionsAreLoading } =
  //   useListExtensionsQuery(0, 1000, [['number', 'asc']], {
  //     searchInput: '',
  //   });

  const handleSubmit = async (userForm: UserCallerIdFormFields) => {
    // update form defaults incase of error
    setFormDefaults(userForm);

    const {
      externalNumber,
      externalName,
      emergencyNumber,
      internalNumber,
      internalName,
      updateExternalName,
    } = userForm;

    const updateUserPromise = updateUser.mutateAsync({
      id: userId,
      caller_id: {
        internal: {
          name: internalName,
          number: internalNumber,
        },
        external: {
          number: externalNumber,
        },
        emergency: {
          number: emergencyNumber,
        },
      },
    });

    let promises = [updateUserPromise];

    // need to update phone number external label?
    if (externalNumber && updateExternalName) {
      const updatePhoneNumberPromise = updatePhoneNumber.mutateAsync({
        id: externalNumber,
        cnam: {
          display_name: externalName || undefined,
        },
      });
      promises.push(updatePhoneNumberPromise);
    }

    // resolve promise with toast notifications
    const results = await toast.promise(Promise.all(promises), {
      pending: 'Updating user caller ID...',
      error: 'Failed to update user caller ID.',
      success: 'User caller ID updated!',
    });

    const errorResponse = results.find(result => result.status !== 'success');

    if (errorResponse) {
      console.log('error', errorResponse);
    } else {
      onComplete();
      // await refetchPhoneNumbers();
    }
  };

  return (
    <Dialog open={true} fullWidth maxWidth={'sm'}>
      {userIsLoading || accountIsLoading || externalNumberIsLoading ? (
        <DialogInProgress title={'Loading user caller ID info...'} />
      ) : updateUser.isLoading || updatePhoneNumber.isLoading ? (
        <DialogInProgress title={'Updating User Caller ID...'} />
      ) : (
        <>
          <DialogTitle>Edit User Caller ID</DialogTitle>
          <Divider />
          {user ? (
            <UserCallerIdForm
              defaultFormValues={formDefaults}
              initialExternalNumber={externalNumber}
              account={account!}
              onCancel={onCancel}
              onSubmit={handleSubmit}
              errorMessage={
                updateUser.error
                  ? `${updateUser.error.message} ${
                      updateUser.error.response
                        ? `: ${updateUser.error.response?.statusText}`
                        : ''
                    }`
                  : updatePhoneNumber.error
                  ? `${updatePhoneNumber.error.message} ${
                      updatePhoneNumber.error.response
                        ? `: ${updatePhoneNumber.error.response?.statusText}`
                        : ''
                    }`
                  : undefined
              }
            />
          ) : (
            <>
              <DialogContent>
                <Typography color={'error'}>Invalid User ID.</Typography>
              </DialogContent>
              <Divider />
              <DialogActions sx={{ justifyContent: 'left' }}>
                <Button variant={'outlined'} color={'error'} onClick={onCancel}>
                  Cancel
                </Button>
              </DialogActions>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};

interface UserCallerIdFormProps {
  defaultFormValues: UserCallerIdFormFields;
  account: Account;
  initialExternalNumber?: PhoneNumber;
  onCancel: () => void;
  onSubmit: (formValues: UserCallerIdFormFields) => void;
  errorMessage?: string;
  startDirty?: boolean;
}

const UserCallerIdForm = ({
  account,
  defaultFormValues,
  onCancel,
  onSubmit,
  errorMessage,
  startDirty = false,
}: UserCallerIdFormProps) => {
  const { t } = useMarkdownTranslate();

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useForm<UserCallerIdFormFields>({
    defaultValues: defaultFormValues,
  });

  const formWatch = watch();

  const { data: externalNumber, isLoading: externalNumberIsLoading } =
    usePhoneNumberQuery({ id: formWatch.externalNumber });

  useEffect(() => {
    setValue('externalName', externalNumber?.doc.cnam?.display_name);
  }, [externalNumber]);

  // flag whether to update external number display name
  useEffect(() => {
    if (dirtyFields.externalName) {
      setValue('updateExternalName', true);
    } else {
      setValue('updateExternalName', false);
    }
  }, [dirtyFields.externalName]);

  const handleSetInternal = (number: string | null) => {
    setValue('internalNumber', number, { shouldDirty: true });
  };

  const handleSetExternal = (number: string | null) => {
    setValue('externalNumber', number, { shouldDirty: true });
  };

  const handleSetEmergency = (number: string | null) => {
    setValue('emergencyNumber', number, { shouldDirty: true });
  };

  return (
    <>
      <DialogContent>
        <>
          {/* Internal CID */}
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant={'h6'}>Internal</Typography>
            </Grid>
            <Grid item>
              <InfoTooltip
                size={'large'}
                title={t('caller_id.internal.tooltip')}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item md={4}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                label={'Name'}
                placeholder={'leave empty'}
                {...register('internalName')}
                helperText={
                  formWatch.internalName
                    ? undefined
                    : account.doc.caller_id?.internal?.name
                    ? `Will use internal account name:
                     ${account.doc.caller_id?.internal?.name}
                    `
                    : 'no account default set. Please set up account caller ID'
                }
              />
            </Grid>
            <Grid item md={5}>
              <div style={{ position: 'relative' }}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Number'}
                  {...register('internalNumber')}
                  helperText={
                    formWatch.internalNumber ? undefined : account.doc.caller_id
                        ?.internal?.number ? (
                      <Grid container>
                        <Grid item>Will use internal account number:</Grid>
                        <Grid item>
                          {/* @ts-ignore */}
                          <PhoneNumberDisplay
                            ptn={account.doc.caller_id?.internal?.number}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      'no account default set. Please set up account caller ID'
                    )
                  }
                />

                {/* internal number display */}
                {/*{formWatch.internalNumber ? (
                  <div style={{ position: 'absolute', top: 9, left: 15 }}>
                    <Grid container alignItems={'center'}>
                      <Grid item>
                        <Typography variant={'h6'}>
                           @ts-ignore
                          <PhoneNumberDisplay ptn={formWatch.internalNumber} />
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => handleSetInternal(null)}>
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                ) : null}*/}
              </div>
            </Grid>

            {/* Links to select ext/number for internal number */}
            <Grid item md={3}>
              <NumberSelect
                number={formWatch.internalNumber}
                onSelect={number => handleSetInternal(number)}
              />
              <ExtensionSelect
                ext={formWatch.internalNumber}
                onSelect={ext => handleSetInternal(ext)}
              />
            </Grid>
          </Grid>
          <br />
          {/* External CID */}
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant={'h6'}>External</Typography>
            </Grid>
            <Grid item>
              <InfoTooltip
                size={'large'}
                title={t('caller_id.external.tooltip')}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item md={4}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                label={'Name'}
                placeholder={
                  externalNumberIsLoading
                    ? 'Loading'
                    : formWatch.externalNumber
                    ? 'leave empty'
                    : 'select number'
                }
                {...register('externalName')}
                error={!!formErrors.externalName}
                disabled={externalNumberIsLoading || !formWatch.externalNumber}
                helperText={
                  '*Will change name for this number in all locations'
                }
              />
            </Grid>
            <Grid item md={5}>
              <div style={{ position: 'relative' }}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Number'}
                  disabled
                  value={formWatch.externalNumber ? '' : 'Select number'}
                  helperText={
                    formWatch.externalNumber ? undefined : account.doc.caller_id
                        ?.external?.number ? (
                      <Grid container>
                        <Grid item>Will use external account number:</Grid>
                        <Grid item>
                          {/* @ts-ignore */}
                          <PhoneNumberDisplay
                            ptn={account.doc.caller_id?.external?.number}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      'no account default set. Please set up account caller ID'
                    )
                  }
                />

                {/* external number display */}
                {formWatch.externalNumber ? (
                  <div style={{ position: 'absolute', top: 9, left: 15 }}>
                    <Grid container alignItems={'center'}>
                      <Grid item>
                        <Typography variant={'h6'}>
                          {/* @ts-ignore*/}
                          <PhoneNumberDisplay ptn={formWatch.externalNumber} />
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => handleSetExternal(null)}>
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                ) : null}
              </div>
            </Grid>
            {/* Links to select number for external number */}
            <Grid item md={3}>
              <NumberSelect
                number={formWatch.externalNumber}
                onSelect={number => handleSetExternal(number)}
              />
            </Grid>
          </Grid>
          <br />
          {/* Emergency CID */}
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant={'h6'}>Emergency</Typography>
            </Grid>
            <Grid item>
              <InfoTooltip
                size={'large'}
                title={t('caller_id.emergency.tooltip')}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item md={5}>
              <div style={{ position: 'relative' }}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Number'}
                  disabled
                  value={formWatch.emergencyNumber ? '' : 'Select number'}
                  helperText={
                    formWatch.emergencyNumber ? undefined : account.doc
                        .caller_id?.emergency?.number ? (
                      <Grid container>
                        <Grid item>Will use emergency account number:</Grid>
                        <Grid item>
                          {/* @ts-ignore */}
                          <PhoneNumberDisplay
                            ptn={account.doc.caller_id?.emergency?.number}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      'no account default set. Please set up account caller ID'
                    )
                  }
                />

                {/* emergency number display */}
                {formWatch.emergencyNumber ? (
                  <div style={{ position: 'absolute', top: 9, left: 15 }}>
                    <Grid container alignItems={'center'}>
                      <Grid item>
                        <Typography variant={'h6'}>
                          {/* @ts-ignore*/}
                          <PhoneNumberDisplay ptn={formWatch.emergencyNumber} />
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => handleSetEmergency(null)}>
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                ) : null}
              </div>
            </Grid>
            {/* Links to select number for emergency number */}
            <Grid item md={3}>
              <NumberSelect
                number={formWatch.emergencyNumber}
                onSelect={number => handleSetEmergency(number)}
              />
            </Grid>
          </Grid>
        </>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button variant={'outlined'} color={'error'} onClick={onCancel}>
          Cancel
        </Button>
        {errorMessage ? (
          <Typography color={'error'}>{errorMessage}</Typography>
        ) : null}
        <Button
          variant={'outlined'}
          color={'success'}
          disabled={
            externalNumberIsLoading || startDirty ? !startDirty : !isDirty
          }
          onClick={handleSubmit(onSubmit)}
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

const NumberSelect = ({ number, onSelect }) => {
  const [showDialog, toggleShowDialog] = useToggleReducer(false);

  const handleSelect = (selected: string[]) => {
    const [number] = selected;
    onSelect(number);
    toggleShowDialog();
  };

  const handleClear = () => {
    onSelect(null);
    toggleShowDialog();
  };

  return (
    <div>
      {showDialog ? (
        <QuickFinderPhoneNumberDialog
          onSelect={handleSelect}
          onCancel={toggleShowDialog}
          onClear={handleClear}
          initialSelected={[number]}
          selectionTitle={number}
        />
      ) : null}
      <Link underline={'hover'} onClick={toggleShowDialog} variant={'caption'}>
        Select Number
      </Link>
    </div>
  );
};

const ExtensionSelect = ({ ext, onSelect }) => {
  const [showDialog, toggleShowDialog] = useToggleReducer(false);

  const handleSelect = (selected: string[]) => {
    const [ext] = selected;
    onSelect(ext);
    toggleShowDialog();
  };

  const handleClear = () => {
    onSelect(null);
    toggleShowDialog();
  };

  return (
    <div>
      {showDialog ? (
        <QuickFinderExtensionDialog
          onSelect={handleSelect}
          onCancel={toggleShowDialog}
          onClear={handleClear}
          initialSelected={[ext]}
          selectionTitle={ext}
        />
      ) : null}
      <Link underline={'hover'} onClick={toggleShowDialog} variant={'caption'}>
        Select Ext
      </Link>
    </div>
  );
};

export default UserCallerIdDialog;
