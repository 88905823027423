import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Typography,
  InfoTooltip,
} from 'app/design';
import { useFindInPage } from 'app/components/FindInPage';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { HoldMusicUser } from 'app/pages/admin/Users/View/components/HoldMusicUser';
import { UserTimezone } from 'app/pages/admin/Users/View/components/UserSettingsCard/components/UserTimezone';
import { UserLoginDetails } from 'app/pages/admin/Users/View/components/UserSettingsCard/components/UserLoginDetails';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { User } from 'types/user';

interface UserSettingsCardProps {
  user: User;
  onComplete: () => void;
}

const UserSettingsCard = ({ user, onComplete }: UserSettingsCardProps) => {
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'settings',
    options: [
      {
        label: 'Settings',
        value: 'settings',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  return (
    // @ts-ignore
    <Card ref={ref} variant="outlined" sx={{ height: '100%' }}>
      <HighlightPulse {...highlightPulseProps} />
      {/* <CardHeader title="User Settings" /> */}
      <CardContent>
        <UserTimezone user={user} />
        <br />
        {/* Hold Music */}
        <HoldMusicUser user={user} onComplete={onComplete} />
        <br />
        {/* Login */}
        <UserLoginDetails user={user} />
      </CardContent>
    </Card>
  );
};

export default UserSettingsCard;
