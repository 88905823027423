import React, { useEffect, useCallback, useState, useRef } from 'react';
import useStyles from './styles';
import { Box, Button, Typography, Grid, IconButton } from 'app/design';

import { Expand as ExpandIcon } from 'app/design/icons-material';

import { useWebphoneContext } from '../../Store';

import { Dialer } from './Dialer';

const CallDetailView = (props, context) => {
  const { call, localVideoRef, remoteVideoRef, wsVideo } = props;

  const [state, dispatch] = useWebphoneContext();

  // TODO: show "ringing" option if no other calls? (unnecessary?)

  // call.hasRemoteVideo()
  const showVideo =
    call && !call.isRinging() && call.hasLocalVideo() && !wsVideo; // || call.hasRemoteVideo());
  //  TODO: call.hasRemoteVideo() && iCareAboutRemoteVideo || wantToShowMine()

  // console.log('hasLocalVideo:', call?.hasLocalVideo());
  // console.log('hasRemoteVideo:', call?.hasRemoteVideo());

  const newHeight = showVideo ? '220px' : '0px';
  const classes = useStyles({});

  return (
    <Box
      style={{
        // flex: 1,
        position: 'relative',
        height: newHeight,
        overflow: 'hidden',
        // display: call && !call.isRinging() ? 'block' : 'none', // only show if a call exists
        display: 'block',
        visibility: showVideo ? undefined : 'hidden', // only show if a call exists
        transition: 'all 0.5s ease',
        borderTop: '1px solid #ccc',
        boxShadow: showVideo
          ? '0px 0px 4px 1px #3A3A3A'
          : '0px 0px 4px 1px white',
      }}
    >
      <Box style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div>Current Active Call</div>
        <div style={{ position: 'relative', flex: 1 }}>
          <LocalVideoContainer {...props} />
          <RemoteVideoContainer {...props} />
        </div>
        <div style={{ position: 'absolute', top: 40, left: 30 }}>
          <IconButton onClick={props.onExpand}>
            <ExpandIcon />
          </IconButton>
        </div>
      </Box>
    </Box>
  );
};

const LocalVideoContainer = props => {
  const { call, localVideoRef, remoteVideoRef } = props;

  const [state, dispatch] = useWebphoneContext();

  const classes = useStyles({
    showLocalVideo: call?.hasLocalVideo(),
  });

  const handleEnableVideo = () => {
    call.offerVideo();
  };

  const handleToggleMute = () => {
    call.toggleVideoMute();
  };

  return (
    <Box sx={classes.localVideoContainer}>
      <video autoPlay ref={localVideoRef} />
      {call &&
        (call.hasLocalVideo() ? (
          //  perhaps have a "stop video" button?
          call.isVideoOnMute() ? (
            <Button size="small" variant="outlined" onClick={handleToggleMute}>
              Unmute
            </Button>
          ) : (
            <Button size="small" variant="outlined" onClick={handleToggleMute}>
              Mute
            </Button>
          )
        ) : (
          <>
            <Typography variant="body2" sx={{ color: 'white' }}>
              Video Disabled
            </Typography>
            <br />
            <Button size="small" variant="outlined" onClick={handleEnableVideo}>
              Send Video
            </Button>
          </>
        ))}
    </Box>
  );
};

const RemoteVideoContainer = props => {
  const { call, localVideoRef, remoteVideoRef } = props;

  const [state, dispatch] = useWebphoneContext();

  const handleToggleMuteVideoIn = () => {
    window.alert(
      'should be able to hide/deafen/mute the remote/incoming video',
    );
    // call.toggleVideoMute();
  };

  const classes = useStyles({
    showRemoteVideo: call?.hasLocalVideo() && call?.hasRemoteVideo(),
  });

  return (
    <Box sx={classes.remoteVideoContainer}>
      <video autoPlay ref={remoteVideoRef} />
      {/* call &&
        (call.hasRemoteVideo() ? (
          // can "hide" the remote video (ie "deafen" the remote video)
          call.isVideoInOnMute ? (
            <Button
              size="small"
              variant="outlined"
              onClick={handleToggleMuteVideoIn}
            >
              Hide Video
            </Button>
          ) : (
            <Button
              size="small"
              variant="outlined"
              onClick={handleToggleMuteVideoIn}
            >
              Show Video
            </Button>
          )
        ) : (
          <>
            <Typography variant="body2">No Remote Video</Typography>
          </>
        )) */}
    </Box>
  );
};

export default CallDetailView;
