import { Card, CardContent, CardHeader } from 'app/design';
import { DetailsDialogVmbox } from 'app/components/DetailsDialogVmbox';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { TimezoneVmbox } from 'app/pages/admin/Vmboxes/View/components/VmboxSettingsCard/components/TimezoneVmbox';
import { GreetingVmbox } from 'app/pages/admin/Vmboxes/View/components/VmboxSettingsCard/components/GreetingVmbox';
import { NameVmbox } from 'app/pages/admin/Vmboxes/View/components/VmboxSettingsCard/components/NameVmbox';
import { OperatorExtVmbox } from 'app/pages/admin/Vmboxes/View/components/VmboxSettingsCard/components/OperatorExtVmbox';
import { TempGreetingVmbox } from 'app/pages/admin/Vmboxes/View/components/VmboxSettingsCard/components/TempGreetingVmbox';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { Vmbox } from 'types/vmbox';

interface VmboxSettingsCardProps {
  vmbox: Vmbox;
  onComplete: () => void;
}

const VmboxSettingsCard = ({ vmbox, onComplete }: VmboxSettingsCardProps) => {
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'settings',
    options: [
      {
        label: 'Settings',
        value: 'settings',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  return (
    // @ts-ignore
    <Card ref={ref} variant="dashboard">
      <HighlightPulse {...highlightPulseProps} />
      <CardHeader title="Primary Settings" />
      <CardContent>
        <TimezoneVmbox vmbox={vmbox} />
        <br />
        <OperatorExtVmbox vmbox={vmbox} onComplete={onComplete} />
        <br />
        <NameVmbox vmbox={vmbox} onComplete={onComplete} />
      </CardContent>
    </Card>
  );
};

export default VmboxSettingsCard;
