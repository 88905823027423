import { Chip, Grid } from 'app/design';
import { CreatedAtTableCell } from 'app/components/CreatedAtTableCell';
import { EmptyTableCell } from 'app/components/EmptyTableCell';
import { FromCell } from 'app/reports/components/ListCdrInteractions/components/FromCell';
import { InteractionTypeCell } from 'app/reports/components/ListCdrInteractions/components/InteractionTypeCell';
import { ToCell } from 'app/reports/components/ListCdrInteractions/components/ToCell';
import { durationFormat } from 'app/reports/components/utils';
import { CdrInteractionListOptions } from './components/CdrInteractionListOptions';
import prepareStickyColumns from 'app/utilities/prepareStickyColumns';
import * as React from 'react';
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { CdrInteraction } from 'types/callDetailRecord';

export const useCdrInteractionColumns = (onComplete: () => void) =>
  useMemo(() => {
    const columns = [
      // {
      //   Header: 'ID',
      //   id: 'id',
      //   accessor: (cdrInteraction: CdrInteraction) => cdrInteraction.id,
      //   disableToggle: true,
      //   disableSortBy: true,
      // },
      {
        Header: 'Type',
        id: 'type',
        Cell: ({ row }) => {
          const interaction = row.original;
          return <InteractionTypeCell interaction={interaction} />;
        },
        headerProps: {
          width: 130,
        },
        disableToggle: true,
        disableSortBy: true,
      },
      {
        Header: 'From',
        id: 'from',
        Cell: ({ row }) => {
          const interaction = row.original;
          return <FromCell interaction={interaction} />;
        },
        headerProps: {
          width: 200,
        },
        disableToggle: true,
        disableSortBy: true,
      },
      {
        Header: 'To',
        id: 'to',
        Cell: ({ row }) => {
          const interaction = row.original;
          return <ToCell interaction={interaction} />;
        },
        headerProps: {
          width: 200,
        },
        disableToggle: true,
        disableSortBy: true,
      },
      {
        Header: 'Result',
        id: 'result',
        Cell: ({ row }) => {
          const interaction = row.original;

          switch (interaction.doc.result) {
            case 'answered':
              return 'Answered';
              break;
            case 'unanswered':
            default:
              return <EmptyTableCell label={'no answer'} />;
          }
        },
        headerProps: {
          width: 150,
        },
        disableToggle: true,
        disableSortBy: true,
      },
      {
        Header: 'Duration',
        id: 'duration',
        Cell: ({ row }) => {
          const interaction = row.original;
          if (interaction.doc.result === 'answered') {
            return durationFormat(
              interaction.doc.durationInSeconds,
              // (interaction.updatedAt - interaction.createdAt) / 1000,
            );
          }

          return <EmptyTableCell label={'none'} />;
        },
        headerProps: {
          width: 150,
        },
        disableToggle: true,
        disableSortBy: true,
      },

      {
        Header: 'Start Time',
        id: 'startTime',
        accessor: (cdrInteraction: CdrInteraction) => cdrInteraction.createdAt,
        Cell: ({ value: createdAt }: { value: Date }) => (
          <CreatedAtTableCell createdAt={createdAt} />
        ),
        headerProps: {
          width: 225,
        },
        disableSortBy: true,
        disableToggle: true,
      },
      {
        id: 'options',
        Cell: ({ row }) => (
          <CdrInteractionListOptions
            cdrInteraction={row.original}
            onComplete={onComplete}
          />
        ),
        headerProps: {
          width: 70,
        },
        disableSortBy: true,
        disableToggle: true,
      },
    ];

    prepareStickyColumns(columns);

    return columns;
  }, []);
