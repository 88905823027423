import { AudioPlayer } from 'app/components/AudioPlayer';
import { EditGreetingDialogVmbox } from 'app/components/EditGreetingDialogVmbox';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { Vmbox } from 'types/vmbox';

interface TempGreetingVmboxProps {
  vmbox: Vmbox;
  onComplete: () => void;
}

const TempGreetingVmbox = ({ vmbox, onComplete }: TempGreetingVmboxProps) => {
  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'tempGreeting',
    options: [
      {
        label: 'Temporary Greeting',
        value: 'tempGreeting',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const handleOnComplete = () => {
    onComplete();
    toggleShowEditDialog();
  };

  return (
    // @ts-ignore
    <div ref={ref}>
      <HighlightPulse {...highlightPulseProps} />
      {showEditDialog ? (
        <EditGreetingDialogVmbox
          vmboxId={vmbox.id}
          mediaId={vmbox.doc.media.temporary_unavailable}
          onComplete={handleOnComplete}
          onCancel={toggleShowEditDialog}
          temporary
        />
      ) : null}
      <GenericSettingsCardField
        title={'Temporary Greeting'}
        content={
          vmbox.doc.media.temporary_unavailable ? (
            <AudioPlayer mediaId={vmbox.doc.media.temporary_unavailable} />
          ) : undefined
        }
        editOnClick={toggleShowEditDialog}
        editLabel={
          vmbox.doc.media.temporary_unavailable
            ? 'Change Temporary Greeting'
            : 'Add Temporary Greeting'
        }
      />
    </div>
  );
};

export default TempGreetingVmbox;
