import * as React from 'react';
import { Box, CircularProgress, Typography } from 'app/design';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAuthSlice, selectAuth, useAuthSelector } from 'app/data/auth';
import { useEffectOnce } from 'react-use';
import { AppLoadingScreen } from 'app/components/AppLoadingScreen';

import {
  useQueryParams,
  StringParam,
  NumberParam,
  ArrayParam,
  withDefault,
} from 'use-query-params';
import { useQuery, useQueryClient } from 'react-query';
import { request, gql } from 'graphql-request';
import jwt from 'jsonwebtoken';
import md5 from 'md5';

//  const query = useQuery('todos', getTodos);

function AuthGate({ children }) {
  const [ready, setReady] = React.useState(false);
  const dispatch = useDispatch();
  const { actions } = useAuthSlice();
  const authState = useAuthSelector();
  // const { actions } = useAuthSlice();
  const history = useHistory();
  // console.log('AuthGate authState:', authState);

  // const authQuery = useQuery(
  //   'auth-user',
  //   async val => {
  //     console.log('Auth Query Running', val);
  //     const requestHeaders = new Headers();
  //     if (authState.auth_token) {
  //       requestHeaders.set('X-Auth-Token', authState.auth_token);
  //       const user = await fetch(
  //         `${process.env.KAPI_URL}/user_auth/${authState.auth_token}`,
  //         { headers: requestHeaders },
  //       );
  //     }
  //     return null;
  //     // const {
  //     //   posts: { data },
  //     // } = await request(
  //     //   endpoint,
  //     //   gql`
  //     //     query {
  //     //       posts {
  //     //         data {
  //     //           id
  //     //           title
  //     //         }
  //     //       }
  //     //     }
  //     //   `,
  //     // );
  //     // return data;
  //   },
  //   {
  //     enabled: false,
  //   },
  // );

  const [qs, setQs] = useQueryParams({
    auth_token: StringParam,
  });
  const { auth_token } = qs;

  useEffectOnce(() => {
    (async () => {
      // Handle querystring login values
      // - may need to reset querystring
      if (qs.auth_token) {
        // trigger auth change process
        // - remove auth_token from querystring
        dispatch(actions.authTokenChange({ auth_token: qs.auth_token }));
        setQs({ auth_token: undefined }, 'replaceIn'); // TODO: make this an action?
        // "ready" is going to have to wait for authTokenChange to complete
        // - otherwise redirection happens (ie redirects to /login cuz no existing user )
        // setReady(true);
      } else {
        if (authState.loggedIn) {
          // logged in, no auth token, just continue
          setReady(true);
        } else {
          // not logged in, no auth token, redirect to login page
          // - handled by switch to redirect automatically (or maybe an About page, or something else is possible...)
          console.log('AUTH: no credentials - probably redirect to login');
          setReady(true);
        }
      }
    })();
  });

  if (!ready && !authState.ready_after_token_change) {
    return <AppLoadingScreen />;
  }

  // if (authState.loggingIn) {
  //   return <>Authenticating...</>;
  // }

  // return <>CHILDREN</>;
  return children;
}

export default AuthGate;
