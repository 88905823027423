import React, { useEffect, useCallback, useState, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogContent,
  Box,
  Grid,
  Hidden,
  Typography,
  Tabs,
  Tab,
  Paper,
  Button,
  Chip,
  Link,
} from 'app/design';

import { Search as SearchIcon } from 'app/design/icons-material';

import Tour from 'reactour';

import { useHistory } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useEffectOnce, useHoverDirty } from 'react-use';

const disableBody = target => disableBodyScroll(target);
const enableBody = target => enableBodyScroll(target);

const WebphoneTour = props => {
  const { onClose } = props;

  const history = useHistory();
  // const dispatch = useDispatch();
  // const { callflows } = useSelector((state) => state.lists);

  const [stepNum, setStepNum] = useState(0); // TODO: use redux?

  const runningRef = useRef(null);

  const handleNextStep = () => {
    setStepNum(stepNum + 1);
  };
  const handlePrevStep = () => {
    setStepNum(stepNum - 1);
  };
  const handleAnyStep = curr => {
    console.log('curr:', curr);
  };

  const onAfterOpen = target => {
    // disableBody(target);
    console.log('onAfterOpen');
    if (window.location.pathname.indexOf('/admin') === -1) {
      history.push('/admin');
    }
  };

  const onRequestClose = target => {
    console.log('onRequestClose');
    // setIsTourOpen(false);
    // update account tour_complete
    onClose && onClose();
  };

  const tourSteps = [
    {
      selector: '[data-tour-is-webphone]',
      position: 'bottom',
      content: (
        <>
          <Typography variant="h5">Webphone</Typography>
          <Typography variant="body1">
            Make and receive calls right from your browser!
          </Typography>
        </>
      ),
      // action: node => history.push('/admin/dashboard'),
    },
  ];

  return (
    <>
      <Tour
        steps={tourSteps}
        // isOpen={isTourOpen}
        isOpen
        onRequestClose={onRequestClose}
        onAfterOpen={onAfterOpen}
        // onBeforeClose={enableBody}
        update={window.location.pathname}
        goToStep={stepNum}
        nextStep={handleNextStep}
        prevStep={handlePrevStep}
        getCurrentStep={handleAnyStep}
        showButtons={false}
        showNumber={false}
        showNavigation={false}
        // disableInteraction={true}
        showCloseButton={false}
        // closeWithMask={false}
        disableDotsNavigation={true}
        lastStepNextButton={
          <Button variant="contained" color="success">
            Let's start!
          </Button>
        }
        badgeContent={(curr, tot) => `${curr} of ${tot}`}
        // inViewThreshold={10000} // no real change it seemed like
        scrollDuration={1000}
        rounded={4}
        maskSpace={2}
        maskClassName={'tmp-mask-class'}
      />
    </>
  );
};

export default WebphoneTour;
