import React, { useState, useContext, useRef, memo, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from 'app/design';
import {
  Phone as PhoneIcon,
  Add as AddIcon,
  Audiotrack as AudiotrackIcon,
} from 'app/design/icons-material';

import { getAtPath, setAtPath } from 'app/utilities';

import { IvrMenuEventEmitterContext } from '../../..';

import {
  RingStrategiesBuilder,
  RING_STRATEGIES,
  ringTypeToComponent,
} from 'app/components/RingStrategies';

import { Box as IvrBox } from '../common/Box';
import { MenuButton } from '../common/MenuButton';
import { InTemplate } from '../common/InTemplate';
import { VariablesEditor } from '../common/VariablesEditor';

import {
  InsertAfterButton,
  InsertBeforeButton,
  RemoveButton,
  ModifyButton,
} from '../Insert';

import { useHoverDirty } from 'react-use';

const RingNode = memo(({ data }) => {
  const {
    skipEditing,
    insertBefore, // bool
    insertAfterData,
    moduleItem,
    // moduleItem,
    // componentInfo,
    callflow,
    setCallflow,
    modifyPath,
    templateRef,
    templateParent,
  } = data;

  const dataModifyPath = `${modifyPath}.data`;

  // console.log('RingNode moduleItem:', moduleItem);
  const ring_setup = getAtPath(callflow, dataModifyPath, {
    ring_type: moduleItem?.options?.props?.defaultRingType,
    data: {},
  });
  // console.log('Ringsetuphere:', ring_setup, callflow, dataModifyPath);

  const [showModifyDialog, setShowModifyDialog] = useState(null);
  const setShowModifyDialogWrap = setTo => () => setShowModifyDialog(setTo);

  const [showVariables, setShowVariables] = useState(null);
  const setShowVariablesWrap = setTo => () => setShowVariables(setTo);

  const boxRef = useRef(null);
  const isHovering = useHoverDirty(boxRef);

  // possibleVariables depends on the RingType!
  // - currently trying to limit to a single user/device/group
  let possibleVariables = [];
  switch (ring_setup.ring_type) {
    case 'user':
      possibleVariables = [
        {
          id: 'user_id', // whatever name we want, not really "used" in any specific sense
          name: 'User (ID)',
          read: {
            type: 'user_id',
          },
          write: {
            type: 'set',
            data: {
              moduleItemModifyPath: 'data.data.id',
            },
          },
        },
        {
          id: 'user_ring_time', // timeout for ringing
          name: 'Ring Time',
          read: {
            type: 'user_ring_time',
          },
          write: {
            type: 'set',
            data: {
              moduleItemModifyPath: 'data.data.timeout',
            },
          },
        },
        {
          id: 'user_fail_on_single_reject', // skip to next immediately on reject from any device
          name: 'Fail on Single Reject',
          description: 'If any leg rejects the call, cancel all other legs', // not used
          read: {
            type: 'user_fail_on_single_reject',
          },
          write: {
            type: 'set',
            data: {
              moduleItemModifyPath: 'data.data.fail_on_single_reject',
            },
          },
        },
      ];
      break;
    case 'group':
      possibleVariables = [
        {
          id: 'group_id', // whatever name we want, not really "used" in any specific sense
          name: 'Group (ID)',
          read: {
            type: 'group_id',
          },
          write: {
            type: 'set',
            data: {
              moduleItemModifyPath: 'data.data.id',
            },
          },
        },
      ];
      break;
    case 'device':
      possibleVariables = [
        {
          id: 'device_id', // whatever name we want, not really "used" in any specific sense
          name: 'Device (ID)',
          read: {
            type: 'device_id',
          },
          write: {
            type: 'set',
            data: {
              moduleItemModifyPath: 'data.data.id',
            },
          },
        },
      ];
      break;
    default:
      console.error('Invalid ringType specified for variables', ring_setup);
      break;
  }

  // action for onCreate
  const ee = useContext(IvrMenuEventEmitterContext);
  const onNodeCreated = data => {
    if (data.optsPath === modifyPath) {
      setShowModifyDialog(true);
    }
  };
  useEffect(() => {
    ee.on('node-created', onNodeCreated);
    return () => {
      ee.removeListener('node-created', onNodeCreated);
    };
  }, []);

  return (
    <>
      <IvrBox
        pref={boxRef}
        position={data?.position}
        borderColor="#1565C0"
        data={data}
      >
        {showVariables && (
          <VariablesEditor // Dialog
            {...data}
            possibleVariables={possibleVariables}
            onClose={setShowVariablesWrap(null)}
          />
        )}
        <Handle
          type="target"
          position="top"
          style={{ background: '#555', visibility: 'hidden' }}
        />
        <div>
          {/*<div style={{ padding: 8, textAlign: 'center' }}>*/}
          <RingStrategiesBuilder
            skipEditing={skipEditing}
            modifyPath={modifyPath}
            callflow={callflow}
            setCallflow={setCallflow}
            variant="ivr_builder"
            defaultRingType={moduleItem?.options?.props?.defaultRingType}
            showModifyDialog={showModifyDialog}
            setShowModifyDialog={setShowModifyDialog}
            // filterFunc={(strat) => strat.categories?.includes('level1')}
          />
          {/*</div>*/}
          {skipEditing ? null : (
            <>
              <MenuButton
                data={data}
                mode={isHovering ? 'normal' : 'ignore'}
                menuItems={[
                  {
                    type: 'modify',
                    props: { onClick: setShowModifyDialogWrap(true) },
                  },
                  'add',
                  'add-before',
                  'move',
                  'duplicate',
                  'copy',
                  process.env.NODE_ENV === 'development'
                    ? 'create_template'
                    : null,
                  templateParent ||
                  callflow.type == 'template' ||
                  process.env.NODE_ENV === 'development'
                    ? {
                        type: 'variables',
                        props: { onClick: setShowVariablesWrap(true) },
                      }
                    : null,
                  'remove',
                ]}
              />
              {/* <RemoveButton
              {...insertAfterData}
              mode={isHovering ? 'normal' : 'ignore'}
            />
            <ModifyButton
              onClick={setShowModifyDialogWrap(true)}
              mode={isHovering ? 'normal' : 'ignore'}
            />
            {insertBefore && (
              <InsertBeforeButton
                insertAfterData={insertAfterData}
                mode={isHovering ? 'normal' : 'ignore'}
              />
            )}
            <InsertAfterButton
              insertAfterData={insertAfterData}
              mode={isHovering ? 'normal' : 'ignore'}
            /> */}
            </>
          )}
        </div>
        <Handle
          type="source"
          position="bottom"
          style={{
            top: 'auto',
            bottom: 1,
            background: '#555',
            visibility: 'hidden',
          }}
        />
        {templateParent ? <InTemplate template={templateParent} /> : ''}
      </IvrBox>
    </>
  );
});

export default RingNode;
