import React, { useState, useEffect } from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tab,
  Typography,
} from 'app/design';
import { Add as AddIcon } from 'app/design/icons-material';
import { TabContext, TabList, TabPanel } from 'app/design/lab';
import { AudioPlayer } from 'app/components/AudioPlayer';
import { ButtonDropdownMenu } from 'app/components/ButtonDropdownMenu';
import { useEditMenuDialog } from 'app/components/EditMenuDialog';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { buildOutputCallflowFromTemplate } from 'app/components/IvrBuilder/Flow/nodes/Template';
import { QuickFinderMenuDialog } from 'app/components/QuickFinderMenuDialog';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { SimpleInlineEditor } from 'app/components/SimpleInlineEditor';
import { useMenuQuery } from 'app/hooks/queries/menu';
import { DialogBuilder, setAtPath, useToggleReducer } from 'app/utilities';
import { useMarkdownTranslate } from 'app/utilities/translate';
import { cloneDeep, omitBy, range, uniq } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { CallflowDoc } from 'types/callflow';
import { Menu } from 'types/menu';
import { useImmer } from 'use-immer';
import { NodeEditDialog } from '../../../../Dialogs';
import Joi from 'joi';
import { Edit as EditIcon } from 'app/design/icons-material';

interface ModifyNodeDialogMenuProps {
  callflow: CallflowDoc;
  setCallflow: (callflow: CallflowDoc) => void;
  modifyPath: string;
  moduleItem: any;
  menuId: string;
  onClose: () => void;
  initialInputString?: string;
}

export const ModifyDialog = ({
  callflow: callflowDoc,
  setCallflow,
  modifyPath,
  moduleItem,
  menuId: initialMenuId,
  initialInputString,
  onClose,
}: ModifyNodeDialogMenuProps) => {
  const [tmpModuleItem, setModifyModuleItem] = useState(() =>
    cloneDeep(moduleItem),
  );
  const menuId = tmpModuleItem?.data?.id;

  const {
    data: menu,
    isLoading: menuIsLoading,
    error: menuError,
    refetch: refetchMenu,
  } = useMenuQuery({ id: menuId });

  const handleSave = async () => {
    // iterate through targets and rebuild

    const targetKeys = Object.keys(tmpModuleItem?.data?.targets ?? {});
    for (let key of targetKeys) {
      const target = tmpModuleItem.data.targets[key];

      // @ts-ignore
      const targetCallflow = target?.callflow;

      if (targetCallflow) {
        const outputCallflow = await buildOutputCallflowFromTemplate({
          // more like "Build Template Strategy using rootCallflow variables"
          // - or, can be used on regular Callflows too!
          rootCallflow: targetCallflow, // root variables (and callflow.strategy.type=template -> callflow.strategy.simple.variables)
          templateCallflow: targetCallflow, // has the "ready for simple" (calculated) values!
          checkSimple: true,
        });
        // add the outputCallflow to the final
        targetCallflow.strategy.data = outputCallflow.strategy.data;
      }
    }

    // moduleItem.data.targets = tmpModuleItem?.data?.targets ?? {};
    // setAtPath(moduleItem,'data.targets',);
    setAtPath(callflowDoc, modifyPath, tmpModuleItem);
    setCallflow({ ...callflowDoc });
    onClose();
  };

  const [initialState] = useState({
    id: initialMenuId,
  });

  const saveLabel = !menuId
    ? 'Leave Empty'
    : !initialMenuId
    ? 'Add'
    : initialMenuId === menuId
    ? 'Done'
    : 'Update';

  // autoshow quick finder if node has no data ID
  const [showFinderDialog, toggleShowFindMenuDialog] = useToggleReducer(
    !initialMenuId,
  );

  const onChangeMenuSelect = selected => {
    const [menuId] = selected;
    setAtPath(tmpModuleItem, `data.id`, menuId); // newValue?.value === id
    setModifyModuleItem({ ...tmpModuleItem });
    toggleShowFindMenuDialog();
  };

  const handleClear = () => {
    setAtPath(tmpModuleItem, `data.id`, null); // newValue?.value === id
    setModifyModuleItem({ ...tmpModuleItem });
    toggleShowFindMenuDialog();
  };

  let targets = tmpModuleItem.data?.targets ?? {};

  const {
    Dialog: InputDialog,
    DialogProps: InputDialogProps,
    toggleOpen: toggleInputDialogOpen,
  } = useKeyInputDialog();

  const values = uniq([
    ...range(0, 2).map(v => v.toString()),
    ...Object.keys(targets ?? {}).sort(),
    'timeout',
  ]);

  // console.log('initialInputString:', initialInputString);
  // if initialInputString is set and NOT a plus, use that for the default to highlight, otherwise use the first option
  const [tabValue, setTabValue] = useImmer(
    initialInputString !== '+' ? initialInputString ?? values[0] : values[0],
  );

  useEffect(() => {
    // runs once
    if (initialInputString === '+') {
      toggleInputDialogOpen();
    }
  }, []);

  const handleTabChange = (e, num) => {
    setTabValue(num);
  };

  // console.log('tmpModule', tmpModuleItem);

  const handleAddTab = value => {
    if (!tmpModuleItem?.data?.targets?.hasOwnProperty(value)) {
      setAtPath(tmpModuleItem, `data.targets`, {
        ...tmpModuleItem.data.targets,
        [value]: undefined,
      });
      setModifyModuleItem({ ...tmpModuleItem });
    }
    setTabValue(value);
    toggleInputDialogOpen();
  };

  return (
    <>
      <InputDialog
        {...InputDialogProps}
        onComplete={handleAddTab}
        onCancel={toggleInputDialogOpen}
        existingValues={values}
      />
      <NodeEditDialog
        title={menuId ? '' : 'Select Menu'}
        titleButton={
          menu ? (
            <Button color="primary" onClick={toggleShowFindMenuDialog}>
              Change Menu
            </Button>
          ) : null
        }
        maxWidth={'sm'}
        onClose={onClose}
        onCancel={onClose}
        onSave={handleSave}
        saveLabel={saveLabel}
        hideCancel={saveLabel === 'Done'}
        cancelLabel={'Cancel'}
        isLoading={menuIsLoading}
        errorMessage={menuError ? 'Failed loading selected menu' : undefined}
      >
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            // sx={{ pr: 2, borderRight: '1px rgba(0,0,0,0.2) solid' }}
          >
            {menu ? (
              <MenuSettings
                {...{
                  tmpModuleItem,
                  setModifyModuleItem,
                  callflowDoc,
                  modifyPath,
                  moduleItem,
                  initialInputString,
                }}
                targets={targets}
                menu={menu}
                onComplete={refetchMenu}
              />
            ) : (
              <>
                <Typography color={'gray'} sx={{ fontStyle: 'italic' }}>
                  No Menu Selected...
                </Typography>
                <Button onClick={toggleShowFindMenuDialog}>Choose Menu</Button>
              </>
            )}
          </Grid>
          {/* <Grid item xs={7}>
            <TabContext value={tabValue}>
              <Grid container wrap={'nowrap'} spacing={1}>
                <Grid item xs={4}>
                  <div style={{ display: 'grid', placeItems: 'center' }}>
                    <TabList
                      orientation="vertical"
                      // variant="scrollable"
                      value={tabValue}
                      onChange={handleTabChange}
                      sx={{
                        textAlign: 'left',
                        borderRight: '1px solid rgba(0,0,0,0.2)',
                      }}
                    >
                      {values.map(inputString => {
                        const targetValue = targets[inputString]?.callflow;
                        let label;
                        // console.log('targetValue:', inputString, targetValue);
                        if (targetValue) {
                          if (targetValue?.strategy?.simple?.enabled) {
                            label =
                              targetValue?.strategy?.template?.parent_callflow
                                ?.name ?? 'Unknown template';
                          } else {
                            label = 'Advanced';
                          }
                        } else {
                          label =
                            inputString === '0' ? 'Operator Ext.' : 'Unused';
                        }

                        return (
                          <Tab
                            value={inputString}
                            label={
                              <div
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  justifyContent: 'left',
                                  alignItems: 'left',
                                  textAlign: 'left',
                                  width: 150,
                                }}
                              >
                                {`${inputString}: ${label}`}
                              </div>
                            }
                          />
                        );
                      })}
                      <Tab
                        label={<AddIcon />}
                        onClick={toggleInputDialogOpen}
                      />
                    </TabList>
                  </div>
                </Grid>
                <Grid item xs={7}>
                  {values.map(inputString => (
                    <KeyPressTabPanel
                      {...{
                        callflowDoc,
                        tmpModuleItem,
                        setModifyModuleItem,
                        modifyPath,
                        moduleItem,
                        targets,
                        values,
                        setTabValue,
                      }}
                      inputString={inputString}
                    />
                  ))}
                </Grid>
              </Grid>
            </TabContext>
          </Grid>
   */}
        </Grid>
      </NodeEditDialog>
      {showFinderDialog ? (
        <QuickFinderMenuDialog
          onSelect={onChangeMenuSelect}
          onCancel={toggleShowFindMenuDialog}
          onClear={handleClear}
          initialSelected={menuId ? [menuId] : []}
          selectionTitle={menu ? `${menu.doc.name}` : null}
          allowSelectNone
          allowCreate
        />
      ) : null}
    </>
  );
};

interface MenuSettingsProps {
  menu: Menu;
  onComplete: () => void;
  targets: any;
}

const MenuSettings = ({
  menu,
  onComplete,
  targets,
  tmpModuleItem,
  setModifyModuleItem,
  modifyPath,
  moduleItem,
  callflowDoc,
  initialInputString,
}) => {
  const { Dialog, DialogProps, toggleOpen } = useEditMenuDialog();
  const handleComplete = (menuId?: string | null) => {
    toggleOpen();
    onComplete && onComplete();
  };

  const { t } = useMarkdownTranslate();

  return (
    <>
      <Dialog
        {...DialogProps}
        menuId={menu.id}
        onComplete={handleComplete}
        onCancel={toggleOpen}
      />
      <Box sx={{ paddingX: 2 }}>
        <>
          <Typography
            variant="h1"
            // href={`/admin/vmboxes/view/${vmbox.id}`}
            // underline="hover"
            // color={'text.primary'}
          >{`${menu.doc.name}`}</Typography>
          <br />
          <Divider />
          <br />
          <GenericSettingsCardField
            title={'Retries'}
            content={menu.doc.retries}
            editOnClick={toggleOpen}
            editLabel={'Edit Menu Settings'}
            titleHelp={t('menu.retries.tooltip')}
          />
          <br />
          <GenericSettingsCardField
            title={'Timeout (in seconds)'}
            content={menu.doc.timeout ? menu.doc.timeout / 1000 : undefined}
            titleHelp={t('menu.timeout.tooltip')}
          />
          <br />
          <GenericSettingsCardField
            title={'Greeting'}
            content={
              menu.doc.media?.greeting ? (
                <AudioPlayer mediaId={menu.doc.media?.greeting} />
              ) : undefined
            }
            titleHelp={t('menu.greeting.tooltip')}
          />
        </>
      </Box>
    </>
  );
};

const KeyPressTabPanel = ({
  inputString,
  targets,
  values,
  callflowDoc,
  tmpModuleItem,
  setModifyModuleItem,
  modifyPath,
  setTabValue,
}) => {
  const {
    Dialog: InputDialog,
    DialogProps: InputDialogProps,
    toggleOpen: toggleInputDialogOpen,
  } = useKeyInputDialog();

  const targetValue = targets[inputString];

  const handleSimple = () => {
    // clear out the target value
    setAtPath(tmpModuleItem, `data.targets.${inputString}`, null);
    setModifyModuleItem({ ...tmpModuleItem });
  };

  if (!targetValue && inputString === '0') {
    return (
      <TabPanel sx={{ width: '100%' }} value={inputString}>
        <Typography color={'gray.main'}>
          By default key press 0 is handled by the account operator extension
        </Typography>
        {/*<FormControlLabel
          control={
            <Switch checked={enabled} size={'small'} onChange={toggleEnabled} />
          }
          label="Override Operator Ext."
        />
        <br />
        <br />*/}
      </TabPanel>
    );
  }

  if (
    targetValue?.callflow?.id &&
    !targetValue?.callflow?.strategy?.simple?.enabled
  ) {
    return (
      <TabPanel sx={{ width: '100%' }} value={inputString}>
        <div>
          This option is Advanced and must use the Visual Editor for changes
          <br />
          <Button onClick={handleSimple}>Switch to Simple Template</Button>
        </div>
      </TabPanel>
    );
  }

  const handleChange = formValue => {
    setAtPath(tmpModuleItem, `data.targets.{${inputString}}`, formValue);
    setModifyModuleItem({ ...tmpModuleItem });
    // console.log('menu formValue', formValue);
  };

  let label;

  if (targetValue) {
    if (targetValue?.callflow?.strategy?.simple?.enabled) {
      label =
        targetValue?.callflow?.strategy?.template?.parent_callflow?.name ??
        'Unknown template';
    } else {
      label = 'Advanced'; // what else could we show here? what ever the user has actually named this inline flow?
    }
  } else {
    label = 'Unused';
  }

  const handleEdit = value => {
    setAtPath(tmpModuleItem, `data.targets`, {
      [value]: targets?.[inputString],
      ...omitBy(
        tmpModuleItem.data.targets,
        (value, key) => key === inputString,
      ),
    });
    setModifyModuleItem({ ...tmpModuleItem });
    setTabValue(value);
    toggleInputDialogOpen();
  };

  const handleRemove = () => {
    setAtPath(
      tmpModuleItem,
      `data.targets`,
      omitBy(tmpModuleItem.data.targets, (value, key) => key === inputString),
    );
    setModifyModuleItem({ ...tmpModuleItem });
    setTabValue('0');
  };

  const handleChangeTemplate = () => {
    // clear out the target value
    setAtPath(tmpModuleItem, `data.targets.${inputString}`, null);
    setModifyModuleItem({ ...tmpModuleItem });
  };

  return (
    <>
      <InputDialog
        {...InputDialogProps}
        onComplete={handleEdit}
        onCancel={toggleInputDialogOpen}
        existingValues={values}
        defaultValue={inputString}
      />
      <TabPanel value={inputString}>
        {targetValue ? (
          <Grid container spacing={1} alignItems={'center'}>
            <Grid item sx={{ flex: 1 }}>
              <Typography variant={'h6'}>{label}</Typography>
            </Grid>
            <Grid item>
              <ButtonDropdownMenu
                menuItems={[
                  {
                    text: 'Change Template',

                    onClick: handleChangeTemplate,
                  },
                  {
                    text: 'Change Key Input',

                    onClick: toggleInputDialogOpen,
                  },
                  {
                    text: 'Remove',

                    onClick: handleRemove,
                  },
                ]}
              />
            </Grid>
          </Grid>
        ) : null}
        <Box>SimpleInlineEditor (removed)</Box>
        {/* <SimpleInlineEditor
          key={targetValue?.callflow?.id ?? 'missing'}
          name_type={'Simple'}
          type={callflowDoc.type}
          owner_type={callflowDoc.owner_type}
          resourceId={callflowDoc.owner_id}
          callflow={targetValue?.callflow}
          onChange={handleChange}
        /> */}
        {targetValue?.callflow ? (
          <>
            <br />
            <Divider />
            <br />
            <Typography variant="body1" paragraph>
              Advanced: after saving, you can "eject" this template to customize
              additional settings
            </Typography>
          </>
        ) : null}
        {/*<Autocomplete
        options={TEMPLATES}
        disabled={!enabled || advanced}
        // filterOptions={filterOptions}
        // @ts-ignore
        // value={optionValue}
        getOptionLabel={option => option.name}
        onChange={(e, v) => {
          // onChange(v?.value);
          // onChangeParent(v?.full);
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={advanced ? 'Using advanced handling' : 'Handle...'}
            placeholder={'not set'}
            fullWidth
          />
        )}
        // disableClearable
      />*/}
      </TabPanel>
    </>
  );
};

export const KeyInputDialog = ({
  onComplete,
  onCancel,
  existingValues,
  defaultValue,
}) => {
  const schema = Joi.object({
    input: Joi.string()
      // .invalid(...existingValues)
      .required(),
  });
  const formMethods = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      input: defaultValue,
    },
  });

  const handleSave = form => {
    onComplete(form.input.toString());
  };

  return (
    <Dialog maxWidth={'xs'} fullWidth open>
      <DialogTitle>Key Input</DialogTitle>
      <DialogContent dividers>
        <FormProvider {...formMethods}>
          <HookFormTextField
            name={'input'}
            label={'Key Input'}
            type={'number'}
            errorMessageParser={error => {
              if (error.type === 'any.invalid') {
                return 'Key Input is already in use';
              }

              return error.message;
            }}
            autoFocus
          />
        </FormProvider>
      </DialogContent>
      <DefaultDialogActions
        onCancel={onCancel}
        onSave={formMethods.handleSubmit(handleSave)}
        saveLabel="Add Key"
      />
    </Dialog>
  );
};

export const useKeyInputDialog = DialogBuilder(KeyInputDialog);

// const ChooseMenu = props => {
//   const { onChoose, onClose } = props;

//   const handleSelect = value => {
//     onChoose(value?.menu?.doc);
//   };

//   return (
//     <>
//       {/* <AutocompleteMenu
//         label=""
//         placeholder={'Search Menu box by name...'}
//         defaultValue={null}
//         onChange={handleSelect}
//       /> */}
//       AutocompleteVmBox
//     </>
//   );
// };

// const EditMenuSettings = props => {
//   const { menu, setMenu, onClose } = props;

//   return (
//     <Grid
//       container
//       spacing={1}
//       // direction={'column'}
//       alignItems={'top'}
//       justify={'center'}
//     >
//       <Grid item xs={12}>
//         <Grid container>
//           <Grid item xs={12}>
//             <Typography variant={'h7'} style={{ display: 'inline-block' }}>
//               Menu Settings
//             </Typography>
//             {/* <HelpToolTip
//               title={
//                 'Changing these settings will affect the Menu wherever it is used'
//               }
//             /> */}
//           </Grid>
//           <Grid item xs={12}>
//             {menu ? (
//               <>Sidebar??</>
//             ) : (
//               // <Sidebar>
//               //   <Divider />
//               //   <MenuBoxName
//               //     menu={{ doc: menu }}
//               //     onComplete={menu => setMenu(menu)}
//               //   />
//               //   <Divider />
//               //   <SidebarGreeting
//               //     menu={{ doc: menu, id: menu.id }}
//               //     onComplete={menu => setMenu(menu)}
//               //   />
//               //   <Divider />
//               // </Sidebar>
//               'Invalid Menu.'
//             )}
//           </Grid>
//         </Grid>
//       </Grid>
//     </Grid>
//   );

//   // return (
//   //   <>
//   //     {showModifyAudioDialog && (
//   //       <ModifyDialogAudio
//   //         mediaId={data.media?.unavailable}
//   //         onSave={afterUpdateMedia}
//   //         onClose={setShowModifyAudioDialogWrap(null)}
//   //       />
//   //     )}
//   //     <div>
//   //       <InputTextField
//   //         label="Name"
//   //         value={getAtPath(data, 'name')}
//   //         onChange={handeChangeText('name')}
//   //       />
//   //     </div>
//   //     <Divider />
//   //     <br />
//   //     {data.media?.unavailable ? (
//   //       <div>
//   //         <SmallMediaPlayer mediaId={data.media?.unavailable} includeName />
//   //         <Link onClick={setShowModifyAudioDialogWrap(true)}>
//   //           Modify Greeting
//   //         </Link>
//   //       </div>
//   //     ) : (
//   //       <div>
//   //         <Link onClick={setShowModifyAudioDialogWrap(true)}>Add Greeting</Link>
//   //       </div>
//   //     )}
//   //
//   //     <Divider />
//   //     <div>
//   //       <InputTextField
//   //         label="Mailbox"
//   //         value={getAtPath(data, 'mailbox')}
//   //         onChange={handeChangeText('mailbox')}
//   //       />
//   //     </div>
//   //   </>
//   // );
// };

// const MenuBoxName = ({ menu, onComplete }) => {
//   const dispatch = useDispatch();
//   const [showDialog, setShowDialog] = useState(false);

//   const {
//     mutate: mutateMenuBox,
//     isLoading: isLoadingMutate,
//     error: mutateError,
//   } = useMutation(data => {
//     // KazooSDK.resourceMenuesPatch(data)
//   });

//   const handleSave = name => {
//     // const menuData = {
//     //   ...menu.doc,
//     //   name,
//     // };
//     // let saveToast = ToastSaving({ title: 'Saving Menu Name...' });
//     // mutateMenuBox(menuData, {
//     //   onSuccess: async response => {
//     //     setShowDialog(false);
//     //     dispatch(syncSingleItem({ collectionKey: 'menues', id: menu.id }));
//     //     // await sleep(1000);
//     //     saveToast.saved({ title: 'Menu Name Saved!' });
//     //     console.log(response.data.data);
//     //     onComplete && onComplete(response.data.data);
//     //   },
//     //   onError: error => {
//     //     saveToast.error(error);
//     //   },
//     // });
//   };

//   return (
//     <>
//       {showDialog && (
//         <EditDetailsDialog
//           type={'Menu'}
//           progressLabel={'Saving Menu Name...'}
//           name={menu?.doc?.name}
//           onCancel={() => setShowDialog(false)}
//           onClose={() => setShowDialog(false)}
//           onSave={handleSave}
//           errors={[mutateError]}
//           isLoadingMutate={isLoadingMutate}
//         />
//       )}
//       {/* <SidebarCard>
//         <SidebarCardHeader
//           label={'Menu Name'}
//           icon={
//             <FontDownloadIcon
//               style={{ verticalAlign: 'middle', color: '#6736F1' }}
//             />
//           }
//           editButtonProps={{
//             onClick: () => setShowDialog(true),
//           }}
//         />
//         <SidebarCardContent>
//           <div>
//             <Typography variant="body1">{menu?.doc?.name}</Typography>
//           </div>
//         </SidebarCardContent>
//       </SidebarCard> */}
//       SidebarStuff
//     </>
//   );
// };

export default ModifyDialog;
