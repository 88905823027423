import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from 'app/design';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from 'app/design/icons-material';
import { useCreateVmbox } from 'app/hooks/mutations/vmbox';
import {
  useToggleReducer,
  DialogBuilder,
  parseAndSetKazooMutationErrors,
} from 'app/utilities';
import Joi from 'joi';
import { pickBy } from 'lodash';
import { useEffect } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Vmbox } from 'types/vmbox';
import { useImmer } from 'use-immer';

import { OwnerInput } from 'app/components/OwnerInput';

// schema for form validation. Passed to useForm to only trigger submit when
//  - the below conditions are met. Any known serverside constraints (min/max,
//  - character limits, numbers only, etc.) should be added. Remove 128 max below
//  - to demonstrate serverside invalidation and handling
// const schema = Joi.object({
//   owner_id: Joi.string(),
//   name: Joi.string().max(128).required(),
//   mailbox: Joi.string().max(5).required(),
// });

interface VmboxCreateForm {
  owner_id?: string;
  owner_type?: string;
  name?: string;
  mailbox?: string;
}

// interface declaring which props are required/allowed
interface VmboxCreateDialogProps extends VmboxCreateDialogFormProps {}

const VmboxCreateDialog = ({
  formDefaults,
  onCancel,
  startDirty = false,
  onComplete,
  navigateOnComplete,
}: VmboxCreateDialogProps) => {
  const handleCancel = () => {
    onCancel();
  };

  return (
    <Dialog open={true} fullWidth maxWidth={'sm'}>
      <VmboxCreateDialogForm
        formDefaults={formDefaults}
        startDirty={startDirty}
        onCancel={onCancel}
        onComplete={onComplete}
        navigateOnComplete={navigateOnComplete}
      />
    </Dialog>
  );
};

interface VmboxCreateDialogFormProps {
  formDefaults: VmboxCreateForm;
  onCancel: () => void;
  startDirty?: boolean;
  navigateOnComplete?: boolean;
  onComplete?: (data: any) => void;
}

const VmboxCreateDialogForm = ({
  formDefaults,
  onCancel,
  navigateOnComplete = true,
  onComplete,
  // onSubmit,
  // errorMessage,
  startDirty = false,
}: VmboxCreateDialogFormProps) => {
  const [saveError, setSaveError] = useImmer(null);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors: formErrors, isDirty, isSubmitting },
    setError,
    watch,
  } = useForm<VmboxCreateForm>({
    defaultValues: formDefaults,
    // resolver: joiResolver(schema), // pass in custom validation
  });

  const router = useHistory();
  const createVmbox = useCreateVmbox();

  const onSubmit = async (vmboxForm: VmboxCreateForm) => {
    // update form defaults if mutation fails

    setSaveError(null);

    console.log('vmboxForm', vmboxForm);
    const doc = {
      pin: undefined,
      require_pin: false,
      announcement_only: false,
      include_message_on_notify: true,
      include_transcription_on_notify: true,
      is_setup: false,
      is_voicemail_ff_rw_enabled: false,
      check_if_owner: true,
      delete_after_notify: false,
      save_after_notify: false,
      skip_envelope: false,
      skip_greeting: false,
      skip_instructions: false,
      transcribe: false,
      notify_email_addresses: [],
      oldest_message_first: false,
      ...vmboxForm,
    };

    // sanitize by removing values that may be unset (owner_id cannot be NULL)
    const sanitizedDoc = pickBy(doc, value => value !== null);

    // TODO: Device owner instead of user?
    if (vmboxForm.owner_id) {
      doc.owner_type = 'user';
    } else {
      // @ts-ignore
      doc.owner_type = null;
    }

    // try {
    // @ts-ignore
    const createVmboxPromise = createVmbox.mutateAsync(sanitizedDoc);

    toast.promise(createVmboxPromise, {
      pending: 'Creating voicemail box...',
      success: 'Voicemail box created!',
      error: 'Error creating voicemail box.',
    });

    let resp;
    try {
      resp = await createVmboxPromise;
    } catch (err) {
      parseAndSetKazooMutationErrors({ response: err.response, setError });

      // @ts-ignore
      setSaveError({
        // @ts-ignore
        message:
          'Unable to save Voicemail Box. Please correct errors and try again.',
      });
      throw err;
    }

    if (resp.status === 'success') {
      if (onComplete) onComplete(resp.data.id);

      if (navigateOnComplete) {
        // redirect to Vmbox
        router.push(`/admin/vmboxes/view/${resp.data.id}`);
      }
    } else {
      alert('failed here');
      throw new Error('Failed saving Voicemail Box');
    }
    // } catch (err) {
    //   console.error('ERROR:', err);
    //   throw new Error('failed');
    // }
  };

  if (isSubmitting) {
    return <DialogInProgress title={'Creating Voicemail Box...'} />;
  }

  return (
    <>
      <DialogTitle>Create Voicemail Box</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={2}>
            <TextField
              label={'Mailbox'}
              {...register('mailbox')}
              error={!!formErrors.mailbox}
              helperText={formErrors.mailbox?.message?.replace(
                '"mailbox"',
                'Mailbox',
              )}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              label={'Name'}
              {...register('name')}
              error={!!formErrors.name}
              helperText={formErrors.name?.message?.replace('"name"', 'Name')}
            />
          </Grid>
        </Grid>
        <br />
        <div>
          <Controller
            control={control}
            name={'owner_id'}
            render={({ field: { onChange, value, ...props } }) => (
              <OwnerInput
                value={value}
                onChange={onChange}
                // error={!!formErrors.mailbox}
                // helperText={formErrors.mailbox?.message?.replace(
                //   '"mailbox"',
                //   'Mailbox',
                // )}
              />
            )}
          />
          {!watch('owner_id') ? (
            <>
              <br />
              <Typography variant="caption">
                A voicemail box without an owner is "shared" and available to
                everyone
              </Typography>
            </>
          ) : null}
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <div>
          <Button variant={'outlined'} color={'error'} onClick={onCancel}>
            Cancel
          </Button>
        </div>
        {/* @ts-ignore */}
        {saveError ? (
          <div>
            <Typography color={'warning.dark'} variant="body2">
              {/* @ts-ignore */}
              {saveError.message}
            </Typography>
          </div>
        ) : null}
        <div>
          <Button
            variant={'outlined'}
            color={'success'}
            disabled={startDirty ? !startDirty : !isDirty}
            onClick={handleSubmit(onSubmit)}
            sx={{ whiteSpace: 'nowrap' }}
          >
            Create Voicemail Box
          </Button>
        </div>
      </DialogActions>
    </>
  );
};

export const useVmboxCreateDialog = DialogBuilder(VmboxCreateDialog);

export default VmboxCreateDialog;
