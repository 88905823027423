import React, { useEffect, useCallback, useState, useRef } from 'react';
import useStyles from './styles';
import { Box, Typography, Select, MenuItem, Slider } from 'app/design';

import { matchSorter } from 'match-sorter';

import { useWebphoneContext } from '../../../Store';

const SpeakerSetting = props => {
  const { filterText, setFilterMatching } = props;

  const SUB_ITEMS = [
    {
      component: SpeakerDevice,
    },
    {
      component: SpeakerVolume,
    },
  ];

  const [fullSubitemsList, setFullSubitemsList] = useState([...SUB_ITEMS]);

  const anyChildrenMatch = fullSubitemsList.find(setting => setting.matching)
    ? true
    : false;

  useEffect(() => {
    setFilterMatching(anyChildrenMatch);
  }, [fullSubitemsList, anyChildrenMatch]);

  const handleChildrenFilterMatching = index => matching => {
    setFullSubitemsList(list => {
      list[index].matching = matching;
      return [...list];
    });
  };

  const classes = useStyles({ anyChildrenMatch });

  return (
    <Box sx={classes.mainCategory}>
      <Typography variant="h6" bold gutterBottom>
        Speakers
      </Typography>
      <div style={{ padding: '0px 8px' }}>
        {SUB_ITEMS.map((subitem, i) => {
          const Component = subitem.component;
          return (
            <Component
              key={i}
              filterText={filterText}
              setFilterMatching={handleChildrenFilterMatching(i)}
            />
          );
        })}
      </div>
    </Box>
  );
};

const SpeakerDevice = props => {
  const { filterText, setFilterMatching } = props;
  const [state, dispatch] = useWebphoneContext();
  const { mediaDevices, changeAudioOutput, getPreferredDevice } = state;

  const [doesMatch, setDoesMatch] = useState(true);
  const [selectedDevice, setSelectedDevice] = useState('default');

  useEffect(() => {
    setFilterMatching(doesMatch);
  }, [doesMatch]);

  useEffect(() => {
    const matches = ['speaker output device'];
    setDoesMatch(matchSorter(matches, filterText).length ? true : false);
  }, [filterText]);

  let audioOutDevices = [];
  if (mediaDevices !== undefined) {
    audioOutDevices = mediaDevices.filter(item => item.kind === 'audiooutput');
    if (audioOutDevices.length === 1) {
      if (selectedDevice !== audioOutDevices[0].deviceId) {
        setSelectedDevice(audioOutDevices[0].deviceId);
      }
    } else {
      const device = getPreferredDevice('audiooutput');
      if (device !== selectedDevice) {
        setSelectedDevice(device);
      }
    }
  }
  const onDeviceChange = id => {
    changeAudioOutput(id);
    setSelectedDevice(id);
  };

  const classes = useStyles({ doesMatch });

  return (
    <Box sx={classes.subitem}>
      <div style={{}}>
        <Typography variant="body1" gutterBottom>
          Output Device
        </Typography>
        <Select
          size="small"
          style={{ width: '100%' }}
          value={selectedDevice}
          onChange={e => onDeviceChange(e.target.value)}
        >
          {audioOutDevices.map((deviceInfo, i) => (
            <MenuItem key={i} value={deviceInfo.deviceId}>
              {deviceInfo.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    </Box>
  );
};

const SpeakerVolume = props => {
  const { filterText, setFilterMatching } = props;
  const [state, dispatch] = useWebphoneContext();
  const { calls, mediaDevices, setSpeakerVolume, getSpeakerVolume } = state;

  const [doesMatch, setDoesMatch] = useState(true);
  const [volume, setVolume] = useState(80);

  useEffect(() => {
    setFilterMatching(doesMatch);
  }, [doesMatch]);

  useEffect(() => {
    const matches = ['speaker output volume'];
    setDoesMatch(matchSorter(matches, filterText).length ? true : false);
  }, [filterText]);

  let spkVol = getSpeakerVolume() * 100;
  spkVol = Math.round(spkVol);
  if (spkVol !== volume) {
    setVolume(spkVol);
  }
  const onVolumeChange = (event, value) => {
    const vol = Math.round(value) / 100;
    setSpeakerVolume(vol);
    setVolume(Math.round(value));
  };

  const classes = useStyles({ doesMatch });

  return (
    <Box sx={classes.subitem}>
      <div style={{}}>
        <Typography variant="body1">Output Volume</Typography>
        <Typography variant="caption" component="div" gutterBottom>
          (what you hear during a call)
        </Typography>
        <span>{volume}%</span>
        <Slider value={volume} onChange={onVolumeChange} />
      </div>
    </Box>
  );
};

export default SpeakerSetting;
