export enum TimeRange {
  Today = 'today',
  Yesterday = 'yesterday',
  ThisWeek = 'this_week',
  LastWeek = 'last_week',
  ThisDayLastWeek = 'this_day_last_week',
  ThisMonth = 'this_month',
  LastMonth = 'last_month',
  TwoMonthsAgo = 'two_months_ago',
  Last90Days = 'last_90_days',
}
