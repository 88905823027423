import { fetchFromGraphql, mutateGraphql } from 'app/sdk/utils/graphql';
import {
  buildGetDefault,
  buildPutDefault,
  buildPatchDefault,
  buildDeleteDefault,
} from 'app/sdk/utils/kazoo';

const exports = {
  // Queries
  // - graphql
  query: {
    phoneNumbers: (vars, fetchOptions) =>
      fetchFromGraphql('phoneNumbers', vars, fetchOptions),

    // Fetch a single phoneNumber by id
    phoneNumberById: (vars, fetchOptions) =>
      fetchFromGraphql('phoneNumberById', vars, fetchOptions),

    // search for phone numbers to buy (simple)
    available: (vars, fetchOptions) => {
      const fn = buildGetDefault('phone_numbers', {
        buildUrlOpts: { skipId: true },
      });
      return fn(null, fetchOptions.auth_token, vars);
    },
  },

  // Mutations
  // - direct to kazoo, wait for update server-side
  mutate: {
    delete: (vars, fetchOptions) =>
      mutateGraphql('phoneNumberDelete', vars, fetchOptions),
    // delete: buildDeleteDefault('phone_numbers', {
    //   buildUrlOpts: { encodeId: true },
    // }),
    updatePartial: buildPatchDefault('phone_numbers', {
      buildUrlOpts: { encodeId: true },
    }),
    buy: (vars, fetchOptions) => {
      const fn = buildPutDefault('phone_numbers/collection/activate', {
        buildUrlOpts: { skipId: true },
      });
      console.log('fetchOptions123:', fetchOptions);
      return fn(vars, fetchOptions.auth_token);
    },
  },
};

export default exports;
