import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  InputTextField,
  Link,
  Typography,
} from 'app/design';

import { useVmboxQuery } from 'app/hooks/queries/vmbox';

import { Voicemail as VoicemailIcon } from 'app/design/icons-material';

import { Handle } from 'react-flow-renderer';
import { useHoverDirty } from 'react-use';
import { IvrMenuEventEmitterContext } from '../../..';
import { AudioPlayer } from '../../../../AudioPlayer';

// import {
//   ModifyDialog as ModifyDialogAudio,
//   SmallMediaPlayer,
// } from '../../../../SingleMedia';

import { Box } from '../common/Box';
import { ModifyNodeDialogVmbox } from './ModifyNodeDialogVmbox';
import { InTemplate } from '../common/InTemplate';
import { MenuButton } from '../common/MenuButton';
import { NodeInfoDisplay } from '../common/NodeInfoDisplay';
import { VariablesEditor } from '../common/VariablesEditor';

// why use memo??
const VoicemailNode = memo(({ data, ...rest }) => {
  const {
    skipEditing,
    insertBefore, // bool
    insertAfterData,
    moduleItem,
    callflow,
    setCallflow,
    modifyPath,
    templateParent,
    // element, // used in Box
    // setRootElements, // used in Box
  } = data;

  // Load vmbox via query
  const {
    data: vmbox,
    isLoading,
    isFetched,
  } = useVmboxQuery({ id: moduleItem?.data?.id });

  const [showModifyDialog, setShowModifyDialog] = useState(null);
  const setShowModifyDialogWrap = setTo => () => setShowModifyDialog(setTo);

  const [showVariables, setShowVariables] = useState(null);
  const setShowVariablesWrap = setTo => () => setShowVariables(setTo);

  const boxRef = useRef(null);
  const isHovering = useHoverDirty(boxRef);

  let possibleVariables = [
    {
      id: 'vmbox_id',
      name: 'Voicemail Box ID',
      read: {
        type: 'vmbox_id',
      },
      write: {
        type: 'set',
        data: {
          moduleItemModifyPath: 'data.id',
        },
      },
    },
    {
      id: 'vm_max_msg_length', // max message length (500)
      name: 'Max Message Length',
      read: {
        type: 'vm_max_msg_length',
      },
      write: {
        type: 'set',
        data: {
          moduleItemModifyPath: 'data.max_message_length',
        },
      },
    },
  ];

  // action for onCreate
  const ee = useContext(IvrMenuEventEmitterContext);
  const onNodeCreated = data => {
    if (data.optsPath === modifyPath) {
      setShowModifyDialog(true);
    }
  };
  useEffect(() => {
    ee.on('node-created', onNodeCreated);
    return () => {
      ee.removeListener('node-created', onNodeCreated);
    };
  }, []);

  return (
    <>
      <Box
        pref={boxRef}
        borderColor="#1E88E5"
        position={data?.position}
        data={data}
        // height={100}
        // onHeight={}
        // onDimensions={handleDimensions}
      >
        {showModifyDialog && (
          <ModifyNodeDialogVmbox
            {...data}
            vmboxId={moduleItem?.data?.id}
            // vmbox={vmbox}
            onClose={setShowModifyDialogWrap(null)}
          />
        )}
        {showVariables && (
          <VariablesEditor // Dialog
            {...data}
            possibleVariables={possibleVariables}
            onClose={setShowVariablesWrap(null)}
          />
        )}
        <Handle
          type="target"
          position="top"
          style={{ background: '#555', visibility: 'hidden' }}
        />
        <div style={{ position: 'relative' }}>
          {skipEditing ? (
            // display for when skipping editing (QuickDisplay)
            <NodeInfoDisplay
              type={'Voicemail'}
              icon={<VoicemailIcon />}
              title={
                vmbox
                  ? `${vmbox?.doc?.mailbox} - ${vmbox?.doc?.name}`
                  : 'None Selected'
              }
              content={
                vmbox ? (
                  vmbox?.doc?.media?.unavailable ? (
                    // <SmallMediaPlayer mediaId={vmbox?.doc?.media?.unavailable} />
                    <span style={{ opacity: 0.6 }}>
                      <AudioPlayer mediaId={vmbox?.doc?.media?.unavailable} />
                    </span>
                  ) : (
                    <span style={{ opacity: 0.6 }}>[No Greeting]</span>
                  )
                ) : null
              }
              footer={
                vmbox ? (
                  vmbox?.doc?.is_setup ? null : (
                    <div
                      style={{
                        marginTop: vmbox?.doc?.media?.unavailable ? -15 : -5,
                      }}
                    >
                      <Typography variant={'caption'} color={'secondary'}>
                        Voicemail Not Set Up
                      </Typography>
                    </div>
                  )
                ) : null
              }
            />
          ) : (
            // full ivr editor node display
            <NodeInfoDisplay
              type={'Voicemail'}
              icon={<VoicemailIcon />}
              title={
                vmbox ? (
                  `${vmbox?.doc?.mailbox} - ${vmbox?.doc?.name}`
                ) : (
                  <Link onClick={setShowModifyDialogWrap(true)}>
                    Select Voicemail
                  </Link>
                )
              }
              content={
                vmbox ? (
                  vmbox?.doc?.media?.unavailable ? (
                    // <SmallMediaPlayer mediaId={vmbox?.doc?.media?.unavailable} />
                    <span style={{ opacity: 0.6 }}>
                      <AudioPlayer mediaId={vmbox?.doc?.media?.unavailable} />
                    </span>
                  ) : (
                    <span style={{ opacity: 0.6 }}>[No Greeting]</span>
                  )
                ) : null
              }
              footer={
                vmbox ? (
                  vmbox?.doc?.is_setup ? null : (
                    <div
                      style={{
                        marginTop: vmbox?.doc?.media?.unavailable ? -15 : -5,
                      }}
                    >
                      <Typography variant={'caption'} color={'secondary'}>
                        Voicemail Not Set Up
                      </Typography>
                    </div>
                  )
                ) : null
              }
            />
          )}
          {skipEditing ? null : (
            <>
              <MenuButton
                data={data}
                mode={isHovering ? 'normal' : 'ignore'}
                menuItems={[
                  {
                    type: 'modify',
                    props: { onClick: setShowModifyDialogWrap(true) },
                  },
                  // 'add',
                  'add-before',
                  'move',
                  'duplicate',
                  'copy',
                  process.env.NODE_ENV === 'development'
                    ? 'create_template'
                    : null,
                  templateParent ||
                  callflow.type == 'template' ||
                  process.env.NODE_ENV === 'development'
                    ? {
                        type: 'variables',
                        props: { onClick: setShowVariablesWrap(true) },
                      }
                    : null,
                  'remove',
                ]}
              />
            </>
          )}
        </div>
        {templateParent ? <InTemplate template={templateParent} /> : ''}
      </Box>
    </>
  );
});

export default VoicemailNode;
