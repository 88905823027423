import { Card, CardContent, CardHeader } from 'app/design';
import { useFindInPage } from 'app/components/FindInPage';
import { AnnouncementOnly } from 'app/pages/admin/Vmboxes/View/components/VmboxAdditionalSettingsCard/components/AnnouncementOnly';
import { DisableUserChanges } from 'app/pages/admin/Vmboxes/View/components/VmboxAdditionalSettingsCard/components/DisableUserChanges';
import { CheckIfOwner } from 'app/pages/admin/Vmboxes/View/components/VmboxAdditionalSettingsCard/components/CheckIfOwner';
import { RequirePin } from 'app/pages/admin/Vmboxes/View/components/VmboxAdditionalSettingsCard/components/RequirePin';
import { SkipGreeting } from 'app/pages/admin/Vmboxes/View/components/VmboxAdditionalSettingsCard/components/SkipGreeting';
import { SkipInstructions } from 'app/pages/admin/Vmboxes/View/components/VmboxAdditionalSettingsCard/components/SkipInstructions';
import { TranscribeVoicemails } from 'app/pages/admin/Vmboxes/View/components/VmboxAdditionalSettingsCard/components/TranscribeVoicemails';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { Vmbox } from 'types/vmbox';

interface VmboxSettingsCardProps {
  vmbox: Vmbox;
  onComplete: () => void;
}

const VmboxAdditionalSettingsCard = ({
  vmbox,
  onComplete,
}: VmboxSettingsCardProps) => {
  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'additionalSettings',
    options: [
      {
        label: 'Additional Settings',
        value: 'additionalSettings',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const handleOnComplete = () => {
    onComplete();
    toggleShowEditDialog();
  };

  return (
    // @ts-ignore
    <Card ref={ref} variant="dashboard">
      <HighlightPulse {...highlightPulseProps} />
      <CardHeader title="Additional Settings" />
      <CardContent>
        <br />
        <RequirePin vmbox={vmbox} />
        <br />
        <CheckIfOwner vmbox={vmbox} />
        <br />
        <AnnouncementOnly vmbox={vmbox} />
        <br />
        <SkipGreeting vmbox={vmbox} />
        <br />
        <SkipInstructions vmbox={vmbox} />
        <br />
        <TranscribeVoicemails vmbox={vmbox} />
        <br />
        <DisableUserChanges vmbox={vmbox} />
      </CardContent>
    </Card>
  );
};

export default VmboxAdditionalSettingsCard;
