import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from 'app/design';
import { AudioPlayer } from 'app/components/AudioPlayer';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { DialogCircularProgress } from 'app/components/DialogCircularProgress';
import { BuiltInAudio } from 'app/components/MediaDialog/components/BuiltInAudio';
import { BuyGreeting } from 'app/components/MediaDialog/components/BuyGreeting';
import { RecordAudio } from 'app/components/MediaDialog/components/RecordAudio';
import { TextToSpeech } from 'app/components/MediaDialog/components/TextToSpeech';
import { UploadAudio } from 'app/components/MediaDialog/components/UploadAudio';
import { useUpdateMediaFull } from 'app/hooks/mutations/media';
import { useCreateMedia } from 'app/hooks/mutations/media/useCreateMedia';
import { useUploadMedia } from 'app/hooks/mutations/media/useUploadMedia';
import useMediaQuery from 'app/hooks/queries/media/useMediaQuery';
import { useToggleReducer } from 'app/utilities';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { MediaType } from 'types/media';

interface MediaDialogProps {
  mediaId?: string | null;
  mediaNameToSaveAs: string;
  onComplete: (mediaId: string | null) => void;
  onCancel: () => void;
  allowedTypes?: MediaType[];
  type?: string;
  saveLabel?: string;
}

const MediaDialog = ({
  type = 'Media',
  mediaId,
  onComplete,
  onCancel,
  saveLabel,
  allowedTypes = [
    MediaType.BuiltIn,
    MediaType.Tts,
    MediaType.Upload,
    MediaType.Recording,
  ],
  mediaNameToSaveAs,
}: MediaDialogProps) => {
  const [editMode, toggleEditMode] = useToggleReducer(
    // !!mediaId
    false,
  );

  return (
    <Dialog open={true} fullWidth maxWidth={'md'}>
      {editMode ? (
        <EditMedia
          mediaId={mediaId}
          onCancel={onCancel}
          onComplete={onComplete}
          allowedTypes={allowedTypes}
          onReplace={toggleEditMode}
          mediaNameToSaveAs={mediaNameToSaveAs}
        />
      ) : (
        <AddMedia
          // if originally editing media (mediaId present)
          type={type}
          mediaId={mediaId}
          onCancel={onCancel}
          onComplete={onComplete}
          allowedTypes={allowedTypes}
          mediaNameToSaveAs={mediaNameToSaveAs}
          saveLabel={saveLabel}
        />
      )}
    </Dialog>
  );
};

const checkDisabled = (watchValue, type) => {
  switch (type) {
    case MediaType.Upload:
      console.log('watchValue', watchValue);
      return !watchValue?.file;
    // Disable save on custom greeting until integrated
    case MediaType.Custom:
      return true;
    default:
      return false;
  }
};

const AddMedia = ({
  type = 'Media',
  mediaId,
  onComplete,
  onCancel,
  allowedTypes = [
    MediaType.BuiltIn,
    MediaType.Tts,
    MediaType.Upload,
    MediaType.Recording,
    MediaType.Custom,
  ],
  mediaNameToSaveAs,
  saveLabel,
}: MediaDialogProps) => {
  const {
    register,
    handleSubmit,
    formState: {
      errors: formErrors,
      isDirty,
      isValid,
      touchedFields,
      dirtyFields,
    },
    setValue,
    watch,
  } = useForm();

  const verbLoading = mediaId ? 'Updating' : 'Uploading';
  const verbCompleted = mediaId ? 'updated' : 'uploaded';

  const [loading, setLoading] = useState<'remove' | 'load' | false>(false);
  const createMedia = useCreateMedia();
  const uploadMedia = useUploadMedia();
  const updateMediaFull = useUpdateMediaFull();

  const [tabValue, setTabValue] = useState(allowedTypes[0]);
  const watchValue = watch(tabValue);

  const handleMediaUpload = async (newMedia, file, isLoaded = false) => {
    if (isLoaded) {
      const mediaToUpload = {
        id: newMedia.id,
        encodedFile: file as string,
      };

      const resp = await uploadMedia.mutateAsync(mediaToUpload);

      if (resp.status === 'success') {
        newMedia.updated_at = Date.now();
        await updateMediaFull.mutateAsync(newMedia);
      }

      return;
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = async function (evt) {
        const file = evt.target?.result;

        const mediaToUpload = {
          id: newMedia.id,
          encodedFile: file as string,
        };

        const resp = await uploadMedia.mutateAsync(mediaToUpload);

        if (resp.status === 'success') {
          newMedia.updated_at = Date.now();
          await updateMediaFull.mutateAsync(newMedia);
          resolve(reader.result);
        }
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  };

  const onSave = async formData => {
    const toastId =
      tabValue === MediaType.BuiltIn
        ? null
        : toast.loading(`${verbLoading} ${type.toLowerCase()}...`);

    setLoading('load');
    try {
      const mediaFullDoc = convertFormDataToFullMediaDoc(formData, tabValue);

      console.log('type of', typeof mediaFullDoc);

      if (typeof mediaFullDoc === 'string') {
        await onComplete(mediaFullDoc);
      } else {
        const resp = await createMedia.mutateAsync({
          ...mediaFullDoc,
          name: mediaNameToSaveAs,
        });

        // const resp = await toast.promise(createMediaPromise, {
        //   error: 'Failed to create media!',
        //   success: 'Media created!',
        //   pending: 'Creating media...',
        // });

        console.log('resp', resp);

        if (resp.status === 'success') {
          const newMedia = resp.data;

          if (tabValue === MediaType.Upload) {
            await handleMediaUpload(newMedia, formData.upload.file);
          }

          if (tabValue === MediaType.Recording) {
            await handleMediaUpload(newMedia, formData.recording.file, true);
          }

          await onComplete(newMedia.id);
        }
      }

      setLoading(false);

      if (toastId) {
        toast.update(toastId, {
          render: `${type} ${verbCompleted}!`,
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        });
      }
    } catch (e) {
      setLoading(false);
      console.error('e', e);
      if (toastId) {
        toast.update(toastId, {
          render: `${type} ${verbCompleted} failed.`,
          type: 'error',
          isLoading: false,
          autoClose: 2000,
        });
      }
    }
  };

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const handleRemove = async () => {
    const toastId = toast.loading(`${verbLoading} ${type?.toLowerCase()}...`);
    setLoading('remove');
    await onComplete(null);
    setLoading(false);
    toast.update(toastId, {
      render: `${type} ${verbCompleted}!`,
      type: 'success',
      isLoading: false,
      autoClose: 2000,
    });
  };

  return createMedia.isLoading || uploadMedia.isLoading || loading ? (
    <DialogCircularProgress
      label={`${verbLoading} ${type?.toLowerCase()}...`}
    />
  ) : (
    <>
      <DialogTitle>
        <Grid
          container
          spacing={1}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Grid item>{mediaId ? `Change ${type}` : `Add ${type}`}</Grid>
          {mediaId ? (
            <Grid item>
              <Button
                onClick={handleRemove}
                variant={'outlined'}
                color={'secondary'}
              >
                Remove Current Audio
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </DialogTitle>

      <>
        <Divider />
        <DialogContent>
          <div style={{ display: 'grid', placeItems: 'center', width: '100%' }}>
            {/*<TextField
              label={'Media Name'}
              defaultValue={''}
              {...register('name', { required: true })}
            />*/}
            <Tabs value={tabValue} onChange={handleTabChange}>
              {allowedTypes?.includes(MediaType.Tts) ? (
                <Tab label={'TTS'} value={MediaType.Tts} />
              ) : null}
              {allowedTypes?.includes(MediaType.BuiltIn) ? (
                <Tab label={'Built-in'} value={MediaType.BuiltIn} />
              ) : null}
              {allowedTypes?.includes(MediaType.Upload) ? (
                <Tab label={'Upload'} value={MediaType.Upload} />
              ) : null}
              {allowedTypes?.includes(MediaType.Recording) ? (
                <Tab label={'Record with Mic'} value={MediaType.Recording} />
              ) : null}
              {allowedTypes?.includes(MediaType.Custom) ? (
                <Tab label={'Buy Greeting'} value={MediaType.Custom} />
              ) : null}
            </Tabs>
            <Divider />
            <br />
            <div style={{ display: 'grid', placeItems: 'center', width: 500 }}>
              {tabValue === MediaType.Tts ? (
                <TextToSpeech
                  register={register}
                  // required={tabValue === MediaType.Tts}
                />
              ) : null}
              {tabValue === MediaType.Upload ? (
                <UploadAudio
                  register={register}
                  setValue={setValue}
                  // required={tabValue === MediaType.Upload}
                />
              ) : null}
              {tabValue === MediaType.BuiltIn ? (
                <BuiltInAudio
                  register={register}
                  setValue={setValue}
                  // required={tabValue === MediaType.BuiltIn}
                />
              ) : null}
              {tabValue === MediaType.Recording ? (
                <RecordAudio
                  register={register}
                  setValue={setValue}
                  // required={tabValue === MediaType.BuiltIn}
                />
              ) : null}
              {tabValue === MediaType.Custom ? (
                <BuyGreeting
                // register={register}
                // setValue={setValue}
                // required={tabValue === MediaType.BuiltIn}
                />
              ) : null}
            </div>
          </div>
        </DialogContent>
        <Divider />
        <DefaultDialogActions
          errorMessage={updateMediaFull.error?.message}
          onSave={handleSubmit(onSave)}
          onCancel={onCancel}
          saveDisabled={checkDisabled(watchValue, tabValue)}
          saveLabel={saveLabel}
        />
      </>
    </>
  );
};

interface EditModeProps extends MediaDialogProps {
  onReplace: () => void;
}

const EditMedia = ({
  mediaId,
  onComplete,
  onCancel,
  onReplace,
}: EditModeProps) => {
  const updateMediaFull = useUpdateMediaFull();

  const {
    handleSubmit,
    formState: { errors: formErrors, isDirty, isValid },
    register,
    reset,
  } = useForm();

  const {
    data: media,
    isLoading: mediaIsLoading,
    error: mediaError,
  } = useMediaQuery({
    id: mediaId,
    options: {
      onSuccess: media => {
        reset({ name: media.doc.name });
      },
    },
  });

  const onSave = formValues => {
    const updateMediaPromise = updateMediaFull.mutateAsync(
      {
        ...media?.doc!,
        name: formValues.name,
      },
      {
        onSuccess: () => {
          onComplete(mediaId!);
        },
      },
    );

    // resolve promise with toast notifications
    toast.promise(updateMediaPromise, {
      pending: 'Updating media...',
      error: 'Failed to update media.',
      success: 'Media updated!',
    });
  };

  return mediaIsLoading || updateMediaFull.isLoading ? (
    <DialogCircularProgress
      label={mediaIsLoading ? 'Loading media...' : 'Updating media...'}
    />
  ) : (
    <>
      <DialogTitle>
        <Grid
          spacing={1}
          alignItems={'center'}
          justifyContent={'space-between'}
          container
        >
          <Grid item>Edit Media</Grid>
          <Grid item>
            <Button onClick={onReplace}>Change Media</Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <>
        <Divider />
        <DialogContent>
          {mediaError ? (
            <Typography color={'red'}>Error loading media.</Typography>
          ) : !media ? (
            <Typography color={'red'}>Media not found.</Typography>
          ) : (
            <Grid container spacing={1} alignItems={'center'}>
              <Grid item>
                <TextField
                  label={'Media Name'}
                  autoComplete={'off'}
                  {...register('name', { required: true })}
                  error={formErrors.name}
                  helperText={formErrors.name ? 'name is required' : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item>
                <AudioPlayer mediaId={mediaId!} />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <Divider />
        <DefaultDialogActions
          errorMessage={updateMediaFull.error?.message}
          saveDisabled={!isDirty || !isValid}
          onSave={handleSubmit(onSave)}
          onCancel={onCancel}
        />
      </>
    </>
  );
};

const convertFormDataToFullMediaDoc = (formData, mediaType: MediaType) => {
  console.log('formData', formData);
  switch (mediaType) {
    case MediaType.Tts:
      return {
        name: formData.name,
        media_source: mediaType,
        tts: formData.tts,
        language: 'en-us',
      };
    case MediaType.Upload:
      return {
        name: formData.name,
        media_source: mediaType,
      };
    case MediaType.Recording:
      return {
        name: formData.name,
        media_source: MediaType.Upload,
      };
    case MediaType.BuiltIn:
      return formData[MediaType.BuiltIn].link;
    default:
      throw new Error(`Unhandled media type ${mediaType}`);
  }
};

export default MediaDialog;
