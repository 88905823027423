import { Grid, InfoTooltip, Link, Typography } from 'app/design';
import { useFindInPage } from 'app/components/FindInPage';
import UserDetailsDialog from 'app/pages/admin/Users/View/components/UserSettingsCard/components/UserDetailsDialog/UserDetailsDialog';
import { useToggleReducer } from 'app/utilities';
import { useMarkdownTranslate } from 'app/utilities/translate';
import * as React from 'react';
import { User } from 'types/user';

interface UserTimezoneProps {
  user: User;
}

const UserLoginDetails = ({ user }: UserTimezoneProps) => {
  const [showDialog, toggleShowDialog] = useToggleReducer();
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'login',
    options: [
      {
        label: 'Login',
        value: 'login',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const { t } = useMarkdownTranslate();

  return (
    <>
      {showDialog ? (
        <UserDetailsDialog
          userId={user.id}
          onCancel={toggleShowDialog}
          onComplete={toggleShowDialog}
        />
      ) : null}
      {/* @ts-ignore */}
      <div ref={ref}>
        <HighlightPulse {...highlightPulseProps} />
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <Typography variant="h3">
              Login{' '}
              <InfoTooltip title={t('user.login.tooltip')} size={'small'} />
            </Typography>
            {user.doc.username ? (
              <Typography variant="h4">{user.doc.username}</Typography>
            ) : (
              <Typography
                variant="h4"
                sx={{ fontStyle: 'italic', color: 'text.secondary' }}
              >
                Not set...
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Link variant="body2" onClick={toggleShowDialog} underline="hover">
              Edit Login
            </Link>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default UserLoginDetails;
