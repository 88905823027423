import { ExtensionsTableCell } from 'app/components/ExtensionsTableCell';
import { QuickFinderGenericDialog } from 'app/components/QuickFinderGenericDialog';
import { useListUsersQuery } from 'app/hooks/queries/user';
import { parseCallflowNumbers } from 'app/utilities';
import {
  QUERY_SEARCH_INPUT_CHANGED,
  useQueryPaginationReducer,
} from 'app/utilities/useQueryPaginationReducer';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { QuickFinderDialogProps } from 'types/components';
import { User } from 'types/user';
import { DialogBuilder } from 'app/utilities';

const QuickFinderUserDialog = ({
  onSelect,
  mutationLoading = false,
  mutationLoadingLabel,
  errorMessage,
  onCancel,
  onClear,
  multiple = false,
  initialSelected,
  selectionTitle,
  exclude,
  passResourceOnSelect,
}: QuickFinderDialogProps) => {
  // query pagination state
  const [{ queryPageSize, queryPageIndex, querySearchInput }, dispatch] =
    useQueryPaginationReducer(0, 5);

  const columns = useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: (user: User) => user.doc.first_name,
      },
      {
        Header: 'Last Name',
        accessor: (user: User) => user.doc.last_name,
      },
      {
        Header: 'Ext(s)',
        accessor: (user: User) => user.doc.presence_id,
        Cell: ({ row }) => {
          const { original: user }: { original: User } = row;

          const mainCallflowNumbers = user.Callflows?.find(
            cf => cf.doc.type === 'main',
          )?.doc.numbers;

          const { extensions } = mainCallflowNumbers
            ? parseCallflowNumbers(mainCallflowNumbers)
            : { extensions: [] };

          return <ExtensionsTableCell extensions={extensions} />;
        },
        disableSortBy: true,
      },
    ],
    [],
  );

  // user list
  const userListQuery = useListUsersQuery({
    skip: queryPageIndex * queryPageSize,
    take: queryPageSize,
    orderBy: [['lastName', 'asc']],
    filters: {
      searchInput: querySearchInput,
    },
  });
  const { users, totalCount = 0 } = userListQuery.data ?? {};

  const handleSearch = (searchInput: string) => {
    if (searchInput === querySearchInput) {
      userListQuery.refetch();
    } else {
      dispatch({ type: QUERY_SEARCH_INPUT_CHANGED, payload: searchInput });
    }
  };

  return (
    <QuickFinderGenericDialog
      // dialog
      title={multiple ? 'Select Multiple Users' : 'Select User'}
      mutationLoading={mutationLoading}
      mutationLoadingLabel={mutationLoadingLabel}
      onSelect={onSelect}
      onCancel={onCancel}
      onClear={onClear}
      errorMessage={errorMessage}
      selectionTitle={selectionTitle}
      exclude={exclude}
      passResourceOnSelect={passResourceOnSelect}
      // table
      onSearch={handleSearch}
      getRowId={(row: User) => row.id}
      searchPlaceholder={'Search Users...'}
      totalCount={totalCount}
      columns={columns}
      data={users ?? []}
      multiple={multiple}
      initialSelected={initialSelected}
      // query
      queryIsFetching={userListQuery.isFetching}
      queryIsLoading={userListQuery.isLoading}
      queryPageSize={queryPageSize}
      queryPageIndex={queryPageIndex}
      queryPaginationDispatch={dispatch}
    />
  );
};

export const useQuickFinderUserDialog = DialogBuilder(QuickFinderUserDialog);

export default QuickFinderUserDialog;
