import { ROW_FIXED_LEFT } from 'app/components/ListGeneric/ListGeneric';

const prepareStickyColumns = (columns: any[]) => {
  columns.forEach((column, index) => {
    // options column - skip
    if (index === columns.length - 1) {
      column.headerProps = {
        ...column.headerProps,
        // @ts-ignore
        position: 'sticky',
        // @ts-ignore
        right: 0,
        background: 'white',
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
        minWidth: column.headerProps.width,
        // @ts-ignore
        maxWidth: column.headerProps.width,
      };
      return;
    }

    // @ts-ignore
    if (column.headerProps?.position === 'sticky') {
      column.headerProps = {
        ...column.headerProps,
        // @ts-ignore
        background: 'white',
        // @ts-ignore
        minWidth: column.headerProps.width,
        // @ts-ignore
        maxWidth: column.headerProps.width,
        left:
          index === 0
            ? ROW_FIXED_LEFT
            : // @ts-ignore
              columns[index - 1].headerProps?.width +
              // @ts-ignore
              columns[index - 1].headerProps?.left, // table cell padding,
        zIndex: 1,
      };
      // @ts-ignore
      // column.cellProps = {
      //   ...column.headerProps,
      // };
    } else {
      column.headerProps = {
        ...column.headerProps,
        // @ts-ignore
        minWidth: column.headerProps.width,
        // @ts-ignore
        // maxWidth: column.headerProps.width,
      };
    }
  });
};

export default prepareStickyColumns;
