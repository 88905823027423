import {
  Box,
  Paper,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Typography,
  Divider,
  Tooltip,
} from 'app/design';
import {
  TableView as TableViewIcon,
  TableRows as TableRowsIcon,
  ViewAgenda as ViewAgendaIcon,
} from 'app/design/icons-material';
import DefaultTable from 'app/components/DefaultTable/DefaultTable';
import DefaultTablePagination from 'app/components/DefaultTablePagination/DefaultTablePagination';
import { Keywords } from 'app/components/KeywordTooltip/KeywordTooltip';
import BulkToolbar from 'app/components/ListGeneric/components/BulkToolBar/BulkToolbar';
import { ListToggleColumns } from 'app/components/ListToggleColumns';
import { SlateSearch } from 'app/components/SlateSearch';
import { BulkActions } from 'app/pages/office/Voicemails/components/VmboxMessageList/components/BulkActions';
import * as React from 'react';
import { Dispatch, ReactNode, useEffect, useState } from 'react';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import { VmboxMessage } from 'types/vmboxMessage';
import { useImmer } from 'use-immer';
import { StringParam, useQueryParams } from 'use-query-params';

interface ListGenericProps {
  columns: any[];
  data: any[];
  totalCount: number;
  initialPageSize: number;
  initialPageIndex: number;
  queryPaginationDispatch: Dispatch<{ type: any; payload: any }>;
  queryIsFetching: boolean;
  queryIsLoading: boolean;
  searchPlaceholder: string;
  getRowId: (row: any) => string;
  actions?: ReactNode;
  initialSortBy?: { id: string; desc: boolean }[];
  searchText: string;
  onSearchTextChange?: (search: string) => void;
  onSearch: (search: string) => void;
  initialHiddenColumns?: string[];
  bulkActions?: ReactNode;
  searchComponents?: ReactNode;
  keywords?: Keywords;
  empty?: ReactNode;
  Card?: any;
  refetch?: any;
}

const ROW_SELECT_WIDTH = 70;
const ROW_NUMBER_WIDTH = 33;
export const ROW_FIXED_LEFT = ROW_SELECT_WIDTH + ROW_NUMBER_WIDTH;

const ListGeneric = ({
  columns,
  data,
  totalCount,
  initialPageSize,
  initialPageIndex,
  queryPaginationDispatch,
  queryIsFetching,
  queryIsLoading,
  searchPlaceholder,
  getRowId,
  actions,
  initialSortBy = [],
  searchText = '',
  onSearchTextChange,
  onSearch,
  initialHiddenColumns = [],
  bulkActions,
  searchComponents,
  keywords,
  empty,
  Card,
  refetch,
}: ListGenericProps) => {
  // required because only row IDs are stored across pages
  const [selectedRows, setSelectedRows] = useImmer<any[]>([]);

  const [{ display = 'cards' }, setQs] = useQueryParams({
    display: StringParam,
  });

  // table state
  const {
    allColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    getToggleHideAllColumnsProps,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    toggleAllRowsSelected,
    toggleRowSelected,
    // Get the state from the instance
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        pageIndex: initialPageIndex,
        pageSize: initialPageSize,
        sortBy: initialSortBy,
        hiddenColumns: initialHiddenColumns,
      },
      getRowId,

      // settings for manual pagination
      autoResetPage: false,
      autoResetSelectedRows: false,
      autoResetRowState: false,
      manualPagination: true,
      pageCount: totalCount ? Math.ceil(totalCount / initialPageSize) : null,

      // settings for manual sorting
      manualSortBy: true,
      autoResetSortBy: false,
      disableSortRemove: true,
      disableMultiSort: true, // TODO: add eventually?
    },
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => {
        return [
          {
            id: 'rowNumber',
            Cell: ({ row, state: { pageSize, pageIndex } }) => {
              return (
                <Typography sx={{ color: 'text.secondary' }}>
                  {row.index + 1 + pageSize * pageIndex}
                </Typography>
              );
            },
            disableToggle: true,
            headerProps: {
              width: ROW_NUMBER_WIDTH,
              minWidth: ROW_NUMBER_WIDTH,
              maxWidth: ROW_NUMBER_WIDTH,
              left: 0,
              position: 'sticky',
              background: 'white',
              zIndex: 1,
            },
          },
          {
            id: 'selection',
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <Checkbox
                sx={{ fontSize: 20 }}
                {...getToggleAllPageRowsSelectedProps()}
              />
            ),
            Cell: ({ row }) => (
              <Checkbox
                sx={{ fontSize: 20 }}
                {...row.getToggleRowSelectedProps()}
              />
            ),
            disableToggle: true,
            headerProps: {
              width: ROW_SELECT_WIDTH,
              minWidth: ROW_SELECT_WIDTH,
              maxWidth: ROW_SELECT_WIDTH,
              left: ROW_NUMBER_WIDTH,
              position: 'sticky',
              background: 'white',
              zIndex: 1,
            },
          },
          ...columns.slice(0, -1),
          // add filler cell to keep options column fixed width
          {
            id: 'fill',
            Cell: () => '',
            disableToggle: true,
          },
          columns[columns.length - 1],
        ];
      });
    },
  );

  useEffect(() => {
    setSelectedRows(prevState => {
      // filter out any removed messages
      let selectedRows = prevState.filter(row => !!selectedRowIds[row.id]);

      // get array of missing row ids
      const missingIds = Object.keys(selectedRowIds).filter(
        rowId => !selectedRows.find(row => row.id === rowId),
      );

      // add missing row Ids
      missingIds.forEach(missingId => {
        const missingRow = data?.find(row => getRowId(row) === missingId);

        if (missingRow) selectedRows.push(missingRow);
        else throw new Error('Missing row not found!');
      });

      // return updated array of selected rows
      return selectedRows;
    });
  }, [selectedRowIds]);

  // only update table pageSize/index only table when the table data is
  // updated (loaded/fetched)
  useEffect(() => {
    setPageSize(initialPageSize);
    gotoPage(initialPageIndex);
  }, [data]);

  // console.log('initialSearchText:', initialSearchText);

  return (
    <div>
      <Grid
        container
        spacing={1}
        alignItems={'center'}
        sx={{ width: '100%' }}
        wrap={'nowrap'}
      >
        <Grid item>
          <SlateSearch
            loading={queryIsFetching}
            searchText={searchText}
            onChange={onSearchTextChange}
            onSearch={onSearch}
            placeholder={searchPlaceholder}
            searchComponents={searchComponents}
            keywords={keywords}
          />
        </Grid>
        <Divider flexItem orientation="vertical" sx={{ ml: 1, mt: 1 }} />
        {Card ? (
          display === 'cards' ? (
            // Show "cards" view
            <>
              <Grid item sx={{ flex: 1 }}>
                <Tooltip
                  title={
                    <>
                      Currently in <strong>Card</strong> View. <br />
                      Click to change to Table View
                    </>
                  }
                  arrow
                >
                  <Button
                    variant="outlined"
                    onClick={e => setQs({ display: 'table' }, 'pushIn')}
                  >
                    <ViewAgendaIcon />
                  </Button>
                </Tooltip>
              </Grid>
            </>
          ) : (
            // Show "table" view
            <>
              <Grid item>
                <Tooltip
                  title={
                    <>
                      Currently in <strong>Table</strong> View. <br />
                      Click to change to Card View
                    </>
                  }
                  arrow
                >
                  <Button
                    variant="outlined"
                    onClick={e => setQs({ display: 'cards' }, 'pushIn')}
                  >
                    <TableRowsIcon />
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item sx={{ flex: 1 }}>
                <ListToggleColumns
                  allColumns={allColumns}
                  getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
                />
              </Grid>
            </>
          )
        ) : (
          // no Cards available, show Table view
          <Grid item sx={{ flex: 1 }}>
            <ListToggleColumns
              allColumns={allColumns}
              getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
            />
          </Grid>
        )}
        {actions ? <Grid item>{actions}</Grid> : null}
      </Grid>
      <br />
      {queryIsLoading ? (
        <Box sx={{ display: 'grid', placeItems: 'center', padding: 4 }}>
          <CircularProgress variant={'indeterminate'} />
          <Typography variant="body2">Loading results...</Typography>
        </Box>
      ) : (
        <>
          {Card && display === 'cards' ? (
            <Cards
              Card={Card}
              page={page}
              prepareRow={prepareRow}
              refetch={refetch}
              toggleRowSelected={toggleRowSelected}
            />
          ) : (
            // @ts-ignore
            <Paper variant="dashboard" sx={{ p: 1 }}>
              <DefaultTable
                getTableProps={getTableProps}
                getTableBodyProps={getTableBodyProps}
                page={page}
                headerGroups={headerGroups}
                prepareRow={prepareRow}
                emptyRowCount={pageSize - page.length}
                queryPaginationDispatch={queryPaginationDispatch}
                allColumns={allColumns}
              />
            </Paper>
          )}
          {!totalCount ? (
            <Box
              sx={{
                padding: 3,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#fcfcfc',
                // border: '0.5px solid #e6e6e6',
                borderTop: 0,
              }}
            >
              {empty ? empty : 'No Results'}
            </Box>
          ) : null}
          <DefaultTablePagination
            totalRowCount={totalCount}
            rowsPerPage={initialPageSize}
            pageIndex={pageIndex}
            queryPaginationDispatch={queryPaginationDispatch}
            gotoPage={gotoPage}
            setPageSize={setPageSize}
          />
        </>
      )}
      {bulkActions && selectedRows.length ? (
        <BulkToolbar
          toggleSelect={toggleAllRowsSelected}
          selectedRows={selectedRows}
        >
          {bulkActions}
        </BulkToolbar>
      ) : null}
    </div>
  );
};

const Cards = props => {
  const { Card, page, prepareRow, refetch, toggleRowSelected } = props;
  return (
    <>
      {page.map(row => {
        // Prepare the row for display
        prepareRow(row);

        return (
          // @ts-ignore
          <Card
            {...row.getRowProps()}
            row={row}
            refetch={refetch}
            toggleRowSelected={toggleRowSelected}
          />
        );
      })}
    </>
  );
};

export default ListGeneric;
