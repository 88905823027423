import constants from 'app/constants';
import { Box } from 'app/design';
import { isNumber, maxBy } from 'lodash';
import * as React from 'react';
import { Portal } from 'react-portal';

const OverlayMask = ({ holesToPoke }) => {
  const maxHeight =
    document.body.scrollHeight > window.innerHeight
      ? document.body.scrollHeight
      : window.innerHeight;

  // get highest highlightHoverLevel that is hovered, use that one for hovering
  // - TODO: allow multiple potentially?
  const maxObj = maxBy(
    holesToPoke.filter(h => h.hoverHighlight),
    'highlightHoverLevel',
  );
  // @ts-ignore
  const max = maxObj?.highlightHoverLevel ?? -1;
  const exclusiveHoverIdx = holesToPoke.findIndex(
    hole => hole.highlightHoverLevel === max && hole.hoverHighlight,
  );

  // when hovering over a max-exclusive item
  const ignoreHoverHighlight =
    max === constants.findInPage.searchHoverLevel ? true : false;

  // when i'm hovering over something that is NOT the excluseHover
  // - TODO: also potentially track the state, so we dont revert to the selected item
  const ignoreSelectHighlight =
    exclusiveHoverIdx > -1 && !ignoreHoverHighlight ? true : false;

  return (
    <Portal>
      <Box
        sx={{
          width: '100vw',
          height: `${maxHeight}px`,
          position: 'absolute',
          top: window.scrollY ?? 0,
          left: 0,
          zIndex: 10000,
          pointerEvents: 'none',
          // clipPath: 'url(#vlClipPathId)', // old way of "poking holes" by building up the outside rectangles (ughhhhh)
          // '& rect.fillOnHover': {
          //   fill: '#555',
          // },
          // '& rect.fillOnHover:hover': {
          //   fill: 'black',
          // },
        }}
      >
        <svg width={window.innerWidth} height={window.innerHeight}>
          <defs>
            <filter id="f1">
              <feGaussianBlur in="SourceGraphic" stdDeviation="4" />
            </filter>
            {/* <clipPath id="vlClipPathId">
              {polygonShapes.map((shape, i) => (
                // <polygon key={i} points={shape.points} />
                <rect
                  key={i}
                  x={shape.rect.x}
                  y={shape.rect.y}
                  width={shape.rect.w}
                  height={shape.rect.h}
                />
              ))}
            </clipPath> */}
          </defs>
          {/* Overlay rectangle that gets masked by "holes" below
              - animates in */}
          <rect
            fill="rgba(0, 0, 0, 0.5)"
            x="0"
            y="0"
            mask="url(#mask)"
            height="100%"
            width="100%"
          >
            <animate
              attributeName="fill"
              dur={'0.25s'}
              values={`rgba(0, 0, 0, 0.0);rgba(0, 0, 0, 0.5)`}
              // repeatCount="1" // indefinite
            ></animate>
          </rect>
          <mask
            id="mask"
            maskUnits="userSpaceOnUse"
            maskContentUnits="userSpaceOnUse"
          >
            <rect x="0" y="0" fill="white" height="100%" width="100%" />

            {/* "soft" (ie "blur" the box around) */}
            {holesToPoke.map((hole, holeIdx) => {
              let highlight = false;
              if (hole.alwaysHighlight) {
                highlight = true;
              }
              if (
                !ignoreHoverHighlight &&
                hole.hoverHighlight &&
                (exclusiveHoverIdx < 0 || holeIdx === exclusiveHoverIdx)
              ) {
                highlight = true;
              }
              if (!ignoreSelectHighlight && hole.selectHighlight) {
                highlight = true;
              }
              const blurVal = isNumber(hole.blur) ? hole.blur : 10;
              const pulseVal = highlight && hole.blur ? 10 : 0; // 10
              const pulseDuration = '0s'; // currently TURNED OFF!
              const fill = highlight
                ? 'black'
                : hole.showPreview
                ? 'rgba(0,0,0,0.2)'
                : 'rgba(0,0,0,0)'; // darker/lower-value => less highlighted on page
              return (
                <React.Fragment key={`${hole.id}${highlight}`}>
                  <g transform={`translate(${hole.x} ${hole.y})`}>
                    <rect
                      // x={hole.x}
                      // y={hole.y}
                      // className="fillOnHover"
                      height={hole.h}
                      width={hole.w}
                      fill={fill}
                      rx="5"
                      ry="5"
                    >
                      {/* animate */}
                      {hole.blur !== false ? (
                        <React.Fragment>
                          <animateTransform
                            attributeName="transform"
                            type="translate"
                            dur={pulseDuration}
                            values={`0,0;-${pulseVal},-${pulseVal};0,0`}
                            repeatCount="1" // indefinite
                          ></animateTransform>
                          <animate
                            attributeName="height"
                            dur={pulseDuration}
                            values={`${hole.h}; ${hole.h + pulseVal * 2}; ${
                              hole.h
                            }`}
                            repeatCount="1" // indefinite
                          ></animate>
                          <animate
                            attributeName="width"
                            dur={pulseDuration}
                            values={`${hole.w}; ${hole.w + pulseVal * 2}; ${
                              hole.w
                            }`}
                            repeatCount="1" // indefinite
                          ></animate>
                        </React.Fragment>
                      ) : null}
                    </rect>
                  </g>
                  {/* blur/soft */}
                  {hole.blur !== false ? (
                    <g
                      transform={`translate(${hole.x - blurVal} ${
                        hole.y - blurVal
                      })`}
                    >
                      <rect
                        // x={hole.x - blurVal}
                        // y={hole.y - blurVal}
                        height={hole.h + blurVal * 2}
                        width={hole.w + blurVal * 2}
                        fill={fill}
                        filter="url(#f1)"
                        rx="5"
                        ry="5"
                      >
                        <React.Fragment>
                          <animateTransform
                            attributeName="transform"
                            type="translate"
                            dur={pulseDuration}
                            values={`0,0;-${pulseVal},-${pulseVal};0,0`}
                            repeatCount="1" // indefinite
                          ></animateTransform>
                          <animate
                            attributeName="height"
                            dur={pulseDuration}
                            values={`${hole.h + blurVal * 2}; ${
                              hole.h + blurVal * 2 + pulseVal * 2
                            }; ${hole.h + blurVal * 2}`}
                            repeatCount="1" // indefinite
                          ></animate>
                          <animate
                            attributeName="width"
                            dur={pulseDuration}
                            values={`${hole.w + blurVal * 2}; ${
                              hole.w + blurVal * 2 + pulseVal * 2
                            }; ${hole.w + blurVal * 2}`}
                            repeatCount="1" // indefinite
                          ></animate>
                        </React.Fragment>
                      </rect>
                    </g>
                  ) : null}
                </React.Fragment>
              );
            })}
          </mask>
        </svg>
      </Box>
    </Portal>
  );
};

const buildShapes = holesToPoke => {
  const maxHeight =
    document.body.scrollHeight > window.innerHeight
      ? document.body.scrollHeight
      : window.innerHeight;

  let overlayBoxes = [
    { x: 0, y: 0, w: window.innerWidth, h: maxHeight },
    // { x: 0, y: 0, w: 200, h: 200 },
  ];

  // console.log('holesToPoke:', holesToPoke.length);

  // find collisions/overlays, build new boxes
  // console.log('Holes to poke:', holesToPoke.length, holesToPoke);
  holesToPoke.map(hole => {
    if (!hole.w || !hole.h) {
      return;
    }
    let tmpboxes = [] as any[];
    overlayBoxes.map(box => {
      // detect overlap
      const overlap = checkOverlap(
        { x: box.x, y: box.y },
        { x: box.x + box.w, y: box.y + box.h },
        { x: hole.x, y: hole.y },
        { x: hole.x + hole.w, y: hole.y + hole.h },
      );
      if (overlap) {
        // create new boxes around overlap area
        // - delete existing box!
        /*
        Box:
        -------------------------
        |          A            |
        |                       |
        |-----------------------|
        |  B  |   Hole    |  C  |
        |-----------------------|
        |                       |
        |          D            |
        -------------------------
        */

        const newBoxes = [
          // a above
          boxAbove(box, hole),
          // b left
          boxLeft(box, hole),
          // c right
          boxRight(box, hole),
          // d below
          boxBelow(box, hole),
        ];

        if (newBoxes.length) {
          // @ts-ignore
          tmpboxes = tmpboxes.concat(newBoxes.filter(b => !!b));
          // console.log('FROM', box, 'TO:', newBoxes);
        } else {
          // overwrite ENTIRE box by hole
          debugger;
        }
      } else {
        // no overlap
        tmpboxes.push(box);
      }
      // console.log('overlap', overlap, overlayBoxes);
    });
    // debugger
    overlayBoxes = tmpboxes;
  });
  // debugger;

  // console.log('boxes:', overlayBoxes.length);

  const shapes = overlayBoxes.map(box => {
    // topleft, topright, bottomleft, bottomright
    return {
      // points: [
      //   [box.x, box.y].join(','), // top-left
      //   [box.x + box.w, box.y].join(','), // top-right
      //   [box.x + box.w, box.y + box.h].join(','), // bottom-right
      //   [box.x, box.y + box.h].join(','), // bottom-left
      // ].join(' '),
      rect: box,
    };
  });

  return shapes;
};

function checkOverlap(
  firstTopLeft,
  firstBottomRight,
  secondTopLeft,
  secondBottomRight,
) {
  // To check if either rectangle is actually a line
  // For example : l1 ={-1,0} r1={1,1} l2={0,-1} r2={0,1}
  // console.log({
  //   firstTopLeft,
  //   firstBottomRight,
  //   secondTopLeft,
  //   secondBottomRight,
  // });

  // to the right?
  if (firstTopLeft.x >= secondBottomRight.x) {
    return false;
  }
  // to the left?
  if (firstBottomRight.x <= secondTopLeft.x) {
    return false;
  }

  // above?
  if (firstBottomRight.y <= secondTopLeft.y) {
    return false;
  }
  // below?
  if (firstTopLeft.y >= secondBottomRight.y) {
    return false;
  }

  return true;
}

const boxAbove = (box, hole) => {
  // get rectangle above hole
  // - no left/right matters, only above!
  const spaceAbove = Math.max(0, hole.y - box.y);
  if (spaceAbove > 0) {
    return {
      x: box.x,
      y: box.y,
      w: box.w,
      h: spaceAbove,
    };
  }
};
const boxBelow = (box, hole) => {
  // get rectangle below hole
  // - no left/right matters, only below!
  const spaceBelow = Math.max(0, box.y + box.h - (hole.y + hole.h));
  if (spaceBelow > 0) {
    return {
      x: box.x,
      y: hole.y + hole.h,
      w: box.w,
      h: spaceBelow,
    };
  }
};
const boxLeft = (box, hole) => {
  // get rectangle to the LEFT of hole
  // - requires knowing above/below as well!
  const spaceAbove = Math.max(0, hole.y - box.y);
  const spaceBelow = Math.max(0, box.y + box.h - (hole.y + hole.h));
  const spaceLeft = Math.max(0, hole.x - box.x);
  // console.log(
  //   'LEFT:',
  //   box.y + box.h - spaceBelow - spaceAbove,
  //   spaceAbove,
  //   spaceBelow,
  //   spaceLeft,
  //   box.y,
  //   box.h,
  // );
  if (spaceLeft > 0) {
    return {
      x: box.x,
      y: box.y + spaceAbove,
      w: spaceLeft,
      h: box.h - spaceBelow - spaceAbove,
    };
  }
};
const boxRight = (box, hole) => {
  // get rectangle to the RIGHT of hole
  // - requires knowing above/below as well!
  const spaceAbove = Math.max(0, hole.y - box.y);
  const spaceBelow = Math.max(0, box.y + box.h - (hole.y + hole.h));
  const spaceRight = Math.max(0, box.x + box.w - (hole.x + hole.w));
  if (spaceRight > 0) {
    return {
      x: hole.x + hole.w,
      y: box.y + spaceAbove,
      w: spaceRight,
      h: box.h - spaceBelow - spaceAbove,
    };
  }
};

export default OverlayMask;
