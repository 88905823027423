import { ExtensionsTableCell } from 'app/components/ExtensionsTableCell';
import { CreateGroupButton } from 'app/components/ListGroups/components/CreateGroupButton';
import { QuickFinderGenericDialog } from 'app/components/QuickFinderGenericDialog';
import { useListGroupsQuery } from 'app/hooks/queries/group';
import { parseCallflowNumbers } from 'app/utilities';
import {
  QUERY_SEARCH_INPUT_CHANGED,
  useQueryPaginationReducer,
} from 'app/utilities/useQueryPaginationReducer';
import { startCase } from 'lodash';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { QuickFinderDialogProps } from 'types/components';
import { Group } from 'types/group';

const QuickFinderGroupDialog = ({
  onSelect,
  mutationLoading = false,
  mutationLoadingLabel,
  errorMessage,
  onCancel,
  multiple = false,
  initialSelected,
  selectionTitle,
  exclude,
  passResourceOnSelect,
  allowCreate,
}: QuickFinderDialogProps) => {
  // query pagination state
  const [{ queryPageSize, queryPageIndex, querySearchInput }, dispatch] =
    useQueryPaginationReducer(0, 5);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (group: Group) => group.doc.name,
      },
    ],
    [],
  );

  // group list
  const groupListQuery = useListGroupsQuery(
    queryPageIndex * queryPageSize,
    queryPageSize,
    [['name', 'asc']],
    { searchInput: querySearchInput },
  );
  const { groups, totalCount = 0 } = groupListQuery.data ?? {};

  const handleSearch = (searchInput: string) => {
    if (searchInput === querySearchInput) {
      groupListQuery.refetch();
    } else {
      dispatch({ type: QUERY_SEARCH_INPUT_CHANGED, payload: searchInput });
    }
  };

  const handleCreate = (groupId: string) => {
    // TODO: does not work with passResource
    onSelect([groupId]);
  };

  return (
    <QuickFinderGenericDialog
      // dialog
      title={multiple ? 'Select Multiple Groups' : 'Select Group'}
      mutationLoading={mutationLoading}
      mutationLoadingLabel={mutationLoadingLabel}
      onSelect={onSelect}
      onCancel={onCancel}
      errorMessage={errorMessage}
      selectionTitle={selectionTitle}
      passResourceOnSelect={passResourceOnSelect}
      titleButton={
        allowCreate ? (
          <CreateGroupButton
            navigateOnComplete={false}
            onComplete={handleCreate}
          />
        ) : null
      }
      // table
      getRowId={(row: Group) => row.id}
      searchPlaceholder={'Search Groups...'}
      totalCount={totalCount}
      columns={columns}
      data={groups ?? []}
      multiple={multiple}
      initialSelected={initialSelected}
      exclude={exclude}
      onSearch={handleSearch}
      // query
      queryIsFetching={groupListQuery.isFetching}
      queryIsLoading={groupListQuery.isLoading}
      queryPageSize={queryPageSize}
      queryPageIndex={queryPageIndex}
      queryPaginationDispatch={dispatch}
    />
  );
};

export default QuickFinderGroupDialog;
