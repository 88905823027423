import { Card, CardContent, CardHeader } from 'app/design';
import { DetailsDialogGroup } from 'app/components/DetailsDialogGroup';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { GroupHoldMusic } from 'app/pages/admin/Groups/View/components/GroupSettingsCard/components/GroupHoldMusic';
import { GroupName } from 'app/pages/admin/Groups/View/components/GroupSettingsCard/components/GroupName';
import { GroupUsage } from 'app/pages/admin/Groups/View/components/GroupSettingsCard/components/GroupUsage';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { Group } from 'types/group';

interface GroupSettingsCardProps {
  group: Group;
  onComplete: () => void;
}

const GroupSettingsCard = ({ group, onComplete }: GroupSettingsCardProps) => {
  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'settings',
    options: [
      {
        label: 'Settings',
        value: 'settings',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const handleOnComplete = () => {
    onComplete();
    toggleShowEditDialog();
  };

  return (
    <>
      {/*@ts-ignore*/}
      <Card ref={ref} variant="outlined">
        <HighlightPulse {...highlightPulseProps} />
        <CardHeader title="Group Settings" />
        <CardContent>
          <GroupHoldMusic group={group} onComplete={onComplete} />
          {/* <br />
          <GroupUsage group={group} onComplete={onComplete} /> */}
          <br />
          <GroupName group={group} onComplete={onComplete} />
        </CardContent>
      </Card>
    </>
  );
};

export default GroupSettingsCard;
