import {
  createPlateUI,
  createPlugins,
  createReactPlugin,
  createSingleLinePlugin,
  Plate,
} from '@udecode/plate';
import { Search as SearchIcon } from 'app/design/icons-material';
import { Box, Button, Grid, Typography, Divider } from 'app/design';
import { KeywordTooltip } from 'app/components/KeywordTooltip';
import { Keywords } from 'app/components/KeywordTooltip/KeywordTooltip';
import { MarkKeyword } from 'app/components/SlateSearch/components/MarkKeyword';
import { MarkValue } from 'app/components/SlateSearch/components/MarkValue';
import {
  createKeywordPlugin,
  createValuePlugin,
} from 'app/components/SlateSearch/plugins';
import { MARK_KEYWORD } from 'app/components/SlateSearch/plugins/createKeywordPlugin';
import { MARK_VALUE } from 'app/components/SlateSearch/plugins/createValuePlugin';
import { useSearchDecorations } from 'app/components/SlateSearch/useSearchDecorations';
import * as React from 'react';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { Node } from 'slate';

export const KeywordContext = createContext<Keywords>({});

interface SlateSearchProps {
  initialText?: string;
  placeholder?: string;
  loading: boolean;
  onChange?: (search: string) => void;
  onSearch: (search: string) => void;
  searchText?: string;
  searchComponents?: ReactNode;
  keywords?: Keywords;
}

const plugins = createPlugins(
  [
    createReactPlugin(),
    createSingleLinePlugin(),
    createKeywordPlugin(),
    createValuePlugin(),
  ],
  {
    // Plate components
    components: createPlateUI({
      [MARK_KEYWORD]: MarkKeyword,
      [MARK_VALUE]: MarkValue,
    }),
  },
);

const textToSlate = (text: string) => [{ children: [{ text }] }];

const SlateSearch = ({
  loading = false,
  initialText = '',
  placeholder = 'Search...',
  onChange,
  onSearch,
  searchText = '',
  searchComponents,
  keywords,
}: SlateSearchProps) => {
  const decorate = useSearchDecorations();
  // unique id for slate instance
  const [id] = useState(Date.now().toString());

  const [slateValue, setSlateValue] = useState<Node[]>(() =>
    textToSlate(searchText),
  );

  const handleSearch = () => {
    // deserialize slate value
    const text = slateValue.map(n => Node.string(n)).join(' ');
    onSearch(text);
  };

  useEffect(() => {
    setSlateValue(textToSlate(searchText));
  }, [searchText]);

  return (
    <Grid
      container
      wrap={'nowrap'}
      spacing={1}
      alignItems={'center'}
      sx={{ width: '100%' }}
    >
      <Grid item sx={{ flex: 1 }}>
        <Box
          sx={{
            // @ts-ignore
            border: theme => `1px solid ${theme.palette.card.border}`,
            background: 'white',
            // height: 40,
            borderRadius: 1,
            // p: 1,
            // width: '100%',
            minWidth: 400,
            // width: 100,
            whiteSpace: 'nowrap',
            '& [data-slate-placeholder]': {
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflowY: 'hidden',
              width: '100%',
            },
          }}
        >
          <Grid
            container
            alignItems={'center'}
            columnSpacing={1}
            sx={{ width: '100%', ml: 0 }}
            wrap={'nowrap'}
          >
            <Grid item sx={{ mb: '-8px' }}>
              {/* @ts-ignore */}
              <Typography sx={{ color: theme => theme.palette.primary.main }}>
                <SearchIcon />
              </Typography>
            </Grid>
            <Grid item sx={{ flex: 1 }}>
              <KeywordContext.Provider value={keywords ?? {}}>
                <Plate
                  id={id}
                  value={slateValue}
                  onChange={setSlateValue}
                  plugins={plugins}
                  editableProps={{
                    placeholder,
                    decorate,
                    style: {
                      // whiteSpace: 'nowrap',
                      overflowX: 'auto',
                      overflowY: 'hidden',
                      width: '100%',
                    },
                    onKeyDown: event => {
                      if (event.key === 'Enter') {
                        handleSearch();
                      }
                      // console.log('event', event);
                    },
                  }}
                />
              </KeywordContext.Provider>
            </Grid>
            {keywords ? (
              <Grid item>
                <div style={{ height: 20, width: 20 }}>
                  <KeywordTooltip keywords={keywords} />
                </div>
              </Grid>
            ) : null}
            {/* <Divider flexItem orientation="vertical" sx={{ ml: 1, mt: 1 }} /> */}
            <Grid item>
              <Button
                variant={'contained'}
                onClick={handleSearch}
                loading={loading}
                sx={{
                  borderRadius: 0,
                  borderTopRightRadius: '2px',
                  borderBottomRightRadius: '2px',
                  mt: '-1px',
                  mb: '-1px',
                  // @ts-ignore
                  // background: theme => theme.palette.card.border,
                  background: '#6ec7de',
                }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {searchComponents ? <Grid item>{searchComponents}</Grid> : null}
    </Grid>
  );
};

export default SlateSearch;
