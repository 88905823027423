import { useEffect, useReducer } from 'react';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

export const QUERY_PAGE_INDEX_CHANGED = 'PAGE_CHANGED';
export const QUERY_PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
export const QUERY_ORDER_BY_CHANGED = 'ORDER_BY_CHANGED';
export const QUERY_SEARCH_INPUT_CHANGED = 'SEARCH_INPUT_CHANGED';
const PAGE_DEFUALT = 1;
const SIZE_DEFAULT = 10;

const tablePaginationReducer = (state, { type, payload }) => {
  switch (type) {
    case QUERY_PAGE_INDEX_CHANGED:
      return {
        ...state,
        queryPageIndex: payload,
      };
    case QUERY_PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload,
      };
    case QUERY_ORDER_BY_CHANGED:
      return {
        ...state,
        queryOrderBy: payload,
      };
    case QUERY_SEARCH_INPUT_CHANGED:
      return {
        ...state,
        querySearchInput: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

export const useQueryPaginationReducerWithQueryParams = (
  initialQueryPageIndex = 0,
  initialQueryPageSize = 10,
  initialQueryOrderBy = [['createdAt', 'desc']],
) => {
  const [
    {
      search = '',
      page = initialQueryPageIndex + 1,
      size = initialQueryPageSize,
    },
    setQs,
  ] = useQueryParams({
    search: StringParam,
    page: NumberParam,
    size: NumberParam,
  });

  const [state, dispatch] = useReducer(tablePaginationReducer, {
    queryPageIndex: page ? page - 1 : initialQueryPageIndex,
    queryPageSize: size ?? initialQueryPageSize,
    queryOrderBy: initialQueryOrderBy,
    querySearchInput: search,
  });

  const { queryPageSize, queryPageIndex, queryOrderBy, querySearchInput } =
    state;

  // keep reducer state in sync with query params
  useEffect(() => {
    console.log('from query params');
    const currentSize = size ?? SIZE_DEFAULT;
    if (currentSize !== queryPageSize) {
      dispatch({ type: QUERY_PAGE_SIZE_CHANGED, payload: currentSize });
    }

    const currentPage = page ?? PAGE_DEFUALT;
    if (currentPage - 1 !== queryPageIndex) {
      dispatch({
        type: QUERY_PAGE_INDEX_CHANGED,
        payload: currentPage - 1,
      });
    }

    if (search !== querySearchInput) {
      dispatch({ type: QUERY_SEARCH_INPUT_CHANGED, payload: search });
    }
  }, [size, page, search]);

  useEffect(() => {
    console.log('from internal state');

    let changes: {
      search?: string;
      page?: number;
      size?: number;
    } = {};

    if ((size ?? SIZE_DEFAULT) !== queryPageSize) {
      changes.size = queryPageSize;
    }

    if ((page ?? PAGE_DEFUALT) !== queryPageIndex + 1) {
      changes.page = queryPageIndex + 1;
    }

    if (search !== querySearchInput) {
      changes.search = querySearchInput;
      changes.page = queryPageIndex === PAGE_DEFUALT - 1 ? undefined : 1;
    }

    if (Object.keys(changes).length) setQs(changes, 'pushIn');
  }, [queryPageIndex, queryPageSize, querySearchInput]);

  return [state, dispatch];
};

export const mapQueryOrderByToTableSortBy = (orderBy: string[][]) => {
  return orderBy.map(order => ({
    id: order[0],
    desc: order[1] === 'desc',
  }));
};
