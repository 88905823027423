import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { CallflowUpdatePartialDoc } from 'types/callflow';
import { DeviceUpdatePartialDoc } from 'types/device';

export const useUpdateCallflowPartial = () => {
  const authState = useAuthSelector();
  return useMutation<any, AxiosError, CallflowUpdatePartialDoc, unknown>(
    (callflowPartialDoc: CallflowUpdatePartialDoc) =>
      sdk.callflow.mutate.updatePartial(
        {
          data: callflowPartialDoc,
          // other values such as "accept_charges" could go here
        },
        {
          authToken: authState?.auth_token,
        },
      ),
  );
};
