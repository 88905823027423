import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';

export const useDeleteMenu = () => {
  const authState = useAuthSelector();
  return useMutation<any, AxiosError, string, unknown>((menuId: string) =>
    sdk.menu.mutate.delete(menuId, authState?.auth_token),
  );
};
