import { fetchFromGraphql } from 'app/sdk/utils/graphql';
import {
  buildPutDefault,
  buildDeleteDefault,
  buildPatchDefault,
} from 'app/sdk/utils/kazoo';
import { buildPostVmboxMessage } from 'app/sdk/utils/kazoo/postVmboxMessage';

const exports = {
  // Queries
  // - graphql
  query: {
    vmboxMessages: (vars, fetchOptions) =>
      fetchFromGraphql('vmboxMessages', vars, fetchOptions),

    // Fetch a single vmbox by id
    // vmboxById: (vars, fetchOptions) =>
    //   fetchFromGraphql('vmboxById', vars, fetchOptions),
  },

  // Mutations
  // - direct to kazoo, wait for update server-side
  mutate: {
    // create: buildPutDefault('vmboxes', { buildUrlOpts: { skipId: true } }),
    updatePartial: buildPostVmboxMessage('vmboxes', 'messages'),
    // delete: buildDeleteDefault('vmboxes'),
  },
};

export default exports;
