import { Box, Button, Grid, Link, Typography } from 'app/design';
import React from 'react';

const BuyGreeting = ({}) => {
  return (
    <div style={{ display: 'grid', placeItems: 'center' }}>
      <Typography variant={'h1'}>Visit:</Typography>
      <br />
      <Grid container spacing={3}>
        <Grid item>
          <Link
            underline={'hover'}
            variant={'button'}
            href={'https://www.snaprecordings.com/'}
            target={'_blank'}
          >
            Snap Recordings
          </Link>
        </Grid>
        <Grid item>
          <Link
            underline={'hover'}
            variant={'button'}
            href={'https://voicestudiopro.com/'}
            target={'_blank'}
          >
            VoiceStudio Pro
          </Link>
        </Grid>
      </Grid>
      <br />
      <Link
        target={'_blank'}
        href={
          'https://fitsmallbusiness.com/best-professional-voicemail-greeting-providers'
        }
        variant={'caption'}
      >
        View comparison
      </Link>
    </div>
  );
};

export default BuyGreeting;
