import { DeviceCodecsDialog } from 'app/components/DeviceCodecsDialog';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { Device } from 'types/device';

interface DeviceCodecsProps {
  device: Device;
  onComplete: () => void;
}

const DeviceCodecs = ({ device, onComplete }: DeviceCodecsProps) => {
  const [showEditDialog, toggleEditDialog] = useToggleReducer(false);
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'codecs',
    options: [
      {
        label: 'Codecs',
        value: 'codecs',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });
  const handleComplete = () => {
    toggleEditDialog();
    onComplete();
  };

  return (
    // @ts-ignore
    <div ref={ref}>
      <HighlightPulse {...highlightPulseProps} />
      {showEditDialog ? (
        <DeviceCodecsDialog
          deviceId={device.id}
          onComplete={handleComplete}
          onCancel={toggleEditDialog}
        />
      ) : null}
      <GenericSettingsCardField
        title={'Audio Codecs'}
        content={
          device?.doc?.media?.audio?.codecs?.length
            ? device.doc.media.audio.codecs.join(', ')
            : undefined
        }
        editLabel={'Edit Codecs'}
        editOnClick={toggleEditDialog}
      />
      <br />
      <GenericSettingsCardField
        title={'Video Codecs'}
        content={
          device?.doc?.media?.video?.codecs?.length
            ? device.doc.media.video.codecs.join(', ')
            : undefined
        }
      />
    </div>
  );
};

export default DeviceCodecs;
