import { gql } from 'graphql-request';

export default gql`
  query account {
    account {
      id
      doc
    }
  }
`;
