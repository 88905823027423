import * as React from 'react';
import { OwnerTableCell } from 'app/components/OwnerTableCell';
import { AvatarCell } from 'app/components/ListUsers/components/AvatarCell';
import { useMarkdownTranslate } from 'app/utilities/translate';
import { Device } from 'types/device';
import { User } from 'types/user';
import { Chip, Grid, InfoTooltip } from 'app/design';

interface DeviceOwnerCellProps {
  owner: User;
  device: Device;
}

const DeviceOwnerCell = ({ owner, device }: DeviceOwnerCellProps) => {
  const { t } = useMarkdownTranslate();
  if (owner) {
    return <OwnerTableCell owner={owner} />;
  }

  return (
    <Grid container columnSpacing={1}>
      {device.doc.owner_id ? (
        // if orphaned (owner ID set, user does not exists)
        <>
          <Grid item>
            <Chip
              color={'error'}
              variant={'contained'}
              size={'small'}
              label={'Orphaned'}
            />
          </Grid>
          <Grid item>
            <InfoTooltip title={t('device.orphan.tooltip')} />
          </Grid>
        </>
      ) : (
        // unowned
        <>
          <Grid item>
            <Chip
              color={'primary'}
              variant={'contained'}
              size={'small'}
              label={'In Pool'}
            />
          </Grid>
          <Grid item>
            <InfoTooltip title={t('device.pool.tooltip')} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default DeviceOwnerCell;
