import { AudioPlayer } from 'app/components/AudioPlayer';
import { EditHoldMusicDialogUser } from 'app/components/EditHoldMusicDialogUser';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { BUILT_IN_AUDIO } from 'app/components/MediaDialog/components/BuiltInAudio/BUILT_IN_AUDIO';
import { useAccountQuery } from 'app/hooks/queries/account';
import { useToggleReducer } from 'app/utilities';
import { useMarkdownTranslate } from 'app/utilities/translate';
import { startCase } from 'lodash';
import { useMemo } from 'react';
import * as React from 'react';
import { User } from 'types/user';
import { Chip } from 'app/design';

interface HoldMusicUserProps {
  user: User;
  onComplete: () => void;
}

const HoldMusicUser = ({ user, onComplete }: HoldMusicUserProps) => {
  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'holdMusic',
    options: [
      {
        label: 'Hold Music',
        value: 'holdMusic',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const handleOnComplete = () => {
    onComplete();
    toggleShowEditDialog();
  };

  const mediaId = user.HoldMusic?.id;

  const { t } = useMarkdownTranslate();

  return (
    // @ts-ignore
    <div ref={ref}>
      <HighlightPulse {...highlightPulseProps} />
      {showEditDialog ? (
        <EditHoldMusicDialogUser
          userId={user.id}
          mediaId={user.doc.music_on_hold.media_id}
          onComplete={handleOnComplete}
          onCancel={toggleShowEditDialog}
        />
      ) : null}
      <GenericSettingsCardField
        title={'Hold Music'}
        titleHelp={t('hold_music.tooltip')}
        chip={
          user.HoldMusic?.inherited ? (
            <Chip
              color={'primary'}
              variant={'contained'}
              label={startCase(user.HoldMusic.inherited)}
              size={'small'}
            />
          ) : !mediaId ? (
            <Chip
              color={'primary'}
              variant={'contained'}
              label={'Platform Default'}
              size={'small'}
            />
          ) : null
        }
        content={<AudioPlayer mediaId={mediaId ?? BUILT_IN_AUDIO[0].link} />}
        editOnClick={toggleShowEditDialog}
        editLabel={
          user.doc.music_on_hold.media_id
            ? 'Change Hold Music'
            : 'Add Hold Music'
        }
      />
    </div>
  );
};

export default HoldMusicUser;
