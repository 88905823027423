import { Grid, IconButton, Tooltip } from 'app/design';
import { ELEMENT_TEMPORAL_RULE } from 'app/components/SchedulePlateEditor/components/TemporalRuleElement/createTemporalRulePlugin';
import React, { useEffect, useState } from 'react';
import {
  AddAlarm as AddAlarmIcon,
  AlarmOff as AlarmOffIcon,
  AlarmOn as AlarmOnIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
  HelpOutline as HelpOutlineIcon,
} from 'app/design/icons-material';
import { Node, Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import {
  setNodes,
  TEditor,
  usePlate,
  usePlateId,
  usePlateStore,
  useSlateProps,
} from '@udecode/plate';
import freeFormRegex from 'app/components/SchedulePlateEditor/utils/freeFormRegex';
import { TemporalPickerPopover } from 'app/components/SchedulePlateEditor/components/TemporalPickerPopover';

const matchRule = ruleText => {
  if (ruleText.length) {
    for (let freeFormInfo of freeFormRegex) {
      // @ts-ignore
      if (freeFormInfo.re === true || freeFormInfo.re.test(ruleText)) {
        // @ts-ignore
        const { groups, ...matchObj } = freeFormInfo.re.exec(ruleText); // ruleText.exec(freeFormInfo.re);
        // @ts-ignore
        return freeFormInfo.parse({ text: ruleText, groups });
      }
    }
  }

  return null;
};

const TemporalRuleElement = props => {
  const { children, attributes, nodeProps, element } = props;
  const ruleText = Node.string(element);
  const editor = useSlateStatic();
  const plateId = usePlateId();
  const {
    get: { editableProps },
  } = usePlateStore();
  const { readOnly } = editableProps() ?? {};
  // cannot use useStoreEditorRef? returns wrong editor?

  useEffect(() => {
    const match = matchRule(ruleText);

    try {
      setNodes(
        editor as TEditor,
        {
          // @ts-ignore
          temporal_rules: match?.temporal_rules ?? [],
          isValid: !!match,
          isEnabled: ruleText
            ? typeof element?.isEnabled === 'boolean'
              ? element?.isEnabled
              : true
            : true,
        },
        {
          at: ReactEditor.findPath(editor as ReactEditor, element),
        },
      );
    } catch (e) {
      console.error(e);
    }
  }, [ruleText, editor]);

  const handleToggleEnabled = () => {
    setNodes(
      editor as TEditor,
      {
        isEnabled: !element.isEnabled,
      },
      {
        at: ReactEditor.findPath(editor as ReactEditor, element),
      },
    );
  };

  const [anchorElement, setAnchorElement] = useState(null);
  const handleOpenMenu = event => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElement(null);
  };

  const handleInsertRule = rules => {
    Transforms.insertNodes(
      editor,
      rules.map(rule => ({
        type: ELEMENT_TEMPORAL_RULE,
        children: [{ text: rule }],
        isEnabled: true,
      })),
      {
        at: ReactEditor.findPath(editor as ReactEditor, element),
      },
    );
    setAnchorElement(null);
  };

  return (
    <Grid container alignItems={'center'} alignContent={'center'}>
      <Grid item contentEditable={false} style={{ paddingTop: 2 }}>
        {readOnly ? null : ruleText ? (
          element.isValid ? (
            <CheckIcon
              style={{
                color: '#4caf50',
                //fontSize: 12,
              }}
            />
          ) : (
            <ClearIcon
              style={{
                color: 'red',
                //fontSize: 12,
              }}
            />
          )
        ) : /* <>
            <TemporalPickerPopover
              handleInsertRule={handleInsertRule}
              anchorElement={anchorElement}
              onClose={handleCloseMenu}
            />

            <IconButton
              size="small"
              disableRipple
              onClick={handleOpenMenu}
              style={{ borderRadius: 5 }}
            >
              <AddAlarmIcon />
            </IconButton>
          </>*/
        null}
      </Grid>
      <Grid
        item
        sx={{
          flex: 1,
        }}
      >
        <span {...attributes}>{children}</span>
      </Grid>
      <Grid item>
        {readOnly ? null : element.isValid ? (
          <Tooltip
            title={element.isEnabled ? 'Enabled' : 'Disabled'}
            placement={'right'}
            arrow
          >
            <IconButton
              size="small"
              disableRipple
              onClick={handleToggleEnabled}
              sx={{
                borderRadius: 5,
                color: theme => theme.palette.primary.main,
              }}
            >
              {element.isEnabled ? (
                <AlarmOnIcon
                // color={'success'}
                />
              ) : (
                <AlarmOffIcon style={{ opacity: 0.6 }} />
              )}
            </IconButton>
          </Tooltip>
        ) : (
          <div style={{ paddingTop: 5 }}>
            <Tooltip
              arrow
              title={
                <span>
                  Examples:
                  {freeFormRegex.map(re => (
                    <p>{re?.examples[0]}</p>
                  ))}
                </span>
              }
            >
              <HelpOutlineIcon style={{ opacity: 0.6, cursor: 'help' }} />
            </Tooltip>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default TemporalRuleElement;
