import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { MediaDoc } from 'types/media';
import { UserUpdatePartialDoc } from 'types/user';

export const useUpdateMediaFull = () => {
  const authState = useAuthSelector();

  return useMutation<any, AxiosError, MediaDoc, unknown>(
    async (mediaFullDoc: MediaDoc) =>
      sdk.media.mutate.updateFull(mediaFullDoc, authState?.auth_token),
  );
};
