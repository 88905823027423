import React, { useState, useContext, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Paper,
  TooltipLight,
  Icon,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ButtonDropdownMenu,
} from 'app/design';
import {
  Delete as DeleteIcon,
  Eject as EjectIcon,
  PresentToAll as PresentToAllIcon,
  FileCopy as FileCopyIcon,
  Phone as PhoneIcon,
  Add as AddIcon,
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
} from 'app/design/icons-material';

import { getAtPath, setAtPath } from 'app/utilities';

import { IvrMenuEventEmitterContext, useSharedFlow } from '../../../../..';

export const MenuItem_Duplicate = props => {
  const { data, mode, onClose } = props;
  const {
    insertBefore, // bool
    insertAfterData,
    infoIdx,
    callflow: rootCallflow,
    currentCallflow,
    componentData,
    componentInfo,
    templateParent,
    // callflow,
    // setCallflow,
    modifyPath,
  } = data;

  // const { callflow, setCallflow, modifyPath } = insertAfterData;

  const [sharedFlow, setSharedFlow] = useSharedFlow();

  const ee = useContext(IvrMenuEventEmitterContext);
  const handleDuplicateTo = () => {
    // alert('duplicate to');
    // if (rootCallflow.type != 'template' && templateParent) {
    //   alert(
    //     'Note that adding to this template outside of a Template Endpoint can result in losing information when the Template is re-applied!'
    //   );
    // }
    const componentInfo =
      currentCallflow.strategy.config.components.slice(infoIdx);
    const componentData = currentCallflow.strategy.data.opts.slice(infoIdx);

    // console.log('ARR:', componentData);

    setSharedFlow(s => ({
      ...s,
      state: 'duplicate-to',
      data: {
        // array to concat to wherever it is being duplicated
        // - TODO: different way of doing this? create a new strategy/callflow each time?
        path: modifyPath,
        duplicateJson: JSON.parse(
          JSON.stringify({
            componentInfo,
            componentData,
          }),
        ),
      },
    }));
  };

  return (
    <MenuItem
      onClick={e => {
        onClose();
        handleDuplicateTo();
      }}
      component={'a'}
    >
      <ListItemIcon>
        <FileCopyIcon />
      </ListItemIcon>
      <ListItemText primary="Duplicate" />
    </MenuItem>
  );
};

export default MenuItem_Duplicate;
