/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useContext,
} from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  removeElements,
  isNode,
  Controls,
  useZoomPanHelper,
  useStore,
  useStoreState,
  useStoreActions,
} from 'react-flow-renderer';
import dagre from 'dagre';

import { useQueries, useQueryClient } from 'react-query';

import { useUpdateCallflowPartial } from 'app/hooks/mutations/callflow';
import { useSelector } from 'react-redux';

import { Close as CloseIcon } from 'app/design/icons-material';

import {
  Box,
  Grid,
  Button,
  ButtonGroup,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
} from 'app/design';

import { ButtonDropdownMenu } from 'app/components/ButtonDropdownMenu';

import { difference, intersection, find, toInteger, cloneDeep } from 'lodash';
// import { KazooSDK } from '@KazooSDK';

import {
  createReducerContext,
  useEffectOnce,
  createStateContext,
  useHoverDirty,
} from 'react-use';
import { useImmer } from 'use-immer';
import { useToggleReducer } from '../../../utilities';
import { DetailsDialogCallflow } from '../../DetailsDialogCallflow';

import { nodeTypes, nodeTypeAllowAfter } from './nodes';
import { InsertEdge } from './edges/Insert';

import ConvertCallflowToFlowElements from './convertCallflowToFlowElements';

// import * as OptionComponents from '../../../Strategies/components';

// import { useSetupHook } from '../../SetupHook';

// import store from '../../../../../../store';
import EventEmitter from 'eventemitter3';

import copy from 'copy-to-clipboard';

import { setAtPath, getAtPath } from 'app/utilities';

import ReactJson from 'react-json-view';

import { useSharedFlow, IvrMenuEventEmitterContext } from '../';
import eventEmitter from '../eventEmitter';
// import { ToastQuick } from '@Util/toast';
// // TODO: this needs to be extended to handle dynamic/custom/PRESET types!
// const nodeTypesToOptionComponents = {
//   ContinueToCallflow: 'ContinueToCallflow',
//   ChooseDirectory: 'ChooseDirectory',
//   ConferenceRoom: 'ConferenceRoom',
//   Menu: 'MenuGreetingAndTargets',
//   PlayAudio: 'PlayAudio',
//   Ring: 'Ring',
//   Transfer: 'Transfer',
//   Schedule: 'TimeOfDayMenu',
//   Voicemail: 'VoicemailBox',
// };

import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import callflowQueryKeys from 'app/hooks/queries/callflow/callflowQueryKeys';

const edgeTypes = {
  insert: InsertEdge,
};

const position = { x: 0, y: 0 };
const edgeType = 'smoothstep';

const nodeHasDimension = node => {
  if (!isNode(node)) {
    return true;
  }
  if (node.__rf?.width && node.__rf?.height) {
    return true;
  }
  return false;
};
const allNodesHaveDimension = nodes => {
  return nodes?.every(nodeHasDimension) ? true : false;
};

const getLayoutedElements = (elements, direction = 'TB') => {
  // other layout options:
  // - https://github.com/kieler/elkjs
  // - https://stackoverflow.com/questions/12152506/algorithm-for-automatic-placement-of-flowchart-shapes
  // - https://ialab.it.monash.edu/webcola/
  // - https://gojs.net/latest/index.html
  // - http://www.daviddurman.com/automatic-graph-layout-with-jointjs-and-dagre.html
  // - https://modeling-languages.com/javascript-drawing-libraries-diagrams/
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const isHorizontal = false; //direction === 'LR';
  dagreGraph.setGraph({
    rankdir: direction,
    ranksep: 50,
    ranksepOLD: 25,
  }); // https://github.com/dagrejs/dagre/wiki#configuring-the-layout
  elements.forEach(el => {
    if (isNode(el)) {
      // width/height if pre-assigned
      dagreGraph.setNode(el.id, {
        width: el.width ?? 1, // || 150,
        height: el.height ?? 1, //50,
      });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });
  dagre.layout(dagreGraph);
  return elements.map(el => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? 'left' : 'top';
      el.sourcePosition = isHorizontal ? 'right' : 'bottom';
      // unfortunately we need this little hack to pass a slightly different position
      // in order to notify react flow about the change
      // console.log(
      //   'reset nodeWithPosition.height:',
      //   el.type,
      //   nodeWithPosition.y,
      //   nodeWithPosition.height
      // );
      el.position = {
        // x: nodeWithPosition.x,
        x:
          nodeWithPosition.x -
          (nodeWithPosition.width ? nodeWithPosition.width / 2 : 0) +
          Math.random() / 1000,
        y:
          nodeWithPosition.y -
          (nodeWithPosition.height ? nodeWithPosition.height / 2 : 0),
      };
      // el.data.position = el.position;
    }
    return el;
  });
};

let nextId = 0;
const getNextId = () => {
  nextId++;
  return `el${nextId.toString()}`;
};

const addNoteNodeBefore = (
  rootData,
  prevNode,
  thisEl,
  modifyPath,
  insertAfterData,
  infoIdx,
) => {
  // cData = componentData
  // - more prone to failure?
  let thisEdge1, thisEdge2, noteNode, noteEdge;
  if (prevNode.type === 'NoteNode') {
    // edge from existing Note
    thisEdge1 = {
      id: getNextId(),
      source: prevNode.id,
      target: thisEl.id,
      // type: edgeType,
      type: 'smoothstep',
      animated: true,
      arrowHeadType: 'arrow',
    };
    rootData.elements.push(thisEl);
    rootData.elements.push(thisEdge1);
  } else {
    // AddNote node/edge
    // console.log('TYPE INSERT');
    noteNode = {
      id: getNextId(),
      type: 'NoteNode',
      data: {
        exists: false, // this will be semi-obvious to the NoteNode component because the componentData won't exist!
        insertAfterData,
        // callflow,
        // setCallflow,
        // modifyPath: `${modifyPath}.strategy.data.opts[${infoIdx}]`,
      },
      key: `${modifyPath}.strategy.config.components[${infoIdx}]__notenode`, // for focus after modify
      // height: 100,
      position,
    };
    thisEdge1 = {
      id: getNextId(),
      source: prevNode.id,
      target: noteNode.id,
      // type: edgeType,
      type: 'smoothstep',
      animated: true,
      // arrowHeadType: 'arrow',
    };
    thisEdge2 = {
      id: getNextId(),
      source: noteNode.id,
      target: thisEl.id,
      // type: edgeType,
      type: 'smoothstep',
      animated: true,
      arrowHeadType: 'arrow',
    };
    rootData.elements.push(noteNode);
    rootData.elements.push(thisEl);
    rootData.elements.push(thisEdge1);
    rootData.elements.push(thisEdge2);
  }
  return prevNode;
};

// const eventEmitter = new EventEmitter(); // should be inside IvrBuilder?

const convertCallflowToFlowElements =
  ConvertCallflowToFlowElements(eventEmitter);

const Flow = props => {
  const {
    editingCallflow,
    setEditingCallflow,
    setEditingCallflowWrap,
    showControls,
  } = props;

  // const { buildAndSaveCallflow, sync, findOwnerByOwnerId } = useSetupHook();
  // console.log('quickflow:', editingCallflow);
  const [sharedFlow, setSharedFlow] = useSharedFlow();

  const { cachePosition, cacheHistory, showJson } = sharedFlow;

  // const [flowElements, setFlowElements] = useState([]); // layoutedElements
  // const [rootElements, setRootElements] = useState([]);
  // const [shouldLayout, setShouldLayout] = useState(null);
  // const [shouldHide, setShouldHide] = useState(true);
  // const [loadedOnce, setLoadedOnce] = useState(false);

  const actions = useStoreActions(actions1 => actions1);
  const { setElements, updateNodeDimensions } = actions;

  // when callflow changes
  // -> rebuild flowElements and setFlowElements
  // -> get position of elements after render (useLayoutEffect) and re-render using new params

  // TODO: oftentimes the node.__rf.height/width values dont exist after the layout is done
  // - when are they created/assigned??

  const { hideWhileRebuilding, flowElements } = useBuildFlow({
    editingCallflow,
    setEditingCallflowWrap,
  });

  // const nn = flowElements.find((el) => el.id == 'el2');
  // // console.log('nn:', nn?.type, nn?.height, flowElements);
  // // console.log('flowElements:', flowElements);
  // console.log(
  //   'allNodesHaveDimension(nodes):',
  //   allNodesHaveDimension(nodes),
  //   nodes
  // );

  // console.log('shouldLayout', shouldHide);
  // if (window.__stop && shouldHide) {
  //   // debugger;
  // }
  // console.log('shouldHide:', shouldHide);

  const activeState = useActiveState(sharedFlow);
  // console.log('activeState:', activeState);

  return (
    <>
      <Box
        sx={{
          height: '100%',
          opacity: hideWhileRebuilding ? 0 : 1,
          // transition: willHide
          //   ? 'opacity .25s ease-in-out'
          //   : 'opacity .25s ease-in-out',
          transition: 'opacity .25s ease-in-out, background-color .25s',
          backgroundColor: activeState?.background,
          '& .react-flow': {
            cursor: 'grab',
          },
          '& .react-flow__node': {
            cursor: 'auto',
          },
        }}
      >
        <ReactFlow
          maxZoom={1}
          elements={flowElements}
          // onConnect={onConnect}
          // onElementsRemove={onElementsRemove}
          connectionLineType="smoothstep" // smoothstep
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          // panOnScroll
          // panOnScrollMode="free"
          preventScrolling={false} // allows default browser scrolling
          zoomOnScroll={false}
          // zoomOnDoubleClick={false}
          // paneMoveable={false} // new version is panOnDrag! (note this is kinda misspelled as "pane" instead of "pan" :) )
          nodesDraggable={false}
          // elementsSelectable={true}
        />
        {showControls ? <Controls showInteractive={false} /> : null}
        <FitViewHelper elements={flowElements} />
      </Box>
    </>
  );
};

const useActiveState = sharedFlow => {
  let activeState;
  switch (sharedFlow.state) {
    case 'duplicate-to':
      activeState = {
        name: 'Duplicating',
        color: 'info',
        variant: 'contained',
        background: 'rgba(100,100,100,0.1)',
      };
      break;
    case 'move-to':
      activeState = {
        name: 'Moving',
        color: 'info',
        variant: 'contained',
        background: 'rgba(100,100,100,0.1)',
      };
      break;
    case 'paste-to':
      activeState = {
        name: 'Pasting',
        color: 'info',
        variant: 'contained',
        background: 'rgba(100,100,100,0.1)',
      };
      break;
    default:
      if (sharedFlow.state) {
        console.error('invalid sharedFlow.state');
      }
      break;
  }
  return activeState;
};

const useBuildFlow = ({ editingCallflow, setEditingCallflowWrap }) => {
  const ee = useContext(IvrMenuEventEmitterContext);

  const flowStore = useStore();
  const { nodes, edges } = flowStore.getState();

  const [flowElements, setFlowElements] = useState([]); // layoutedElements
  const [rootElements, setRootElements] = useState([]);
  const [shouldLayout, setShouldLayout] = useState(null);
  const [shouldHide, setShouldHide] = useState(true);
  const [loadedOnce, setLoadedOnce] = useState(false);

  const [referencedCallflowIds, setReferencedCallflowIds] = useState([]);
  const [relatedCallflows, setRelatedCallflows] = useState({});
  const queryClient = useQueryClient();

  const authState = useAuthSelector();

  const queries = referencedCallflowIds.map(id => {
    return {
      queryKey: callflowQueryKeys.byId(id),
      queryFn: () =>
        sdk.callflow.query.callflowById(
          { id },
          { authToken: authState.auth_token },
        ), // ...options here ie "enabled"};
    };
  });
  const referencedCallflowResults = useQueries(queries);

  // useEffect(() => {}, []);
  // build relatedCallflow
  useEffect(() => {
    const relatedCallflows = {};
    referencedCallflowResults.map((result, i) => {
      relatedCallflows[referencedCallflowIds[i]] = {
        result,
      };
    });
    setRelatedCallflows(relatedCallflows);
    // console.log('resetting relatedCallflows');
  }, [referencedCallflowResults.map(r => r.status).join(' ')]);

  useEffect(() => {
    // nextId = 0;
    const rootData = convertCallflowToFlowElements({
      expandSimple: true,
      skipEditing: true,
      callflow: editingCallflow,
      setCallflow: setEditingCallflowWrap,
      modifyPath: '', // root
      parentSourceNode: null, //initialArr[1],
      rootData: {
        callflowIds: [],
        elements: [],
        referencedCallflowIds: [],
        missingCallflowIds: [],
      },
      edgeData: null,
      relatedCallflows, // for schedules, templates, etc. (need to load remotely to get the follow-on results) (schedules MUST work this way!! ie CANNOT be like templates!)
      // optionalNodesEdges...
    });

    // TODO: processing referencedCallflowIds (load before rendering)
    const referencedCallflowIds = rootData.referencedCallflowIds;
    // console.log('referencedCallflowIds:', referencedCallflowIds);
    setReferencedCallflowIds(referencedCallflowIds);

    // not exists yet, loading, error, success

    // not exists yet
    if (
      difference(referencedCallflowIds, Object.keys(relatedCallflows)).length
    ) {
      // console.log('Unseen ids');
      setShouldHide(true);
      return;
    }

    // loading
    for (const cfId of Object.keys(relatedCallflows)) {
      const cf = relatedCallflows[cfId];
      if (cf.result.status === 'loading') {
        // console.log('Loading still');
        setShouldHide(true);
        return;
      }
    }

    // error, success (both do NOT block display!)

    // see if any referencedCallflowIds were missing/invalid
    // - dont display results yet, wait for loading everything
    if (rootData.missingCallflowIds.length) {
      // console.log(
      //   'Missing IDS!',
      //   rootData.missingCallflowIds,
      //   relatedCallflows,
      // );
    }

    // console.log('INITIAL Layout');
    const le = getLayoutedElements(rootData.elements);
    setFlowElements([...le]);
    setShouldHide(true);
    setTimeout(() => {
      // leaving the setTimeout out causes a broken re-render
      // - the view is sometimes still lost entirely! (shouldHide gets stuck on, cuz nodes dont render w/ height/width)
      setShouldLayout(true);
    }, 1);
  }, [editingCallflow, relatedCallflows]);

  useLayoutEffect(() => {
    if (shouldLayout && allNodesHaveDimension(nodes)) {
      // console.log('SECOND Layout');
      // recreate elements w/ x/y provided
      let newFlowElements = [...nodes, ...edges].map(node => {
        if (!isNode(node)) {
          delete node.position;
          delete node.__rf;
          return { ...node }; // edge
        }
        const r = {
          ...node,
          width: node.__rf.width,
          height: node.__rf.height,
          position: null,
          __rf: null,
        };
        delete r.position;
        delete r.__rf;
        return r;
      });

      const le = getLayoutedElements(newFlowElements);
      setFlowElements([...le]);
      setShouldHide(false);
      setShouldLayout(false);
      setLoadedOnce(true);
      if (!loadedOnce) {
        setTimeout(() => {
          ee.emit('fit-view');
        }, 100);
      }
    } else {
      // console.log(
      //   'No relayout',
      //   shouldLayout,
      //   allNodesHaveDimension(nodes),
      //   nodes
      // );
      // TODO: if shouldLayout, then force a reflow that rebuilds the dimensions
      if (shouldLayout) {
        // console.info(
        //   'should be doing a layout, but the damn nodes are missing height/width values'
        // );
        setTimeout(() => {
          setFlowElements([...flowElements]);
        }, 1);
        // trying to force a "calc all the node dimensions" but not sure how to do it (fit-view doesnt do it)
        // setTimeout(() => {
        //   ee.emit('fit-view');
        // }, 100);
      }
    }
  }, [shouldLayout, nodes]);

  const hideWhileRebuilding = shouldHide || !allNodesHaveDimension(nodes);

  return { hideWhileRebuilding, flowElements };
};

const FitViewHelper = props => {
  const { elements } = props;
  const { zoomTo, setCenter, transform, fitView, initialized } =
    useZoomPanHelper();

  const flowStore = useStore();

  const focusNode = useCallback(
    filterData => {
      // console.log('focusNode data:', filterData);
      const { nodes } = flowStore.getState();
      // console.log('focusNodes:', nodes);
      if (nodes.length) {
        const node =
          filterData && Object.keys(filterData)
            ? find(nodes, filterData) || nodes[0]
            : nodes[0];
        const x = node.__rf.position.x + node.__rf.width / 2;
        const y = node.__rf.position.y + node.__rf.height / 2;
        // console.log('focus on:', x, y, node);
        const zoom = 1.0;
        setCenter(x, y, zoom);
      }
    },
    [setCenter],
  );

  // const shakeView = useCallback(() => {
  // console.log('shake view');
  //   const { transform: flowTransform } = flowStore.getState();
  //   transform({
  //     x: flowTransform.x,
  //     y: flowTransform.y + 5,
  //     zoom: flowTransform.zoom,
  //   });
  // }, [transform]);

  const ee = useContext(IvrMenuEventEmitterContext);

  useEffect(() => {
    ee.on('focus-node', focusNode);
    ee.on('fit-view', fitView);
    // ee.on('shake-view', shakeView);
    return () => {
      ee.removeListener('focus-node', focusNode);
      ee.removeListener('fit-view', fitView);
      // ee.removeListener('shake-view', shakeView);
    };
  }, [focusNode]);

  const firstRunRef = useRef(false);
  useEffect(() => {
    if (firstRunRef.current) {
      return;
    }
    // console.log('Running focusOnFirst');
    if (initialized) {
      firstRunRef.current = true;
      // window.setTimeout(focusNode, 1);
      // fitView();
    }
  }, [initialized, focusNode, fitView]);

  return null;
};

export default Flow;
