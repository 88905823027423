import MediaDialog from 'app/components/MediaDialog/MediaDialog';
import { useUpdateDevicePartial } from 'app/hooks/mutations/device';
import { useDeviceQuery } from 'app/hooks/queries/device';
import React from 'react';
import { MediaType } from 'types/media';

interface EditHoldMusicDialogProps {
  deviceId: string;
  onComplete: () => void;
  onCancel: () => void;
  mediaId?: string | null;
}

const EditHoldMusicDialogDevice = ({
  deviceId,
  mediaId,
  onComplete,
  onCancel,
}: EditHoldMusicDialogProps) => {
  const updateDevice = useUpdateDevicePartial();
  const { data: device } = useDeviceQuery(deviceId);

  const handleComplete = async (mediaId: string | null) => {
    return updateDevice.mutateAsync(
      {
        id: deviceId,
        music_on_hold: {
          media_id: mediaId,
        },
      },
      {
        onSuccess: () => {
          onComplete();
        },
      },
    );

    // resolve promise with toast notifications
    // await toast.promise(updateDevicePromise, {
    //   pending: 'Updating device details...',
    //   error: 'Failed to update device details.',
    //   success: 'Device details updated!',
    // });
  };

  return (
    <MediaDialog
      type={'Hold Music'}
      mediaNameToSaveAs={`${device?.doc.name} Hold Music`}
      mediaId={mediaId}
      onComplete={handleComplete}
      onCancel={onCancel}
      allowedTypes={[MediaType.BuiltIn, MediaType.Upload]}
    />
  );
};

export default EditHoldMusicDialogDevice;
