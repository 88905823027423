import * as React from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Paper,
  Link,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from 'app/design';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from 'app/design/icons-material';
import {
  useToggleReducer,
  DialogBuilder,
  parseAndSetKazooMutationErrors,
} from 'app/utilities';
import Joi from 'joi';
import { pickBy } from 'lodash';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useImmer } from 'use-immer';

import copy from 'copy-to-clipboard';

import { useQueryClient } from 'react-query';
import accountQueryKeys from 'app/hooks/queries/account/accountQueryKey';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useAccountStripeCustomerMutate } from 'app/hooks/mutations/account';
import { useCustomBackdrop } from 'app/components/CustomBackdrop';

// schema for form validation. Passed to useForm to only trigger submit when
//  - the below conditions are met. Any known serverside constraints (min/max,
//  - character limits, numbers only, etc.) should be added. Remove 128 max below
//  - to demonstrate serverside invalidation and handling
// const schema = Joi.object({
//   owner_id: Joi.string(),
//   name: Joi.string().max(128).required(),
//   mailbox: Joi.string().max(5).required(),
// });

interface CreditCardAddForm {
  name?: string;
}

// interface declaring which props are required/allowed
interface CreditCardAddDialogProps {
  formDefaults?: any;
  onCancel: () => void;
  startDirty?: boolean;
  onComplete: () => void;
}

const CreditCardAddDialog = ({
  formDefaults,
  onCancel,
  onComplete,
  startDirty = true,
}: CreditCardAddDialogProps) => {
  const handleCancel = () => {
    onCancel();
  };

  return (
    <Dialog open={true} fullWidth maxWidth={'sm'}>
      <CreditCardAddDialogForm
        formDefaults={formDefaults}
        startDirty={startDirty}
        onCancel={onCancel}
        onComplete={onComplete}
      />
    </Dialog>
  );
};

interface CreditCardAddDialogFormProps {
  formDefaults: CreditCardAddForm;
  onCancel: () => void;
  onComplete: () => void;
  startDirty?: boolean;
}

const CreditCardAddDialogForm = ({
  formDefaults,
  onCancel,
  onComplete,
  // onSubmit,
  // errorMessage,
  startDirty = false,
}: CreditCardAddDialogFormProps) => {
  const [saveError, setSaveError] = useImmer(null);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors: formErrors, isDirty, isSubmitting },
    setError,
    watch,
  } = useForm<CreditCardAddForm>({
    defaultValues: formDefaults,
    // resolver: joiResolver(schema), // pass in custom validation
  });

  const router = useHistory();
  const addCreditCard = useAccountStripeCustomerMutate();
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();

  const onSubmit = async (ccForm: CreditCardAddForm) => {
    // update form defaults if mutation fails

    setSaveError(null);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.log('stripe not loaded');
      return;
    }

    console.log('elements:', elements);

    // alert('after payment');

    // get token for card (to apply to customer)
    const { token, error } = await stripe.createToken(
      // @ts-ignore
      elements.getElement(CardElement),
    );
    console.log('token:', token);
    if (error) {
      console.error('card error:', error);
      return;
    }

    // const result = await sdk.stripe.mutate.newCustomer(
    //   {
    //     data: { token },
    //   },
    //   { authToken: store.getState().auth.auth_token },
    // );

    // console.log('Stripe Response:', result);
    // onCancel();

    const addCreditCardPromise = addCreditCard.mutateAsync({
      data: {
        action: 'addCard',
        data: {
          name: ccForm.name,
          source: token?.id,
          // @ts-ignore
          setDefault: formDefaults?.setDefault ? true : false,
        },
      },
    });

    toast.promise(addCreditCardPromise, {
      pending: 'Adding credit card...',
      success: 'Credit card added!',
      error: 'Error adding credit card.',
    });

    let resp;
    try {
      resp = await addCreditCardPromise;
    } catch (err) {
      // TODO: parse and set errors

      // @ts-ignore
      setSaveError({
        // @ts-ignore
        message:
          'Unable to add Credit Card. Please correct errors and try again.',
      });
      throw err;
    }

    await queryClient.refetchQueries(accountQueryKeys.stripe_customer);

    onComplete();
  };

  const {
    toggleOpen: toggleBackdrop,
    Dialog: CustomBackdrop,
    DialogProps: CustomBackdropProps,
  } = useCustomBackdrop({ initialOpen: false });

  // if (isSubmitting) {
  //   return <DialogInProgress title={'Adding Credit Card...'} />;
  // }

  return (
    <>
      <CustomBackdrop {...CustomBackdropProps} open={isSubmitting} />
      <DialogTitle>Add Credit Card</DialogTitle>
      <Divider />
      <DialogContent>
        <div>
          <TextField
            label={'Friendly Name for Card (optional)'}
            {...register('name')}
            error={!!formErrors.name}
            helperText={formErrors.name?.message?.replace('"name"', 'Name')}
            size="small"
            fullWidth
          />
          <br />
          <br />
          <Paper variant="outlined" sx={{ padding: 1 }}>
            <CardElement />
          </Paper>
          <br />
          <Paper variant="outlined" sx={{ padding: 1 }}>
            <Typography variant="body2">
              Success: <br />
              <Link onClick={e => copy('4242424242424242')}>
                Visa: 4242 4242 4242 4242
              </Link>
              <br />
              <Link onClick={e => copy('5555555555554444')}>
                Mastercard: 5555 5555 5555 4444
              </Link>
              <br />
              <Link onClick={e => copy('378282246310005')}>
                Amex: 3782 822463 10005
              </Link>
            </Typography>
            <Typography variant="body2">
              Fail:{' '}
              <Link onClick={e => copy('4040404040404040')}>
                4040 4040 4040 4040
              </Link>
            </Typography>
          </Paper>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <div>
          <Button variant={'outlined'} color={'error'} onClick={onCancel}>
            Cancel
          </Button>
        </div>
        {/* @ts-ignore */}
        {saveError ? (
          <div>
            <Typography color={'warning.dark'} variant="body2">
              {/* @ts-ignore */}
              {saveError.message}
            </Typography>
          </div>
        ) : null}
        <div>
          <Button
            variant={'outlined'}
            color={'success'}
            disabled={startDirty ? !startDirty : !isDirty}
            onClick={handleSubmit(onSubmit)}
            sx={{ whiteSpace: 'nowrap' }}
          >
            Add Credit Card
          </Button>
        </div>
      </DialogActions>
    </>
  );
};

export const useCreditCardAddDialog = DialogBuilder(CreditCardAddDialog);

export default CreditCardAddDialog;
