import {
  ChipSwitch,
  Grid,
  InfoTooltip,
  StatusTooltip,
  Switch,
  Typography,
} from 'app/design';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsSwitchTemplate } from 'app/components/GenericSettingsSwitchTemplate';
import { useUpdateVmboxPartial } from 'app/hooks/mutations/vmbox';
import * as React from 'react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Vmbox, VmboxDoc } from 'types/vmbox';

interface AdditionalSettingProps {
  vmbox: Vmbox;
}

interface FormValues {
  not_configurable: boolean;
}

const DisableUserChanges = ({ vmbox }: AdditionalSettingProps) => {
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'disableUserChanges',
    options: [
      {
        label: 'Disable User Changes',
        value: 'disableUserChanges',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const [showTooltip, setShowTooltip] = useState(false);
  const updateVmbox = useUpdateVmboxPartial();
  const {
    formState: { isDirty },
    watch,
    control,
    handleSubmit,
    reset,
  } = useForm<FormValues>({
    defaultValues: { not_configurable: vmbox.doc.not_configurable ?? false },
  });
  const watchEnabled = watch('not_configurable');
  const statusRef = useRef(updateVmbox.status);
  statusRef.current = updateVmbox.status;

  // reset form if vmbox doc changes
  useEffect(() => {
    reset({ not_configurable: vmbox.doc.not_configurable ?? false });
  }, [vmbox]);

  // display switch tooltip based on status
  useEffect(() => {
    if (updateVmbox.status === 'loading') setShowTooltip(true);
    else if (showTooltip)
      setTimeout(
        // @ts-ignore
        () => statusRef.current !== 'loading' && setShowTooltip(false),
        1000,
      );
  }, [updateVmbox.status]);

  // trigger submit on switch trigger
  useEffect(() => {
    if (isDirty) {
      // only submit if form is dirty (we reset form on success, fail)
      handleSubmit(onSubmit)();
    }
  }, [watchEnabled]);

  const onSubmit = async (formValues: FormValues) => {
    await updateVmbox.mutateAsync(
      {
        id: vmbox.id,
        ...formValues,
      },
      {
        onSuccess: resp => {
          const { data }: { data: VmboxDoc } = resp;
          console.log('data', data);
          // reset form data (needed since this form is initialized once, not
          // - every time like when a dialog opens). We need to reset the default
          // - values which are the source of truth for error reset/isDirty value
          reset({ not_configurable: data.not_configurable ?? false });
        },
        onError: () => {
          reset(); // reset form to last valid state
          toast.error('Failed to update vmbox settings.');
        },
      },
    );
  };

  return (
    // @ts-ignore
    <div ref={ref}>
      <HighlightPulse {...highlightPulseProps} />
      <GenericSettingsSwitchTemplate
        title={'Disable User Changes'}
        caption={'Disable owner from changing voicemail greeting and settings'}
      >
        <Controller
          control={control}
          name={'not_configurable'}
          render={({ field: { onChange, value, ...props } }) => (
            <StatusTooltip
              status={updateVmbox.status}
              placement={'right'}
              open={showTooltip}
            >
              <Switch
                size="small"
                checked={value}
                onChange={e => onChange(e.target.checked)}
              />
            </StatusTooltip>
          )}
        />
      </GenericSettingsSwitchTemplate>
    </div>
  );
};

export default DisableUserChanges;
