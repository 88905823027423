import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from 'app/design';
import { useListCdrInteractionsQuery } from 'app/hooks/queries/callDetailRecord';
import { ExportButton } from 'app/reports/components/ExportButton';
import TimeRangeSelect from 'app/reports/components/ListCdrInteractions/components/TimeRangeSelect/TimeRangeSelect';
import { ReportListGeneric } from 'app/reports/components/ReportListGeneric';
import { getTimeRange } from 'app/reports/components/utils';
import { useQueryPaginationReducerWithQueryParams } from 'app/utilities/useQueryPaginationReducerWithQueryParams';
import { startCase } from 'lodash';
import { useEffect } from 'react';
import * as React from 'react';
import { CdrInteraction } from 'types/callDetailRecord';
import { TimeRange } from 'types/generic';
import { useImmer } from 'use-immer';
import { useCdrInteractionColumns } from './useCdrInteractionColumns';
import { Link as RouterLink } from 'react-router-dom';

interface ListCdrInteractionsProps {
  userId?: string;
  selectTimeRange?: boolean;
  callFinderLink?: boolean;
  exportPage?: boolean;
  pageSize?: number;
  refresh?: any;
}

const ListCdrInteractions = ({
  userId,
  selectTimeRange = false,
  callFinderLink = false,
  exportPage = false,
  pageSize = 10,
  refresh,
}: ListCdrInteractionsProps) => {
  const [timeRange, setTimeRange] = useImmer(getTimeRange(TimeRange.Today));
  const [timeRangeLabel, setTimeRangeLabel] = useImmer('Today');
  // query pagination state
  const [
    { queryPageSize, queryPageIndex, queryOrderBy, querySearchInput },
    dispatch,
    // @ts-ignore
  ] = useQueryPaginationReducerWithQueryParams(0, pageSize, [
    ['createdAt', 'desc'],
  ]);

  // cdrInteraction list
  const { refetch, isLoading, isFetching, data } = useListCdrInteractionsQuery(
    queryPageIndex * queryPageSize, //skip
    queryPageSize, // take
    queryOrderBy, // order by
    {
      searchInput: querySearchInput,
      raw: [
        {
          variables: {
            docFilters: [
              // add userId if passed
              userId ? ['owner_ids', 'contains', userId] : [],
              // ['id', '=', '63808989494-cfe3ae0c'],
              ['start_time', '>=', timeRange.start],
              ['end_time', '<=', timeRange.end],
            ],
          },
        },
      ],
    }, // filters
  );
  const { cdrInteractions = [], totalCount = 0 } = data ?? {};

  const columns = useCdrInteractionColumns(refetch);

  useEffect(() => {
    if (refresh) refetch();
  }, [refresh]);

  const handleSetTimeRange = (timeRange: TimeRange) => {
    setTimeRange(getTimeRange(timeRange));
    setTimeRangeLabel(startCase(timeRange.replace('_', ' ')));
  };

  return (
    <Card
      // @ts-ignore
      variant="dashboard"
    >
      <CardHeader
        title={
          <Grid container alignItems={'center'} spacing={1}>
            <Grid item>{`Calls ${timeRangeLabel} (${totalCount})`}</Grid>
            {isFetching ? (
              <Grid item>
                <CircularProgress size={20} />
              </Grid>
            ) : null}
            <Grid item sx={{ flex: 1 }} />
            {selectTimeRange ? (
              <Grid item>
                <TimeRangeSelect setTimeRange={handleSetTimeRange} />
              </Grid>
            ) : null}
            {exportPage ? (
              <Grid item>
                <ExportButton
                  timeframe={timeRangeLabel}
                  interactions={cdrInteractions}
                >
                  Export List
                </ExportButton>
              </Grid>
            ) : null}
            {callFinderLink ? (
              <Grid item>
                <Link
                  component={RouterLink}
                  underline={'hover'}
                  to={'/admin/calllogs/callfinder'}
                  variant={'body1'}
                >
                  View Call Finder
                </Link>
              </Grid>
            ) : null}
          </Grid>
        }
      />
      <CardContent>
        {cdrInteractions?.length || isLoading ? (
          <ReportListGeneric
            columns={columns}
            data={cdrInteractions}
            totalCount={totalCount}
            initialPageSize={queryPageSize}
            initialPageIndex={queryPageIndex}
            queryPaginationDispatch={dispatch}
            queryIsFetching={isFetching}
            queryIsLoading={isLoading}
            getRowId={(row: CdrInteraction) => row.id}
          />
        ) : (
          <Typography sx={{ color: 'text.secondary' }}>
            No Calls for this time range
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default ListCdrInteractions;
