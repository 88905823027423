import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid,
  InfoTooltip,
  Link,
  Typography,
} from 'app/design';
import {
  ContactPhone as ContactPhoneIcon,
  MedicalServices as MedicalServicesIcon,
  Place as PlaceIcon,
} from 'app/design/icons-material';
import { useFindInPage } from 'app/components/FindInPage';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { useAccountQuery } from 'app/hooks/queries/account';
import { usePhoneNumberQuery } from 'app/hooks/queries/phoneNumber';
import { useUserQuery } from 'app/hooks/queries/user';
import { CallerIdDialogDevice } from 'app/components/CallerIdDialogDevice';
import { useToggleReducer } from 'app/utilities';
import { useMarkdownTranslate } from 'app/utilities/translate';
import * as React from 'react';
import { Device } from 'types/device';

interface DeviceCallerIdCardProps {
  device: Device;
  onComplete: () => void;
}

const DeviceCallerIdCard = ({
  device,
  onComplete,
}: DeviceCallerIdCardProps) => {
  const [showEditDialog, toggleEditShowDialog] = useToggleReducer(false);
  const { data: account } = useAccountQuery();
  const { data: user } = useUserQuery(device.doc.owner_id);
  // CID Finder
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'caller id',
    options: [
      {
        label: 'Caller ID',
        value: 'caller_id',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  // External CID Finder
  const {
    ref: refExt,
    HighlightPulse: HighlightPulseExt,
    highlightPulseProps: highlightPulsePropsExt,
  } = useFindInPage({
    name: 'ext',
    options: [
      {
        label: 'External Caller ID',
        value: 'external_caller_id',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  // e911 CID Finder
  const {
    ref: refE911,
    HighlightPulse: HighlightPulseE911,
    highlightPulseProps: highlightPulsePropsE911,
  } = useFindInPage({
    name: 'e911',
    options: [
      {
        label: 'Emergency Caller ID',
        value: 'e911',
      },
      {
        label: 'E911',
        value: 'e911',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  // device external CID
  let externalCid = device.doc.caller_id?.external?.number;
  let externalInherited = '';

  // device doesn't have external CID?
  if (!externalCid) {
    //user has external CID?
    if (user?.doc.caller_id?.external?.number) {
      // inherit user external CID
      externalCid = user?.doc.caller_id?.external?.number;
      externalInherited = 'User';
    } else {
      // inherit account external CID
      externalCid = account?.doc.caller_id?.external?.number;
      externalInherited = 'Account';
    }
  }

  // device emergency CID
  let emergencyCid = device.doc.caller_id?.emergency?.number;
  let emergencyInherited = '';

  // device doesn't have emergency CID?
  if (!emergencyCid) {
    //user has emergency CID?
    if (user?.doc.caller_id?.emergency?.number) {
      // inherit user emergency CID
      emergencyCid = user?.doc.caller_id?.emergency?.number;
      emergencyInherited = 'User';
    } else {
      // inherit account emergency CID
      emergencyCid = account?.doc.caller_id?.emergency?.number;
      emergencyInherited = 'Account';
    }
  }

  const {
    data: externalNumberData,
    isLoading: externalNumberIsLoading,
    refetch: refetchExternal,
  } = usePhoneNumberQuery({ id: externalCid });

  const {
    data: emergencyNumberData,
    isLoading: emergencyNumberIsLoading,
    refetch: refetchEmergency,
  } = usePhoneNumberQuery({ id: emergencyCid });

  const externalName = externalNumberData?.doc.cnam?.display_name;
  const externalNumber = externalNumberData?.doc.id;

  const emergencyName = emergencyNumberData?.doc.cnam?.display_name;
  const emergencyAddress = emergencyNumberData?.doc.e911?.street_address;

  const handleComplete = () => {
    onComplete();
    refetchExternal();
    refetchEmergency();
    toggleEditShowDialog();
  };

  const { t } = useMarkdownTranslate();

  return (
    <>
      {showEditDialog ? (
        <CallerIdDialogDevice
          deviceId={device.id}
          onCancel={toggleEditShowDialog}
          onComplete={handleComplete}
        />
      ) : null}
      {/* @ts-ignore */}
      <Card ref={ref} variant="dashboard">
        <HighlightPulse {...highlightPulseProps} />
        <CardHeader
          disableTypography
          title={
            <Grid container>
              <Grid item sx={{ flex: 1 }}>
                <Typography variant="h2">Outgoing Caller ID</Typography>
              </Grid>
              {emergencyNumberIsLoading || externalNumberIsLoading ? (
                <Grid item>
                  <CircularProgress size={15} />
                </Grid>
              ) : null}
              <Grid item>
                <Link
                  onClick={toggleEditShowDialog}
                  underline="hover"
                  variant="body2"
                >
                  Edit Caller ID
                </Link>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {/* @ts-ignore */}
          <Box ref={refExt} sx={{ position: 'relative' }}>
            <HighlightPulseExt {...highlightPulsePropsExt} />

            <Grid container columnSpacing={2} wrap="nowrap">
              <Grid item>
                <Typography
                  sx={{ color: theme => theme.palette.primary.mainAlt }} // doesnt work, wrong color
                >
                  <ContactPhoneIcon
                  // @ts-ignore
                  // sx={{ color: theme => theme.palette.primary.main }} // doesnt work, wrong color
                  />
                </Typography>
              </Grid>
              <Grid item sx={{ flex: 1 }}>
                <Typography variant="h3" gutterBottom>
                  <strong>Public:</strong>{' '}
                  <InfoTooltip
                    title={t('caller_id.external.tooltip')}
                    size={'small'}
                  />
                </Typography>
                {externalInherited ? (
                  <>
                    <Typography
                      variant="caption"
                      sx={{
                        color: theme => theme.palette.primary.main,
                        fontStyle: 'italic',
                      }}
                    >
                      Inherits from {externalInherited}
                    </Typography>
                  </>
                ) : // <Chip
                //   sx={{ float: 'right' }}
                //   color={'primary'}
                //   variant={'outlined'}
                //   size={'small'}
                //   label={'Account'}
                // />
                null}
                <Typography
                  variant="bodyBig"
                  sx={
                    externalName
                      ? {}
                      : { color: 'text.secondary', fontStyle: 'italic' }
                  }
                >
                  {externalNumberIsLoading ? (
                    <>&nbsp;</>
                  ) : (
                    externalName ?? 'name not set'
                  )}
                </Typography>
                <Typography variant="bodyBig">
                  {externalNumberIsLoading ? (
                    <>&nbsp;</>
                  ) : externalNumber ? (
                    // @ts-ignore
                    <PhoneNumberDisplay ptn={externalNumber} />
                  ) : (
                    <Typography
                      variant="number1"
                      sx={{ color: 'text.secondary', fontStyle: 'italic' }}
                    >
                      Number not set
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
            {/*
              <Typography
                variant="number1"
                sx={
                  externalName
                    ? {}
                    : { color: 'text.secondary', fontStyle: 'italic' }
                }
              >
                {externalNumberIsLoading ? (
                  <>&nbsp;</>
                ) : (
                  externalName ?? 'name not set'
                )}
              </Typography>
              <Typography variant="number1">
                {externalNumberIsLoading ? (
                  <>&nbsp;</>
                ) : externalNumber ? (
                  // @ts-ignore
                  <PhoneNumberDisplay ptn={externalNumber} />
                ) : (
                  <Typography
                    variant="number1"
                    sx={{ color: 'text.secondary', fontStyle: 'italic' }}
                  >
                    number not set
                  </Typography>
                )}
              </Typography> */}
          </Box>
          <br />
          {/* @ts-ignore */}
          <Box ref={refE911} sx={{ position: 'relative' }}>
            <HighlightPulseE911 {...highlightPulsePropsE911} />
            <Grid container columnSpacing={2} wrap="nowrap">
              <Grid item>
                <Typography
                  sx={{ color: theme => theme.palette.primary.mainAlt }} // doesnt work, wrong color
                >
                  <MedicalServicesIcon
                  // @ts-ignore
                  // sx={{ color: theme => theme.palette.primary.main }} // doesnt work, wrong color
                  />
                </Typography>
              </Grid>
              <Grid item sx={{ flex: 1 }}>
                <Typography variant="h3" gutterBottom>
                  <strong>Emergency (E911):</strong>{' '}
                  <InfoTooltip
                    title={t('caller_id.emergency.tooltip')}
                    size={'small'}
                  />
                </Typography>

                {emergencyInherited ? (
                  <>
                    <Typography
                      variant="caption"
                      sx={{
                        color: theme => theme.palette.primary.main,
                        fontStyle: 'italic',
                      }}
                    >
                      Inherits from {emergencyInherited}
                    </Typography>
                  </>
                ) : // <Chip
                //   sx={{ float: 'right' }}
                //   color={'primary'}
                //   variant={'outlined'}
                //   size={'small'}
                //   label={'Account'}
                // />
                null}
                <Typography
                  variant="bodyBig"
                  sx={
                    emergencyName
                      ? {}
                      : { color: 'text.secondary', fontStyle: 'italic' }
                  }
                >
                  {emergencyNumberIsLoading ? (
                    <>&nbsp;</>
                  ) : (
                    emergencyName ?? 'name not set'
                  )}
                </Typography>
                <Typography variant="bodyBig">
                  {externalNumberIsLoading ? (
                    <>&nbsp;</>
                  ) : emergencyCid ? (
                    // @ts-ignore
                    <PhoneNumberDisplay ptn={emergencyCid} />
                  ) : (
                    <Typography
                      variant="number1"
                      sx={{ color: 'text.secondary', fontStyle: 'italic' }}
                    >
                      Number not set
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Grid container columnSpacing={2} wrap="nowrap">
              <Grid item>
                <Typography
                  sx={{ color: theme => theme.palette.primary.mainAlt }} // doesnt work, wrong color
                >
                  <PlaceIcon
                  // @ts-ignore
                  // sx={{ color: theme => theme.palette.primary.main }} // doesnt work, wrong color
                  />
                </Typography>
              </Grid>
              <Grid item sx={{ flex: 1 }}>
                <Typography
                  variant="bodyBig"
                  sx={
                    emergencyAddress
                      ? {}
                      : { color: 'text.secondary', fontStyle: 'italic' }
                  }
                >
                  {emergencyNumberIsLoading ? (
                    <>&nbsp;</>
                  ) : emergencyAddress ? (
                    <>
                      <div>{emergencyNumberData?.doc.e911?.street_address}</div>
                      <div>
                        {emergencyNumberData?.doc.e911?.extended_address}
                      </div>
                      <div>
                        {emergencyNumberData?.doc.e911?.locality},{' '}
                        {emergencyNumberData?.doc.e911?.region}{' '}
                        {emergencyNumberData?.doc.e911?.postal_code}
                      </div>
                    </>
                  ) : (
                    'Address Not Set'
                  )}
                </Typography>
              </Grid>
            </Grid>

            {/*
              <Typography variant="h3">
                Emergency (E911)
                <InfoTooltip title={'placeholder'} size={'small'} />
                {emergencyInherited ? (
                  <Chip
                    color={'primary'}
                    variant={'contained'}
                    size={'small'}
                    label={emergencyInherited}
                  />
                ) : null}
              </Typography>

              <Typography
                variant="number1"
                sx={
                  emergencyName
                    ? {}
                    : { color: 'text.secondary', fontStyle: 'italic' }
                }
              >
                {emergencyNumberIsLoading ? (
                  <>&nbsp;</>
                ) : (
                  emergencyName ?? 'name not set'
                )}
              </Typography>
              <Typography
                variant="number1"
                sx={
                  emergencyAddress
                    ? {}
                    : { color: 'text.secondary', fontStyle: 'italic' }
                }
              >
                {emergencyNumberIsLoading ? (
                  <>&nbsp;</>
                ) : (
                  emergencyAddress ?? 'address not set'
                )}
              </Typography> */}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default DeviceCallerIdCard;
