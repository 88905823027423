// import { patch } from 'app/sdk/utils/kazoo';
import { useAuthSelector } from 'app/data/auth';
// import { patchResource, patchResourceKeys } from 'app/kazooSDK';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { PhoneNumberUpdatePartialDoc } from 'types/phoneNumber';

export const useUpdatePhoneNumberPartial = () => {
  const authState = useAuthSelector();
  return useMutation<any, AxiosError, PhoneNumberUpdatePartialDoc, unknown>(
    (partialDoc: any) =>
      sdk.phoneNumber.mutate.updatePartial(partialDoc, authState?.auth_token),
    // patchResource(patchResourceKeys.phoneNumber, partialDoc),
  );
};
