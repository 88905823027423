import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Typography,
  InfoTooltip,
  Chip,
  CircularProgress,
} from 'app/design';
import {
  MedicalServices as MedicalServicesIcon,
  ArrowCircleDown as ArrowCircleDownIcon,
  ArrowCircleUp as ArrowCircleUpIcon,
} from 'app/design/icons-material';
import { useFindInPage } from 'app/components/FindInPage';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { useAccountQuery } from 'app/hooks/queries/account';
import { usePhoneNumberQuery } from 'app/hooks/queries/phoneNumber';
import { CallerIdDialogAccount } from 'app/components/CallerIdDialogAccount';
import { useToggleReducer } from 'app/utilities';
import { useMarkdownTranslate } from 'app/utilities/translate';
import { startCase } from 'lodash';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Account } from 'types/account';

interface AccountCallerIdCardProps {
  onComplete: () => void;
  account: Account;
}

const AccountCallerIdCard = ({
  account,
  onComplete,
}: AccountCallerIdCardProps) => {
  const [showEditDialog, toggleEditShowDialog] = useToggleReducer(false);

  // account external CID
  let externalCid = account?.doc.caller_id?.external?.number;

  // account emergency CID
  let emergencyCid = account?.doc.caller_id?.emergency?.number;

  const {
    data: externalNumberData,
    isLoading: externalNumberIsLoading,
    refetch: refetchExternal,
  } = usePhoneNumberQuery({ id: externalCid });

  const {
    data: emergencyNumberData,
    isLoading: emergencyNumberIsLoading,
    refetch: refetchEmergency,
  } = usePhoneNumberQuery({ id: emergencyCid });

  const externalName = externalNumberData?.doc.cnam?.display_name;
  const externalNumber = externalNumberData?.doc.id;

  const emergencyName = emergencyNumberData?.doc.cnam?.display_name;
  const emergencyAddress = emergencyNumberData?.doc.e911?.street_address
    ? true
    : false;

  const handleComplete = () => {
    onComplete();
    refetchExternal();
    refetchEmergency();
    toggleEditShowDialog();
  };

  const { t } = useMarkdownTranslate();

  return (
    <>
      {showEditDialog ? (
        <CallerIdDialogAccount
          onCancel={toggleEditShowDialog}
          onComplete={handleComplete}
        />
      ) : null}

      <Typography variant="h1" paragraph>
        <ArrowCircleUpIcon
          sx={{ width: 24, height: 24, verticalAlign: 'bottom' }}
        />{' '}
        Outgoing Calls
      </Typography>

      <Link onClick={toggleEditShowDialog} underline="hover" variant="body2">
        Edit Caller ID
      </Link>

      <br />
      <br />

      <Box>
        <Typography variant="number1" paragraph>
          {externalNumberIsLoading ? (
            <>&nbsp;</>
          ) : emergencyCid ? (
            // @ts-ignore
            <PhoneNumberDisplay ptn={emergencyCid} />
          ) : (
            <Typography
              variant="number1"
              sx={{ color: 'text.secondary', fontStyle: 'italic' }}
            >
              number not set
            </Typography>
          )}
          {externalNumberIsLoading ? <CircularProgress size={15} /> : null}
        </Typography>
        <Typography variant="h5">
          Display Name{' '}
          <InfoTooltip title={t('caller_id.external.tooltip')} size={'small'} />
        </Typography>
        <Typography
          variant="h2"
          sx={
            externalName ? {} : { color: 'text.secondary', fontStyle: 'italic' }
          }
        >
          {externalNumberIsLoading ? (
            <>&nbsp;</>
          ) : (
            externalName ?? 'name not set'
          )}{' '}
        </Typography>
      </Box>

      <br />
      <br />

      <Box>
        <Typography variant="h3" paragraph>
          <MedicalServicesIcon
            sx={{ width: 16, height: 16, verticalAlign: 'middle' }}
          />{' '}
          Emergency Call Info{' '}
          <InfoTooltip
            title={t('caller_id.emergency.tooltip')}
            size={'small'}
          />
          {emergencyNumberIsLoading ? <CircularProgress size={15} /> : null}
        </Typography>

        <Typography variant="number1" paragraph>
          {emergencyNumberIsLoading ? (
            <>&nbsp;</>
          ) : externalNumber ? (
            // @ts-ignore
            <PhoneNumberDisplay ptn={externalNumber} />
          ) : (
            <Typography
              variant="number1"
              sx={{ color: 'text.secondary', fontStyle: 'italic' }}
            >
              number not set
            </Typography>
          )}
          {emergencyNumberIsLoading ? <CircularProgress size={15} /> : null}
        </Typography>
        <Typography variant="h5">Address</Typography>
        <Typography
          variant="h2"
          sx={
            emergencyName
              ? {}
              : { color: 'text.secondary', fontStyle: 'italic' }
          }
        >
          {emergencyNumberIsLoading ? (
            <>&nbsp;</>
          ) : (
            emergencyName ?? 'name not set'
          )}
        </Typography>
        <Typography
          variant="number1"
          sx={
            emergencyAddress
              ? {}
              : { color: 'text.secondary', fontStyle: 'italic' }
          }
        >
          {emergencyNumberIsLoading ? (
            <>&nbsp;</>
          ) : emergencyAddress ? (
            <>
              <div>{emergencyNumberData?.doc.e911?.street_address}</div>
              <div>{emergencyNumberData?.doc.e911?.extended_address}</div>
              <div>
                {emergencyNumberData?.doc.e911?.locality},{' '}
                {emergencyNumberData?.doc.e911?.region}{' '}
                {emergencyNumberData?.doc.e911?.postal_code}
              </div>
            </>
          ) : (
            'Address Not Set'
          )}
        </Typography>
      </Box>
    </>
  );
};

export default AccountCallerIdCard;
