import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
// import { Account, any } from 'types/account';
import { UserUpdatePartialDoc } from 'types/user';

export const useAccountStripeCustomerMutate = () => {
  const authState = useAuthSelector();
  return useMutation<any, AxiosError, any, unknown>((vars: any) =>
    sdk.stripe.mutate.customer(vars, { authToken: authState?.auth_token }),
  );
};
