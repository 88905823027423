import axios from 'axios';

const buildHeaders = config => {
  const requestHeaders = {
    'Content-Type': config.contentType ?? 'application/json',
    'X-AUTH-TOKEN': config.auth_token, // Uncomment or modify to test 401
  };

  return requestHeaders;
};

const buildUrl = url => {
  return `${process.env.REACT_APP_KAPI_URL}${url}`;
};

export const get = async (url, config) => {
  // TODO: handle additional config
  const headers = buildHeaders(config);
  const params = config?.urlParams;
  return axios.get(buildUrl(url), { headers, params });
};

export const patch = async (url, data, config) => {
  // TODO: handle additional config
  const headers = buildHeaders(config);
  return axios.patch(buildUrl(url), JSON.stringify({ data }), {
    headers,
  });
};

export const put = async (url, data, config) => {
  // TODO: handle additional config
  const headers = buildHeaders(config);
  return axios.put(buildUrl(url), JSON.stringify({ data }), {
    headers,
  });
};

export const post = async (url, data, config) => {
  // TODO: handle additional config
  const headers = buildHeaders(config);
  return axios.post(buildUrl(url), JSON.stringify({ data }), {
    headers,
  });
};

// base64 data is already in body string format
export const postBase64 = async (url, data, config) => {
  // TODO: handle additional config
  const headers = buildHeaders(config);
  return axios.post(buildUrl(url), data, {
    headers,
  });
};

export const del = async (url, config) => {
  // TODO: handle additional config
  const headers = buildHeaders(config);
  return axios.delete(buildUrl(url), {
    headers,
  });
};
