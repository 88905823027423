import { Grid, MenuItem, Select, TextField } from 'app/design';
import React from 'react';
import { MediaType, VoiceOptions } from 'types/media';

export const voices = [
  {
    value: VoiceOptions.femaleEnglishUS,
    label: 'Female - English US',
  },
  {
    value: VoiceOptions.maleEnglishUS,
    label: 'Male - English US',
  },
];

const TextToSpeech = ({ register }) => {
  return (
    <>
      <TextField
        label={'TTS'}
        defaultValue={''}
        fullWidth
        multiline
        /* EXPECTING REGISTER FROM REACT HOOK FORM */
        {...register(`${MediaType.Tts}.text`, {
          // required,
        })}
      />
      <br />
      <Select
        defaultValue={voices[0].value}
        {...register(`${MediaType.Tts}.voice`, {
          // required,
        })}
      >
        {voices.map(voice => (
          <MenuItem key={voice.value} value={voice.value}>
            {voice.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default TextToSpeech;
