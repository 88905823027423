import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from 'app/design';
import { useListCdrInteractionsQuery } from 'app/hooks/queries/callDetailRecord';
import { ExportButton } from 'app/reports/components/ExportButton';
import { InteractionTypeCell } from 'app/reports/components/ListCdrInteractions/components/InteractionTypeCell';
import { Leg } from 'app/reports/components/ListCdrInteractions/components/Leg';
import { durationFormat } from 'app/reports/components/utils';
import * as React from 'react';

interface CdrInteractionDetailsDialogProps {
  interactionId: string;
  onClose: () => void;
}

const CdrInteractionDetailsDialog = ({
  interactionId,
  onClose,
}: CdrInteractionDetailsDialogProps) => {
  const { data: interactionsResponse, isLoading } = useListCdrInteractionsQuery(
    0,
    1,
    [['createdAt', 'desc']],
    {
      raw: [
        {
          variables: {
            docFilters: [['id', '=', interactionId]],
          },
        },
      ],
    },
  );

  const [interaction] = interactionsResponse?.cdrInteractions ?? [];

  return (
    <Dialog open={true} fullWidth maxWidth={'md'}>
      {isLoading ? (
        <DialogInProgress title={'Loading interaction...'} />
      ) : (
        <>
          <DialogTitle>Interaction Details</DialogTitle>
          <Divider />
          <DialogContent>
            {interaction ? (
              <Grid container spacing={1}>
                <Grid item sm={4}>
                  <Box
                    sx={{ borderRight: '1px solid gray', textAlign: 'center' }}
                  >
                    <Typography variant="h6">
                      Call Interaction Quick Details
                    </Typography>
                    <br />
                    <InteractionTypeCell interaction={interaction} />
                    <br />
                    <Grid
                      container
                      justifyContent={'center'}
                      spacing={1}
                      sx={{
                        width: '100%',
                      }}
                    >
                      <Grid item>
                        <Typography sx={{ fontWeight: 700 }} variant={'body1'}>
                          Duration:{' '}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant={'body1'}>
                          {interaction.doc.result === 'answered'
                            ? durationFormat(
                                (interaction.updatedAt -
                                  interaction.createdAt) /
                                  1000,
                              )
                            : '00:00:00'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent={'center'}
                      spacing={1}
                      sx={{
                        width: '100%',
                      }}
                    >
                      <Grid item>
                        <Typography sx={{ fontWeight: 700 }} variant={'body1'}>
                          Start Time:{' '}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant={'body1'}>
                          {new Date(interaction.updatedAt).toLocaleString()}
                        </Typography>
                      </Grid>
                    </Grid>

                    <br />
                    <ExportButton interactions={[interaction]}>
                      Export
                    </ExportButton>
                  </Box>
                </Grid>
                <Grid item sm={8}>
                  <Grid container direction="column" spacing={2}>
                    {interaction.legs.map((leg, i) => {
                      return (
                        <Leg key={i} leg={leg} interaction={interaction} />
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <>
                <Typography color={'error'}>Invalid interaction ID.</Typography>
              </>
            )}
          </DialogContent>
          <Divider />
          <DialogActions sx={{ justifyContent: 'right' }}>
            <Button variant={'outlined'} color={'success'} onClick={onClose}>
              Done
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default CdrInteractionDetailsDialog;
