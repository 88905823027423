import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { MenuCreateDoc } from 'types/menu';

export const useCreateMenu = () => {
  const authState = useAuthSelector();
  return useMutation<any, AxiosError, MenuCreateDoc, unknown>(
    (createMenuDoc: MenuCreateDoc) =>
      sdk.menu.mutate.create(createMenuDoc, authState?.auth_token),
  );
};
