import { sdk } from 'app/sdk';
import React from 'react';

const setupCompanyVoicemail = async ({ auth_token }) => {
  // create media (text-to-speech) for greeting
  const mediaResult = await sdk.media.mutate.create(
    {
      streamable: true,
      name: `company voicemail greeting`,
      media_source: 'tts',
      tts: {
        text: 'We are not available. Please leave a message after the tone',
        voice: 'female/en-US',
      },
      language: 'en-us',
    },
    auth_token,
  );
  console.log('mediaResult:', mediaResult);
  if (mediaResult.status !== 'success') {
    throw 'Failed saving greeting';
  }
  const mediaId = mediaResult.data.id;

  // create vmbox
  const vmboxResult = await sdk.vmbox.mutate.create(
    {
      // owner_id: user?.id, // todo: account id?
      owner_type: 'account',
      name: 'Company Shared Voicemail',
      mailbox: '900',
      // random 4-digit PIN
      pin: (Math.floor(Math.random() * 10000) + 10000).toString().substring(1),
      require_pin: false,
      announcement_only: false,
      include_message_on_notify: true,
      include_transcription_on_notify: true,
      is_setup: true,
      is_voicemail_ff_rw_enabled: false,
      check_if_owner: true,
      delete_after_notify: false,
      save_after_notify: false,
      skip_envelope: false,
      skip_greeting: false,
      skip_instructions: false,
      transcribe: true,
      notify_email_addresses: [],
      oldest_message_first: false,
      media: {
        unavailable: mediaId,
      },
    },
    auth_token,
  );
  console.log('vmboxResult:', vmboxResult);
  if (vmboxResult.status !== 'success') {
    throw 'Failed saving vmbox';
  }
  const vmboxId = vmboxResult.data.id;

  return { vmboxId };
};

export default setupCompanyVoicemail;
