import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useLocalSelector, useLocalSlice } from 'app/data/local';
import { useNavCrumbBuilder } from 'app/hooks/general/useNavCrumbBuilder';

/*
  crumbs: builds array of items from input
  - input accepts:
    - array of items (same as final output)
    - function to build array of items
  deps: dependencies for the input function
*/
/*
  final crumbs format for input:
    [
      "string with no link",
      {
        name: 'here',
        link: '/xyz'
      }
    ]
*/

interface useNavDetailsProps {
  sidebar_key: string;
  crumbs: any;
  deps?: any[];
}

// TODO: use numbers list to update external name when external number selection changes
const useNavDetails = ({
  sidebar_key,
  crumbs: inputCrumbs,
  deps = [],
}: useNavDetailsProps) => {
  const dispatch = useDispatch();

  const crumbs = useNavCrumbBuilder(inputCrumbs, deps);

  // const { sidebar_key } = useLocalSelector();
  const { actions: localActions } = useLocalSlice();

  // Register crumbs
  // - TODO: allows multiple registrations of "breadcrumbs" on the page, but on the first (or last, depending on perspective) is shown in the header
  useEffect(() => {
    const thisElem = crumbs.filter(c => !!c); // filter out null entries
    dispatch(localActions.set_nav_breadcrumbs(thisElem));

    // set title on page, according to last breadcrumb item
    if (thisElem.length) {
      let elem = thisElem[thisElem.length - 1];
      document.title = `Calling.io - ${
        typeof elem === 'string' ? elem : elem?.name
      }`;
    }

    return () => {
      // unregister
      dispatch(localActions.unregister_nav_breadcrumbs(thisElem));
    };
  }, [crumbs]);

  // save sidebar key (for highlighting in sidebar)
  useEffect(() => {
    if (sidebar_key) {
      dispatch(localActions.set_sidebar_key(sidebar_key));
    }
  }, [sidebar_key]);

  return null;
};

export default useNavDetails;
