import { AccountMini } from 'app/components/Sidebar/components/AccountMini';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAuthSlice, selectAuth } from 'app/data/auth';
import { useAccountQuery } from 'app/hooks/queries/account';

import {
  Avatar,
  AppBar,
  Box,
  Drawer,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  Collapse,
  Grid,
  Toolbar,
  Tooltip,
  ThemeProvider,
  createTheme,
  useTheme,
  buildThemeWithMode,
  Button,
} from 'app/design';
import {
  Dashboard as DashboardIcon,
  Business as BusinessIcon,
  Group as GroupIcon,
  Groups as GroupsIcon,
  People as PeopleIcon,
  Numbers as NumbersIcon,
  Menu as MenuIcon,
  Extension as ExtensionIcon,
  Phone as PhoneIcon,
  Fax as FaxIcon,
  AltRoute as AltRouteIcon,
  Forum as ForumIcon,
  Voicemail as VoicemailIcon,
  MenuBook as MenuBookIcon,
  ContentCopy as ContentCopyIcon,
  Today as TodayIcon,
  Assessment as AssessmentIcon,
  CreditCard as CreditCardIcon,
  SupportAgent as SupportAgentIcon,
  QueueMusic as QueueMusicIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  History as HistoryIcon,
  ContactMail as ContactMailIcon,
  DevicesOther as DevicesOtherIcon,
} from 'app/design/icons-material';
import PerfectScrollbar from 'react-perfect-scrollbar';

import constants from 'app/constants';
import { useLocalSelector, useLocalSlice } from 'app/data/local';

import { Account } from './components/Account';

export const Sidebar = () => {
  // TODO: use breadcrumbs/etc for updating this
  // - ie state.local.breadcrumbs
  const { sidebar_key: selectedSidebarKey, sidebar_mini } = useLocalSelector();
  const { actions } = useLocalSlice();
  const dispatch = useDispatch();

  // scroll to top on sidebar change
  let location = useLocation();
  useEffect(() => window.scrollTo(0, 0), [location.pathname]);

  // console.log('sidebar selected:', selectedSidebarKey);

  let isAdminUrl =
    window.location.pathname.indexOf('/admin') > -1 ? true : false;
  const [openItems, setOpenItems] = useState([] as any[]);

  useEffect(() => {
    // Update the "open" status of each parent for an item
    // - if selected sidebar key changes, make sure to open/un-collapse the parent of that one
    const newOpenItems = [] as any;
    for (let parentTab of sidebarTabs) {
      if (openItems.includes(parentTab.sidebarKey)) {
        continue;
      }
      if (
        parentTab.sidebarKey === selectedSidebarKey ||
        (parentTab.children &&
          parentTab.children.find(
            childTab => childTab.sidebarKey === selectedSidebarKey,
          ))
      ) {
        newOpenItems.push(parentTab.sidebarKey);
      }
    }

    if (newOpenItems.length) {
      // if (JSON.stringify(openItems) !== JSON.stringify(newOpenItems)) {
      setOpenItems([...openItems, ...newOpenItems]);
    }
    // }
  }, [selectedSidebarKey, openItems]);

  // determine if menu should be open or closed
  // - sets default if not set
  const theme = useTheme();
  const isMediaQueryMd = useMediaQuery(theme.breakpoints.up('md'));
  let normalMenu = sidebar_mini === null ? isMediaQueryMd : !sidebar_mini;
  // console.log('isMediaQueryMd:', isMediaQueryMd, normalMenu, sidebar_mini);

  const tabsItems = sidebarTabs
    .filter(tab => tab.show({ isAdminUrl }))
    .map((tab, i) => (
      <SidebarListItem
        key={i}
        {...tab}
        isAdminUrl={isAdminUrl}
        isMini={!normalMenu}
        openItems={openItems}
        setOpenItems={setOpenItems}
        normalMenu={normalMenu}
        selectedSidebarKey={selectedSidebarKey}
      />
    ));

  const handleScrollY = () => {
    // handle if we should show elevation below the Appbar/toolbar
  };

  const toggleSidebarMini = () => {
    // normal menu is inverse of sidebar_mini
    dispatch(actions.set_sidebar_mini(normalMenu));
  };

  return (
    <ThemeProvider
      // @ts-ignore
      theme={outerTheme => ({
        // @ts-ignore
        ...createTheme(buildThemeWithMode('dark')),
        // @ts-ignore
        outerTheme, // for "going back" to previous theme (for tooltips)
      })}
    >
      <Drawer
        variant="permanent"
        sx={{
          width: normalMenu
            ? constants.nav.DRAWER_WIDTH
            : constants.nav.DRAWER_MINI_WIDTH,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: normalMenu
              ? constants.nav.DRAWER_WIDTH
              : constants.nav.DRAWER_MINI_WIDTH,
            boxSizing: 'border-box',
          },
          // @ts-ignore
        }}
        // PaperProps={{
        //   sx: {
        //     background: theme.palette.text.primary,
        //     color: theme.palette.common.white,
        //   },
        // }}
      >
        <Box sx={{ overflow: 'auto' }}>
          <PerfectScrollbar
            options={{ wheelPropagation: false }}
            onScrollY={handleScrollY}
          >
            {/* <AppBar elevation={4}>
              <Toolbar variant="dense" />
            </AppBar> */}

            {/* <Toolbar variant="dense" /> */}
            <Box sx={{ padding: 3, pb: 2.5, ml: normalMenu ? 0 : -2 }}>
              <Grid container alignItems="center" columnSpacing={2}>
                <Grid item>
                  <Link component={RouterLink} to="/" underline="none">
                    <img src="https://ucarecdn.com/0bdb1986-1c08-4bf6-a6e9-fe324355d111/callingio1.svg" />
                  </Link>
                </Grid>
                {normalMenu ? (
                  <Grid item>
                    <Link
                      component={RouterLink}
                      to="/"
                      variant="h2"
                      color="#fff"
                      underline="none"
                    >
                      CallingIO
                    </Link>
                  </Grid>
                ) : null}
                <Grid item>
                  <Button onClick={toggleSidebarMini}>
                    <MenuIcon />
                  </Button>
                </Grid>
              </Grid>
            </Box>
            {normalMenu ? <Account /> : <AccountMini />}
            <List dense sx={{ padding: normalMenu ? '4px 8px' : '2px 4px' }}>
              {tabsItems}
            </List>
          </PerfectScrollbar>
        </Box>
      </Drawer>
    </ThemeProvider>
  );
};

const SidebarListItem = props => {
  const {
    text,
    // className,
    normalMenu,
    subheading,
    route,
    type,
    icon,
    iconLetter,
    padTop,
    tour,
    sidebarKey,
    selectedSidebarKey,
    depth = 0,
    isAdminUrl,
    isMini,
    openItems,
    setOpenItems,
    children = [] as any[],
  } = props;

  const history = useHistory();

  const childSelected = children.find(c => c.sidebarKey === selectedSidebarKey); // todo: handle deeper w/ recursion
  const isSelected =
    selectedSidebarKey === sidebarKey
      ? true
      : childSelected && isMini
      ? true
      : false;

  const handleToggleShowHide = e => {
    console.log('toggle', openItems.includes(sidebarKey));
    if (openItems.includes(sidebarKey)) {
      setOpenItems([...openItems.filter(item => item !== sidebarKey)]);
    } else {
      setOpenItems([...openItems, sidebarKey]);
    }
    e?.stopPropagation();
    return false;
  };

  const handleClick = () => {
    if (route) {
      history.push(route);
      // @ts-ignore
      handleToggleShowHide();
    }
  };

  const childrenFiltered = children.filter(c => c.show({ isAdminUrl }));

  // const theme = useTheme();

  return (
    <>
      <ListItem
        button
        key={text}
        onClick={
          handleClick
          // gotoRoute(
          // history,
          // route,
          // handleDrawerToggle,
          // isPermanent,
          // childrenFiltered,
          // handleToggleShowHide,
          // )
        }
        selected={isSelected}
        disableRipple
        sx={{
          borderRadius: '4px',
          // marginTop: padTop || 0,
          // paddingTop: isMiniSub ? 6 : undefined,
          // paddingBottom: isMiniSub ? 6 : undefined,
          color: theme => theme.palette.primary.main,
          '& .MuiListItemIcon-root': {
            color: theme => theme.palette.primary.main,
          },
          '&.Mui-selected': {
            background: '#1F8DA4',
            color: 'white !important',
          },
          '&.Mui-selected .MuiListItemIcon-root': {
            color: 'white !important',
          },
          // '&.Mui-selected': {
          //   color: 'white !important',
          // },
        }}
        // data-tour={tour}
      >
        <div style={{ width: depth * 36 }}>&nbsp;</div>
        {icon || iconLetter ? (
          <>
            {/* @ts-ignore */}
            {icon && typeof icon !== 'string' ? (
              <ListItemIcon
                sx={{
                  minWidth: 28,
                  '& .MuiSvgIcon-root': {
                    height: 18,
                    width: 18,
                  },
                }}
              >
                {/* {getIcon(icon, classes, isSelected)} */}
                {icon}
              </ListItemIcon>
            ) : iconLetter ? (
              <ListItemIcon>
                {/* {getIconLetter(iconLetter, classes, isSelected)} */}
                {'invalid'}
              </ListItemIcon>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
        {normalMenu ? (
          <>
            <ListItemText
              primaryTypographyProps={
                type === 'heading' || isSelected || childSelected
                  ? {
                      sx: { fontWeight: 900 },
                    }
                  : { sx: { fontWeight: 600 } }
              }
              primary={text}
              secondary={subheading}
            />
            {childrenFiltered && childrenFiltered.length ? (
              openItems.includes(sidebarKey) ? (
                <ExpandLessIcon onClick={handleToggleShowHide} />
              ) : (
                <ExpandMoreIcon onClick={handleToggleShowHide} />
              )
            ) : (
              ''
            )}
          </>
        ) : null}
      </ListItem>
      {/* SubItems for Menu (Only show if not mini) */}
      {!isMini && childrenFiltered && childrenFiltered.length ? (
        <Collapse
          in={openItems.includes(sidebarKey)}
          timeout="auto"
          sx={{ minHeight: 'auto' }}
        >
          {childrenFiltered.map((c, i) => (
            <SidebarListItem
              key={i}
              {...c}
              depth={depth + 1}
              isAdminUrl={isAdminUrl}
              openItems={openItems}
              setOpenItems={setOpenItems}
              selectedSidebarKey={selectedSidebarKey}
              normalMenu={normalMenu}
            />
          ))}
        </Collapse>
      ) : (
        ''
      )}
    </>
  );
};

// TODO: move sidebarTabs into a reducer?
const sidebarTabs = [
  // {
  //   text: 'My Personal Office',
  //   subheading: (
  //     <>
  //       <Typography variant="caption">
  //         <Link component={RouterLink} to="/admin/dashboard">
  //           Switch to Company Admin
  //         </Link>
  //       </Typography>
  //     </>
  //   ),
  //   // icon: miniMenu ? 'main-office' : undefined,
  //   type: 'heading',
  //   // route: '/office/home',
  //   padTop: '8px',
  //   show: ({ isAdminUrl }) => !isAdminUrl, // true/false
  // },
  {
    text: 'Dashboard',
    type: 'link',
    route: '/office/dashboard',
    icon: <DashboardIcon />,
    sidebarKey: constants.nav.OFFICE_DASHBOARD.sidebar_key,
    show: ({ isAdminUrl }) => !isAdminUrl, // true/false
  },
  {
    text: 'Voicemails',
    type: 'link',
    route: '/office/voicemails',
    icon: <VoicemailIcon />,
    tour: 'sidebar-voicemails',
    sidebarKey: constants.nav.OFFICE_VOICEMAILS.sidebar_key,
    show: ({ isAdminUrl }) => !isAdminUrl, // true/false
  },
  // {
  //   text: 'Conferences',
  //   type: 'link',
  //   route: '/office/conferences',
  //   icon: 'video',
  //   tour: 'sidebar-conferences',
  //   sidebarKey: 'conferences',
  //   show: ({ isAdminUrl }) => !isAdminUrl, // true/false
  // },
  {
    text: 'Call History',
    type: 'link',
    route: '/office/call_history',
    icon: <HistoryIcon />,
    sidebarKey: constants.nav.OFFICE_CALL_HISTORY.sidebar_key,
    show: ({ isAdminUrl }) => !isAdminUrl, // true/false
  },
  // {
  //   text: 'Faxes',
  //   type: 'link',
  //   route: '/office/faxes',
  //   icon: 'device-fax',
  //   sidebarKey: 'faxes',
  //   show: ({ isAdminUrl }) => !isAdminUrl, // true/false
  // },
  // {
  //   text: 'Administration',
  //   subheading: (
  //     <>
  //       <Typography variant="caption">
  //         <Link
  //           // onClick={() => history.push('/office/home')}
  //           to="/office/dashboard"
  //           component={RouterLink}
  //         >
  //           Switch to Personal Office
  //         </Link>
  //       </Typography>
  //     </>
  //   ),
  //   route: null,
  //   // icon: miniMenu ? 'user-admin' : undefined,
  //   type: 'heading',
  //   padTop: '8px',
  //   show: ({ isAdminUrl }) => isAdminUrl, // true/false
  // },
  {
    text: 'Dashboard',
    type: 'link',
    route: '/admin/dashboard',
    icon: <BusinessIcon />,
    sidebarKey: constants.nav.ADMIN_DASHBOARD.sidebar_key,
    show: ({ isAdminUrl }) => isAdminUrl, // true/false
  },
  {
    text: 'Users ',
    type: 'link',
    route: '/admin/users',
    icon: <GroupIcon />,
    // icon: <>&nbsp;</>,
    sidebarKey: constants.nav.ADMIN_USERS_LIST.sidebar_key,
    show: ({ isAdminUrl }) => isAdminUrl, // true/false
  },
  // {
  //   text: 'Team',
  //   type: 'link',
  //   route: '/admin/users',
  //   icon: <GroupIcon />,
  //   sidebarKey: 'admin_parent_people',
  //   show: ({ isAdminUrl }) => isAdminUrl, // true/false
  //   children: [
  //     {
  //       text: 'Users ',
  //       type: 'link',
  //       route: '/admin/users',
  //       // icon: <GroupIcon />,
  //       // icon: <>&nbsp;</>,
  //       sidebarKey: constants.nav.ADMIN_USERS_LIST.sidebar_key,
  //       show: ({ isAdminUrl }) => isAdminUrl, // true/false
  //     },
  //     // {
  //     //   text: 'Users & Directories',
  //     //   type: 'link',
  //     //   route: '/admin/users',
  //     //   // icon: 'multiple-items',
  //     //   icon: 'user',
  //     //   show: ({ isAdminUrl }) => isAdminUrl, // true/false
  //     //   sidebarKey: 'admin_users_parent',
  //     //   children: [
  //     //     {
  //     //       text: 'Users ',
  //     //       type: 'link',
  //     //       route: '/admin/users',
  //     //       icon: 'user',
  //     //       sidebarKey: constants.nav.ADMIN_USERS_LIST.sidebar_key,
  //     //       show: ({ isAdminUrl }) => isAdminUrl, // true/false
  //     //     },
  //     //     {
  //     //       text: 'Directories ',
  //     //       type: 'link',
  //     //       route: '/admin/directories',
  //     //       icon: 'folder',
  //     //       sidebarKey: constants.nav.ADMIN_DIRECTORIES_LIST.sidebar_key,
  //     //       show: ({ isAdminUrl }) => isAdminUrl, // true/false
  //     //     },
  //     //   ],
  //     // },
  //     {
  //       text: 'Groups ',
  //       type: 'link',
  //       route: '/admin/groups',
  //       // icon: <PeopleIcon />,
  //       // icon: <>&nbsp;</>,
  //       sidebarKey: constants.nav.ADMIN_GROUPS_LIST.sidebar_key,
  //       show: ({ isAdminUrl }) => isAdminUrl, // true/false
  //     },
  //   ],
  // },
  // {
  //   text: 'Media & Screenpops',
  //   type: 'speaking',
  //   route: '/admin/media',
  //   icon: 'account-tree',
  //   sidebarKey: 'admin_parent_media_screenpops',
  //   show: ({ isAdminUrl }) => isAdminUrl, // true/false
  //   children: [
  //     {
  //       text: 'Media',
  //       type: 'link',
  //       route: '/admin/media',
  //       icon: 'speaking',
  //       sidebarKey: constants.nav.ADMIN_MEDIAS_LIST.sidebar_key,
  //       show: ({ isAdminUrl }) => isAdminUrl, // true/false
  //     },
  //     {
  //       text: 'Screenpops',
  //       type: 'link',
  //       route: '/admin/screenpops',
  //       icon: 'lightning-bolt',
  //       sidebarKey: constants.nav.ADMIN_SCREENPOPS_LIST.sidebar_key,
  //       show: ({ isAdminUrl }) => isAdminUrl,
  //     },
  //   ],
  // },
  {
    text: 'Phones & Devices',
    type: 'link',
    route: '/admin/devices',
    icon: <DevicesOtherIcon />,
    sidebarKey: constants.nav.ADMIN_DEVICES_LIST.sidebar_key,
    show: ({ isAdminUrl }) => isAdminUrl, // true/false
  },
  {
    text: 'Ring Groups ',
    type: 'link',
    route: '/admin/groups',
    icon: <GroupsIcon />,
    // icon: <>&nbsp;</>,
    sidebarKey: constants.nav.ADMIN_GROUPS_LIST.sidebar_key,
    show: ({ isAdminUrl }) => isAdminUrl, // true/false
  },
  {
    text: 'Numbers',
    type: 'link',
    route: '/admin/numbers',
    icon: <NumbersIcon />,
    sidebarKey: 'admin_numbers_parent',
    show: ({ isAdminUrl }) => isAdminUrl, // true/false
    children: [
      {
        text: 'Phone Numbers',
        type: 'link',
        route: '/admin/numbers',
        // icon: <NumbersIcon />,
        sidebarKey: constants.nav.ADMIN_NUMBERS_LIST.sidebar_key,
        show: ({ isAdminUrl }) => isAdminUrl, // true/false
      },
      {
        text: 'Extensions',
        type: 'link',
        route: '/admin/extensions',
        // icon: <ExtensionIcon />,
        sidebarKey: constants.nav.ADMIN_EXTENSIONS_LIST.sidebar_key,
        show: ({ isAdminUrl }) => isAdminUrl, // true/false
      },
    ],
  },
  // {
  //   text: 'Conferences',
  //   type: 'link',
  //   route: '/admin/conferences',
  //   icon: 'video',
  //   sidebarKey: constants.nav.ADMIN_CONFERENCES_LIST.sidebar_key,
  //   show: ({ isAdminUrl }) => isAdminUrl, // true/false
  // },
  {
    // text: 'Callflows & Media',
    text: 'Miscellaneous',
    type: 'link',
    route: '/admin/callflows',
    icon: <AltRouteIcon />,
    sidebarKey: 'admin_parent_strategies',
    show: ({ isAdminUrl }) => isAdminUrl, // true/false
    children: [
      {
        text: 'Call Routes',
        type: 'link',
        route: '/admin/callflows',
        // icon: <AltRouteIcon />,
        sidebarKey: constants.nav.ADMIN_ALL_CALLFLOWS_LIST.sidebar_key,
        show: ({ isAdminUrl }) => isAdminUrl, // true/false
      },
      // {
      //   text: 'IVR Extensions',
      //   type: 'link',
      //   route: '/admin/callflows',
      //   icon: 'account-tree',
      //   sidebarKey: constants.nav.ADMIN_IVR_EXTENSIONS_LIST.sidebar_key,
      //   show: ({ isAdminUrl }) => isAdminUrl, // true/false
      // },
      {
        text: 'Schedules',
        type: 'link',
        route: '/admin/schedules',
        // icon: <TodayIcon />,
        sidebarKey: constants.nav.ADMIN_SCHEDULES_LIST.sidebar_key,
        show: ({ isAdminUrl }) => isAdminUrl, // true/false
      },
      {
        text: 'Media',
        type: 'link',
        route: '/admin/media',
        // icon: <QueueMusicIcon />,
        sidebarKey: constants.nav.ADMIN_MEDIAS_LIST.sidebar_key,
        show: ({ isAdminUrl }) => isAdminUrl, // true/false
      },
      {
        text: 'Menus',
        type: 'link',
        route: '/admin/menus',
        // icon: <MenuBookIcon />,
        sidebarKey: constants.nav.ADMIN_MENUS_LIST.sidebar_key,
        show: ({ isAdminUrl }) => isAdminUrl, // true/false
      },
      {
        text: 'Templates',
        type: 'link',
        route: '/admin/callflow_templates',
        // icon: <ContentCopyIcon />,
        sidebarKey: constants.nav.ADMIN_TEMPLATES_LIST.sidebar_key,
        show: ({ isAdminUrl }) =>
          process.env.NODE_ENV === 'development' && isAdminUrl, // true/false
      },
    ],
  },
  {
    text: 'Voicemail Boxes',
    type: 'link',
    route: '/admin/vmboxes',
    icon: <VoicemailIcon />,
    sidebarKey: constants.nav.ADMIN_VMBOXES_LIST.sidebar_key,
    show: ({ isAdminUrl }) => isAdminUrl, // true/false
  },
  {
    text: 'Messaging',
    type: 'link',
    route: '/admin/messaging',
    icon: <ForumIcon />,
    sidebarKey: constants.nav.ADMIN_MESSAGING.sidebar_key,
    show: ({ isAdminUrl }) =>
      process.env.NODE_ENV === 'development' && isAdminUrl, // true/false
  },
  {
    text: 'Contacts ',
    type: 'link',
    route: '/admin/contacts',
    icon: <ContactMailIcon />,
    // icon: <>&nbsp;</>,
    sidebarKey: constants.nav.ADMIN_CONTACTS.sidebar_key,
    show: ({ isAdminUrl }) =>
      process.env.NODE_ENV === 'development' && isAdminUrl, // true/false
  },
  // {
  //   text: 'Voicemail, Messages',
  //   type: 'link',
  //   route: '/admin/vmboxes',
  //   icon: 'chevron-double-down',
  //   sidebarKey: 'admin_parent_boxes',
  //   show: ({ isAdminUrl }) => isAdminUrl, // true/false
  //   children: [
  //     {
  //       text: 'Voicemails',
  //       type: 'link',
  //       route: '/admin/vmboxes',
  //       icon: 'voicemail',
  //       sidebarKey: constants.nav.ADMIN_VMBOXES_LIST.sidebar_key,
  //       show: ({ isAdminUrl }) => isAdminUrl, // true/false
  //     },
  //     {
  //       text: 'Messaging',
  //       type: 'link',
  //       route: '/admin/messaging',
  //       icon: 'comments',
  //       sidebarKey: constants.nav.ADMIN_MESSAGING_LIST.sidebar_key,
  //       show: ({ isAdminUrl }) => isAdminUrl, // true/false
  //     },
  //   ],
  // },
  {
    text: 'Reporting',
    type: 'link',
    route: '/admin/calllogs',
    sidebarKey: constants.nav.ADMIN_CALL_LOGS.sidebar_key,
    icon: <AssessmentIcon />,
    show: ({ isAdminUrl }) => isAdminUrl, // true/false
  },

  {
    text: 'Billing',
    type: 'link',
    route: '/admin/billing',
    // padTop: '24px',
    icon: <CreditCardIcon />,
    sidebarKey: constants.nav.ADMIN_BILLING.sidebar_key,
    show: ({ isAdminUrl }) => isAdminUrl, // true/false
  },
  // {
  //   text: 'Support',
  //   type: 'link',
  //   // route: '/admin/support',
  //   route: 'https://callingiotest1.freshdesk.com/support/home',
  //   // padTop: '24px',
  //   icon: <SupportAgentIcon />,
  //   sidebarKey: constants.nav.ADMIN_SUPPORT.sidebar_key,
  //   show: ({ isAdminUrl }) => isAdminUrl, // true/false
  // },
  // {
  //   text: 'Callflows',
  //   type: 'link',
  //   route: '/admin/callflows',
  //   icon: 'setting-cog',
  //   show: ({isAdminUrl}) => isAdminUrl, // true/false
  // },
  // {
  //   text: 'Faxes ',
  //   type: 'link',
  //   route: '/admin/faxboxes',
  //   icon: 'device-fax',
  //   sidebarKey: 'admin_faxes',
  //   show: ({isAdminUrl}) => isAdminUrl, // true/false
  // },
  // {
  //   text: 'Chat',
  //   type: 'link',
  //   route: '/chat',
  //   icon: 'comments',
  //   show: ({isAdminUrl}) => !isAdminUrl, // true/false
  // },
  // {
  //   text: 'Contacts',
  //   type: 'link',
  //   route: '/contacts',
  //   icon: 'speaking',
  //   show: ({isAdminUrl}) => !isAdminUrl, // true/false
  // },
  // {
  //   text: 'My Devices',
  //   type: 'link',
  //   route: '/user_portal/devicesnumbers',
  //   icon: 'device-voip-phone',
  //   show: ({isAdminUrl}) => !isAdminUrl, // true/false
  // },
  // {
  //   text: 'Company',
  //   type: 'link',
  //   route: '/admin/settings',
  //   icon: 'setting-cog',
  //   sidebarKey: 'admin_settings',
  //   show: ({isAdminUrl}) => isAdminUrl, // true/false
  //   // collapsed: [
  //   //   {
  //   //     text: 'Locations',
  //   //     type: 'link',
  //   //     route: '/admin/locations',
  //   //     icon: 'main-office',
  //   //     sidebarKey: 'admin_locations',
  //   //     show: ({isAdminUrl}) => (locations.length > 0 ? true : false), // true/false
  //   //   },
  //   // ],
  // },
  // {
  //   text: 'Locations & Rooms',
  //   type: 'link',
  //   route: '/admin/locations',
  //   icon: 'layers--2',
  //   sidebarKey: 'admin_physical_parent',
  //   show: ({isAdminUrl}) => isAdminUrl, // true/false
  //   collapsed: [
  //     {
  //       text: 'Locations ',
  //       type: 'link',
  //       route: '/admin/locations',
  //       icon: 'location-pin',
  //       sidebarKey: 'admin_locations',
  //       show: ({isAdminUrl}) => isAdminUrl, // true/false
  //     },
  //     {
  //       text: 'Rooms ',
  //       type: 'link',
  //       route: '/admin/rooms',
  //       icon: 'home',
  //       sidebarKey: 'admin_rooms',
  //       show: ({isAdminUrl}) => isAdminUrl, // true/false
  //     },
  //   ],
  // },
  // {
  //   text: 'Reports',
  //   type: 'link',
  //   route: '/admin/reports',
  //   icon: 'setting-cog',
  //   show: ({isAdminUrl}) => isAdminUrl, // true/false
  // },
  // {
  //   text: 'Live Dashboard',
  //   type: 'link',
  //   route: '',
  //   icon: 'setting-cog',
  //   show: ({isAdminUrl}) => isAdminUrl, // true/false
  // },
  // {
  //   text: 'SUPPORT',
  //   type: 'heading',
  //   padTop: '24px',
  //   show: ({isAdminUrl}) => isAdminUrl, // true/false
  // },
  // {
  //   text: 'Reception',
  //   type: 'link',
  //   route: '/admin/receptions',
  //   icon: 'speaking',
  //   sidebarKey: 'admin_receptions',
  //   show: ({isAdminUrl}) => isAdminUrl, // true/false
  // },
  // {
  //   text: 'Forums',
  //   type: 'link',
  //   route: '/',
  //   icon: 'setting-cog',
  //   show: ({isAdminUrl}) => isAdminUrl, // true/false
  // },
];

// const getIcon = (icon, classes, active) => {
//   if (!icon) {
//     return '';
//   }
//   return (
//     <Telicon
//       name={icon}
//       className={active ? classes.sideBarIconActive : classes.sideBarIcon}
//     />
//   );
// };

const getIconLetter = (iconLetter, classes, active) => {
  return (
    <Avatar
      // className={
      //   active ? classes.sideBarIconLetterActive : classes.sideBarIconLetter
      // }
      sx={{ fontSize: 14 }}
    >
      {iconLetter}
    </Avatar>
  );
};

const useGotoRoute = () => {
  /*

  (
    history,
    route,
    handleDrawerToggle,
    isPermanent,
    collapsed,
    handleToggleShowHide,
  ) =>
  () => {
    if (!route) {
      return null;
    }

    if (!isPermanent && handleDrawerToggle) {
      handleDrawerToggle();
    }

    if (collapsed && collapsed.length) {
      handleToggleShowHide();
    }

    if (route && route.indexOf('http') === 0) {
      window.open(route, '_blank');
      return;
    }

    if (route) {
      history.push(route);
    }

    // switch (name) {
    //   case 'Home':
    //     history.push('/user_portal/home');
    //     break;

    //   case 'Voicemails':
    //     history.push('/user_portal/voicemails');
    //     break;

    //   case 'Call History':
    //     history.push('/user_portal/call_history');
    //     break;

    //   case 'Devices & Numbers':
    //     history.push('/user_portal/devices_numbers');
    //     break;

    //   case 'Faxes':
    //     history.push('/user_portal/faxes');
    //     break;

    //   case 'Settings':
    //     history.push('/user_portal/settings');
    //     break;

    //   case 'Conferences':
    //     history.push('/user_portal/conferences');
    //     break;

    //   default:
    //     break;
    // }
  };

  */

  return null;
};

export default Sidebar;
