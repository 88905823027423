import { Grid, InfoTooltip, Link, Typography } from 'app/design';
import React, { ReactNode } from 'react';

interface GenericSettingsField {
  title: ReactNode;
  titleHelp?: ReactNode | string | any;
  content?: ReactNode;
  caption?: ReactNode;
  chip?: ReactNode;
  editLabel?: string;
  editOnClick?: () => void;
}

const GenericSettingsCardField = ({
  title,
  titleHelp,
  content,
  chip,
  caption,
  editLabel,
  editOnClick,
}: GenericSettingsField) => {
  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <Typography variant="h3">
              {title}{' '}
              {titleHelp ? (
                <InfoTooltip title={titleHelp} size={'small'} />
              ) : null}{' '}
              {chip ? (
                <div style={{ display: 'inline-block', marginBottom: 5 }}>
                  {chip}
                </div>
              ) : null}
            </Typography>
            {caption ? (
              <Typography variant="caption">{caption}</Typography>
            ) : null}
          </Grid>
        </Grid>
        {content ? (
          <Typography variant="h4">{content}</Typography>
        ) : (
          <Typography
            variant="h4"
            sx={{ fontStyle: 'italic', color: 'text.secondary' }}
          >
            Not set...
          </Typography>
        )}
      </Grid>
      {editOnClick ? (
        <Grid item>
          <Link variant="body2" onClick={editOnClick} underline="hover">
            {editLabel ?? `Edit ${title}`}
          </Link>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default GenericSettingsCardField;
