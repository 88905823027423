import { Card, CardContent, CardHeader } from 'app/design';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { AnnouncementOnly } from 'app/pages/admin/Vmboxes/View/components/VmboxAdditionalSettingsCard/components/AnnouncementOnly';
import { DisableUserChanges } from 'app/pages/admin/Vmboxes/View/components/VmboxAdditionalSettingsCard/components/DisableUserChanges';
import { RequirePin } from 'app/pages/admin/Vmboxes/View/components/VmboxAdditionalSettingsCard/components/RequirePin';
import { SkipGreeting } from 'app/pages/admin/Vmboxes/View/components/VmboxAdditionalSettingsCard/components/SkipGreeting';
import { SkipInstructions } from 'app/pages/admin/Vmboxes/View/components/VmboxAdditionalSettingsCard/components/SkipInstructions';
import { TranscribeVoicemails } from 'app/pages/admin/Vmboxes/View/components/VmboxAdditionalSettingsCard/components/TranscribeVoicemails';
import { DeleteAfterNotify } from 'app/pages/admin/Vmboxes/View/components/VmboxNotificationsSettingsCard/components/DeleteAfterNotify';
import { IncludeOnNotify } from 'app/pages/admin/Vmboxes/View/components/VmboxNotificationsSettingsCard/components/IncludeOnNotify';
import { NotificationEmails } from 'app/pages/admin/Vmboxes/View/components/VmboxNotificationsSettingsCard/components/NotificationEmails';
import { SaveAfterNotify } from 'app/pages/admin/Vmboxes/View/components/VmboxNotificationsSettingsCard/components/SaveAfterNotify';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { Vmbox } from 'types/vmbox';

interface VmboxSettingsCardProps {
  vmbox: Vmbox;
  onComplete: () => void;
}

const VmboxNotificationsSettingsCard = ({
  vmbox,
  onComplete,
}: VmboxSettingsCardProps) => {
  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'notificationsSettings',
    options: [
      {
        label: 'Notifications Settings',
        value: 'notificationsSettings',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  return (
    // @ts-ignore
    <Card ref={ref} variant="dashboard">
      <HighlightPulse {...highlightPulseProps} />
      <CardHeader title="Notification Settings" />
      <CardContent>
        <NotificationEmails vmbox={vmbox} onComplete={onComplete} />
        <br />
        <IncludeOnNotify vmbox={vmbox} />
        <br />
        <SaveAfterNotify vmbox={vmbox} />
        <br />
        <DeleteAfterNotify vmbox={vmbox} />
      </CardContent>
    </Card>
  );
};

export default VmboxNotificationsSettingsCard;
