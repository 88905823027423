import { sdk } from 'app/sdk';
import React from 'react';

const setupRingGroup = async ({ auth_token, userId }) => {
  const timeout = 20;
  const groupResult = await sdk.group.mutate.create(
    {
      name: 'All Users',
      // type: 'none',
      version: 1, // for if we change the type's version
      endpoints: {
        [userId]: {
          delay: 0,
          timeout,
          weight: 1,
        },
      },
      preEndpoints: [
        {
          id: userId,
          delay: 0,
          endpoint_type: 'user',
          timeout,
        },
      ], // gets built into "endpoints" depending on the useGroupFormat
      timeout,
      strategy: 'simultaneous', // is default
      type: 'simu',
    },
    auth_token,
  );
  console.log('groupResult:', groupResult);
  if (groupResult.status !== 'success') {
    throw 'Failed saving ringgroup';
  }
  const groupId = groupResult.data.id;
  return { groupId };
};

export default setupRingGroup;
