const useStyles = (props = {}) => ({
  call: {
    borderBottom: '1px solid #fcfcfc',
    padding: 12,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
});

export default useStyles;
