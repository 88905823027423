import React from 'react';
import { Backdrop, CircularProgress, Typography } from 'app/design';
import { DialogBuilder } from 'app/utilities';

interface CustomBackdropProps {
  label?: string;
}

const CustomBackdrop = ({ label }: CustomBackdropProps) => {
  return (
    <Backdrop
      className="TETSING123"
      sx={{
        color: '#fff',
        zIndex: theme => {
          console.log('theme.zIndex:', theme.zIndex.modal);
          return theme.zIndex.modal + 10;
        },
      }}
      open
    >
      <div style={{ display: 'grid', placeItems: 'center' }}>
        <CircularProgress color="inherit" />
        {label ? <Typography>{label}</Typography> : null}
      </div>
    </Backdrop>
  );
};

export const useCustomBackdrop = DialogBuilder(CustomBackdrop);

export default CustomBackdrop;
