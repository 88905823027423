import {
  Box,
  Button,
  DialogActions,
  Divider,
  Grid,
  Typography,
} from 'app/design';
import { ReactNode } from 'react';
import * as React from 'react';

interface QuickFinderDialogActionsProps {
  errorMessage?: ReactNode;
  multiple: boolean;
  allowSelectNone: boolean;
  onSelect: () => void;
  onCancel: () => void;
  onClear?: () => void;
  selectedIds: string[];
}

const QuickFinderDialogActions = ({
  errorMessage,
  multiple,
  allowSelectNone,
  onSelect,
  onCancel,
  onClear,
  selectedIds,
}: QuickFinderDialogActionsProps) => {
  return (
    <>
      <Divider />
      <DialogActions>
        <Grid
          container
          spacing={1}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Grid item>
            <Button onClick={onCancel} variant={'outlined'} color={'error'}>
              Cancel
            </Button>
          </Grid>
          {errorMessage ? (
            <Grid item sx={{ flex: 1 }}>
              <Box sx={{ display: 'grid', placeItems: 'center' }}>
                <Typography color={'error'}>{errorMessage}</Typography>
              </Box>
            </Grid>
          ) : null}
          {onClear ? (
            <Grid item>
              <Button
                onClick={onClear}
                variant={'outlined'}
                color={'secondary'}
              >
                Clear Selection
              </Button>
            </Grid>
          ) : null}
          {multiple ? (
            <Grid item>
              <Button
                onClick={onSelect}
                variant={'outlined'}
                color={'primary'}
                disabled={
                  !allowSelectNone && !selectedIds.length ? true : false
                }
              >
                {allowSelectNone && !selectedIds.length ? (
                  <>Select: None</>
                ) : (
                  <>
                    Select{' '}
                    {selectedIds.length > 0 ? `(${selectedIds.length})` : ''}
                  </>
                )}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </DialogActions>
    </>
  );
};

export default QuickFinderDialogActions;
