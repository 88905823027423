import { Card, CardContent, CardHeader } from 'app/design';
import { DetailsDialogDevice } from 'app/components/DetailsDialogDevice';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { DeviceProvisionerSettings } from 'app/pages/admin/Devices/View/components/DeviceProvisionerSettings';
import { DeviceCodecs } from 'app/pages/admin/Devices/View/components/DeviceSettingsCard/components/DeviceCodecs';
import { HoldMusicDevice } from 'app/pages/admin/Devices/View/components/DeviceSettingsCard/components/HoldMusicDevice';
import { NameDevice } from 'app/pages/admin/Devices/View/components/DeviceSettingsCard/components/NameDevice';
import { RestrictionsDevice } from 'app/pages/admin/Devices/View/components/DeviceSettingsCard/components/RestrictionsDevice';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { Device } from 'types/device';

interface DeviceSettingsCardProps {
  device: Device;
  onComplete: () => void;
}

const DeviceSettingsCard = ({
  device,
  onComplete,
}: DeviceSettingsCardProps) => {
  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'settings',
    options: [
      {
        label: 'Settings',
        value: 'settings',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const handleOnComplete = () => {
    onComplete();
    toggleShowEditDialog();
  };

  return (
    <>
      {showEditDialog ? (
        <DetailsDialogDevice
          deviceId={device.id}
          onCancel={toggleShowEditDialog}
          onComplete={handleOnComplete}
        />
      ) : null}
      {/*@ts-ignore*/}
      <Card ref={ref} variant="outlined" sx={{ height: '100%' }}>
        <HighlightPulse {...highlightPulseProps} />
        <CardHeader title="Device Settings" />
        <CardContent>
          <HoldMusicDevice device={device} onComplete={onComplete} />
          <br />
          <DeviceCodecs device={device} onComplete={onComplete} />
          <br />
          <RestrictionsDevice device={device} onComplete={onComplete} />
          <br />
          <NameDevice device={device} onComplete={onComplete} />
          {/* show combo keys / sidecar if physical device */}
          {device.doc.provision?.endpoint_brand ? (
            <>
              <br />
              <DeviceProvisionerSettings
                device={device}
                onComplete={onComplete}
              />
            </>
          ) : null}
          <br />
        </CardContent>
      </Card>
    </>
  );
};

export default DeviceSettingsCard;
