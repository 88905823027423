import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { AccountUpdatePartialDoc } from 'types/account';
import { DirectoryUpdatePartialDoc } from 'types/directory';

export const useUpdateDirectoryPartial = () => {
  const authState = useAuthSelector();
  return useMutation<any, AxiosError, DirectoryUpdatePartialDoc, unknown>(
    (directoryUpdatePartialDoc: DirectoryUpdatePartialDoc) =>
      sdk.directory.mutate.updatePartial(
        directoryUpdatePartialDoc,
        authState?.auth_token,
      ),
  );
};
