import React, { useCallback, useState, useEffect } from 'react';
import * as SmartySDK from 'smartystreets-javascript-sdk';

import { Grid, TextField, Autocomplete, Typography, Tooltip } from 'app/design';
import {
  LocationOn as LocationOnIcon,
  PersonOutline as PersonOutlineIcon,
  VideocamOutlined as VideocamOutlinedIcon,
  Language as LanguageIcon,
  AirlineStops as AirlineStopsIcon,
  Tag as TagIcon,
} from 'app/design/icons-material';
// import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import _ from 'lodash';
import { useThrottle, useThrottleFn } from 'react-use';

export default class AddressAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    const SmartyCore = SmartySDK.core;
    const websiteKey = props.websiteKey;
    const smartySharedCredentials = new SmartyCore.SharedCredentials(
      websiteKey,
    );
    const autoCompleteClientBuilder = new SmartyCore.ClientBuilder(
      smartySharedCredentials,
    ).withLicenses(['us-autocomplete-pro-cloud']);
    const usStreetClientBuilder = new SmartyCore.ClientBuilder(
      smartySharedCredentials,
    );

    // @ts-ignore
    this.SmartyCore = SmartyCore;
    // @ts-ignore
    this.autoCompleteClient =
      autoCompleteClientBuilder.buildUsAutocompleteProClient();
    // @ts-ignore
    this.usStreetClient = usStreetClientBuilder.buildUsStreetApiClient();
  }

  render() {
    return (
      <AutocompleteBox
        // @ts-ignore
        SmartyCore={this.SmartyCore}
        // @ts-ignore
        autoCompleteClient={this.autoCompleteClient}
        // @ts-ignore
        usStreetClient={this.usStreetClient}
        {...this.props}
      />
    );
  }
}

export const AutocompleteBox = props => {
  const {
    SmartyCore,
    autoCompleteClient,
    usStreetClient,
    autocompleteProps = {},
    textfieldProps = {},
    onChange = () => {},
  } = props;

  const [options, setOptions] = useState<any[]>([]);
  const [value, setValue] = useState<any>(null);
  const [inputValue, setInputValue] = useState('');

  const getAndSetSuggestions = (query, entries) => {
    const lookup = new SmartySDK.usAutocompletePro.Lookup(query);
    if (entries) {
      lookup.selected = query;
    }
    // console.log('lookup:', lookup);

    autoCompleteClient
      .send(lookup)
      .then(results => {
        // this.setState({ suggestions: results });
        // console.log('RESULTS:', results);
        const opts = results.result.map(result => ({
          label: formatAutocompleteSuggestionDisplay(result),
          searchval: formatAutocompleteSuggestion(result),
          value: result,
        }));
        setOptions(opts);
      })
      .catch(console.warn);
  };

  const throttledValue = useThrottle(inputValue, 350);
  useEffect(() => {
    if (!throttledValue.length) {
      return;
    }
    // @ts-ignore
    getAndSetSuggestions(throttledValue);
  }, [throttledValue]);

  return (
    <div>
      <Autocomplete
        autoComplete
        // open={null}
        // includeInputInList
        filterSelectedOptions
        // disableCloseOnSelect
        openOnFocus={false}
        selectOnFocus={false}
        filterOptions={x => x}
        options={options}
        getOptionLabel={(option: any) =>
          typeof option === 'string' ? option : option.label
        }
        onInputChange={(event, newInputValue, reason) => {
          if (reason === 'reset') {
            console.log(
              'skipping inputchange trigger on reset cuz selecting more (i think)',
            );
            return;
          }
          setInputValue(newInputValue);
        }}
        value={value}
        onChange={(event, newValue) => {
          console.log('newValue:', newValue);
          // if (newValue?.value?.entries > 1) {
          //   // disable close on select
          //   getAndSetSuggestions(newValue.searchval, true);
          //   return false;
          // }
          // setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          onChange && onChange(newValue);
          // close on select
        }}
        renderInput={params => (
          <TextField
            {...params}
            // inputRef={inputRef}
            variant="outlined"
            // // label={props.hasOwnProperty('label') ? props.label : 'Conference'}
            // placeholder={
            //   props.hasOwnProperty('placeholder')
            //     ? props.placeholder
            //     : 'Type conference name'
            // }
            InputLabelProps={{
              shrink: true,
            }}
            // onFocus={setFocusWrap(true)}
            // onBlur={setFocusWrap(false)}
            {...textfieldProps}
          />
        )}
        renderOption={(props, option: any, x) => {
          return (
            <li
              {...props}
              key={option.label}
              onClick={(...args) => {
                if (option?.value?.entries > 1) {
                  // disable close on select
                  getAndSetSuggestions(option.searchval, true);
                  return false;
                } else {
                  // @ts-ignore
                  props.onClick(...args);
                }
              }}
            >
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs>
                  {option.label}
                </Grid>
              </Grid>
            </li>
          );
        }}
        // freeSolo
        {...autocompleteProps}
      />
    </div>
  );
};

const formatAutocompleteSuggestion = suggestion => {
  const street = suggestion.streetLine ? `${suggestion.streetLine} ` : '';
  const secondary = suggestion?.secondary ? `${suggestion.secondary} ` : '';
  const entries = suggestion?.entries > 1 ? `(${suggestion.entries}) ` : '';
  const city = suggestion?.city ? `${suggestion.city} ` : '';
  const state = suggestion?.state ? `${suggestion.state}, ` : '';
  const zip = suggestion?.zipcode ? `${suggestion.zipcode}` : '';

  return street + secondary + entries + city + state + zip;
};

const formatAutocompleteSuggestionDisplay = suggestion => {
  const street = suggestion.streetLine ? `${suggestion.streetLine} ` : '';
  const secondary = suggestion?.secondary ? `${suggestion.secondary} ` : '';
  const entries =
    suggestion?.entries > 1 ? `(${suggestion.entries} entries) ` : '';
  const city = suggestion?.city ? `${suggestion.city} ` : '';
  const state = suggestion?.state ? `${suggestion.state}, ` : '';
  const zip = suggestion?.zipcode ? `${suggestion.zipcode}` : '';

  return street + secondary + entries + city + state + zip;
};
// export default AutocompleteBox;
