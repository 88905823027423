import * as React from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Paper,
  Link,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from 'app/design';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from 'app/design/icons-material';
import {
  useToggleReducer,
  DialogBuilder,
  parseAndSetKazooMutationErrors,
} from 'app/utilities';
import Joi from 'joi';
import { pickBy } from 'lodash';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useImmer } from 'use-immer';

import copy from 'copy-to-clipboard';

import { useCreditCardAddDialog } from 'app/components/CreditCardAddDialog';

interface CreditCardRequiredDialogProps {
  onCancel?: () => void;
  onComplete?: () => void;
  startDirty?: boolean;
}

const CreditCardRequiredDialog = ({
  onCancel,
  onComplete,
  // onSubmit,
  // errorMessage,
  startDirty = false,
}: CreditCardRequiredDialogProps) => {
  const {
    toggleOpen: toggleOpenCreditCardAddDialog,
    Dialog: CreditCardAddDialog,
    DialogProps: CreditCardAddDialogProps,
  } = useCreditCardAddDialog({ initialOpen: false });

  return (
    <>
      <CreditCardAddDialog
        {...CreditCardAddDialogProps}
        onCancel={onCancel}
        onComplete={onComplete}
      />
      <Dialog open={true} fullWidth maxWidth={'sm'} onClose={onCancel}>
        <DialogContent>
          <div>
            A credit card is required
            <br />
            <Button variant="contained" onClick={toggleOpenCreditCardAddDialog}>
              Add a Credit Card
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const useCreditCardRequiredDialog = DialogBuilder(
  CreditCardRequiredDialog,
);

export default CreditCardRequiredDialog;
