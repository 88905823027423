import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from 'app/design';
import { Phone as PhoneIcon } from 'app/design/icons-material';
import { NodeInfoDisplay } from 'app/components/IvrBuilder/Flow/nodes/common/NodeInfoDisplay';

import { QuickFinderDeviceDialog } from 'app/components/QuickFinderDeviceDialog';
import { useDeviceQuery } from 'app/hooks/queries/device';
import { DeviceCallerIdCard } from 'app/pages/admin/Devices/View/components/DeviceCallerIdCard';
import { DeviceSettingsCard } from 'app/pages/admin/Devices/View/components/DeviceSettingsCard';
import { getAtPath, setAtPath, useToggleReducer } from 'app/utilities';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { NodeEditDialog } from '../../IvrBuilder/Dialogs';
import { ExpandLess as ExpandLessIcon } from 'app/design/icons-material';
import { Link as RouterLink } from 'react-router-dom';

// import Select from 'react-select';

// Select devices from list of devices
export const Device = props => {
  const {
    skipEditing,
    modifyPath,
    callflow,
    setCallflow,
    variant,
    showModifyDialog,
    setShowModifyDialog,
  } = props;

  const dataModifyPath = `${modifyPath}.data`;

  const moduleItem = getAtPath(callflow, modifyPath, {}); // { ring_type: 'custom', data: {...} }
  const ringItem = moduleItem?.data;

  // console.log('moduleItem:', moduleItem);
  /*
  moduleItem = {
    type: 'RingStrategy',
    data: {
      ring_type: 'device',
      data: {
        deviceId: 'xyz',
        timeout: 10
      }
    }
  }
  */

  const {
    data: device,
    isLoading: deviceIsLoading,
    error: deviceError,
    refetch: refetchDevice,
  } = useDeviceQuery(ringItem?.data?.id);

  // console.log('ring device:', device);

  const [isModifying, setIsModifying] = useState(null);

  useEffect(() => {
    if (setShowModifyDialog) {
      // parent is managing "display" state, NOT this component
      // setShowModifyDialog()
      setIsModifying(showModifyDialog);
    }
  }, [showModifyDialog]);

  const handleUpdate = compData => {
    setAtPath(callflow, `${dataModifyPath}`, compData);
    setCallflow({ ...callflow });
  };

  const handleShowModifying = value =>
    setShowModifyDialog ? setShowModifyDialog(value) : setIsModifying(value);

  if (variant === 'ivr_builder') {
    return (
      <>
        {isModifying && (
          <ModifyRingSettings
            ringInfo={ringItem}
            onChangeRingInfo={handleUpdate}
            onClose={e => handleShowModifying(null)}
          />
        )}
        <NodeInfoDisplay
          icon={<PhoneIcon />}
          type={'Ring Device'}
          title={
            device ? (
              device.doc?.name
            ) : skipEditing ? (
              'None Selected'
            ) : (
              <Link onClick={() => handleShowModifying(true)}>
                Select Device
              </Link>
            )
          }
          footer={
            ringItem?.data?.timeout &&
            device &&
            `${ringItem?.data?.timeout} seconds`
          }
        />
      </>
    );
  }

  return (
    <>
      {isModifying && (
        <ModifyRingSettings
          ringInfo={ringItem}
          onChangeRingInfo={handleUpdate}
          onClose={e => handleShowModifying(null)}
        />
      )}
      <Paper variant="outlined" elevation={0}>
        {device ? (
          <>
            <Typography variant="body1">
              Ringing <strong>{device.doc.name}</strong>
            </Typography>
            <Typography variant="subtitle1">
              {ringItem?.data?.timeout} seconds
            </Typography>
          </>
        ) : (
          <Typography variant="body1" style={{ fontStyle: 'italic' }}>
            No device chosen for ringing
          </Typography>
        )}
        <div>
          <Link onClick={e => handleShowModifying(true)}>Modify</Link>
        </div>
      </Paper>
    </>
  );
};

const timeoutDefault = 20;

const ModifyRingSettings = props => {
  const { ringInfo, onChangeRingInfo, onClose } = props;

  const [modifyData, setModifyData] = useState(() => cloneDeep(ringInfo));

  const deviceId = modifyData?.data?.id;

  const {
    data: device,
    isLoading: deviceIsLoading,
    error: deviceError,
    refetch: refetchDevice,
  } = useDeviceQuery(deviceId);

  // defaults
  useEffect(() => {
    // timeout
    if (!getAtPath(modifyData, `data.timeout`)) {
      setAtPath(modifyData, `data.timeout`, timeoutDefault);
      setModifyData({ ...modifyData });
    }
  }, [modifyData, setModifyData]);

  const [initialState] = useState({
    id: ringInfo?.data?.id,
    timeout: ringInfo?.data?.timeout ?? timeoutDefault,
  });

  const saveLabel = !deviceId
    ? 'Leave Empty'
    : !initialState.id
    ? 'Add'
    : initialState.id === deviceId &&
      Number(initialState.timeout) === Number(modifyData?.data?.timeout)
    ? 'Done'
    : 'Update';

  // Finder Dialog
  const [showFinderDialog, toggleShowFindDeviceDialog] = useToggleReducer(
    !deviceId,
  );

  const onChangeDeviceSelect = selected => {
    const [deviceId] = selected;
    setAtPath(modifyData, `data.id`, deviceId); // newValue?.value === id
    setModifyData({ ...modifyData });
    toggleShowFindDeviceDialog();
  };

  const handleClear = () => {
    setAtPath(modifyData, `data.id`, null); // newValue?.value === id
    setModifyData({ ...modifyData });
    toggleShowFindDeviceDialog();
  };

  const [showAllSettings, toggleShowAllSettings] = useToggleReducer(false);

  return (
    <>
      <NodeEditDialog
        title={'Edit Phone Ringing'}
        maxWidth={showAllSettings ? 'md' : 'sm'}
        onClose={onClose}
        onCancel={onClose}
        onSave={() => {
          onChangeRingInfo(modifyData);
          onClose();
        }}
        titleButton={
          device ? (
            <Button color="primary" onClick={toggleShowFindDeviceDialog}>
              Change Phone
            </Button>
          ) : null
        }
        saveLabel={saveLabel}
        hideCancel={saveLabel === 'Done'}
        isLoading={deviceIsLoading}
        errorMessage={deviceError ? 'Failed loading selected phone' : undefined}
      >
        {device ? (
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <Typography variant={'h4'}>Phone Settings</Typography>
              <Divider />
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <DeviceSettings
                    {...props}
                    modifyData={modifyData}
                    setModifyData={setModifyData}
                    device={device}
                    onComplete={refetchDevice}
                  />
                </Grid>
                {showAllSettings ? (
                  <Grid item>
                    <Button onClick={toggleShowAllSettings}>
                      <ExpandLessIcon />
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
              {showAllSettings ? (
                <>
                  <Box sx={{ paddingX: 2 }}>
                    <DeviceSettingsCard
                      onComplete={refetchDevice}
                      device={device}
                    />
                    <br />
                    <DeviceCallerIdCard
                      device={device}
                      onComplete={refetchDevice}
                    />
                  </Box>
                  <br />
                  <Divider />
                </>
              ) : null}
              <br />
              <Link underline={'hover'} onClick={toggleShowAllSettings}>
                {showAllSettings
                  ? 'Hide additional settings'
                  : 'Show all settings'}
              </Link>
            </Grid>
            <Grid item xs={5}>
              <Typography variant={'h4'}>Ring Settings</Typography>
              <Divider />
              <RingSettings
                {...props}
                modifyData={modifyData}
                setModifyData={setModifyData}
              />
            </Grid>
          </Grid>
        ) : (
          <>
            <Typography color={'gray'} sx={{ fontStyle: 'italic' }}>
              No Phone Selected...
            </Typography>
            <Button onClick={toggleShowFindDeviceDialog}>Choose Phone</Button>
          </>
        )}
      </NodeEditDialog>
      {showFinderDialog ? (
        <QuickFinderDeviceDialog
          onSelect={onChangeDeviceSelect}
          onCancel={toggleShowFindDeviceDialog}
          onClear={handleClear}
          initialSelected={deviceId ? [deviceId] : []}
          selectionTitle={device ? `${device.doc.name}` : null}
        />
      ) : null}
    </>
  );
};

const DeviceSettings = ({ modifyData, setModifyData, device, onComplete }) => {
  const deviceId = modifyData?.data?.id;
  console.log('DeviceId:', deviceId, modifyData);

  return (
    <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
      <Link
        variant="h1"
        to={`/admin/devices/view/${deviceId}`}
        underline="hover"
        component={RouterLink}
        color={'text.primary'}
      >{`${device.doc.name}`}</Link>
    </Box>
  );
};

const RingSettings = ({ modifyData, setModifyData }) => {
  const handleChangeText = e => {
    setAtPath(modifyData, `data.timeout`, e.target.value); // newValue?.value === id
    setModifyData({ ...modifyData });
  };
  return (
    <Box sx={{ marginTop: 2 }}>
      <div>
        <TextField
          label="Ring Duration (seconds)"
          value={modifyData?.data?.timeout}
          onChange={handleChangeText}
          type="number"
          inputProps={{
            min: 0,
          }}
          size="small"
        />
      </div>
    </Box>
  );
};

export default Device;
