import React, { useEffect, useState } from 'react';
import { isFunction } from 'lodash';

const useNavCrumbBuilder = (input: any, deps: any[] = []) => {
  let fn = input;
  if (!isFunction(input)) {
    fn = () => input;
  }
  const [crumbs, setCrumbs] = useState(() => fn(deps));
  useEffect(() => {
    setCrumbs(fn(deps));
  }, deps);

  return crumbs;
};

export default useNavCrumbBuilder;
