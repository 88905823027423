import useScrollTrigger from '@mui/material/useScrollTrigger';

import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Menu,
  MenuItem,
  ThemeProvider,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from 'app/design';
import {
  ExpandMore as ExpandMoreIcon,
  SupportAgent as SupportAgentIcon,
  Dashboard as DashboardIcon,
  Business as BusinessIcon,
  Upgrade as UpgradeIcon,
  Logout as LogoutIcon,
} from 'app/design/icons-material';
import { useBillingDialog } from 'app/components/BillingDialog';

import { AvatarCell } from 'app/components/ListUsers/components/AvatarCell';

import { OmniSearchWidget } from 'app/components/OmniSearchWidget';
import { usePlanChangeDialog } from 'app/components/PlanChangeDialog';

import { useTrialDialog } from 'app/components/TrialDialog';

import constants from 'app/constants';
import { useAuthSlice } from 'app/data/auth';
import { useLocalSelector } from 'app/data/local';
import { useAccountQuery } from 'app/hooks/queries/account';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { cloneDeep, merge } from 'lodash';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { UserPrivLevel } from 'types/user';
import { MobileAppButton } from './MobileAppButton';
import { PinnedButton } from './PinnedButton';
import { WebphoneButton } from './WebphoneButton';
import { WebphoneContainer } from './WebphoneContainer';

const { PLANS } = constants;

function Header() {
  const dispatch = useDispatch();
  const { actions } = useAuthSlice();
  const history = useHistory();

  // useWebsocketBinding(
  //   [
  //     'call.CHANNEL_CREATE.*',
  //     'call.CHANNEL_ANSWER.*',
  //     'call.CHANNEL_DESTROY.*',
  //     'call.CHANNEL_HOLD.*',
  //     'call.CHANNEL_UNHOLD.*',
  //     'call.CHANNEL_BRIDGE.*',
  //     'call.PARK_PARKED.*',
  //     'call.PARK_RETRIEVED.*',
  //     // 'call.PARK_ABANDONED.*',
  //   ],
  //   event => {
  //     // updateChannels();
  //   },
  // );

  const {
    data: userData,
    isLoading: userIsLoading,
    error: userError,
  } = useAuthenticatedUserQuery();

  // const {
  //   toggleOpen: toggleOpenTrialDialog,
  //   TrialDialog,
  //   TrialDialogProps,
  // } = useTrialDialog({});
  // const {
  //   toggleOpen: toggleOpenPlanChangeDialog,
  //   Dialog: PlanChangeDialog,
  //   DialogProps: PlanChangeDialogProps,
  // } = usePlanChangeDialog({});
  // const {
  //   toggleOpen: toggleOpenBillingDialog,
  //   BillingDialog,
  //   BillingDialogProps,
  // } = useBillingDialog({});
  const { data: account, isLoading: accountIsLoading } = useAccountQuery();

  const { nav_breadcrumbs } = useLocalSelector();
  const crumbs = nav_breadcrumbs.length ? nav_breadcrumbs[0] : [];
  // console.log('nav_breadcrumbs:', nav_breadcrumbs, crumbs);
  // auth error will trigger logout in global handling - App.tsx, only error case we should log out?

  const drawerWidth = 240;
  const headerLeftPadding = 24;

  // @ts-ignore
  // const plan = PLANS.find(p => p.id === account?.doc?.plan_id) ?? PLANS[0];

  const theme = useTheme();
  const isMediaQueryMd = useMediaQuery(theme.breakpoints.up('md'));
  const { sidebar_mini } = useLocalSelector();
  const sidebarCollapsed =
    sidebar_mini === null ? !isMediaQueryMd : sidebar_mini;
  const headerMini = !isMediaQueryMd;

  return (
    <>
      <WebphoneContainer />
      <ThemeProvider
        theme={outerTheme => ({
          ...outerTheme,
          shadows: [
            // @ts-ignore
            ...outerTheme.shadows.slice(0, 4),
            '0px 4px 3px 0px rgba(27, 125, 144, 0.07)',
            // @ts-ignore
            ...outerTheme.shadows.slice(5),
          ],
        })}
      >
        <ElevationScroll>
          <AppBar
            position="fixed"
            sx={{
              // zIndex: theme => theme.zIndex.drawer + 1, // under Sidebar, or above it?
              // @ts-ignore
              backgroundColor: theme => theme.palette.content.background,
            }}
          >
            <Toolbar variant="dense" sx={{ color: '#333' }}>
              <Grid
                container
                columnSpacing={2}
                alignItems="center"
                wrap="nowrap"
                sx={{ paddingLeft: sidebarCollapsed ? '50px' : '240px' }}
              >
                {/* <Grid item sx={{ width: drawerWidth - headerLeftPadding }}>
                <Link
                  variant="h6"
                  noWrap
                  component={RouterLink}
                  color="inherit"
                  underline="none"
                  to="/"
                >
                  CallingIO
                </Link>
              </Grid> */}
                {/* <Grid item sx={{ flex: 1 }} zeroMinWidth>
                <ThemeProvider
                // @ts-ignore
                theme={outerTheme => createTheme(buildThemeWithMode('dark'))}
              >
                <Breadcrumbs
                  maxItems={2}
                  separator={'›'}
                  sx={{
                    ol: {
                      flexWrap: 'nowrap',
                      whiteSpace: 'nowrap',
                      overflowX: 'hidden',
                    },
                  }}
                >
                  {crumbs.map((crumb, idx) => {
                    if (typeof crumb === 'string') {
                      return (
                        <Typography
                          key={idx}
                          underline="none"
                          color="white"
                          variant="h3"
                        >
                          {crumb}
                        </Typography>
                      );
                    }
                    return (
                      <Typography
                        key={idx}
                        underline="hover"
                        color="white"
                        variant="h3"
                        sx={{
                          cursor: 'pointer',
                          '&:hover': { textDecoration: 'underline' },
                        }}
                        onClick={() => history.push(crumb.link)}
                      >
                        {crumb.name}
                      </Typography>
                    );
                  })}
                </Breadcrumbs>
              </ThemeProvider>
              </Grid> */}
                {userData?.doc?.priv_level === UserPrivLevel.Admin ? (
                  <Grid item sx={{}}>
                    <ThemeProvider
                      // @ts-ignore
                      theme={outerTheme => {
                        return merge(cloneDeep(outerTheme), {
                          components: {
                            MuiInputAdornment: {
                              styleOverrides: {
                                root: {
                                  // @ts-ignore
                                  color: `${outerTheme.palette.primary.dark} !important`,
                                },
                              },
                            },
                            MuiAutocomplete: {
                              styleOverrides: {
                                input: {
                                  width: '100%',
                                  // color: 'white !important',
                                  '&::placeholder': {
                                    textOverflow: 'ellipsis',
                                    color: 'rgba(15, 97, 113, 0.53)',
                                    opacity: 1,
                                  },
                                  color: 'rgba(15, 97, 113, 0.53)',
                                },
                                endAdornment: {
                                  opacity: 0,
                                },
                                // groupLabel: {
                                //   fontStyle: 'italic',
                                //   fontWeight: 700,
                                //   borderTop: '1px solid #eee',
                                //   borderBottom: '1px solid #fafafa',
                                // },
                                listbox: {
                                  maxHeight: '80vh',
                                },
                              },
                            },
                            MuiOutlinedInput: {
                              styleOverrides: {
                                root: {
                                  padding: '4px !important',
                                  background: 'white',
                                },
                                notchedOutline: {
                                  // borderColor: 'white !important',
                                  borderColor:
                                    'rgba(27, 125, 144, 0.18) !important',
                                  borderWidth: '1px !important',
                                },
                              },
                            },
                          },
                        });
                      }}
                    >
                      <OmniSearchWidget />
                    </ThemeProvider>
                  </Grid>
                ) : null}
                <Grid item>
                  <WebphoneButton />
                </Grid>
                <Grid item sx={{ flex: 1 }}></Grid>
                {process.env.NODE_ENV === 'development' &&
                userData?.doc?.priv_level === UserPrivLevel.Admin ? (
                  <Grid item>
                    <PinnedButton />
                  </Grid>
                ) : (
                  ''
                )}
                {/* {userData?.doc?.cio_allow_appphone ? ( */}
                <Grid item>
                  <MobileAppButton />
                </Grid>
                {/* ) : (
                  ''
                )} */}
                <Grid item>
                  <Button
                    component={Link}
                    href={'https://support.voiplabscorp.com/hc/en-us'}
                    target="_blank"
                    startIcon={headerMini ? '' : <SupportAgentIcon />}
                  >
                    {headerMini ? <SupportAgentIcon /> : 'Help'}
                  </Button>
                </Grid>
                {/* @ts-ignore */}
                {/*
                {userData?.doc?.priv_level === UserPrivLevel.Admin &&
                plan.canUpgrade ? (
                  <Grid item>
                    <TrialDialog {...TrialDialogProps} />
                    <PlanChangeDialog
                      {...PlanChangeDialogProps}
                      onComplete={toggleOpenPlanChangeDialog}
                    />
                    <Button
                      // size="large"
                      color="info"
                      variant="contained"
                      // onClick={toggleOpenTrialDialog}
                      onClick={toggleOpenPlanChangeDialog}
                      // sx={{ color: '#121212' }}
                    >
                      Upgrade
                    </Button>
                  </Grid>
                ) : null}
                */}
                {/*  TODO: current monthly accrued charges */}
                {/* @ts-ignore */}
                {/* {account?.doc?.trial_complete ? (
              <Grid item>
                <BillingDialog {...BillingDialogProps} />
                <ButtonGroup disableElevation>
                  <Button
                    size="small"
                    color="white"
                    variant="contained"
                    sx={{ color: '#121212' }}
                    onClick={toggleOpenBillingDialog}
                  >
                    $21.50
                  </Button>
                </ButtonGroup>
              </Grid>
            ) : null} */}
                <Grid item>
                  <AccountMenu
                    userData={userData}
                    account={account}
                    headerMini={headerMini}
                  />
                </Grid>
                {/* {userData?.extra?.fullName ? (
              <Grid item>
                <Link
                  component={RouterLink}
                  variant={'body1'}
                  to={`/admin/users/view/${userData.id}`}
                  sx={{ color: 'white !important' }}
                >
                  {userData?.extra?.fullName}
                </Link>
              </Grid>
            ) : null}
            <Grid item>
              <Link
                component={RouterLink}
                to="/logout"
                variant="body2"
                sx={{ color: 'white !important' }}
              >
                (Logout)
              </Link>
            </Grid> */}
              </Grid>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
      </ThemeProvider>
    </>
  );
}

interface ElevationScrollProps {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function ElevationScroll(props: ElevationScrollProps) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

function AccountMenu({ userData, account, headerMini }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let isAdminUrl =
    window.location.pathname.indexOf('/admin') > -1 ? true : false;

  const {
    toggleOpen: toggleOpenTrialDialog,
    TrialDialog,
    TrialDialogProps,
  } = useTrialDialog({});
  const {
    toggleOpen: toggleOpenPlanChangeDialog,
    Dialog: PlanChangeDialog,
    DialogProps: PlanChangeDialogProps,
  } = usePlanChangeDialog({});
  // @ts-ignore
  const plan = PLANS.find(p => p.id === account?.doc?.plan_id) ?? PLANS[0];

  return (
    <React.Fragment>
      <TrialDialog {...TrialDialogProps} />
      <PlanChangeDialog
        {...PlanChangeDialogProps}
        onComplete={toggleOpenPlanChangeDialog}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Button
          onClick={handleClick}
          startIcon={
            <AvatarCell
              resource={userData}
              size={24}
              sx={{ fontSize: '11px !important' }}
            />
            // <Avatar sx={{ width: 24, height: 24 }}>
            //   {userData?.doc?.first_name?.charAt(0) ?? ''}
            //   {userData?.doc?.last_name?.charAt(0) ?? ''}
            // </Avatar>
          }
          endIcon={<ExpandMoreIcon />}
          variant="outlined"
        >
          {headerMini ? '' : userData?.doc?.first_name ?? ''}
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{ dense: true }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            // arrow
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* <MenuItem>
          <Avatar /> Profile
        </MenuItem> */}

        {/* Show "Switch to Admin" only for Admins */}
        {userData?.doc?.priv_level === UserPrivLevel.Admin ? (
          <>
            {isAdminUrl ? (
              <MenuItem component={RouterLink} to="/office/dashboard">
                <Grid container columnSpacing={1} alignItems={'center'}>
                  <Grid item sx={{ mt: 0.5 }}>
                    <Typography
                      sx={{
                        '.MuiSvgIcon-root': {
                          fontSize: '16px',
                          color: '#1B7D90',
                        },
                      }}
                    >
                      <DashboardIcon
                        sx={{
                          '.MuiSvgIcon-root': {
                            fontSize: '16px',
                            color: '#1B7D90',
                          },
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item>Switch To Personal Office</Grid>
                </Grid>
              </MenuItem>
            ) : (
              <MenuItem
                component={RouterLink}
                to="/admin/dashboard"
                // sx={{ fontWeight: 'bold' }}
              >
                <Grid container columnSpacing={1} alignItems={'center'}>
                  <Grid item sx={{ mt: 0.5 }}>
                    <Typography
                      sx={{
                        '.MuiSvgIcon-root': {
                          fontSize: '16px',
                          color: '#1B7D90',
                        },
                      }}
                    >
                      <BusinessIcon
                        sx={{
                          '.MuiSvgIcon-root': {
                            fontSize: '16px',
                            color: '#1B7D90',
                          },
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item>Switch To Admin</Grid>
                </Grid>
              </MenuItem>
            )}
          </>
        ) : null}
        <Divider />
        {userData?.doc?.priv_level === UserPrivLevel.Admin &&
        plan.canUpgrade ? (
          <>
            <MenuItem
              onClick={toggleOpenPlanChangeDialog}
              sx={{ fontWeight: 'bold', color: 'primary.main' }}
            >
              <Grid container columnSpacing={1} alignItems={'center'}>
                <Grid item>
                  <Typography
                    sx={{
                      '.MuiSvgIcon-root': {
                        fontSize: '16px',
                        color: '#1B7D90',
                      },
                    }}
                  >
                    <UpgradeIcon
                      sx={{
                        '.MuiSvgIcon-root': {
                          fontSize: '16px',
                          color: '#1B7D90',
                        },
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item>Upgrade Account</Grid>
              </Grid>
            </MenuItem>
            <Divider />
          </>
        ) : null}
        <MenuItem component={RouterLink} to="/logout">
          <Grid container columnSpacing={1} alignItems={'center'}>
            <Grid item>
              <Typography
                sx={{
                  '.MuiSvgIcon-root': {
                    fontSize: '16px',
                    color: '#1B7D90',
                  },
                }}
              >
                <LogoutIcon
                  sx={{
                    '.MuiSvgIcon-root': {
                      fontSize: '16px',
                      color: '#1B7D90',
                    },
                  }}
                />
              </Typography>
            </Grid>
            <Grid item>Logout</Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default Header;
