import { Card, CardContent, CardHeader } from 'app/design';
import { DetailsDialogGroup } from 'app/components/DetailsDialogGroup';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { Group } from 'types/group';

interface GroupNameProps {
  group: Group;
  onComplete: () => void;
}

const GroupName = ({ group, onComplete }: GroupNameProps) => {
  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'name',
    options: [
      {
        label: 'Name',
        value: 'name',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const handleOnComplete = () => {
    onComplete();
    toggleShowEditDialog();
  };

  return (
    // @ts-ignore
    <div ref={ref}>
      <HighlightPulse {...highlightPulseProps} />
      {showEditDialog ? (
        <DetailsDialogGroup
          groupId={group.id}
          onCancel={toggleShowEditDialog}
          onComplete={handleOnComplete}
        />
      ) : null}
      <GenericSettingsCardField
        title={'Group Name'}
        content={group.doc.name}
        editOnClick={toggleShowEditDialog}
      />
    </div>
  );
};

export default GroupName;
