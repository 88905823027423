import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from 'app/design';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from 'app/design/icons-material';
import { useCreateGroup } from 'app/hooks/mutations/group';
import { useToggleReducer } from 'app/utilities';
import Joi from 'joi';
import { pickBy } from 'lodash';
import { useEffect } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Group } from 'types/group';
import { useImmer } from 'use-immer';

// schema for form validation. Passed to useForm to only trigger submit when
//  - the below conditions are met. Any known serverside constraints (min/max,
//  - character limits, numbers only, etc.) should be added. Remove 128 max below
//  - to demonstrate serverside invalidation and handling
const schema = Joi.object({
  name: Joi.string().max(128).required(),
});

interface GroupCreateForm {
  name: string;
}

// interface declaring which props are required/allowed
interface GroupCreateDialogProps {
  // groupId: string;
  onCancel: () => void;
  onComplete?: (groupId: string) => void;
  navigateOnComplete?: boolean;
}

const GroupCreateDialog = ({
  // groupId,
  onCancel,
  onComplete,
  navigateOnComplete = true,
}: GroupCreateDialogProps) => {
  // const {
  //   data: group,
  //   isLoading: groupIsLoading,
  //   refetch: refetchGroup,
  // } = useGroupQuery(groupId, {
  //   onSuccess: group => {
  //     setFormDefaults({
  //       name: '',
  //     });
  //   },
  // });
  const router = useHistory();
  const createGroup = useCreateGroup();
  const [formDefaults, setFormDefaults] = useImmer<GroupCreateForm>({
    name: '',
  });

  const handleSubmit = async (groupForm: GroupCreateForm) => {
    // update form defaults if mutation fails
    setFormDefaults(groupForm);

    // "sanitize" and remove any unset values (empty strings)
    // - so that they are not sent to server.
    // - ex: An empty string would override the existing password(?)
    // - no current way to unset password
    const sanitizedFormData = pickBy(groupForm, value => !!value?.length);

    const createGroupPromise = createGroup.mutateAsync({
      name: sanitizedFormData.name ?? '',
    });

    toast.promise(createGroupPromise, {
      pending: 'Creating group...',
      success: 'Group created!',
      error: 'Error creating group.',
    });

    const resp = await createGroupPromise;
    if (resp.status === 'success') {
      if (onComplete) onComplete(resp.data.id);

      // redirect to Group
      if (navigateOnComplete) router.push(`/admin/groups/view/${resp.data.id}`);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Dialog open={true} fullWidth maxWidth={'sm'}>
      {createGroup.isLoading ? (
        <DialogInProgress title={'Creating Group...'} />
      ) : (
        <>
          <DialogTitle>Create Group</DialogTitle>
          <Divider />
          <GroupCreateDialogForm
            formDefaults={formDefaults}
            startDirty={createGroup.isError}
            onCancel={onCancel}
            onSubmit={handleSubmit}
            errorMessage={
              // @ts-ignore
              createGroup.error
                ? // @ts-ignore
                  `${createGroup.error.message} ${
                    // @ts-ignore
                    createGroup.error.response
                      ? // @ts-ignore
                        `: ${createGroup.error.response?.statusText}`
                      : ''
                  }`
                : undefined
            }
          />
        </>
      )}
    </Dialog>
  );
};

interface GroupCreateDialogFormProps {
  formDefaults: GroupCreateForm;
  onCancel: () => void;
  onSubmit: (form: GroupCreateForm) => void;
  errorMessage?: string;
  startDirty?: boolean;
}

const GroupCreateDialogForm = ({
  formDefaults,
  onCancel,
  onSubmit,
  errorMessage,
  startDirty = false,
}: GroupCreateDialogFormProps) => {
  const [showPassword, toggleShowPassword] = useToggleReducer(false);

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty },
    setError,
    watch,
  } = useForm<GroupCreateForm>({
    defaultValues: formDefaults,
    resolver: joiResolver(schema), // pass in custom validation
  });

  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <TextField
              label={'Name'}
              {...register('name')}
              error={!!formErrors.name}
              helperText={formErrors.name?.message?.replace('"name"', 'Name')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button variant={'outlined'} color={'error'} onClick={onCancel}>
          Cancel
        </Button>
        {errorMessage ? (
          <Typography color={'error'}>{errorMessage}</Typography>
        ) : null}
        <Button
          variant={'outlined'}
          color={'success'}
          disabled={startDirty ? !startDirty : !isDirty}
          onClick={handleSubmit(onSubmit)}
        >
          Create Group
        </Button>
      </DialogActions>
    </>
  );
};

export default GroupCreateDialog;
