import {
  Grid,
  Table,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from 'app/design';
import { InfoOutlined as InfoIcon } from 'app/design/icons-material';
import React from 'react';

export enum KeywordType {
  Exact,
  Free,
}

export type Keywords = {
  [key: string]: { values?: string[]; description: string; type: KeywordType };
};

export interface KeywordTooltipProps {
  keywords: Keywords;
}

const KeywordTooltip = ({ keywords }: KeywordTooltipProps) => {
  return (
    <Tooltip
      arrow
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            maxWidth: 400,
          },
        },
      }}
      title={
        <div>
          <Typography variant={'h6'}>Keyword search:</Typography>
          <Typography variant={'caption'}>
            format: keyword:value (ex: 'name:John')
          </Typography>
          <Table>
            {Object.entries(keywords).map(
              ([keyword, { description, values, type }]) => (
                <TableRow>
                  <TableCell>
                    <Typography variant={'subtitle1'}>{`${keyword}${
                      type === KeywordType.Exact ? '' : '*'
                    }:`}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={'subtitle2'}>{`${description} ${
                      !!values ? `(${values.join(', ')})` : ''
                    }`}</Typography>
                  </TableCell>
                </TableRow>
              ),
            )}
          </Table>
          <Typography variant={'caption'}>
            * keywords marked with '*' can use wildcards in the search value
            (ex: 'ext:10*' will match 101, 102 etc.)
          </Typography>
        </div>
      }
    >
      <Typography
        sx={{ color: theme => theme.palette.primary.main, fontSize: 14 }}
      >
        <InfoIcon fontSize={'inherit'} />
      </Typography>
    </Tooltip>
  );
};

export default KeywordTooltip;
