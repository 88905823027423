import React, { useState, useEffect } from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tab,
  Typography,
} from 'app/design';
import {
  useForm,
  useFormContext,
  FormProvider,
  Controller,
} from 'react-hook-form';
import {
  Add as AddIcon,
  ExpandMore as ExpandMoreIcon,
} from 'app/design/icons-material';
import { TabContext, TabList, TabPanel } from 'app/design/lab';
import { AudioPlayer } from 'app/components/AudioPlayer';
import { ButtonDropdownMenu } from 'app/components/ButtonDropdownMenu';
import { useEditMenuDialog } from 'app/components/EditMenuDialog';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { buildOutputCallflowFromTemplate } from 'app/components/IvrBuilder/Flow/nodes/Template';
import { QuickFinderMenuDialog } from 'app/components/QuickFinderMenuDialog';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { SimpleInlineEditor } from 'app/components/SimpleInlineEditor';
import { useMenuQuery } from 'app/hooks/queries/menu';
import { DialogBuilder, setAtPath, useToggleReducer } from 'app/utilities';
import { useMarkdownTranslate } from 'app/utilities/translate';
import { cloneDeep, omitBy, range, uniq } from 'lodash';
import { CallflowDoc } from 'types/callflow';
import { Menu } from 'types/menu';
import { useImmer } from 'use-immer';
import Joi from 'joi';
import { Edit as EditIcon } from 'app/design/icons-material';
import { IvrQuickDisplay, IvrCustomInline } from 'app/components/IvrBuilder';

export const MenuTargets = ({ item, modifyPath, ee }) => {
  // console.log('MenuTargets item:', item);
  const { varItem, moduleItem, moduleModifyPath } = item;
  const {
    register,
    formState: { errors: formErrors, isDirty, dirtyFields },
    setError,
    watch,
    control,
    setValue,
  } = useFormContext();

  const key = varItem.key;
  const callflowDoc = watch(`callflow`); // this is the "root" (used for types of templates to use)
  const variables = watch(`${modifyPath}callflow.strategy.simple.variables`);

  // simple variable values
  const currentVarOutputValue = varItem.output_value;
  const defaultValueInFlow = varItem.output_value_default;
  const simpleValueInCallflow = variables[key];

  // // update the simple value so that "menu_greeting" is called each time for building the targets correctly
  // // - if there is no value set, it gets skipped
  // useEffect(() => {
  //   setValue(`${modifyPath}callflow.strategy.simple.variables.${key}`, true);
  // }, []);

  // get CURRENT value FROM MODULE
  // - for when saved by the Advanced Editor!
  //   - that doesnt update the varItem, it only updates the actual flow!

  // get current targets
  const targets =
    watch(`${modifyPath}callflow.${moduleModifyPath}.data.targets`) ?? {}; //moduleItem?.data?.targets;

  // console.log('Targets:', moduleModifyPath, targets);

  const handleSave = async () => {
    // // iterate through targets and rebuild
    // const targetKeys = Object.keys(moduleItem?.data?.targets ?? {});
    // for (let key of targetKeys) {
    //   const target = moduleItem.data.targets[key];
    //   // @ts-ignore
    //   const targetCallflow = target?.callflow;
    //   if (targetCallflow) {
    //     const outputCallflow = await buildOutputCallflowFromTemplate({
    //       // more like "Build Template Strategy using rootCallflow variables"
    //       // - or, can be used on regular Callflows too!
    //       rootCallflow: targetCallflow, // root variables (and callflow.strategy.type=template -> callflow.strategy.simple.variables)
    //       templateCallflow: targetCallflow, // has the "ready for simple" (calculated) values!
    //       checkSimple: true,
    //     });
    //     // add the outputCallflow to the final
    //     targetCallflow.strategy.data = outputCallflow.strategy.data;
    //   }
    // }
    // // moduleItem.data.targets = moduleItem?.data?.targets ?? {};
    // // setAtPath(moduleItem,'data.targets',);
    // // setAtPath(callflowDoc, modifyPath, moduleItem);
    // // setCallflow({ ...callflowDoc });
    // // setValue(,'data.targets',targets);
    // // onClose();
  };

  const {
    Dialog: InputDialog,
    DialogProps: InputDialogProps,
    toggleOpen: toggleInputDialogOpen,
  } = useKeyInputDialog();

  // TODO: only show the "values" that are actually in use?
  const values = uniq([
    ...range(1, 10).map(v => v.toString()),
    ...Object.keys(targets ?? {}).sort(),
    'timeout',
  ]);

  // console.log('initialInputString:', initialInputString);
  // if initialInputString is set and NOT a plus, use that for the default to highlight, otherwise use the first option
  const [tabValue, setTabValue] = useImmer(
    // initialInputString !== '+' ? initialInputString ?? values[0] : values[0],
    values[0],
  );

  // useEffect(() => {
  //   // runs once
  //   if (initialInputString === '+') {
  //     toggleInputDialogOpen();
  //   }
  // }, []);

  const handleTabChange = (e, num) => {
    setTabValue(num);
  };

  // console.log('tmpModule', moduleItem);

  const handleAddTab = value => {
    if (!targets?.hasOwnProperty(value)) {
      setAtPath(moduleItem, `data.targets`, {
        ...moduleItem.data.targets,
        [value]: undefined,
      });
      setModifyModuleItem({ ...moduleItem });
    }
    setTabValue(value);
    toggleInputDialogOpen();
  };

  const setModifyModuleItem = val => {
    setValue(`${modifyPath}callflow.${moduleModifyPath}`, val, {
      shouldDirty: true,
    });
    // setAtPath(moduleItem, `data.targets`, {
    //   ...moduleItem.data.targets,
    //   [value]: undefined,
    // });
  };

  return (
    <>
      <InputDialog
        {...InputDialogProps}
        onComplete={handleAddTab}
        onCancel={toggleInputDialogOpen}
        existingValues={values}
      />
      <div
      // title={menuId ? '' : 'Select Menu'}
      // titleButton={
      //   menu ? (
      //     <Button color="primary" onClick={toggleShowFindMenuDialog}>
      //       Change Menu
      //     </Button>
      //   ) : null
      // }
      // maxWidth={'lg'}
      // onClose={onClose}
      // onCancel={onClose}
      // onSave={handleSave}
      // saveLabel={saveLabel}
      // hideCancel={saveLabel === 'Done'}
      // cancelLabel={'Cancel'}
      // isLoading={menuIsLoading}
      // errorMessage={menuError ? 'Failed loading selected menu' : undefined}
      >
        <Grid container columnSpacing={1}>
          <Grid item xs={12}>
            {/* <Typography
              variant="h1"
              // href={`/admin/vmboxes/view/${vmbox.id}`}
              // underline="hover"
              // color={'text.primary'}
            >{`Key Mapping`}</Typography>
            <br />
            <Divider />
            <br /> */}
            <TabContext value={tabValue}>
              <Box>
                {values.map(inputString => {
                  const targetValue = targets[inputString]?.callflow;
                  let label;
                  // console.log('targetValue:', inputString, targetValue);
                  if (targetValue) {
                    if (targetValue?.strategy?.simple?.enabled) {
                      label =
                        targetValue?.strategy?.template?.parent_callflow
                          ?.name ?? 'Unknown template';
                    } else {
                      if (targetValue?.callflow?.name) {
                        label = targetValue?.callflow?.name;
                      } else {
                        label = 'Custom Flow'; // what else could we show here? what ever the user has actually named this inline flow?
                      }
                    }
                  } else {
                    label = inputString === '0' ? 'Operator Extension' : '';
                  }

                  return (
                    <Accordion
                      key={inputString}
                      // expanded={expanded === 'panel1'}
                      // onChange={handleChange('panel1')}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Box sx={{ width: '100%' }}>
                          <Grid container columnSpacing={2} alignItems="center">
                            <Grid item sx={{ minWidth: '24px' }}>
                              <Typography variant="h6" sx={{}}>
                                {inputString}
                              </Typography>
                            </Grid>
                            <Grid item sx={{ flex: 1 }}>
                              <Typography variant="h6" sx={{}}>
                                {label}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box>
                          <KeyPressTabPanel
                            {...{
                              callflowDoc,
                              setModifyModuleItem,
                              modifyPath: `${modifyPath}callflow.${moduleModifyPath}.data.targets.${inputString}`,
                              moduleItem,
                              targets,
                              values,
                              setTabValue,
                              ee,
                            }}
                            inputString={inputString}
                          />
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
                <Accordion>
                  <AccordionSummary>
                    <Box sx={{ width: '100%' }}>
                      <Button
                        onClick={toggleInputDialogOpen}
                        variant="outlined"
                      >
                        Add another option to menu
                      </Button>
                    </Box>
                  </AccordionSummary>
                </Accordion>
              </Box>
            </TabContext>

            {/* <TabContext value={tabValue}>
              <Grid container wrap={'nowrap'} spacing={1}>
                <Grid item xs={4}>
                  <div style={{ display: 'grid', placeItems: 'center' }}>
                    <TabList
                      orientation="vertical"
                      // variant="scrollable"
                      value={tabValue}
                      onChange={handleTabChange}
                      sx={{
                        textAlign: 'left',
                        borderRight: '1px solid rgba(0,0,0,0.2)',
                      }}
                    >
                      {values.map(inputString => {
                        const targetValue = targets[inputString]?.callflow;
                        let label;
                        // console.log('targetValue:', inputString, targetValue);
                        if (targetValue) {
                          if (targetValue?.strategy?.simple?.enabled) {
                            label =
                              targetValue?.strategy?.template?.parent_callflow
                                ?.name ?? 'Unknown template';
                          } else {
                            label = 'Advanced';
                          }
                        } else {
                          label =
                            inputString === '0' ? 'Operator Ext.' : 'Unused';
                        }

                        return (
                          <Tab
                            value={inputString}
                            label={
                              <div
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  justifyContent: 'left',
                                  alignItems: 'left',
                                  textAlign: 'left',
                                  width: 150,
                                }}
                              >
                                {`${inputString}: ${label}`}
                              </div>
                            }
                          />
                        );
                      })}
                      <Tab
                        label={<AddIcon />}
                        onClick={toggleInputDialogOpen}
                      />
                    </TabList>
                  </div>
                </Grid>
                <Grid item xs={7}>
                  {values.map(inputString => (
                    <KeyPressTabPanel
                      {...{
                        callflowDoc,
                        setModifyModuleItem,
                        // modifyPath,
                        moduleItem,
                        targets,
                        values,
                        setTabValue,
                      }}
                      inputString={inputString}
                    />
                  ))}
                </Grid>
              </Grid>
            </TabContext> */}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const KeyPressTabPanel = ({
  inputString,
  targets,
  values,
  callflowDoc,
  moduleItem,
  setModifyModuleItem,
  modifyPath,
  setTabValue,
  ee,
}) => {
  const {
    Dialog: InputDialog,
    DialogProps: InputDialogProps,
    toggleOpen: toggleInputDialogOpen,
  } = useKeyInputDialog();

  const targetValue = targets[inputString];

  const handleSimple = () => {
    // clear out the target value
    setAtPath(moduleItem, `data.targets.${inputString}`, null);
    setModifyModuleItem({ ...moduleItem });
  };

  const handleModifyAdvanced = () => {
    // emit an event to start a new editor instance
    // - add to chain
    // console.log('callflow:', callflow);
    ee?.emit('MODIFY_CALLFLOW', {
      callflow: targetValue?.callflow,
      onDone: callflow => {
        console.log('done callflow:', callflow);
        // setValue(`callflow`, callflow, {
        //   shouldDirty: true,
        // });
        setAtPath(moduleItem, `data.targets.{${inputString}}`, { callflow });
        setModifyModuleItem({ ...moduleItem });
      },
    });
    window.scrollTo(0, 0);
  };

  if (!targetValue && inputString === '0') {
    return (
      <TabPanel sx={{ width: '100%' }} value={inputString}>
        <Typography color={'gray.main'}>
          By default key press 0 is handled by the account operator extension
        </Typography>
        {/*<FormControlLabel
          control={
            <Switch checked={enabled} size={'small'} onChange={toggleEnabled} />
          }
          label="Override Operator Ext."
        />
        <br />
        <br />*/}
      </TabPanel>
    );
  }

  if (
    targetValue?.callflow?.id &&
    !targetValue?.callflow?.strategy?.simple?.enabled
  ) {
    return (
      // <TabPanel sx={{ width: '100%' }} value={inputString}>
      <div>
        {/* Please use the Visual Editor for changes
        <br /> */}
        <Button variant="contained" onClick={handleModifyAdvanced}>
          Modify Custom Flow in Visual Editor
        </Button>
        &nbsp;&nbsp;
        <Button onClick={handleSimple}>Switch to a simple template</Button>
        <br />
        <br />
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '300px',
            borderRadius: 2,
            border: '1px solid #ececec',
          }}
        >
          <IvrQuickDisplay
            // id={JSON.stringify(callflow)}
            // key={previewCallflowKey} // causes a rerender when changed!
            isInline
            callflow={targetValue?.callflow}
          />
        </Box>
      </div>
      // </TabPanel>
    );
  }

  const handleChange = formValue => {
    // debugger;
    setAtPath(moduleItem, `data.targets.{${inputString}}`, formValue);
    setModifyModuleItem({ ...moduleItem });
    // console.log('menu formValue', formValue);
  };

  let label;

  if (targetValue) {
    if (targetValue?.callflow?.strategy?.simple?.enabled) {
      label =
        targetValue?.callflow?.strategy?.template?.parent_callflow?.name ??
        'Unknown template';
    } else {
      if (targetValue?.callflow?.name) {
        label = targetValue?.callflow?.name;
      } else {
        label = 'Custom Flow'; // what else could we show here? what ever the user has actually named this inline flow?
      }
    }
  } else {
    label = 'Unused';
  }

  const handleEdit = value => {
    setAtPath(moduleItem, `data.targets`, {
      [value]: targets?.[inputString],
      ...omitBy(moduleItem.data.targets, (value, key) => key === inputString),
    });
    setModifyModuleItem({ ...moduleItem });
    setTabValue(value);
    toggleInputDialogOpen();
  };

  const handleRemove = () => {
    setAtPath(
      moduleItem,
      `data.targets`,
      omitBy(moduleItem.data.targets, (value, key) => key === inputString),
    );
    setModifyModuleItem({ ...moduleItem });
    setTabValue('0');
  };

  const handleChangeTemplate = () => {
    // clear out the target value
    setAtPath(moduleItem, `data.targets.${inputString}`, null);
    setModifyModuleItem({ ...moduleItem });
  };

  return (
    <>
      <InputDialog
        {...InputDialogProps}
        onComplete={handleEdit}
        onCancel={toggleInputDialogOpen}
        existingValues={values}
        defaultValue={inputString}
      />
      {/* <TabPanel value={inputString}> */}
      {targetValue ? (
        <Grid container spacing={1} alignItems={'center'}>
          {/* <Grid item sx={{ flex: 1 }}>
            <Typography variant={'h6'}>{label}</Typography>
          </Grid> */}
          <Grid item>
            <ButtonDropdownMenu
              menuItems={[
                {
                  text: 'Change Template',

                  onClick: handleChangeTemplate,
                },
                {
                  text: 'Change Key Input',

                  onClick: toggleInputDialogOpen,
                },
                {
                  text: 'Clear / Remove',

                  onClick: handleRemove,
                },
              ]}
            />
          </Grid>
        </Grid>
      ) : null}
      <SimpleInlineEditor
        key={targetValue?.callflow?.id ?? 'missing'}
        name_type={'Simple'}
        type={callflowDoc.type}
        owner_type={callflowDoc.owner_type}
        parent_obj="menu"
        resourceId={callflowDoc.owner_id}
        callflow={targetValue?.callflow}
        onChange={handleChange}
        modifyPath={modifyPath}
        ee={ee}
      />
      {/* {targetValue?.callflow ? (
          <>
            <br />
            <Divider />
            <br />
            <Typography variant="body1" paragraph>
              Advanced: after saving, you can "eject" this template to customize
              additional settings
            </Typography>
          </>
        ) : null} */}
      {/*<Autocomplete
        options={TEMPLATES}
        disabled={!enabled || advanced}
        // filterOptions={filterOptions}
        // @ts-ignore
        // value={optionValue}
        getOptionLabel={option => option.name}
        onChange={(e, v) => {
          // onChange(v?.value);
          // onChangeParent(v?.full);
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={advanced ? 'Using advanced handling' : 'Handle...'}
            placeholder={'not set'}
            fullWidth
          />
        )}
        // disableClearable
      />*/}
      {/* </TabPanel> */}
    </>
  );
};

const KeyInputDialog = ({
  onComplete,
  onCancel,
  existingValues,
  defaultValue,
}) => {
  const schema = Joi.object({
    input: Joi.string()
      // .invalid(...existingValues)
      .required(),
  });
  const formMethods = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      input: defaultValue,
    },
  });

  const handleSave = form => {
    onComplete(form.input.toString());
  };

  return (
    <Dialog maxWidth={'xs'} fullWidth open>
      <DialogTitle>Key Input</DialogTitle>
      <DialogContent dividers>
        <FormProvider {...formMethods}>
          <HookFormTextField
            name={'input'}
            label={'Key Input'}
            type={'number'}
            errorMessageParser={error => {
              if (error.type === 'any.invalid') {
                return 'Key Input is already in use';
              }

              return error.message;
            }}
            autoFocus
          />
        </FormProvider>
      </DialogContent>
      <DefaultDialogActions
        onCancel={onCancel}
        onSave={formMethods.handleSubmit(handleSave)}
        saveLabel="Add Key"
      />
    </Dialog>
  );
};

const useKeyInputDialog = DialogBuilder(KeyInputDialog);

export default MenuTargets;
