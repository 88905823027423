import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  InputTextField,
  Link,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from 'app/design';

import { useMenuQuery } from 'app/hooks/queries/menu';

import { Folder as FolderIcon } from 'app/design/icons-material';

import { Handle } from 'react-flow-renderer';
import { useHoverDirty } from 'react-use';
import { IvrMenuEventEmitterContext, useSharedFlow } from '../../..';

// import {
//   ModifyDialog as ModifyDialogAudio,
//   SmallMediaPlayer,
// } from '../../../../SingleMedia';

import { Box } from '../common/Box';
import { ModifyDialog } from './ModifyDialog';
import { InTemplate } from '../common/InTemplate';
import { MenuButton } from '../common/MenuButton';
import { NodeInfoDisplay } from '../common/NodeInfoDisplay';
import { VariablesEditor } from '../common/VariablesEditor';
import { AudioPlayer } from 'app/components/AudioPlayer';

import { getAtPath, setAtPath } from 'app/utilities';

import { StrategySelect } from '../common/StrategySelect';

// why use memo??
const MenuNode = memo(({ data, ...rest }) => {
  const {
    skipEditing,
    insertBefore, // bool
    insertAfterData,
    moduleItem,
    callflow,
    setCallflow,
    modifyPath,
    templateParent,
    // element, // used in Box
    // setRootElements, // used in Box
  } = data;

  // Load menu via query
  const {
    data: menu,
    isLoading,
    isFetched,
  } = useMenuQuery({ id: moduleItem?.data?.id });

  const [sharedFlow, setSharedFlow] = useSharedFlow();

  const [showAddOptionDialog, setShowAddOptionDialog] = useState(null);
  const setShowAddOptionDialogWrap = setTo => () =>
    setShowAddOptionDialog(setTo);

  const [showAddOptionPreExistingDialog, setShowAddOptionPreExistingDialog] =
    useState(null);
  const setShowAddOptionPreExistingDialogWrap = setTo => () =>
    setShowAddOptionPreExistingDialog(setTo);

  const [showModifyDialog, setShowModifyDialog] = useState(null);
  const setShowModifyDialogWrap = setTo => () => setShowModifyDialog(setTo);

  const [showVariables, setShowVariables] = useState(null);
  const setShowVariablesWrap = setTo => () => setShowVariables(setTo);

  const boxRef = useRef(null);
  const isHovering = useHoverDirty(boxRef);

  let possibleVariables = [
    {
      id: 'menu_greeting',
      name: 'Menu Greeting',
      read: {
        type: 'menu_greeting',
      },
      write: {
        type: 'menu_greeting',
        data: {
          // moduleItemModifyPath: 'data.id',
        },
      },
    },
    {
      id: 'menu_targets',
      name: 'Menu Targets',
      read: {
        type: 'menu_targets',
      },
      write: {
        type: 'menu_targets',
        data: {
          // moduleItemModifyPath: 'data.id',
        },
      },
    },
    {
      id: 'menu_id',
      name: 'Menu ID',
      read: {
        type: 'menu_id',
      },
      write: {
        type: 'set',
        data: {
          moduleItemModifyPath: 'data.id',
        },
      },
    },
  ];

  // action for onCreate
  const ee = useContext(IvrMenuEventEmitterContext);
  const [insertData, setInsertData] = useState(null);

  const onRemoteInsert = remoteData => {
    if (remoteData?.type === 'duplicate') {
      setInsertData(remoteData.data);
      setShowAddOptionPreExistingDialog(true);
    } else {
      // no other states besides "duplicate" and "blank" yet
      setShowAddOptionDialog(true);
    }
    // if (sharedFlow?.state === 'duplicate-to') {

    // }
  };
  const onNodeCreated = data => {
    if (data.optsPath === modifyPath) {
      setShowModifyDialog(true);
    }
  };
  useEffect(() => {
    ee.on(`insert-to-${modifyPath}`, onRemoteInsert);
    ee.on('node-created', onNodeCreated);
    return () => {
      ee.removeListener('node-created', onNodeCreated);
      ee.removeListener(`insert-to-${modifyPath}`, onRemoteInsert);
    };
  }, [modifyPath]);

  return (
    <>
      {showAddOptionDialog && (
        <AddOptionDialog {...data} onClose={setShowAddOptionDialogWrap(null)} />
      )}
      {showAddOptionPreExistingDialog && (
        <AddOptionPreExistingDialog
          {...data}
          insertData={insertData}
          onClose={setShowAddOptionPreExistingDialogWrap(null)}
        />
      )}
      <Box
        pref={boxRef}
        borderColor="#1E88E5"
        position={data?.position}
        data={data}
        // height={100}
        // onHeight={}
        // onDimensions={handleDimensions}
      >
        {showModifyDialog && (
          <ModifyDialog
            {...data}
            menuId={moduleItem?.data?.id}
            // menu={menu}
            onClose={setShowModifyDialogWrap(null)}
          />
        )}
        {showVariables && (
          <VariablesEditor // Dialog
            {...data}
            possibleVariables={possibleVariables}
            onClose={setShowVariablesWrap(null)}
          />
        )}
        <Handle
          type="target"
          position="top"
          style={{ background: '#555', visibility: 'hidden' }}
        />
        <div style={{ position: 'relative' }}>
          <NodeInfoDisplay
            type={'Menu'}
            icon={<FolderIcon />}
            title={
              menu ? (
                `${menu?.doc?.name}`
              ) : skipEditing ? (
                'None Selected'
              ) : (
                <Link onClick={setShowModifyDialogWrap(true)}>Select Menu</Link>
              )
            }
            content={
              menu?.doc?.media?.greeting ? (
                <AudioPlayer mediaId={menu?.doc?.media?.greeting} />
              ) : (
                'No Greeting'
              )
            }
            footer={''}
          />
          {skipEditing ? null : (
            <>
              <MenuButton
                data={data}
                mode={isHovering ? 'normal' : 'ignore'}
                menuItems={[
                  {
                    type: 'modify',
                    props: { onClick: setShowModifyDialogWrap(true) },
                  },
                  // 'add',
                  'add-before',
                  'move',
                  'duplicate',
                  'copy',
                  process.env.NODE_ENV === 'development'
                    ? 'create_template'
                    : null,
                  templateParent ||
                  callflow.type == 'template' ||
                  process.env.NODE_ENV === 'development'
                    ? {
                        type: 'variables',
                        props: { onClick: setShowVariablesWrap(true) },
                      }
                    : null,
                  'remove',
                ]}
              />
            </>
          )}
        </div>
        <Handle
          type="source"
          position="bottom"
          style={{
            top: 'auto',
            bottom: 1,
            background: '#555',
            visibility: 'hidden',
          }}
        />
        {templateParent ? <InTemplate template={templateParent} /> : ''}
      </Box>
    </>
  );
});

// // // OLD

// import React, {
//   memo,
//   useEffect,
//   useState,
//   useRef,
//   useContext,
//   useReducer,
// } from 'react';
// import { Handle } from 'react-flow-renderer';
// import { useMutation } from 'react-query';
// import { useHistory } from 'react-router-dom';
// import { NodeInfoDisplay } from '../common/NodeInfoDisplay';

// import {
//   Grid,
//   Typography,
//   Button,
//   Divider,
//   Link,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   TextField,
//   // Sidebar,
//   // EditDetailsDialog,
//   // SidebarCard,
//   // SidebarCardHeader,
//   // SidebarCardContent,
//   // SidebarCardSubtitle,
//   // DialogCircularProgress,
//   DefaultDialogActions,
// } from 'app/design';

// import {
//   NavigateNext as NavigateNextIcon,
//   Dialpad as DialpadIcon,
//   Voicemail as VoicemailIcon,
//   FontDownload as FontDownloadIcon,
//   EmojiPeople,
//   Replay as ReplayIcon,
//   AccessTime as AccessTimeIcon,
//   Tune as TuneIcon,
//   Search,
//   ImportExport,
//   Check,
// } from 'app/design/icons-material';

// import { Box } from '../common/Box';
// import { MenuButton } from '../common/MenuButton';
// import { InTemplate } from '../common/InTemplate';
// import { VariablesEditor } from '../common/VariablesEditor';

// import { getAtPath, setAtPath } from 'app/utilities';
// import { useHoverDirty } from 'react-use';

// // import { useSetupHook } from '../../../../SetupHook';
// // import { ModifyDialog } from './ModifyDialog';
// // import {
// //   SingleMedia,
// //   ChangeAudioDialog,
// //   SmallMediaPlayer,
// //   ModifyDialog as ModifyDialogAudio,
// //   ModifyDialogGreeting,
// // } from '../../../../SingleMedia';

// import { StrategySelect } from '../common/StrategySelect';

// import {
//   InsertAfterButton,
//   InsertBeforeButton,
//   RemoveButton,
//   ModifyButton,
// } from '../Insert';

// import { IvrMenuEventEmitterContext, useSharedFlow } from '../../..';

// // why using memo() before??
// const MenuNode = memo(({ data }) => {
//   const {
//     insertBefore,
//     insertAfterData, // used for removal
//     componentData,
//     callflow,
//     setCallflow,
//     modifyPath,
//     templateParent,
//   } = data;

//   const ee = useContext(IvrMenuEventEmitterContext);
//   const [sharedFlow, setSharedFlow] = useSharedFlow();

//   const [showAddOptionDialog, setShowAddOptionDialog] = useState(null);
//   const setShowAddOptionDialogWrap = setTo => () =>
//     setShowAddOptionDialog(setTo);

//   const [showAddOptionPreExistingDialog, setShowAddOptionPreExistingDialog] =
//     useState(null);
//   const setShowAddOptionPreExistingDialogWrap = setTo => () =>
//     setShowAddOptionPreExistingDialog(setTo);

//   const [showModifyDialog, setShowModifyDialog] = useState(null);
//   const setShowModifyDialogWrap = setTo => () => setShowModifyDialog(setTo);

//   const boxRef = useRef(null);
//   const isHovering = useHoverDirty(boxRef);
//   // const actAsHovering = isHovering || anchorEl || anchorFakeEl; // try to detect if a menu was selected

//   // const menus = useSelector(state => state.lists.menus.list);
//   // const menu = menus.find(m => m.id === componentData.menuId);

//   const menu = null; // TODO: graphql!!!!

//   const [insertData, setInsertData] = useState(null);

//   const onRemoteInsert = remoteData => {
//     if (remoteData?.type === 'duplicate') {
//       setInsertData(remoteData.data);
//       setShowAddOptionPreExistingDialog(true);
//     } else {
//       // no other states besides "duplicate" and "blank" yet
//       setShowAddOptionDialog(true);
//     }
//     // if (sharedFlow?.state === 'duplicate-to') {

//     // }
//   };

//   useEffect(() => {
//     ee.on(`insert-to-${modifyPath}`, onRemoteInsert);
//     return () => {
//       ee.removeListener(`insert-to-${modifyPath}`, onRemoteInsert);
//     };
//   }, [modifyPath]);

//   // action for onCreate
//   const onNodeCreated = data => {
//     if (data.optsPath === modifyPath) {
//       setShowModifyDialog(true);
//     }
//   };
//   useEffect(() => {
//     ee.on('node-created', onNodeCreated);
//     return () => {
//       ee.removeListener('node-created', onNodeCreated);
//     };
//   }, []);

//   return (
//     <>
//       {showAddOptionDialog && (
//         <AddOptionDialog {...data} onClose={setShowAddOptionDialogWrap(null)} />
//       )}
//       {showAddOptionPreExistingDialog && (
//         <AddOptionPreExistingDialog
//           {...data}
//           insertData={insertData}
//           onClose={setShowAddOptionPreExistingDialogWrap(null)}
//         />
//       )}
//       {/* {showModifyDialog && (
//         <ModifyDialog
//           {...data}
//           menu={menu}
//           onClose={setShowModifyDialogWrap(null)}
//         />
//       )} */}
//       <Box
//         pref={boxRef}
//         // height={100}
//         borderColor="#5E35B1"
//         position={data?.position}
//         data={data}
//       >
//         <Handle
//           type="target"
//           position="top"
//           style={{ background: '#555', visibility: 'hidden' }}
//         />
//         <NodeInfoDisplay
//           type={'Menu'}
//           icon={<DialpadIcon />}
//           title={
//             menu ? (
//               menu?.doc?.name
//             ) : (
//               <Link onClick={setShowModifyDialogWrap(true)}>Select Menu</Link>
//             )
//           }
//           content={
//             menu ? (
//               menu?.doc?.media?.greeting ? (
//                 // <SmallMediaPlayer mediaId={menu?.doc?.media?.greeting} />
//                 <>Small media Player</>
//               ) : (
//                 <span style={{ opacity: 0.6 }}>[No Greeting]</span>
//               )
//             ) : null
//           }
//         />
//         <MenuButton
//           data={data}
//           mode={isHovering ? 'normal' : 'ignore'}
//           menuItems={[
//             {
//               type: 'modify',
//               props: { onClick: setShowModifyDialogWrap(true) },
//             },
//             // 'add',
//             'add-before',
//             'move',
//             'duplicate',
//             'copy',
//             'create_template',
//             'remove',
//           ]}
//         />
//         {/* <RemoveButton
//               {...insertAfterData}
//               mode={isHovering ? 'normal' : 'ignore'}
//             />
//             <ModifyButton
//               onClick={setShowModifyDialogWrap(true)}
//               mode={isHovering ? 'normal' : 'ignore'}
//             />
//             {insertBefore && (
//               <InsertBeforeButton
//                 insertAfterData={insertAfterData}
//                 mode={isHovering ? 'normal' : 'ignore'}
//               />
//             )}
//             <InsertAfterButton
//               insertAfterData={insertAfterData}
//               onClick={setShowAddOptionDialogWrap(true)}
//               mode={isHovering ? 'normal' : 'ignore'}
//             /> */}
//         {/*</>*/}
//         {/*</div>*/}
//         <Handle
//           type="source"
//           position="bottom"
//           // id="b"
//           style={{
//             top: 'auto',
//             bottom: 1,
//             background: '#555',
//             visibility: 'hidden',
//           }}
//         />
//         {templateParent ? <InTemplate template={templateParent} /> : ''}
//       </Box>
//     </>
//   );
// });

export const AddOptionDialog = props => {
  const {
    callflow,
    setCallflow,
    modifyPath,
    index,
    moduleItem,
    templateParent,
    onClose,
  } = props;

  const [data, setData] = useState({
    option: '',
    selected: null,
  });

  const handleSave = selected => {
    // Add a NEW callflow to the target option for the callflow

    const compData = {
      type: selected ? selected.value.type : data.selected.value.type,
      options: selected ? selected.value.options : data.selected.value.options,
    };

    let newCallflow = {
      id: 'inline',
      strategy: {
        type: 'blank',
        data: {
          modules: [compData],
        },
      },
    };

    setAtPath(
      callflow,
      `${modifyPath}.data.targets.{${data.option}}.callflow`,
      newCallflow,
    );
    // console.log('callflow:', callflow);

    setCallflow({ ...callflow });
    onClose();

    // TODO: focus after rebuild, instead of on a Timeout
    // window.setTimeout(() => {
    //   ee.emit('focus-node', {
    //     key: `${modifyPath}.strategy.config.components[${index}]`,
    //   });
    // }, 100);
  };

  const handleSetSelected = selected => {
    handleSave(selected);
    // setData({
    //   ...data,
    //   selected,
    // });
  };

  const handleChangeText = prop => e => {
    setAtPath(data, prop, e.target.value);
    setData({ ...data });
  };

  // console.log('data:', data);
  let optionWritten;
  if (data.option !== '') {
    optionWritten = true;
  }

  let overwriting;
  if (optionWritten) {
    overwriting = getAtPath(
      callflow,
      `${modifyPath}.targets.${data.option}.callflow`,
      null,
    )
      ? true
      : false;
  }

  return (
    <Dialog open onClose={onClose} fullWidth={true} maxWidth="sm">
      {/* <DialogTitle>Add Option for Key Press</DialogTitle>
      <Divider /> */}
      {optionWritten ? (
        <>
          <DialogTitle disableTypography>
            <div>
              <TextField
                label="Key Press"
                value={getAtPath(data, 'option')}
                onChange={handleChangeText('option')}
                helpText={'Digits that the caller will press'}
                showHelpText
                errorText={
                  overwriting
                    ? 'This key press is already in use! Using it will overwrite your existing action'
                    : null
                }
              />
            </div>
          </DialogTitle>
          <Divider />
        </>
      ) : (
        ''
      )}
      <DialogContent>
        {optionWritten ? (
          <>
            <StrategySelect
              isInTemplate={templateParent || callflow.type == 'template'}
              endpointAlreadyInArr={false} // starting a new chain, so never going to be in here
              requireAllowBefore={false}
              requireAllowAfter={false}
              onChange={handleSetSelected}
              selected={data.selected}
            />
          </>
        ) : (
          <>
            <TextField
              label="Key Press"
              value={getAtPath(data, 'option')}
              onChange={handleChangeText('option')}
              helpText={
                'Enter the digits that the caller will press for this menu option'
              }
              showHelpText
              autoFocus
            />
          </>
        )}
      </DialogContent>
      {/* {optionWritten ? (
        <>
          <Divider />
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              onClick={(e) => {
                // onClose();
                handleSave();
              }}
            >
              Add Option
            </Button>
          </DialogActions>
        </>
      ) : (
        ''
      )} */}
    </Dialog>
  );
};

export const AddOptionPreExistingDialog = props => {
  const {
    callflow,
    setCallflow,
    modifyPath,
    index,
    componentData,
    onClose,
    insertData,
  } = props;

  const [data, setData] = useState({
    option: '',
    // selected: null,
  });

  const [sharedFlow, setSharedFlow] = useSharedFlow();

  const handleSave = selected => {
    // likely that an array of components is being dropped here
    // - via duplicateTo

    const { infoArr, dataArr } = insertData;

    let newCallflow = {
      strategy: {
        type: 'blank',
        config: {
          components: infoArr,
        },
        data: {
          opts: dataArr,
        },
      },
    };

    setAtPath(
      callflow,
      `${modifyPath}.targets.${data.option}.callflow`,
      newCallflow,
    );
    setCallflow({ ...callflow });
    onClose();

    setSharedFlow(s => ({ ...s, state: null, data: null }));

    // TODO: focus after rebuild, instead of on a Timeout
    // window.setTimeout(() => {
    //   ee.emit('focus-node', {
    //     key: `${modifyPath}.strategy.config.components[${index}]`,
    //   });
    // }, 100);
  };

  const handleSetSelected = selected => {
    handleSave(selected);
    // setData({
    //   ...data,
    //   selected,
    // });
  };

  const handleChangeText = prop => e => {
    setAtPath(data, prop, e.target.value);
    setData({ ...data });
  };

  // console.log('data:', data);
  let optionWritten;
  if (data.option !== '') {
    optionWritten = true;
  }

  let overwriting;
  if (optionWritten) {
    overwriting = getAtPath(
      callflow,
      `${modifyPath}.targets.${data.option}.callflow`,
      null,
    )
      ? true
      : false;
  }

  return (
    <Dialog open onClose={onClose} fullWidth={true} maxWidth="sm">
      {/* <DialogTitle>Add Option for Key Press</DialogTitle>
      <Divider /> */}
      {optionWritten ? (
        <>
          <DialogTitle disableTypography>
            <div>
              <TextField
                label="Key Press"
                value={getAtPath(data, 'option')}
                onChange={handleChangeText('option')}
                helpText={'Digits that the caller will press'}
                showHelpText
                errorText={
                  overwriting
                    ? 'This key press is already in use! Using it will overwrite your existing action'
                    : null
                }
              />
            </div>
          </DialogTitle>
          <Divider />
        </>
      ) : (
        ''
      )}
      <DialogContent>
        {optionWritten ? (
          <></>
        ) : (
          <>
            <TextField
              label="Key Press"
              value={getAtPath(data, 'option')}
              onChange={handleChangeText('option')}
              helpText={
                'Enter the digits that the caller will press for this menu option'
              }
              showHelpText
              autoFocus
            />
          </>
        )}
      </DialogContent>
      {optionWritten ? (
        <>
          <Divider />
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              onClick={e => {
                // onClose();
                handleSave();
              }}
            >
              Add
            </Button>
          </DialogActions>
        </>
      ) : (
        ''
      )}
    </Dialog>
  );
};

export default MenuNode;
