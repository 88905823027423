import { Box, Button, Link, Typography } from 'app/design';
import { useCallRoutingDialog } from 'app/components/CallRoutingDialog';
import { QuickFinderExtensionDialog } from 'app/components/QuickFinderExtensionDialog';
import { useListExtensionsQuery } from 'app/hooks/queries/extension';
import { setAtPath, useToggleReducer } from 'app/utilities';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import { CallflowDoc } from 'types/callflow';
import { Extension } from 'types/extension';
import { NodeEditDialog } from 'app/components/IvrBuilder/Dialogs';
import { Link as RouterLink } from 'react-router-dom';
interface ModifyNodeDialogExtensionProps {
  callflow: CallflowDoc;
  setCallflow: (callflow: CallflowDoc) => void;
  modifyPath: string;
  moduleItem: any;
  extensionId: string;
  onClose: () => void;
}

const ModifyNodeDialogTransfer = ({
  callflow: callflowDoc,
  setCallflow,
  modifyPath,
  moduleItem,
  extensionId: initialExtensionId,
  onClose,
}: ModifyNodeDialogExtensionProps) => {
  // autoshow quick finder if node has no data ID
  const [showFinderDialog, toggleShowFindExtensionDialog] = useToggleReducer(
    !initialExtensionId,
  );

  const [tmpModuleItem, setModifyModuleItem] = useState(() =>
    cloneDeep(moduleItem),
  );
  const extensionId = tmpModuleItem?.data?.target;

  const {
    data: extensionsPage,
    isLoading: extensionIsLoading,
    error: extensionError,
    refetch: refetchExtension,
  } = useListExtensionsQuery({
    filters: {
      context: {
        numbersQuoted: [extensionId],
      },
    },
    options: {
      enabled: !!extensionId,
    },
  });
  const extension = extensionsPage?.extensions?.[0];

  const handleSave = async () => {
    moduleItem.data.target = extensionId;
    setAtPath(callflowDoc, modifyPath, tmpModuleItem);
    setCallflow({ ...callflowDoc });
    onClose();
  };

  const [initialState] = useState({
    id: initialExtensionId,
  });

  const saveLabel = !extensionId
    ? 'Leave Empty'
    : !initialExtensionId
    ? 'Add'
    : initialExtensionId === extensionId
    ? 'Done'
    : 'Update';

  const onChangeExtensionSelect = selected => {
    const [extensionId] = selected;
    setAtPath(tmpModuleItem, `data.target`, extensionId); // newValue?.value === id
    setModifyModuleItem({ ...tmpModuleItem });
    toggleShowFindExtensionDialog();
  };

  const handleClear = () => {
    setAtPath(tmpModuleItem, `data.target`, null); // newValue?.value === id
    setModifyModuleItem({ ...tmpModuleItem });
    toggleShowFindExtensionDialog();
  };

  return (
    <>
      <NodeEditDialog
        title={
          extensionId
            ? 'Edit Transfer Target Settings'
            : 'Select Transfer Target'
        }
        titleButton={
          extension ? (
            <Button color="primary" onClick={toggleShowFindExtensionDialog}>
              Change Transfer Target
            </Button>
          ) : null
        }
        maxWidth={'sm'}
        onClose={onClose}
        onCancel={onClose}
        onSave={handleSave}
        saveLabel={saveLabel}
        hideCancel={saveLabel === 'Done'}
        cancelLabel={'Cancel'}
        isLoading={extensionIsLoading}
        errorMessage={
          extensionError ? 'Failed loading selected extension' : undefined
        }
      >
        {extension ? (
          <ExtensionSettings
            extension={extension}
            onComplete={refetchExtension}
          />
        ) : (
          <>
            <Typography color={'gray'} sx={{ fontStyle: 'italic' }}>
              No Transfer Target Selected...
            </Typography>
            <Button onClick={toggleShowFindExtensionDialog}>
              Choose Transfer Target
            </Button>
          </>
        )}
      </NodeEditDialog>
      {showFinderDialog ? (
        <QuickFinderExtensionDialog
          onSelect={onChangeExtensionSelect}
          onCancel={toggleShowFindExtensionDialog}
          onClear={handleClear}
          initialSelected={extensionId ? [extensionId] : []}
          selectionTitle={extension ? `${extension.number}` : null}
          allowSelectNone
          allowCreate
        />
      ) : null}
    </>
  );
};

interface ExtensionSettingsProps {
  extension: Extension;
  onComplete: () => void;
}

const ExtensionSettings = ({
  extension,
  onComplete,
}: ExtensionSettingsProps) => {
  return (
    <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
      <Link
        component={RouterLink}
        variant="h1"
        to={`/admin/extensions?search=ext:${extension.number}`}
        underline="hover"
        color={'text.primary'}
      >{`${extension.number}`}</Link>
      <br />
      <Link
        variant="body2"
        underline="hover"
        component={RouterLink}
        to={`/admin/callflows?search=id:${extension.callflow.id}`}
      >
        {extension.callflow.doc.name ?? 'Unnamed Callflow'}
      </Link>
    </Box>
  );
};

export default ModifyNodeDialogTransfer;
