import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { MenuCreateDoc } from 'types/menu';
import { ScheduleCreateDoc } from 'types/schedule';

export const useCreateSchedule = () => {
  const authState = useAuthSelector();
  return useMutation<any, AxiosError, ScheduleCreateDoc, unknown>(
    (scheduleCreateDoc: ScheduleCreateDoc) =>
      sdk.schedule.mutate.create(
        {
          data: scheduleCreateDoc,
          // other values such as "accept_charges" could go here
        },
        {
          authToken: authState?.auth_token,
        },
      ),
  );
};
