// import useStyles from './styles';
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from 'app/design';

import {
  AccountBox as AccountBoxIcon,
  Dialpad as DialpadIcon,
  History as HistoryIcon,
  Phone as PhoneIcon,
  Settings as SettingsIcon,
} from 'app/design/icons-material';
import { useWebphoneSelector, useWebphoneSlice } from 'app/data/webphone';

import { useCallsParkedQuery } from 'app/hooks/queries/calls';
import React, { useEffect, useState } from 'react';

// import { useSetupHook } from '../../SetupHook';
import { useDispatch } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import { useWebphoneContext } from '../Store';
// import { IncomingView } from './IncomingView';
import { CallDetailView } from './CallDetailView';
import { CallHistoryView } from './CallHistoryView';
import { CallsView } from './CallsView';
import { ContactsView } from './ContactsView';
import { DialView } from './DialView';
import { ParkingView } from './ParkingView';
// import { ConferenceRoom } from './ConferenceRoom';
import { Permissions } from './Permissions';
import { SettingsView } from './SettingsView';
import { VideoFullView } from './VideoFullView';

let isUpdating = false,
  shouldUpdateAgain = false;
export const WebphoneUI = props => {
  const { open, device } = props; // the device we SHOULD use

  // TODO: do a check to see if we should be registering the device immediately
  // - on page load, or when the Phone icon is clicked
  const [hasBeenOpened, setHasBeenOpened] = useState(false);
  const [showWsVideo, setWsVideo] = useState(false);

  // connecting immediately after sidebar/drawer is clicked (not waiting for "Connect Webphone" button to be clicked!)
  useEffect(() => {
    if (!hasBeenOpened) {
      setHasBeenOpened(true);
      handleConnect();
    }
  }, [open]);

  const dispatchRedux = useDispatch();

  const [webphoneState, dispatchWebphone] = useWebphoneContext();
  const {
    mainTab,
    sip,
    calls,
    // parked_calls,
    isLineConnected,
    isRegistered,
    registerSip,
    makeCall,
    getErrorMessage,
    eventBus,
  } = webphoneState;

  const { data: parkingData, isFetching, refetch } = useCallsParkedQuery();
  const parked_calls = parkingData?.callsParked ?? [];

  const { actions: webphoneActions } = useWebphoneSlice();

  // console.log('STATE:', state)

  // TODO: have a switch/slider for Enabled (doesnt actually change on the server, simply changes locally if we want to connect/register!)
  // - if enabled==false then dont allow easy connection
  // const [hasDetailView, setDetailView] = useState(false);

  const handleConnect = async () => {
    // TODO: update the server w/ our data
    // - on reconnect, we refetch data from the server to see if we should auto-reconnect
    //   - ie, has the "last_register_key" stayed what we set it to?
    //     - if not, we do NOT reconnect!

    // make server request here to set to a value we want
    // - store that value in redux (but NOT in localstorage!!) also (should store in window.session for this TAB only!)
    const last_register_key = uuidv4();
    // await KazooSDK.resourceDevicePatch({
    //   id: device.id,
    //   app_cio: {
    //     last_register_key,
    //   },
    // });
    dispatchWebphone({
      type: 'sip:update',
      payload: { device, last_register_key },
    });
    // dispatchRedux({
    //   type: 'SET_WEBPHONE_DEVICE',
    //   payload: device,
    // });
    dispatchRedux(webphoneActions.set_device(device));
  };

  // handle autoRegister
  // - ie, do not re-register!
  // const firstRegister = useRef(null);
  // - TODO: figure out how to run this in a more intelligent way...current seems error-prone to rely on react rendering
  useEffect(() => {
    (async () => {
      if (isLineConnected && isLineConnected()) {
        //} && sipStatus !== SIP_STATUS_REGISTERED) {

        // // TODO: ensure the register key is correct (ie, latest on the device!)
        // const resp = await KazooSDK.resourceDeviceGet(device.id);
        // const d = resp.data.data;

        // // disabled device?
        // if (!d.enabled) {
        //   return;
        // }

        // // verify that we have a valid "last_register_key" value!
        // if (d.app_cio?.last_register_key === state.last_register_key) {
        //   console.log('registering webphone cuz last_register_key is the same');
        registerSip();
        // } else {
        //   console.log(
        //     'NOT REGISTERING cuz webphone last_register_key has changed',
        //   );
        // }
      } else {
        // console.log('Line not connected, not registering');
      }
    })();
  }, [isLineConnected && isLineConnected(), webphoneState?.last_register_key]);

  // on disconnect, remove the device
  // TODO: have a different way of handling?
  useEffect(() => {
    if (getErrorMessage && getErrorMessage() === 'disconnected') {
      console.log('Disconnected, clearing (to trip ua.stop)');
      dispatchWebphone({
        type: 'sip:clear',
        payload: {},
      });
      // dispatchRedux({
      //   type: 'SET_WEBPHONE_DEVICE',
      //   payload: null,
      // });
      dispatchRedux(webphoneActions.set_device(null));
    }
  }, [getErrorMessage && getErrorMessage()]);

  // refs
  const localVideoEl = React.useRef(null);
  const remoteVideoEl = React.useRef(null);
  const localConferenceVideoEl = React.useRef(null);
  const remoteConferenceVideoEl = React.useRef(null);

  // const isDialer = calls?.length === 0;
  const incomingCallRinging = calls?.find(call => call.isRinging() === true);
  // const isRinging = incomingCallRinging !== undefined && calls?.length === 1;
  // const isActive = !isDialer && !isRinging;
  let activeCall = calls?.find(call => call.isMediaActive() === true);
  if (activeCall === undefined && calls?.length > 0) {
    activeCall = calls[calls.length - 1];
  }

  const changeTab = newTab => {
    dispatchWebphone({
      type: 'sip:update',
      payload: {
        mainTab: newTab,
      },
    });
  };

  useEffect(() => {
    const changeToCalls = () => changeTab('calls');
    eventBus.on('started-call', changeToCalls);
    return () => {
      eventBus.removeListener('started-call', changeToCalls);
    };
  }, []);

  // show dialer when there is a new incoming call
  useEffect(() => {
    if (incomingCallRinging) changeTab('calls');
  }, [incomingCallRinging]);

  useEffect(() => {
    if (calls?.length === 0 && showWsVideo) {
      setWsVideo(false);
    }
    //   if (activeCall && !hasDetailView && activeCall.hasLocalVideo()) {
    //     setDetailView(true);
    //   }
  }, [calls?.length, showWsVideo]);

  // const toggleDetailView = () => {
  //   setDetailView(!hasDetailView);
  // };
  const callEventHandler = (event, params) => {
    const call = params.obj;
    switch (event) {
      case 'input.stream.opened':
      case 'input.stream.modified':
        if (call !== undefined && params.video) {
          const stream = call.getInputMediaStream();
          localVideoEl.current.srcObject = stream;
        }
        break;
      case 'output.stream.opened':
      case 'output.stream.modified':
        if (call !== undefined && params.video) {
          const stream = call.getOutputMediaStream();
          remoteVideoEl.current.srcObject = stream;
        }
        break;
    }
  };

  const onDial = (target, isVideoCall) => {
    makeCall(target, isVideoCall, {}, callEventHandler);
    changeTab('calls');
  };
  const onDialConference = (target, isVideoCall, additionalInfo) => {
    makeCall(target, isVideoCall, additionalInfo, callEventHandler);
    changeTab('calls');
  };
  const onAnswer = isVideoCall => {
    incomingCallRinging.accept(true, isVideoCall, callEventHandler);
  };

  const handleTabChange = (event, newValue) => {
    // setTabValue(newValue);
    changeTab(newValue);
  };

  const onExpandVideo = () => {
    setWsVideo(true);
  };
  const onCloseFullView = onEndCall => {
    if (onEndCall) {
      activeCall.hangup();
    }
    setWsVideo(false);
  };

  const { device: webphone_device } = useWebphoneSelector();
  // const { webphone_device } = useSelector(state => ({
  //   webphone_device: state.tmp.webphone_device,
  // }));
  // console.log('webphone_device:', webphone_device);
  // console.log('sip:', sip);

  if (!webphone_device) {
    return (
      <Container style={{ height: '100vh', background: '#F8FDFD' }}>
        <Grid container style={{ height: '100%' }} alignItems="center">
          <Grid item>
            <div style={{ textAlign: 'center', padding: '0 12px' }}>
              <CircularProgress size={40} />
              <br />
              <Typography variant="h6">Connecting Webphone...</Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }

  if (webphoneState.device?.doc?.enabled === false) {
    return (
      <Container style={{ height: '100vh', background: '#F8FDFD' }}>
        <Grid container style={{ height: '100%' }} alignItems="center">
          <Grid item>
            <div style={{ padding: '0 12px' }}>
              <Alert severity="error">
                <Typography variant="body1">
                  Webphone Device Disabled:
                </Typography>
                <Typography variant="h6">
                  {webphoneState.device?.doc?.name ?? 'Unknown Webphone Device'}
                </Typography>
              </Alert>
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }

  // TODO: favicon handler for updating to show that this tab has the webphone active
  // - add a little "phone" to this tab (changing the favicon only affects this tab!)
  // - good example: https://reactgo.com/react-change-favicon-dynamically/

  return (
    <Box sx={{ height: '100%' }}>
      {isRegistered ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',

            flexWrap: 'no-wrap',
            height: '100vh',
            background: '#F8FDFD',
          }}
        >
          <Box
            style={{
              color: 'white',
            }}
            sx={{
              boxShadow: '-20px -40px -30px -30px rgba(27, 125, 144, 0.7)',
            }}
          >
            <Tabs
              sx={{
                background: '#1B7D90',
                // height: '80px',
                zIndex: 100,
                alignItems: 'center',
              }}
              variant={'fullWidth'}
              value={mainTab}
              onChange={handleTabChange}
              textColor={'inherit'}
              TabIndicatorProps={{ sx: { display: 'none' } }}
            >
              <Tab
                value="calls"
                label={'Calls'}
                icon={<PhoneIcon fontSize={'large'} />}
                style={{ textTransform: 'none', minWidth: 'auto' }}
              />
              <Tab
                value="history"
                label={'Recent'}
                icon={<HistoryIcon fontSize={'large'} />}
                style={{ textTransform: 'none', minWidth: 'auto' }}
              />
              <Tab
                value="contacts"
                label={'Contacts'}
                icon={<AccountBoxIcon fontSize={'large'} />}
                style={{ textTransform: 'none', minWidth: 'auto' }}
              />
              <Tab
                value="dialer"
                label={'Dialer'}
                icon={<DialpadIcon fontSize={'large'} />}
                style={{ textTransform: 'none', minWidth: 'auto' }}
              />
              {/*<Tab
                value="parking"
                label={`Parking (${parked_calls.length})`}
                style={{ textTransform: 'none', minWidth: 'auto' }}
              />*/}
              <Tab
                icon={<SettingsIcon fontSize={'large'} />}
                value="settings"
                label={'Settings'}
                style={{ textTransform: 'none', minWidth: 'auto' }}
              />
            </Tabs>
          </Box>
          <div>
            <Divider />
          </div>
          <div
            style={{
              flex: 1,
              overflowY: 'hidden',
              width: 480,
              background: '#F8FDFD',
            }}
          >
            <DialView
              show={mainTab === 'dialer' ? true : false}
              dial={onDial}
              dialConference={onDialConference}
            />
            <CallsView
              show={mainTab === 'calls' ? true : false}
              // toggleDetailView={toggleDetailView}
              localVideoRef={localVideoEl}
              remoteVideoRef={remoteVideoEl}
            />
            <ContactsView
              show={mainTab === 'contacts' ? true : false}
              dial={onDial}
              dialConference={onDialConference}
              // toggleDetailView={toggleDetailView}
            />
            <CallHistoryView
              show={mainTab === 'history' ? true : false}
              dial={onDial}
              dialConference={onDialConference}
              // toggleDetailView={toggleDetailView}
            />
            <ParkingView
              show={mainTab === 'parking' ? true : false}
              // toggleDetailView={toggleDetailView}
            />
            <SettingsView
              show={mainTab === 'settings' ? true : false}
              // toggleDetailView={toggleDetailView}
            />
          </div>
          {/* TODO: instead of passing in the ref,
          the components below should request a copy
          of the video stream and point it at their
          own <video> elements  */}
          {/* Active Call Detail (Video-only) */}

          <CallDetailView
            call={activeCall}
            wsVideo={showWsVideo}
            onExpand={onExpandVideo}
            localVideoRef={localVideoEl}
            remoteVideoRef={remoteVideoEl}
          />
          {showWsVideo && (
            <VideoFullView call={activeCall} onStop={onCloseFullView} />
          )}
          <Permissions />
          {/* Conference Room (when maximized) */}
          {/* {activeCall?._additionalInfo?.conference ? ( */}
          {/*
          TODO: UNCOMMENT THIS NICK (only commented out to get working)
          <ConferenceRoom
            // conference={activeCall._additionalInfo.conference}
            call={activeCall}
            localVideoRef={localConferenceVideoEl}
            remoteVideoRef={remoteConferenceVideoEl}
          />*/}
        </div>
      ) : (
        <>
          {/* View for "Registering"  */}
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ height: '100%', padding: 3 }}
          >
            <Grid item>
              <Box sx={{ maxWidth: '280px', textAlign: 'center' }}>
                <>
                  <CircularProgress />
                  <Typography variant="h2" component="div">
                    Registering
                  </Typography>
                  <Typography variant="caption" component="div">
                    {sip?.status}
                    <br />
                    {sip?.status === `sipStatus/ERROR` &&
                      ` (${sip?.errorMessage})`}
                  </Typography>
                </>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default WebphoneUI;
