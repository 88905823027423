import { Chip, Divider, Tooltip, Typography } from 'app/design';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { NotificationEmailsDialog } from 'app/pages/admin/Vmboxes/View/components/VmboxNotificationsSettingsCard/components/NotificationEmailsDialog';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { Vmbox } from 'types/vmbox';

interface NotificationEmailsProps {
  vmbox: Vmbox;
  onComplete: () => void;
}

const NotificationEmails = ({ vmbox, onComplete }: NotificationEmailsProps) => {
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'notificationEmails',
    options: [
      {
        label: 'Notification Emails',
        value: 'notificationEmails',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });
  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);

  const handleOnComplete = () => {
    onComplete();
    toggleShowEditDialog();
  };

  const emails = vmbox.doc.notify_email_addresses;

  return (
    // @ts-ignore
    <div ref={ref}>
      <HighlightPulse {...highlightPulseProps} />
      {showEditDialog ? (
        <NotificationEmailsDialog
          onCancel={toggleShowEditDialog}
          onComplete={handleOnComplete}
          vmboxId={vmbox.id}
        />
      ) : null}
      <GenericSettingsCardField
        title={'Notification on New Message'}
        caption={
          "List of email addresses to send notifications to (in addition to owner's email, if set)"
        }
        content={
          emails?.length ? (
            <>
              {emails[0]}{' '}
              {emails.length > 1 ? (
                <Tooltip
                  title={
                    <>
                      <Typography variant="h4">All Phone Numbers:</Typography>
                      <Divider />
                      {emails.map(email => (
                        <div key={email}>
                          <Typography variant="number1">{email}</Typography>
                        </div>
                      ))}
                    </>
                  }
                >
                  <Chip
                    label={`+${emails.length - 1}`}
                    color="primary"
                    size="small"
                  />
                </Tooltip>
              ) : null}
            </>
          ) : undefined
        }
        editLabel={'Edit emails'}
        editOnClick={toggleShowEditDialog}
      />
    </div>
  );
};

export default NotificationEmails;
