import * as Sentry from '@sentry/react';
import { Onboarding } from 'app/components/Onboarding';
import { useAccountQuery } from 'app/hooks/queries/account';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { AdminAccountCalling as AdminAccountCalling2 } from 'app/pages/admin/AccountCalling2';
import { AdminLiveCalls } from 'app/pages/admin/LiveCalls';
import React, { lazy, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Layout } from './components/Layout';
import { NotFoundPage } from './components/NotFoundPage/Loadable';

import { useAuthSelector } from './data/auth';

const AdminDashboard = lazy(
  () => import('app/pages/admin/Dashboard/Dashboard'),
);
const AdminAccountCalling = lazy(
  () => import('app/pages/admin/AccountCalling/AccountCalling'),
);
const AdminUsersCreate = lazy(
  () => import('app/pages/admin/Users/Create/Create'),
);
const AdminPhonesCreate = lazy(
  () => import('app/pages/admin/Phones/Create/Create'),
);
const AdminPhonesBuy = lazy(() => import('app/pages/admin/Phones/Buy/Buy'));
const AdminUsersList = lazy(() => import('app/pages/admin/Users/List/List'));
const AdminDevicesList = lazy(
  () => import('app/pages/admin/Devices/List/List'),
);
const Login = lazy(() => import('app/pages/auth/Login/Login'));
const Logout = lazy(() => import('app/pages/auth/Logout/Logout'));
const ForgotPassword = lazy(
  () => import('app/pages/auth/ForgotPassword/ForgotPassword'),
);
const AdminUserView = lazy(() => import('app/pages/admin/Users/View/View'));
const OfficeVoicemailsView = lazy(
  () => import('app/pages/office/Voicemails/View'),
);

const AdminConferencesList = lazy(
  () => import('app/pages/admin/Conferences/List/List'),
);
const AdminConferenceView = lazy(
  () => import('app/pages/admin/Conferences/View/View'),
);
const EditCallHandlingAccount = lazy(
  () =>
    import(
      'app/pages/admin/Dashboard/EditCallHandlingAccount/EditCallHandlingAccount'
    ),
);
const EditCallHandlingDevice = lazy(
  () =>
    import(
      'app/pages/admin/Devices/View/EditCallHandlingDevice/EditCallHandlingDevice'
    ),
);
const AdminDirectoryView = lazy(
  () => import('app/pages/admin/Directories/View/View'),
);
const AdminExtensionsList = lazy(
  () => import('app/pages/admin/Extensions/List/List'),
);
const AdminGroupView = lazy(() => import('app/pages/admin/Groups/View/View'));
const EditCallHandlingGroup = lazy(
  () =>
    import(
      'app/pages/admin/Groups/View/EditCallHandlingGroup/EditCallHandlingGroup'
    ),
);
const AdminIvrExtensionsList = lazy(
  () => import('app/pages/admin/IvrExtensions/List/List'),
);
const AdminIvrExtensionView = lazy(
  () => import('app/pages/admin/IvrExtensions/View/View'),
);
const AdminIvrEdit = lazy(() => import('app/pages/admin/Ivr/Edit/Edit'));
const AdminScreenpopEdit = lazy(
  () => import('app/pages/admin/Screenpops/Edit/Edit'),
);
const AdminTemplateEdit = lazy(
  () => import('app/pages/admin/Templates/Edit/Edit'),
);
const EditCallHandlingUser = lazy(
  () =>
    import(
      'app/pages/admin/Users/View/EditCallHandlingUser/EditCallHandlingUser'
    ),
);
const AdminVmboxesList = lazy(
  () => import('app/pages/admin/Vmboxes/List/List'),
);
const AdminVmboxView = lazy(() => import('app/pages/admin/Vmboxes/View/View'));
const AdminBillingHome = lazy(
  () => import('app/pages/admin/Billing/Home/Home'),
);
const AdminBillingSummary = lazy(
  () => import('app/pages/admin/Billing/Summary/Summary'),
);
const AdminBillingIntegrations = lazy(
  () => import('app/pages/admin/Billing/Integrations/Integrations'),
);
const AdminContactsHome = lazy(
  () => import('app/pages/admin/Contacts/Home/Home'),
);
const AdminMessagingHome = lazy(
  () => import('app/pages/admin/Messaging/Home/Home'),
);
const EditCallHandlingVmbox = lazy(
  () =>
    import(
      'app/pages/admin/Vmboxes/View/EditCallHandlingVmbox/EditCallHandlingVmbox'
    ),
);
const OfficeCallHistoryView = lazy(
  () => import('app/pages/office/CallHistory/View'),
);
const OfficeDashboardView = lazy(
  () => import('app/pages/office/Dashboard/View'),
);

const AdminAllCallflowsList = lazy(
  () => import('app/pages/admin/AllCallflows/List/List'),
);
const AdminCallFinder = lazy(
  () => import('app/pages/admin/CallLogsAndReports/CallFinder/CallFinder'),
);
const AdminCallLogsAndReports = lazy(
  () => import('app/pages/admin/CallLogsAndReports/CallLogsAndReports'),
);
const AdminDirectoriesList = lazy(
  () => import('app/pages/admin/Directories/List/List'),
);
const AdminMenusList = lazy(() => import('app/pages/admin/Menus/List/List'));
const AdminPhoneNumbersList = lazy(
  () => import('app/pages/admin/PhoneNumbers/List/List'),
);
const AdminScreenpopsList = lazy(
  () => import('app/pages/admin/Screenpops/List/List'),
);
const AdminTemplatesList = lazy(
  () => import('app/pages/admin/Templates/List/List'),
);
const AdminDeviceView = lazy(() => import('./pages/admin/Devices/View/View'));
const AdminMediaList = lazy(() => import('./pages/admin/Media/List/List'));
const AdminSchedulesList = lazy(
  () => import('./pages/admin/Schedules/List/List'),
);
const AdminGroupsList = lazy(() => import('app/pages/admin/Groups/List/List'));
const Dev = lazy(() => import('app/pages/Dev/Dev'));

function Routing() {
  const { show_login_dialog, loggedIn, owner_id, account_id } =
    useAuthSelector();

  const { data: account } = useAccountQuery();
  const { isLoading, data: user } = useAuthenticatedUserQuery();

  useEffect(() => {
    if (loggedIn) {
      Sentry.setUser({
        ip_address: '{{auto}}',
        // @ts-ignore
        id: owner_id ?? undefined,
        account_id,
        email: user?.doc?.email,
        account: account?.doc?.name,
      });
    }
  }, [account, user, loggedIn, owner_id, account_id]);

  return (
    <>
      {loggedIn ? (
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              if (isLoading) {
                console.log('Loading user information before redirecting');
                return null;
              }
              return user?.doc?.priv_level === 'admin' ? (
                <Redirect to="/admin" />
              ) : (
                <Redirect to="/office" />
              );
            }}
          />
          <Route path="/onboarding" component={Onboarding} />
          <Route path="/office" component={OfficeRouting} />
          <Route path="/admin" component={AdminRouting} />
          <Route path="/dev" component={Dev} />
          <Route path="/logout" component={Logout} />
          {/* Redirecting login below (might not be necessary...) */}
          <Route exact path="/login" render={() => <Redirect to="/" />} />
          <Route component={NotFoundPage} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route render={() => <Redirect to="/login" />} />
        </Switch>
      )}
    </>
  );
}

const OfficeRouting = () => {
  return (
    <Layout>
      <Switch>
        <Route exact path="/office" component={OfficeDashboardView} />
        <Route path="/office/dashboard" component={OfficeDashboardView} />
        <Route path="/office/voicemails" component={OfficeVoicemailsView} />
        <Route path="/office/call_history" component={OfficeCallHistoryView} />
        <Route component={NotFoundPage} />
      </Switch>
    </Layout>
  );
};

const AdminRouting = () => {
  // if not admin, disallow
  // - wait for USER info to exist, check if priv_role = 'admin' ?

  //   if (!userIsAdmin) {
  //     return <>Not authorized to view</>;
  //   }

  return (
    <Layout>
      <Switch>
        <Route exact path="/admin" component={AdminDashboard} />
        <Route
          exact
          path="/admin/callflows"
          component={AdminAllCallflowsList}
        />
        <Route exact path="/admin/dashboard" component={AdminDashboard} />
        <Route exact path="/admin/calling2" component={AdminAccountCalling} />
        <Route exact path="/admin/calling" component={AdminAccountCalling2} />
        <Route exact path="/admin/live" component={AdminLiveCalls} />
        <Route
          exact
          path="/admin/dashboard/call_handling"
          component={EditCallHandlingAccount}
        />
        <Route
          exact
          path="/admin/calllogs"
          component={AdminCallLogsAndReports}
        />
        <Route
          exact
          path="/admin/calllogs/callfinder"
          component={AdminCallFinder}
        />
        <Route exact path="/admin/users/create" component={AdminUsersCreate} />
        <Route exact path="/admin/users" component={AdminUsersList} />
        <Route
          path="/admin/users/view/:user_id/call_handling"
          component={EditCallHandlingUser}
        />
        <Route path="/admin/users/view/:user_id" component={AdminUserView} />
        <Route
          exact
          path="/admin/phones/create"
          component={AdminPhonesCreate}
        />
        <Route exact path="/admin/phones/buy" component={AdminPhonesBuy} />
        <Route exact path="/admin/devices" component={AdminDevicesList} />
        <Route
          path="/admin/devices/view/:device_id/call_handling"
          component={EditCallHandlingDevice}
        />
        <Route
          path="/admin/devices/view/:device_id"
          component={AdminDeviceView}
        />
        <Route
          exact
          path="/admin/conferences"
          component={AdminConferencesList}
        />
        <Route
          path="/admin/conferences/view/:conference_id"
          component={AdminConferenceView}
        />
        <Route
          exact
          path="/admin/directories"
          component={AdminDirectoriesList}
        />
        <Route
          path="/admin/directories/view/:directory_id"
          component={AdminDirectoryView}
        />
        <Route exact path="/admin/extensions" component={AdminExtensionsList} />
        <Route exact path="/admin/groups" component={AdminGroupsList} />
        <Route
          path="/admin/groups/view/:group_id/call_handling"
          component={EditCallHandlingGroup}
        />
        <Route
          exact
          path="/admin/groups/view/:group_id"
          component={AdminGroupView}
        />
        <Route
          exact
          path="/admin/ivr_extensions"
          component={AdminIvrExtensionsList}
        />
        <Route
          path="/admin/ivr_extensions/view/:ivrExtension_id"
          component={AdminIvrExtensionView}
        />
        <Route path="/admin/ivr/edit/:id" component={AdminIvrEdit} />
        <Route exact path="/admin/media" component={AdminMediaList} />
        <Route exact path="/admin/menus" component={AdminMenusList} />
        <Route exact path="/admin/numbers" component={AdminPhoneNumbersList} />
        <Route exact path="/admin/schedules" component={AdminSchedulesList} />
        <Route exact path="/admin/screenpops" component={AdminScreenpopsList} />
        <Route
          path="/admin/screenpop/edit/:id"
          component={AdminScreenpopEdit}
        />
        <Route
          exact
          path="/admin/callflow_templates"
          component={AdminTemplatesList}
        />
        <Route path="/admin/template/edit/:id" component={AdminTemplateEdit} />
        <Route exact path="/admin/vmboxes" component={AdminVmboxesList} />
        <Route
          path="/admin/vmboxes/view/:vmbox_id/call_handling"
          component={EditCallHandlingVmbox}
        />
        <Route
          path="/admin/vmboxes/view/:vmbox_id"
          component={AdminVmboxView}
        />
        <Route path="/admin/contacts" component={AdminContactsHome} />
        <Route path="/admin/messaging" component={AdminMessagingHome} />
        <Route
          path="/admin/billing/integrations"
          component={AdminBillingIntegrations}
        />
        <Route path="/admin/billing/summary" component={AdminBillingSummary} />
        <Route path="/admin/billing" component={AdminBillingHome} />
        {/* <Route path="/admin/billing" component={AdminVmboxView} /> */}
        {/* <Route exact path="/users" component={AdminUsersList} /> */}
        <Route component={NotFoundPage} />
      </Switch>
    </Layout>
  );
};

export default Routing;
