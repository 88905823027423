import { useAuthSelector } from 'app/data/auth';
import accountQueryKey from 'app/hooks/queries/provisioner/provisionerQueryKey';
import { sdk } from 'app/sdk';
import { useQuery } from 'react-query';

interface Props {
  brand?: string;
  family?: string;
  model?: string;
}

export const useProvisionerPhoneModelQuery = ({
  brand,
  family,
  model,
}: Props) =>
  // options?: Omit<
  //   UseQueryOptions<Account, Error, Account, QueryKey>,
  //   'queryKey' | 'queryFn'
  // >,
  {
    const authState = useAuthSelector();
    const queryKey = accountQueryKey;

    const query = useQuery(
      queryKey,
      () =>
        sdk.provisioner.query.provisionerPhoneModel(
          { brand, family, model },
          { authToken: authState?.auth_token },
        ),
      {
        // only enable if all present
        enabled: !!(brand && family && model),
        // ...options,
      },
    );

    return {
      ...query,
      queryKey,
    };
  };

export default useProvisionerPhoneModelQuery;
