import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from 'app/design';
import useDialogBuilder from 'app/utilities/useDialog';

import React, { useEffect, useState } from 'react';

interface DefaultDialogActionsProps {
  onSave?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
  saveLabel?: string;
  cancelLabel?: string;
  deleteLabel?: string;
  errorMessage?: string;
  saveDisabled?: boolean;
}

const DefaultDialogActions = ({
  saveLabel = 'Save',
  cancelLabel = 'Cancel',
  deleteLabel = 'Delete',
  saveDisabled = false,
  onSave,
  onCancel,
  onDelete,
  errorMessage,
}: DefaultDialogActionsProps) => {
  const [showConfirmDeleteDialog, setConfirmDeleteDialog] = useState(false);

  const handleConfirm = () => {
    setConfirmDeleteDialog(false);
    onDelete && onDelete();
  };
  return (
    <>
      {showConfirmDeleteDialog && (
        <ConfirmationDialog
          onClose={() => setConfirmDeleteDialog(false)}
          onCancel={() => setConfirmDeleteDialog(false)}
          onConfirm={handleConfirm}
        />
      )}
      <DialogActions>
        <Grid
          container
          spacing={1}
          wrap={'nowrap'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {onCancel && (
            <Grid item>
              <Button onClick={onCancel} color="error" variant="outlined">
                {cancelLabel}
              </Button>
            </Grid>
          )}
          <Grid item style={{ flex: 1 }}>
            <div style={{ display: 'grid', placeItems: 'center' }}>
              <Typography color={'error'}>{errorMessage}</Typography>
            </div>
          </Grid>
          {onDelete && (
            <Grid item>
              <Button
                onClick={() => setConfirmDeleteDialog(true)}
                color="error"
                variant="outlined"
              >
                {deleteLabel}
              </Button>
            </Grid>
          )}
          {onSave && (
            <Grid item>
              <Button
                onClick={onSave}
                disabled={saveDisabled}
                variant="outlined"
                color="success"
              >
                {saveLabel}
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </>
  );
};

export const ConfirmationDialog = ({
  onConfirm,
  onCancel,
  onClose,
  label = null,
  title = null,
  open = true,
  isLoading = false,
  loadingLabel = null,
}) => {
  console.log('confirmation dialog');
  useEffect(() => {
    console.log('dialog mount');

    return () => console.log('dialog unmount');
  }, []);

  return (
    <Dialog open={open} fullWidth maxWidth={'sm'}>
      {isLoading ? (
        <DialogInProgress title={loadingLabel ?? 'Loading...'} />
      ) : (
        <>
          <DialogTitle>{title ?? `Confirm`}</DialogTitle>

          <DialogContent dividers>
            <div style={{ display: 'grid', placeItems: 'center' }}>
              <Typography variant="h6">
                {label ??
                  `Are you sure you would like to delete this ${'item'}?`}
              </Typography>
            </div>
          </DialogContent>

          <DefaultDialogActions
            onSave={onConfirm}
            saveLabel={'Confirm'}
            onCancel={onCancel}
          />
        </>
      )}
    </Dialog>
  );
};

export const useConfirmationDialog = (initialOpen = false) => {
  const useDialog = useDialogBuilder(ConfirmationDialog);
  return useDialog({ initialOpen });
};

export default DefaultDialogActions;
