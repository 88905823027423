import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { Menu } from 'types/menu';
import { PhoneNumber } from 'types/phoneNumber';
import phoneNumberQueryKeys from './phoneNumberQueryKeys';

interface QueryParams {
  id?: string | null;
  options?: Omit<
    UseQueryOptions<PhoneNumber, Error, PhoneNumber, QueryKey>,
    'queryKey' | 'queryFn'
  >;
}

export const usePhoneNumberQuery = ({ id, options }: QueryParams) => {
  const authState = useAuthSelector();
  const queryKey = phoneNumberQueryKeys.byId(id);

  const query = useQuery<PhoneNumber, Error>(
    queryKey,
    () =>
      sdk.phoneNumber.query.phoneNumberById(
        { id },
        { authToken: authState.auth_token },
      ),
    {
      ...options,
      enabled: options?.enabled ?? !!id,
    },
  );

  return {
    ...query,
    queryKey,
  };
};

export default usePhoneNumberQuery;
