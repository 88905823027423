import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { Device } from 'types/device';

interface HoldMusicDeviceProps {
  device: Device;
  onComplete: () => void;
}

const RestrictionsDevice = ({ device, onComplete }: HoldMusicDeviceProps) => {
  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'restrictions',
    options: [
      {
        label: 'Restrictions',
        value: 'restrictions',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const handleOnComplete = () => {
    onComplete();
    toggleShowEditDialog();
  };

  return (
    // @ts-ignore
    <div ref={ref}>
      <HighlightPulse {...highlightPulseProps} />
      <GenericSettingsCardField title={'Restrictions'} editOnClick={() => {}} />
    </div>
  );
};

export default RestrictionsDevice;
