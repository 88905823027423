import { joiResolver } from '@hookform/resolvers/joi';
import {
  Dialog,
  DialogContent,
  DialogInProgress,
  DialogTitle,
  Grid,
  InfoTooltip,
} from 'app/design';
import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { HookFormMediaSelect } from 'app/components/reactHookFormComponents/HookFormMediaSelect';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { useCreateMenu, useUpdateMenuPartial } from 'app/hooks/mutations/menu';
import { useMenuQuery } from 'app/hooks/queries/menu';
import { DialogBuilder } from 'app/utilities';
import { useMarkdownTranslate } from 'app/utilities/translate';
import Joi from 'joi';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

// schema for form validation. Passed to useForm to only trigger submit when
//  - the below conditions are met. Any known serverside constraints (min/max,
//  - character limits, numbers only, etc.) should be added. Remove 128 max below
//  - to demonstrate serverside invalidation and handling
const schema = Joi.object({
  name: Joi.string().max(128).required(),
  timeout: Joi.number().required(),
  retries: Joi.number().required(),
  media: Joi.object({ greeting: Joi.string().optional() }).optional(),
});

interface MenuCreateForm {
  name: string;
  timeout: number;
  media?: {
    greeting?: string | null;
  };
  retries: number;
}

// interface declaring which props are required/allowed
interface MenuEditDialogProps {
  menuId?: string;
  onCancel: () => void;
  onComplete?: (menuId: string) => void;
}

const EditMenuDialog = ({
  menuId,
  onCancel,
  onComplete,
}: MenuEditDialogProps) => {
  const formMethods = useForm<MenuCreateForm>({
    defaultValues: {
      name: 'Menu',
      timeout: 10,
      retries: 3,
      media: {
        greeting: undefined,
      },
    },
    resolver: joiResolver(schema),
  });
  const { reset, watch, handleSubmit } = formMethods;
  const mediaId = watch('media.greeting');

  const { isLoading: menuIsLoading, refetch: refetchMenu } = useMenuQuery({
    id: menuId,
    options: {
      onSuccess: menu => {
        const { name, timeout, media, retries } = menu.doc;
        reset({
          name: name ?? 'Menu',
          timeout: timeout ? timeout / 1000 : 10,
          media: media ?? undefined,
          retries: retries ?? 3,
        });
      },
    },
  });
  const createMenu = useCreateMenu();
  const updateMenu = useUpdateMenuPartial();

  const handleSave = async (menuForm: MenuCreateForm) => {
    if (menuId) {
      const updateMenuPromise = updateMenu.mutateAsync({
        id: menuId,
        interdigit_timeout: 2000,
        max_extension_length: 4,
        hunt: false, // can dial extensions?
        allow_record_from_offnet: false, // record new menu from outside account?
        suppress_media: false, // dont play the "media is missing" message?
        ...menuForm,
        timeout: menuForm.timeout * 1000,
      });

      toast.promise(updateMenuPromise, {
        pending: 'Updating menu...',
        success: 'Menu updated!',
        error: 'Error updating menu.',
      });

      const resp = await updateMenuPromise;
      if (resp.status === 'success') {
        refetchMenu();
        if (onComplete) onComplete(resp.data.id);
      }
    } else {
      const createMenuPromise = createMenu.mutateAsync({
        ...menuForm,
        timeout: menuForm.timeout * 1000,
      });

      toast.promise(createMenuPromise, {
        pending: 'Creating menu...',
        success: 'Menu created!',
        error: 'Error creating menu.',
      });

      const resp = await createMenuPromise;
      if (resp.status === 'success') {
        if (onComplete) onComplete(resp.data.id);
      }
    }
  };

  const { t } = useMarkdownTranslate();

  return (
    <Dialog open={true} fullWidth maxWidth={'sm'}>
      {createMenu.isLoading || updateMenu.isLoading || menuIsLoading ? (
        <DialogInProgress
          title={
            menuIsLoading
              ? 'Loading menu...'
              : `${menuId ? 'Updating' : 'Creating'} Menu...`
          }
        />
      ) : (
        <>
          <DialogTitle>{menuId ? 'Edit' : 'Create'} Menu</DialogTitle>
          <DialogContent dividers>
            <FormProvider {...formMethods}>
              <HookFormTextField name={'name'} label={'Name'} />
              <br />
              <br />
              <Grid container spacing={1} alignItems={'center'}>
                <Grid item xs={6}>
                  <HookFormTextField
                    type={'number'}
                    name={'retries'}
                    label={'Retries'}
                    InputProps={{
                      endAdornment: (
                        <InfoTooltip title={t('menu.retries.tooltip')} />
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <HookFormTextField
                    type={'number'}
                    name={'timeout'}
                    label={'Timeout (in seconds)'}
                    InputProps={{
                      endAdornment: (
                        <InfoTooltip title={t('menu.timeout.tooltip')} />
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={11}>
                <br />
                <HookFormMediaSelect
                  name={'media.greeting'}
                  label={'Greeting'}
                  editLabel={mediaId ? 'Change Greeting' : 'Add Greeting'}
                />
              </Grid>
            </FormProvider>
          </DialogContent>
          <DefaultDialogActions
            onSave={handleSubmit(handleSave)}
            saveLabel={`${mediaId ? 'Update' : 'Create'} Menu`}
            onCancel={onCancel}
          />
        </>
      )}
    </Dialog>
  );
};

export const useEditMenuDialog = DialogBuilder(EditMenuDialog);

export default EditMenuDialog;
