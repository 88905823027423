import { Button, Chip, Grid, InfoTooltip, Link, Typography } from 'app/design';
import { Add as AddIcon } from 'app/design/icons-material';
import { EmptyTableCell } from 'app/components/EmptyTableCell';
import { useFindInPage } from 'app/components/FindInPage';
import { QuickFinderGroupDialog } from 'app/components/QuickFinderGroupDialog';
import { useAccountQuery } from 'app/hooks/queries/account';
import UserTimezoneDialog from 'app/pages/admin/Users/View/components/UserSettingsCard/components/UserTimezoneDialog/UserTimezoneDialog';
import { getTimezoneName, useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { User } from 'types/user';
import { useMarkdownTranslate } from 'app/utilities/translate';

interface UserTimezoneProps {
  user: User;
}

const UserTimezone = ({ user }: UserTimezoneProps) => {
  const { t } = useMarkdownTranslate();
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'timezone',
    options: [
      {
        label: 'Timezone',
        value: 'timezone',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });
  const [showDialog, toggleShowDialog] = useToggleReducer();

  const accountQuery = useAccountQuery();

  const usedTimezone = user.doc.timezone ?? accountQuery.data?.doc?.timezone;

  return (
    <>
      {showDialog ? (
        <UserTimezoneDialog
          userId={user.id}
          onCancel={toggleShowDialog}
          onComplete={toggleShowDialog}
        />
      ) : null}
      {/* @ts-ignore */}
      <div ref={ref}>
        <HighlightPulse {...highlightPulseProps} />
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="h3">
                  Timezone{' '}
                  <InfoTooltip
                    // @ts-ignore
                    title={t('timezone.user.tooltip')}
                    size={'small'}
                  />
                </Typography>
              </Grid>
              {usedTimezone === user.doc.timezone ? null : (
                <Grid item>
                  <Chip
                    label={'Account'}
                    color={'primary'}
                    variant={'contained'}
                    size={'small'}
                  />
                </Grid>
              )}
            </Grid>
            {usedTimezone ? (
              <Typography variant="h4">
                {getTimezoneName(usedTimezone).replace('_', ' ')}
              </Typography>
            ) : (
              <Typography
                variant="h4"
                sx={{ fontStyle: 'italic', color: 'text.secondary' }}
              >
                Not set...
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Link variant="body2" onClick={toggleShowDialog} underline="hover">
              Edit Timezone
            </Link>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default UserTimezone;
