import {
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from 'app/design';
import {
  AccessTime as AccessTimeIcon,
  Dehaze as DehazeIcon,
  DragHandle as DragHandleIcon,
  Edit as EditIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  Group as GroupIcon,
  Person as PersonIcon,
  PhoneAndroid as PhoneAndroidIcon,
} from 'app/design/icons-material';
import { CollectionListHeader } from 'app/components/CollectionListHeader';
import { useListDevicesQuery } from 'app/hooks/queries/device';
import { useListUsersQuery } from 'app/hooks/queries/user';
import { getAtPath, setAtPath } from 'app/utilities';
import {
  preEndpointsToCallflowModuleFormat,
  preEndpointsToGroupFormat,
} from 'app/utilities/utils';
// import { useTranslation } from 'react-i18next';
// import { set } from 'lodash';
import { toInteger } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// import { fetchAllVmboxes } from '../../../../../Actions/voicemails';

import { AddComponent } from '../AddComponent';

// Select users from list of users
export const AllAtOnceComponent = props => {
  const {
    modifyPath,
    strategyCallflow,
    setStrategyCallflow,
    // users,
    // devices,
    defaultToFirst,
    ownerUser,
    // fetchUsers,
    allow,
    useGroupFormat, // vs. "ringgroup" Format (requires conversion! has restrictions!)
    onSave,
    startEditing,
    onReset,
    users,
    devices,
  } = props;

  const [isEditing, setIsEditing] = useState(startEditing ? true : false);

  const ringGroup = getAtPath(strategyCallflow, `${modifyPath}`, {
    endpoints: useGroupFormat ? {} : [],
    preEndpoints: [], // gets built into "endpoints" depending on the useGroupFormat
    timeout: 20,
    strategy: 'simultaneous',
  });

  const selectedEndpoints = getAtPath(
    strategyCallflow,
    `${modifyPath}.preEndpoints`,
    [],
  );

  const handleSave = () => {
    const data = getAtPath(strategyCallflow, `${modifyPath}`);

    let { preEndpoints = [] } = data;

    if (!data.timeout) {
      data.timeout = ringTimeout;
    }

    // rebuild preEndpoints timeout and delay
    // - assigns for Tiers
    let delay = 0;
    preEndpoints = preEndpoints.map(endpoint => {
      endpoint.delay = 0;
      endpoint.timeout = data.timeout; // group-level timeout
      return endpoint;
    });

    const endpoints = useGroupFormat
      ? preEndpointsToGroupFormat(preEndpoints)
      : preEndpointsToCallflowModuleFormat(preEndpoints);
    setAtPath(strategyCallflow, `${modifyPath}.endpoints`, endpoints);
    setAtPath(strategyCallflow, `${modifyPath}.preEndpoints`, preEndpoints);
    setStrategyCallflow({
      ...strategyCallflow,
    });
    console.log('AllAtOnce Endpoints Build:', endpoints);
    onSave && onSave();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const endpoints = reorder(
        selectedEndpoints,
        result.source.index,
        result.destination.index,
      );
      setAtPath(strategyCallflow, `${modifyPath}.preEndpoints`, endpoints);

      // TODO: convert according to useGroupFormat

      setStrategyCallflow({ ...strategyCallflow });

      // convert according to useGroupFormat
    },
    [selectedEndpoints, modifyPath, strategyCallflow, setStrategyCallflow],
  );

  useEffect(() => {
    // break apart tiers
    if (selectedEndpoints.find(e => e.endpoint_type === 'tier')) {
      let updatedEndpoints: any[] = [];
      selectedEndpoints.map(endpoint => {
        if (endpoint.endpoint_type === 'tier') {
          endpoint.tiers.map(tierEndpoint => {
            updatedEndpoints.push({
              ...tierEndpoint,
              timeout: endpoint.timeout,
              delay: endpoint.delay,
            });
          });
        } else {
          updatedEndpoints.push(endpoint);
        }
      });
      setAtPath(
        strategyCallflow,
        `${modifyPath}.preEndpoints`,
        updatedEndpoints,
      );
      setStrategyCallflow({
        ...strategyCallflow,
      });
    }
  }, [selectedEndpoints, modifyPath, strategyCallflow, setStrategyCallflow]);

  const handleAdd = new_endpoints_arr => {
    let currentOpts = {
      delay: 0,
      timeout: 10,
    };
    let newArr = new_endpoints_arr.map(t => ({ ...t, ...currentOpts }));

    const updatedEndpoints = [...selectedEndpoints, ...newArr];

    setAtPath(strategyCallflow, `${modifyPath}.preEndpoints`, updatedEndpoints);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const handleSetRingTimeout = timeoutInt => {
    setAtPath(strategyCallflow, `${modifyPath}.timeout`, timeoutInt);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const handleResetToChooser = () => {
    setAtPath(strategyCallflow, `${modifyPath}.type`, 'none');
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const ringTimeout = getAtPath(strategyCallflow, `${modifyPath}.timeout`, 20);

  return (
    <div
      style={{
        padding: 20,
        minWidth: 500,
      }}
    >
      <CollectionListHeader
        // telicon="device-fax"
        // teliconStyle={{ marginBottom: -4 }}
        icon={<DehazeIcon color={'primary'} style={{ fontSize: 20 }} />}
        title="All At Once"
        subtitle="Add users and devices that will all ring for the same amount of
        time."
        actionButton={
          isEditing ? (
            <>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    // startIcon={<EditIcon />}
                    disableRipple
                    onClick={handleResetToChooser}
                  >
                    Switch Ring Strategy
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="error"
                    variant="outlined"
                    size="small"
                    // startIcon={<EditIcon />}
                    disableRipple
                    onClick={e => {
                      setIsEditing(false);
                      onReset();
                    }}
                  >
                    Cancel Editing
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <IconButton
              //color="info"
              size="small"
              onClick={e => setIsEditing(true)}
            >
              <EditIcon />
            </IconButton>
          )
        }
      />
      <br />
      <Divider />
      <br />

      <Grid
        container
        style={{
          minWidth: 500,
        }}
      >
        <Grid item xs={6}>
          <Grid
            container
            direction={'column'}
            spacing={1}
            style={{ padding: 8 }}
          >
            <Grid item style={{ height: isEditing ? 90 : 60 }}>
              <>
                <div>
                  <Typography variant="h7">
                    <Grid container alignItems={'center'} spacing={1}>
                      <Grid item>
                        <div style={{ paddingTop: 4 }}>
                          <AccessTimeIcon color={'primary'} />
                        </div>
                      </Grid>
                      <Grid item>Total Ring Time (seconds)</Grid>
                    </Grid>
                  </Typography>
                </div>
                <div style={{ paddingLeft: 25 }}>
                  {isEditing ? (
                    <TextField
                      label=""
                      value={ringTimeout}
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      onChange={e =>
                        // setDefaultRingTotalTime(toInteger(e.target.value))
                        handleSetRingTimeout(toInteger(e.target.value))
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="dense"
                      variant="outlined"
                      size="small"
                      style={{ textAlign: 'right' }}
                    />
                  ) : (
                    <Typography variant="body1">{ringTimeout}</Typography>
                  )}
                </div>
              </>
            </Grid>
            <Grid item>
              <Typography variant="h7">
                <Grid container alignItems={'center'} spacing={1}>
                  <Grid item>
                    <div style={{ paddingTop: 4 }}>
                      <PersonIcon color={'primary'} />
                    </div>
                  </Grid>
                  <Grid item>Group Members</Grid>
                </Grid>
              </Typography>
              <div style={{ paddingLeft: 25 }}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {provided => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {selectedEndpoints.map((endpoint, i) => (
                          <RingConfig
                            key={endpoint.id}
                            index={i}
                            endpoint={endpoint}
                            users={users}
                            devices={devices}
                            timeout={ringGroup.timeout}
                            endpointsModifyPath={`${modifyPath}.preEndpoints`}
                            modifyPath={`${modifyPath}.preEndpoints[${i}]`}
                            strategyCallflow={strategyCallflow}
                            setStrategyCallflow={setStrategyCallflow}
                            provided={provided}
                            isEditing={isEditing}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {!selectedEndpoints.length ? (
                  <Typography
                    variant="body1"
                    style={{
                      fontStyle: 'italic',
                      opacity: 0.8,
                      textAlign: 'left',
                      paddingBottom: '16px',
                    }}
                  >
                    No users or devices added to group
                  </Typography>
                ) : (
                  ''
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
        {isEditing ? (
          <Grid item xs={6}>
            <Grid
              container
              direction={'column'}
              spacing={1}
              style={{
                borderLeft: '1px solid rgba(0,0,0,0.3)',
                paddingLeft: 8,
                height: '100%',
              }}
            >
              <>
                <AddComponent
                  {...props}
                  onAdd={handleAdd}
                  selectedEndpoints={selectedEndpoints}
                />
              </>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>

      {isEditing && (
        <>
          <br />
          <Divider />
          <br />
          <Grid container justifyContent="space-between" wrap={'nowrap'}>
            <Grid item>
              {!selectedEndpoints.length ? (
                <Typography variant="body1">
                  No users or devices added to group
                </Typography>
              ) : (
                ''
              )}
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="success"
                size="small"
                disableRipple
                onClick={e => {
                  handleSave();
                  setIsEditing(false);
                }}
              >
                Save Group Changes
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      {/*<br />*/}
    </div>
  );
};

const RingConfig = props => {
  const {
    index,
    endpoint,
    timeout,
    users,
    devices,
    endpointsModifyPath,
    modifyPath,
    strategyCallflow,
    setStrategyCallflow,
    isEditing,
  } = props;

  const value = [endpoint.delay, endpoint.timeout + endpoint.delay];

  const handleChange = useCallback(
    (e, value) => {
      // console.log('modifyPath:', modifyPath, value);
      setAtPath(strategyCallflow, `${modifyPath}.delay`, value[0]);
      setAtPath(strategyCallflow, `${modifyPath}.timeout`, value[1] - value[0]);
      setStrategyCallflow({ ...strategyCallflow });
    },
    [modifyPath, strategyCallflow, setStrategyCallflow],
  );

  const handleRemove = useCallback(
    (e, value) => {
      const endpointsArr = getAtPath(strategyCallflow, endpointsModifyPath);
      endpointsArr.splice(index, 1);
      setAtPath(strategyCallflow, `${endpointsModifyPath}`, endpointsArr);
      setStrategyCallflow({ ...strategyCallflow });
    },
    [index, endpointsModifyPath, strategyCallflow, setStrategyCallflow],
  );

  const user = users.find(d => d.id === endpoint.id);
  const device = devices.find(d => d.id === endpoint.id);

  return (
    <Draggable draggableId={endpoint.id} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Grid
            container
            spacing={1}
            style={{ marginBottom: 5 }}
            wrap={'nowrap'}
          >
            <Grid item>
              <Grid container wrap={'nowrap'}>
                <Grid item style={{ width: 34, display: 'none' }}>
                  <Button
                    {...provided.dragHandleProps}
                    style={{ minWidth: 0, padding: 0 }}
                  >
                    <DragHandleIcon style={{ color: 'gray' }} />
                  </Button>
                </Grid>
                <Grid
                  item
                  wrap={'nowrap'}
                  style={{
                    width: isEditing ? 200 : '100%',
                  }}
                >
                  <div
                    style={{
                      marginTop: 2,
                      width: '100%',
                      display: 'block',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {endpoint.endpoint_type === 'user' ? (
                      <Typography variant={'body1'} wrap={'nowrap'}>
                        <Grid
                          container
                          spacing={1}
                          alignItems={'center'}
                          wrap={'nowrap'}
                        >
                          <Grid item style={{ fontSize: 13 }}>
                            <div style={{ paddingTop: 5 }}>
                              <PersonIcon color={'primary'} />
                            </div>
                          </Grid>
                          <Grid
                            item
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {user?.extra?.fullName ?? 'Unknown User'}
                          </Grid>
                        </Grid>
                      </Typography>
                    ) : (
                      // <>{user?.extra?.fullName ?? 'Unknown User'}</>
                      ''
                    )}
                    {endpoint.endpoint_type === 'device' ? (
                      <Typography variant={'body1'}>
                        <Grid
                          container
                          spacing={1}
                          alignItems={'center'}
                          wrap={'nowrap'}
                        >
                          <Grid item style={{ fontSize: 13 }}>
                            <div style={{ paddingTop: 5 }}>
                              <PhoneAndroidIcon color={'primary'} />
                            </div>
                          </Grid>
                          <Grid
                            item
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {device?.doc?.name}
                          </Grid>
                        </Grid>
                      </Typography>
                    ) : (
                      // <>{device?.doc?.name}</>
                      ''
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs></Grid> */}

            {isEditing && (
              <Grid item style={{ width: 34, textAlign: 'left' }}>
                {/* @ts-ignore */}
                <IconButton
                  onClick={handleRemove}
                  // color="secondary"
                  // variant="outlined"
                  style={{ minWidth: 0, padding: 0, fontSize: 20 }}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </Draggable>
  );
};

const DividerWithText = ({ children }) => {
  return (
    <Grid container alignItems="center" wrap="nowrap">
      <Divider style={{ flex: '1 1 auto' }} />
      <span style={{ padding: '0 10px' }}>{children}</span>
      <Divider style={{ flex: '1 1 auto' }} />
    </Grid>
  );
};

export default AllAtOnceComponent; //connect(mapStateToProps, { fetchUsers })(RingGroup);
