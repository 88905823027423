import useMediaUrl from 'app/hooks/queries/media/useMediaUrl';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

interface AudioPlayerProps {
  mediaId: string;
}

const AudioPlayer = ({ mediaId }: AudioPlayerProps) => {
  const mediaUrl = useMediaUrl({ id: mediaId });
  const audioEl = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    audioEl.current?.load();
  }, [mediaId]);

  return (
    <div>
      <audio
        ref={audioEl}
        preload="metadata"
        controls
        controlsList={'nodownload'}
        style={{ height: 50 }}
        // controlsList='nodownload'
      >
        <source src={mediaUrl} />
        <p> Your browser doesn't support the audio tag </p>
      </audio>
    </div>
  );
};

export default AudioPlayer;
