import { Box, Button, Divider, Grid, Link, Typography } from 'app/design';
import { QuickFinderVmboxDialog } from 'app/components/QuickFinderVmboxDialog';
import { useVmboxQuery } from 'app/hooks/queries/vmbox';
import { VmboxAdditionalSettingsCard } from 'app/pages/admin/Vmboxes/View/components/VmboxAdditionalSettingsCard';
import { VmboxNotificationsSettingsCard } from 'app/pages/admin/Vmboxes/View/components/VmboxNotificationsSettingsCard';
import { VmboxOwner } from 'app/pages/admin/Vmboxes/View/components/VmboxOwner';
import { VmboxSettingsCard } from 'app/pages/admin/Vmboxes/View/components/VmboxSettingsCard';
import { GreetingVmbox } from 'app/pages/admin/Vmboxes/View/components/VmboxSettingsCard/components/GreetingVmbox';
import { TempGreetingVmbox } from 'app/pages/admin/Vmboxes/View/components/VmboxSettingsCard/components/TempGreetingVmbox';
import { setAtPath, useToggleReducer } from 'app/utilities';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { CallflowDoc } from 'types/callflow';
import { Vmbox } from 'types/vmbox';
import { NodeEditDialog } from '../../../../Dialogs';
import { ExpandLess as ExpandLessIcon } from 'app/design/icons-material';

interface ModifyNodeDialogVmboxProps {
  callflow: CallflowDoc;
  setCallflow: (callflow: CallflowDoc) => void;
  modifyPath: string;
  moduleItem: any;
  vmboxId: string;
  onClose: () => void;
}

const ModifyNodeDialogVmbox = ({
  callflow: callflowDoc,
  setCallflow,
  modifyPath,
  moduleItem,
  vmboxId: initialVmboxId,
  onClose,
}: ModifyNodeDialogVmboxProps) => {
  // autoshow quick finder if node has no data ID
  const [showFinderDialog, toggleShowFindVmboxDialog] = useToggleReducer(
    !initialVmboxId,
  );

  const [tmpModuleItem, setModifyModuleItem] = useState(() =>
    cloneDeep(moduleItem),
  );
  const vmboxId = tmpModuleItem?.data?.id;

  const {
    data: vmbox,
    isLoading: vmboxIsLoading,
    error: vmboxError,
    refetch: refetchVmbox,
  } = useVmboxQuery({ id: vmboxId });

  const handleSave = async () => {
    moduleItem.data.id = vmboxId;
    setAtPath(callflowDoc, modifyPath, tmpModuleItem);
    setCallflow({ ...callflowDoc });
    onClose();
  };

  const [initialState] = useState({
    id: initialVmboxId,
  });

  const saveLabel = !vmboxId
    ? 'Leave Empty'
    : !initialVmboxId
    ? 'Add'
    : initialVmboxId === vmboxId
    ? 'Done'
    : 'Update';

  const onChangeVmboxSelect = selected => {
    const [vmboxId] = selected;
    setAtPath(tmpModuleItem, `data.id`, vmboxId); // newValue?.value === id
    setModifyModuleItem({ ...tmpModuleItem });
    toggleShowFindVmboxDialog();
  };

  const handleClear = () => {
    setAtPath(tmpModuleItem, `data.id`, null); // newValue?.value === id
    setModifyModuleItem({ ...tmpModuleItem });
    toggleShowFindVmboxDialog();
  };

  return (
    <>
      <NodeEditDialog
        title={vmboxId ? 'Edit Voicemail Box Settings' : 'Select Voicemail Box'}
        titleButton={
          vmbox ? (
            <Button color="primary" onClick={toggleShowFindVmboxDialog}>
              Change Voicemail Box
            </Button>
          ) : null
        }
        maxWidth={'sm'}
        onClose={onClose}
        onCancel={onClose}
        onSave={handleSave}
        saveLabel={saveLabel}
        hideCancel={saveLabel === 'Done'}
        cancelLabel={'Cancel'}
        isLoading={vmboxIsLoading}
        errorMessage={
          vmboxError ? 'Failed loading selected voicemail box' : undefined
        }
      >
        {vmbox ? (
          <VmboxSettings vmbox={vmbox} onComplete={refetchVmbox} />
        ) : (
          <>
            <Typography color={'gray'} sx={{ fontStyle: 'italic' }}>
              No Voicemail Box Selected...
            </Typography>
            <Button onClick={toggleShowFindVmboxDialog}>
              Choose Voicemail Box
            </Button>
          </>
        )}
      </NodeEditDialog>
      {showFinderDialog ? (
        <QuickFinderVmboxDialog
          onSelect={onChangeVmboxSelect}
          onCancel={toggleShowFindVmboxDialog}
          onClear={handleClear}
          initialSelected={vmboxId ? [vmboxId] : []}
          selectionTitle={vmbox ? `${vmbox.doc.name}` : null}
          allowSelectNone
          allowCreate
        />
      ) : null}
    </>
  );
};

interface VmboxSettingsProps {
  vmbox: Vmbox;
  onComplete: () => void;
}

const VmboxSettings = ({ vmbox, onComplete }: VmboxSettingsProps) => {
  const [showAllSettings, toggleShowAllSettings] = useToggleReducer(false);

  return (
    <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <Link
            variant="h1"
            to={`/admin/vmboxes/view/${vmbox.id}`}
            underline="hover"
            color={'text.primary'}
            component={RouterLink}
          >{`${vmbox.doc.name}`}</Link>
        </Grid>

        {showAllSettings ? (
          <Grid item>
            <Button onClick={toggleShowAllSettings}>
              <ExpandLessIcon />
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <VmboxOwner vmbox={vmbox} onComplete={onComplete} />
      <br />
      <Divider />
      <br />
      {showAllSettings ? (
        <>
          <Box sx={{ paddingX: 2 }}>
            <VmboxSettingsCard vmbox={vmbox} onComplete={onComplete} />
            <br />
            <VmboxAdditionalSettingsCard
              vmbox={vmbox}
              onComplete={onComplete}
            />
            <br />
            <VmboxNotificationsSettingsCard
              vmbox={vmbox}
              onComplete={onComplete}
            />
          </Box>
          <br />
          <Divider />
        </>
      ) : (
        <>
          <GreetingVmbox vmbox={vmbox} onComplete={onComplete} />
          <br />
          <TempGreetingVmbox vmbox={vmbox} onComplete={onComplete} />
        </>
      )}
      <br />
      <Link underline={'hover'} onClick={toggleShowAllSettings}>
        {showAllSettings ? 'Hide additional settings' : 'Show all settings'}
      </Link>
    </Box>
  );
};

export default ModifyNodeDialogVmbox;
