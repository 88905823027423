import React, { useState, useContext, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Box,
  Autocomplete,
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Paper,
  TooltipLight,
  Icon,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ButtonDropdownMenu,
  Switch,
  FormControlLabel,
  Checkbox,
  TextField,
  Select,
} from 'app/design';
import {
  Delete as DeleteIcon,
  Eject as EjectIcon,
  PresentToAll as PresentToAllIcon,
  FileCopy as FileCopyIcon,
  Phone as PhoneIcon,
  Add as AddIcon,
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
} from 'app/design/icons-material';

import { getAtPath, setAtPath } from 'app/utilities';

import { buildOutputCallflowFromTemplate } from 'app/components/IvrBuilder/Flow/nodes/Template/buildOutputCallflowFromTemplate';
import { sdk } from 'app/sdk';
import { store } from '../../../../../../../store';

import { useFormContext, Controller } from 'react-hook-form';

// these are the variables for the Select
// - used for POPULATING a variable (ie, function to use for finding a value)
// - TODO: have a way of saying "you are using this value wrong!" (ie, MUST be used in a "user-main" callflow, or a callflow that belongs to a user, etc)
export const GET_A_VAR_TYPES = [
  {
    id: 'user_fail_on_single_reject',
    name: 'Checkbox Enabled/Disabled',
    description: 'Skip to next action immedately or not', // not used...
    types: ['user_fail_on_single_reject'],
    dataComponent: props => (
      <DataComponentCheckbox {...props} label="Fail on Single Reject?" />
    ),
    build: async ({
      callflow,
      templateCallflow, // TODO: should be an array of parent template (for waterfall/cascade variable inheritance?)
      varId,
      varItem,
      // componentData,
      moduleItem,
      checkSimple,
    }) =>
      QuickDefaultCheckSimple({
        defaultValue: false,
        checkSimple,
        callflow,
        varItem,
      }),
  },
  {
    id: 'user_ring_time_length',
    name: 'Enter Ring Time',
    description:
      'How long to ring the user until moving to the next call route action', // not used...
    types: ['user_ring_time'],
    dataComponent: props => (
      <DataComponentText {...props} label="Ring Time (seconds)" type="number" />
    ),
    build: async ({
      callflow,
      templateCallflow, // TODO: should be an array of parent template (for waterfall/cascade variable inheritance?)
      varId,
      varItem,
      // componentData,
      moduleItem,
      checkSimple,
    }) =>
      QuickDefaultCheckSimple({
        defaultValue: 20,
        checkSimple,
        callflow,
        varItem,
      }),
  },
  {
    id: 'vm_max_msg_length',
    name: 'Enter Max Length (in seconds)',
    description: 'Max length (in seconds) allowed for a voicemail', // not used...
    types: ['vm_max_msg_length'],
    dataComponent: props => (
      <DataComponentText {...props} label="Max Time (seconds)" type="number" />
    ),
    build: async ({
      callflow,
      templateCallflow, // TODO: should be an array of parent template (for waterfall/cascade variable inheritance?)
      varId,
      varItem,
      // componentData,
      moduleItem,
      checkSimple, // do NOT use the callflow "simple" values (this is for building the defaults FOR simple editing)
    }) =>
      QuickDefaultCheckSimple({
        defaultValue: 500,
        checkSimple,
        callflow,
        varItem,
      }),
  },
  {
    id: 'callflow_owner_user',
    name: 'User who owns callflow',
    types: ['user_id'], // that this is valid to build for (ie: "callflow_owner_user" can only be used when the variable "type" is "user_id")
    dataComponent: () => {
      return null;
    },
    build: async ({
      callflow,
      templateCallflow, // TODO: should be an array of parent template (for waterfall/cascade variable inheritance?)
      varId,
      varItem,
      // componentData,
      moduleItem,
      checkSimple,
    }) => {
      if (callflow.owner_type !== 'user') {
        return null;
        // throw new Error(
        //   'Invalid owner_type of callflow for callflow_owner_user variable',
        // );
      }
      return callflow.owner_id;
    },
  },
  {
    id: 'callflow_owner_group',
    name: 'Group that owns callflow',
    types: ['group_id'], // that this is valid to build for (ie: "callflow_owner_user" can only be used when the variable "type" is "user_id")
    dataComponent: () => {
      return null;
    },
    build: async ({
      callflow,
      templateCallflow, // TODO: should be an array of parent template (for waterfall/cascade variable inheritance?)
      varId,
      varItem,
      // componentData,
      moduleItem,
      checkSimple,
    }) => {
      if (callflow.owner_type !== 'group') {
        return null;
        // throw new Error(
        //   'Invalid owner_type of callflow for callflow_owner_group variable',
        // );
      }
      return callflow.owner_id;
    },
  },
  {
    id: 'callflow_owner_device',
    name: 'Device that owns callflow',
    types: ['device_id'], // that this is valid to build for (ie: "callflow_owner_user" can only be used when the variable "type" is "user_id")
    dataComponent: () => {
      return null;
    },
    build: async ({
      callflow,
      templateCallflow, // TODO: should be an array of parent template (for waterfall/cascade variable inheritance?)
      varId,
      varItem,
      // componentData,
      moduleItem,
    }) => {
      if (callflow.owner_type !== 'device') {
        return null;
        // throw new Error(
        //   'Invalid owner_type of callflow for callflow_owner_device variable',
        // );
      }
      return callflow.owner_id;
    },
  },
  {
    id: 'callflow_owner_user_vmbox',
    name: 'First voicemail box of user who owns callflow',
    types: ['vmbox_id'], //
    dataComponent: () => {
      return null;
    },
    simpleBuilderFixErrorComponent: () => {
      // TODO: provide an option for easily "fixing" the error when in the Simple Builder
      // - only shown when this is the Default Value and there IS an error
      // - create a voicemail box for the user, or some other option
    },
    build: async ({
      callflow,
      templateCallflow,
      varId,
      varType,
      moduleItem,
    }) => {
      if (!callflow.owner_id) {
        // no owner_id for callflow, so not going to find a vmbox for them!
        console.info('No vmbox for user');
        return null;
      }
      if (callflow.owner_type !== 'user') {
        return null;
      }
      // return null;
      const authToken = store.getState().auth.auth_token;
      const { vmboxes } = await sdk.vmbox.query.vmboxes(
        {
          skip: 0,
          take: 1,
          orderBy: [],
          filters: {
            raw: [['owner_id', '=', callflow.owner_id]],
          },
        },
        { authToken },
      );
      const vmbox = vmboxes?.length ? vmboxes[0] : null;
      // setAtPath(moduleItem, modifyPath, vmbox?.id);
      console.log('VMBOX WE FOUND', vmbox);
      return vmbox?.id;
    },
  },
  {
    id: 'menu_greeting',
    name: 'Menu Greeting',
    description: 'Media ID to use for the greeting', // not used...
    types: ['menu_greeting'],
    dataComponent: props => {
      return null;
    },
    build: async ({
      callflow,
      templateCallflow, // TODO: should be an array of parent template (for waterfall/cascade variable inheritance?)
      varId,
      varItem,
      // componentData,
      moduleItem,
      checkSimple,
    }) => {
      return null;
    },
  },
  // {
  //   id: 'callflow_variable',
  //   name: 'Get value from callflow variables (Not enabled yet)',
  //   types: ['*'], // works for any type! (ie, can cause problems!)
  //   dataComponent: () => {
  //     return null;
  //   },
  //   build: ({ callflow, templateCallflow, varId, varType, moduleItem }) => {
  //     // replace the values in componentData
  //     // the value/id was maped to something we will read from the callflow's variables
  //     // setAtPath(componentData, modifyPath, callflow.variables);
  //     return null;
  //   },
  // },
  // {
  //   id: 'owner_variable',
  //   name: 'Get value from the user/device/etc. that owns the callflow (Not enabled yet)',
  //   types: ['*'], // works for any type! (ie, can cause problems!)
  //   dataComponent: () => {
  //     return null;
  //   },
  //   build: ({ callflow, templateCallflow, varId, varType, moduleItem }) => {
  //     // replace the values in componentData
  //     // the value/id was maped to something we will read from the callflow's variables
  //     // setAtPath(componentData, modifyPath, callflow.variables);
  //     return null;
  //   },
  // },
  // {
  //   id: 'account_variable',
  //   name: 'Get value from account variables (Not enabled yet)',
  //   types: ['*'], // works for any type! (ie, can cause problems!)
  //   dataComponent: () => {
  //     return null;
  //   },
  //   build: ({ callflow, templateCallflow, varId, varType, moduleItem }) => {
  //     // replace the values in componentData
  //     // the value/id was maped to something we will read from the callflow's variables
  //     // setAtPath(componentData, modifyPath, callflow.variables);
  //     return null;
  //   },
  // },
  // TODO: have a "cascade" type that gets the value from the callflow, or the template, etc.? (or do cascade/etc differently)
];

export const SET_A_VAR_TYPES = {
  set: async ({
    varValue, // calculated in GET_A_VAR_TYPES
    moduleItem,
    writeTypeData, // { moduleItemModifyPath }
  }) => {
    console.log('SET_A_VAR_TYPES.set:', {
      varValue,
      moduleItem,
      writeTypeData,
    });
    setAtPath(moduleItem, writeTypeData.moduleItemModifyPath, varValue);
  },
  menu_greeting: async ({
    varValue, // calculated in GET_A_VAR_TYPES (should be the "simple" value we care about)
    moduleItem, // contains our "menu_id" (or we'll create one if we need it)
    writeTypeData,
  }) => {
    // get if a menu is already created
    // - create if not and add our greeting_id (and other settings)
    console.log('SET_A_VAR_TYPES.menu_greeting:', {
      varValue,
      moduleItem,
      writeTypeData,
    });

    // TODO:
    // - check for "variables[key].changed to determine if we have an updated Greeting"
    //   - remove the "changed" check after updating

    const authToken = store.getState().auth.auth_token;
    let menuData, output;
    if (!moduleItem.data.id) {
      // menu has NOT been set yet
      // - create a new menu
      menuData = {
        name: 'Menu',
        interdigit_timeout: 2000,
        max_extension_length: 4,
        hunt: false, // can dial extensions?
        allow_record_from_offnet: false, // record new menu from outside account?
        suppress_media: false, // dont play the "media is missing" message?
        retries: 3,
        timeout: 10 * 1000,
        media: {
          greeting: varValue?.greeting_id,
        },
      };
      output = await sdk.menu.mutate.create(menuData, authToken);
      const menuId = output.data.id;
      // const vmbox = vmboxes?.length ? vmboxes[0] : null;
      // // setAtPath(moduleItem, modifyPath, vmbox?.id);
      // console.log('VMBOX WE FOUND', vmbox);
      // return vmbox?.id;
      console.log('output:', output);
      setAtPath(moduleItem, 'data.id', menuId);
    } else {
      // get existing menu, update the greeting only
      // - TODO: if bad/broken menu, just recreate
      const menu = await sdk.menu.query.menuById(
        { id: moduleItem.data.id },
        { authToken },
      );
      menuData = {
        id: menu.id,
        media: {
          greeting: varValue?.greeting_id,
        },
      };
      output = await sdk.menu.mutate.updatePartial(menuData, authToken);
      // const menuId = output.data.id;
    }

    // setAtPath(moduleItem, writeTypeData.moduleItemModifyPath, varValue);
  },
  menu_targets: async ({
    varValue, // calculated in GET_A_VAR_TYPES (should be the "simple" value we care about)
    moduleItem, // contains our "menu_id" (or we'll create one if we need it)
    writeTypeData,
    rootCallflow,
    checkSimple,
  }) => {
    // this passes through the "checkSimple" value
    // run buildOutputCallflowFromTemplate for each target!
    console.log('SET_A_VAR_TYPES.menu_targets:', {
      varValue,
      moduleItem,
      writeTypeData,
    });

    // rebuild targets individually
    // - not sure if this goes "deeper" into each individual "simple" flow
    //   - it probably shouldn't? or should have some sort of "level-increase" to see what should be built vs. skipped
    // const targets = moduleItem.data.targets ?? {};
    // for (let inputString of Object.keys(targets)) {
    //   let target = targets[inputString];
    // }

    const targetKeys = Object.keys(moduleItem?.data?.targets ?? {});
    for (let key of targetKeys) {
      const target = moduleItem.data.targets[key];

      // @ts-ignore
      const targetCallflow = target?.callflow;

      if (targetCallflow) {
        const outputCallflow = await buildOutputCallflowFromTemplate({
          // more like "Build Template Strategy using rootCallflow variables"
          // - or, can be used on regular Callflows too!
          rootCallflow: targetCallflow, // root variables (and callflow.strategy.type=template -> callflow.strategy.simple.variables)
          templateCallflow: targetCallflow, // has the "ready for simple" (calculated) values!
          checkSimple,
        });
        // add the outputCallflow to the final
        targetCallflow.strategy.data = outputCallflow.strategy.data;
      }
    }
  },
};

export const VariablesEditor = props => {
  const {
    callflow,
    setCallflow,
    modifyPath,
    onClose,
    possibleVariables = [],
  } = props;

  // use a temporary callflow for editing, in case of no save
  const [varData, setVarData] = useState(() =>
    getAtPath(callflow, `${modifyPath}.data.variables`, {
      enabled: false,
      items: {}, // id_of_var=>{id_of_VAR_TYPE, data}
    }),
  );

  const handleSave = () => {
    setAtPath(callflow, `${modifyPath}.data.variables`, varData);
    setCallflow({ ...callflow }, { name: `Updated Variable Data` });
  };

  const handleChangeEnabled = e => {
    varData.enabled = e.target.checked;
    setVarData({ ...varData });
  };

  return (
    <Dialog open onClose={onClose} fullWidth={true} maxWidth="sm" scroll="body">
      <DialogTitle>Variables Editor</DialogTitle>
      <Divider />
      <DialogContent>
        <div style={{ width: '100%' }}>
          <div>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={varData.enabled}
                  onChange={handleChangeEnabled}
                />
              }
              label="Enabled"
            />
          </div>
          <div>
            {possibleVariables.map((varItem, i) => (
              <VariableItem
                {...props}
                key={i}
                varItem={varItem}
                varData={varData}
                setVarData={setVarData}
              />
            ))}
            {!possibleVariables.length ? (
              <div>
                <Typography variant="body2" style={{ fontStyle: 'italic' }}>
                  No variables available to modify
                </Typography>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          color="success"
          variant="contained"
          onClick={e => {
            handleSave();
            onClose();
          }}
        >
          Save Variables
        </Button>
        {/* <Button
          color="primary"
          variant="outlined"
          onClick={(e) => {
            onClose();
          }}
        >
          Apply Now
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

const VariableItem = props => {
  const { varItem, varData, callflow, setCallflow, modifyPath } = props;

  const thisData = varData.items[varItem.id];

  return (
    <Box sx={{ marginTop: 3 }}>
      <Typography variant="h2">{varItem.name}</Typography>
      <VariableItemData {...props} />
    </Box>
  );
};

const VariableItemData = props => {
  const { varItem, varData, setVarData, callflow, setCallflow, modifyPath } =
    props;

  const thisData = varData.items[varItem.id] ?? {
    // read_id: null,
    key: varItem.name,
    data: {},
    read_get_id: null, // the "id" we use for getting the default value (loaded from above)
    read: varItem.read, // used to determine which types are allowed for "read_get_id"
    write: varItem.write, // used for actually setting the value
    output_value_default: null, // value set in VariableEditor (in Expert Editor)
    output_value_simple: null, // value set by Simple Builder (overwrites other values)
    output_value: null, // value that it is calculated to be (after default/simple)
    enabled: false, // can be disabled/ignored!
    simple: false, // enable in Simple Builder
    simple_advanced: false, // hide until they click "more advanced" in the Simple Builder
    simple_title: varItem.name, // title in Simple Builder
    simple_input_id: null, // how to allow the user to input a new value (will fallback to "read_get_id" if NOT specified)
  };

  // const handleChangeSelect = e => {
  //   // reset data?
  //   const thisNewData = {
  //     id: e.target.value,
  //     data: {},
  //     read: varItem.read, // not necessary
  //     write: varItem.write, // used for actually setting the value
  //     output_value: null, // value that it is calculated to be (necessary?)
  //     enabled: true, // can be disabled/ignored!
  //   };
  //   varData.items[varItem.id] = thisNewData;
  //   // if () {
  //   varData.enabled = true;
  //   // }
  //   setVarData({ ...varData });
  // };

  const handleCheckbox = path => e => {
    let thisNewData = {
      ...thisData,
      [path]: e.target.checked ? true : false,
    };
    varData.items[varItem.id] = thisNewData;
    varData.enabled = true;
    // console.log(thisNewData);
    setVarData({ ...varData });
  };

  const handleText = path => e => {
    let thisNewData = {
      ...thisData,
      [path]: e.target.value,
    };
    if (path === 'simple_title') {
      // also update the "key" automatically
      thisNewData.key = e.target.value;
    }
    varData.items[varItem.id] = thisNewData;
    varData.enabled = true;
    // console.log(thisNewData);
    setVarData({ ...varData });
  };

  const handleSelect = (e, v) => {
    let thisNewData = {
      ...thisData,
      read_get_id: v?.value,
      data: {},
    };
    varData.items[varItem.id] = thisNewData;
    varData.enabled = true;
    // console.log(thisNewData);
    setVarData({ ...varData });
  };

  let ChosenDataComponent = GET_A_VAR_TYPES.find(
    t => t.id == thisData?.read_get_id,
  );
  ChosenDataComponent = ChosenDataComponent
    ? ChosenDataComponent.dataComponent
    : null;

  const options = GET_A_VAR_TYPES.filter(
    at => at.types.includes('*') || at.types.includes(varItem.read.type),
  ).map(menuitem => ({
    label: menuitem.name,
    value: menuitem.id,
    full: menuitem,
  }));

  const value =
    options.find(menuitem => menuitem.value === thisData?.read_get_id) ?? null;
  console.log('value:', value, thisData);
  return (
    <div>
      <Typography variant="body2">
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={thisData.enabled}
              onChange={handleCheckbox('enabled')}
              // disabled={row.save_status === 'saved'}
            />
          }
          label={`Enabled`}
        />
        {thisData.enabled ? (
          <div>
            {/* <Select
          value={thisData?.id}
          onChange={handleChangeSelect}
          displayEmpty
          size="small"
        >
          {GET_A_VAR_TYPES.filter(
            at =>
              at.types.includes('*') || at.types.includes(varItem.read.type),
          ).map((menuitem, i) => (
            <MenuItem key={menuitem.id} value={menuitem.id}>
              {menuitem.name}
            </MenuItem>
          ))}
        </Select> */}
            <Autocomplete
              options={options}
              // filterOptions={filterOptions}
              value={value}
              onChange={handleSelect}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Get Default Value From:"
                  fullWidth
                  margin="normal"
                />
              )}
            />
            {ChosenDataComponent && <ChosenDataComponent {...props} />}

            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={thisData.simple}
                  onChange={handleCheckbox('simple')}
                  // disabled={row.save_status === 'saved'}
                />
              }
              label={`Show in Simple Builder`}
            />

            {thisData.simple ? (
              <div>
                <TextField
                  value={thisData.simple_title}
                  onChange={handleText('simple_title')}
                  label={'Title in Simple Builder'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={!thisData.simple}
                  fullWidth
                  margin="normal"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={thisData.simple_advanced}
                      onChange={handleCheckbox('simple_advanced')}
                      disabled={!thisData.simple}
                    />
                  }
                  label={`Show as "Advanced" in Simple Builder`}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </Typography>
    </div>
  );
};

const DataComponentText = ({
  label,
  type, // text, number
  varItem,
  varData,
  setVarData,
  callflow,
  setCallflow,
  modifyPath,
}) => {
  const thisData = varData.items[varItem.id];
  const handleChangeText = path => e => {
    let thisNewData = {
      ...thisData,
      [path]: e.target.value,
    };
    varData.items[varItem.id] = thisNewData;
    varData.enabled = true;
    setVarData({ ...varData });
  };
  return (
    <Box>
      <TextField
        label={label}
        type={type}
        value={
          // getAtPath(thisData.data ?? {}, 'output_value', false) ?? false
          getAtPath(thisData ?? {}, 'output_value_default', '')
        }
        onChange={handleChangeText('output_value_default')}
      />
    </Box>
  );
};

const DataComponentCheckbox = ({
  varItem,
  varData,
  setVarData,
  callflow,
  setCallflow,
  modifyPath,
}) => {
  const thisData = varData.items[varItem.id];
  const handleCheckbox = path => e => {
    let thisNewData = {
      ...thisData,
      [path]: e.target.checked,
    };
    varData.items[varItem.id] = thisNewData;
    varData.enabled = true;
    console.log('vardatanow:', varData);
    setVarData({ ...varData });
  };
  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={
              // getAtPath(thisData.data ?? {}, 'output_value', false) ?? false
              getAtPath(thisData ?? {}, 'output_value_default', false) ?? false
            }
            onChange={handleCheckbox('output_value_default')}
            // disabled={row.save_status === 'saved'}
          />
        }
        label={`Check for "Enabled"`}
      />
    </Box>
  );
};

const QuickDefaultCheckSimple = async ({
  defaultValue,
  callflow,
  checkSimple,
  varItem,
}) => {
  if (checkSimple) {
    // check for Simple value first
    if (
      // callflow.strategy?.type === 'template' &&
      callflow.strategy?.simple?.variables?.hasOwnProperty(varItem.key)
    ) {
      return callflow.strategy?.simple?.variables[varItem.key];
    }
  }

  // NOT checking Simple
  let val = varItem.output_value_default;

  // // get value from Callflow.template_variables (not used yet)
  // if (callflow.template_variables?.[varItem.key]) {
  //   val = callflow.template_variables?.[varItem.key];
  // }

  return val ?? defaultValue;
};

export default VariablesEditor;
