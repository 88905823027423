import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  InfoTooltip,
  Link,
  Tooltip,
  Typography,
  Portal,
} from 'app/design';
import {
  ArrowCircleDown as ArrowCircleDownIcon,
  ArrowCircleUp as ArrowCircleUpIcon,
  Numbers as NumbersIcon,
  AltRoute as AltRouteIcon,
} from 'app/design/icons-material';
import { useFindInPage } from 'app/components/FindInPage';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { useListCallflowsQuery } from 'app/hooks/queries/callflow';
import { useCallRoutingDialog } from 'app/components/CallRoutingDialog';
import { useUpdateCallflowPartial } from 'app/hooks/mutations/callflow';
import FixCallHandlingDialog from 'app/pages/admin/Dashboard/components/CallHandlingFixDialogGroup/FixCallHandlingDialog';
import { parseCallflowNumbers, useToggleReducer } from 'app/utilities';
import { Account } from 'types/account';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { IvrQuickDisplay } from 'app/components/IvrBuilder';

import { DefaultDialogActions } from 'app/components/DefaultDialogActions';
import { cloneDeep } from 'lodash';
import { CallflowNumbers } from 'app/components/CallflowNumbers';
import { DialogCircularProgress } from 'app/components/DialogCircularProgress';

interface CallHandlingCardProps {
  account: Account;
  // setDialogShown: any;
  onComplete: () => void;
}

const CallHandlingCard = ({
  account,
  // setDialogShown,
  onComplete,
}: CallHandlingCardProps) => {
  const {
    toggleOpen: toggleOpenCallRoutingDialog,
    CallRoutingDialog,
    CallRoutingDialogProps,
  } = useCallRoutingDialog({});

  const [showNumbersDialog, toggleNumbersDialog] = useToggleReducer(false);
  const [showFixDialog, toggleFixDialog] = useToggleReducer(false);

  const {
    data: callflowsPageResponse,
    isLoading: callflowsAreLoading,
    isFetching: callflowsFetching,
    refetch,
  } = useListCallflowsQuery({
    filters: {
      raw: [
        {
          variables: {
            docFilters: [
              ['owner_id', '=', account.id],
              ['type', '=', 'main'],
            ],
          },
        },
      ],
    },
  });

  const handleDone = async => {
    refetch();
    toggleNumbersDialog();
  };

  const mainCallflow = callflowsPageResponse?.callflows?.length
    ? callflowsPageResponse.callflows[0]
    : null;

  const {
    ptns: accountPtns,
    ptnsParsed: accountPtnsParsed,
    extensions: accountExtensions,
  } = parseCallflowNumbers(mainCallflow?.doc?.numbers);

  // useEffect(() => {
  //   setDialogShown(CallRoutingDialogProps.open);
  // }, [CallRoutingDialogProps.open]);

  let enabledSimple = mainCallflow?.doc?.strategy?.simple?.enabled;

  let label = enabledSimple
    ? mainCallflow?.doc?.strategy?.template?.parent_callflow?.name ??
      'Unknown template'
    : 'Custom Call Routing';

  return (
    <>
      {showNumbersDialog && (
        <IncomingDialog
          callflow={mainCallflow?.doc}
          onClose={toggleNumbersDialog}
          onComplete={handleDone}
        />
      )}
      <CallRoutingDialog
        {...CallRoutingDialogProps}
        name_type="account"
        owner_type="account"
        type="main"
        resourceId={account.id}
        defaultNumbers={[]}
        callflow={mainCallflow}
        onComplete={() => {
          toggleOpenCallRoutingDialog();
          refetch();
        }}
      />
      {/* @ts-ignore */}

      <Typography variant="h1" paragraph>
        <ArrowCircleDownIcon
          sx={{ width: 24, height: 24, verticalAlign: 'bottom' }}
        />{' '}
        Incoming Calls
      </Typography>
      <br />

      {/* <Typography variant="h2" paragraph>
        {label}
      </Typography> */}

      <Typography>
        {callflowsAreLoading ? (
          <Typography variant="h5">Loading calling</Typography>
        ) : (
          <>
            {mainCallflow ? (
              <>
                <Grid container columnSpacing={3}>
                  <Grid item md={6}>
                    <Typography variant="h5" paragraph>
                      <NumbersIcon sx={{ verticalAlign: 'bottom' }} /> Phone
                      Numbers and Extensions
                    </Typography>
                    {accountPtns.length ? (
                      <div>
                        {accountPtns.map(ptn => (
                          <div key={ptn}>
                            <Typography variant="number1">
                              {/* @ts-ignore */}
                              <PhoneNumberDisplay width="auto" ptn={ptn} />
                            </Typography>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                        No Main Account Phone Number
                      </Typography>
                    )}
                    <Typography variant="number1">
                      <>
                        {accountExtensions.length ? (
                          <div>
                            {accountExtensions.map(ext => (
                              <div key={ext}>
                                <Typography variant="number1">
                                  <span style={{ opacity: 0.5 }}>#</span>
                                  {ext}
                                </Typography>
                              </div>
                            ))}
                          </div>
                        ) : // Should show an error here, expecting at least 1 extension!
                        // <Typography
                        //   variant="body1"
                        //   sx={{ fontStyle: 'italic' }}
                        // >
                        //   No Extensions
                        // </Typography>
                        null}
                      </>
                    </Typography>
                  </Grid>
                </Grid>
                <Box sx={{ mt: 1, mb: 2 }}>
                  <Link
                    onClick={toggleNumbersDialog}
                    // variant="contained"
                    // size="small"
                    underline="hover"
                    variant="body2"
                  >
                    Add/Remove/Change Numbers
                  </Link>
                </Box>
                <br />
                <br />
                <Typography variant="h5" paragraph>
                  <AltRouteIcon sx={{ verticalAlign: 'text-bottom' }} /> Call
                  Routing
                </Typography>
                <Link
                  component={RouterLink}
                  // color="primary"
                  // variant="contained"
                  // size="small"
                  to={`/admin/dashboard/call_handling`}
                  underline="hover"
                  variant="body2"
                >
                  Change Incoming Call Routing
                </Link>
                <br />
              </>
            ) : (
              <>
                {showFixDialog ? (
                  <FixCallHandlingDialog
                    resourceId={account.id}
                    type={'account'}
                    onCancel={toggleFixDialog}
                  />
                ) : null}
                <Typography variant="body1" paragraph>
                  You do not have any routing for incoming calls.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={`/admin/dashboard/call_handling`}
                >
                  Setup Account Call Handling
                </Button>
              </>
            )}
          </>
        )}
      </Typography>

      <br />

      {mainCallflow ? (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '300px',
            borderRadius: '4px',
            border: '1px solid #efefef',
            background: '#f3fdfd',
          }}
        >
          <IvrQuickDisplay
            // id={JSON.stringify(callflow)}
            // key={mainCallflowKey} // causes a rerender when changed!
            callflow={mainCallflow?.doc}
            showControls
          />
        </Box>
      ) : null}
    </>
  );
};

const IncomingDialog = ({ onClose, onComplete, callflow }) => {
  // const { data, onClose } = props;

  const [editingCallflow, setEditingCallflow] = useState(cloneDeep(callflow));

  const updateCallflowPartial = useUpdateCallflowPartial();

  const handleSave = () => {
    // setCallflow({ ...editingCallflow });

    const updateCallflowPromise = updateCallflowPartial.mutateAsync(
      {
        id: editingCallflow.id,
        numbers: editingCallflow.numbers,
      },
      {
        onSuccess: onComplete,
        onError: error => console.error(error),
      },
    );
    // toast.promise(updateCallflowPromise, {
    //   pending: 'Unassigning phone number...',
    //   success: 'Phone number unassigned!',
    //   error: 'Error unassigning phone number.',
    // });

    // onClose(); // already done for some reason when setCallflow?
  };

  if (updateCallflowPartial.isLoading) {
    return <DialogCircularProgress label={'Updating Numbers'} />;
  }

  return (
    <Dialog open fullWidth maxWidth={'md'}>
      {/* <DialogTitle>Incoming Numbers</DialogTitle>
      <Divider /> */}
      <DialogContent>
        <CallflowNumbers
          neverCollapse
          strategyCallflow={editingCallflow}
          setStrategyCallflow={setEditingCallflow}
          // modifyPath={modifyPath}
        />
      </DialogContent>
      <Divider />
      <DefaultDialogActions
        // errors={errors}
        saveLabel="Update Numbers for Account Call Routing"
        onSave={handleSave}
        onCancel={onClose}
      />
    </Dialog>
  );
};

export default CallHandlingCard;
