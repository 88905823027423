// @ts-ignore - this not being used?
import account_ring_group_go_to_vm from 'app/components/CallRoutingDialog/templates/account_ring_group_go_to_vm';
import { sdk } from 'app/sdk';

import { setAtPath } from 'app/utilities';
import { cloneDeep } from 'lodash';
import React from 'react';

const setupMainCallflow = async ({
  auth_token,
  ptn,
  accountId,
  groupId,
  vmboxId,
}) => {
  const cf = account_ring_group_go_to_vm;
  const callflow = cloneDeep({
    name: cf.name,
    numbers: [ptn],
    // numbers: [],
    type: 'main',
    owner_id: accountId,
    owner_type: 'account',
    strategy: {
      ...cf.strategy,
      simple: {
        enabled: true,
        variables: {},
      },
      template: {
        parent_id: cf.id,
        parent_callflow: cf,
      },
    },
  });
  // insert values into callflow
  // - TODO: insert into "simple" values?
  setAtPath(callflow, 'strategy.data.modules.0.data.data.id', groupId);
  setAtPath(callflow, 'strategy.data.modules.1.data.id', vmboxId);
  console.log('callflow:', callflow);
  const callflowResult = await sdk.callflow.mutate.create(
    { data: callflow },
    {
      authToken: auth_token,
    },
  );
  console.log('callflowResult:', callflowResult);
  if (!callflowResult.success) {
    console.error(
      'callfow error:',
      callflowResult.message,
      callflowResult.data,
    );
    throw 'Failed saving callflow';
  }
  const callflowId = callflowResult.data.id;
  return { callflowId };
};

export default setupMainCallflow;
