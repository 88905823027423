import { EmptyTableCell } from 'app/components/EmptyTableCell';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { User } from 'types/user';

interface OwnerTableCellProps {
  owner: User;
}

const OwnerTableCell = ({ owner }: OwnerTableCellProps) => {
  if (!owner) {
    return <EmptyTableCell label={'no owner'} />;
  }

  return (
    <RouterLink
      to={`/admin/users/view/${owner.doc.id}`}
    >{`${owner.doc.first_name} ${owner.doc.last_name}`}</RouterLink>
  );
};

export default OwnerTableCell;
