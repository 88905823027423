import React, { useEffect } from 'react';
import { Button, Link } from 'app/design';
import { CreditCard as CreditCardIcon } from 'app/design/icons-material';
import { useCreateUser } from 'app/hooks/mutations/user/useCreateUser';
import { toast } from 'react-toastify';

import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { useBuyPhoneNumberDialog } from 'app/components/BuyPhoneNumberDialog';

interface BuyNumbersButtonProps {
  onComplete: (args?: any) => void;
  ButtonProps?: any;
}

const BuyNumbersButton = ({
  onComplete,
  ButtonProps = {},
}: BuyNumbersButtonProps) => {
  const {
    toggleOpen: toggleOpenBuyPhoneNumberDialog,
    Dialog: BuyPhoneNumberDialog,
    DialogProps: BuyPhoneNumberDialogProps,
  } = useBuyPhoneNumberDialog({
    // onComplete: numberArr => {
    //   // alert('done! add numbers to here');
    //   console.log('numberArr:', numberArr);
    //   toggleOpenBuyPhoneNumberDialog();
    //   onComplete(numberArr);
    //   // setNumbers([...numbers, ...numberArr]);
    // },
  });

  return (
    <>
      <BuyPhoneNumberDialog
        {...BuyPhoneNumberDialogProps}
        onComplete={numberArr => {
          // alert('done! add numbers to here');
          // console.log('numberArr:', numberArr);
          toggleOpenBuyPhoneNumberDialog();
          onComplete(numberArr);
          // setNumbers([...numbers, ...numberArr]);
        }}
      />
      <Button
        variant={'contained'}
        startIcon={<CreditCardIcon />}
        {...ButtonProps}
        onClick={toggleOpenBuyPhoneNumberDialog}
      >
        Buy Numbers
      </Button>
    </>
  );
};

export default BuyNumbersButton;
