import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { DirectoryUpdatePartialDoc } from 'types/directory';
import { GroupUpdatePartialDoc } from 'types/group';

export const useUpdateGroupPartial = () => {
  const authState = useAuthSelector();
  return useMutation<any, AxiosError, GroupUpdatePartialDoc, unknown>(
    (groupUpdatePartialDoc: GroupUpdatePartialDoc) =>
      sdk.group.mutate.updatePartial(
        groupUpdatePartialDoc,
        authState?.auth_token,
      ),
  );
};
