import { Box, Button } from 'app/design';
import { AudioPlayer } from 'app/components/AudioPlayer';
import { useToggleReducer } from 'app/utilities';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MediaType } from 'types/media';
import { ReactMic } from '@cleandersonlobo/react-mic';

const RecordAudio = ({ register, setValue }) => {
  const [isRecording, toggleRecording] = useToggleReducer(false);
  const [file, setFile] = useState<string | ArrayBuffer | null | undefined>(
    null,
  );
  useEffect(() => register(`${MediaType.Recording}.file`), [register]);

  const handleStop = data => {
    const recordedBlob = data.blob;
    var reader = new FileReader();
    reader.onloadend = function (evt) {
      const file = evt.target?.result;
      setValue(`${MediaType.Recording}.file`, file);
      setFile(file);
    };
    reader.onerror = function (err) {
      console.error('Reader Error:', err);
      alert(
        'Sorry, there was a problem recording audio, please try again or record the audio locally and upload as a file',
      );
    };

    reader.readAsDataURL(recordedBlob);
  };

  return (
    <div style={{ display: 'grid', placeItems: 'center' }}>
      {file && !isRecording && (
        <div>
          {/* TODO: key should NOT be the actual URLMedia! (too long if base64 audio)  */}
          <AudioPlayer mediaId={file! as string} />
        </div>
      )}
      <br />
      <Button
        onClick={toggleRecording}
        variant="outlined"
        color={isRecording ? 'secondary' : 'primary'}
      >
        {isRecording
          ? 'Stop Recording'
          : file
          ? 'Rerecord Audio'
          : 'Start Recording'}
      </Button>
      <div style={{ display: isRecording ? '' : 'none' }}>
        <ReactMic
          css={`
            sound-wave {
              width: 300px;
            }
          `}
          className="sound-wave"
          key={isRecording || 'hidden'}
          record={isRecording}
          onStop={handleStop}
          strokeColor="#000000"
          mimeType={'audio/mp3'}
        />
      </div>
    </div>
  );
};

export default RecordAudio;
