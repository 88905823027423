import { Grid, Toolbar, Typography } from 'app/design';
import React, { ReactNode } from 'react';
import flattenChildren from 'react-keyed-flatten-children';

interface BulkToolbarProps {
  selectedRows: any[];
  children: ReactNode;
  toggleSelect: (set?: boolean) => void;
}

const BulkToolbar = ({
  selectedRows,
  toggleSelect,
  children,
}: BulkToolbarProps) => {
  return (
    <Toolbar
      component={'div'}
      sx={{
        paddingX: 5,
        fontWeight: 'bold',
        zIndex: 1000,
        position: 'fixed',
        borderRadius: 2,
        bottom: 5,
        left: '50%',
        transform: 'translate(-50%, 0%)',
        border: '1px solid',
        borderColor: 'primary.main',
        background: 'white',
      }}
      color={'primary'}
      variant={'dense'}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography color="inherit" variant="subtitle1">{`${
            selectedRows.length
          } row${selectedRows.length > 1 ? 's' : ''} selected`}</Typography>
        </Grid>
        <Grid item>
          {flattenChildren(children).map(child => {
            // @ts-ignore
            return React.cloneElement(child, {
              selectedRows,
              toggleSelect,
            });
          })}
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default BulkToolbar;
