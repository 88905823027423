import React, { useState, useContext, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Paper,
  TooltipLight,
  Icon,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ButtonDropdownMenu,
} from 'app/design';
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  Eject as EjectIcon,
  PresentToAll as PresentToAllIcon,
  FileCopy as FileCopyIcon,
  Phone as PhoneIcon,
  Add as AddIcon,
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
  LocalHospitalOutlined as LocalHospitalOutlinedIcon,
} from 'app/design/icons-material';

import { getAtPath, setAtPath } from 'app/utilities/utils';

import { IvrMenuEventEmitterContext, useSharedFlow } from '../../..';

import { InTemplate } from '../common/InTemplate';

// import { USER_ADDABLE_COMPONENTS } from '../../../../Strategies/base/GenericDefault';
// import * as OptionComponents from '../../../../Strategies/components';

import { ChooseDialog } from '../common/ChooseDialog';

////////////////////////
// THIS IS OLD (well, the InsertNode isnt, but the rest is)!!!!!
// - Use the "MenuButton" from "./nodes/common/MenuButton" instead!

const InsertNode = memo(({ data }) => {
  const {
    skipEditing,
    callflow: rootCallflow,
    setCallflow,
    modifyPath,
    index,
    requireAllowBefore, // always false
    requireAllowAfter,
    onClose,
    onAdd, // replaces handleAdd from below!
    templateParent,
    templateRef,
    fix,
    fixData,
    fixBySpliceIdx,
  } = data;

  const [showAdd, setShowAdd] = useState(null);
  const setShowAddWrap = setTo => () => setShowAdd(setTo);

  const [sharedFlow, setSharedFlow] = useSharedFlow();
  const sharedFlowState = sharedFlow?.state;

  let icon = <AddIcon />,
    canMove = true;
  if (fix) {
    icon = <LocalHospitalOutlinedIcon />;
  } else if (
    // determine if after or on same level as trying to move-to
    sharedFlow?.state === 'move-to' &&
    (onAdd ||
      `${modifyPath}.strategy.data.modules`.indexOf(
        sharedFlow?.data?.pathLevel,
      ) > -1)
  ) {
    icon = <CloseIcon />;
    canMove = false;
  } else if (
    (sharedFlow?.state === 'duplicate-to' ||
      sharedFlow?.state === 'paste-to') &&
    onAdd
  ) {
    icon = <CloseIcon />;
  }

  const duplicateHere = () => {
    // TODO: Make sure to detect if the above element has taken over the "handle add" button!
    // - likely has a special way of adding the "duplicate" info?
    const moduleArr = sharedFlow.data.duplicateJson.moduleItem;
    // const dataArr = sharedFlow.data.duplicateJson.componentData;

    if (onAdd) {
      onAdd({
        type: 'duplicate',
        data: {
          moduleArr,
        },
      });
      return;
    }

    const modules = getAtPath(
      rootCallflow,
      `${modifyPath}.strategy.data.modules`,
      [],
    );
    // const stratDataOpts = getAtPath(
    //   rootCallflow,
    //   `${modifyPath}.strategy.data.opts`,
    //   [],
    // );

    for (let i = 0; i < moduleArr.length; i++) {
      modules.splice(index + i, 0, moduleArr[i]);
      // stratDataOpts.splice(index + i, 0, dataArr[i]);
    }

    setAtPath(rootCallflow, `${modifyPath}.strategy.data.modules`, modules);
    // setAtPath(rootCallflow, `${modifyPath}.strategy.data.opts`, stratDataOpts);

    setCallflow({ ...rootCallflow });
    setSharedFlow(s => ({ ...s, state: null, data: null }));
  };

  const pasteHere = () => {
    // TODO: Make sure to detect if the above element has taken over the "handle add" button!
    // - likely has a special way of adding the "duplicate" info?
    const moduleArr = sharedFlow.data.pasteJson.moduleItem;
    const dataArr = sharedFlow.data.pasteJson.componentData;

    if (onAdd) {
      onAdd({
        type: 'paste',
        data: {
          moduleArr,
        },
      });
      return;
    }

    const modules = getAtPath(
      rootCallflow,
      `${modifyPath}.strategy.data.modules`,
      [],
    );

    for (let i = 0; i < moduleArr.length; i++) {
      modules.splice(index + i, 0, moduleArr[i]);
    }

    setAtPath(rootCallflow, `${modifyPath}.strategy.data.modules`, modules);

    setCallflow({ ...rootCallflow });
    setSharedFlow(s => ({ ...s, state: null, data: null }));
  };

  const moveHere = () => {
    if (!canMove) {
      alert('Unable to move a branch to itself');
      return;
    }
    const moduleArr = sharedFlow.data.moveJson.moduleItem;

    if (onAdd) {
      onAdd({
        type: 'move',
        data: {
          moduleArr,
        },
      });
      return;
    }

    const modules = getAtPath(
      rootCallflow,
      `${modifyPath}.strategy.data.modules`,
      [],
    );

    for (let i = 0; i < moduleArr.length; i++) {
      modules.splice(index + i, 0, moduleArr[i]);
    }

    setAtPath(rootCallflow, `${modifyPath}.strategy.data.modules`, modules);

    // remove from old place (ie reset old array)
    // - easier to just call sharedFlow.data.onMove() ?
    const oldModules = getAtPath(
      rootCallflow,
      `${sharedFlow.data.currentCallflowPath}.strategy.data.modules`,
    );

    oldModules.splice(sharedFlow.data.infoIdx); // moduleIdx??
    setAtPath(
      rootCallflow,
      `${sharedFlow.data.currentCallflowPath}.strategy.data.modules`,
      oldModules,
    );
    setCallflow({ ...rootCallflow });
    setSharedFlow(s => ({ ...s, state: null, data: null }));
  };

  const handleClick = () => {
    // if (rootCallflow.type != 'template' && templateParent) {
    //   alert(
    //     'Note that adding to this template outside of a Template Endpoint can result in losing information when the Template is re-applied!',
    //   );
    // }
    switch (sharedFlow?.state) {
      case 'duplicate-to':
        if (onAdd) {
          alert('Unable to duplicate here');
          return;
        }
        duplicateHere();
        break;
      case 'paste-to':
        if (onAdd) {
          alert('Unable to copy here');
          return;
        }
        pasteHere();
        break;
      case 'move-to':
        if (onAdd) {
          alert('Unable to move here');
          return;
        }
        moveHere();
        break;
      default:
        if (onAdd) {
          onAdd(); // TODO: finish (used by Menu, and nothing else?)
        } else {
          setShowAdd(true);
        }
    }
  };

  // const handleFix = () => {
  //   const blankCallflow = {
  //     id: 'inline',
  //     flow: {},
  //     numbers: [],
  //     strategy: {
  //       id: 'blank',
  //       config: {
  //         components: [],
  //       },
  //       data: {
  //         opts: [],
  //       },
  //     },
  //   };
  //   if (fixBySpliceIdx > -1) {
  //     let newArr = getAtPath(callflow, modifyPath, []);
  //     newArr.splice(fixBySpliceIdx, 0, fixData || blankCallflow);
  //     setAtPath(callflow, modifyPath, newArr);
  //     setCallflow({ ...callflow }, { name: 'Fix Missing' });
  //     return;
  //   }
  //   setAtPath(callflow, modifyPath, fixData || blankCallflow);
  //   setCallflow({ ...callflow }, { name: 'Fix Missing' });
  // };

  return (
    <div
      style={{
        // width: 150,
        // height: 24,
        display: 'flex',
        justifyContent: 'center',
        // border: '1px solid #ddd',
        // borderRadius: 4,
      }}
    >
      {showAdd && (
        <ChooseDialog
          {...data}
          showTemplateList
          onClose={setShowAddWrap(null)}
        />
      )}
      <div
        style={{
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <Handle
          type="target"
          position="top"
          style={{ background: '#555', visibility: 'hidden' }}
        />
        <IconButton
          disabled={skipEditing}
          onClick={handleClick}
          size="small"
          variant="outlined"
        >
          {skipEditing ? <CloseIcon /> : icon}
        </IconButton>
        {templateParent ? <InTemplate template={templateParent} /> : ''}
        <Handle
          type="source"
          position="bottom"
          style={{
            top: 'auto',
            bottom: 4,
            background: '#555',
            visibility: 'hidden',
          }}
        />
      </div>
    </div>
  );
});

export default InsertNode;
