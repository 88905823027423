import { Menu, Button, MenuItem, Checkbox } from 'app/design';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from 'app/design/icons-material';

import React, { useRef, useState } from 'react';

interface ListToggleColumnsProps {
  allColumns: any[];
  getToggleHideAllColumnsProps: (props?: any) => void;
}

export default function ListToggleColumns({
  allColumns,
  getToggleHideAllColumnsProps,
}: ListToggleColumnsProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const checkboxRef = useRef<HTMLInputElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = () => {
    checkboxRef.current!.click();
  };

  // override default toggleAllColumns handling to not hide columns with toggle disabled
  const handleToggleAll = e => {
    allColumns.forEach(
      column => !column.disableToggle && column.toggleHidden(!e.target.checked),
    );
  };

  return (
    <div>
      <Button
        variant={'outlined'}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Columns
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ dense: true }}
      >
        <MenuItem key={'toggle-all'} onClick={handleMenuClick}>
          <Checkbox
            sx={{ fontSize: 20 }}
            {...getToggleHideAllColumnsProps({ onChange: handleToggleAll })}
            inputRef={checkboxRef}
            onClick={handleMenuClick}
          />
          All
        </MenuItem>
        {allColumns
          .filter(column => !column.disableToggle)
          .map(column => (
            <MenuItemCheckbox key={column.id} column={column} />
          ))}
      </Menu>
    </div>
  );
}

const MenuItemCheckbox = ({ column }) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    console.log(checkboxRef.current);
    checkboxRef.current!.click();
  };

  return (
    <MenuItem onClick={handleClick}>
      <Checkbox
        onClick={handleClick}
        sx={{ fontSize: 20 }}
        {...column.getToggleHiddenProps()}
        inputRef={checkboxRef}
      />
      {column.Header}
    </MenuItem>
  );
};
