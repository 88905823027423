import { Divider, Tab, Tabs } from 'app/design';
import React from 'react';
import { useImmer } from 'use-immer';
import { CombinedContacts } from './components/CombinedContacts';
import { InternalContacts } from './components/InternalContacts';
// import Menu from 'material-ui-popup-state/HoverMenu'; // use for HoverMenu!

const epochOffset = 62167219200;

// only showing LOCAL call history!!
// - ie NOT using CDRs!! they are difficult to query against (and are too "expensive" to query per-user)

/*
  item: {
    type: 'outgoing',
    data: {
      // to, from, etc,
    }
  }
*/
const ContactsView = props => {
  const [tabValue, setTabValue] = useImmer('internal');

  const handleTabChange = (_, value) => {
    setTabValue(value);
  };

  return <CombinedContacts {...props} />;
};

export default ContactsView;
