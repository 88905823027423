const useStyles = props => ({
  localVideoContainer: {
    position: 'absolute',
    bottom: 4,
    left: 4,
    width: 110,
    height: 82, // 4*3 aspect ratio
    zIndex: 99,
    background: 'black',
    border: '1px solid white',
    // opacity:  (props.showLocalVideo ? 1 : 0.01),
    '& > video': {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      background: '#000000',
      opacity: props.showLocalVideo ? 1 : 0,
    },
  },
  remoteVideoContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: '#81D4FA',
    '& > video': {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      opacity: props.showRemoteVideo ? 1 : 0.01,
    },
  },
  actionButton: {
    background: 'rgba(220,242,248,0.5)',
    border: 'none',
    '&:hover': {
      background: 'rgba(220,242,248,0.7)',
    },
  },
});

export default useStyles;
