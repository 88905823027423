import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { actions } from './index';
import jwt from 'jsonwebtoken';

// import { getUserByAuthToken } from 'app/sdk/utils';
// import { authenticateUserWithCredentials } from 'app/sdk/utils/kazoo';
import { sdk } from 'app/sdk';

function* handleLoginWithAuthToken(action) {
  const { auth_token } = action.payload;

  try {
    // kazoo api fetch for user auth
    const { userAuth, error } = yield call(
      sdk.user.query.userByAuthTokenFromKazoo,
      auth_token,
    );

    // fetch successful
    if (userAuth) {
      console.log('auth_token result', userAuth);
      yield put(
        actions.loginSuccess({
          auth_token: userAuth.auth_token,
        }),
      );
      yield put(actions.readyAfterTokenChange({}));
      return;
    }

    // fetch failed
    yield put(actions.loginFail({ error: error.message }));
  } catch (error) {
    // error thrown
    yield put(actions.loginFail({ error: error.message }));
  }
  yield put(actions.readyAfterTokenChange({}));
}

function* handleLoginWithCredentials(action) {
  const { username, account_name, password } = action.payload;

  try {
    // kazoo api fetch for user auth
    const { userAuth, error } = yield call(
      sdk.user.auth.authenticateUserWithCredentials,
      username,
      account_name,
      password,
    );

    // fetch successful
    if (userAuth) {
      yield put(
        actions.loginSuccess({
          auth_token: userAuth.auth_token,
        }),
      );
      return;
    }

    // fetch failed
    yield put(actions.loginFail({ error: error.message }));
  } catch (error) {
    // error thrown
    yield put(actions.loginFail({ error: error.message }));
  }
}

const jwtDecode = auth_token => {
  try {
    return jwt.decode(auth_token);
  } catch (e) {
    console.error(e);
    return null;
  }
};

function* handleAuthTokenChange(action) {
  // when a new auth_token arrives
  // - tell the user we are authenticating (when new user, or user in auth_token changed)
  //   - also clear the cache via LOGOUT action (todo: CLEAR_CACHE instead of LOGOUT?)
  // - or, silently update the auth_token in the background, if it fails on requests then the user is logged out (and sent to the login page)
  const { auth_token } = action.payload;
  const state = yield select();
  const { auth: authState } = state;
  console.log('handleAuthTokenChange authState:', authState);

  try {
    if (auth_token) {
      const auth_token_decoded = jwtDecode(auth_token);

      if (auth_token_decoded) {
        // user is different?
        if (
          auth_token_decoded.owner_id === authState.auth_token_decoded?.owner_id
        ) {
          // user is same

          // is expiration newer?
          if (auth_token_decoded.exp > authState.auth_token_decoded.exp) {
            // newer expiration

            // silently update the authToken locally
            yield put(actions.silentlyUpdateAuthToken({ auth_token }));
          } else {
            // older expiration, dont do anything, kinda weird to get an auth_token here...
            // - TODO: remove auth_token from querystring? currently already doing...
          }
        } else {
          // user is different or user doesn't exist

          // log out current user (clear data)
          yield put(actions.logout(null));

          // login with auth token
          yield put(actions.loginWithAuthToken({ auth_token }));
          // do NOT continue with actions.readyAfterTokenChange...
          return;
        }
      } else {
        // invalid auth token
        yield put(actions.logout(null));
      }

      // const { exp } = auth_token_decoded;
      // const currentTimestamp = Math.ceil(new Date().getTime() / 1000);
      // const expirationBuffer = 10; // seconds
      // const expirationTimestamp = exp - expirationBuffer;
      // console.log('auth exp timestamp & current', exp, currentTimestamp);
      // if (currentTimestamp >= expirationTimestamp) {
      //   console.log('auth token expired');
      // } else {
      //   console.log('auth token still valid');
      // }
    }
  } catch (e) {
    console.error(e);
  }
  yield put(actions.readyAfterTokenChange({}));
}

export function* authSaga() {
  // takeLatest means only the most recent of multiple dispatch will be run,
  // still running previously dispatched actions will be canceled
  yield takeLatest(actions.authTokenChange.type, handleAuthTokenChange);
  yield takeLatest(actions.loginWithAuthToken.type, handleLoginWithAuthToken);
  yield takeLatest(
    actions.loginWithCredentials.type,
    handleLoginWithCredentials,
  );
}
