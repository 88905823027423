import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

export const useDeleteCallflow = () => {
  const authState = useAuthSelector();
  return useMutation<any, AxiosError, string, unknown>((callflowId: string) =>
    sdk.callflow.mutate.delete(callflowId, authState?.auth_token),
  );
};
