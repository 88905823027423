import React, { useEffect, useCallback, useState, useRef } from 'react';

import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
  Grid,
  InputAdornment,
  Divider,
} from 'app/design';

import { Search as SearchIcon } from 'app/design/icons-material';

import { matchSorter } from 'match-sorter';

import { cloneDeep } from 'lodash';
import { ContactDisplay } from '../ContactDisplay';
import { ViewContainer } from '../ViewContainer';

import { MicSetting } from './MicSetting';
import { SpeakerSetting } from './SpeakerSetting';
// import { CameraSetting } from './CameraSetting';
import { RingSetting } from './RingSetting';
// import { ConferenceSetting } from './ConferenceSetting';

// this list gets copied/modified to improve searchability/filterability

const SettingsView = props => {
  const { show } = props;

  const BASE_SETTINGS_LIST = [
    {
      component: MicSetting,
    },
    {
      component: SpeakerSetting,
    },
    // {
    //   component: CameraSetting,
    // },
    // {
    //   component: RingSetting,
    // },
    // {
    //   component: ConferenceSetting,
    // },
  ];

  // determine which settings match (and so will be displayed)
  const anyMatch = () => {
    // run matchsorter against each subcomponent in array
    for (let item of BASE_SETTINGS_LIST) {
    }
    // run matchsorter against the output list
    // - for ones that we already want to include (childrenMatched) we make sure they are added/included
    const settingsList = matchSorter(fullSettingsList, filterText, {
      keys: ['baseSearch', 'addedSearch'],
    });
  };

  const [fullSettingsList, setFullSettingsList] = useState(
    cloneDeep(BASE_SETTINGS_LIST),
  );
  const [filterText, setFilterText] = useState('');
  // const [showSettings, setShowSettings] = useState(SETTINGS_LIST);

  // useEffect(() => {
  //   const settingsList = matchSorter(fullSettingsList, filterText, {
  //     keys: ['baseSearch', 'addedSearch'],
  //   });
  //   setShowSettings([...settingsList]);
  // }, [filterText, fullSettingsList]);

  const handleChildrenFilterMatching = index => matching => {
    setFullSettingsList(list => {
      list[index].matching = matching;
      return [...list];
    });
  };

  // any children match?
  const anyChildrenMatch = fullSettingsList.find(setting => setting.matching);

  return (
    <Box
      sx={{
        display: show ? 'flex' : 'none',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ flex: '0 1 auto' }}>
        <ViewContainer
          title={'Settings'}
          show={show}
          searchComponent={
            <TextField
              value={filterText}
              onChange={e => setFilterText(e.target.value)}
              placeholder="Filter Settings"
              variant="outlined"
              fullWidth
              size="small"
              style={{ margin: 0 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ transform: 'scaleX(-1)' }} />
                  </InputAdornment>
                ),
              }}
            />
          }
        />
      </Box>
      <Divider />
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
        }}
      >
        <div
          style={{
            flex: 1,
            // overflowY: 'scroll',
          }}
        >
          {!anyChildrenMatch ? (
            <div style={{ padding: 8, textAlign: 'center' }}>
              <Typography variant="body2">No Matching Settings</Typography>
            </div>
          ) : (
            ''
          )}
          <div style={{ padding: 8 }}>
            {/* Render every item, which handles whether it is displayed or not */}
            {BASE_SETTINGS_LIST.map((setting, i) => {
              const Component = setting.component;
              return (
                <Component
                  key={i}
                  filterText={filterText}
                  setFilterMatching={handleChildrenFilterMatching(i)}
                />
              );
            })}
          </div>
        </div>
      </Box>
    </Box>
  );

  // console.log('filterText:', filterText);
  return (
    <div
      style={{
        height: '100%',
        display: show ? 'block' : 'none',
      }}
    >
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div>
          <div style={{ padding: '2px 2px' }}>
            <TextField
              value={filterText}
              onChange={e => setFilterText(e.target.value)}
              placeholder="Filter Settings"
              variant="outlined"
              fullWidth
              size="small"
              style={{ margin: 0 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ transform: 'scaleX(-1)' }} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Divider />
        </div>

        <div
          style={{
            flex: 1,
            overflowY: 'scroll',
          }}
        >
          {!anyChildrenMatch ? (
            <div style={{ padding: 8, textAlign: 'center' }}>
              <Typography variant="body2">No Matching Settings</Typography>
            </div>
          ) : (
            ''
          )}
          <div style={{ padding: 8 }}>
            {/* Render every item, which handles whether it is displayed or not */}
            {BASE_SETTINGS_LIST.map((setting, i) => {
              const Component = setting.component;
              return (
                <Component
                  key={i}
                  filterText={filterText}
                  setFilterMatching={handleChildrenFilterMatching(i)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsView;
