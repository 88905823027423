/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { Suspense, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query';

import { globalStyles } from 'styles/global-styles';
import {
  CssBaseline,
  useMediaQuery,
  ThemeProvider,
  createTheme,
  buildThemeWithMode,
  Box,
  Typography,
  Link,
  Container,
} from 'app/design';

import {
  WebphoneSipProvider,
  WebphoneProvider,
  reducer as webphoneReducer,
  initialState as webphoneInitialState,
} from 'app/components/Webphone'; // for root

// import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
// import { useAccountQuery } from 'app/hooks/queries/account';
import { Onboarding } from 'app/components/Onboarding';
import { Channels } from 'app/components/Channels';
import { GlobalTourProvider } from 'app/components/Tourguide/Helper';
import { default as Routing } from './Routing';
import { AuthGate } from './components/AuthGate';
import { useTranslation } from 'react-i18next';
import { ReactQueryDevtools } from 'react-query/devtools';
// import {} from '@reduxjs/toolkit';
import { useAuthSelector, useAuthSlice } from './data/auth';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  parseGraphqlErrorCode,
  FeatureFlagProvider,
  HintProvider,
} from 'app/utilities';
import { WebsocketProvider } from 'app/utilities/websocketBinding';
import { AxiosError } from 'axios';
import { ErrorBoundary } from 'react-error-boundary';
import { Link as RouterLink } from 'react-router-dom';
import { LoginDialog } from './components/LoginDialog';

import 'resize-observer-polyfill'; // for @rehooks/component-size

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// @ts-ignore
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// const queryClient = new QueryClient({
//   /* default options are only a fallback and will not be used if the
//      if the respective option is set explicitly in the query/mutation option
//   */
//   defaultOptions: {
//     queries: {
//       refetchOnWindowFocus: process.env.NODE_ENV === 'production',
//       staleTime: 20 * 1000, // set a minimum stale time on all queries to deduplicate requests?
//     },
//   },
// });

function App() {
  const { i18n } = useTranslation();
  const { actions: authActions } = useAuthSlice();
  const { show_login_dialog, loggedIn } = useAuthSelector();
  const dispatch = useDispatch();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: async error => {
            const errorCode = parseGraphqlErrorCode(error);
            // is error unauthenticated request
            if (
              // @ts-ignore
              errorCode === 'UNAUTHENTICATED'
            ) {
              // unauthenticated request, cancel queries, clear cache and log user out
              await queryClient.cancelQueries();
              await queryClient.clear();
              dispatch(authActions.logout(null));
              toast.error(
                // @ts-ignore
                `Authentication failed. Error: ${errorCode ?? error.message}`,
              );
            }
          },
        }),

        mutationCache: new MutationCache({
          onError: async (error, variables, context, mutation) => {
            console.log('Mutation cache level error123', error, context);
            if ((error as AxiosError).response?.status === 401) {
              dispatch(authActions.unauthorizedRequest({}));
            }
            // @ts-ignore
            // if (error.message === '401') {
            //   // unauthenticated request, cancel queries, clear cache and log user out
            //   await queryClient.cancelQueries();
            //   await queryClient.clear();
            //   dispatch(authActions.logout(null));
            //   toast.error(
            //     // @ts-ignore
            //     `Authentication failed. Error: ${error.message}`,
            //   );
            // }
          },
          // onSuccess: data => {},
        }),

        /* default options are only a fallback and will not be used if the
       if the respective option is set explicitly in the query/mutation option
    */
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: process.env.NODE_ENV === 'production',
            // staleTime: 20 * 1000, // set a minimum stale time on all queries to deduplicate requests? NO!!!
          },
          mutations: {
            // three reasons we could get 401:
            //    1. no credentials sent up (blank auth header)
            //    2. credentials have expired (we can check local)
            //    3. server side revokes auth token (deleted user, etc.)
            // useErrorBoundary: error =>
            //   (error as AxiosError).response?.status === 401,
          },
        },
      }),
    [dispatch, authActions],
  );

  // better way to do this? TODO: get query client into auth saga to handle there
  useEffect(() => {
    // clear query cache on log out
    if (!loggedIn) queryClient.clear();
  }, [loggedIn]);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // @ts-ignore
  const usedTheme = React.useMemo(
    () =>
      // @ts-ignore
      createTheme(buildThemeWithMode(prefersDarkMode ? 'light' : 'light')), // dark
    [prefersDarkMode],
  );

  // oauth postmessage
  // - notifies parent of access_token
  // - closes this window
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('oauthpopper')) {
    window.opener.postMessage(window.location.search);
    window.close();
  }

  return (
    <Suspense fallback={null}>
      <WebsocketProvider>
        <BrowserRouter>
          <QueryParamProvider ReactRouterRoute={Route}>
            <QueryClientProvider client={queryClient}>
              <FeatureFlagProvider>
                <Elements stripe={stripePromise}>
                  <Helmet
                    titleTemplate="%s :: CallingIO"
                    defaultTitle="CallingIO"
                    // htmlAttributes={{ lang: i18n.language }}
                  >
                    <meta name="description" content="CallingIO Browser App" />
                  </Helmet>
                  <ThemeProvider theme={usedTheme}>
                    <CssBaseline />
                    <HintProvider>
                      {show_login_dialog ? <LoginDialog /> : null}
                      <AuthGate>
                        <WebphoneProvider>
                          {/* <WebphoneDeviceBuilder /> */}
                          <WebphoneSipProvider />
                          {/* <Onboarding /> */}
                          <Channels />
                          <GlobalTourProvider>
                            <Routing />
                          </GlobalTourProvider>
                        </WebphoneProvider>
                      </AuthGate>
                    </HintProvider>
                  </ThemeProvider>
                  <ToastContainer position={'bottom-right'} />
                  {process.env.NODE_ENV === 'development' && (
                    <ReactQueryDevtools initialIsOpen={false} />
                  )}
                  {globalStyles}
                </Elements>
              </FeatureFlagProvider>
            </QueryClientProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </WebsocketProvider>
    </Suspense>
  );
}

export default App;
