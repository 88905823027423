import { Grid, Typography } from 'app/design';
import { ContactNameDisplay } from 'app/components/Webphone/UI/ContactsView/components/CombinedContacts/CombinedContacts';
import React from 'react';
import { Contact } from 'types/contact';
import { AvatarCell } from '../../../ListUsers/components/AvatarCell';
import { PhoneNumberDisplay } from '../../../PhoneNumberDisplay';

interface ContactDisplayProps {
  contact: Contact;
  number?: string;
}

const ContactDisplay = ({ contact, number }: ContactDisplayProps) => {
  return (
    <div>
      <Grid
        container
        sx={{ minWidth: 0 }}
        alignItems="center"
        columnSpacing={2}
      >
        <Grid item>
          <AvatarCell
            resource={{
              doc: {
                first_name: contact.info.firstName,
                last_name: contact.info.lastName,
                company: contact.info.company,
              },
            }}
            size={40}
          />
        </Grid>
        <Grid item sx={{ flex: 1 }}>
          <ContactNameDisplay contact={contact} />
        </Grid>
        {/*<Grid item sx={{ flex: 1 }}>
          <Typography>
            {contact.info.firstName ? (
              contact.info.lastName?.length ? (
                <Typography sx={{ display: 'inline-block' }}>
                  {contact.info.firstName}&nbsp;
                </Typography>
              ) : (
                <Typography
                  sx={{ display: 'inline-block', fontWeight: 'bold' }}
                >
                  {contact.info.firstName}
                </Typography>
              )
            ) : null}
            {contact.info.lastName ? (
              <Typography sx={{ display: 'inline-block', fontWeight: 'bold' }}>
                {contact.info.lastName}{' '}
              </Typography>
            ) : null}
          </Typography>
          {contact.info?.company?.length ? (
            contact.info.firstName || contact.info.lastName ? (
              <Typography variant="caption" sx={{ opacity: 0.7 }}>
                {contact.info?.company}
              </Typography>
            ) : (
              <Typography sx={{ display: 'inline-block', fontWeight: 'bold' }}>
                {contact.info?.company}
              </Typography>
            )
          ) : null}
        </Grid>*/}
        {number ? (
          <Grid item sx={{ textAlign: 'right' }}>
            <PhoneNumberDisplay ptn={number} />
          </Grid>
        ) : undefined}
      </Grid>
    </div>
  );
};

export default ContactDisplay;
