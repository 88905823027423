import { IconButton, Tooltip } from 'app/design';
import { Delete as DeleteIcon } from 'app/design/icons-material';

import { useCallflowQuery } from 'app/hooks/queries/callflow';
import React, { memo, useRef } from 'react';
import { Handle } from 'react-flow-renderer';

// import useStyles from './styles';
import { useHoverDirty } from 'react-use';

// import SlateEditor from '../../../../DialogEditSchedule/SlateEditor/slateEditor';
// import { default as TemporalRuleBuilder } from '../../../../Strategies/components/TemporalRoute/temporalRuleBuilder';
import { InTemplate } from '../common/InTemplate';

const OptionScheduleNode = memo(({ data }) => {
  const {
    skipEditing,
    scheduleCallflow, // {id: 'xyz'}
    callflow,
    setCallflow,
    modifyPath, // to ".times"
    timeKey,
    templateParent,
  } = data;

  const optionRef = useRef(null);
  const isHovering = useHoverDirty(optionRef);

  // Load schedule via query
  // - TODO: should just use query cache?
  const {
    data: foundScheduleCallflow,
    isLoading,
    isFetched,
  } = useCallflowQuery({ id: scheduleCallflow?.id });

  // // TODO: handle inline schedules!
  // // - the ENTIRE scheduleCallflow is already passed in the parent!!
  // const foundScheduleCallflow = callflows.find(
  //   cf => cf.id === scheduleCallflow?.id,
  // );

  // console.log(
  //   'foundScheduleCallflow:',
  //   foundScheduleCallflow,
  //   scheduleCallflow
  // );

  let timeDetails;
  let text = '';
  if (foundScheduleCallflow) {
    timeDetails = foundScheduleCallflow.doc?.strategy?.data?.times.find(
      t => t.id === timeKey,
    );
    text = timeDetails?.name ?? '';
  } else {
    text = 'Loading...';
  }

  const isWide = text.length > 1 ? true : false;

  // const classes = useStyles({ mode: isHovering ? 'normal' : 'ignore' });

  const handleRemove = () => {
    // only should clear flow below!!
    // - change times from the schedule editor!
    // const targets = getAtPath(callflow, modifyPath);
    // delete targets[timeKey];
    // setAtPath(callflow, modifyPath, targets);
    // setCallflow({ ...callflow });
  };

  const hasRules = timeDetails?.rules?.[0]?.outputRuleIds?.length
    ? true
    : false;

  return (
    <div
      ref={optionRef}
      style={{
        // width: 150,
        // height: 50,
        display: 'flex',
        justifyContent: 'center',
        // border: '1px solid #ddd',
        // borderRadius: 4,
      }}
    >
      <div
        style={{
          position: 'relative',
          width: isWide ? 80 : 30,
          height: 30,
          borderRadius: isWide ? 3 : 15,
          border: '1px solid #ddd',
          background: 'rgb(254, 255, 255)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Handle
          type="target"
          position="top"
          style={{ top: 4, background: '#555', visibility: 'hidden' }}
          onConnect={params => console.log('handle onConnect', params)}
        />
        <div style={{ position: 'relative', textAlign: 'center' }}>
          {/* Text w/ Hover */}

          <Tooltip
            title={
              <>
                {/* {hasRules ? (
                  <SlateEditor
                    value={timeDetails.rules[0].data.value.filter(
                      v => v.children[0].text,
                    )}
                    setValue={() => {}}
                    readOnly
                  />
                ) : (
                  // <TemporalRuleBuilder timeDetails={timeDetails} readOnly />
                  <Typography variant="body1">No rules</Typography>
                )} */}
                RULES
              </>
            }
            placement="bottom"
            type="light"
            arrow
          >
            <span>{text}</span>
          </Tooltip>

          {/* Delete Icon */}
          {skipEditing ? null : (
            <span
              // className={classes.opacity}
              style={{ position: 'absolute', top: -18, left: -25 }}
            >
              <Tooltip
                title={<>Remove this Time</>}
                placement="bottom"
                type="light"
                arrow
              >
                <IconButton
                  onClick={handleRemove}
                  size="small"
                  variant="outlined"
                  color="secondary"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </span>
          )}
        </div>
        <Handle
          type="source"
          position="bottom"
          style={{
            top: 'auto',
            bottom: 4,
            background: '#555',
            visibility: 'hidden',
          }}
        />
        {templateParent ? <InTemplate template={templateParent} /> : ''}
      </div>
    </div>
  );
});

export default OptionScheduleNode;
